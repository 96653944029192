import { FC } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onSave: () => void;
  onCancel: () => void;
  className?: string;
  saveButtonDisabled?: boolean;
}

const SaveAndCancelIcons: FC<Props> = ({
  onSave,
  onCancel,
  className,
  saveButtonDisabled,
}) => {
  return (
    <div className={className}>
      <button
        type="submit"
        className="mr-2 rounded-full focus:outline-none"
        onClick={() => onCancel()}
      >
        <FontAwesomeIcon icon={["fas", "times-circle"]} color="#f57c67" />
      </button>
      <button
        className={cn(
          "mr-2 rounded-full focus:outline-none",
          saveButtonDisabled && "cursor-not-allowed"
        )}
        onClick={() => onSave()}
        disabled={saveButtonDisabled}
      >
        <FontAwesomeIcon
          icon={["fas", "check"]}
          color={saveButtonDisabled ? "gray" : "#53cfc6"}
        />
      </button>
    </div>
  );
};

export default SaveAndCancelIcons;
