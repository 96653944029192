// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import * as slice from "../../reducer";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  GET_PROFILE_IMPACT,
  SAVE_PROFILE_IMPACT,
} from "queries/startup/startupSettings/impactStep";
import { goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { updateCompanyImpact } from "../../reducer";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

type ImpactData = {
  impact: {
    impactNote: string;
    isImpact: boolean;
  };
  sustainableDevelopmentGoals: {
    items: [];
  };
};

const fortmatGetImpact = (data: ImpactData) => {
  const { impact, sustainableDevelopmentGoals } = data;
  return {
    impact: {
      impactNote: impact?.impactNote || "",
      isImpact: impact?.isImpact,
    },
    sustainableDevelopmentGoals: {
      items: sustainableDevelopmentGoals?.items || [],
    },
  };
};

function* getImpact() {
  yield put(toggleLoader({ component: STEP.IMPACT, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_PROFILE_IMPACT,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  const impactSdgs = fortmatGetImpact(data?.me);

  yield put(
    updateCompanyImpact({
      impactSdgs,
    })
  );
  yield put(toggleLoader({ component: STEP.IMPACT, isLoading: false }));
}

function* updateIsImpact(data) {
  yield put(
    slice.updateIsImpact({
      isImpact: data.payload,
    })
  );
}

function* saveImpact({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.IMPACT, isLoading: true }));
  yield put(toggleLoader({ component: STEP.IMPACT, isLoading: false }));

  const { isError } = yield client
    .mutate({
      mutation: SAVE_PROFILE_IMPACT,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.IMPACT, isLoading: false }));
    return;
  }

  yield getImpact();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.IMPACT, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getImpact, getImpact),
    yield takeEvery(sagaActions.saveImpact, saveImpact),
    yield takeEvery(sagaActions.updateIsImpact, updateIsImpact),
  ];
}
