// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const initializeCompanySettings = createAction(
  "companySettings/init",
  (payload) => ({
    payload,
  })
);

export const submitCompanyApplication = createAction(
  "companySettings/submit-application",
  (payload) => ({
    payload,
  })
);

export const saveProfileInfo = createAction(
  "companySettings/profileInfo/save",
  (payload) => ({
    payload,
  })
);

export const getProfileInfo = createAction(
  "companySettings/profileInfo/fetch",
  (payload) => ({
    payload,
  })
);
