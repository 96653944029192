// @ts-nocheck
import { useState, useEffect } from "react";
import _ from "lodash";
import { SubmitHandler, Controller, useForm, useWatch } from "react-hook-form";
import TextArea from "atoms/Form/TextArea";
import { useQuery } from "@apollo/client";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getFundraising } from "./selectors";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import DefaultImage from "images/dealroom-image.png";

import { saveFundraising } from "./sagaActions";
import { STEP } from "pages/companySettings/constants";
import ButtonGroup from "molecules/Form/ButtonGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrencyList } from "models/user/selectors";
import { RAISEDFROM, AMOUNT_PATTERN, FUNDRAISING_STAGES } from "core/consts";
import CurrencySelectionDropdownInput from "atoms/Form/CurrencySelectionDropdownInput";
import CustomInvestor, { CustomInvestorType } from "./CustomInvestor";
import Investors, { InvestorType } from "./Investors";
import { ReactComponent as FundraisingIcon } from "images/fundraising-icon.svg";
import cn from "classnames";
// TODO: extract it to a shared organism component
import TypeSelect from "./TypeSelect";
import StepActionButtonStateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import DateSelect from "atoms/Form/DateSelect";
import TextField from "pages/onboard/components/TextField/TextField";
import { InvestorShort } from "gql/graphql";
import { SEARCH_INVESTORS_TO_ADD } from "queries/startup/startupDealflow";

type Currency = { _id: string; code: string; isDefault: boolean };

type FormValues = {
  raisedValue: string;
  investors: InvestorType[];
  customInvestor: CustomInvestorType; // to collect/validate only.will be merged with investors onSubmit
  investorNote: string;
  raisedTarget: { amount: string; currency: Currency };
  valuation: string;
  raisedFrom: string[];
  investmentStage: string[];
  investmentStageNote: string;
  minTicketSize: string;
  fundraisingCloseOn: string;
  committed: string;
  fit: string;
};

// what we get from the graphql query
type FundraisingData = {
  currency: string;
  finance: {
    raisedFrom: string[];
    investmentStage: string[];
    investmentStageNote: string;
    minTicketSize: string;
    fundraisingCloseOn: string;
    committed: string;
    raisedValue: string;
    raisedTarget: string;
  };
  investorNote: string;
  fit: string;
  investors: InvestorType[];
  valuation: string;
};

const FundraisingForm = () => {
  const data: FundraisingData = useSelector(getFundraising);
  const currencyData: Currency[] = useSelector((state) =>
    getCurrencyList(state)
  );

  const getDefaultCurrency = () =>
    currencyData.find((currency) => currency.isDefault) as Currency;

  const getCurrencyById = (id: string) =>
    currencyData.find((currency) => currency._id === id);

  const getDefaultValues = (): FormValues => {
    const { finance, currency, investors, ...rest } = data;

    const {
      raisedValue,
      raisedFrom,
      raisedTarget,
      minTicketSize,
      fundraisingCloseOn,
      committed,
      investmentStage,
      investmentStageNote,
    } = finance || {
      raisedValue: "",
      raisedFrom: [],
      raisedTarget: "",
      minTicketSize: "",
      fundraisingCloseOn: "",
      committed: "",
      investmentStage: [],
      investmentStageNote: "",
    };

    return {
      raisedValue,
      raisedFrom,
      raisedTarget: {
        amount: raisedTarget,
        currency: getCurrencyById(currency) || getDefaultCurrency(),
      },
      investors: investors || [],
      customInvestor: { name: "", displayName: "" },
      investmentStage,
      investmentStageNote,
      minTicketSize,
      fundraisingCloseOn,
      ...rest,
    };
  };

  const {
    register,
    handleSubmit,
    getValues,
    control,
    trigger,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: getDefaultValues() });

  const { lang } = useLang();
  const dispatch = useDispatch();

  const [isInvestorInputShown, setIsInvestorInputShown] =
    useState<boolean>(false);
  const [isInvestorNoteEnabled, setIsInvestorNoteEnabled] =
    useState<boolean>(false);
  const [isActivelyLookingForCapital, setIsActivelyLookingForCapital] =
    useState<string>("true");
  const [query, setQuery] = useState("");

  const { data: investorsToAdd, previousData } = useQuery(
    SEARCH_INVESTORS_TO_ADD,
    {
      variables: {
        query,
      },
      skip: !query,
    }
  );

  const investorsAdd =
    investorsToAdd?.investorsToAdd || previousData?.investorsToAdd || [];

  // We have to update the default value after data has been fethced and turn it into string
  useEffect(() => {
    if (
      data.isActivelyLookingForCapital === true ||
      data.isActivelyLookingForCapital == null
    ) {
      setIsActivelyLookingForCapital("true");
      setValue("isActivelyLookingForCapital", true);
    } else {
      setIsActivelyLookingForCapital("false");
    }
  }, [data.isActivelyLookingForCapital]);

  const currencyCode = useWatch({
    control,
    name: "raisedTarget.currency.symbol",
  });
  const investors = useWatch({ control, name: "investors" });
  const customInvestor = useWatch({ control, name: "customInvestor" });
  const investmentStage = useWatch({ control, name: "investmentStage" });

  const loading = useSelector((state) =>
    isLoadingEnabled(state, STEP.FUNDRAISING)
  );

  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const onSubmit: SubmitHandler<FormValues> = async () => {
    const { raisedTarget, customInvestor, ...rest } = getValues();

    const {
      currency: { _id },
      amount,
    } = raisedTarget;

    const input = {
      raisedTarget: amount,
      currency: _id,
      ...rest,
    };

    dispatch(
      saveFundraising({
        lang,
        input,
      })
    );
  };

  const handleAddInvestor = (investor: InvestorShort) => {
    const { name, displayName } = investor;
    setValue("investors", [...investors, { name, displayName }]);
    setQuery("");
  };

  const handleAddCustomInvestor = async () => {
    const passed = await trigger("customInvestor", { shouldFocus: true });
    if (!passed) {
      return;
    }
    const { name, displayName } = customInvestor;
    setValue("investors", [...investors, { name, displayName }]);
    resetField("customInvestor");
    setIsInvestorInputShown(false);
  };

  const handleCustomInvestorCancel = () => {
    resetField("customInvestor");
    setIsInvestorInputShown(false);
  };

  const onActivelyLookingChanged = (e) => {
    if (e.target.value) {
      setIsActivelyLookingForCapital(e.target.value);
      setValue(
        "isActivelyLookingForCapital",
        e.target.value === "true" ? true : false
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <FundraisingIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.fundraising}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>{lang.fundraisingDescription}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <div>
          <label
            htmlFor="investmentStage"
            className="text-sm font-medium text-black text-opacity-100"
          >
            What fundraising stage are you in?
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="investmentStage"
              render={({ field: { value, onChange } }) => (
                <ButtonGroup
                  id="investmentStage"
                  options={Object.entries(FUNDRAISING_STAGES)}
                  onChange={onChange}
                  defaultValue={value}
                  type="company"
                  limit={1}
                />
              )}
            />
          </div>
        </div>

        {investmentStage?.includes("OTHER") && (
          <>
            <TextArea
              rows="3"
              id="investmentStageNote"
              className={cn(
                "form-textarea block mt-4 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm",
                errors.investmentStage
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : ""
              )}
              placeholder="Add note for fundraising stage"
              {...register("investmentStageNote", {
                required: true,
                shouldUnregister: true,
              })}
              maxLength={200}
            />
            {errors.investmentStageNote && (
              <p className="mb-2 -mt-6 text-sm text-fire-400">
                {lang.mandatory}
              </p>
            )}
          </>
        )}
        <div className="mt-4">
          <label
            htmlFor="activelyLooking"
            className="text-sm font-medium text-black text-opacity-100"
          >
            Are you currently looking for capital?
          </label>
          <div className="mt-2">
            <TypeSelect
              name="activelyLooking"
              label1="Yes"
              label2="No"
              checked={isActivelyLookingForCapital}
              onChange={onActivelyLookingChanged}
              companyType="startup"
            />
          </div>
        </div>
        {isActivelyLookingForCapital === "true" && (
          <div className="grid flex-wrap w-full md:flex">
            <div className="w-full mt-4">
              <Controller
                control={control}
                name="raisedTarget"
                rules={{
                  validate: {
                    shoudBeNumber: (value) => {
                      if (value.amount === "") {
                        return AMOUNT_PATTERN.test(0);
                      } else {
                        return AMOUNT_PATTERN.test(value.amount);
                      }
                    },
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CurrencySelectionDropdownInput
                    selectName="currency"
                    label={lang.whatIsYourFundraisingTarget}
                    selectOptions={currencyData?.map((currency: Currency) => ({
                      key: currency._id,
                      value: currency.code,
                    }))}
                    onSelectVal={(selected: string[]) => {
                      const currency = getCurrencyById(selected[0]);
                      if (currency) {
                        onChange({ ...value, currency });
                      }
                    }}
                    direction="left"
                    selectValue={value?.currency?.code}
                    inputName="raisedTarget"
                    inputVal={value.amount}
                    inputPlaceholder={lang.enterAmount}
                    errors={error && lang.amountValidation}
                    handleUpdateVal={(amount: string) => {
                      onChange({ ...value, amount });
                    }}
                  />
                )}
              />
            </div>

            <div className="grid mt-2 sm:mt-4 sm:grid-cols-2 md:flex sm:gap-4">
              <div className="flex flex-col justify-between w-full">
                <div className="mt-4 mb-2 text-sm font-medium text-black text-opacity-100">
                  {lang.whatIsYourValuation}
                </div>
                <div
                  className={cn(
                    "py-2 pl-2.5 outline-none items-center flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border",
                    errors.valuation
                      ? " border-red-300 text-red-900 placeholder-red-300"
                      : " text-gray-700 border-black border-opacity-25"
                  )}
                >
                  <div className="py-1.5 text-lg text-gray-500">
                    {currencyCode}
                  </div>
                  <input
                    type="text"
                    id="valuation"
                    className="pl-3 outline-none "
                    placeholder={lang.enterAmount}
                    {...register("valuation", { pattern: AMOUNT_PATTERN })}
                  />
                </div>
                {errors.valuation && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.amountValidation}
                  </p>
                )}
              </div>
              <div className="flex flex-col justify-end w-full">
                <div className="mt-4 mb-2 text-sm font-medium text-black text-opacity-100">
                  What is the minimum ticket size in your current investment
                  round?
                </div>
                <div
                  className={cn(
                    "py-3.5 pl-2.5 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border",
                    errors.minTicketSize
                      ? " border-red-300 text-red-900 placeholder-red-300"
                      : " text-gray-700 border-black border-opacity-25"
                  )}
                >
                  <div className="text-lg text-gray-500">{currencyCode}</div>
                  <input
                    type="text"
                    id="minTicketSize"
                    className="pl-3 outline-none"
                    placeholder={lang.enterAmount}
                    {...register("minTicketSize", { pattern: AMOUNT_PATTERN })}
                  />
                </div>
                {errors.minTicketSize && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.amountValidation}
                  </p>
                )}
              </div>
            </div>
            <div className="grid mt-2 sm:mt-4 sm:grid-cols-2 md:flex sm:gap-4">
              <div className="flex flex-col justify-end w-full">
                <div className="mt-4 mb-2 text-sm font-medium text-black text-opacity-100">
                  When do you expect to close your fundraising round?
                </div>
                <Controller
                  control={control}
                  name="fundraisingCloseOn"
                  render={({ field: { value, onChange } }) => (
                    <DateSelect
                      placeholder="Pick a date"
                      defaultValue={value}
                      onChange={(val) => onChange(val)}
                      className="border-black border-opacity-25 rounded-md shadow-sm cursor-pointer form-input"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-4">
          <label
            htmlFor="fit"
            className="text-sm font-medium text-black text-opacity-100"
          >
            {lang.whatAreYoulookingForInvestors}
          </label>
          <TextArea
            rows="3"
            id="fit"
            className={`form-textarea block mt-2 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
              errors.fit
                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
                : ""
            }`}
            placeholder="Write your desired investor profile here"
            {...register("fit")}
            maxLength={200}
          />
          {errors.fit && (
            <p className="mb-2 -mt-6 text-sm text-fire-400">{lang.mandatory}</p>
          )}
        </div>
        <div className="mt-4 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.howMuchMoneyHaveYouRaised}
        </div>
        <div
          className={cn(
            "py-3.5 pl-2.5 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border",
            errors.raisedValue
              ? " border-red-300 text-red-900 placeholder-red-300"
              : " text-gray-700 border-black border-opacity-25"
          )}
        >
          <div className="text-lg text-gray-500">{currencyCode}</div>
          <input
            type="text"
            id="raisedValue"
            className="pl-3 outline-none"
            placeholder={lang.enterAmount}
            {...register("raisedValue", { pattern: AMOUNT_PATTERN })}
          />
        </div>
        {errors.raisedValue && (
          <p className="mt-2 mb-2 text-sm text-fire-400">
            {lang.amountValidation}
          </p>
        )}
        <div className="mt-5">
          <label
            htmlFor="raisedFrom"
            className="text-sm font-medium text-black text-opacity-100"
          >
            {lang.whoDidYouRaiseFrom}
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="raisedFrom"
              render={({ field: { value, onChange } }) => (
                <ButtonGroup
                  id="raisedFrom"
                  options={Object.entries(RAISEDFROM)}
                  onChange={onChange}
                  defaultValue={value}
                  type="company"
                />
              )}
            />
          </div>
        </div>

        <div>
          <div className="mb-5">
            <Controller
              control={control}
              name="investors"
              render={({ field: {} }) => (
                <div>
                  <div className="mb-6 text-sm text-gray-500">
                    Search our network for an investor or add it manually
                  </div>
                  <TextField
                    label="Search or add by investor or firm name"
                    labelClassName="text-sm font-medium text-black text-opacity-100 ml-0 mb-2"
                    value={query}
                    onChange={setQuery}
                    className="mb-4 ml-0"
                  />
                  <div>
                    {investorsAdd.map((investor: InvestorShort) => (
                      <div
                        key={investor.id}
                        className="flex items-center justify-between w-full p-2 mt-2 border border-gray-300 rounded-xl"
                      >
                        <div className="flex items-center">
                          <img
                            src={investor.logo?.small?.location || DefaultImage}
                            alt=""
                            className="object-cover w-8 h-8 mr-2 border border-gray-300 rounded-full"
                          />
                          <span>{investor.name}</span>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleAddInvestor(investor)}
                          className="focus:text-aqua-400 flex items-center focus:border-aqua-300 hover:text-aqua-400 hover:border-aqua-300 text-sm px-2 py-0.5 rounded-lg"
                        >
                          <span className="mr-1">Add</span>
                          <FontAwesomeIcon icon={["fas", "plus"]} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            />
          </div>
          <div className="justify-between flex-none md:flex">
            <div className="text-sm sm:col-span-2">
              {lang.canNotFindYourInvestor}
              <span
                className="ml-2 -mb-2 text-sm cursor-pointer text-aqua-400"
                onClick={() => setIsInvestorInputShown(true)}
              >
                {lang.addManually}
              </span>
            </div>
            <div className="sm:col-span-2">
              <div
                className={cn(
                  "block -mb-2 text-sm text-right cursor-pointer align-right sm:mt-0",
                  isInvestorNoteEnabled ? "text-fire-400" : "text-aqua-400"
                )}
                onClick={() => setIsInvestorNoteEnabled(!isInvestorNoteEnabled)}
              >
                <FontAwesomeIcon
                  icon={["fas", `${isInvestorNoteEnabled ? "minus" : "plus"}`]}
                  className="mr-1"
                />
                {isInvestorNoteEnabled ? lang.removeNote : lang.addANote}
              </div>
            </div>
          </div>
        </div>
        {isInvestorInputShown && (
          <CustomInvestor
            onCustomInvestorAdded={handleAddCustomInvestor}
            onCustomInvestorCancel={handleCustomInvestorCancel}
            register={register}
            errors={errors}
          />
        )}

        {investors && investors.length > 0 && (
          <div className="mt-4 mb-5 sm:grid sm:gap-8 sm:items-start">
            <div className="block grid-cols-2 gap-6 sm:mt-0 sm:col-span-1 sm:grid">
              <Investors
                investors={investors}
                onRemoveInvestor={(index: number) => {
                  const newInvestors = [...investors];
                  newInvestors.splice(index, 1);
                  setValue("investors", newInvestors);
                }}
              />
            </div>
          </div>
        )}

        {isInvestorNoteEnabled && (
          <>
            <TextArea
              rows="3"
              id="investorNote"
              className={cn(
                "form-textarea mt-4 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm",
                errors.investorNote
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
                  : ""
              )}
              placeholder={lang.addAdditionalNotesAboutYourFundRaising}
              {...register("investorNote", { required: true })}
              maxLength={200}
            ></TextArea>
            {errors.investorNote && (
              <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
            )}
          </>
        )}
      </div>

      <StepActionButtonStateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButtonStateful>
    </form>
  );
};

export default FundraisingForm;
