import { FC } from "react";
import PlanToggle from "./PlanToggle";
import usePlan from "../../hooks/usePlan";

const Header: FC = () => {
  const {
    header: { userType, description },
  } = usePlan();

  return (
    <div className="flex flex-col items-center justify-between w-full mb-5 lg:items-start lg:flex-row lg:mb-0">
      <div>
        <h1 className="text-2xl font-thin text-center sm:text-left sm:text-3xl lg:4xl">
          {userType} - Plans & Pricing
        </h1>
        <p className="text-md font-[#848199] text-center sm:text-left font-thin mt-2 mb-5 sm:mb-0">
          {description}
        </p>
      </div>
      <PlanToggle />
    </div>
  );
};

export default Header;
