import { useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Truncate from "react-truncate";
import Button from "atoms/Button/Button";
import Loading from "atoms/Loading/Loading";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import { useGuard } from "hooks/useGuard";
import { useLang } from "core/lang/LangaugeSetting";
import { animation } from "ui/animations";
import { getFormattedDate } from "utils/generalUtils";
import { InfoLink, SectionAnimation } from "./PendingApplications.style";
import DownloadApplicationModal from "./DownloadApplicationModal";
import { MustBeAccelerator } from "core/routePolicies";
import { gql, useQuery } from "@apollo/client";

const colors = ["#18beb3", "#16192F"];

const LIMIT = 25;

const LIST_PENDING_APPLICATIONS = gql`
  query getPendingApplications($start: Int, $limit: Int) {
    getPendingApplications(start: $start, limit: $limit) {
      totalCount
      __typename
      nodes {
        id
        company {
          displayName
          email
          phone
          createdAt
          logo {
            small {
              location
            }
          }
        }
      }
    }
  }
`;

const enableBody = (target: any) => enableBodyScroll(target);

export default () => {
  useGuard([MustBeAccelerator]);

  const { lang } = useLang();

  const [pendingApplications, setPendingApplications] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [isDownloadExcelModalOpen, setIsDownloadExcelModalOpen] =
    useState(false);

  const { data, loading, fetchMore } = useQuery(LIST_PENDING_APPLICATIONS, {
    variables: {
      limit: LIMIT,
      start: 0,
    },
    onCompleted: (data) => {
      setPendingApplications(
        data?.getPendingApplications.nodes.map((node: any) => node.company)
      );
      setTotalCount(data?.getPendingApplications?.totalCount);
    },
    notifyOnNetworkStatusChange: true,
  });

  const fetchMoreApplications = () => {
    fetchMore({
      variables: { start: pendingApplications.length, limit: LIMIT },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) return prev;

        return {
          getPendingApplications: {
            __typename: "PendingApplicationsPaginateResult",
            nodes: [
              ...prev?.getPendingApplications?.nodes,
              ...fetchMoreResult.getPendingApplications?.nodes,
            ],
            totalCount: prev?.getPendingApplications?.totalCount,
          },
        };
      },
    });
  };

  const openModal = () => {
    setIsDownloadExcelModalOpen(true);
    disableBodyScroll(document.querySelector("#applicationList"));
  };

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="p-8 bg-gray-100"
    >
      <SectionAnimation variants={animation} animate="show" initial="hidden">
        <div className="relative">
          {loading && (
            <div>
              <Loading fullPage position="absolute" />
            </div>
          )}
          {isDownloadExcelModalOpen && (
            <DownloadApplicationModal
              closeModal={() => {
                setIsDownloadExcelModalOpen(false);
                enableBody(document.querySelector("#applicationList"));
              }}
            />
          )}
          <div className="flex items-center justify-between pb-4 mt-4 mb-5 border-b">
            <h1 className="text-xs font-medium lg:text-xl">
              {`${
                lang.pendingApplicationsFromStatups
              } ${`(${pendingApplications.length}/${totalCount})`}`}
            </h1>
            <div>
              <Button color={"primary"} onClick={openModal}>
                Export data
              </Button>
            </div>
          </div>
          <div id="applicationList" className="overflow-auto">
            <table className="w-full table-auto min-w-max">
              <thead>
                <tr className="text-sm font-normal capitalize">
                  <th className="px-6 py-3 font-medium text-left text-gray-700">
                    {lang.startupName}
                  </th>
                  <th className="px-6 py-3 font-medium text-left text-gray-700">
                    {lang.email}
                  </th>
                  <th className="px-6 py-3 font-medium text-left text-gray-700">
                    {lang.phone}
                  </th>
                  <th className="px-6 py-3 font-medium text-left text-gray-700">
                    {lang.createdAt}
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-600 bg-white rounded-md">
                {pendingApplications.length === 0 && (
                  <tr>
                    <td
                      colSpan={5}
                      className="sticky right-0 w-20 px-3 py-2 text-center text-black bg-white border-l border-gray-100"
                    >
                      {lang.noRecordFound}
                    </td>
                  </tr>
                )}

                {pendingApplications.map((member: any, index: any) => {
                  const logo = member?.logo?.small?.location;
                  const color = colors[index % 2];
                  return (
                    <tr
                      className="bg-white border-b border-gray-200"
                      key={index}
                    >
                      <td className="flex items-center px-6 py-3 text-left cursor-auto whitespace-nowrap">
                        {logo && (
                          <img
                            className="object-cover w-10 h-10 mr-2 rounded-full"
                            src={logo}
                            alt=""
                          />
                        )}
                        {!logo && (
                          <span
                            className={`mr-2 inline-flex items-center justify-center h-10 w-10 rounded-full object-cover flex-shrink-0`}
                            style={{ backgroundColor: color }}
                          >
                            <span className="font-medium leading-none text-white">
                              {member?.displayName?.charAt(0)?.toUpperCase()}
                            </span>
                          </span>
                        )}
                        <Truncate lines={1}>{member.displayName}</Truncate>
                      </td>
                      <td className="px-6 py-3 text-left cursor-auto">
                        {member.email}
                      </td>
                      <td className="px-6 py-3 text-left cursor-auto">
                        <InfoLink
                          color="#18BEB3"
                          href={`tel:${member.phone}`}
                          target="_blank"
                        >
                          {member.phone}
                        </InfoLink>
                      </td>
                      <td className="px-6 py-3 text-left cursor-auto">
                        {member.createdAt && getFormattedDate(member.createdAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {totalCount > pendingApplications.length && (
                <tfoot>
                  <tr style={{ background: "#f3fcfb" }}>
                    <td
                      style={{ background: "#f3fcfb" }}
                      className="px-6 py-3 text-center capitalize whitespace-nowrap text-aqua-400"
                      colSpan={4}
                      onClick={fetchMoreApplications}
                    >
                      {`${lang.view} ${LIMIT} ${lang.more}`}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </SectionAnimation>
    </Page>
  );
};
