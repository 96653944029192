import tw from "twin.macro";
import styled, { css } from "styled-components";

export const TypeSelection = styled.div<any>(({ companyType }) => [
  tw`mt-4 flex items-center mr-4 cursor-pointer`,
  css`
    margin-top: 0;
    input {
      &:checked + label {
        background: ${companyType === "startup" ? "#ff8060" : "#18beb3"};
        border-color: ${companyType === "startup" ? "#ff8060" : "#18beb3"};
      }
      &:hover:label {
        background: #e5e7eb;
      }
    }
  `,
]);

export default ({ checked, label1, label2, companyType, ...props }: any) => (
  <div className={`flex items-center ${companyType !== "startup" && "mb-4"}`}>
    <TypeSelection className="__custom_input" companyType={companyType}>
      <input
        {...props}
        id="yes"
        type="radio"
        className="w-4 h-4 cursor-pointer form-radio transition duration-150 ease-in-out"
        value={"true"}
        checked={checked == "true"}
      />
      <label htmlFor="yes" className="ml-2 cursor-pointer hover:bg-gray-200 ">
        <span className="block text-sm leading-5">{label1}</span>
      </label>
    </TypeSelection>
    <TypeSelection className="__custom_input" companyType={companyType}>
      <input
        {...props}
        id="no"
        type="radio"
        className="w-4 h-4 cursor-pointer form-radio transition duration-150 ease-in-out"
        value={"false"}
        checked={checked == "false"}
      />
      <label htmlFor="no" className="ml-2 cursor-pointer hover:bg-gray-200">
        <span className="block text-sm leading-5 ">{label2}</span>
      </label>
    </TypeSelection>
  </div>
);
