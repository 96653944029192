import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import { setGlobalState } from "models/globalState/sagaActions";
import { getUser, getWorkspaces } from "models/user/selectors";
import { GET_STARTUP_BOARDS } from "queries/investor/boards";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomState } from "../selectors";
import { updateCustomState } from "../reducer";

const useStartupsBoard = () => {
  const dispatch = useDispatch();
  const state = useSelector(getCustomState);
  const user = useSelector(getUser);
  const companyId = user?.id;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    data: { getStartupBoards: startupBoards = [] } = {},
    loading: isBoardsLoading,
    refetch,
  } = useQuery(GET_STARTUP_BOARDS);

  const activeWorkspace = useSelector(getWorkspaces)?.find(
    (ws: any) => ws.isActive
  );

  const isOnProPlan =
    activeWorkspace?.permissions &&
    activeWorkspace.permissions.includes("BOARD_ANALYTICS");

  const updateGlobalState = (params: any) => {
    dispatch(setGlobalState(params));
  };

  const setState = (params: any) => {
    dispatch(updateCustomState(params));
  };

  const onEditButtonClicked = () => {
    setState({ isEditBoardModalOpen: true });
  };

  const onShareButtonClicked = () => {
    updateGlobalState({ isPublicShareModal: true });
  };

  const onMoreButtonClicked = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onCloseModalClicked = () => {
    setState({ isEditBoardModalOpen: false });
  };

  useEffect(() => {
    // allows opening links in new window
    DOMPurify.setConfig({ ADD_ATTR: ["target"] });
  }, []);

  useEffect(() => {
    if (!isBoardsLoading && startupBoards?.length === 0) {
      setState({ isEditBoardModalOpen: true });
    }
  }, [isBoardsLoading, startupBoards]);

  return {
    state,
    isMenuOpen,
    startupBoards,
    isBoardsLoading,
    companyId,
    isOnProPlan,
    refetch,
    setIsMenuOpen,
    onEditButtonClicked,
    onShareButtonClicked,
    onMoreButtonClicked,
    onCloseModalClicked,
  };
};

export default useStartupsBoard;
