import { moduleTypes } from "core/modules";

const module = [
  {
    path: "/boards",
    key: "boards",
    componentId: "boards",
    component: import(/* webpackChunkName: "startupsBoard" */ "./PrivateStartupsBoards"),
    routes: [],
    module: moduleTypes.STARTUPS_BOARDS,
    isEnabled: () => true,
    importReducer: () => [],
    importSagas: () => [],
    title: "Boards",
    exact: true,
    name: "Boards",
  },
];

export default module;
