import type { FC } from "react";
import { FilterPanel, TableView } from "./components";
import { PreviewSidebar, NotesSidebar } from "../components/Sidebar";
import { RecordModal } from "./components/RecordModal";
import { useDealflow } from "./hooks";
import { InviteModal } from "./components/InviteModal";
import { BottomPanel } from "./components/BottomPanel";

const InvestorDealflow: FC = () => {
  const {
    isLoading,
    isNoResults,
    isSearchEmpty,
    isShowModal,
    isAddModal,
    isInviteModal,
    columns,
    myCohorts,
    records,
    invitationLink,
    query,
    page,
    total,
    totalPages,
    sections,
    industries,
    sort,
    showingFrom,
    showingTo,
    selectedRecord,
    previewRecord,
    editRecord,
    userCreatedAt,
    refetch,
    handleQueryChange,
    handleCohortsChange,
    handleSectionsChange,
    handleIndustriesChange,
    handlePageChange,
    handleGotoCohorts,
    handleSortChange,
    handleOpenModal,
    handleCloseModal,
    handleColumnsChange,
    handleSelect,
    handleCloseSidebar,
    handleClosePreview,
    handleOpenAddModal,
    handleCloseAddModal,
    handleCloseInviteModal,
    handleEdit,
  } = useDealflow();

  return (
    <div className="w-full h-screen flex flex-col justify-between">
      <FilterPanel
        isLoading={isLoading}
        cohorts={myCohorts}
        sections={sections}
        industries={industries}
        onCohortsChange={handleCohortsChange}
        onSectionsChange={handleSectionsChange}
        onIndustriesChange={handleIndustriesChange}
        query={query}
        onQueryChange={handleQueryChange}
        onGotoCohorts={handleGotoCohorts}
        onCloseModal={handleCloseModal}
        onShowModal={handleOpenModal}
        onAddCompany={handleOpenAddModal}
        isShowModal={isShowModal}
      />
      <RecordModal
        refetch={refetch}
        show={isAddModal}
        onClose={handleCloseAddModal}
        record={editRecord ?? null}
      />
      <InviteModal
        show={isInviteModal}
        onClose={handleCloseInviteModal}
        invitationLink={invitationLink}
      />
      {/* Empty div for placement of the activation tooltip */}
      <div className="dealflow-table h-0" />
      <div className="flex items-start w-full invisible-scroll overflow-y-scroll grow border no-scrollbar invisible-scroll">
        <TableView
          sort={sort}
          onSortChange={handleSortChange}
          onColumnsChange={handleColumnsChange}
          onSelect={handleSelect}
          selected={selectedRecord}
          sections={sections}
          columns={columns}
          records={records}
          preview={previewRecord}
          isLoading={isLoading}
          isNoResults={isNoResults}
          isSearchEmpty={isSearchEmpty}
          onShowModal={handleOpenModal}
          onCloseModal={handleCloseModal}
          onEdit={handleEdit}
          refetch={refetch}
        />
        <PreviewSidebar record={previewRecord} onClose={handleClosePreview} />
        <NotesSidebar
          sections={sections}
          record={selectedRecord}
          onClose={handleCloseSidebar}
          refetch={refetch}
        />
      </div>
      <BottomPanel
        showingFrom={showingFrom}
        showingTo={showingTo}
        total={total}
        isLoading={isLoading}
        totalPages={totalPages}
        page={page}
        onPageChange={handlePageChange}
      />
      {/* Disabling activation for investors */}
      {/* <DealflowActivationWrapper userType="investor" /> */}
    </div>
  );
};

export default InvestorDealflow;
