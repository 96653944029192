import { FC, Fragment, useEffect, useRef, useState } from "react";
import type { FinalStepProps } from "./interfaces";
import Swiper from "react-id-swiper";
import { Transition } from "@headlessui/react";
import { useSteps } from "../hooks";
import * as Startup from "./teaser/startup";
import * as Investor from "./teaser/investor";
import cn from "classnames";
import { FloatImageDecor } from "./decors";
import { ReactComponent as TickMark } from "images/tick-mark.svg";
import { TeaserStepProps } from "./teaser";
import Loading from "atoms/Loading/Loading";
import { useMutation } from "@apollo/client";
import { ONBOARD_INVESTOR, ONBOARD_STARTUP } from "queries/onboard/onboard";
import { getInvestorFields, getStartupFields } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useNavigate } from "react-router-dom";
import { refetchUserData } from "models/user/sagaActions";
import { getUser } from "models/user/selectors";

const STARTUP_STEPS = [
  "welcome",
  "basics",
  "investment",
  "team",
  "elevator_pitch",
  "pitch_deck",
] as const;

const INVESTOR_STEPS = ["welcome"] as const;

const getStartupContent = (props: TeaserStepProps) =>
  [
    ["welcome", <Startup.WelcomeTeaser {...props} />],
    ["basics", <Startup.BasicsTeaser {...props} />],
    ["investment", <Startup.InvestmentTeaser {...props} />],
    ["team", <Startup.TeamTeaser {...props} />],
    ["elevator_pitch", <Startup.ElevatorPitchTeaser {...props} />],
    ["pitch_deck", <Startup.PitchDeckTeaser {...props} />],
  ] as const;

const getInvestorContent = (props: TeaserStepProps) =>
  [["welcome", <Investor.Welcome {...props} />]] as const;

const TeaserStep: FC<FinalStepProps> = ({ show, payload, onFinish }) => {
  const isStartup = payload.type === "startup";
  const isAccelerator = payload.type === "accelerator";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const redirectTo = useRef("");
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [onboardStartup] = useMutation(ONBOARD_STARTUP);
  const [onboardInvestor] = useMutation(ONBOARD_INVESTOR);
  const {
    steps,
    next,
    prev,
    active,
    isActive,
    isPassed,
    payload: teaserPayload,
  } = useSteps(isStartup ? STARTUP_STEPS : INVESTOR_STEPS, {
    onFinish: async (payload) => {
      setIsLoading(true);

      try {
        if (isStartup) {
          await onboardStartup({
            variables: getStartupFields(payload),
          });
          redirectTo.current = "/company-profile";
        } else {
          await onboardInvestor({
            variables: getInvestorFields(payload, isAccelerator),
          });
          redirectTo.current = "/aia";
        }

        if (onFinish) {
          onFinish();
        } else {
          dispatch(refetchUserData({}));
        }
      } catch (error) {
        dispatch(
          triggerSnack({
            type: "error",
            title: "Oops, something went wrong",
            message: "An error occurred, please try again",
          })
        );
      }

      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (user && redirectTo.current) {
      navigate(redirectTo.current);
    }
  }, [navigate, user]);

  useEffect(() => {
    if (ref.current) ref.current?.scrollTo(0, 0);
  }, [active]);

  const content = isStartup
    ? getStartupContent({
        onNext: next,
        onPrev: prev,
        type: payload.type || "startup",
      })
    : getInvestorContent({
        onNext: next,
        onPrev: prev,
        type: payload.type || "investor",
        teaserPayload,
      });

  const getElement = (activeKey: string) => {
    const el = content.find((i) => i[0] === activeKey);
    if (el) {
      return el[1];
    } else return null;
  };

  return (
    <>
      {show && <FloatImageDecor active={active} />}
      <Transition show={show}>
        <Transition.Child
          className="relative z-30 flex flex-col items-center justify-center w-screen h-screen transition delay-500"
          enter="easy-in-out duration-500"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="easy-in-out duration-500"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
        >
          <div className="w-[600px] max-w-full mt-10 md:mt-0">
            <div className="w-full py-4 px-4 border-b border-gray-200 text-gray-800 bg-white rounded-t-lg min-h-[64px]">
              {steps.length > 1 && (
                <div
                  className={cn(
                    "flex items-center justify-center",
                    isLoading && "hidden"
                  )}
                >
                  {steps.map((step, index) => (
                    <Fragment key={step}>
                      {index > 0 && (
                        <div
                          className={cn(
                            "w-6 md:w-12 h-[2px] mx-2",
                            isPassed(step) || isActive(step)
                              ? "bg-[#5EC253]"
                              : "bg-gray-300"
                          )}
                        ></div>
                      )}
                      <div
                        className={cn(
                          "flex items-center justify-center w-6 h-6 border-2 rounded-full transition duration-300 p-1",
                          isActive(step)
                            ? "border-gray-600"
                            : isPassed(step)
                            ? "border-[#5EC253] bg-[#5EC253]"
                            : "border-gray-300"
                        )}
                      >
                        {isActive(step) ? (
                          <div
                            className={cn(
                              "w-3 h-3 rounded-full min-w-[0.75rem]",
                              isActive(step) ? "bg-gray-600" : "bg-[#5EC253]"
                            )}
                          ></div>
                        ) : (
                          isPassed(step) && (
                            <TickMark className="w-3 text-white" />
                          )
                        )}
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
              {isLoading && (
                <div className="flex items-center justify-center w-full text-lg">
                  <span className="mr-4 font-bold text-aqua-400">
                    You'll join the Network in a moment
                  </span>
                  <Loading size="medium" />
                </div>
              )}
            </div>
            <div
              className={cn(
                "flex flex-col relative items-center w-full text-gray-800 bg-white rounded-b-lg h-[80vh] max-h-[80vh] md:h-[70vh] md:max-h-[70vh] overflow-hidden",
                isLoading && "pointer-events-none opacity-60"
              )}
            >
              {/* <Swiper
                activeSlideKey={active}
                wrapperClass="swiper-wrapper items-start border border-red-300"
                cssMode
              >
                {content.map(([key, element]) => (
                  <div
                    key={key}
                    className="max-h-[80vh] md:max-h-[70vh] overflow-y-auto"
                  >
                    {element}
                  </div>
                ))}
              </Swiper> */}
              <div
                className="max-h-[80vh] md:max-h-[70vh] overflow-y-auto w-full relative"
                ref={ref}
              >
                {getElement(active)}
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};

export default TeaserStep;
