import { gql } from "@apollo/client";

export const GET_DISCOVER_COMPANIES = gql`
  query adminDiscoverCompanies(
    $page: Int
    $perPage: Int
    $query: String
    $status: DiscoverFeatureStatus
    $type: AccountType
    $sort: AdminDiscoverCompanySortConfig
  ) {
    adminDiscoverCompanies(
      page: $page
      perPage: $perPage
      query: $query
      status: $status
      type: $type
      sort: $sort
    ) {
      companies {
        _id
        id
        displayName
        discoverFeatureStatus
        discoverApplicationDate
        createdAt
        email
        website
        type
        subscription {
          id
          status
        }
        isGem
        logo {
          small {
            location
          }
        }
        slug
        ... on Startup {
          referrer
        }
      }
      investorsCount
      startupsCount
    }
  }
`;

export const APPROVE_COMPANY = gql`
  mutation adminDiscoverApprove($companyId: String!) {
    adminDiscoverApprove(companyId: $companyId)
  }
`;

export const DECLINE_COMPANY = gql`
  mutation adminDiscoverDecline($companyId: String!) {
    adminDiscoverDecline(companyId: $companyId)
  }
`;

export const SET_IS_GEM = gql`
  mutation adminDiscoverSetIsGem($companyId: String!, $value: Boolean!) {
    adminDiscoverSetIsGem(companyId: $companyId, value: $value)
  }
`;

export const START_TRIAL = gql`
  mutation adminStartTrial($companyId: String!) {
    adminStartTrial(companyId: $companyId)
  }
`;

export const CANCEL_TRIAL = gql`
  mutation adminCancelTrial($subscriptionId: String!) {
    adminCancelTrial(subscriptionId: $subscriptionId)
  }
`;