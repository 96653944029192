import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import PublicCompanyProfile from "./PublicCompanyProfile";
import CompanyProfile from "./CompanyProfile";

const RedirectCompanyProfile = (props: any) => {
  const userData = useSelector(getUser);

  return userData ? (
    <CompanyProfile {...props} />
  ) : (
    <PublicCompanyProfile {...props} />
  );
};

export default RedirectCompanyProfile;
