// @ts-nocheck
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLang } from "core/lang/LangaugeSetting";
import { getSocialNetworks } from "./selectors";
import { PageHeader, StepActionButton } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { saveSocialNetworks } from "./sagaActions";
import FloatingLabelInput from "atoms/Form/FloatingLabelInput";
import { URL_PATTERN } from "core/consts";

//@ts-ignore
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
//@ts-ignore
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
//@ts-ignore
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
//@ts-ignore
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
//@ts-ignore
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
//@ts-ignore
import { ReactComponent as SocialNetworkIcon } from "images/social-network-icon.svg";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";

type FormValues = {
  youtube: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
};

export default () => {
  const data = useSelector(getSocialNetworks);
  const loading = useSelector((state) =>
    isLoadingEnabled(state, STEP.SOCIAL_NETWORKS)
  );
  const {
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: data });

  const { lang } = useLang();
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(
      saveSocialNetworks({
        lang,
        input: data,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <SocialNetworkIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.socialNetworks}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p> {lang.companySocialNetworkDesc}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <div className="mb-7">
          <FloatingLabelInput
            name="linkedin"
            id="linkedin"
            type="text"
            reference={{
              ...register("linkedin", { pattern: URL_PATTERN }),
              placeholder: lang.linkedin,
            }}
            errors={errors.linkedin && lang.websiteValidation}
            icon={<LinkedinIcon />}
            unregister={unregister}
          />
        </div>

        <div className="mb-7">
          <FloatingLabelInput
            name="facebook"
            id="facebook"
            type="text"
            reference={{
              ...register("facebook", { pattern: URL_PATTERN }),
              placeholder: lang.facebook,
            }}
            errors={errors.facebook && lang.websiteValidation}
            icon={<FacebookIcon />}
            unregister={unregister}
          />
        </div>

        <div className="mb-7">
          <FloatingLabelInput
            name="twitter"
            id="twitter"
            type="text"
            reference={{
              ...register("twitter", { pattern: URL_PATTERN }),
              placeholder: lang.twitter,
            }}
            errors={errors.twitter && lang.websiteValidation}
            icon={<TwitterIcon />}
            unregister={unregister}
            // handleUpdateVal={(val)=>handleUpdateVal(val, `name-${index}`, index)}
          />
        </div>

        <div className="mb-7">
          <FloatingLabelInput
            name="instagram"
            id="instagram"
            type="text"
            reference={{
              ...register("instagram", { pattern: URL_PATTERN }),
              placeholder: lang.instagram,
            }}
            errors={errors.instagram && lang.websiteValidation}
            icon={<InstagramIcon />}
            unregister={unregister}
          />
        </div>

        <div className="mb-7">
          <FloatingLabelInput
            name="youtube"
            id="youtube"
            type="text"
            reference={{
              ...register("youtube", { pattern: URL_PATTERN }),
              placeholder: lang.youtube,
            }}
            errors={errors.youtube && lang.websiteValidation}
            icon={<YoutubeIcon />}
            unregister={unregister}
          />
        </div>
      </div>
      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
