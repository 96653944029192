import Loading from "atoms/Loading/Loading";
import { isLoadingEnabled } from "models/loaders/selectors";
import { Invitation } from "pages/invitation/types";
import { BackgroundGradient, HeaderDecor } from "pages/onboard/desktop/decors";
import { FC } from "react";
import { useSelector } from "react-redux";
import InvitationForm from "./InvitationForm";
import CircleDecor from "../auth/LoginPage/components/CircleDecor";
import { INVITATION } from "pages/invitation/constants";
import ExpiredInvitation from "./ExpiredInvitation";
import { Navigate } from "react-router-dom";

const InvitationPage: FC = () => {
  const isLoading = useSelector((state) => isLoadingEnabled(state, INVITATION));
  const invitation: Invitation = useSelector((state: any) => state.invitation);

  if (isLoading) return <Loading fullPage />;

  /**
   * This is to prevent a user copy and paste a route like http://localhost:3000/login/invitation/60555e34 into the browser.
   * The flow must start from http://localhost:3000/invitation/60555e34 route.
   */
  if (!invitation.invitationId) return <Navigate to="/not-found" replace />;

  return (
    <div className="relative w-screen h-screen text-white md:overflow-hidden flex flex-col items-center justify-center">
      <BackgroundGradient type={"login"} />
      <HeaderDecor spread={false} />
      <CircleDecor />
      {invitation?.invitationStatus !== "PENDING" ? (
        <ExpiredInvitation
          header={invitation?.inviterCompanyName}
          logo={invitation?.inviterCompanyLogo}
          invitationStatus={invitation?.invitationStatus}
        />
      ) : (
        <InvitationForm invitation={invitation} />
      )}
    </div>
  );
};

export default InvitationPage;
