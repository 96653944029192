import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, PropsWithChildren, useState } from "react";

const CollapsiblePage: FC<
  PropsWithChildren<{ title: string; className?: string }>
> = ({ title, className, children }) => {
  const [isCollaped, setIsCollaped] = useState(false);

  return (
    <div className={className}>
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={["fas", isCollaped ? "caret-down" : "caret-right"]}
          size="lg"
          className="cursor-pointer !w-4"
          onClick={() => setIsCollaped((prev) => !prev)}
        />
        <h3 className="ml-2">{title}</h3>
      </div>
      {isCollaped && <div className="ml-6">{children}</div>}
    </div>
  );
};

export default CollapsiblePage;
