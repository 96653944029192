import { FC } from "react";
import cn from "classnames";
import { PlanFeaturesProps } from "../interfaces";

const PlanFeatures: FC<PlanFeaturesProps> = ({
  features,
  renderFeature,
  className,
}: PlanFeaturesProps) => (
  <div className={cn("z-20", className)}>
    {features.map((feature) => renderFeature(feature))}
  </div>
);

export default PlanFeatures;
