import { useQuery } from "@apollo/client";
import { AiaRateLimit, GetAiaRateLimitsQuery } from "gql/graphql";
import { GET_RATE_LIMITS } from "queries/aia/aia-rate-limits";

export const formatAiaRates = (aiaRates: AiaRateLimit[]) => {
  return aiaRates?.reduce((obj: { [key: string]: any }, item) => {
    if (item && item.feature) {
      obj[item.feature] = {
        limit: item.limit,
        remaining: item.remaining,
        timeUntilReset: item.timeUntilReset,
        interval: item.interval,
      };
    }
    return obj;
  }, {});
};

export const useAiaRateLimits = (companyId: string, features: string[]) => {
  const {
    data: aiaRates,
    loading: isRatesLoading,
    refetch,
  } = useQuery<GetAiaRateLimitsQuery>(GET_RATE_LIMITS, {
    variables: {
      companyId: companyId,
      features: features,
    },
    fetchPolicy: "network-only",
  });

  const formattedAiaRates = formatAiaRates(
    aiaRates?.aiaRateLimits?.aiaRateLimits as AiaRateLimit[]
  );

  return { formattedAiaRates, isRatesLoading, refetch };
};
