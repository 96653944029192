import type { TableProps, WithId } from "./interfaces";
import { useEffect, useMemo, useState } from "react";
import { get as getProperty } from "lodash";

const ASCENDING_SORT = 1;
const REVERSE_SORT = -1;
const ANY_INTERACTIVE_SELECTOR =
  "input, a, select, button, textarea, label, [role=option], .ignore-click";

const useTable = <RecordData extends WithId>({
  rows,
  columns,
  cellRenderers,
  onCheck,
  isLoading,
  onClick,
  onSort,
  sort,
  highlightIds = [],
  isFrozen,
}: TableProps<RecordData>) => {
  const [checkState, setCheckState] = useState<Record<string, boolean>>({});
  const [expandState, setExpandState] = useState<Record<string, boolean>>({});
  const finalCheckState = useMemo(
    () =>
      rows.reduce((result, row) => {
        result[row.id] = Boolean(checkState[row.id]);

        return result;
      }, {} as Record<string, boolean>),
    [checkState, rows]
  );
  const [isHeadChecked, setIsHeadChecked] = useState(false);
  const isSomeChecked = Object.values(finalCheckState).some((value) => value);
  const isSelectable = Boolean(onCheck);
  const isMobile = window.matchMedia("(max-width: 1024px)").matches;
  const isBodyFrozen = isFrozen;

  useEffect(() => {
    setIsHeadChecked(isSomeChecked);
  }, [isSomeChecked]);

  useEffect(() => {
    const checkedRows = Object.keys(finalCheckState)
      .filter((key) => finalCheckState[key])
      .map((id) => rows.find((row) => row.id === id))
      .filter((row) => row) as RecordData[];

    onCheck?.(checkedRows);
  }, [finalCheckState]);

  const getIsChecked = (key: string) => Boolean(finalCheckState[key]);

  const getIsExpanded = (key: string) => Boolean(expandState[key]);

  const getIsSortable = (key: string) =>
    Boolean(columns.find((column) => column.key === key)?.isSortable);

  const getIsHighlighted = (key: string) => highlightIds.includes(key);

  const getHandleClick =
    (row: RecordData) =>
    ({ target }: React.MouseEvent) => {
      if (!onClick || isMobile) {
        return;
      }

      const element = target as HTMLElement;

      if (element.closest(ANY_INTERACTIVE_SELECTOR)) {
        return;
      }

      onClick(row);
    };

  const getHandleSort = (key: string) =>
    getIsSortable(key) && sort && onSort
      ? () =>
          onSort({
            key: key,
            order:
              sort.key === key ? sort.order * REVERSE_SORT : ASCENDING_SORT,
          })
      : undefined;

  const getHandleCheck = (key: string) => (checked: boolean) =>
    setCheckState((prev) => ({
      ...prev,
      [key]: checked,
    }));

  const getHandleExpand = (key: string) => () =>
    setExpandState((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));

  const handleHeadChecked = () => {
    if (isSomeChecked) {
      setCheckState({});
    } else {
      setCheckState(
        rows.reduce((result, row) => {
          result[row.id] = true;

          return result;
        }, {} as Record<string, boolean>)
      );
    }
  };

  const renderCell = (key: string, row: RecordData, index: number) => {
    if (key in cellRenderers) {
      return cellRenderers[key](row, index);
    }

    return <span>{String(getProperty(row, key) || "-")}</span>;
  };

  return {
    rows,
    columns,
    sort,
    isLoading,
    isSelectable,
    isHeadChecked,
    isBodyFrozen,
    renderCell,
    handleHeadChecked,
    getIsChecked,
    getIsSortable,
    getIsExpanded,
    getIsHighlighted,
    getHandleClick,
    getHandleSort,
    getHandleCheck,
    getHandleExpand,
  };
};

export default useTable;
