import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { RecurringRevenue as GmvGsvType } from "gql/graphql";
import { PERIODICITY } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  gmvGsv: Array<GmvGsvType | null> | null | undefined;
  note: string | null | undefined;
  currencyCode: string;
}

const GmvGsv: FC<Props> = ({ gmvGsv, note, currencyCode }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const gmvGsvData = gmvGsv?.[0];

  const renderContent = () => {
    return (
      <div>
        {gmvGsvData?.amount && gmvGsvData.time ? (
          <div>
            {formatCurrencyValues(Number(gmvGsvData.amount), currencyCode)}{" "}
            {PERIODICITY[gmvGsvData.time]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, dropdownValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          smv_gsv: {
            amount: inputValue,
            time: dropdownValue,
            // this won't affect functionality but still good to update the DB
            currency: currencyCode,
          },
          smv_gsvNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={gmvGsvData?.amount}
        dropdownDefaultValue={gmvGsvData?.time}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(PERIODICITY).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="GMV/GSV"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={gmvGsvData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default GmvGsv;
