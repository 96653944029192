import type { FC } from "react";
import type { CheckboxProps } from "./interfaces";
import cn from "classnames";

const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  className,
  isError,
  disabled,
}) => (
  <label className={cn("flex items-center cursor-pointer", className)}>
    <input
      type="checkbox"
      checked={checked}
      onChange={({ target }) =>
        disabled ? () => null : onChange(target.checked)
      }
      className="absolute w-0 h-0 opacity-0"
    />
    <span
      className={cn(
        "w-5 h-5 min-w-[1.25rem] border-2 rounded-md transition flex justify-center items-center",
        checked ? "bg-aqua-100 border-aqua-400" : "border-gray-400",
        disabled && !checked
          ? "opacity-50 cursor-not-allowed"
          : "cursor-pointer",
        isError && "border-red-400"
      )}
    >
      <span
        className={cn(
          "w-2 h-2 transition rounded-[3px]",
          checked ? "bg-aqua-400" : "bg-transparent",
          disabled && !checked
            ? "opacity-50 cursor-not-allowed"
            : "cursor-pointer",
          isError && "border-red-400"
        )}
      />
    </span>
    <span className="ml-2">{label}</span>
  </label>
);

export default Checkbox;
