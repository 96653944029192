import styled from "styled-components";
import media from "styled-media-query";

export const KeyFigures = styled.div`
  ${media.greaterThan("medium")`
      
  `}

  h2 {
    margin-bottom: 15px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  p {
    margin-bottom: 5px;
  }
`;

export const Header = styled.div`
  ${media.greaterThan("medium")`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `}

  h2 span {
    font-size: 20px;
    font-weight: 400;
    margin-left: 20px;
    line-height: 1em;
    color: #6b7280;
  }
`;

export const HeaderAction = styled.div`
  color: #6b7280;
  margin-top: 15px;

  ${media.greaterThan("medium")`
    font-size: 20px;
    text-align: right;
    margin-top: 0px;
  `}

  div {
    &:last-child {
      font-size: 15px;
      svg {
        margin-left: 8px;
      }
    }

    &:first-child {
      margin-bottom: 15px;
      span {
        color: #000;
      }
    }
  }
`;

export const FiguresContainer = styled.div`
  margin-top: 30px;

  ${media.greaterThan("medium")`
    margin-top: 30px;
  `}
`;

export const Figure = styled.div``;

export const FiguresLargeRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  ${media.greaterThan("medium")`
    grid-template-columns: repeat(4, minmax(0px, auto));
  `}

  ${Figure} {
    div {
      &:first-child {
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
        white-space: nowrap;

        ${media.greaterThan("medium")`
          font-size: 60px;
          line-height: 60px;
        `}
      }

      &:last-child {
        font-size: 20px;
        color: #6b7280;
      }
    }
  }
`;

export const FiguresSmallRow = styled.div`
  margin-top: 30px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  ${media.greaterThan("medium")`
    margin-top: 0px;
    grid-template-columns: repeat(3, minmax(0px, auto));
  `}
  ${media.between("768px", "1024px")`
  grid-template-columns: repeat(2, minmax(0px, auto));
 `}

  ${Figure} {
    position: relative;
    div {
      &:first-child {
        font-size: 35px;
        line-height: 35px;
        color: #18beb3;
        font-weight: 500;
        ${media.greaterThan("medium")`
          font-size: 26px;
          line-height: 35px;
        `}
        ${media.between("1170px", "1280px")`
        font-size: 30px; 
        `}
        ${media.between("320px", "767px")`
         font-size: 24px;
        `}
      }
    }
    .label {
      text-transform: capitalize;
      font-weight: 500;
      display: flex;
      align-items: center;
      position: relative;
      ${media.between("320px", "767px")`
         justify-content: start;
         font-size: 14px;
         .__arrow{
           svg{
            width: 20px;
            height: 17px;
           }
          }
        `}
      .__arrow {
        cursor: pointer;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #000;
          top: -18px;
          right: 5px;
          z-index: 9999999999999999;
          transaction: 0.5s;
          opacity: 0;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
        &:hover + .hover-desc {
          display: block;
          transaction: 0.5s;
          opacity: 1;
        }
        svg {
          color: #ff8060;
          margin-left: 10px;
        }
      }
    }
    .hover-desc {
      font-size: 12px;
      display: none;
      -webkit-transaction: 0.5s;
      transaction: 0.5s;
      position: absolute;
      background: #000;
      padding: 15px;
      box-shadow: 0px 0px 5px 1px #f0f0f0;
      z-index: 9;
      width: 80%;
      color: #fff !important;
      border-radius: 8px;
      word-wrap: break-word;
      word-break: break-all;
      bottom: 40px;
      text-align: center;
    }
  }
`;

export const ReportsContainer = styled.div`
  margin-top: 60px;

  ${media.greaterThan("medium")`
    margin-top: 120px;
  `}
`;
