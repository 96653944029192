// @ts-nocheck
import Auth from "./auth";
import CompanyProfile from "./companyProfile";
import Admin from "./admin";
import CompanySettings from "./companySettings";
import Messages from "./messages";
import MySpace from "./mySpace";
import InvestorDealflow from "./dealflow/InvestorDealflow";
import StartupDealflow from "./dealflow/StartupDealflow";
import Applications from "./applications";
import PendingApplications from "./pendingApplications";
import PrivateStartupsBoards from "./startupsBoard/PrivateStartupsBoards";
import PublicStartupBoard from "./startupsBoard/PublicStartupBoard";
import Plans from "./plans";
import Onboard from "./onboard";
import Account from "./account";
import Invitation from "./invitation";
import editStartupProfile from "./editStartupProfile";

interface Page {
  module: string;
  path: string;
  key: string;
  componentId: string;
  component: Promise<typeof import("")>;
  routes: never[];
  importReducer: () => any[];
  importSagas: () => any[];
  isEnabled: (state: any) => boolean;
  showHeader: boolean;
  title: string;
  exact: boolean;
  name: string;
}

export const Pages: Page[] = [
  Auth,
  MySpace,
  InvestorDealflow,
  StartupDealflow,
  Applications,
  PendingApplications,
  PrivateStartupsBoards,
  PublicStartupBoard,
  CompanyProfile,
  Admin,
  CompanySettings,
  Messages,
  Plans,
  Account,
  Invitation,
  Onboard,
  editStartupProfile,
];

export const BreadCrumbsPages = [];
