import { FC, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import SaveAndCancelButtons from "../../reusables/SaveAndCancelButtons";
import { InvestorShort, Person } from "gql/graphql";
import { SEARCH_INVESTORS_TO_ADD } from "queries/startup/startupDealflow";
import { useQuery } from "@apollo/client";
import DefaultImage from "images/dealroom-image.png";
import { ReactComponent as CloseIcon } from "images/gray-close-icon.svg";
import { ReactComponent as UserCircleIcon } from "images/user-circle-icon.svg";
import { useLang } from "core/lang/LangaugeSetting";
import CustomInvestor from "./CustomInvestor";
import { nanoid } from "@reduxjs/toolkit";

interface Props {
  investors: Person[] | null | undefined;
}

type AddedInvestor = InvestorShort & { displayName?: string };

const AddInvestorCard: FC<Props> = ({ investors }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [query, setQuery] = useState("");
  const [addedInvestors, setAddedInvestors] = useState<AddedInvestor[]>([]);
  const [isInvestorInputShown, setIsInvestorInputShown] =
    useState<boolean>(false);
  const investorNoteRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();
  const { lang } = useLang();

  const { data: investorsToAdd, previousData } = useQuery(
    SEARCH_INVESTORS_TO_ADD,
    {
      variables: {
        query,
      },
      skip: !query,
    }
  );

  const investorsAdd =
    investorsToAdd?.investorsToAdd || previousData?.investorsToAdd || [];

  const getAllInvestors = () => {
    const result = investors || [];
    const addedInvestorsRefined = addedInvestors.map((i) => {
      return {
        id: i.id,
        name: i.name,
        displayName: i.displayName,
        logo: i.logo?.small?.location,
      };
    });
    return result.concat(addedInvestorsRefined);
  };

  const handleSave = () => {
    const investorNote = investorNoteRef.current?.value;

    dispatch(
      updateStartupProfile({
        investors: getAllInvestors(),
        investorNote,
      })
    );

    setIsEditMode(false);
    setQuery("");
    setAddedInvestors([]);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setQuery("");
    setAddedInvestors([]);
  };

  const handleAddInvestor = (investor: AddedInvestor) => {
    setAddedInvestors((prevState) => [...prevState, investor]);
    setQuery("");
  };

  const handleRemovedAddedInvestor = (id: string | undefined | null) => {
    setAddedInvestors((prevState) => prevState.filter((p) => p.id !== id));
  };

  const handleAddCustomInvestor = (name: string, firm: string) => {
    setAddedInvestors((prevState) => [
      ...prevState,
      { id: nanoid(), name, displayName: firm },
    ]);
    setIsInvestorInputShown(false);
  };

  const handleCustomInvestorCancel = () => {
    setIsInvestorInputShown(false);
  };

  const getEditModeContent = () => {
    const className = "w-full border p-2 bg-blue-50 border-blue-100 rounded-lg";

    return (
      <div className="mt-6 flex flex-col">
        <div>
          <div className="text-base text-gray-500 mb-4">
            Search our network for an investor or add it manually
          </div>
          <label htmlFor="manualSearch" className="text-sm text-gray-500">
            Search or add by investor or firm name
          </label>
          <input
            id="manualSearch"
            type="text"
            className="block w-full p-2 mb-4 mt-1 border rounded"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <div>
            {investorsAdd &&
              investorsAdd.map((investor) => {
                if (investor)
                  return (
                    <div
                      key={investor.id}
                      className="flex items-center justify-between w-full p-2 mt-2 border border-gray-300 rounded-xl"
                    >
                      <div className="flex items-center">
                        <img
                          src={investor.logo?.small?.location || DefaultImage}
                          alt=""
                          className="object-cover w-8 h-8 mr-2 border border-gray-300 rounded-full"
                        />
                        <span>{investor.name}</span>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleAddInvestor(investor)}
                        className="focus:text-aqua-400 flex items-center focus:border-aqua-300 hover:text-aqua-400 hover:border-aqua-300 text-sm px-2 py-0.5 rounded-lg"
                      >
                        <span className="mr-1">Add</span>
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                      </button>
                    </div>
                  );
              })}
          </div>
        </div>

        <div className="text-sm sm:col-span-2 mb-1">
          {lang.canNotFindYourInvestor}
          <span
            className="ml-2 -mb-2 text-sm cursor-pointer text-aqua-400"
            onClick={() => setIsInvestorInputShown(true)}
          >
            {lang.addManually}
          </span>
        </div>

        {isInvestorInputShown && (
          <CustomInvestor
            onCustomInvestorAdded={handleAddCustomInvestor}
            onCustomInvestorCancel={handleCustomInvestorCancel}
          />
        )}

        {addedInvestors && addedInvestors.length > 0 && (
          <div className="flex flex-wrap gap-x-2">
            {addedInvestors.map((i) => (
              <div
                className="flex flex-row bg-slate-800 text-white rounded-full text-sm items-center p-1"
                key={i.id}
              >
                {i.logo?.small?.location ? (
                  <img
                    className="w-8 h-8 mr-2 rounded-full"
                    src={i.logo.small?.location}
                  />
                ) : (
                  <UserCircleIcon className="w-8 h-8" />
                )}
                <div className="mx-2">
                  <div>{i.name}</div>
                </div>
                <CloseIcon
                  className="cursor-pointer mr-2"
                  onClick={() => handleRemovedAddedInvestor(i.id)}
                />
              </div>
            ))}
          </div>
        )}

        <SaveAndCancelButtons
          onSave={handleSave}
          onCancel={handleCancel}
          className="self-end mt-4"
        />
      </div>
    );
  };

  const getNoContentplaceholder = () => (
    <div className="w-full p-4 mt-4 border border-dashed rounded-lg border-aqua-400 bg-white">
      <div className="flex items-center h-full">
        <div className="border border-dashed rounded-full border-aqua-400 w-20 h-20 bg-aqua-50 ml-2 mr-6 shrink-0"></div>
        <div className="cursor-pointer" onClick={() => setIsEditMode(true)}>
          <FontAwesomeIcon
            icon={["fal", "plus-circle"]}
            className="mr-2 text-aqua-400"
          />
          <span className="text-aqua-400">Add former investor</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full mt-2 z-10">
      {isEditMode ? getEditModeContent() : getNoContentplaceholder()}
    </div>
  );
};
export default AddInvestorCard;
