// @ts-nocheck
import tw, { styled, css } from "twin.macro";

export const ModalContent = styled.div(() => [
  css`
    h3 {
      font-size: 23px;
      margin-bottom: 15px;
      font-weight: normal;
    }
    p {
      font-weight: 400;
      line-height: 1.5em;
    }
    button.preview-btn {
      color: #000;
      b {
        color: #18beb3;
      }
    }
  `,
]);

export const SuccessContent = styled.div(() => [
  tw`w-full text-center pt-7 pb-7`,
  css`
    img {
      width: 100px;
      margin: 0 auto;
    }
    h2 {
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    button {
      margin-top: 20px;
    }
  `,
]);

export const ModalHeader = styled.div(() => [
  css`
    margin-bottom: 30px;
  `,
]);
