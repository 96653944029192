// @ts-nocheck
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, select, takeEvery } from "redux-saga/effects";
import { OFFER_MODAL, SEND_OFFER } from "./constants";
import { GET_OFFER, SEND_OFFER_REQUEST } from "./query";
import {
  getOfferData,
  triggerShowOfferRequestVerification,
  updateOfferModalData,
} from "./reducer";
import {
  getOfferById,
  takeAdvantageOfOffer,
  triggerOfferModal,
} from "./sagaActions";
import { getOfferModalId } from "./selectors";

const client = getClient();

function getOffer(id) {
  return client.query({
    query: GET_OFFER,
    variables: {
      id,
    },
  });
}

function takeAdvantage(id) {
  return client.mutate({
    mutation: SEND_OFFER_REQUEST,
    variables: {
      id,
    },
  });
}

function* getOfferUsingId() {
  yield put(toggleLoader({ component: OFFER_MODAL, isLoading: true }));
  const id = yield select((state) => getOfferModalId(state));
  yield put(triggerShowOfferRequestVerification({ isEnabled: false }));
  let { data } = yield getOffer(id);
  yield put(getOfferData({ data }));
  yield put(toggleLoader({ component: OFFER_MODAL, isLoading: false }));
}

function* handleTakeAdvantage({ payload: { lang } }) {
  yield put(toggleLoader({ component: SEND_OFFER, isLoading: true }));
  const id = yield select((state) => getOfferModalId(state));
  try {
    yield takeAdvantage(id);
    yield put(triggerShowOfferRequestVerification({ isEnabled: true }));
    // setGlobalState({ showOfferRequestVerification: true });
  } catch (error) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.faildedToSendExpressionOfInterest,
      })
    );
  }
  yield put(toggleLoader({ component: SEND_OFFER, isLoading: false }));
}

export function* handleOfferModalUpdate({ payload }) {
  yield put(updateOfferModalData(payload));
}

export default function* rootSaga() {
  return [
    yield takeEvery(triggerOfferModal, handleOfferModalUpdate),
    yield takeEvery(getOfferById, getOfferUsingId),
    yield takeEvery(takeAdvantageOfOffer, handleTakeAdvantage),
  ];
}
