import { moduleTypes } from "core/modules";

export default [
  {
    module: moduleTypes.ONBOARD,
    path: "/onboard",
    componentId: "onboard",
    component: import(
      /* webpackChunkName: "onboard" */ "pages/onboard/Onboard"
    ),
    routes: [],
    importReducer: () => [],
    importSagas: () => [],
    isEnabled: () => true,
    title: "Onboarding | Sircular",
    exact: true,
    showHeader: false,
    name: "Onboard",
  },
];
