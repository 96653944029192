import { ReactNode } from "react";

type Props = {
  title: string;
  description: string;
  children: ReactNode;
};

const PageLayout = ({ title, description, children }: Props) => (
  <div
    className="relative w-full pb-10 bg-gray-50"
    style={{
      minHeight: `calc(100vh - 81px)`,
    }}
  >
    <img
      className="absolute w-full h-64 pointer-events-none"
      src={"/assets/images/header-meetings.png"}
      alt="background"
    />
    <div className="relative pt-12 ml-6 mr-6 text-white content-container">
      <h1 className="text-4xl md:text-center">{title}</h1>
      <p className="m-auto mt-2 mb-6 text-white md:text-center md:pt-6 md:max-w-4xl">
        {description}
      </p>
      {children}
    </div>
  </div>
);

export default PageLayout;
