import { FC } from "react";
import StarRating from "./StarRating";
import { ReviewProps } from "./interfaces";

const Review: FC<ReviewProps> = ({ title, rating, userName, index }) => {
  return (
    <div>
      <div className="flex flex-row">
        <img
          src={`https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
            ((index + 1) % 6) + 1
          }.png`}
          className="w-8 h-8 mr-2"
        />
        <div className="flex flex-col">
          <div className="items-center">
            <h1>{userName}</h1>
            <StarRating rating={rating} />
          </div>
          <p className="max-w-xs">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default Review;
