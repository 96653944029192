import { ReactComponent as AppStoreLogo } from "images/appstore-icon.svg";
import { FC } from "react";
import Rating from "./Rating";
import RatingList from "./RatingList";
import Review from "./Review";
import { AppStoreProps } from "./interfaces";
import SimilarAppsAppStore from "./SimilarApps";
import SectionHeader from "../SectionHeader/SectionHeader";

const AppStoreReviews: FC<AppStoreProps> = ({ appstore }) => {
  return (
    <div className="container py-8 mx-auto">
      <div className="flex flex-col xl:flex-row gap-x-8">
        <div className="flex flex-col basis-1/3">
          <div className="flex flex-row items-center w-full gap-2">
            <AppStoreLogo className="w-10 h-10 rounded-full" />
            <SectionHeader title="Appstore" mb={0} />
          </div>
          <div className="flex flex-col xl:flex-row xl:items-center lg:justify-between xl:mt-0 my-6">
            <Rating
              rating={(appstore?.appInfo?.currentVersionScore || 0).toFixed(2)}
              noOfReviews={appstore.appInfo?.numberOfRatings || 0}
              ratingStarsize="lg"
              ratingTextSize="text-4xl"
            />
            <RatingList histogram={appstore?.appInfo?.histogram || []} />
          </div>
        </div>
        {appstore?.reviews && (
          <div className="flex flex-col mb-8 md:mb-0 lg:mt-0 mt-4 basis-1/3">
            <h1 className="mb-2 ml-8 text-base whitespace-nowrap">
              Latest reviews
            </h1>
            <div className="flex flex-col ml-8 gap-y-2">
              {appstore?.reviews?.slice(0, 3).map((review, index) => (
                <div key={review?.userName}>
                  <Review
                    index={index}
                    rating={review?.score || 0}
                    title={review?.title || ""}
                    userName={review?.userName || ""}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="basis-1/3 px-6">
          {appstore?.appInfo?.description && (
            <>
              <h1 className="my-4 xl:my-0 xl:mb-2 text-base whitespace-nowrap">
                Description
              </h1>
              <p className="line-clamp-6 hover:line-clamp-none transition-transform duration-1000 ease-in-out">
                {appstore?.appInfo?.description}
              </p>
            </>
          )}
        </div>
      </div>
      <SimilarAppsAppStore similarApps={appstore?.similarApps || ([] as any)} />
    </div>
  );
};

export default AppStoreReviews;
