import { ChangeEvent, FC, useState } from "react";
import cn from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Button from "atoms/Button/Button";
import Skeleton from "react-loading-skeleton";
import { useMutation, useQuery } from "@apollo/client";
import {
  AddTokenMutation,
  AddTokenMutationVariables,
  GetTokensQuery,
  MeWebhookMutations,
  MeWebhookMutationsAddArgs,
} from "gql/graphql";
import {
  ADD_TOKEN,
  ADD_WEBHOOK,
  GET_TOKENS,
  GET_WEBHOOKS,
} from "queries/general/account/apiDocs";
import Upload from "./upload/Upload";
import Fetch from "./fetch/Fetch";
import axios from "axios";

const ApiSandBox: FC = () => {
  const dispatch = useDispatch();

  const {
    data: tokenData,
    loading,
    refetch: refetchTokens,
  } = useQuery<GetTokensQuery>(GET_TOKENS);

  const {
    data: webhookData,
    loading: webhookLoading,
    error: webhookError,
    refetch: refetchWebhooks,
  } = useQuery(GET_WEBHOOKS);

  const [
    addToken,
    { data: newTokenData, loading: isNewTokenGenerating, error: newTokenError },
  ] = useMutation<AddTokenMutation, AddTokenMutationVariables>(ADD_TOKEN);

  const [
    addWebhook,
    { loading: isNewWebhookGenerating, error: newWebhookError },
  ] = useMutation<MeWebhookMutations, MeWebhookMutationsAddArgs>(ADD_WEBHOOK);

  const [label, setLabel] = useState("");
  const [labelError, setLabelError] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [webhookUrlError, setWebhookUrlError] = useState(false);
  const [webhookSecret, setWebhookSecret] = useState("");
  const [webhookLabel, setWebhookLabel] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [externalId, setExternalId] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [response, setResponse] = useState();

  const onCopy = () => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Code snippet copied to clipboard!",
      })
    );
  };

  const handleAddWebhook = () => {
    if (!webhookUrl) {
      setWebhookUrlError(true);
      return;
    }
    addWebhook({
      variables: {
        input: {
          label: webhookLabel,
          url: webhookUrl,
          secret: webhookSecret,
        },
      },
    });
    refetchWebhooks();
  };

  const onLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
    if (e.target.labels) {
      setLabelError(false);
    }
  };

  const handleGenerateKey = () => {
    if (!label) {
      setLabelError(true);
      return;
    }
    addToken({ variables: { label: label } });
    refetchTokens();
  };

  const onCopyKey = () => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Key copied to clipboard!",
      })
    );
  };

  const handleUpload = (file: File) => {
    setIsUploadLoading(true);
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", externalId);

    const headers = {
      Authorization: "Bearer " + apiKey ?? "apiKey",
    };

    axios
      .post(`${process.env.REACT_APP_REST_URL}/ai/profiles`, formData, {
        headers: headers,
      })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        setResponse(error);
      })
      .finally(() => setIsUploadLoading(false));
  };

  const handleFetch = () => {
    setIsFetchLoading(true);
    const headers = {
      Authorization: "Bearer " + apiKey,
    };

    axios
      .get(`${process.env.REACT_APP_REST_URL}/ai/profiles/${externalId}`, {
        headers: headers,
      })
      .then((response) => {
        setResponse(response.data);
        setIsFetchLoading(false);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setResponse(error.response.data);
        } else {
          setResponse(error.message);
        }
      })
      .finally(() => setIsFetchLoading(false));
  };

  const uploadError = !selectedFile || !apiKey;
  const fetchError = !externalId || !apiKey;

  return (
    <div className="flex xl:flex-row flex-col w-[calc(100vw-250px)] mt-2 mb-6">
      <div className="xl:w-2/3 rounded-md my-2 ml-2 mr-1 flex flex-col xl:h-[calc(100vh-20px)]">
        <div className="px-6">
          <h1 className="text-xl my-2">
            Welcome to Our API Sandbox Environment
          </h1>
          <p className="text-black">
            Our API Sandbox provides a dynamic platform for developers to
            interact with and test our API's capabilities. This environment is
            designed to give you a hands-on experience with our API, allowing
            you to understand its features and integrate them into your
            applications effectively.
          </p>
          <h3 className="mt-4">API Key Generation and Management:</h3>
          <ul className="!list-inside !ml-4 !list-disc">
            <li>
              Securely generate API keys with custom labels for your
              application.
            </li>
            <li>
              For security, each API key is shown only once upon creation.
              Ensure to copy and store it safely.
            </li>
          </ul>
          {tokenData?.me?.tokens && tokenData.me.tokens.length > 0 && (
            <table className="my-4">
              <h2 className="mb-2">Your API keys:</h2>
              {tokenData.me.tokens.map((t) => (
                <tr>
                  <td>{t?.label}</td>
                  <td>
                    <span className="align-sub">*****</span>
                    <span>{t?.tail.slice(-5)}</span>
                  </td>
                </tr>
              ))}
            </table>
          )}
          <div className="mt-4">
            <div className="flex gap-y-2 flex-col w-52">
              <input
                type="text"
                className={cn(
                  "border py-2 px-3 rounded-full",
                  labelError && "border-red-500"
                )}
                placeholder="Enter key's label"
                value={label}
                onChange={onLabelChange}
              />
              <Button
                color="primary"
                onClick={handleGenerateKey}
                disabled={!label}
              >
                Generate API key
              </Button>

              {newTokenData?.me?.tokens?.add?.value && (
                <CopyToClipboard
                  onCopy={onCopyKey}
                  text={newTokenData?.me?.tokens?.add?.value}
                >
                  <div className="flex px-4 py-3 bg-gray-100 rounded-full max-w-md placeholder:text-sm placeholder:italic hover:cursor-pointer">
                    <FontAwesomeIcon icon={["fal", "copy"]} className="!w-3" />
                    <span className="ml-2 text-xs truncate">
                      {newTokenData?.me?.tokens?.add?.value}
                    </span>
                  </div>
                </CopyToClipboard>
              )}
            </div>
            {newTokenData?.me?.tokens?.add?.value && (
              <p className="text-green-500 mt-2">
                For security purposes, this key is only shown to you once and
                will not be accessible once you leave this page. You will be
                able to create new keys at any point on this page however.
              </p>
            )}
          </div>
          <h3 className="mt-4 mb-2">Add webhook</h3>
          <span>
            Enhance your application's responsiveness with our Webhook
            integration.
          </span>
          <ul className="!list-inside !ml-4 !list-disc">
            <li>
              Quickly set up a webhook by specifying a label, URL, and a secret
              key for secure communication.
            </li>
            <li>
              Once configured, our system will promptly notify your application
              of any relevant events or changes, ensuring real-time data
              synchronization.
            </li>
          </ul>
          <div className="flex flex-col w-52 gap-2 my-2">
            <input
              type="text"
              className="border py-2 px-3 rounded-full"
              placeholder="Enter webhook label"
              value={webhookLabel}
              onChange={(e) => setWebhookLabel(e.target.value)}
            />
            <input
              type="text"
              className={cn(
                "border py-2 px-3 rounded-full",
                webhookUrlError && "border-red-500"
              )}
              placeholder="Enter webhook url"
              value={webhookUrl}
              onChange={(e) => setWebhookUrl(e.target.value)}
            />
            <input
              type="text"
              className="border py-2 px-3 rounded-full"
              placeholder="Enter secret"
              value={webhookSecret}
              onChange={(e) => setWebhookSecret(e.target.value)}
            />
            <Button
              color="primary"
              onClick={handleAddWebhook}
              loading={isNewWebhookGenerating || webhookLoading}
              disabled={!webhookUrl || isNewWebhookGenerating || webhookLoading}
            >
              Add webhook
            </Button>
            {webhookData?.me?.webhooks &&
              webhookData.me.webhooks.length > 0 && (
                <table className="my-4">
                  <h2 className="mb-2 whitespace-nowrap">Your webhook Urls:</h2>
                  {webhookData.me.webhooks.map((wh: any) => (
                    <tr>
                      {wh?.label && <td>{wh?.label}: </td>}

                      <td>
                        <span className="whitespace-nowrap text-aqua-400">
                          {wh?.url}
                        </span>
                      </td>
                    </tr>
                  ))}
                </table>
              )}
          </div>
        </div>
        <Upload
          apiKey={apiKey}
          externalId={externalId}
          setExternalId={setExternalId}
          setApiKey={setApiKey}
          onCopy={onCopy}
          error={uploadError}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          handleUpload={handleUpload}
          isUploadLoading={isUploadLoading}
        />
        <Fetch
          apiKey={apiKey}
          externalId={externalId}
          setExternalId={setExternalId}
          setApiKey={setApiKey}
          onCopy={onCopy}
          error={fetchError}
          handleFetch={handleFetch}
          isFetchLoading={isFetchLoading}
        />
      </div>
      <div className="px-6 xl:px-0 xl:w-1/3 h-full">
        <div className="overflow-x-auto bg-gray-100 rounded-md xl:my-2 xl:mr-6 min-h-[200vh] overflow-hidden overflow-y-auto">
          <span className="flex justify-end py-2 text-gray-500 bg-white w-full rounded-t-md border-2 border-gray-100 px-2">
            Response
          </span>
          <code>
            <pre className="px-4 py-6 whitespace-pre-wrap">
              {isFetchLoading || isUploadLoading ? (
                <Skeleton count={3.6} />
              ) : (
                JSON.stringify(response, null, 2)
              )}
            </pre>
          </code>
        </div>
      </div>
    </div>
  );
};

export default ApiSandBox;
