import PureHtml from "atoms/PureHtml";
import { CURRENCY_FORMAT } from "core/consts";
import { AiaCompetitor } from "gql/graphql";
import Rating from "pages/companyProfile/components/shared/Appstore/Rating";
import cn from "classnames";
import { uniqBy } from "lodash";

export const getDomain = (url?: string) => {
  const match = url?.match(/^https?:\/\/([^\/]+)/);
  const domain = match ? match[1] : "";
  return domain;
};

export const getSourceDomains = (url?: string) => {
  const match = url?.replace(/^(https?:\/\/)/, "");
  return match;
};

const competitorPitch = (comp: AiaCompetitor) => comp.pitch || "-";
const competitorKeyFeatures = (comp: AiaCompetitor) => {
  return (
    <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile text-sm">
      <PureHtml content={comp.productOverview?.keyFeatures || "-"} />{" "}
    </div>
  );
};

const competitorBusinessModel = (comp: AiaCompetitor) =>
  comp.productOverview?.businessModel || "-";

const competitorPricing = (comp: AiaCompetitor) =>
  comp.productOverview?.pricing || "-";
const competitorDifference = (comp: AiaCompetitor) =>
  comp?.comparison?.differences || "-";
const competitorSimilarity = (comp: AiaCompetitor) =>
  comp?.comparison?.similarities || "-";
const competitorLinkedinFollowers = (comp: AiaCompetitor) => {
  const formatFollowers = CURRENCY_FORMAT(comp.linkedInCompany?.followerCount);
  return <span>{formatFollowers || "-"}</span>;
};
const competitorMarketPositionAnalysis = (comp: AiaCompetitor) => (
  <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile text-sm">
    <PureHtml
      content={comp.competitiveLandscapeAnalysis?.marketPositionAnalysis || ""}
    />
  </div>
);

const competitorAppstore = (comp: AiaCompetitor) => {
  return (
    <>
      {comp.appstore?.appInfo ? (
        <div>
          <Rating
            rating={(comp?.appstore?.appInfo?.currentVersionScore || 0).toFixed(
              2
            )}
            noOfReviews={comp.appstore?.appInfo?.numberOfRatings || 0}
            ratingStarsize="sm"
            ratingTextSize="text-md"
          />

          {comp.appstore?.appInfo?.appStoreUrl && (
            <a
              href={comp.appstore?.appInfo?.appStoreUrl}
              target="_blank"
              rel="noreferrer"
              className="text-aqua-400 hover:text-aqua-500"
            >
              View on Appstore
            </a>
          )}
        </div>
      ) : (
        <span>-</span>
      )}
    </>
  );
};

const competitorFounders = (comp: AiaCompetitor) => {
  const founders = [
    ...(comp?.founders || []),
    /*     ...(comp?.teamMembers || []), */
  ].filter((member) => member?.name?.toLowerCase().includes("founder"));

  const uniqFounders = uniqBy(founders, "name");

  if (!uniqFounders || uniqFounders.length === 0) return <span>-</span>;
  return uniqFounders.map((founder) => {
    return (
      <div>
        {founder?.name && founder.title?.toLowerCase().includes("founder") && (
          <span className="whitespace-nowrap capitalize">
            {founder?.name}, {founder?.title}
          </span>
        )}
      </div>
    );
  });
};

const competitorRevenue = (comp: AiaCompetitor, competitorsLength?: number) => {
  if (!comp.finances?.revenue || comp.finances.revenue.length === 0)
    return <span>-</span>;

  return comp?.finances?.revenue?.map((revenue) => {
    return (
      <div
        key={`${comp.id} ${revenue?.year}`}
        className={cn(competitorsLength === 1 && "w-1/2")}
      >
        <div className="flex flex-row justify-between">
          <span>{revenue?.year}:</span>
          <span>{revenue?.revenue}</span>
        </div>
      </div>
    );
  });
};

const competitorHq = (comp: AiaCompetitor) => {
  return (
    <>
      {comp.hq?.emoji && <span>{comp.hq?.emoji}</span>}{" "}
      {comp.hq?.name ? <span>{comp.hq?.name}</span> : <span>-</span>}
    </>
  );
};

const competitorPresence = (comp: AiaCompetitor) => {
  return (
    <>
      {comp?.presence && comp?.presence.length > 0 ? (
        <div>
          {comp?.presence?.map((presence) => presence?.name).join(", ")}
        </div>
      ) : (
        "-"
      )}
    </>
  );
};

const competitorFundraising = (
  comp: AiaCompetitor,
  competitorsLength?: number
) => {
  if (
    !comp.finances?.fundingRounds ||
    comp?.finances?.fundingRounds?.length === 0
  )
    return "-";

  return (
    <div className={cn(competitorsLength === 1 && "w-1/2")}>
      {comp.finances.fundingRounds.slice(0, 1).map((item, index: number) => {
        if (item === null) return null;

        const uniquInvestors = uniqBy(item.investors, "name");

        return (
          <div className="flex flex-col gap-1" key={index}>
            <div>
              <div className="flex flex-row justify-between">
                <div className="whitespace-nowrap">Announced On:</div>
                <span>{item?.announcedDate}</span>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div>Round:</div>
              <span>{item?.name}</span>
            </div>
            <div className="flex flex-row justify-between">
              <div className="whitespace-nowrap">Money Raised:</div>
              <span>{item?.moneyRaised || "-"}</span>
            </div>
            <div className="flex flex-row justify-between">
              <div className="whitespace-nowrap">Investors: </div>
              <div className="flex flex-col text-right">
                {uniquInvestors && uniquInvestors.length > 0
                  ? uniquInvestors.map((investor) => (
                      <span key={investor?.name}>
                        {investor ? investor.name : "-"}
                      </span>
                    ))
                  : "-"}
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="whitespace-nowrap">Lead Investors: </div>
              <span className="whitespace-nowrap overflow-hidden truncate ml-2">
                {item?.leadInvestors || "-"}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const COLUMNS_DEEP_DIVE = [
  {
    rowName: "Pitch",
    getValue: competitorPitch,
  },
  {
    rowName: "Key Features",
    getValue: competitorKeyFeatures,
  },
  {
    rowName: "Market Position",
    getValue: competitorMarketPositionAnalysis,
  },
  {
    rowName: "Differences",
    getValue: competitorDifference,
  },
  {
    rowName: "Similarities",
    getValue: competitorSimilarity,
  },
  /*   {
    rowName: "Founders",
    getValue: competitorFounders,
  }, */
  {
    rowName: "Hq",
    getValue: competitorHq,
  },
  {
    rowName: "Presence",
    getValue: competitorPresence,
  },
  /*   {
    rowName: "Revenue (in thousands, USD)",
    getValue: competitorRevenue,
  }, */
  {
    rowName: "Fundraising",
    getValue: competitorFundraising,
  },
  /*   {
    rowName: "Pricing",
    getValue: competitorPricing,
  }, */
  {
    rowName: "Business Model",
    getValue: competitorBusinessModel,
  },
  {
    rowName: "Followers on linkedin",
    getValue: competitorLinkedinFollowers,
  },
  {
    rowName: "Appstore rating",
    getValue: competitorAppstore,
  },
];
