import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FieldName } from "gql/graphql";

interface Props {
  content: string;
}

const FutureRegulations: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div>
      <h2 className="text-lg text-gray-700 dark:text-white/80 mb-2">
        Future regulations
      </h2>
      <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper pl-4">
        <PureHtml content={content} />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(
  FutureRegulations,
  FieldName.FutureRegulations
);
