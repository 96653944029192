export const generateInputObject = (
  inputValue: string | number | undefined | null, // some api input fields might actully be undefined or null
  fieldName: string
) => {
  const inputProperties = fieldName.split(".").reverse();
  const inputObj = inputProperties.reduce((accumulator: any, currentValue) => {
    return {
      [currentValue]: accumulator,
    };
  }, inputValue);

  return inputObj;
};
