// @ts-nocheck
import { getClient } from "core/utility";
import { API_STATUS } from "models/auth/constants";
import { toggleLoader } from "models/loaders/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import { FORGOT_PASSWORD as FORGOT_PASSWORD_QUERY } from "./query";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { FORGOT_PASSWORD_BUTTON } from "./constants";
import { triggerSnack } from "organisms/Snack/sagaActions";

const doForgotPassword = async ({
  variables,
  fetchPolicy = "network-only",
}) => {
  try {
    let client = getClient();
    return await client.mutate({
      mutation: FORGOT_PASSWORD_QUERY,
      fetchPolicy,
      variables: variables,
    });
  } catch (error) {
    return { isError: true, error };
  }
};

function* handleForgotPassword({ payload: { formValues, lang } }) {
  const { email } = formValues;
  yield put(
    toggleLoader({ component: FORGOT_PASSWORD_BUTTON, isLoading: true })
  );
  if (!email) {
    return;
  }
  try {
    const { error, isError } = yield doForgotPassword({
      variables: { email },
    });
    if (isError) {
      throw error;
    }
    yield put(slice.updateForgotPassword({ status: API_STATUS.SUCCESS }));
    yield put(
      toggleLoader({ component: FORGOT_PASSWORD_BUTTON, isLoading: false })
    );
  } catch (error) {
    console.log(error);
    yield put(
      toggleLoader({ component: FORGOT_PASSWORD_BUTTON, isLoading: false })
    );
    yield put(
      triggerSnack({
        type: "error",
        title: lang?.somethingWentWrong,
        message: error?.message,
      })
    );
    yield put(slice.updateForgotPassword({ status: API_STATUS.FAILED }));
    yield put(
      toggleLoader({ component: FORGOT_PASSWORD_BUTTON, isLoading: false })
    );
  }
}

export default function* rootSaga() {
  return [yield takeEvery(sagaActions.forgotPassword, handleForgotPassword)];
}
