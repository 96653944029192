import { FC } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  fullPage?: boolean;
  position?: "absolute" | "relative";
  size?: "small" | "medium" | "large";
}

const Loading: FC<Props> = ({ size = "large", position, fullPage }) => (
  <div
    className={cn(
      "flex justify-center items-center",
      fullPage && "w-full h-full bg-slate-100 top-0 left-0 dark:bg-neutral-900",
      position === "absolute" && "absolute z-50",
      position === "relative" && "relative z-50",
      size === "small" ? "text-lg" : size === "medium" ? "text-3xl" : "text-5xl"
    )}
  >
    <FontAwesomeIcon
      icon={["fal", "spinner-third"]}
      className="text-aqua-400"
      spin
    />
  </div>
);

export default Loading;
