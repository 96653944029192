import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CompetitorsSkeleton = () => {
  return (
    <div className="mx-auto">
      <div className="border border-gray-200 rounded">
        <div className="border-b border-b-gray-200 px-3 py-3 flex">
          <div className="text-gray-400 text-sm basis-1/3 p-1">
            <Skeleton count={0.3} borderRadius="1rem" />
          </div>
          <div className="text-gray-400 text-sm basis-1/3 p-1">
            <Skeleton count={0.3} borderRadius="1rem" />
          </div>
          <div className="text-gray-400 text-sm basis-1/3 p-1">
            <Skeleton count={0.3} borderRadius="1rem" />
          </div>
        </div>
        {[1, 2, 3].map((i) => {
          return (
            <div className="border-b border-b-gray-200 px-3 py-3 flex" key={i}>
              <div className="basis-1/3 pl-1 pr-10">
                <Skeleton count={0.4} borderRadius="1rem" />
                <Skeleton count={1} borderRadius="1rem" />
              </div>
              <div className="basis-1/3 pl-1 pr-10">
                <Skeleton count={1.7} borderRadius="1rem" />
              </div>
              <div className="basis-1/3 pl-1 pr-6">
                <Skeleton count={1.7} borderRadius="1rem" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompetitorsSkeleton;
