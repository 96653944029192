import type { ModalContentProps } from "./interfaces";
import { useMutation, useQuery } from "@apollo/client";
import { TextField } from "pages/onboard/components";
import { FC, useState } from "react";
import DefaultImage from "images/dealroom-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ADD_TO_DEALFLOW,
  SEARCH_STARTUPS_TO_ADD,
} from "queries/investor/investorDealflow";
import { Startup, StartupShort } from "gql/graphql";
import { mixpanelAddExistingStartupToDealflow } from "core/mixpanel/Mixpanel";

const AddExisting: FC<ModalContentProps> = ({
  onChangeMode,
  onClose,
  refetch: refetchDealflow,
}) => {
  const [query, setQuery] = useState("");
  const [addToDealflow] = useMutation(ADD_TO_DEALFLOW);
  const { data, previousData, refetch } = useQuery(SEARCH_STARTUPS_TO_ADD, {
    variables: {
      query,
    },
    skip: !query,
  });
  const startups = data?.startupsToAdd || previousData?.startupsToAdd || [];

  const handleAddToDealflow = async (startup: StartupShort) => {
    await addToDealflow({ variables: { companyId: startup?.id } });
    refetch();
    refetchDealflow();
    onClose();
    mixpanelAddExistingStartupToDealflow(startup?.name ?? "");
  };

  return (
    <div>
      <div className="mb-6 text-sm text-gray-500">
        Search our network for a company or add it manually
      </div>
      <TextField
        label="Company name"
        value={query}
        onChange={setQuery}
        className="mb-4"
      />
      {startups.length === 0 && query && (
        <div className="text-sm">
          <span className="italic">There's no such company in our network</span>
          <button
            type="button"
            className="px-2 py-0.5 ml-1 rounded-lg text-aqua-400 hover:text-aqua-700"
            onClick={() => onChangeMode("create")}
          >
            Add it
          </button>
        </div>
      )}
      <div>
        {startups.map((startup) => (
          <div
            key={startup?.id}
            className="flex items-center justify-between w-full p-2 mt-2 border border-gray-300 rounded-xl"
          >
            <div className="flex items-center">
              <img
                src={startup?.logo?.small?.location || DefaultImage}
                alt=""
                className="object-cover w-8 h-8 mr-2 border border-gray-300 rounded-full"
              />
              <span>{startup?.name}</span>
            </div>
            <button
              type="button"
              onClick={() =>
                handleAddToDealflow(startup ?? ({} as StartupShort))
              }
              className="focus:text-aqua-400 flex items-center focus:border-aqua-300 hover:text-aqua-400 hover:border-aqua-300 text-sm px-2 py-0.5 rounded-lg"
            >
              <span className="mr-1">Add</span>
              <FontAwesomeIcon icon={["fas", "plus"]} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddExisting;
