// @ts-nocheck
import { gql } from "@apollo/client";

export const SAVE_TIMING = gql`
  mutation saveCompanyTiming($input: TimingInput) {
    saveCompanyTiming(input: $input) {
      _id
      businessTiming
    }
  }
`;

export const GET_TIMING = gql`
  query getTiming {
    me {
      businessTiming
    }
  }
`;
