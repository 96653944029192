import { Tab } from "@headlessui/react";
import Button from "atoms/Button/Button";
import cn from "classnames";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { FC } from "react";
import { useDispatch } from "react-redux";
import NodeFetch from "./NodeFetch";

interface UploadFeatureProps {
  apiKey: string;
  externalId: string;
  setExternalId: (externalId: string) => void;
  setApiKey: (apiKey: string) => void;
  onCopy: () => void;
  handleFetch: () => void;
  isFetchLoading: boolean;
  error: boolean;
}

const Fetch: FC<UploadFeatureProps> = ({
  setExternalId,
  setApiKey,
  onCopy,
  handleFetch,
  apiKey,
  externalId,
  error,
  isFetchLoading,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="px-6 my-6">
        <h3 className="my-2">Data fetching:</h3>
        <p className="text-black">Test data retrieval using our API.</p>
        <ol className="!list-inside !ml-4 !list-decimal text-base">
          <li>Copy the id returned from the upload request.</li>
          <li>Paste this id into the externalId field.</li>
          <li>Check that you API key is still available and added inside.</li>
          <li>Try it out!</li>
          <li>
            The response from the upload will be returned on the right side.
          </li>
        </ol>
      </div>
      <div className="w-full rounded-b-md bg-gray-100 border-gray-300 border py-6 px-12 my-4">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-md w-52 float-right bg-gray-100 p-1 mx-6">
            <Tab
              className={({ selected }) =>
                cn(
                  //Pointer events none is used to prevent the tab from being clicked until we have more options
                  "w-full rounded-lg py-1 text-sm font-medium leading-5 text-aqua-400 pointer-events-none",
                  "ring-white focus:outline-none focus:ring-0",
                  selected
                    ? "bg-white shadow"
                    : "text-gray-600 hover:text-aqua-400"
                )
              }
            >
              Node.js
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="rounded-xl py-2 ring-white ring-opacity-0 focus:outline-none focus:ring-0 px-6">
              <NodeFetch
                apiKey={apiKey}
                externalId={externalId}
                setApiKey={setApiKey}
                setExternalId={setExternalId}
                onCopy={onCopy}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className="w-full flex justify-end">
          <Button
            onClick={() => {
              if (error) {
                dispatch(
                  triggerSnack({
                    type: "error",
                    title: " ",
                    message: `Missing parameters! ${
                      !externalId ? "missing externalId" : ""
                    } ${!apiKey ? "API Key not provided" : ""}`,
                  })
                );
                return;
              } else handleFetch();
            }}
            color="primary"
            loading={isFetchLoading}
            disabled={!apiKey && isFetchLoading}
            className="rounded-lg"
          >
            <span className="font-bold">Try it out!</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Fetch;
