import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enableBodyScroll } from "body-scroll-lock";
import { useLang } from "core/lang/LangaugeSetting";
import { History } from "core/utility";
import { setGlobalState } from "models/globalState/sagaActions";
import { getUser } from "models/user/selectors";
import { triggerShareModal } from "./sagaActions";
import { Dialog, Transition } from "@headlessui/react";
import FixScrollProblem from "atoms/FixScrollProblem";
import { mixpanelShareAiaProfile } from "core/mixpanel/Mixpanel";

const enableBody = (target: any) => enableBodyScroll(target);

interface Props {
  title: string;
  publicUrl: string;
  onClose?: () => void;
  us?: string;
  uc?: string;
  shareAia?: boolean;
}

const PublicShare: FC<Props> = (props) => {
  const [qr, setQr] = useState<string>();
  const dispatch = useDispatch();
  const { lang } = useLang();
  const [state, setState] = useState({
    copiedText: "",
  });
  const user = useSelector(getUser);

  const isMe = user?.displayName === props.title;

  const publicUrl = `${props?.publicUrl}?us=${
    props.us ? props.us : "public_share"
  }${props.uc ? `&uc=${props.uc}` : ""}`;

  const generateQr = async (link: any) => {
    var opts = {
      errorCorrectionLevel: "H",
      type: "image/jpeg",
      quality: 2,
      margin: 5,
    };
    const url = await QRCode.toDataURL(link, opts as any);
    setQr(url);
  };

  useEffect(() => {
    generateQr(publicUrl);
  }, []);

  const closeModal = () => {
    dispatch(
      triggerShareModal({
        isEnabled: false,
      })
    );
    dispatch(
      setGlobalState({
        isPublicShareModal: false,
      })
    );
    props.onClose?.();
    enableBody(document.body);
  };

  const handleClickMySpace = () => {
    /* New routing double check */
    // @ts-ignore
    History.navigate("/myspace");
    closeModal();
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <FixScrollProblem />
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex justify-between">
                    <h4 className="font-medium">{lang.profileLink}</h4>
                    <button
                      type="button"
                      className="text-gray-900 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
                      aria-label="Close"
                      onClick={() => closeModal()}
                    >
                      <FontAwesomeIcon
                        className="text-2xl"
                        icon={["fal", "times"]}
                      />
                    </button>
                  </div>
                </Dialog.Title>
                <div className="flex flex-col items-center">
                  <div className="w-full mt-3 text-left">
                    <CopyToClipboard text={publicUrl}>
                      <button
                        className="copy-link"
                        data-key={
                          isMe ? "copy-own-profilelink" : "copy-profile-link"
                        }
                        onClick={() => {
                          setState({ ...state, copiedText: publicUrl });
                          props?.shareAia &&
                            mixpanelShareAiaProfile(user, props?.title);
                        }}
                      >
                        <div className="truncate" style={{ width: "auto" }}>
                          {publicUrl}
                        </div>
                        {state.copiedText === publicUrl ? (
                          <span className="copied">{lang.copied}</span>
                        ) : (
                          <span>{lang.copy}</span>
                        )}
                      </button>
                    </CopyToClipboard>
                  </div>
                  <img src={qr} className="w-64 mt-4" alt="" />
                  <h6 className="mt-4 font-medium ">{`Scan the QR code to open ${props.title}’s profile.`}</h6>
                </div>
                {user?.me && (
                  <div className="mt-2 text-sm text-left">
                    {lang.viewYourAnalyticsIn}
                    <span
                      onClick={handleClickMySpace}
                      className="cursor-pointer text-aqua-400"
                    >
                      {lang.mySpace}
                    </span>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PublicShare;
