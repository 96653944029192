import type { FC } from "react";
import type { FilterPanelProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelect from "atoms/Form/MultiSelect";
import Loading from "atoms/Loading/Loading";
import EditStatusModal from "../EditStatusModal";
import { INDUSTRY_CATEGORIES_SWEDISH } from "core/consts";
import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import Button from "atoms/Button/Button";

const FilterPanel: FC<FilterPanelProps> = ({
  isLoading,
  query,
  cohorts,
  sections,
  onQueryChange,
  onCohortsChange,
  onSectionsChange,
  onIndustriesChange,
  onShowModal,
  onCloseModal,
  onAddCompany,
  isShowModal,
}) => {
  const user = useSelector(getUser);

  return (
    <div className="p-1 lg:p-3 lg:pt-2">
      <div className="lg:flex justify-between items-center">
        <div className="flex items-center flex-wrap w-full md:w-auto md:grid md:grid-cols-2 md:gap-1 lg:flex">
          <input
            placeholder="Search..."
            value={query}
            onChange={({ target }) => onQueryChange(target.value)}
            className="w-full px-2 py-1 text-sm border border-gray-200 rounded-md shadow-sm focus:border-gray-400 lg:w-52 lg:self-end"
          />
          {user?.isAccelerator && (
            <div className="flex w-full lg:w-52">
              <div className="w-full dealflow-multi-select">
                <MultiSelect
                  label="All cohorts"
                  name="cohorts"
                  options={cohorts.map((cohort) => ({
                    key: cohort.id,
                    value: cohort.name,
                  }))}
                  onChange={onCohortsChange}
                  resetText="All cohorts"
                  showChecked
                  checkMark
                />
              </div>
            </div>
          )}
          <div className="flex w-full lg:w-52">
            <div className="z-40 w-full dealflow-multi-select dealflow-status-filter">
              <MultiSelect
                label="All statuses"
                name="statuses"
                showChecked
                checkMark
                options={sections.map((section) => ({
                  key: section.id,
                  value: section.title,
                }))}
                onChange={onSectionsChange}
                resetText="All statuses"
                additionalOptions={[
                  {
                    onClick: onShowModal,
                    content: (
                      <span className="flex items-center">
                        <FontAwesomeIcon icon={["fal", "pencil"]} />
                        <span className="ml-2 font-normal whitespace-nowrap">
                          Edit your statuses
                        </span>
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex w-full lg:w-52">
            <div className="z-40 w-full dealflow-multi-select">
              <MultiSelect
                label="All industries"
                name="industries"
                showChecked
                checkMark
                options={Object.entries(INDUSTRY_CATEGORIES_SWEDISH).map(
                  ([key, value]) => ({ key, value })
                )}
                onChange={onIndustriesChange}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center w-full lg:w-fit mt-2 mb-1">
          {/* Hiding add startup so that VCs cannot add to DF manually */}
          {/* <Button
            onClick={onAddCompany}
            className="mx-1 flex justify-center basis-1/2 lg:basis-52"
            size="small"
            color="primary"
            id="add-company-button"
          >
            <FontAwesomeIcon icon={["fas", "plus"]} />
            <span className="ml-2 truncate">Add startup</span>
          </Button> */}
        </div>
      </div>

      {isLoading && (
        <span className="hidden ml-2 md:block">
          <Loading size="small" />
        </span>
      )}
      {isShowModal && <EditStatusModal onClose={onCloseModal} />}
    </div>
  );
};

export default FilterPanel;
