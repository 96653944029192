import type { FC } from "react";
import type { SectionProps } from "./interfaces";
import {
  CURRENT_COMPANY_STAGE,
  INDUSTRY_CATEGORIES_SWEDISH,
  TEAM_SIZE,
} from "core/consts";

const Header: FC<SectionProps> = ({ startup }) => (
  <div className="px-4">
    {startup.website && (
      <div className="flex items-center">
        <span className="mr-2 text-sm text-gray-400 min-w-[70px]">
          Website:
        </span>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={startup.website}
          className="cursor-pointer text-aqua-400 hover:text-aqua-500 hover:underline"
        >
          {startup.website}
        </a>
      </div>
    )}
    {startup.fullAddress && (
      <div className="flex items-center">
        <span className="mr-2 text-sm text-gray-400 min-w-[70px]">
          Address:
        </span>
        <span className="text-gray-600">{startup.fullAddress}</span>
      </div>
    )}
    {startup.founded && (
      <div className="flex items-center">
        <span className="mr-2 text-sm text-gray-400 min-w-[70px]">
          Founded:
        </span>
        <span className="text-gray-600">{startup.founded}</span>
      </div>
    )}
    {startup.teamSize && (
      <div className="flex items-center">
        <span className="mr-2 text-sm text-gray-400 min-w-[70px]">Team:</span>
        <span className="text-gray-600">
          {TEAM_SIZE[startup.teamSize as keyof typeof TEAM_SIZE]}
        </span>
      </div>
    )}
    {startup?.companyStage && startup.companyStage?.length > 0 && (
      <div className="flex items-center">
        <span className="mr-2 text-sm text-gray-400 min-w-[70px]">Stage:</span>
        <span className="text-gray-600">
          {startup.companyStage
            .map(
              (stage) =>
                CURRENT_COMPANY_STAGE[
                  stage as keyof typeof CURRENT_COMPANY_STAGE
                ]
            )
            .join(", ")}
        </span>
      </div>
    )}
    <div className="mt-2">
      <ul className="flex flex-wrap text-sm">
        {startup.industries?.map((industry, idx) => (
          <li
            key={idx}
            className="px-3 py-1 mb-2 mr-2 border rounded-2xl bg-sky-100 border-sky-300 text-sky-600"
          >
            {
              INDUSTRY_CATEGORIES_SWEDISH[
                industry as keyof typeof INDUSTRY_CATEGORIES_SWEDISH
              ]
            }
          </li>
        ))}
      </ul>
    </div>
    {startup.description && (
      <div className="mt-2 text-sm">
        <div className="text-gray-400 ">Product description</div>
        <div className="mt-1 text-gray-600">{startup.description}</div>
      </div>
    )}
  </div>
);

export default Header;
