import { FC } from "react";
import { Socials } from "./interfaces";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";

interface Props {
  socials: Socials;
}
const SocialMedia: FC<Props> = ({ socials }) => {
  if (socials && Object.values(socials).some((v) => !!v)) {
    return (
      <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
        <h6 className="font-medium">Social Media</h6>
        <div className="flex flex-row items-center gap-3">
          {socials?.linkedin && (
            <a
              href={socials?.linkedin}
              rel="noreferrer noopener"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
          )}
          {socials?.instagram && (
            <a
              href={socials?.instagram}
              rel="noreferrer noopener"
              target="_blank"
            >
              <img src="assets/images/instagram-icon.svg" alt="" />
            </a>
          )}
          {socials?.facebook && (
            <a
              href={socials?.facebook}
              rel="noreferrer noopener"
              target="_blank"
            >
              <FacebookIcon />
            </a>
          )}
          {socials?.youtube && (
            <a
              href={socials?.youtube}
              rel="noreferrer noopener"
              target="_blank"
            >
              <YoutubeIcon className="h-4" />
            </a>
          )}
          {socials?.twitter && (
            <a
              href={socials?.twitter}
              rel="noreferrer noopener"
              target="_blank"
            >
              <TwitterIcon />
            </a>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default SocialMedia;
