import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ListBoxProps } from "./interfaces";
import cn from "classnames";

export const ListboxWrapper = <T,>({
  options,
  selected,
  onSelect,
}: ListBoxProps<T>) => (
  <Listbox value={selected} onChange={onSelect} as={Fragment}>
    <div className="relative w-[12rem]">
      <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
        <span className="block truncate">{selected.name}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <FontAwesomeIcon icon={["fas", "sort"]} />
        </span>
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {options.map((option, optionIdx) => (
            <Listbox.Option
              key={optionIdx}
              className={({ active }) =>
                cn(
                  "relative cursor-default select-none py-2 px-4",
                  active ? "bg-aqua-400" : "text-gray-900"
                )
              }
              value={option}
            >
              {({ selected }) => (
                <span
                  className={cn(
                    "block",
                    selected ? "font-medium" : "font-normal"
                  )}
                >
                  {option.name}
                </span>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </div>
  </Listbox>
);
