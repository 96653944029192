import { FC, createRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "../../sagaActions";
import EditButton from "../reusables/EditButton";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  name: string | undefined | null;
}

const Name: FC<Props> = ({ name }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const handleSave = () => {
    const inputValue = inputRef.current?.value;
    dispatch(updateStartupProfile({ name: inputValue }));
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  return (
    <div className="flex flex-row mb-2">
      {isEditMode ? (
        <>
          <input
            type="text"
            className="px-2 mr-2 border border-gray-300 rounded-md"
            defaultValue={name || ""}
            ref={inputRef}
          />
          <SaveAndCancelButtons onSave={handleSave} onCancel={handleCancel} />
        </>
      ) : name ? (
        <>
          <h1 className="p-2 text-3xl max-w-xs">{name}</h1>
          <EditButton onClick={() => setIsEditMode(true)} />
        </>
      ) : (
        <div
          className="cursor-pointer flex items-center ml-2"
          onClick={() => setIsEditMode(true)}
        >
          <FontAwesomeIcon
            icon={["fal", "plus-circle"]}
            className="mr-2 text-aqua-400"
          />
          <span className="text-aqua-400 text-sm">Add Company Name</span>
        </div>
      )}
    </div>
  );
};

export default Name;
