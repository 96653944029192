import { en } from "./lang/en";
import { sv } from "./lang/sv";

export const REMEMBER_ME_COOKIE_KEY = "__sircular_remember_me__";

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_PATTERN =
  /(https?:\/\/(?:http\.|(?!http))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:http\.|(?!http))[a-zA-Z0-9]+\.[^\s]{2,}|http\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
export const YEAR_PATTERN = /^[12][0-9]{3}$/;
export const NUMBER_PATTERN = /^(0|[1-9][0-9]*)$/;
export const IFRAME_PATTERN = /^.*\biframe\b.*$/;
export const AMOUNT_PATTERN = /^\d+(\.\d{1,2})?$/;
// export const LINKEDIN_PATTERN = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
export const LINKEDIN_PATTERN =
  /(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
export const PASSWORD_PATTERN =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const YOUTUBE_VIDEO_PATTERN =
  /(?:https?:\/\/)?(?:www.)?(?:(?:youtu.be\/)|(?:youtube.com)\/(?:v\/|u\/w\/|embed\/|watch))(?:(?:\?v=)?([^#&?=]*))?((?:[?&]w*=w*)*)/;

export const VIMEO_PATTERN = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/;

const getLanguage = () => {
  var language = localStorage.getItem("language");
  switch (language) {
    case "sv":
      language = sv;
      break;
    case "en":
    default:
      language = en;
      break;
  }
  if (language) {
    return language;
  }
};

// @TODO: refactor: rename and move to utils
//
export const MILIFY_CURRENCY_FORMAT = (currency) => {
  var amount;
  const currencyFormat = Math.abs(Number(currency));

  if (currencyFormat >= 1.0e9) {
    amount = (currencyFormat / 1.0e9).toFixed(1) + " B";
  } else if (currencyFormat >= 1.0e6) {
    amount = (currencyFormat / 1.0e6).toFixed(1) + " M";
  } else if (currencyFormat >= 1.0e3) {
    amount = (currencyFormat / 1.0e3).toFixed(1) + " K";
  }

  if (amount && currency.toString()?.length > 3) {
    return parseInt(amount.split(" ")[0]) % 1 !== 0
      ? amount.split(" ")[0] + amount.split(" ")[1]
      : Number(amount.split(" ")[0]) + amount.split(" ")[1];
  } else {
    return currency;
  }
};

export const CURRENCY_FORMAT = (currency) => {
  var amount;
  const currencyFormat = Math.abs(Number(currency));

  if (currencyFormat >= 1.0e9) {
    amount = (currencyFormat / 1.0e9).toFixed(1) + " B";
  } else if (currencyFormat >= 1.0e6) {
    if (currencyFormat > 5000000) {
      amount = (currencyFormat / 1.0e6).toFixed(1) + " M+";
    } else {
      amount = (currencyFormat / 1.0e6).toFixed(1) + " M";
    }
  } else if (currencyFormat >= 1.0e4) {
    if (currencyFormat < 100000) {
      amount = (parseInt(currencyFormat / 1.0e4) * 10).toFixed(1) + " K";
    } else {
      amount = (parseInt(currencyFormat / 1.0e5) * 100).toFixed(1) + " K";
    }
  } else if (currencyFormat >= 1.0e3) {
    amount = (currencyFormat / 1.0e3).toFixed(1) + " K";
  }

  if (amount) {
    return parseInt(amount.split(" ")[0]) % 1 !== 0
      ? amount.split(" ")[0] + amount.split(" ")[1]
      : Number(amount.split(" ")[0]) + amount.split(" ")[1];
  }
};

export const ACCOUNT_TYPES = {
  COMPANY: "company",
  INVESTOR: "investor",
};

const AccountTypeSwedishFun = () => {
  const lang = getLanguage();
  return {
    COMPANY: lang.startups,
    INVESTOR: lang.investor,
  };
};

const OfferStatusFun = () => {
  const lang = getLanguage();
  return {
    UPCOMING: lang.planned,
    ONGOING: lang.onGoing,
    ENDED: lang.completed,
    DRAFT: lang.draft,
  };
};

export const OFFER_STATUS_KEYS = {
  UPCOMING: "UPCOMING",
  ONGOING: "ONGOING",
  ENDED: "ENDED",
  DRAFT: "DRAFT",
};

const IndustryCategorySwedishFun = () => {
  const lang = getLanguage();
  return {
    AGRICULTURE: lang.agriculture,
    AI: lang.AI,
    DATA_ANALYTICS: lang.dataAnalytics,
    AR_VR: lang.arVr,
    AUTOMATION: lang.automation,
    BLOCKCHAIN: lang.blockchain,
    CHEMICALS: lang.chemicals,
    CLOUD_INFRASTRUCTURE_IT: lang.cloudInfrastructurIT,
    CONSUMER_GOODS: lang.consumerGoods,
    CONSTRUCTION: lang.construction,
    COSMETICS: lang.cosmetics,
    CRYPTOCURRENCY: lang.cryptocurrency,
    CYBERSECURITY: lang.cybersecurity,
    DEVELOPER_TOOLS: lang.developerTools,
    ECOMMERCE: lang.eCommerce,
    EDUCATION: lang.education,
    ENERGY: lang.energy,
    ENTERPRISE: lang.enterprise,
    ENVIRONMENT_CLEANTECH: lang.environmentCleanTech,
    FASHION: lang.fashion,
    FINANCE_INSURANCE: lang.financeAndInsurance,
    FOOD_DRINKS: lang.foodAndDrinks,
    GAMING: lang.gaming,
    GOVERNMENT: lang.government,
    HARDWARE: lang.hardware,
    HEALTHCARE: lang.health,
    HOME_LEISURE: lang.homeLeisure,
    HOSPITALITY: lang.hospitality,
    HR_RECRUITMENT: lang.hrRecruitment,
    IOT: "IoT",
    LEGAL: lang.law,
    LIFE_SCIENCE_BIOTECH: lang.lifeScienceAndBiotech,
    MANUFACTURING: lang.manufacturing,
    MARKETING_COMMS: lang.marketingComms,
    MATERIALS: lang.materials,
    MEDIA_ENTERTAINMENT: lang.mediaAndEntertainment,
    MESSAGING: lang.messaging,
    MOBILITY_AUTO: lang.mobilityAuto,
    MUSIC_AND_SOUND: lang.musicAndSound,
    NATURAL_RESOURCES: lang.naturalResources,
    PARENTING_FAMILIES: lang.parentingFamilies,
    PETS: lang.pets,
    PHARMACEUTICALS: lang.Pharmaceuticals,
    REAL_ESTATE_PROPTECH: lang.realEstateAndProptech,
    RETAIL: lang.retail,
    ROBOTICS: lang.robotics,
    SALES_CRM: lang.salesAndCRM,
    SECURITY: lang.security,
    SMART_CITIES: lang.smartCities,
    SPACE: lang.space,
    SPORTS: lang.sportAndTraining,
    TELECOMMUNICATION: lang.teleCommunication,
    TRANSPORT_LOGISTICS: lang.transportAndLogistics,
    TRAVEL_TOURISM: lang.travelAndTourism,
    WELLNESS_FITNESS: lang.wellnessAndFitness,
  };
};

export const OFFER_CATEGORIES = {
  LAW_AND_CRISIS_MANAGEMENT: "LAW_AND_CRISIS_MANAGEMENT",
  INSURANCE: "INSURANCE",
  ECONOMICS: "ECONOMICS",
  OFFICE_AND_SHIPPING: "OFFICE_AND_SHIPPING",
  EDUCATION: "EDUCATION",
  TRANSPORT: "TRANSPORT",
  TRIPS_AND_HOTEL: "TRIPS_AND_HOTEL",
  HEALTH_AND_WELLNESS: "HEALTH_AND_WELLNESS",
};

const OfferCategorySwedishFun = () => {
  const lang = getLanguage();
  return {
    LAW_AND_CRISIS_MANAGEMENT: lang.legalAidManagementAndDocTemplates,
    INSURANCE: lang.insurance,
    ECONOMICS: lang.economyAndProfitability,
    OFFICE_AND_SHIPPING: lang.officeAndShipping,
    EDUCATION: lang.training,
    TRANSPORT: lang.carsAndFuel,
    TRIPS_AND_HOTEL: lang.travelAndHotels,
    HEALTH_AND_WELLNESS: lang.healthAndWellNess,
  };
};

const PhaseFun = () => {
  const lang = getLanguage();
  return {
    IDEA: lang.id,
    PROTOTYPE: lang.productOrProtoType,
    GO_TO_MARKET: lang.goToMarket,
    GROWTH: lang.growthAndExpansion,
  };
};

const FinancePhaseFun = () => {
  const lang = getLanguage();
  return {
    BOOTSTRAPPING: lang.bootstraping,
    PRE_SEED: lang.preSeed,
    SEED: lang.seed,
    SERIES_A: `${lang.series} A`,
    SERIES_B: `${lang.series} B`,
    SERIES_C_PLUS: `${lang.series} C+`,
  };
};

const FinanceTypeFun = () => {
  const lang = getLanguage();
  return {
    CONTRIBUTION: lang.contribution,
    LOAN: lang.loansAndCredit,
    INVESTMENT: lang.investment,
  };
};

const FinanceUrgeFun = () => {
  const lang = getLanguage();
  return {
    MONTHS_0_3: `0-3 ${lang.months}`,
    MONTHS_3_6: `3-6 ${lang.months}`,
    MONTHS_6_12: `6-12 ${lang.months}`,
    MONTHS_12_24: `1-2 ${lang.year}`,
  };
};

const RaisedBeforeFun = () => {
  const lang = getLanguage();
  return {
    YES: lang.yes,
    NO: lang.no,
  };
};

const SearchingPhaseFun = () => {
  const lang = getLanguage();
  return {
    YES: lang.yes,
    NO: lang.no,
  };
};

const MarketsFun = () => {
  const lang = getLanguage();
  return {
    DEVELOPED: lang.developed,
    EMERGING: lang.emerging,
    DEVELOPED_AND_EMERGING: lang.developedAndEmerging,
  };
};

export const BUSINESS_MODEL = {
  B2C: "B2C",
  B2B: "B2B",
  B2G: "B2G",
  B2B2C: "B2B2C",
  B2B2B: "B2B2B",
  P2P: "P2P",
  OTHERS: "Other",
};

const CurrentStageCompanyFun = () => {
  const lang = getLanguage();
  return {
    PRE_PRODUCT: lang.preProduct,
    PRE_REVENUE: lang.preRevenue,
    POST_REVENUE: lang.postRevenue,
  };
};

const BusinessCategoryFun = () => {
  const lang = getLanguage();
  return {
    E_COMMERCE: lang.eCommerce,
    HARDWARE: lang.hardware,
    MARKETPLACE: lang.marketPlace,
    MARKET_NETWORK: lang.marketNetwork,
    SOCIAL_NETWORK: lang.socialNetwork,
    PLATFORM: "Platform",
    SAAS: "SaaS",
    OTHER: lang.other,
  };
};

const ONLY_IMPACT_COMPANY_RESPONSES = {
  YES: "Yes",
  NO: "No",
  MOSTLY: "Mostly",
};

const IS_ACCREDITED_INVESTOR_RESPONSES = {
  YES: "Yes",
  NO: "No",
  UNSURE: "Unsure",
};

const IS_COUNTRY_RESTRICTED = {
  YES: "Yes",
  NO: "No",
};

const GendersFun = () => {
  const lang = getLanguage();
  return {
    GENDER: lang.gender,
    MALE: lang.man,
    FEMALE: lang.woman,
  };
};

const InvestorTypeFun = () => {
  const lang = getLanguage();
  return {
    ANGEL_INVESTOR: "Angel Investor",
    VC: "VC",
    FAMILY_OFFICE: "Family Office",
  };
};

const RaisedFromFun = () => {
  const lang = getLanguage();
  return {
    SELF_FUNDED: lang.selfFunded,
    FRIENDS_FAMILY: lang.friendsAndFamily,
    FAMILY_OFFICE: lang.familyOffice,
    VENTURE_CAPITAL: lang.ventureCapital,
    ANGELS: lang.angels,
  };
};

export const INVESTMENT_RANGES = {
  LESS_25K: "SEK < 250K",
  BETWEEN_25K_50K: "SEK 250 - 500K",
  BETWEEN_50K_100K: "SEK 500 - 1M",
  BETWEEN_100K_500K: "SEK 1M - 5M",
  BETWEEN_05M_1M: "SEK 5 - 10M",
  BETWEEN_1K_5M: "SEK 10 - 50M",
  MORE_5M: "SEK > 50M",
};

export const SETTINGS_STEPS = {
  COMPANY_TEASER_STEP_1: "COMPANY_TEASER_STEP_1",
  COMPANY_TEASER_STEP_2: "COMPANY_TEASER_STEP_2",
  COMPANY_TEASER_STEP_3: "COMPANY_TEASER_STEP_3",
  COMPANY_TEASER_STEP_4: "COMPANY_TEASER_STEP_4",
  COMPANY_TEASER_STEP_5: "COMPANY_TEASER_STEP_5",
  COMPANY_TEASER_STEP_6: "COMPANY_TEASER_STEP_6",
  COMPANY_TEASER_STEP_7: "COMPANY_TEASER_STEP_7",
  COMPANY_TEASER_STEP_8: "COMPANY_TEASER_STEP_8",
  COMPANY_TEASER_STEP_9: "COMPANY_TEASER_STEP_9",
  COMPANY_TEASER_STEP_10: "COMPANY_TEASER_STEP_10",
  COMPANY_TEASER_STEP_11: "COMPANY_TEASER_STEP_11",
  COMPANY_TEASER_STEP_12: "COMPANY_TEASER_STEP_12",
  COMPANY_TEASER_STEP_13: "COMPANY_TEASER_STEP_13",
  COMPANY_TEASER_STEP_14: "COMPANY_TEASER_STEP_14",

  COMPANY_PROFILE: "COMPANY_PROFILE",
  COMPANY_DETAILS: "COMPANY_DETAILS",
  COMPANY_SOCIALS: "COMPANY_SOCIALS",
  COMPANY_INDUSTRY: "COMPANY_INDUSTRY",
  COMPANY_PHASE: "COMPANY_PHASE",
  COMPANY_FINANCE: "COMPANY_FINANCE",
  COMPANY_MARKET: "COMPANY_MARKET",
  COMPANY_BUSINESS_MODEL: "COMPANY_BUSINESS_MODEL",
  COMPANY_FOUNDERS: "COMPANY_FOUNDERS",
  COMPANY_TEAM_MEMBERS: "COMPANY_TEAM_MEMBERS",
  COMPANY_KEY_FIGURES: "COMPANY_KEY_FIGURES",
  COMPANY_SDG: "COMPANY_SDG",
  COMPANY_INVESTORS: "COMPANY_INVESTORS",
  COMPANY_GALLERY: "COMPANY_GALLERY",
  COMPANY_STYLE: "COMPANY_STYLE",

  INVESTOR_PROFILE: "INVESTOR_PROFILE",
  INVESTMENT_STRATEGY: "INVESTMENT_STRATEGY",
  INVESTOR_SDG: "INVESTOR_SDG",
  INVESTOR_DETAILS: "INVESTOR_DETAILS",
  INVESTOR_DESCRIPTION: "INVESTOR_DESCRIPTION",
  INVESTOR_INDUSTRY: "INVESTOR_INDUSTRY",
  INVESTOR_TEAM_MEMBERS: "INVESTOR_TEAM_MEMBERS",
  INVESTOR_PRIOR_INVESTMENTS: "INVESTOR_PRIOR_INVESTMENTS",
};

const SDGFun = () => {
  const lang = getLanguage();
  return [
    lang.noPoverty,
    lang.noHunger,
    lang.goodHealthAndWellBeing,
    lang.goodEducationForEveryone,
    lang.equality,
    lang.cleanWaterAndSenitationForEveryOne,
    lang.sustainableEnergyForEveryone,
    lang.decentWorkingConditionsAndEconomicGrowth,
    lang.sustainableIndustryInnovationsAndInfrastructure,
    lang.reducedInequality,
    lang.sustainableCitiesAndCommunities,
    lang.sustainableConsumptionAndProduction,
    lang.fightingClimateChange,
    lang.marineAndMarineResources,
    lang.ecosystemsAndBiodiversity,
    lang.peacefulAndInclusiveSocieties,
    lang.implementationAndGlobalPartnership,
  ];
};

export const CONVERSATION_STATUS = {
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
  PENDING: "PENDING",
  BLOCKED: "BLOCKED",
};

export const TEAM_SIZE = {
  TEAM_0_5: "0-5",
  TEAM_5_20: "5-20",
  TEAM_20_40: "20-40",
  TEAM_40_70: "40-70",
  TEAM_70: "70+",
};

export const CONVERSATION_HISTORY_ACTION = {
  INITIATE_CONTACT: "INITIATE_CONTACT",
  DECLINE_CONTACT: "DECLINE_CONTACT",
  ACCEPT_CONTACT: "ACCEPT_CONTACT",
  BLOCK_CONTACT: "BLOCK_CONTACT",
};

export const NOTIFICATION_TYPES = {
  PROFILE_VISIT: "PROFILE_VISIT",
  NEW_OFFER: "NEW_OFFER",
  NEW_COMPANY_NEWS: "NEW_COMPANY_NEWS",
  NEW_CHAT_REQUEST: "NEW_CHAT_REQUEST",
  CHAT_REQUEST_STATUS_CHANGE: "CHAT_REQUEST_STATUS_CHANGE",
  INVESTOR_REGISTERED: "INVESTOR_REGISTERED",
  COMPANY_REGISTERED: "COMPANY_REGISTERED",
};

export const MAJORSTEPS = {
  WELCOME_PAGE_VISITED: "WELCOME_PAGE_VISITED",
  SUCCESS_POPUP_SHOWN: "SUCCESS_POPUP_SHOWN",
  FIRST_LOGIN_COMPLETED: "FIRST_LOGIN_COMPLETED",
  APPLICATION_SENT: "APPLICATION_SENT",
  TEASER_ONE_ALL_STEPS_DONE: "TEASER_ONE_ALL_STEPS_DONE",
};

export const YearsFun = () => {
  var date = Date().split(" ")[3];
  var years = [];
  if (date) {
    date = parseInt(date);
    for (let i = 0; i <= 15; i++) {
      years.push(date - i.toString());
    }
  }
  return years;
};

export const WebsiteOptions = () => ["https://", "http://"];

const DocumentCategoryFun = () => {
  const lang = getLanguage();
  return {
    Management: lang.management,
    Financials: lang.financials,
    Market: lang.market,
    Tech: lang.tech,
    Legal: lang.legal,
    Hr: lang.hr,
  };
};

const SmartCityCategoriesFun = () => {
  const lang = getLanguage();
  return {
    REAL_ESTATE_FINTECH: lang.realEstateFintech,
    PLANNING: lang.planning,
    CONTECH: lang.contech,
    ALTERNATIVE_MATERIALS: lang.alternativeMaterials,
    GREEN_BUILDINGS: lang.greenBuildings,
    ENERGY_EFFICIENCY: lang.energyEfficiency,
    DIGITALISATION_OF_REAL_ESTATE: lang.digitalisationOfRealEstate,
    FUTURE_OF_WORK: lang.futureOfWork,
    ENERGY_MANAGEMENT: lang.energyManagement,
    FUTURE_OF_CITY_LIFE: lang.futureOfCityLife,
    INFRASTRUCTURE: lang.infrastructure,
    UTILITIES_AND_WATER_MANAGEMENT: lang.utilitiesAndWatermanagement,
    SERVICES: lang.services,
  };
};

const PeriodicityFun = () => {
  const lang = getLanguage();
  return {
    Daily: lang.daily,
    Weekly: lang.weekly,
    Monthly: lang.monthly,
    Quarterly: lang.quarterly,
    Annually: lang.annually,
  };
};

const ProfileAnalyticsDaysFun = () => {
  const lang = getLanguage();
  return {
    7: lang.thisWeek,
    30: lang.daily,
    Monthly: lang.monthly,
  };
};

const ProfileAnalyticsYearFun = () => {
  const lang = getLanguage();
  return {
    7: lang.past7Days,
    30: lang.past30Days,
    90: lang.pastThreeMonth,
  };
};

const UsersFun = () => {
  const lang = getLanguage();
  return {
    Users: lang.users,
    Customers: lang.customers,
    Suppliers: lang.suppliers,
    SMBs: lang.SMBs,
    Subscribers: lang.subscribers,
    Users_Paying: lang.usersPaying,
  };
};

const TimeFun = () => {
  const lang = getLanguage();
  return {
    DAYS: lang.days,
    WEEKS: lang.weeks,
    MONTHS: lang.months,
    QUARTERS: lang.quarters,
    YEARS: lang.years,
  };
};

const ApplicationStatus = () => {
  const lang = getLanguage();
  return [lang.all, lang.pending, lang.approved];
};

const RatePeriodFun = () => {
  const lang = getLanguage();
  return {
    DAY: lang.day,
    WEEK: lang.week,
    MONTH: lang.month,
    QUARTER: lang.quarter,
    YEAR: lang.year,
  };
};

const PerRatePeriodFun = () => {
  const lang = getLanguage();
  return {
    DAY: `/${lang.day}`,
    WEEK: `/${lang.week}`,
    MONTH: `/${lang.month}`,
    QUARTER: `/${lang.quarter}`,
    YEAR: `/${lang.year}`,
  };
};

const DemandFreqUnitsFun = () => {
  const lang = getLanguage();
  return {
    HOURS: lang.hours,
    VISITS: lang.visits,
    PURCHASES: lang.purchases,
  };
};

const SupplyFreqUnitsFun = () => {
  const lang = getLanguage();
  return {
    TRANSACTIONS: lang.transactioins,
    HOURS: lang.hours,
    VISITS: lang.visits,
    ITEMS: lang.items,
    CONTRACTS: lang.contracts,
  };
};

const ChurnRatePeriodsFun = () => {
  const lang = getLanguage();
  return {
    MONTH: `/${lang.month}`,
    YEAR: `/${lang.year}`,
  };
};

const GrowthPerRatePeriodFun = () => {
  const lang = getLanguage();
  return {
    DAY: `/${lang.day}`,
    WEEK: `/${lang.week}`,
    MONTH: `/${lang.month}`,
  };
};

const GrowthTimeFun = () => {
  const lang = getLanguage();
  return {
    MONTHS: lang.months,
  };
};

const SDG = SDGFun();
const PHASES = PhaseFun();
const MARKETS = MarketsFun();
const GENDERS = GendersFun();
const OFFER_STATUS = OfferStatusFun();
const FINANCE_PHASES = FinancePhaseFun();
const FINANCE_TYPE = FinanceTypeFun();
const FINANCE_URGE = FinanceUrgeFun();
const RAISED_BEFORE = RaisedBeforeFun();
const INVESTOR_TYPES = InvestorTypeFun();
const SEARCHING_FINANCE = SearchingPhaseFun();
const ACCOUNT_TYPES_SWEDISH = AccountTypeSwedishFun();
const OFFER_CATEGORIES_SWEDISH = OfferCategorySwedishFun();
const INDUSTRY_CATEGORIES_SWEDISH = IndustryCategorySwedishFun();
const CURRENT_COMPANY_STAGE = CurrentStageCompanyFun();
const BUSINESS_CATEGORY = BusinessCategoryFun();
const YEAR_OPTIONS = YearsFun();
const RAISEDFROM = RaisedFromFun();
const PERIODICITY = PeriodicityFun();
const USERS = UsersFun();
const RATEPERIOD = RatePeriodFun();
const DEMANDFREQUNITS = DemandFreqUnitsFun();
const SUPPLYFREQUNITS = SupplyFreqUnitsFun();
const CHURNRATEPERIODS = ChurnRatePeriodsFun();
const GROWTHPERRATEPERIOD = GrowthPerRatePeriodFun();
const GROWTHTIME = GrowthTimeFun();
const TIME = TimeFun();
const PERRATEPERIOD = PerRatePeriodFun();
const SMART_CITY_CATEGORIES = SmartCityCategoriesFun();
const DOCUMENT_CATEGORY = DocumentCategoryFun();
const WEBSITE_OPTIONS = WebsiteOptions();
const PROFILE_ANALYTICS_DAYS = ProfileAnalyticsDaysFun();
const PROFILE_ANALYTICS_YEAR = ProfileAnalyticsYearFun();
const APPLICATION_STATUS = ApplicationStatus();

const LINKEDIN_SHARE_URL =
  "https://www.linkedin.com/sharing/share-offsite/?url=";
const TWITTER_SHARE_LINK = "http://twitter.com/share?url=";
const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=";
const WHATSAPP_SHARE_URL = "https://wa.me/?text=";

export {
  SDG,
  PHASES,
  MARKETS,
  GENDERS,
  OFFER_STATUS,
  FINANCE_PHASES,
  FINANCE_TYPE,
  FINANCE_URGE,
  RAISED_BEFORE,
  INVESTOR_TYPES,
  SEARCHING_FINANCE,
  ACCOUNT_TYPES_SWEDISH,
  OFFER_CATEGORIES_SWEDISH,
  INDUSTRY_CATEGORIES_SWEDISH,
  CURRENT_COMPANY_STAGE,
  BUSINESS_CATEGORY,
  YEAR_OPTIONS,
  RAISEDFROM,
  PERIODICITY,
  USERS,
  RATEPERIOD,
  DEMANDFREQUNITS,
  SUPPLYFREQUNITS,
  CHURNRATEPERIODS,
  GROWTHPERRATEPERIOD,
  TIME,
  GROWTHTIME,
  PERRATEPERIOD,
  SMART_CITY_CATEGORIES,
  DOCUMENT_CATEGORY,
  WEBSITE_OPTIONS,
  PROFILE_ANALYTICS_DAYS,
  PROFILE_ANALYTICS_YEAR,
  LINKEDIN_SHARE_URL,
  TWITTER_SHARE_LINK,
  FACEBOOK_SHARE_URL,
  WHATSAPP_SHARE_URL,
  APPLICATION_STATUS,
  ONLY_IMPACT_COMPANY_RESPONSES,
  IS_ACCREDITED_INVESTOR_RESPONSES,
  IS_COUNTRY_RESTRICTED,
};

export const memberType = {
  PENDING: 0,
  ALL_INVITATIONS: 1,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const FUNDRAISING_STAGES = {
  PRE_SEED: "Pre-seed",
  SEED: "Seed",
  SERIES_A: "Series A",
  SERIES_B: "Series B",
  SERIES_C: "Series C",
  IPO: "IPO",
  OTHER: "Other",
};

export const INVESTOR_COMPETENCES = {
  BUSINESS_STRATEGY: "Business strategy",
  SALES: "Sales",
  Pricing: "Pricing",
  SOFTWARE_DEVELOPMENT: "Software development",
  DATA_SCIENCE: "Data science",
  AI_ML: "AI / ML",
  PRODUCT_MANAGEMENT: "Product management",
  BLOCKCHAIN: "Blockchain",
  CLOUD: "Cloud",
  APP_DEVELOPMENT: "App Development",
  CLOUD_SECURITY: "Cloud security",
  CIVIL_MECH_ENG: "Civil and mechanical engineering",
  MEDICAL_RESEARCH: "Medical research",
  PHYSICS: "Physics",
  MATHEMATICS: "Mathematics",
  CHEMISTRY: "Chemistry",
  BIOLOGY: "Biology",
  ACCOUNTING: "Accounting",
  FINANCE: "Finance",
  FUND_RAISING: "Fund raising",
  TAXES: "Taxes",
  TRADE_AND_EX_RATES: "Trade and exchange rates",
  HARDWARE_DEV: "Hardware development",
  HR_MANAGEMENT: "Human resource management",
  RECRUITING: "Recruiting",
  LEGAL: "Legal",
  INTELLECTUAL_PROPERTY: "Intellectual property",
  GROWTH_HACKING: "Growth hacking",
  MARKETING: "Marketing",
  PR: "PR",
  BRANDING: "Branding",
  MARKETING_AUTOMATION: "Marketing automation",
  SOCIAL_MEDIA_MARKETING: "Social media marketing",
  FOOD_PRODUCTION: "Food production",
  SUPPLY_CHAIN: "Supply chain",
  MANUFACTURING: "Manufacturing",
  DESIGN_AND_UX: "Design and user experience",
  COPYWRITING: "Copywriting",
};

export const ACCREDITED_INVESTOR_TOOLTIP =
  "An accredited investor is an individual or a business entity that is allowed to trade securities that may not be registered with financial authorities. If you are unsure about this, you are most likely not an accredited investor.";
