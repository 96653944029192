import { gql } from "@apollo/client";

export const SAVE_ELEVATOR_PITCH = gql`
  mutation saveCompanyPitchStep($pitch: String, $pitchVideoLink: String) {
    saveCompanyPitchStep(pitch: $pitch, pitchVideoLink: $pitchVideoLink) {
      _id
      pitch
      pitchVideoLink
    }
  }
`;

export const GET_ELEVATOR_PITCH = gql`
  query getElevatorPitch {
    me {
      pitch
      pitchVideoLink
    }
  }
`;
