import { FC, Fragment } from "react";
import { getUser } from "models/user/selectors";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as RocketIllustration } from "images/rocket.svg";
import Button from "atoms/Button/Button";
import { useSelector } from "react-redux";
interface Props {
  isShown: boolean;
  close: () => void;
}

const AiaCheckoutSuccess: FC<Props> = ({ isShown, close }) => {
  const user = useSelector(getUser);
  return (
    <Transition show={isShown} appear as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 overflow-hidden"
        onClose={() => null} // hack to make it not closable on click outside
      >
        <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-sm" />
        <div className="fixed inset-0 z-10 overflow-y-auto rounded-lg">
          <div className="flex items-end justify-center min-h-full p-0 text-center sm:items-center sm:py-20 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="absolute flex flex-col items-center font-lato bg-gradient-to-b py-6 from-[#0B0D18]  via-black to-[#0C312E]  z-[101] top-26 left-0 sm:left-[250px] right-0 mx-auto w-full max-w-2xl shadow-xl rounded-2xl">
                <div className="w-1/2 ">
                  <p className="space-x-1 text-3xl sm:text-5xl  font-semibold tracking-[14px] text-white">
                    AIA
                  </p>
                </div>
                <RocketIllustration className="w-1/5 h-auto my-3" />
                <div className="text-sm font-semibold sm:text-lg">
                  <span className="text-white ">You have upgraded to</span>
                  <span className="text-[#28B8AE] ml-2 ">{user?.subscription?.plan.title}</span>
                </div>
                <Button
                  onClick={() => close()}
                  className=" flex w-3/4 sm:w-1/4 mt-4 justify-center text-white z-30 p-2  bg-[#18BEB3] hover:opacity-90"
                >
                  <span className="font-bold ">Upload pitchdeck</span>
                </Button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AiaCheckoutSuccess;
