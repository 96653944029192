import CompanyCard from "organisms/CompanyCard/CompanyCard";
import { FC } from "react";
import ToProfile from "./ToProfile";

const BoardStartups: FC<any> = ({
  currencyData,
  startups = [],
  isPublic = false,
  isDarkMode = false,
  slug = "",
}) => {
  const getStartupLink = (startupSlug: string) =>
    isPublic
      ? `/public/${startupSlug}?us=board&uc=${slug}${
          isDarkMode ? "&dark=1" : ""
        }`
      : `/company/${startupSlug}`;

  return (
    <div>
      <div className="container flex flex-row flex-wrap justify-center mx-auto gap-x-8 gap-y-12 lg:px-6">
        {startups.map((startup: any) => (
          <ToProfile startup={startup} getLink={getStartupLink(startup?.slug)}>
            <CompanyCard
              founders={startup?.founders}
              traction={startup?.traction?.summary}
              _id={startup._id}
              displayName={startup.displayName}
              logo={startup?.logo?.large?.location}
              pitch={startup?.pitch}
              industries={startup?.industries}
              keyFigures={startup?.keyFigures}
              founded={startup?.founded}
              businessModel={startup?.businessModel}
              companyStage={startup?.companyStage}
              valuation={startup?.valuation}
              investors={startup?.investors}
              raisedTarget={startup?.finance?.raisedTarget}
              raisedValue={startup?.finance?.raisedValue}
              currencyList={currencyData?.getCurrenciesList}
              currency={startup?.currency}
              country={startup?.country}
              city={startup?.city}
            />
          </ToProfile>
        ))}
      </div>
    </div>
  );
};

export default BoardStartups;
