import type { FC } from "react";
import { Fragment, useEffect } from "react";
import Button from "atoms/Button/Button";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useDispatch } from "react-redux";
import { refetchUserData } from "models/user/sagaActions";
import FixScrollProblem from "atoms/FixScrollProblem";
import { SAVE_MEETING_LINK } from "queries/startup/startupProfile";

const AddMeetingLinkModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  meetingLink?: string;
}> = ({ isOpen, onClose, meetingLink = "" }) => {
  const dispatch = useDispatch();
  const [addMeetingLink, { loading }] = useMutation(SAVE_MEETING_LINK);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: meetingLink,
    },
    reValidateMode: "onChange",
  });

  useEffect(() => {
    setValue("link", meetingLink);
  }, [meetingLink]);

  const onSubmit = (data: { link: string }) => {
    addMeetingLink({
      variables: {
        link: data.link,
      },
      onCompleted: () => {
        dispatch(
          triggerSnack({
            type: "success",
            title: "Success",
            message: "Your meeting link saved successfully",
            timer: 2000,
          })
        );
        dispatch(refetchUserData({}));
        onClose();
      },
      onError: () => {
        dispatch(
          triggerSnack({
            type: "error",
            title: "Error",
            message:
              "Something went wrong, please check your link and try again",
            timer: 2000,
          })
        );
      },
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-xl p-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <FixScrollProblem />
                <FontAwesomeIcon
                  icon={["fal", "times"]}
                  onClick={onClose}
                  className="absolute scale-150 hover:cursor-pointer top-5 right-8"
                />
                <Dialog.Title as="div">
                  <div className="flex flex-row items-center justify-between mb-3 text-base font-semibold">
                    Setup a scheduling link
                  </div>
                  <p className="text-sm font-normal text-black">
                    Add your Calendly link or other scheduling provider so
                    people can book meetings with you directly from your
                    profile.
                  </p>
                </Dialog.Title>
                <div className="mt-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <label
                      htmlFor="scheduling-link"
                      className="mb-2 text-sm font-semibold"
                    >
                      Scheduling link
                    </label>
                    <div className="flex flex-row justify-between mt-2">
                      <div className="flex flex-row justify-between flex-grow mr-2 bg-gray-100">
                        <input
                          id="scheduling-link"
                          placeholder="Enter your meeting scheduling link here"
                          className="w-full h-full px-2 bg-gray-100 placeholder:italic placeholder:text-gray-100 placeholder:text-sm"
                          {...register("link", {
                            required: {
                              value: true,
                              message: "Link is mandatory",
                            },
                            pattern: {
                              message: "Link is not valid",
                              value:
                                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                            },
                            disabled: loading,
                          })}
                        />
                      </div>

                      <Button
                        color="primary"
                        size="small"
                        type="submit"
                        loading={loading}
                      >
                        Save link
                      </Button>
                    </div>
                    {errors.link?.message && (
                      <span className="text-xs text-red-500">
                        {errors.link?.message}
                      </span>
                    )}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMeetingLinkModal;
