// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const triggerOfferModal = createAction(
  "organisms/sagaActions/offerModal",
  (payload) => ({ payload })
);

export const getOfferById = createAction(
  "organisms/sagaActions/getOfferById",
  (payload) => ({ payload })
);

export const takeAdvantageOfOffer = createAction(
  "organisms/sagaActions/takeAdvantage",
  (payload) => ({ payload })
);
