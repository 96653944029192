// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { UiRepresentation } from "gql/graphql";
import { API_STATUS } from "models/auth/constants";

interface State {
  status: API_STATUS;
  companyDetails: any | null;
  currencyDetails: any | null;
  tractionDetails: any | null;
  analysisDetails: any | null;
  competitorDetails: any | null;
  uiRepresentationDetails: UiRepresentation | null;
}

export const initialState: State = {
  status: API_STATUS.PENDING,
  companyDetails: null,
  currencyDetails: null,
  tractionDetails: null,
  analysisDetails: null,
  competitorDetails: null,
  uiRepresentationDetails: null,
};

export const name = "companyProfile";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateCompanyDetails: (state, { payload: { companyDetails } }) => ({
      ...state,
      companyDetails,
    }),
    setCurrencyDetails: (state, { payload: { currencyDetails } }) => ({
      ...state,
      currencyDetails,
    }),
    setTractionDetails: (state, { payload: { tractionDetails } }) => ({
      ...state,
      tractionDetails,
    }),
    setAnalysisDetails: (state, { payload: { analysisDetails } }) => ({
      ...state,
      analysisDetails,
    }),
    setCompetitorDetails: (state, { payload: { competitorDetails } }) => ({
      ...state,
      competitorDetails,
    }),
    setUiRepresentationDetails: (
      state,
      { payload: { uiRepresentationDetails } }
    ) => ({
      ...state,
      uiRepresentationDetails,
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
    builder.addCase("isPublicProfile", (state) => ({
      ...state,
      isPublicProfile: true,
    }));
  },
});

export default slice;

export const {
  updateCompanyDetails,
  setCurrencyDetails,
  setTractionDetails,
  setAnalysisDetails,
  setCompetitorDetails,
  setUiRepresentationDetails,
} = slice.actions;
