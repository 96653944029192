// @ts-nocheck

import { Link } from "react-router-dom";

const ConfirmResetPassword = (props) => {
  const { language: lang } = props;
  return (
    <div
      className="px-4 pt-5 pb-4 overflow-hidden bg-white rounded-lg shadow-xl transition-all transform sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div>
        <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-aqua-100">
          <svg
            className="w-6 h-6 text-aqua-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg font-medium text-gray-900 leading-6"
            id="modal-headline"
          >
            {lang.passwordReset}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500 leading-5">
              {lang.clickTheButtonBelowToLoginWithNewPassword}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <span className="flex w-full rounded-md shadow-sm">
          <Link to="/login" className="w-full">
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent leading-6 transition duration-150 ease-in-out rounded-md shadow-sm bg-aqua-400 hover:bg-aqua-500 focus:outline-none focus:border-aqua-700 focus:ring-aqua sm:text-sm sm:leading-5"
            >
              {lang.signIn}
            </button>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default ConfirmResetPassword;
