// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getImpact = createAction("companySettings/impact/fetch");

export const saveImpact = createAction(
  "companySettings/impact/save",
  (payload) => ({
    payload,
  })
);

export const updateIsImpact = createAction(
  "companySettings/impact/isImpactValue/update",
  (payload) => ({
    payload,
  })
);
