import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { Role, Roles } from "./types";

interface Props {
  selectedRole: Role;
  styleForInvitationModal?: boolean;
  onChange: (role: Role) => void;
}

const RoleSelector: React.FC<Props> = ({
  selectedRole: selected,
  styleForInvitationModal,
  onChange,
}) => {
  const onRoleChange = (role: Role) => {
    onChange(role);
  };

  return (
    <Listbox value={selected} onChange={onRoleChange}>
      <div className="relative mt-1">
        <Listbox.Button
          className={cn(
            "relative w-auto py-2 pl-0 text-left rounded-lg cursor-default hover:cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm",
            styleForInvitationModal ? "pr-7" : "pr-10"
          )}
        >
          <span
            className={cn(
              "block truncate",
              styleForInvitationModal && "text-aqua-400 font-medium"
            )}
          >
            {selected.name}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <FontAwesomeIcon
              icon={["fas", "chevron-down"]}
              style={{ color: "#18BEB3" }}
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={cn(
              "absolute w-auto py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              { "right-0": styleForInvitationModal }
            )}
          >
            {Object.values(Roles).map((role) => (
              <Listbox.Option
                key={role.id}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 z-10 bg-inherit ${
                    active ? "bg-aqua-50 text-amber-900" : "text-gray-900"
                  }`
                }
                value={role}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {role.name}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <FontAwesomeIcon
                          icon={["fas", "check"]}
                          className="text-[#18BEB3]"
                        />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default RoleSelector;
