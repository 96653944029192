import type { SignUpType, StepProps } from "./interfaces";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as KeyIcon } from "images/key.svg";
import { ReactComponent as EyeIcon } from "images/eye.svg";
import { ReactComponent as TickMark } from "images/tick-mark.svg";
import { TicksDecor } from "./decors";
import GoogleAuth from "organisms/GoogleAuth/GoogleAuth";
import { Transition } from "@headlessui/react";
import { EMAIL_PATTERN } from "core/consts";
import cn from "classnames";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "atoms/Loading/Loading";
import { useMutation, useQuery } from "@apollo/client";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Checkbox from "atoms/Checkbox/Checkbox";
import { mixpanelReferred, mixpanelSignUp } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import { GET_REFERRAL } from "queries/onboard/onboard";
import { SIGN_UP_MUTATION, LOGIN_MUTATION } from "queries/auth/auth";
import TermsAndConditions from "pages/auth/LoginPage/components/TermsAndConditions";
import { Controller, useForm } from "react-hook-form";

const getSignUsAs = (type: SignUpType) => {
  if (type === "startup") {
    return "COMPANY";
  }

  return "INVESTOR";
};

const AccountStep: FC<StepProps> = ({ onNext, show, payload }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [name] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const user = useSelector(getUser);
  const referral = useRef<string | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<{ email: string; password: string; isAgreed: boolean }>({
    defaultValues: {
      email: "",
      password: "",
      isAgreed: false,
    },
    criteriaMode: "all",
    mode: "onChange",
    shouldFocusError: true,
  });

  const passwordErrors = errors.password?.types;

  const PASSWORD_RULES = [
    {
      key: "specialChar",
      value: "Special character",
      isSatisfied: dirtyFields.password && !passwordErrors?.specialChar,
    },
    {
      key: "atLeastOneNumber",
      value: "At least one number",
      isSatisfied: dirtyFields.password && !passwordErrors?.atLeastOneNumber,
    },
    {
      key: "minLength",
      value: "At least 8 characters",
      isSatisfied: dirtyFields.password && !passwordErrors?.minLength,
    },
  ] as const;

  referral.current = searchParams.get("referral");

  const { data: referralData } = useQuery(GET_REFERRAL, {
    variables: {
      slug: referral.current,
    },
    skip: !referral.current,
  });

  const [signUp, { loading: isRegisterLoading }] =
    useMutation(SIGN_UP_MUTATION);
  const [signIn, { loading: isLoginLoading }] = useMutation(LOGIN_MUTATION);

  const isLoading = isRegisterLoading || isLoginLoading;

  const triggerError = (message: string | undefined | null) =>
    dispatch(
      triggerSnack({
        type: "error",
        title: "Error occurred",
        message: message || "Something went wrong, please try again later",
      })
    );

  const getReferralUsed = () => {
    return referralData?.referral?.id || undefined;
  };

  const handleRegister = async () => {
    if (isLoading) {
      return;
    }

    const userType = payload.type ? getSignUsAs(payload.type) : null;
    const { email, password, isAgreed } = getValues();

    try {
      await signUp({
        variables: {
          input: {
            name,
            email,
            password,
            signUpAs: userType,
            referralUsed: getReferralUsed(),
            policiesAndTermsAgreed: isAgreed,
          },
        },
      });

      // Item to be used for avtivation indicating user is coming from onboarding
      localStorage.setItem("isFromOnboarding", "true");

      await signIn({
        variables: {
          email,
          password,
        },
      });

      mixpanelSignUp(email, userType);
      if (referralData) {
        mixpanelReferred(referralData);
      }

      window.dataLayer?.push({
        event: "Pageview",
        "Page Path": "/signup successful",
        "Data Layer": `from ${payload.type} portal`,
      });

      onNext();
    } catch (error: any) {
      triggerError(error.message);
    }
  };

  useEffect(() => {
    if (user && !user?.isOnboarding) {
      navigate("/profile");
    }
  }, [user, navigate]);

  const triggerErrorManually = () => {
    trigger("isAgreed");
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 w-screen h-screen animate-account-decor">
          <TicksDecor />
        </div>
      )}
      <Transition show={show}>
        <Transition.Child
          className="relative z-30 flex flex-col items-center justify-center w-screen h-screen transition"
          enter="easy-in-out duration-700"
          enterFrom="opacity-0 scale-95 translate-y-24"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="duration-700"
          leaveTo="scale-95 opacity-0 translate-y-96"
        >
          <div>
            {payload.type === "investor" && (
              <div className="w-[400px] p-6">
                <h1 className="text-gray-300 text-2xl text-center mb-2">
                  Become a data-driven VC
                </h1>
                <h2 className="text-gray-400 text-sm text-center">
                  Join hundreds of VCs using Sircular to automate the analysis
                  of companies in their Dealflow
                </h2>
              </div>
            )}
            <div className="bg-white flex flex-col items-center w-[400px] pt-10 pb-6 px-12 text-gray-800 rounded-lg">
              <div className="w-full">
                {isLoading && (
                  <div className="flex justify-center w-full mb-4 min-h-[24px]">
                    <Loading size="medium" />
                  </div>
                )}
                <form onSubmit={handleSubmit(handleRegister)}>
                  <label
                    className={cn(
                      "flex items-center w-full p-2 border mb-4 rounded-lg",
                      errors.email ? "border-red-400" : "border-gray-300 "
                    )}
                  >
                    <EmailIcon width="24" height="24" />
                    <input
                      type="text"
                      {...register("email", {
                        required: true,
                        pattern: EMAIL_PATTERN,
                      })}
                      className="w-full ml-2 border-none"
                      placeholder="Email"
                    />
                  </label>
                  <label
                    className={cn(
                      "flex items-center w-full p-2 border rounded-lg",
                      errors.password ? "border-red-400" : "border-gray-300 "
                    )}
                  >
                    <KeyIcon width="24" height="24" />
                    <input
                      type={isPassword ? "password" : "text"}
                      {...register("password", {
                        required: true,
                        validate: {
                          specialChar: (v) =>
                            /[$&+,:;=?@#|'<>.^*()%!-]/.test(v),
                          atLeastOneNumber: (v) => /[0-9]/.test(v),
                          minLength: (v) => v.length >= 8,
                        },
                      })}
                      className="w-full ml-2 border-none"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={() => setIsPassword(!isPassword)}
                      className="rounded-full cursor-pointer"
                    >
                      <EyeIcon width="24" height="24" />
                    </button>
                  </label>
                  <div>
                    {PASSWORD_RULES.map(({ key, value, isSatisfied }) => (
                      <div key={key} className="flex items-center mt-2">
                        <span
                          className={cn(
                            "border flex justify-center items-center p-1 rounded-full text-white w-[20px] h-[20px] transition duration-100",
                            isSatisfied
                              ? "bg-[#5EC253] border-transparent"
                              : "border-gray-400"
                          )}
                        >
                          <TickMark />
                        </span>
                        <span className="ml-2 text-gray-500">{value}</span>
                      </div>
                    ))}
                  </div>
                  <Controller
                    name="isAgreed"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Checkbox
                        checked={value}
                        isError={error?.type === "required"}
                        onChange={onChange}
                        className="mt-4 text-xs"
                        label={<TermsAndConditions />}
                      />
                    )}
                  />
                  {errors.isAgreed && (
                    <p className="my-2 text-sm text-red-400">
                      Please agree to the terms and conditions
                    </p>
                  )}

                  <button
                    className="flex justify-center w-full py-2 mt-6 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                    type="submit"
                  >
                    Create account
                  </button>
                </form>
                <GoogleAuth
                  type="SIGN_UP"
                  validate={triggerErrorManually}
                  policiesAndTermsAgreed={watch("isAgreed")}
                  signUpAs={
                    payload.type ? getSignUsAs(payload.type) : undefined
                  }
                  userType={payload.type!}
                  onSignUpSuccess={() => {
                    onNext();
                  }}
                  referralUsed={getReferralUsed()}
                />
              </div>
              <div className="mt-4 text-sm">
                <span>Already have an account?</span>
                <Link className="ml-1 text-aqua-400" to="/login">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};

export default AccountStep;
