import { FC } from "react";

const TermsAndConditions: FC = () => (
  <div className="ml-2 text-xs">
    I agree with{" "}
    <a
      className="text-aqua-400 hover:text-aqua-600"
      target="_blank"
      rel="noreferrer nofollow"
      href="https://www.sircular.io/privacy-policy"
    >
      Privacy policy
    </a>{" "}
    and{" "}
    <a
      className="text-aqua-400 hover:text-aqua-600"
      target="_blank"
      rel="noreferrer nofollow"
      href="https://www.sircular.io/terms-of-service"
    >
      Terms of conditions
    </a>
  </div>
);

export default TermsAndConditions;
