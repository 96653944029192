import { FC, useEffect, useMemo, useState } from "react";
import Joyride, { Placement } from "react-joyride";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ActivationProps, OpenSection } from "../types";
import { Tooltip } from "../Tooltips/Tooltip";

const DealflowActivation: FC<ActivationProps> = ({
  activationStatus,
  setActivationStatus,
  setIsMinimized,
  setOpenSection,
  userType,
}) => {
  const [runActivationTour, setRunActivationTour] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      disableBodyScroll(document.body);
      setIsMinimized(true);
      setRunActivationTour(true);
    }, 1000);

    return () => {
      enableBodyScroll(document.body);
    };
  }, []);

  const getNextSection = (): OpenSection => {
    if (!activationStatus.BoardsOrAnalytics) return "boardsOrAnalytics";
    return "none";
  };

  const steps = useMemo(() => {
    const baseSteps = [
      {
        target: ".dealflow-table",
        content: (
          <div className="text-white grow bg-gradient-to-r from-[#18BEB3] to-[#05999F] px-5 pt-5">
            <h3 className="text-xl">Your Dealflow </h3>
            <p className="text-white max-w-[24rem] mt-2">
              When you invite
              {userType === "investor" ? " a startup " : " an investor "}
              they will show up in a list here.
            </p>
          </div>
        ),
        placement: "bottom-end" as Placement,
        disableBeacon: true,
        spotlightClicks: false,
        disableOverlay: true,
        title: "dealflowActivationFirstStep",
      },
      {
        target:
          userType === "investor"
            ? "#add-company-button"
            : "#add-investor-button",
        content: (
          <div className="text-white grow bg-gradient-to-r from-[#18BEB3] to-[#05999F] px-5 pt-5">
            <h3 className="text-xl">
              Add {userType === "investor" ? "a company" : "an investor"}
            </h3>
            <p className="text-white max-w-[24rem] mt-2">
              Add
              {userType === "investor" ? " a startup " : " an investor "}
              to your Dealflow here. You can enter their information if they are
              not part of our network yet.
            </p>
          </div>
        ),
        disableBeacon: true,
        spotlightClicks: false,
        placement: "bottom-end" as Placement,
      },
    ];

    if (userType === "company") return baseSteps;
    else
      return baseSteps.concat([
        {
          target: "#invite-company-button",
          content: (
            <div className="text-white grow bg-gradient-to-r from-[#18BEB3] to-[#05999F] px-5 pt-5">
              <h3 className="text-xl">Invite startups to your Dealflow</h3>
              <p className="text-white max-w-[24rem] mt-2">
                Do you want to streamline your startup sourcing to a single
                place? Invite startups gives you a link to share with founders,
                it will add them straight to your Dealflow.
              </p>
            </div>
          ),
          disableBeacon: true,
          spotlightClicks: false,
          placement: "bottom-end" as Placement,
        },
        {
          target: ".dealflow-status-filter",
          content: (
            <div className="text-white grow bg-gradient-to-r from-[#18BEB3] to-[#05999F] px-5 pt-5">
              <h3 className="text-xl">Change status</h3>
              <p className="text-white max-w-[24rem] mt-2">
                Have you contacted the company yet? You can change the status of
                the company by using this dropdown. You can also create your own
                statuses.
              </p>
            </div>
          ),
          disableBeacon: true,
          spotlightClicks: false,
          placement: "bottom-start" as Placement,
        },
      ]);
  }, [userType]);

  return (
    <Joyride
      run={runActivationTour}
      continuous
      steps={steps}
      beaconComponent={undefined}
      tooltipComponent={Tooltip}
      disableOverlayClose={true}
      scrollToFirstStep={true}
      showSkipButton={true}
      styles={{
        options: {
          overlayColor: "rgba(0, 0, 0, 0.68)",
          zIndex: 1000,
          arrowColor: "transparent",
        },
        spotlight: {
          borderRadius: 100,
        },
      }}
      callback={({ status, index }) => {
        if (index === 1) {
          enableBodyScroll(document.body);
          window.scrollTo(0, 0);
        }
        if (status === "finished") {
          setActivationStatus({ Dealflow: true });
          setIsMinimized(false);
          setOpenSection(getNextSection());
          enableBodyScroll(document.body);
        } else if (status === "skipped") {
          setActivationStatus({ Dealflow: true });
          enableBodyScroll(document.body);
        }
      }}
    />
  );
};

export default DealflowActivation;
