import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_INVESTOR_TYPE } from "queries/general/account/teamMembers";
import Loading from "atoms/Loading/Loading";
import { getUser } from "models/user/selectors";
import { UpgradePlanModalProvider } from "organisms/AccessControl/UpgradePlanModal";
import cn from "classnames";

import Referral from "./components/Referral/Referral";
import TeamMembers from "./components/TeamMembers/TeamMembers";
import Billing from "./components/Billing/Billing";
import ApiDocs from "./components/ApiDocs/ApiDocs";
import Account from "./components/Account/Account";

const Buttons = [
  { label: "Referral", path: "referral", component: <Referral /> },
  { label: "Team members", path: "team-members", component: <TeamMembers /> },
  { label: "Billing", path: "billing", component: <Billing /> },
  { label: "API", path: "api-docs", component: <ApiDocs /> },
  { label: "Account", path: "account", component: <Account /> },
];

const isMobile = window.matchMedia("(max-width: 768px)").matches;

const AccountSettings = () => {
  const navigate = useNavigate();
  const activeButtonKey = useParams()["*"];
  const { data, loading: isInvestorTypeLoading } = useQuery(GET_INVESTOR_TYPE);
  const isAngelInvestor = data?.me?.investorType === "ANGEL_INVESTOR";
  const userData = useSelector(getUser);

  if (isInvestorTypeLoading) return <Loading fullPage />;

  return (
    <UpgradePlanModalProvider>
      <Transition
        show={true}
        appear={true}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="w-full"
      >
        <div className="bg-cover h-44 bg-[url('images/bg-members-page.png')]" />
        <div className="mt-8 sm:mx-10 main-container">
          <div className="flex flex-col pb-2 border-t sm:border-b sm:flex-row sm:-ml-4 border-t-gray-200 sm:border-b-gray-200 sm:border-t-0">
            {Buttons.map((button) => {
              if (button.path === "team-members" && isAngelInvestor)
                return null;

              if (button.path === "api-docs" && userData?.isCompany)
                return null;

              if (button.path === "account" && userData?.isCompany) return null;

              return (
                <Button
                  button={button}
                  key={button.path}
                  onClick={() => {
                    navigate(button.path);
                  }}
                  isActive={
                    activeButtonKey === button.path ||
                    (button.path === Buttons[0].path && !activeButtonKey)
                  }
                />
              );
            })}
          </div>

          <Routes>
            {!isMobile && <Route index element={Buttons[0].component} />}
            {Buttons.map((button) => {
              if (button.path === "team-members" && isAngelInvestor)
                return null;
              if (button.path === "api-docs" && userData?.isCompany)
                return null;
              if (button.path === "account" && userData?.isCompany) return null;

              return (
                <Route
                  key={button.path}
                  path={button.path}
                  element={button.component}
                />
              );
            })}
          </Routes>
        </div>
      </Transition>
    </UpgradePlanModalProvider>
  );
};

interface Props {
  button: {
    label: string;
    path: string;
  };
  isActive: boolean;
  onClick: () => void;
}

const Button: React.FC<Props> = ({ button, onClick, isActive }) => {
  const className =
    "flex items-center justify-between px-4 py-4 mr-2 text-left text-gray-500 border-b sm:text-sm sm:rounded-full hover:bg-aqua-50 sm:border-none sm:py-1";

  const activeClassName = "sm:text-black sm:bg-aqua-50";

  return (
    <button
      className={cn(className, isActive && activeClassName)}
      key={button.path}
      onClick={onClick}
    >
      <span>{button.label}</span>
      <FontAwesomeIcon
        icon={["fas", "chevron-right"]}
        className="ml-2 sm:hidden"
      />
    </button>
  );
};
export default AccountSettings;
