import { createContext, useContext, useState, PropsWithChildren } from "react";
import { BillingFrequency, PlanContext } from "../interfaces";
import {} from "gql/graphql";

const Context = createContext<PlanContext | undefined>(undefined);

export const PlanProvider = (props: PropsWithChildren<{}>) => {


  const billingFrequencies: BillingFrequency[] = ["MONTHLY","THREE_MONTHLY", "YEARLY"];

  const [selectedFrequency, setselectedFrequency] = useState(
    billingFrequencies[0]
  );

  const toggleBillingFrequency = (frequency: BillingFrequency) => {
    setselectedFrequency(frequency);
  };

  return (
    <Context.Provider
      value={{ billingFrequencies, selectedFrequency, toggleBillingFrequency }}
      {...props}
    />
  );
};

export const usePlanContext = () => {
  const planContext = useContext(Context);

  if (planContext === undefined) {
    throw new Error("plan context not provided");
  }

  return planContext;
};
