// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const saveProfileInfo = createAction(
  "companySettings/profileInfo/save",
  (payload) => ({
    payload,
  })
);

export const getProfileInfo = createAction("companySettings/profileInfo/fetch");

export const removeFounder = createAction(
  "companySettings/profileInfo/removeFounder",
  (payload) => ({
    payload,
  })
);
