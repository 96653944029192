import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";
import cn from "classnames";
import { PROFILE_SECTION_IDS } from "../../aia/ProfileNavigation/constants";
import useProfileNavigation from "../../aia/ProfileNavigation/useProfileNavigation";

interface Props {
  investors: any[];
  isLoading?: boolean;
}

const CapTable: FC<Props> = ({ investors, isLoading }) => {
  const { profileSectionRef } = useProfileNavigation();

  const investorsWithExperience = investors?.filter(
    (investor) => investor.experience?.length > 0
  );
  const investorsWithoutExperience = investors?.filter(
    (investor) =>
      investor.experience === null || investor.experience?.length === 0
  );

  return (
    <div
      id={PROFILE_SECTION_IDS["Cap Table"]}
      ref={profileSectionRef}
      className="mx-auto"
    >
      <SectionHeader title="Cap Table" />
      <div className="-mt-4">
        {isLoading ? (
          <div className="grid grid-cols-2 gap-4 w-full mb-2">
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full my-6">
            {investorsWithExperience?.map((investor, index) => (
              <div
                className="flex flex-col border border-gray-400 rounded-lg p-4"
                key={investor?.name}
              >
                <div className="flex flex-row">
                  <div className="mr-2 rounded-full w-14 h-14 my-auto">
                    <img
                      className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full my-auto"
                      src={
                        investor?.image?.large?.location
                          ? investor?.image?.large?.location
                          : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                              ((index + 1) % 6) + 1
                            }.png`
                      }
                      alt={`member-${index}`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h5 className="text-lg font-bold capitalize">
                      {investor?.name}
                    </h5>
                    {investor?.linkedin && (
                      <a
                        type="linkedin"
                        href={
                          investor?.linkedin.includes("https")
                            ? investor?.linkedin
                            : `https://${investor?.linkedin}`
                        }
                        target="_blank"
                        className="h-5 w-5 rounded-full bg-blue-600 text-white flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="sm" />
                      </a>
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    "aiaProfile",
                    "whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-sm text-gray-700"
                  )}
                >
                  {investor?.experience && investor?.experience.length > 0 && (
                    <>
                      <p className="text-sm font-bold !text-[#24272B] mt-2">
                        Experience:{" "}
                      </p>
                      <div className="flex flex-col">
                        {investor?.experience?.map(
                          (experience: any, index: number) => (
                            <div key={index}>
                              {experience?.company && (
                                <ul>
                                  <li className="text-sm text-gray-700">
                                    {experience?.title
                                      ? experience?.title + " at "
                                      : ""}
                                    {experience?.company}
                                  </li>
                                </ul>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {investorsWithoutExperience?.length > 0 && (
          <div className="flex flex-row overflow-x-scroll pb-2">
            {investorsWithoutExperience.map((investor, index) => (
              <div className="mr-6 flex flex-row">
                <div className="mr-2 rounded-full w-14 h-14 my-auto">
                  <img
                    className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full my-auto"
                    src={
                      investor?.image?.large?.location
                        ? investor?.image?.large?.location
                        : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                            ((index + 1) % 6) + 1
                          }.png`
                    }
                    alt={`member-${index}`}
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-lg font-bold capitalize">
                    {investor?.name}
                  </h5>
                  {investor?.linkedin && (
                    <a
                      type="linkedin"
                      href={
                        investor?.linkedin.includes("https")
                          ? investor?.linkedin
                          : `https://${investor?.linkedin}`
                      }
                      target="_blank"
                      className="h-5 w-5 rounded-full bg-blue-600 text-white flex items-center justify-center"
                    >
                      <FontAwesomeIcon icon={["fab", "linkedin"]} size="sm" />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {!isLoading && (
        <p className="text-sm text-center mt-4">
          There may be more investors on the cap table.
        </p>
      )}
    </div>
  );
};

export default CapTable;
