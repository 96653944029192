// @ts-nocheck
import React from "react";
import { withRouter } from "hooks/withRouter";
import { animation } from "ui/animations";
import { UnavailableLink, ContentSection } from "./ServerError.style";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as UnavailableLinkIcon } from "images/unavailable-link-icon.svg";

const UnavailableLinkScreen = (props) => {
  const { lang } = useLang();

  return (
    <UnavailableLink>
      <ContentSection variants={animation} animate="show" initial="hidden">
        <div className="circle-icon">
          <UnavailableLinkIcon />
        </div>
        <h3 className="text-2xl font-medium">{lang.unlinkAvailableDesc}</h3>
      </ContentSection>
    </UnavailableLink>
  );
};
export default withRouter(UnavailableLinkScreen);
