import { FC, useEffect, useState } from "react";
import { FinancePhase } from "gql/graphql";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { CURRENT_COMPANY_STAGE } from "core/consts";
import ButtonGroup from "molecules/Form/ButtonGroup";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";

interface Props {
  phase: FinancePhase | undefined | null;
}

const StartupStage: FC<Props> = ({ phase }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSeleccted] = useState<string | null>();

  useEffect(() => {
    setSeleccted(phase);
  }, [phase]);

  const dispatch = useDispatch();

  const onStageChange = (data: string[]) => {
    setSeleccted(data[0]);
  };

  const onCancel = () => {
    setSeleccted(phase);
    setIsEditMode(false);
  };

  const onSave = () => {
    dispatch(updateStartupProfile({ fundraising: { phase: selected } }));
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="mt-2">
        <ButtonGroup
          id="startupStage"
          name="startupStage"
          options={Object.entries(CURRENT_COMPANY_STAGE)}
          limit={1}
          type="company"
          onChange={onStageChange}
          className="capitalize"
          defaultValue={phase || undefined}
        />

        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right"
        />
      </div>
    );
  };

  return (
    <EditablePlaceholder
      title="Startup Stage"
      value={phase ? CURRENT_COMPANY_STAGE[phase] : undefined}
      style="secondary"
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default StartupStage;
