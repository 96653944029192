// @ts-nocheck
import tw, { styled, css } from "twin.macro";

export const Sdg = styled.div(() => [
  tw`cursor-pointer opacity-25 relative`,

  css`
    opacity: 1 !important;
    .check-icon {
      position: absolute;
      top: -6px;
      right: -6px;
      width: 20px;
    }
    &:hover {
      opacity: 0.5;
    }

    ${({ active }) =>
      active &&
      css`
        opacity: 1 !important;
      `}

    img {
      border-radius: 0.2rem;
      width: 100%;
      height: auto !important;
    }
  `,
]);
