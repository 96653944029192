import { AiaCompetitor } from "gql/graphql";

export enum CompetitorSource {
  WEB = "web",
  PERPLEXITY = "perplexity",
  PROFILE = "profile",
  SOURCING = "sourcing",
  MANUALLY_ADDED = "manuallyAdded",
}
export interface CompetitorsProps {
  competitors: AiaCompetitor[];
  marketGrowthDrivers: string;
  isCompetitorsFromDeckLoading?: boolean;
  isPerplexityLoading?: boolean;
  isValidatingCompetitors?: boolean;
  competitorsFinished?: boolean;
  companyId: string;
  isPublic?: boolean;
  amITeamMember?: boolean;
  slug?: string;
}

export interface CompetitorProps {
  item: AiaCompetitor;
  companyId: string;
  idsDeepDive: string[];
  handleAddToDeepDive: (id: string) => void;
  isPublic?: boolean;
  amITeamMember?: boolean;
  noOfDeepDives: number;
}
