import { FC, useState } from "react";
import type { GoogleInputPlace, TeaserStepProps } from "../interfaces";
import GoogleInput from "molecules/Form/GoogleInput";
import ButtonGroup from "molecules/Form/ButtonGroup";
import { TEAM_SIZE } from "core/consts";
import { TextField } from "pages/onboard/components";
import cn from "classnames";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";

const Welcome: FC<TeaserStepProps> = ({ onNext }) => {
  const [logo, setLogo] = useState<File | null>(null);
  const [location, setLocation] = useState("");
  const [place, setPlace] = useState<GoogleInputPlace>();
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [lastName, setLastName] = useState("");
  const [teamSize, setTeamSize] = useState<string[]>([]);
  const [website, setWebsite] = useState("");
  const [isError, setIsError] = useState(false);

  const isValid =
    firstName && lastName && companyName && teamSize.length && website && place;

  const handleNext = () => {
    setIsError(!isValid);

    const finalWebsite = website.match(/^https?:\/\//)
      ? website
      : `https://${website}`;

    if (isValid) {
      onNext({
        welcome: {
          logo,
          firstName,
          lastName,
          companyName,
          place,
          teamSize: teamSize[0],
          website: finalWebsite,
        },
      });
    }
  };

  return (
    <div className="flex flex-col justify-between w-full h-full px-2 py-8 md:px-16">
      <div>
        <h2 className="text-xl text-center">Welcome to Sircular!</h2>
        <div className="px-4 mt-8 text-center text-gray-600">
          Let's start by making your profile a bit more personal.
        </div>
        <div className="flex w-full px-6 mt-6">
          <TextField
            label="First name"
            value={firstName}
            onChange={setFirstName}
            isError={isError && !firstName}
          />
        </div>
        <div className="flex w-full px-6 mt-2">
          <TextField
            label="Last name"
            value={lastName}
            onChange={setLastName}
            isError={isError && !lastName}
          />
        </div>
        <div className="flex w-full px-6 mt-2">
          <TextField
            label="Company name"
            value={companyName}
            onChange={setCompanyName}
            isError={isError && !companyName}
          />
        </div>
        <div className="flex flex-col items-start w-full px-6 mt-4">
          <GoogleInput
            label="Where are you located?"
            labelClass="ml-2 text-sm font-normal !text-gray-400"
            getPlace={(newPlace: GoogleInputPlace) => {
              setPlace(newPlace);
              setLocation(newPlace.autoCompleteVal);
            }}
            value={location}
            onChange={(e: any) => setLocation(e.target.value)}
            error={Boolean(isError && !place)}
          />
        </div>

        <div className="flex w-full px-6 mt-4">
          <TextField
            label="Website"
            value={website}
            onChange={setWebsite}
            isError={isError && !website}
          />
        </div>
        <div className="flex flex-col items-start px-6 mt-6">
          <label
            className={cn(
              "mb-2 text-sm ml-2",
              isError && !teamSize.length ? "text-red-400" : "text-gray-400"
            )}
            htmlFor="team-size"
          >
            Your team size
          </label>
          <ButtonGroup
            id="team-size"
            options={Object.entries(TEAM_SIZE)}
            className="!mb-2 !mr-2"
            limit={1}
            onChange={setTeamSize}
          />
        </div>
        <div className="flex justify-center mt-8">
          <ProfilePictureSelect label="Upload logo" onComplete={setLogo} />
        </div>
      </div>
      <div className="flex items-center justify-between mt-6 pb-4">
        <span className="mr-8 text-sm text-red-500">
          {isError && !isValid && "Please fill all required fields"}
        </span>
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Welcome;
