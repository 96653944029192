export const en = {
  lang: "English",
  langSort: "en",
  doNotHaveAnAccount: "Don't have an account?",
  createOneAccountHere: "Create account",
  loginTo: "Login to",
  sircular: "Sircular",
  email: "Email",
  password: "Password",
  rememberMe: "Remember Me",
  forgotYourPassword: "Forgot your password?",
  login: "Login",
  thisIsNotValidEmail: "This is not a valid email address.",
  mandatory: "Mandatory",
  doYouAlreadyHaveAnAccount: "Do you already have an account?",
  signIn: "Sign in",
  registerTo: "Register to",
  createSircularAccount: "Create a Sircular account",
  investor: "Investor",
  theNameOfTheCompany: "Company Name",
  confirmPassword: "Confirm password",
  createAccount: "Create Account",
  firstName: "First name",
  surname: "Surname",
  confirmYourEmail: "Confirm your email.",
  emailConfirmDesc:
    "Your account has been registered. Before you can log in, you need to verify your email. Follow the instructions in the email we sent to the address you provided.",
  okayThanks: "Okay Thanks!",
  forgotPasswordDesc:
    "No problem! Enter the email you used when you started your account and we will send instructions on how to create a new one.",
  forgotPasswordDesc2:
    "If you do not receive an email in your main inbox, please check your spam inbox. And if you want to be a hero, mark us as not spam!",
  back: "Back",
  resetPassword: "Reset Password",
  checkYourInbox: "Check your inbox!",
  instructionHowToResetPass:
    "Instructions on how to reset your password have been sent to your email.",
  startups: "Startups",
  howDidYouHearAboutUs: "How did you hear about us?",
  businessInformation: "Business information",
  details: "Details",
  industry: "Industry",
  fas: "Phase",
  market: "Market",
  sdg: "SDG",
  theTeam: "Team",
  keyFigures: "Key figures",
  gallery: "Gallery",
  apperarance: "Appearance",
  submitApplication: "Submit application",
  updateProfile: "Update Profile",
  changePassword: "Change Password",
  investoreVisibilityDesc:
    "Here you see your visibility for investors. Fill in more information for better visibility!",
  companyVisibilityDesc:
    "Here you see your visibility for companies. Fill in more information for better visibility!",
  solverOrderDoesNotMatch: "The solvent order does not match",
  passwordSaved: "Password saved",
  newPasswordHasBeenSaved: "Your new password has been saved.",
  somethingWentWrong: "Something went wrong",
  weCouldNotSave: "We could not save.",
  save: "Save",
  currentPassword: "Current password",
  newPassword: "New Password",
  settingSaved: "Settings saved",
  yourSettingSaved: "Your settings have been saved.",
  settingDetailsDescription:
    "This information will appear on your company profile and presentation when investors are looking for your profile. fill in a lot of information to be seen better.",
  website: "Website",
  websiteValidation:
    "Please enter a valid URL. Must contain https: // or http://",
  allCompanies: "All companies",
  city: "City",
  foundedYear: "Founded year",
  enterValidYearValidation: "Enter a valid year between 1000 and 2999.",
  noOfEmployees: "Number of employees",
  socialMediaLinks: "Social media links",
  socialMediaLinkDesc:
    "The links below are links to the company's social media that will come to be seen on your company profile.",
  youtube: "Youtube",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  twitter: "Twitter",
  previousStep: "Previous Step",
  nextStep: "Next Step",
  unableToSubmitApplication: "We were unable to submit your application.",
  readyToSubmitApplication: "Ready to submit your application?",
  finalStepDescription:
    "Nice work! Now you are ready to submit your application. Remember to you can go back and change or add at any time information.",
  holdOnReviewingYourApplication: "Hold on! We are reviewing your application.",
  asSoonAsWeAreReadyReceiveAnEmail:
    "As soon as we are ready, you will receive an email and so on instructions.",
  galleryInstruction:
    "Help your investors understand who you are and do profile page more interesting by uploading pictures in the gallery. You can e.g. upload product images or pictures from your office.",
  photoForGallery: "Photos for gallery",
  howDidYouHearAboutSircular: "How did you hear about Sircular?",
  chooseAnOptionAboutSircular:
    "Choose an option that matches how you heard about Sicular.",
  canYouExplainWhere: "Can you explain where?",
  chooseAnIndustriesInWhichYouOperate:
    "Choose up to 3 industries in which you operate.",
  businessInfoInstruction:
    "Ready to create a great first impression? Show off from your best side by uploading a high-resolution logo and write a few lines about you as a company. The more information you posts the better your profile will be visible. Here we go!",
  companyName: "Company Name",
  companyNameIsMandatory: "Company name is mandatory!",
  startupNameIsMandatory: "Startup name is mandatory!",
  hisspitch: "Hisspitch",
  writeAnElevatorPitchForCompanyDesc:
    "Write an elevator pitch for your company. This is shown at the top the company profile. Keep it short!",
  elevatorPitchIsMandatory: "Elevator pitch is mandatory!",
  description: "Description",
  writeAMoreDetailsDescriptionForCompany:
    "Write a more detailed description of your company. What are you product? Who are you? What are your goals?",
  descriptionOfCompanyMandatory: "Description of the company is mandatory!",
  vision: "Vision",
  whatIsCompanyVision: "What is the company's vision?",
  mission: "Mission",
  whatIsCompanyMission: "What is the company's mission?",
  name: "Name",
  remove: "Remove",
  whoHasInvestedInYourCompanyBefore: "Who has invested in your company before?",
  addInvestors: "Add investors",
  enterNameOfFile: "Enter a name for the file",
  keyFigureDesc:
    "Now we delve into the details! Based on the latest financial statements.",
  revenue: "Revenue",
  youMustCompleteATurnover: "You must complete a turnover.",
  NoOfCustomers: "Number of customers",
  profitMargin: "Profit Margin",
  inPercent: "In percent",
  totalAssets: "Total assets",
  writeTheTotalAmount: "Write the total amount",
  caseLiquidity: "Cash liquidity",
  solidity: "Solidity",
  grossProfitMargin: "Gross profit margin",
  forecastEstimatedTuroverInFuture:
    "Forecast - Estimated turnover in future financial statements",
  documentsAndFiles: "Documents and files",
  keyFigureDocumentUploadDesc:
    "Help potential investors get a complete picture of you company. Here you can e.g. upload annual reports, product sheets or other documents that you think create value for one investor.",
  addDocuments: "Add documents",
  marketAndBusinessModal: "Market and business model",
  whichMarketDoYouFocusOn: "Which markets do you focus on?",
  whatIsYourBusinessModal: "What is your business model?",
  whatPhaseAreYouIn: "What phase are you in?",
  chooseWhichPhaseYourCompanyIn: "Choose which phase your company is in.",
  chooseWhichInvestmentStateYourAreIn:
    "Choose which investment stage you are in.",
  areYouLookingForInvestmentsRightNow:
    "Are you looking for investments right now?",
  whatTypeOfInvestmentAreYouLookingFor:
    "What type of investment are you looking for?",
  whenDoYouNeedFinancing: "When do you need financing?",
  haveYouRaisedMoneyBefore: "Have you raised money before?",
  whatValueDidYouRaiseMoney: "To what value did you raise money?",
  writeSumInIntegers: "Write a sum in integers",
  whatYearDidYourRaiseMoney: "What year did you raise money?",
  enterValidYear: "Enter a valid year",
  decorateYourProfile: "Decorate your profile",
  stylingInstruction:
    "Stand out from the crowd by uploading a banner image and choose colors that align with your graphic profile.",
  bannerImage: "Banner image",
  bannerImgVisibleOnYourCompanyProfileOnTop:
    "The banner image is visible on your company profile in the area at the top.",
  video: "Video",
  pasteAndEmbedCodeFromAnyService:
    "Paste the embed code from any video service.",
  pasteValidCodeWithIframe: "Paste a valid embed code with an iframe!",
  primer: "Primer",
  embedCodeForVideo: "Embed code for video",
  sdgTargets: "SDG targets",
  chooseYourComapnyWorksSDGTargets:
    "Choose if your company works towards one or more special ones SDG targets.",
  tellWhyGoalaImportantAndHowYouAchieve:
    "Tell us why these goals are important to you and how you working to achieve them.",
  nameAndSurname: "Name and surname",
  sex: "Sex",
  selectGender: "Select gender",
  title: "Title",
  linkToLinkedin: "Link to LinkedIn",
  profilePicture: "Profile picture",
  uploadProfilePicture: "Upload profile picture",
  foundersAndTeams: "Founders and teams",
  foundersAndTeamsDesc:
    "Tell us more about who is behind your company. Add one or several founders and employees.",
  addFounder: "Add founder",
  team: "Team",
  addTeamMember: "Add team member",
  information: "Information",
  typeOfInvestor: "Type of investor",
  aboutYouAsAnInvestor: "About you as an investor",
  previousInvestments: "Previous investments",
  savedAutomatically: "Saved automatically",
  bannerUpdated: "Banner updated",
  weCouldNotUpdateBanner: "We could not update the banner",
  weCouldNotSaveImage: "We could not save the image",
  weCouldDeleteImage: "We could delete this image",
  logoUpdated: "Logo updated",
  weCouldNotSaveYourLogo: "We could not save your logo",
  logo: "Logo",
  updateCompanyLogo: "Upload the company logo.",
  minimumSize300: "Minimum size 300x300 px",
  passwordAreNotSame: "The passwords are not the same",
  passwordCouldNotResetTryAgain:
    "The password could not be reset. Please try again later.",
  chooseNewPassword: "Choose new password",
  resetPasswordDesc:
    "You have chosen to reset your password. Enter your new password below.",
  resetLinkHasBeenDeleted: "This reset link has been deleted",
  stilDoNotRememberYourPassword:
    "If still do not remember your password. Follow",
  toResetPassword: "to reset the password.",
  thisLink: "this link",
  passwordReset: "Password reset",
  clickTheButtonBelowToLoginWithNewPassword:
    "Click the button below to Sign in with your new password.",
  yourNewPasswordHasBeenSaved: "Your new password has been saved.",
  niceWorkReadyToSubmitAppYouCanGoBackAndChangePass:
    "Nice work! Now you are ready to submit your application. Remember to you can go back and change or add at any time information.",
  industries: "Industries",
  chooseOneOrMoreIndustriesTechnologiesForInvestment:
    "Choose one or more industries and technologies in which you are looking for investment opportunities.",
  selectIndustries: "Select industries",
  investmentName: "Investment name",
  investmentNameIsMandatory: "Investment name is mandatory",
  emailIsMandatory: "Email is mandatory",
  phone: "Phone",
  enterValidAddressForWebsite: "Enter a valid address for your website",
  shortDescription: "short description",
  writeFewLineAboutYourSelf: "Write a few lines about yourself as an investor.",
  shortDescriptionIsMandatory: "A short description is mandatory!",
  anonymous: "Anonymous",
  enableOptionIfRemainAnonymous:
    "Enable this option if you want to remain anonymous. Your image, website name and the team will then be hidden in your profile.",
  investorDescription:
    "Let the companies get to know you a little better. What added value can you create in the company? What is important to you as an investor? What does your investment philosophy look like?",
  descriptionIsMandatory: "A description is mandatory!",
  investorTypeInstruction:
    "Indicate what type of investor you are so that we can match you against the right company.",
  howBigInvestmentAreYOuInterested:
    "How big an investment are you interested in making?",
  whatPhaseWantToCompanyYouInvest:
    "What phase do you want the companies you invest in to be?",
  whatStageWantToInvest: "At what stage do you want to invest?",
  whatBusinessModalWantToInvestIn: "What business models do you invest in?",
  shareYourPreviousInvestments: "Share your previous investments.",
  addInvestment: "Add investment",
  chooseWhetherYouInvestOneOrMoreSpecificSDGS:
    "Tell us why these goals are important to you and how you working to meet them.",
  tellUsMoreAboutYouAndYOurTeam:
    "Tell us more about you and your team. Add one or more of yours coworker.",
  accountAccepted: "Account accepted",
  accountHasBeenAccepted: "The account has been accepted",
  weCouldNotAcceptTheAccount: "We could not accept the account.",
  accountBlocked: "Account blocked",
  accountHasBeenBlocked: "The account has been blocked",
  unableToBlockAccount: "We were unable to block the account.",
  accountUnblocked: "Account unblocked",
  accountHasBeenUnblocked: "The account has been unblocked",
  couldNotUnblockAccount: "We could not unblock the account.",
  unblock: "Unblock",
  block: "Block",
  contact: "Contact",
  approve: "Approve",
  employees: "Employees",
  registeredIt: "Registered it",
  hasSentApplication: "Has sent the application",
  approve: "Approve",
  couldNotCreateNewCompaign: "We could not create a new campaign.",
  offers: "Offers",
  belowYouWillFindAllCreatedOffers:
    "Below you will find all created offers and whether they are active or not.",
  clickReadMoreToSeeMoreOffers:
    "Click on read more to see more information about the offer.",
  createOffer: "Create offer",
  status: "Status",
  order: "Order",
  inquiries: "Inquiries",
  onGoing: "Ongoing",
  show: "Show",
  copyId: "Copy id",
  couldNotCreateNewPodcast: "We could not create a new podcast.",
  podcasts: "Podcasts",
  belowYouWilFindAllPodcasts:
    "Below you will find all created podcasts and if they are active or not.",
  clickOnReadMoreToSeeMoreInfoAboutPodcasts:
    "Click on read more to see more information about each podcast.",
  createPodCast: "Create a podcast",
  publisher: "Publisher",
  length: "Length",
  createdIt: "Created it",
  play: "Play",
  bannerImageVisibleOnTheNews: "The banner image is visible on the news.",
  size200By160: "Size: 200x160",
  adSaved: "Ad saved",
  adHasBeenSaved: "The ad has been saved",
  unableToSave: "Unable to save.",
  bannerRemoved: "Banner removed",
  adHasBeenRemoved: "Ad has been removed",
  adCouldNotBeSaved: "The ad could not be deleted.",
  adSpaceHomeTop: "Ad space - Home top",
  commercialAreaSettingInstruction:
    "Information you fill in below affects the entire system and sends out information to different user groups and users.",
  saveChanges: "Save Changes",
  displayOptions: "Display options",
  sameMessage: "Same message",
  seperateMessage: "Separate messages",
  preTitle: "Pre title",
  titleIsMandatory: "Title is mandatory!",
  link: "Link",
  doNotForgetExternalLink: "Do not forget https: // at external links",
  linkIsMandatory: "Link is mandatory!",
  linkText: "Link text",
  linkTextIsMandatory: "Link text is mandatory!",
  writeTextThatDescribeOffer: "Write a text that describes the offer",
  size400By190: "Size: 400x190",
  adSpaceHomePageCenter: "Ad space - Home page center",
  noMessage: "No message",
  bannerSaved: "Banner saved",
  bannerHasBeenSaved: "The banner has been saved",
  bannerHasBeenRemoved: "The banner has been removed",
  bannerCouldNotBeRemoved: "The banner could not be removed.",
  bannerAtTheTop: "Banner at the top",
  cancelSubscription: "Cancel subscription",
  subscriptionCancelled: "Subscription canceled",
  receipts: "Receipts",
  date: "Date",
  period: "Period",
  open: "Open",
  download: "Download",
  completed: "Completed",
  active: "Active",
  inactive: "Inactive",
  paymentOptions: "Payment options",
  memberSince: "Member since",
  subscription: "Subscription",
  pleaseTryAgain: "Please try again later.",
  youAre: "You are",
  one: "one",
  clickAwayToTakepartInSircular:
    "click away to take part in the Sircular world!",
  favorablePriceFromOurPartners:
    "Favorable prices from our Partners (value SEK 19,999 / month)",
  exposureToSuccessfullInvestors: "Exposure to successful Investors",
  presentYourCompanyInDigitalAttractiveFormat:
    "Present your Company in a digital and attractive format",
  collectCompanyInfoInSircularSys:
    "Collect company information in Sircular's secure system",
  uploadCompanyNewsAndUpdates: "Upload company news and updates",
  networkAndCommunicateWithInvestors: "Network and communicate with Investors",
  legalTemplatesAndFrameworkAgreements:
    "Legal templates and Framework agreements",
  sircularDDApproval: "Sircular DD approval",
  invitationsToEntrepreneurialEvents: "Invitations to entrepreneurial events",
  accessToVariousCoursesWithPartners:
    "Access to various courses with our partners",
  chooseHowYouWantToPay: "Choose how you want to pay",
  monthly: "Monthly",
  mon: "Mon.",
  startMonthlySubscription: "Start monthly subscription",
  yearly: "Yearly",
  year: "Year",
  startAnnualSubscription: "Start annual subscription",
  couldNotLoadCompaniesTryAgainLater:
    "We could not load companies. Please try again later.",
  couldNotLoadInvestorsTryAgainLater:
    "We could not load investors. Please try again later.",
  search: "Search",
  allSDGTargets: "All SDG targets",
  financingStage: "Financing stage",
  allFinancingStage: "All financing stages",
  startupPhase: "Startup phase",
  allStartupPhase: "All startup phases",
  businessModal: "Business model",
  allBusinessModal: "All business models",
  shows: "Shows",
  of: "of",
  allIndustries: "All industries",
  allTypes: "All types",
  showAllCompanies: "Show all companies",
  headOffice: "Head office",
  founded: "Founded",
  lookingForInvestment: "Looking for investment",
  investmentPhase: "Investment phase",
  remainingCapital: "Remaining capital",
  investmentYear: "Investment year",
  socialMedia: "Social media",
  sdgGolas: "SDG goals",
  whyDidWeChooseThese: "Why did we choose these?",
  playVideo: "Play video",
  scrollToReadMore: "Scroll to read more",
  allKeyFiguresShownInThousand: "All figures are shown in",
  thousands: "Thousands",
  keyfigureDescForComapny:
    "Here you will find the latest key figures from the company and reports / documents showing the company's status.",
  salesForecast: "Sales forecast",
  reportAndDocuments: "Reports & documents",
  keyFiguresAndReport: "Key figures and reports",
  newsAndUpdates: "News & Updates",
  startChatting: "Start chatting",
  belowYouWillFindNewsAndUpdatesSuchAs:
    "Below you will find the latest news and updates such as",
  compnayItselfPublishes: "the company itself publishes.",
  weCouldNotSavePromotion: "We could not save this promotion",
  yourAccountCouldNotBeVerified: "Your account could not be verified",
  accountIsAlreadyVerifiedWithThisEmail:
    "There is already a verified account associated with this email.",
  yourAccoundIsVerified: "Your account is now verified.",
  clickTheButtonBelowToLogin: "Click the button below to log in.",
  verifyingYourAccount: "Verifying your account",
  takeAdvantageOfTheOffer: "Take advantage of the offer",
  hello: "Hello",
  followers: "Followers",
  guards: "Bookmark",
  visitThisWeek: "visit this week",
  whatHasHappenedInLastWeek: "What has happened in the last week?",
  inTheLastWeek: "In the last week",
  and: "and",
  joinedAndSircular: "have joined Sircular.",
  company: "company",
  weHave: "We have",
  new: "new",
  exciting: "exciting",
  offer: "offer",
  forYouAsMember: "for you as a member!",
  thisWeekWeHaveAlsoUploaded: "This week we have also uploaded",
  podcastsEpisodeWeRecommendSoYouCanStayUpToDate:
    "podcast episodes that we recommend so you can stay up-to-date in the startup world.",
  myTeaser: "My teaser",
  editTeaser: "Edit teaser",
  latestCompanyOnSircular: "Latest startups on Sircular",
  newsFromCompaniesInYourCoverage: "News from startups in your coverage",
  ourLatestOffer: "Our latest offers",
  latestPodcasts: "Latest podcasts",
  mostPopularCompaniesRightNow: "The most popular startups right now",
  whoVisitedYourProfile: "who visited your profile",
  visitedYourProfile: "Visited your profile",
  bannerImageUpdated: "Banner image updated",
  bannerImageIsVisibleOnTheOffer: "The banner image is visible on the offer.",
  addLine: "add a line",
  belowYouCanSeeWhichCompanyHaveMadeRequestForOffer:
    "Below you can see which companies have made a request to take part of the offer.",
  type: "Type",
  companySize: "Company size",
  requestWasMade: "The request was made",
  weWereUnableToRemoveThisOffer: "We were unable to remove this offer.",
  visibility: "Visibility",
  created: "Created",
  updated: "Updated",
  delete: "Delete",
  published: "Published",
  notVisible: "Not visible",
  offerSaved: "Offer saved",
  saved: "Saved",
  visibilityOfTheOfferHasChanged: "The visibility of the offer has changed",
  visibilityOfTheOfferCouldNotBeChanged:
    "Visibility of this offer could not be changed",
  thisInfoWillDisplayedForAllCompanies:
    "This information will be displayed for all companies.",
  unpublish: "Unpublish",
  publish: "Publish",
  offerPeriod: "Offer period",
  startDate: "Start date",
  endDate: "End date",
  emailForApplication: "Email for applications",
  thisIsNotValidEmailAddress: "This is not a valid email address!",
  linkToTheOffer: "Link to the offer",
  describeWhatOfferContains: "Describe what the offer contains",
  points: "Points",
  brieflyWritePointsThatAre: "Briefly write points that are",
  mainContentOfTheOffer: "the main content of the offer",
  category: "Category",
  chooseWhichCategoryOfferBelongsTo:
    "Choose which category the offer belongs to.",
  podcastSaved: "Podcast saved",
  visibilityOfThePodcastsHasChanged:
    "The visibility of the podcast has changed",
  visibilityCouldNotBeChangedForPodcosts:
    "Visibility could not be changed on this podcast",
  infoWillAppearInThePorcostsDescToAllMembers:
    "This information will appear in the podcast description displayed to all Sircular members.",
  update: "Update",
  publishingIsMandatory: "Publishing is mandatory!",
  lengthIsMandatory: "Length is mandatory!",
  writeDescriptiveTextAsInDetail: "Write a descriptive text as in detail",
  explainWhatOfferContains: "explains what the offer contains.",
  embedLink: "Embed link",
  podcastLinkIsRequired: "Podcast embed link is required!",
  investingInStage: "Investing in stage",
  investmentOpportunities: "Investment opportunities",
  focusAreas: "Focus areas",
  primaryMarkets: "Primary markets",
  investsInTheFollowingSDGS: "Invests in the following SDGs",
  explanationOfSDGs: "Explanation of SDGs",
  messages: "Messages",
  conversationDeleted: "Conversation deleted",
  pushHere: "Push here",
  decline: "Decline",
  accept: "Accept",
  viewProfile: "View profile",
  send: "Send",
  published: "Published",
  draft: "Draft",
  newsSaved: "News Saved",
  couldNotSaveNews: "We could not save this news",
  writeYourNews: "Write your news",
  weCouldNotCreateNewNews: "We could not create any new news.",
  newsFor: "News for",
  writeNews: "Write news",
  uniqueOffersTo: "unique offers to",
  youAsASircularMember: "you as a Sircular member",
  faildedToSendExpressionOfInterest:
    "Failed to send your expression of interest",
  wantToTakeAdvantageOfTheOffer: "I want to take advantage of the offer!",
  weHaveSentYourExpressionOfInterestTo:
    "We have sent your expression of interest to",
  youWillReceiveAnEmailWithinFewDays:
    "You will receive an email within a few working days",
  toYourEmail: "to your email",
  withInformation: "with information",
  aboutHowToTakePartAndTakeAdvOfThisOffer:
    "about how to take part in and take advantage of this offer!",
  showsAllOffers: "Shows all offers.",
  chooseCategory: "Choose category",
  sort: "Sort",
  yourGaurds: "Your watchlist",
  youHaveNoGaurds: "You have no watchlist",
  newBookmark: "Bookmarked",
  companyWasAddedToYourWatchdog: "Startup has been bookmarked.",
  companyCouldNotBeAdded: "The startup could not be added",
  bookmarkedRemoved: "Bookmark Removed",
  companyRemovedFromYourGuards: "Startup bookmark has been removed.",
  companyCouldNotBeRemoved: "The startup could not be removed",
  watch: "Watch",
  endBookmark: "End bookmark",
  subscriptionCompleted: "Subscription completed",
  writeYourFeedbackHere: "Write your feedback here",
  cancel: "Cancel",
  closeAccount: "Close Account",
  SEKTurnover: "SEK turnover",
  initiateContactWith: "Initiate contact with",
  after: "After",
  once: "Once",
  fileIsTooLarge: "The file is too large",
  imageIsTooSmallInWidth: "The image is too small in width",
  imageIsTooSmallInHeight: "The image is too small in height",
  imageIsNotSquare: "The image is not square",
  uploadOne: "Upload one",
  fileOr: "file or",
  changePicture: "change picture",
  changeFile: "Change file",
  clear: "Clear",
  ok: "Ok",
  investsInSDGS: "Invests in SDGs",
  menu: "Menu",
  homePage: "Home page",
  yourAccount: "Your account",
  settings: "Settings",
  manageNews: "Manage news",
  manageSubscription: "Manage subscription",
  admin: "Admin",
  manageCompanies: "Manage companies",
  manageInvestors: "Manage investors",
  manageOffers: "Manage offers",
  managePodcasts: "Manage podcasts",
  logOut: "Log out",
  accepted: "Accepted",
  declined: "Declined",
  hasPublishedNewOneOffer: "has published a new one offer",
  wroteAnUpdate: "wrote an update",
  hasSentChatRequest: "has sent a chat request to you!",
  your: "your",
  chatRequest: "chat request!",
  unread: "unread",
  markEveryoneAsRead: "Mark everyone as read",
  loadMore: "Load more",
  notifications: "Notifications",
  readMore: "Read more",
  NoResultFoundFourYourSearch: "No results were found for your search",
  trySearchingForSomethingElse: "Try searching for something else!",
  teamDescription:
    "Below you will find the teams that work actively in our company.",
  inquiry: "inquiry",
  registrationFailed: "Registration failed",
  emailAlreadyBeenTaken: "Email has already been taken",
  selectLanguage: "Select Language",
  google: "Google",
  friend: "Friend",
  event: "Event",
  other: "Other",
  id: "Id",
  productOrProtoType: "Product or prototype",
  goToMarket: "Go to market",
  growthAndExpansion: "Growth and expansion",
  venture: "Venture",
  accelerator: "Accelerator",
  angel: "Angel",
  bank: "Bank",
  familyOffice: "Family Office",
  gender: "Gender",
  man: "Male",
  woman: "Female",
  nonBinary: "Other",
  developed: "Developed",
  emerging: "Emerging",
  developedAndEmerging: "Developed and emerging",
  yes: "Yes",
  no: "No",
  months: "Months",
  contribution: "Contribution",
  loansAndCredit: "Loans / Credit",
  investment: "Investment",
  bootstraping: "Bootstrapping",
  preSeed: "Pre-seed",
  seed: "Seed",
  series: "Series",
  legalAidManagementAndDocTemplates:
    "Legal aid, crisis management and document templates",
  insurance: "Insurance",
  economyAndProfitability: "Economy and profitability",
  officeAndShipping: "Office and shipping",
  training: "Training",
  carsAndFuel: "Cars and fuel",
  travelAndHotels: "Travel and hotels",
  healthAndWellNess: "Health and wellness",
  advertisingAndPR: "Advertising and PR",
  agriculture: "Agriculture",
  consumerGoods: "Consumer goods",
  energyAndGreentech: "Energy & Greentech",
  homeAndLeisure: "Home & Leisure",
  fashion: "Fashion",
  fintech: "Fintech",
  medtech: "Medtech",
  financeAndInsurance: "Finance & insurance",
  realEstateAndProptech: "Real Estate & Proptech",
  metalsAndMines: "Metals & mines",
  foodAndDrinks: "Food and drinks",
  health: "Health",
  gaming: "Gaming",
  eSport: "E-sports",
  lifeScienceAndBiotech: "Life Science & Biotech",
  itAndSoftware: "IT & software",
  hrAndRecruitment: "HR & recruitment",
  law: "Law",
  manufacturing: "Manufacturing",
  shipping: "Shipping",
  transportAndLogistics: "Transport & logistics",
  eCommerce: "E-commerce",
  mediaAndEntertainment: "Media & Entrainment",
  musicAndSound: "Music and sound",
  retail: "Retail",
  automation: "Automation",
  salesAndMarketing: "sales and marketing",
  scienceAndTechnology: "Science & technology",
  service: "Service",
  sportAndTraining: "Sports & training",
  teleCommunication: "Telecommunication",
  travelAndTourism: "Travel & tourism",
  food: "Food",
  restaurant: "Restaurant",
  pets: "Pets",
  drug: "Drug",
  environmentCleanTech: "Environment clean tech",
  planned: "Planned",
  onGoing: "Ongoing",
  noPoverty: "No poverty",
  noHunger: "No hunger",
  goodHealthAndWellBeing: "Good health and well-being",
  goodEducationForEveryone: "Good education for everyone",
  equality: "Equality",
  cleanWaterAndSenitationForEveryOne: "Clean water and sanitation for everyone",
  sustainableEnergyForEveryone: "Sustainable energy for everyone",
  decentWorkingConditionsAndEconomicGrowth:
    "Decent working conditions and economic growth",
  sustainableIndustryInnovationsAndInfrastructure:
    "Sustainable industry, innovations and infrastructure",
  reducedInequality: "Reduced inequality",
  sustainableCitiesAndCommunities: "Sustainable cities and communities",
  sustainableConsumptionAndProduction: "Sustainable consumption and production",
  fightingClimateChange: "Fighting climate change",
  marineAndMarineResources: "Marine and marine resources",
  ecosystemsAndBiodiversity: "Ecosystems and biodiversity",
  peacefulAndInclusiveSocieties: "Peaceful and inclusive societies",
  implementationAndGlobalPartnership: "Implementation and global partnership",
  changeLanguage: "Change Language",
  theOffer: "Offer",
  writeYourMessage: "Write your message",
  oldest: "Oldest",
  newest: "Latest",
  mostListened: "Most listened",
  shareTeaser: "Share Teaser",
  youCanChooseMaximum: "You can choose a maximum of",
  choices: "choices",
  pasteLinkToWebsite: "Paste link to website",
  profileCompleted: "Profile Completed",
  startPhase2: "Start Phase 2",
  pictureMissing: "Picture missing",
  profile: "Profile",
  enterCEOFounderName: "Enter CEO/Founder's Name",
  enterCEOFoundersEmail: "Enter CEO/Founder's Email",
  enterEmailAddress: "Enter email address",
  enterLinkedinLink: "Enter linkedin link",
  browse: "browse",
  dropYourLogoHereOr: "Drop your logo here or",
  saveAndContinue: "Save & Continue",
  preview: "Preview",
  addCoFounder: "Add Founder",
  basics: "Basics",
  less: "Less",
  more: "More",
  whatIsCurrentStageOfStartup: "What is the current stage of your startup?",
  whoDidYouRaiseFrom: "Who did you raise from?",
  preProduct: "Pre-Product",
  preRevenue: "Pre-Revenue",
  postRevenue: "Post-Revenue",
  marketNetwork: "Market Network",
  marketPlace: "Marketplace",
  hardware: "Hardware",
  foundingDetails: "Founding details",
  companyWebsite: "Company website",
  enterLocation: "Enter location",
  team: "Team",
  founderDetails: "Founder details",
  memberDetails: "Member details",
  addAMember: "Add a member",
  enterName: "Enter name",
  titleRole: "Title/Role",
  settingUpYourProfile: "Setting up your profile",
  thisIsPrivateByDefault: "All eyes are on your idea!",
  thisIsPrivateDescription:
    "Introduce your idea and product to potential investors as well as you can. Making a great first impression is important for getting the investments you need. Make it count!",
  foundersReport: "From the founder's desk",
  foundersReportDescription:
    "of investors move on if your teaser does not brilliantly showcase your company and potential product.",
  elevatorPitch: "Elevator pitch",
  elevatorPitchDesc:
    "Let VCs know that you mean business in under 30 seconds! Write a compelling story of your idea in one line, or at max. two.",
  addYourElevatorPitchHere: "Add your elevator pitch here",
  socialNetworks: "Social networks",
  sdgGoalsDesc:
    "Showcase your company's commitment to sustainability by selecting  one or more SDG goals.",
  uploadDocuments: "Upload documents",
  documents: "Documents",
  selectYourTeamSize: "Select your team size",
  documentDescription:
    "Remember, this is private. You can include a shortened deck or your long deck. Best practice is 6-14 slides.",
  dropProfilePictureHereOr: "Drop profile picture here or",
  dropYourFilesHereOr: "Drop your files here or",
  fundraising: "Fundraising",
  fundraisingDescription:
    "Mention details about your fundraising history and goals on the new round",
  howMuchMoneyHaveYouRaised: "How much money have you raised?",
  enterAmount: "Enter amount",
  addANote: "Add a note",
  canNotFindYourInvestor: "Can't find your investor?",
  addManually: "Add manually",
  whatIsYourFundraisingTarget: "What is your fundraising target?",
  add: "Add",
  enterFullName: "Enter full name",
  enterFirmName: "Enter firm name",
  addAdditionalNotesAboutYourFundRaising:
    "Add additional notes about your fundraising history here.",
  euro: "EURO",
  searchAddByInvestorOrFirmName: "Search or add by investor or firm name",
  selfFunded: "Self Funded",
  friendsAndFamily: "Friends and Family",
  previous: "Previous",
  addNoteForBusinessCategory: "Add note for business categoy",
  excellentWork: "Excellent Work!",
  youHaveTakenFirstStepTowardsSuccess:
    "You've taken the first step towards success! Well done!",
  explore: "Explore",
  continue: "Continue",
  letDoThatIn: "Let's do that in",
  company: "Company",
  companyDescription:
    "Describe your company in one sentence. Add your vision and mission to supplement your company description.",
  addYourCompanySummaryInOneSentence:
    "Add your company summary in one sentence here...",
  addYourMarketOpportunityDescription:
    "Add your market opportunity description here...",
  marketOpportunity: "Market opportunity",
  addYourBusinessModalHere: "Add your business model here ...",
  businessModalDescription:
    "How will your business make money? Who pays for your products? What are your margins?",
  timing: "Timing",
  addWhyIsTheRightTimeToBeInBusiness:
    "Add why is the right time to be in business here...",
  timingDescription:
    "What is that one change in technology, platforms, laws or customer behaviour that will ensure business success?",
  whyIsItGreatTimeForYoueCompany:
    "Why is it great time for your company to be in business?",
  traction: "Traction",
  mostExcitingTractionMetricForBusiness:
    "What's the most exciting traction metric for your business?",
  tractionDescription:
    "Choose at least one. If you do not see a metric that best showcases your traction, write it in the open text field at the bottom.",
  tractionSummary: "How would you summarise your traction?",
  tractionSummaryPlaceholder: "Summarise your traction.",
  letterOfIntentSigned: "Letters of intent signed",
  addAnyAdditionalTractionMetricsHere:
    "Add any additional traction metrics here.",
  valuation: "Valuation",
  daily: "Daily",
  weekly: "Weekly",
  annually: "Annually",
  quarterly: "Quarterly",
  SMBs: "SMBs",
  users: "Users",
  customers: "Customers",
  suppliers: "Suppliers",
  subscribers: "Subscribers",
  usersPaying: "Users Paying",
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  hours: "Hours",
  visits: "Visits",
  purchases: "Purchases",
  items: "Items",
  contracts: "Contracts",
  transactioins: "Transactions",
  companyProfileDesc:
    "The first impression is often the one that matters. Take the first few steps to tell people more about your company. Ready? Let's do this!",
  settingUpProfileDesc:
    "Honesty is essential for investors. Put your best foot forward, honestly.",
  companyBasicDesc:
    "Add industry-specific and business category-specific information that helps VCs finalize investment decisions faster.",
  settingUpBasicDesc:
    "This information will help investors know more about your business. Add as much information as you can and improve your visibility!",
  companyTeamsDesc:
    "Investors love to know about your founding team members to figure out if your company is in good hands.",
  settingUpTeams:
    "Having a team with diverse competences united around a common passion is the most powerful force investors look for.",
  settingUpElevatorPitchDesc: "Your best one-liner is emotional, not literal.",
  settingUpFundraisingDesc:
    "Investors will ask you about your burn rate and what you will spend the capital on. But beyond capital, how can they help you achieve your goals?",
  companyDocumentsDesc:
    "Upload your pitch deck and any other documents that you'd like to present to the investors",
  settingUpDocumentDesc:
    "In a nutshell, the pitch deck is your way to explain why the problem you are solving is worth solving, and why you are the right team to do it.",
  companySocialNetworkDesc: "Add links to your company's social media handles.",
  settingUpSocialNetworkDesc:
    "Investors love to know if you have a community of passionate users who will drive your product to success.",
  settingUpSDGsDesc: `The Sustainable Development Goals or Global Goals are a collection of 17 interlinked global goals designed to be a "blueprint to achieve a better and more sustainable future for all".`,
  settingUpTractionDesc:
    "Show metrics that matter and not hockey stick numbers.",
  settingUpCompanyDesc:
    "Mention the core aspects of your product or service, uniqueness, product benefits, or any highlights that you'd like to mention for the investors",
  companyMarketDesc:
    "Why is there a great market opportunity for your product? Are you creating an entirely new market with your product?",
  settingUpMarketDesc:
    "Funds and users will flow in when the time is right. Tell your investors that today is the best time for your company to be there!",
  settingUpTimingDesc:
    "Timing is key and often underestimated. Explain why you are neither too late nor too early to the dinner party.",
  settingUpBusinessModelDesc:
    "Investors are tired of the “grow first and the business model will follow” philosophy. Ensure your business model is well thought through, even if it isn’t set in stone.",
  settingUpAppearanceDesc:
    "We have step-by-step tutorials to teach you the basics and the not-so-basics.",
  activeUsers: "Active users",
  totalUsers: "Total users",
  optionalNoteToProvideFurtherContect:
    "Optional note to provide further context…",
  days: "Days",
  weeks: "Weeks",
  years: "Years",
  quarters: "Quarters",
  userGrowthRate: "User growth rate",
  enter: "Enter",
  retentionRate: "Retention rate",
  churnRate: "Churn rate",
  netRevenue: "Net revenue",
  recurringRevenue: "Recurring revenue",
  revenueGrowthRate: "Revenue growth rate",
  SMVperGSV: "GMV/GSV",
  averageSalesPrice: "Average sales price",
  rakePercentage: "Rake percentage",
  CacPayback: "CAC payback",
  ltvCacRatio: "LTV: CAC ratio",
  demandFrequency: "Demand frequency",
  supplyFrequency: "Supply frequency",
  signedContracts: "Signed contracts",
  annualValueOfSignedInContract: "Annual value of signed in contracts",
  hashOfContractsInPipeline: "# of contracts in pipeline",
  annualValueOfSignedInPipeline: "Annual value of signed in pipeline",
  additionalTraction: "Additional traction",
  enterYourEmailAddress: "Enter your email address",
  loginWith: "Login with",
  google: "Google",
  linkedin: "Linkedin",
  or: "Or",
  howSircularHelps: "How sircular helps",
  seeHowSircularCanTakeBusinessForward:
    "See how sircular can take our business forward.",
  takeJustTwoMinutesToSignIn: "Take just two minutes to sign in.",
  youAreAllSetToSucceed: "You're all set to succeed!",
  startNow: "Start Now",
  businessRelation: "Business relation",
  whatBestDescribeYourBusinessCategory:
    "What best describes the business category?",
  whatBestDescibesYourBusinessRelations:
    "What best describes your business relations?",
  whatIndustryDoesYourBusinessOperateIn:
    "What industry does your business operate in?",
  whatIndustryCategoryDoesYourBusinessOperateIn:
    "What industry category does your business operate in?",
  whatIsYourProductCategory: "What product categories do you invest in?",
  youCanChooseUpTo3Categories: "You can choose up to 3 categories",
  addNoteForBusinessType: "Add note for business type",
  dropYourProfilePictureHereOr: "Drop profile picture here or",
  whatIsYourValuation: "What is your valuation?",
  apperanceDescription:
    "Stand out from the crowd by uploading a banner image and choosing colors that align with your graphic profile.",
  primaryColor: "Primary color",
  enterNumber: "Enter number",
  teaser: "Teaser",
  thereIsProblemInTheSystemContactToAdmin:
    "There is a problem in the system, Please contact to admin",
  selectAccountType: "Select account type",
  upToTill: "up to till",
  emailAddress: "Email address",
  enterPassword: "Enter password",
  registerTo: "Register to",
  enterStartupName: "Enter startup name",
  enterCompleteName: "Enter complete name",
  companyName: "Company name",
  completeName: "Complete name",
  asStartup: "As startup",
  asInvestor: "As investor",
  startTeaserDesc:
    "The best way to communicate your business to top VCs and Angels",
  startYourTeaser: "Start your teaser",
  exploreThePlatform: "Explore the platform",
  start30DayFreeTrial: "Start a 30 Day free trial",
  removeNote: "Remove Note",
  coFounderAndCEO: "Co-founder & CEO",
  noRecordFound: "No records found",
  pleaseUploadPExcelFormat: "Please upload only xlsx, .xls, PDF format",
  pleaseUploadPDFormat: "Please upload only PDF format",
  addNoteAboutTeam: "Add note about your team...",
  sdgLeftContentDesc:
    'The Sustainable Development Goals or Global Goals are a collection of 17 interlinked global goals designed to be a "blueprint to achieve a better and more sustainable future for all”.',
  visionDescription:
    "Describe your company in one sentence. Add your vision and mission to supplement your company description.",
  addHowYouThinkAboutYourMarketTimingHere:
    "Add how you think about your market timing here...",
  agTech: "AgTech",
  AI: "AI",
  dataAnalytics: "Data Analytics",
  arVr: "AR/VR",
  audioTech: "AudioTech",
  autoTech: "AutoTech",
  bioTech: "BioTech",
  blockchain: "Blockchain",
  chemicals: "Chemicals",
  cleanTechEnviorment: "CleanTech/Environment",
  cloudInfrastructurIT: "Cloud Infrastructure IT",
  construction: "Construction",
  consumerHealth: "Consumer Health",
  consumerInternet: "Consumer Internet",
  cosmetics: "Cosmetics",
  cryptocurrency: "Cryptocurrency",
  cybersecurity: "Cybersecurity",
  dataServices: "Data Services",
  developerTools: "Developer Tools",
  diagnostics: "Diagnostics",
  digitalHealth: "Digital Health",
  directToConsumerDTC: "Direct-to-Consumer (DTC)",
  drugDelivery: "Drug Delivery",
  energy: "Energy",
  enterprise: "Enterprise",
  enterpriseApplications: "Enterprise Applications",
  enterpriseInfrastructure: "Enterprise Infrastructure",
  entertainmentAndSports: "Entertainment & Sports",
  futureOfWork: "Future of Work",
  games: "Games",
  gamingAndEsports: "Gaming/eSports",
  gigEconomy: "Gig Economy",
  government: "Government",
  healthAndHospitalServices: "Health & Hospital Services",
  healthIT: "Health IT",
  humanCapitalHRTech: "Human Capital/HRTech",
  impact: "Impact",
  legalTech: "LegalTech",
  localServices: "Local Services",
  hospitality: "Hospitality",
  logistics: "Logistics",
  marketingComms: "Marketing Comms",
  marketplaces: "Marketplaces",
  material: "Material",
  mediaContent: "Media/Content",
  medicalDevices: "Medical Devices",
  messaging: "Messaging",
  mobilityAuto: "Mobility Auto",
  parentingFamilies: "Parenting/Families",
  payments: "Payments",
  robotics: "Robotics",
  saas: "SaaS",
  salesAndCRM: "Sales & CRM",
  security: "Security",
  semiconductors: "Semiconductors",
  smbSoftware: "SMB Software",
  socialCommerce: "Social Commerce",
  space: "Space",
  transportationTech: "TransportationTech",
  travel: "Travel",
  wellnessAndFitness: "Wellness & Fitness",
  selectAtleastOneSDG: "Please select at least one SDG.",
  pleaseSelectTeamSize: "Please select team size",
  pleaseSelectAtleastOneBusinessCategory:
    "Please select at least one business category",
  selectAtleastOneIndustriy: "Please select at least one business industry",
  selectcompanyCurrentState: "Please select your current stage of your company",
  selectYourBusinessType: "Please select your business type",
  amountValidation: "Please enter a numeric value",
  selectAtleastOneRaiseFrom: "Select at least one raised from",
  enterOnlyNumericNumbers: "Enter only numeric numbers",
  fileSizeIsTooLargeFor10MB:
    "File size is too large, It must be less than 10 MB",
  fileSizeIsTooLargeFor20MB:
    "File size is too large, It must be less than 20 MB",
  foundedIn: "Founded in",
  target: "Target",
  raised: "Raised",
  investors: "Investors",
  education: "Education",
  Pharmaceuticals: "Pharmaceuticals",
  musicAndEntertainment: "Music & Entertainment",
  mode: "Mode",
  startupStage: "Startup stage",
  ceoFounderName: "CEO / Founder name",
  investorRegisterSuceessMessage:
    "You have registered successfully! Use the credentials you provided during the sign-up process to log in to Sircular.",
  success: "Success",
  internetConnection: "Internet Connection",
  connectionIsOut: "Please check your internet connection",
  connectionIsBack: "Your internet connection is back",
  stockhomFounderRaised: "Stockholm Founder who raised 2,000,000 SEK",
  stockhomFounderRaisedDesc: `"Completing the teaser convinced me that my startup was worth an investment. That's what drew investors in"`,
  socialNetwork: "Social Network",
  registerWith: "Register with",
  allowedOnly30Charcters: "Allowed only 30 characters",
  cityIsRequired: "City is required",
  selectValidCityFromTheDropdown: "Choose a valid city from the dropdown",
  viewProductDemo: "View pitch video",
  fundraisingAndbusinessOverflow: "Fundraising & Business Overview",
  productDescription: "Product description",
  theTeamText: "The team",
  next: "Next",
  prev: "Prev",
  pitchDeck: "Pitch deck",
  by: "By",
  viewed: "Viewed",
  previouslyRaisedFrom: "Previously raised from",
  businessSectors: "Business sectors",
  teamSize: "Team size",
  whatAreWelookingForInvestors: "What are we looking for in our investors?",
  whatAreYoulookingForInvestors:
    "Beyond financial capital, what are you looking for in our investors?",
  ceoFounder: "CEO/founder",
  coCeoFounder: "Co-CEO/Co-founder",
  emailAddressText: "Email address",
  fundraisingHistory: "Fundraising history",
  productDescriptionInstruction:
    "When writing descriptions that include features and benefits, keep in mind the following:",
  productDescriptionInfo1:
    "1. You don't have to list benefits of every feature. Pick the three highest value features.",
  productDescriptionInfo2:
    "2. Describe the advantages of the features and what they bring to the table.",
  productDescriptionInfo3:
    "3. Explain how it will solve a problem or help the customer.",
  product: "Product",
  addYourProductSummaryHere: "Add your product summary here...",
  upload: "Upload",
  ventureCapital: "VCs",
  angels: "Angels",
  addNote: "Add note",
  removeNote: "Remove note",
  urlMustBeLInkedinUrl: "Url must be linkedin url",
  loading: "Loading",
  allowedOnly25Charcters: "Endast 25 tecken är tillåtna.",
  share: "Share",
  shareProfileHeading:
    "Do you want to complete your company teaser before sharing it?",
  shareProfileDesc:
    "A survey by NFX shows that 78% of investors prefer a company teaser over a pitch deck. The best thing? When you update your teaser, all investors with the link will access the latest data.",
  whatsApp: "WhatsApp",
  copyLink: "Copy Link",
  linkHasBeenCopied: "Link has been copied",
  signUp: "Sign Up",
  pasteTheUrlForVisitCompany: "Paste the url for visit company",
  thanksForCheckingOur: "Thanks for checking our",
  getInTouchWithUsToFollowUp:
    "Get in touch with us and we'll be sure to follow-up.",
  enterFirstName: "Enter First Name",
  lastName: "Last name",
  enterLastName: "Enter last name",
  enterYourMessage: "Enter your message",
  submit: "Submit",
  typeHere: "Type Here",
  enterContactNumber: "Enter Contact Number",
  invalidPhoneNumber: "Invalid phone number",
  wooLetsGrowTogether: "Woo Hoo! Let's Grow Together.",
  getYourSelfOnboardNow: "Get yourself onboard now.",
  completeMyCompanyTeaser: "Complete my company teaser",
  sms: "SMS",
  optional: "Optional",
  profileLink: "Profile Link",
  defaultDescription:
    "Our company teaser serves as a single source of truth about us. As we innovate and work towards a sustainable, financially sound future, we also adapt and learn. We’re taking steps towards transparency and enhancing our visibility to our potential investors and team members.",
  weInviteYouToKnowUsBetter:
    "We invite you to get to know us better through our company profile on Sircular",
  seeMore: "See More",
  reject: "Reject",
  youDontHaveAnyDataAsOfNow: "Oh nooo, you don't have any data as of now.",
  addCompany: "Add Company",
  dataroom: "Dataroom",
  dealroom: "Dealroom",
  rejected: "Rejected",
  typeToSearchStartups: "Type to search startups",
  couldNotFindTheStartup: "Couldn't find the startup?",
  enterIndustryType: "Enter industury type",
  enterCompanyUrl: "Enter company url",
  dealflow: "Dealflow",
  companyAddedSuccessfully: "Company added successfully",
  sendInviteToRegister: "Send invite to register",
  addMoreCompanies: "Add more companies",
  willAddLater: "Will add later",
  addCompanyDetails: "Add company details",
  statusUpdatedSuccessfully: "Status updated successfully.",
  serverErrorTitle: "Temporary Server Error",
  serverErrorDesc:
    "Sorry, something went wrong. Connection with the server currently unavailable. If you think there is a problem in the system, Please",
  contactUs: "Contact Us",
  refresh: "Refresh",
  takeMeBack: "Take Me Back",
  didYouKnow: "Did you know?",
  investorsTip: "Investor's tip",
  teamInvestorTipDesc:
    "Bring forward how each key player on the team will contribute to making your product hit and lit!",
  profileInvestorTipDesc:
    "Your profile is the first thing investors will look at, make it count.",
  elevatorPitchInvestorTipDesc:
    "Get people as excited as you are about your pitch! Engage your audience with an exciting, engaging and compassionate pitch.",
  documentInvestorTipDesc:
    "Your intro pitch deck should not be more than ten slides. Give a high-level overview instead of the nitty-gritty.",
  fundraisingInvestorTipDesc:
    "Investors don’t like undersubscribed investment rounds, so ask for the minimum you need, and be open to take in more capital if the interest is big.",
  businessModalInvestorTipDesc:
    "Make sure to tie your business model to your value proposition, not to your features. That way, your goals are aligned with those of your users.",
  timingInvestorTipDesc:
    "Know the market and technology dynamics that support why the timing for your venture is just right.",
  tractionInvestorTipDesc:
    "Explain your numbers in relation to where you are today. Showcase your retention curve to exhibit growth or adoption rates.",
  headquarteredAt: "Headquartered at",

  youAreNotOneWhoBlockedThisConversation:
    "You are not the one who blocked this conversation.",
  deleted: "Deleted",
  has: "has",
  typing: "Typing",
  noInternetConnection: "No internet connection",
  internetConnectionError:
    "You can't use this feature offline, Check your connection and try again.",
  contactRequestExist: "You have already sent request to this user",
  phoneLengthError: "Phone should be at least 13 digit",
  teaserTwoImpactDescription:
    "Survey suggests that 78% of investors prefer a company teaser over a pitch deck.",
  skip: "Skip",
  completeTeaser: "Complete Teaser",
  enterPhone: "Enter phone",
  moveTo: "Move to",
  typeToSearchSelection: "Type to search selection",
  startupName: "Startup name",
  contactNumber: "Contact number",
  enterContactNumber: "Enter contact number",
  thisIsNotAValidContactNumber: "This is not a valid contact number.",
  wouldYouBeVisibleInSircularMarketplace:
    "Would you also like to be visible in Sircular's marketplace?",
  startYour4MonthsFreeTrial: "Start your 4 months free trial",
  marketplaceVisibility: "Marketplace visibility",
  welcomeTo: "Welcome to",
  youHaveGot4Months: "You've got 4 months",
  premiumForFree: "premium for free",
  whatIsSircular: "What is sircular?",
  freemiumDesc1:
    "For many startups, raising funds is an important part of building the capital needed to succeed with their company.",
  freemiumDesc2:
    "While we will help those of you that make it into the program to build a strong case to investors, we also offer access to Sircular for everyone applying to",
  freemiumDesc3:
    "The information you have shared in your application has been put together to form a profile. This profile allows us to get a better overview of your application and make a more informed decision.",
  freemiumDesc4:
    "You can check it out and update the information by clicking on View profile below.",
  freemiumDesc5:
    "Note that this profile is only visible to you and to our internal team.",
  joinSircular: "Join Sircular",
  willDoLater: "Will do later",
  switchTo: "Switch to",
  getYourProfileListed: "Get your profile listed",
  switchNow: "Switch now",
  notNow: "Not now",
  proceedToApplication: "Proceed to Application",
  weHadWelcomeYourArrival: "We'd welcome your arrival",
  itSeemsYourEmailIsAlreadyRegistered:
    "It seems that your email is already registered with Sircular.",
  wouldYourLikeToProceedUsingSameDetails:
    "Would you like to proceed using same details.",
  proceed: "Proceed",
  addMembersOnSircular: "Add members on Sircular",
  MakeSircularWorkBetterForYou:
    "Make Sircular work better for you, set up parental controls for your members.",
  addMembers: "Add Members",
  addMemberDetails: "Add member details",
  enterMemberName: "Enter member name",
  view: "View",
  members: "members",
  allMembersDetails: "All members details",
  createdAt: "Created At",
  addMoreDetailedDescription: "Add a more detailed description",
  activity: "Activity",
  added: "Added",
  newMemberAdded: "New member added.",
  couldNotLoadDescription: "Couldn't load the description",
  whatAreYourThoughts: "What are your thoughts?",
  activityAddedSuccessfully: "Activity added successfully",
  activityDeletedSuccssfully: "Activity deleted successfully",
  activityUpdatedsuccessfully: "Activity updated successfully",
  requestHasBeenAccepted: "Request has been accepted.",
  startup: "Startup",
  startupBoard: "Startup Board",
  applyTo: "Apply to",
  apply: "Apply",
  yourApplicationTo: "Your application to",
  hasBeenSubmitted: "has been submitted!",
  doYouAlreadyHaveAnApplication: "Do you already have an application?",
  applyWith: "Apply with",
  doNotHaveAnApplication: "Do not have an application?",
  createApplication: "Create application",
  willGetBackToYouWithinComingDays:
    "We will get back to you within the coming days.",
  marketplaceDescription1:
    "Sircular is a market network that allows you to meet investors and raise money faster.",
  marketplaceDescription2:
    "If you want to join, just click on Join Sircular below.",
  regAcceleratorDescription:
    "We are thrilled that you want to apply. Please fill in the information below to submit your application. The information you provide will also enable you to quickly share your profile with mentors, investors, and other stakeholders if you get selected for the program.",
  bestOfLuck: "Best of luck!",
  whichOfTheseSubcategoriesWithinSmartCitiesDoYouIdentifyWith:
    "Which of these sub-categories within Smart cities do you identify with?",
  pleaseWriteThingsThat: "Please write 3 things that",
  acceleratorCanHelpYouWith: "can help you with.",
  explainHere: "Explain here",
  max200CharLimit: "Max 200 char limit",
  whatSupportAreWeLookingFor: "What support are we looking for",
  categoriesOfSmartCitiesThatWeIdentifyWith:
    "Categories of smart cities that we identify with",
  realEstateFintech: "Real Estate Fintech",
  planning: "Planning",
  contech: "Contech",
  alternativeMaterials: "Alternative Materials",
  materials: "Materials",
  greenBuildings: "Green Buildings",
  energyEfficiency: "Energy Efficiency",
  digitalisationOfRealEstate: "Digitalisation Of Real Estate",
  futureOfWork: "Future Of Work",
  energyManagement: "Energy Management",
  futureOfCityLife: "Future of City Life",
  infrastructure: "Infrastructure",
  utilitiesAndWatermanagement: "Utilities & Water management",
  services: "Services",
  selectAtleaseOneSmartCity: "Select at least one one smart city",
  loginImpactDesc:
    "Where impact startups get support to create global companies that make the world a better place.",
  received: "received",
  application: "application",
  selectAtleaseOneSubCategory: "Select at least one sub-category",
  welcome: "Welcome",
  management: "Management",
  financials: "Financials",
  tech: "Tech",
  legal: "Legal",
  hr: "Hr",
  successfullyDeleted: "Successfully deleted.",
  weCouldNotDeleted: "We could not deleted.",
  documentUpdatedSuccessfully: "Document updated successfully.",
  areYouSure: "Are you sure?",
  youWillNotAbleToRecoverThisFile: "You will not able to recover this file.",
  byClickingThisYouAgreeToOur: "By clicking this you agree to our",
  addOrRemoveStatusDnD:
    "Add or remove a status to your dealflow. Use drag-and-drop to change the order of the statuses.",
  addANewStatus: "Add a new status",
  enterYourNewStatus: "Enter your new status",
  statusInUse:
    "This status is currently used on some of your companies. Please select a new status before deleting this status.",
  statusIsNotDeletable: "This status is not deletable",
  youCanOnlyAdd30Statuses: "You can only add maximum 30 statuses",
  statusAlreadyInList: "You already have this status in your list.",
  customizeStatuses: "Customize statuses",
  privacyPolicy: "Privacy policy",
  tearmsAndPrivacyMissing: "Terms and privacy missing",
  termsAndConditions: "Terms and conditions",
  registerRoyaltyDesc:
    "In the event that I successfully raise capital through investors met on Sircular, I understand that I am liable to pay 3% of the total amount raised from these investors to Sircular.",
  moreInformation: "More Information",
  moreInformationPara1: "The average commission in the industry is 5-8%.",
  moreInformationPara2:
    "Sircular takes a 3% commission in order to generate the revenues needed to develop and maintain the Sircular network and tool.",
  moreInformationPara3:
    "This 3% commission is only charged for the investments raised through investors that you have met in the Sircular network.",
  moreInformationPara4:
    "This means that even if you share your Sircular profile with investors outside the Sircular network in order to build your investment case, you will not have to pay the 3% commission on the external investors.",
  moreInformationPara5:
    "Example: Startup A is raising 10M EUR. Half of the investment round (5M EUR) is raised from 3 investors that Startup A met through Sircular. The remaining 5M EUR comes from investors that Startup A met outside of the Sircular network.",
  moreInformationPara6:
    "In this example, Startup A is only liable to pay commission on the 5M EUR that they raised from investors met on the Sircular network.",
  termsAndPrivacyPolicyCheckBoxError:
    "Please check terms and privacy policy box if you want to proceed.",
  source: "Source",
  pendingApplications: "Pending Applications",
  pendingApplicationsFromStatups: "Pending application from startups",
  applicationsAreNowClosed: "Applications are now closed.",
  weWishYouTheBestWithYourVenture: "We wish you the best with your venture.",
  stayTurnedOn: "Stay turned on",
  eventsAndInitiativesByFollowingUsOnSocialMedia:
    "events and initiatives by following us on social media:",
  dontAddHttpInInput: "Don't add http/https in the input, already have added.",
  pastThreeMonth: "Past 3 months",
  custom: "Custom",
  profileAnalytics: "Profile analytics",
  filterBy: "Filter by",
  totalVisits: "Total visits",
  uniqueProfileLinks: "Unique profile links",
  enterUniqueLinkName: "Enter unique link name",
  create: "Create",
  uniqueVisitors: "Unique visitors",
  pitchDownloads: "Pitch downloads",
  copy: "Copy",
  chat: "Chat",
  keep: "Keep",
  track: "track",
  trialScreenDesc:
    "Share a unique link with an investor, and get insights that empower you to do the right move at the right time.",
  didTheyViewYourProfile: "Did they view your profile?",
  howManyTimes: "How many times?",
  didTheyShareItWithTheirNetwork: "Did they share it with their network?",
  didTheyDownloadYourPitchDeck: "Did they download your pitch deck?",
  andManyMore: "And many more...",
  tryFreeForTwoWeeks: "Try free for two weeks",
  afterTheFreeTrial: "After the free trial",
  weCouldNotLoadData: "We could not load the data.",
  myplace: "My place",
  linkCreatedSuccessfully: "Link created successfully.",
  mySpace: "My Space",
  viewYourAnalyticsIn: "View your analytics in",
  pageLink: "Page link",
  public: "Public",
  private: "Private",
  linkCreatedSuccessfully: "Link created successfully.",
  mySpace: "My space",
  pageLink: "Page link",
  profile: "Profile",
  unique: "Unique",
  thisWeek: "This week",
  thisMonth: "This Month",
  byPremium: "Buy premium",
  recentlyCreated: "Recently created",
  trackAllYourPrivateLinksIn: "Track all your private links in",
  trackAllYourUniqueLinksIn: "Track all your unique links in",
  pastOneYear: "Past 1 year",
  selectOne: "Select one",
  allPeriod: "All period",
  upgradeToPremiumNow: "Upgrade to premium now",
  yourFreeTrialIsExpireSoon: "Your free trial is expiring soon!",
  unlinkAvailableDesc:
    "This shared link has been removed or is unavailable to you.",
  linkHasBeen: "Link has been",
  enabled: "enabled",
  disabled: "disabled",
  searchLinkName: "Search link name",
  copied: "Copied",
  referral: "Referral",
  invalidReferralCode: "Invalid referral code",
  givenReferralCodeIsInvalid: "Given referral code is invalid.",
  invite: "Invite",
  clickToCopyYourReferralLink: "Click to copy your referral link",
  orShareAcross: "or share across",
  YourListIsEmpty: "Your list is empty.",
  startInviting: "Start Inviting!",
  membersJoined: "members joined.",
  membersPending: "members pending.",
  showPending: "Show Pending",
  hidePending: "Hide Pending",
  Name: "Name",
  Type: "Type",
  Angel: "Angel",
  hasInvited: "has invited",
  youToJoin: "you to join",
  Sircular: "Sircular",
  joinSircularAs: "Join Sircular as",
  Investor: "Investor",
  Startup: "Startup",
  createdOn: "Created on",
  applyFilter: "Apply filter",
  uniqueLink: "Unique link",
  past7Days: "Past 7 days",
  past30Days: "Past 30 days",
  lastVisited: "Last visited",
  done: "Done",
  aboutYou: "About you",
  firmName: "Firm name",
  areYouOpenToSupportStartupAsAnAdvisor:
    "Are you open to support startups as an advisor?",
  whatIsYourPrimaryLocation: "What is your primary location?",
  shareShortDescriptionAsInvestor:
    "Share a short description about yourself as an investor",
  introduceYourSelf: "Introduce yourself",
  investmentStrategy: "Investment strategy",
  howMuchCapitalLookingInvestInStartup:
    "How much capital are you currently looking to invest in startups?",
  whatStagesOfStartupMostlyInterestedInInvesting:
    "What stage(s) of a startup are you mostly interested in investing?",
  whatBusinessRelationshipModelsAreYouMostinterestedIn:
    "What business relationship models are you most interested in?",
  whatIndustriesAreYouMostInterested:
    "What industries are you most interested in?",
  whatInvestmentOppertunitiesAreYouInterested:
    "What investment opportunities are you most interested in?",
  whatInvestmentOppertunitiesAreYouNotInterested:
    "What investment opportunities are you not interested in?",
  sdgFocus: "SDG focus",
  whichSDGAreMostInterestedInInvesting:
    "Which SDGs are you most interested in investing in?",
  ifSDGsAreImportantToYouShareWhy: "If SDGs are important to you, share why?",
  tellUsAboutYourTeamIfYouHaveOne: "Tell us about your team, if you have one",
  fillAtleastOneMemberDetails: "Fill at least one member details",
  yourInvestmentPortfolio: "Your investment portfolio",
  whatStartupsHaveYouPreviouslyInvestedIn:
    "What startups have you previously invested in?",
  addMember: "Add member",
  canNotFindYourStartup: "Can't find your startup?",
  investmentDate: "Investment date",
  countryName: "Country name",
  selectStartupInvestingStage: "Select the startup's investing stage",
  selectIndustyType: "Select the industry type",
  selectSDGsYourStartupIsContributingTo:
    "Select the SDGs, your startup is contributing to",
  typeCountyCityName: "Type country/city name",
  thisStartupHasBeenAlreadySelected: "This startup has been already selected",
  homeLeisure: "Home Leisure",
  hrRecruitment: "HR Recruitment",
  naturalResources: "Natural Resources",
  smartCities: "Smart Cities",
  meetLink: "Meet link",
  AddaLinkToBookMeeting: "Add link to book a meeting (ie. Calendly)",
  youWillNotAbleToRecoverData: "You will not able to recover data.",
  primaryLocationIsRequired: "Primary location is required",
  selectValidLocationFromDropdown: "Select valid location from dropdown",
  investableCapital: "Investable capital",
  ticketSize: "Ticket size",
  investingStage: "Investing stage",
  beyondCapital: "Beyond capital",
  mostInterestedIn: "Most interested in",
  NotInterestedIn: "Not interested in",
  advisor: "Advisor",
  countries: "Countries",
  gotIt: "Got it",
  thanksForSendingApplicationOnSircularNetwork:
    "Thanks for sending your application to the  Sircular network.",
  thanksForSendingApplication: "Thanks for sending your application.",
  weWillGetBackToYouAsap: "We will get back to you asap!",
  beAnonymous: "Be anonymous",
  allStatrupStages: "All startup stages",
  stages: "Stages",
  location: "Location",
  meetTheTeam: "Meet the team",
  teamDescriptionForInvestor:
    "Top professionals from all over the world are taking part in shapping the future of work.",
  whiteSpaceIsNotAllowed: "White space is not allowed",
  notYet: "Not yet",
  aboutYouSavedSuccessfully: "About you saved successfully.",
  investmentStrategySavedSuccessfully:
    "Investment strategey saved successfully.",
  sdgFocusSavedSuccessfully: "SDG focus saved successfully.",
  teamSavedSuccessfully: "Team saved successfully.",
  addedSuccessfully: "added successfully.",
  previousIntestment: "Previous investment",
  saveAndNext: "Save & Next",
  investorName: "Investor name",
  locationOfStartupsHQ: "Location of startup’s HQ",
  updatedSuccessfully: "updated successfully.",
  ticketRange: "Ticket range",
  whatTicketSizeAreYouLookingToInvestInStartup:
    "What ticket size range are you looking to invest in a given startup?",
  deletedSuccessfully: "deleted successfully.",
  yesIHaveATeam: "Yes, I have a team",
  saveAndSubmit: "Save & submit",
  foodDelivery: "Food Delivery",
  foodSafetyTracebility: "Food Safety Traceability",
  nextgenFoodDrinks: "Nextgen Food Drinks",
  consumerAppServices: "Consumer App Services",
  foodProcessing: "Food Processing",
  searchHere: "Search here",
  dealroom: "Dealroom",
  bookmarked: "Bookmarked",
  editProfile: "Edit profile",
  myProfile: "My profile",
  passwordError:
    "Password must be at least 8 characters long with 1 uppercase, 1 lowercase, 1 numeric and 1 special character (!@#$%^&*).",
  notes: "Notes",
  comments: "Comments",
  addComment: "Add comment...",
  pending: "Pending",
  approved: "Approved",
  all: "All",
  invalidPassword: "Invalid Password",
  applications: "Applications",
  weCouldNotFindDataForProvidedFilters:
    "We could not find data for provided filters",
};
