import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import { FieldName } from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";

interface Props {
  content: string;
}

const MarketOpp_PitckDeck: FC<Props> = ({ content }) => {
  return (
    <div className="text-base aiaProfile whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
      <h2 className="text-lg text-gray-700 dark:text-white/80 mb-2">
        Market size
      </h2>
      <PureHtml content={content} tag="p" />
    </div>
  );
};

export default withContentEditable<Props>(
  MarketOpp_PitckDeck,
  FieldName.MarketOppPitchDeck
);
