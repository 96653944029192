import React from "react";
import { useDispatch } from "react-redux";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { format } from "date-fns";
import { ReactComponent as CopyIcon } from "images/copy-btn.svg";

export type MessageType = {
  id: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  phone: string;
  sender?: {
    slug: string;
    type: "company" | "investor";
  };
};

interface Props {
  message: MessageType;
}

const Message: React.FC<Props> = ({ message }) => {
  const dispatch = useDispatch();

  const { createdAt, firstName, lastName, email, message: text } = message;

  const recievedOn = new Date(Number(createdAt));

  const handleOnEmailCopy = () => {
    console.log("call");
    dispatch(
      triggerSnack({
        type: "success",
        title: "Email address copied",
        message:
          "You can use this address to send an email directly from your email provider.",
      })
    );
  };

  return (
    <div className="relative p-4 m-4 text-sm bg-gray-100 rounded-md sm:p-8">
      <div className="relative flex flex-col sm:flex-row ">
        <span className="flex-1 pt-2 mb-2 sm:mb-0">
          {message.sender ? (
            <a
              href={`${
                message.sender.type === "investor" ? "/investor" : ""
              }/public/${message.sender.slug}?us=messages`}
              className="underline"
              rel="noopener noreferrer"
              target="_blank"
            >
              {firstName} {lastName}
            </a>
          ) : (
            <span className="flex-1 pt-2 mb-2 sm:mb-0">{`${firstName} ${lastName}`}</span>
          )}
        </span>

        <span className="flex-1 pt-2 mb-2 sm:mb-0">
          {format(recievedOn, "d MMM yyyy, hh:mm a")}
        </span>

        <span className="flex flex-1">
          <CopyToClipboard text={message.email} onCopy={handleOnEmailCopy}>
            <div className="flex flex-1 w-[200px] justify-between items-center text-gray-500 border mr-6 p-2 h-8 border-[#C4C4C4] bg-gray-100 rounded">
              <span
                dir="ltl"
                className="inline-block overflow-hidden text-sm whitespace-nowrap text-ellipsis"
              >
                {email}
              </span>
              <CopyIcon
                className="ml-2 cursor-pointer"
                width="16"
                height="16"
              />
            </div>
          </CopyToClipboard>
        </span>
        <span className="flex-[2] mt-2 pt-2 sm:mt-0 font-thin text-justify ml-auto">
          {text}
        </span>
      </div>
    </div>
  );
};

export default Message;
