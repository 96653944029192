import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const InvestorCard = styled.div(() => [
  tw`flex`,
  css`
    background: #16192f;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    align-items: center;
    line-height: 18px;
    min-height: 21px;
    max-height: 50px;
    width: 100%;
    ${media.lessThan("medium")`
            margin-bottom: 10px;
        `}
    svg {
      width: 30px;
      margin-right: 7px;
    }
    div {
      width: calc(100% - 60px);
      font-size: 14px;
      svg {
        width: 15px;
        margin: 0;
        cursor: pointer;
      }
    }
    p {
      color: #c8c8c8;
      font-size: 13px;
    }
  `,
]);

export const RightCardItem = styled.div(({ height }) => [
  tw`rounded-sm mr-2`,
  css`
    transition: all 0.3s ease;
    height: calc(100vh - 190px);
    background: #16192f;
    border-radius: 10px;
    padding: 5px 0;
    overflow: auto;
    cursor: auto;
    border-bottom-right-radius: 0;
    ${media.lessThan("medium")`
            height: 100%;
            border-radius: 0;
        `}
    :after {
      content: "";
      width: 0;
      height: 0;
      border-top: 30px solid #16192f;
      border-left: 30px solid transparent;
      position: absolute;
      bottom: -30px;
      right: 8px;
      ${media.lessThan("medium")`
                content: none;
            `}
    }
    .content {
      h1 {
        color: #fff;
        padding: 5px 15px;
        position: relative;
        margin: 5px 0;
        &:after {
          content: "";
          position: absolute;
          height: 90%;
          left: 0;
          top: 0;
          width: 4px;
          background: #18beb3;
        }
      }
      h3 {
        padding: 0 15px;
        display: flex;
        color: #fff;
        font-size: 14px;
        align-items: center;
        margin-bottom: 10px;
        .profile-img {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          background: #16404e;
          border: 5px solid #162a3c;
          padding: 3px;
          margin-right: 10px;
        }
      }
      p {
        color: #b9bac1;
        padding: 0 15px;
        font-size: 13px;
      }
    }
    img {
      bottom: -80px;
      right: -21px;
    }
  `,
  height &&
    css`
      transition: all 0.3s ease;
      height: calc(100vh - 110px);
      ${media.lessThan("medium")`
                height: 100%;
            `}
    `,
]);

export const VideoSection = styled.div(() => [
  css`
    display: flex;
    justify-content: end;
    svg {
      margin-right: 25px;
      width: 50%;
      height: auto;
      float: right;
    }
    img {
      width: 100%;
      height: auto;
      float: right;
    }
    h5 {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin-top: 15px;
      font-weight: 500;
    }
    p {
      text-align: center;
    }
  `,
]);

export const ContentSection = styled.div(() => [
  css`
    height: 87px;
    max-height: 87px;
  `,
]);

export const SuccessContainer = styled(motion.div)(() => [
  tw`flex`,
  css`
    height: calc(100vh - 81px);
    ${({ bgImage }) =>
      bgImage &&
      css`
        background-color: #e7f8f7;
        background-image: url(${bgImage});
        background-size: cover;
        background-position: center;
        align-items: center;
      `}
    .bg-white {
      width: 400px;
      padding: 35px;
    }
  `,
]);

export const WelcomeStepScreen = styled(motion.div)(() => [tw`w-full`]);

export const SuccessContainerContent = styled(motion.div)(() => [
  tw`bg-white p-4 text-center m-auto shadow-sm rounded-md`,
]);

export const ColorSelection = styled.div(() => [
  tw`flex relative`,
  css`
    .rounded-full {
      div {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    .twitter-picker {
      padding: 0 !important;
      box-shadow: 0px 0px 5px 0px #e1e1e1 !important;
      border: none !important;
      width: 280px !important;
      position: absolute !important;
      top: 47px;
      left: -5px;
      div {
        padding: 0 !important span {
          div {
            border-radius: 50% !important;
            display: block !important;
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  `,
]);
