import Chip from "atoms/Chip/Chip";
import { INDUSTRY_CATEGORIES_SWEDISH } from "core/consts";
import { ReactComponent as BinIcon } from "images/bin-icon.svg";
import React from "react";

const CompanyRow = ({
  company,
  isSearchResult,
  removeCompanyFromSelectedCompanies,
}) => {
  const onRemoveCompanyClicked = (e) => {
    e.stopPropagation();
    removeCompanyFromSelectedCompanies(company._id);
  };

  return (
    <div className="relative flex items-center py-2">
      <img
        className="mr-2 rounded-full w-14 h-14"
        src={
          company?.logo?.small?.location ||
          "https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png"
        }
        style={{ boxShadow: "0px 0px 10px -3px black" }}
        alt={company.displayName}
      />
      <div className="flex flex-col">
        <span>{company.displayName}</span>
        <span className="mb-1 text-xs text-cool-gray-500">
          {company.fullAddress}
        </span>
        <div>
          {company.industries?.length > 0 &&
            company.industries.map((industry) => <Chip>{INDUSTRY_CATEGORIES_SWEDISH[industry]}</Chip>)}
        </div>
      </div>
      {!isSearchResult && (
        <BinIcon
          className="absolute right-0 text-red-400 cursor-pointer fill-current"
          onClick={onRemoveCompanyClicked}
        />
      )}
    </div>
  );
};

export default CompanyRow;
