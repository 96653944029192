import { useEffect } from "react";
import PageLayout from "./components/PageLayout";
import Card from "./components/Card";
import { useDispatch, useSelector } from "react-redux";
import { initializeMessagesPage } from "./sagaActions";
import { isLoadingEnabled } from "models/loaders/selectors";
import Loading from "atoms/Loading/Loading";
import { MESSAGES } from "./constants";
import MessageList from "./components/MessageList";
import { getMessages } from "./selectors";
import { ReactComponent as Mailbox } from "images/mailbox.svg";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import { isPublicShareModalOpen } from "organisms/ShareProfileModal/selectors";
import { getUser } from "models/user/selectors";
import Button from "atoms/Button/Button";
import { triggerShareModal } from "organisms/ShareProfileModal/sagaActions";
import { useGuard } from "hooks/useGuard";
import { MustBeCompany } from "core/routePolicies";

const Messages = () => {
  const isLoading = useSelector((state) => isLoadingEnabled(state, MESSAGES));
  const { messages } = useSelector(getMessages);
  const user = useSelector(getUser);
  const isPublicShareModal = useSelector((state) =>
    isPublicShareModalOpen(state)
  );
  const dispatch = useDispatch();

  useGuard([MustBeCompany]);

  useEffect(() => {
    dispatch(initializeMessagesPage());
  }, []);

  if (isLoading) {
    return <Loading fullPage />;
  }

  if (messages.length > 0) {
    return (
      <PageLayout
        title="Messages"
        description="These messages have been sent to you by visitors of your profile.
      If you wish to answer, copy their email address and send them an email derectly from your email provider."
      >
        <Card wide>
          <MessageList />
        </Card>
      </PageLayout>
    );
  }

  return (
    <div className="w-full p-3 m-4 mx-auto mt-6">
      <Card wide>
        <div className="flex flex-col items-center pt-6 pb-10 m-2 text-center">
          <Mailbox />
          <div className="mt-5 text-2xl font-bold">
            Oh no! You don't have any messages yet.
          </div>
          <p>
            Make sure to share your profile in order to get analytics on how
            people interact <br /> with it, as well as prompt investors to
            contact you
          </p>
          <Button
            color="primary"
            className="m-auto mt-6"
            onClick={() =>
              dispatch(
                triggerShareModal({
                  isEnabled: true,
                  flags: {
                    isPublic: false,
                  },
                })
              )
            }
          >
            <span>Share your profile</span>
          </Button>
        </div>
        {isPublicShareModal && (
          <PublicShare
            title={user.displayName}
            publicUrl={`${window.location.origin}${
              user.isInvestor ? "/investor" : ""
            }/public/${user?.slug}`}
            us="messages_page_public_share"
          />
        )}
      </Card>
    </div>
  );
};

export default Messages;
