import { useEffect } from "react";
import Loading from "atoms/Loading/Loading";
import UnavailableLink from "atoms/ServerError/UnavailableLink";
import { useLang } from "core/lang/LangaugeSetting";
import { isLoadingEnabled } from "models/loaders/selectors";
import { isUserLinkRemoved } from "models/user/selectors";
import ServerError from "molecules/ServerError/ServerError";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LOADER_COMPANY_BY_SLUG } from "./constants";
import { initializeCompanyProfile } from "./sagaActions";
import useSetMixpanelUserProps from "core/mixpanel/useSetMixpanelUserProps";
import CompanyProfile_Aia from "./CompanyProfile_Aia";
import CompanyProfile_Regular from "./CompanyProfile_Regular";
import {
  getAnalysisDetails,
  getCompanyDetails,
  getCompetitorsDetails,
  getCurrencyDetails,
  getTractionDetails,
} from "./selectors";
import { ProfileNavigationProvider } from "./components/aia/ProfileNavigation/context";

const CompanyProfileComponent: React.FC<any> = ({
  params,
  profileLoadingStatus,
  analysisLoadingStatus,
}) => {
  /* Other hooks */
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  useSetMixpanelUserProps();
  /* Selectors */
  const { currencyCode } = useSelector(getCurrencyDetails);
  const loading = useSelector((state) =>
    isLoadingEnabled(state, LOADER_COMPANY_BY_SLUG)
  );
  const data = useSelector(getCompanyDetails);
  const traction = useSelector(getTractionDetails);
  const analysis = useSelector(getAnalysisDetails);
  const competitors = useSelector(getCompetitorsDetails);
  const isLinkRemoved = useSelector(isUserLinkRemoved);

  /* Effects */
  useEffect(() => {
    dispatch(initializeCompanyProfile({ lang, params }));
  }, []);

  useEffect(() => {
    const isDark = Boolean(searchParams.get("dark"));
    if (!isDark) return;

    const element = document.documentElement;
    element.classList.add("dark");

    return () => {
      element.classList.remove("dark");
    };
  }, [searchParams]);

  if (loading) {
    return <Loading fullPage />;
  }

  if (!data?.companyBySlug) {
    return <ServerError />;
  }

  if (isLinkRemoved) {
    return <UnavailableLink />;
  }

  if (data?.companyBySlug?.isAiaGenerated) {
    return (
      <ProfileNavigationProvider>
        <CompanyProfile_Aia
          profileLoadingStatus={profileLoadingStatus}
          analysisLoadingStatus={analysisLoadingStatus}
          data={data}
          traction={traction}
          analysis={analysis}
          currencyCode={currencyCode}
          competitors={competitors}
        />
      </ProfileNavigationProvider>
    );
  } else
    return (
      <CompanyProfile_Regular
        data={data}
        traction={traction}
        analysis={analysis}
        currencyCode={currencyCode}
      />
    );
};

export default CompanyProfileComponent;
