// @ts-nocheck
import tw, { styled, css } from "twin.macro";
import { motion } from "framer-motion";
import media from "styled-media-query";

export const ModalIcon = styled.div(({ type }) => [
  tw`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100
  `,

  type === "primary" && tw`bg-aqua-100`,
  type === "secondary" && tw`bg-fire-100`,
  type === "warning" && tw`bg-yellow-100`,
]);

export const ModalTitle = styled.h3(() => [tw`text-lg leading-6  text-black`]);

export const ModalText = styled.p(() => [
  tw`text-sm leading-5 text-gray-500 mt-2`,
  css`
    white-space: break-spaces;
  `,
]);

export const ButtonWrap = styled.div(() => [
  tw`mt-6`,
  css`
    display: flex;
    align-items: center;
    justify-content: strech;

    & > button {
      width: 100%;
      justify-content: center;
      white-space: nowrap;

      &:first-child:not(:last-child) {
        margin-right: 15px;
      }
    }
  `,
]);

export const Modal = styled(motion.div)(({ type, width }) => [
  tw`bg-white lg:rounded-lg overflow-hidden shadow-xl sm:w-full p-6 lg:mt-6 z-10 relative`,

  css`
    img {
      max-height: calc(100vh - 120px);
      overflow: auto;
    }
    ${width &&
    css`
      max-width: ${width};
    `}
    ${media.lessThan("767px")`
      margin: 10px;
      max-height: 90vh;
      overflow: auto;
   `}
    iframe {
      width: 100%;
    }
    &.video-modal {
      opacity: 1;
      transform: none;
      padding: 0px;
      background: transparent;
      box-shadow: none;
      margin: 0px auto;
      max-width: 80%;

      & > div {
        padding-bottom: 0;
        position: relative;

        iframe {
          width: 100%;
          max-height: 100%;
        }
      }
    }
  `,
]);

export const ModalAnimation = styled.div(() => []);
// motion.div
