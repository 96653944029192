import { Switch, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import PureHtml from "atoms/PureHtml";
import cn from "classnames";
import { Cohort } from "gql/graphql";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as EyeIcon } from "images/eye.svg";
import { ReactComponent as KeyIcon } from "images/key.svg";
import { ReactComponent as TickMark } from "images/tick-mark.svg";
import GoogleAuth from "organisms/GoogleAuth/GoogleAuth";
import { FC } from "react";
import useCohortForm from "../hooks/useCohortForm";
import { PASSWORD_RULES } from "../utils";
import TermsAndConditions from "./TermsAndConditions";
import { Link } from "react-router-dom";

interface CohortFormProps {
  cohort: Cohort;
  show: boolean;
  onNext: (payload: any) => void;
}

const CohortForm: FC<CohortFormProps> = ({ cohort, show, onNext }) => {
  const {
    emailValue,
    password,
    isPassword,
    isAgreed,
    userType,
    isLoading,
    isRegisterDisabled,
    cohortDescription,
    params,
    error,
    hasExistingAccount,
    setHasExistingAccount,
    setIsPassword,
    setEmailValue,
    setPassword,
    setIsAgreed,
    onSubmit,
    handleRegister,
  } = useCohortForm(onNext);

  const getLogo = () => {
    if (cohort?.logo?.large?.location) {
      return (
        <img
          src={cohort?.logo?.large?.location}
          alt=""
          className={cn(
            "w-20 h-20 object-cover z-[50] mx-auto rounded-full flex border border-aqua-400",
            params?.id === "time-to-raise" && "time-to-raise"
          )}
        />
      );
    } else return null;
  };

  const getHeader = () => {
    const header =
      (cohort.isOpen ? cohort.header : cohort.closedHeader) || cohort.header;

    return (
      <div className="flex flex-col flex-wrap items-center justify-center my-2 text-3xl font-normal text-center">
        {cohort.isInvitation ? (
          <span>
            <span>Apply here to get in touch with </span>
            <span className="text-aqua-400">{cohort.owner?.displayName}</span>
          </span>
        ) : (
          <span className="text-aqua-400">{header}</span>
        )}
      </div>
    );
  };

  return (
    <Transition appear show={show}>
      <Transition.Child
        className="relative z-30 flex flex-col items-center justify-center w-screen min-h-screen transition"
        enter="easy-in-out duration-700"
        enterFrom="opacity-0 scale-95 translate-y-24"
        enterTo="opacity-100 scale-100 translate-y-0"
        leave="duration-700"
        leaveTo="scale-95 opacity-0 translate-y-96"
      >
        <h2 className="relative z-10 w-full mx-auto leading-7 flex-col hidden md:flex">
          {getLogo()}
          {getHeader()}
        </h2>
        <div
          className={cn(
            "md:w-[800px] px-8 bg-white flex flex-col items-center w-[400px] mt-4 pt-4 pb-8 text-gray-800 rounded-lg max-h-screen overflow-y-auto"
          )}
        >
          <div className="bg-[#202337] w-[70px] h-[5px] rounded-md mb-4"></div>
          <div className="flex md:flex-row gap-6">
            <div className="hidden w-full md:flex flex-col">
              {cohort.poster?.original?.location && (
                <img
                  src={cohort?.poster?.original?.location}
                  className="object-cover w-full rounded-md grow"
                />
              )}
              {cohortDescription && (
                <PureHtml
                  tag="p"
                  content={cohortDescription}
                  className="mt-4 overflow-y-auto text-center max-h-36 grow"
                />
              )}
            </div>

            <div className="w-full">
              <div className="flex flex-col items-center justify-center my-2 overflow-hidden text-center text-black md:hidden">
                {getLogo()}
                {getHeader()}
                {cohortDescription && (
                  <PureHtml
                    tag="p"
                    content={cohortDescription}
                    className="text-base text-center my-2 max-w-[300px] font-normal line-clamp-5"
                  />
                )}
              </div>
              {!cohort.isOpen ? (
                <>
                  <div className="flex flex-col items-center my-2 overflow-hidden text-center text-black">
                    <span>This Applications Is closed</span>
                    <PureHtml
                      tag="p"
                      content={cohort.closedDescription ?? ""}
                      className="text-base text-center my-2 max-w-[300px] font-normal line-clamp-5"
                    />
                  </div>
                  <label className="flex items-center justify-center w-full mt-2 text-sm text-gray-500">
                    <span>Already have an account?</span>
                  </label>
                  <Link
                    className="flex justify-center w-full py-2 mt-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 "
                    to={"/login"}
                    target={"_parent"}
                  >
                    Sign in
                  </Link>
                  <label className="flex items-center justify-center w-full mt-2 text-sm text-gray-500">
                    <span>Don't have an account?</span>
                  </label>
                  <Link
                    className="flex justify-center w-full py-2 mt-2 font-bold bg-white border rounded-lg text-aqua-300 border-aqua-300 hover:text-white hover:bg-aqua-300"
                    to={"/onboard"}
                  >
                    Create Account
                  </Link>
                </>
              ) : (
                <>
                  <div>
                    <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                      <EmailIcon width="24" height="24" />
                      <input
                        type="text"
                        className="w-full ml-2 border-none"
                        placeholder="Email"
                        value={emailValue}
                        onChange={({ target }) => setEmailValue(target.value)}
                      />
                    </label>
                    <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                      <KeyIcon width="24" height="24" />
                      <input
                        type={isPassword ? "password" : "text"}
                        className="w-full ml-2 border-none"
                        placeholder="Password"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                      />
                      <button
                        type="button"
                        onClick={() => setIsPassword((prev) => !prev)}
                        className="rounded-full cursor-pointer"
                      >
                        <EyeIcon width="24" height="24" />
                      </button>
                    </label>

                    {error && (
                      <div className="flex justify-center w-full h-4 mb-2">
                        <p className="text-xs text-fire-400">
                          {error?.message}
                        </p>
                      </div>
                    )}

                    {!hasExistingAccount ? (
                      <div className="flex flex-col w-full">
                        <div className="ml-1">
                          {PASSWORD_RULES.map(([regexp, text], index) => (
                            <div key={index} className="flex items-center mb-2">
                              <span
                                className={cn(
                                  "border flex justify-center items-center p-1 rounded-full text-white w-[15px] h-[15px] transition duration-100",
                                  password.match(regexp)
                                    ? "bg-[#5EC253] border-transparent"
                                    : "border-gray-400"
                                )}
                              >
                                <TickMark />
                              </span>
                              <span className="ml-2 text-xs text-gray-500">
                                {text}
                              </span>
                            </div>
                          ))}
                        </div>
                        <div className="flex items-center mt-2">
                          <Switch
                            checked={isAgreed}
                            onChange={(e: any) => setIsAgreed(e)}
                            className={cn(
                              isAgreed ? "bg-aqua-400" : "bg-aqua-200",
                              "relative inline-flex mb-0.5 h-[20px] w-[33px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={cn(
                                isAgreed ? "translate-x-3" : "translate-x-0.5",
                                "pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg my-auto ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                          <TermsAndConditions />
                        </div>
                        <Button
                          className="flex justify-center w-full py-2 mt-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                          type="submit"
                          loading={isLoading}
                          disabled={isRegisterDisabled}
                          onClick={handleRegister}
                        >
                          Apply
                        </Button>
                        <div className="mt-2">
                          <span className="mt-2 text-sm text-gray-600">
                            Already have an account?
                          </span>
                          <button
                            type="button"
                            className="flex justify-center w-full py-2 mt-1 bg-white border rounded-lg text-aqua-400 hover:text-white border-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                            onClick={() => setHasExistingAccount(true)}
                          >
                            Apply with existing account
                          </button>
                        </div>
                        <GoogleAuth
                          type="SIGN_UP"
                          policiesAndTermsAgreed={isAgreed}
                          cohort={params?.id}
                          //@ts-ignore
                          signUpAs={userType}
                          onSignUpSuccess={() => {
                            onNext({
                              type:
                                userType === "COMPANY" ? "startup" : "investor",
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col w-full">
                        <label className="flex items-center justify-center w-full mt-2 text-sm">
                          <span className="text-gray-400">
                            Already have an account with Sircular?
                          </span>
                        </label>
                        <Button
                          className="flex justify-center w-full py-2 mt-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                          type="submit"
                          loading={isLoading}
                          disabled={isRegisterDisabled}
                          onClick={onSubmit}
                        >
                          Apply
                        </Button>

                        <GoogleAuth type="SIGN_IN" cohort={params?.id} />
                        <label className="flex items-center justify-center w-full mt-4 text-sm">
                          <span className="text-gray-400">
                            Don't have an account?
                          </span>
                        </label>
                        <button
                          type="button"
                          className="flex justify-center w-full py-2 mt-1 bg-white border rounded-lg text-aqua-400 hover:text-white border-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                          onClick={() => setHasExistingAccount(false)}
                        >
                          Apply with new account
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default CohortForm;
