import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import TabContainer from "../TabContainer";
import AffinityIntegrationDocs from "./AffinityIntegrationDocs";
import AirtableIntegrationDocs from "./AirtableIntegrationDocs";
import SalesforceIntegrationDocs from "./SalesforceIntegrationDocs";
import SlackIntegrationDocs from "./SlackIntegrationDocs";
import EmailIntegrationDocs from "./EmailIntegrationDocs";
import Button from "atoms/Button/Button";
import { ADD_TOKEN, GET_TOKENS } from "queries/general/account/apiDocs";
import { triggerSnack } from "organisms/Snack/sagaActions";
import {
  AddTokenMutation,
  AddTokenMutationVariables,
  GetTokensQuery,
} from "gql/graphql";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { getUser } from "models/user/selectors";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import Loading from "atoms/Loading/Loading";

const AIA_FEATURE_LIMITS = ["ZAPIER_INTEGRATION"];

const ApiKey = () => {
  const { data, refetch } = useQuery<GetTokensQuery>(GET_TOKENS);

  const [addToken, { data: newTokenData }] = useMutation<
    AddTokenMutation,
    AddTokenMutationVariables
  >(ADD_TOKEN);

  const dispatch = useDispatch();

  const user = useSelector(getUser);

  const [label, setLabel] = useState("");
  const [labelError, setLabelError] = useState(false);

  const { openModal } = useUpgradePlanModal();

  const aiaRates = useAiaRateLimits(user.id, AIA_FEATURE_LIMITS);

  const zapierRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["ZAPIER_INTEGRATION"];

  const isGenerateKeyAllowed = Boolean(zapierRates?.remaining);

  const onLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
    if (e.target.labels) {
      setLabelError(false);
    }
  };

  const handleGenerateKey = () => {
    if (!label) {
      setLabelError(true);
      return;
    }
    addToken({ variables: { label: label } });
    refetch();
  };

  const onCopy = () => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Key copied to clipboard!",
      })
    );
  };

  if (aiaRates?.isRatesLoading) {
    return <Loading />;
  }

  return (
    <TabContainer>
      <div className="p-2 font-lato">
        <h1 className="text-xl my-2">
          Welcome to the Sircular-Zapier Integration API Documentation
        </h1>
        <p className="text-black">
          This guide is designed to help you seamlessly integrate your favorite
          CRM applications like Affinity, Airtable, Salesforce, and others with
          Sircular using Zapier. Our aim is to automate the process of
          generating startup analysis, making your investment decisions faster
          and more data-driven.
          <br />
          In this documentation, we will provide you with:
        </p>
        <ul className="!list-disc !list-inside !ml-4">
          <li>Your API key</li>
          <li>Why it is a good idea to integrate Sircular with your CRM</li>
          <li>
            Step by step guides for integrating Sircular with various CRMs and
            communication tools
          </li>
        </ul>

        <p className="text-black">
          To begin the integration, you will need an API key from Sircular.
        </p>

        {data?.me?.tokens && data.me.tokens.length > 0 && (
          <table className="my-4">
            <h2 className="mb-2">Your API keys:</h2>
            {data.me.tokens.map((t) => (
              <tr>
                <td>{t?.label}</td>
                <td>
                  <span className="align-sub">*****</span>
                  <span>{t?.tail.slice(-5)}</span>
                </td>
              </tr>
            ))}
          </table>
        )}

        {isGenerateKeyAllowed ? (
          <div className="my-4">
            <div className="flex items-center gap-x-2">
              <Button color="primary" onClick={handleGenerateKey}>
                Generate API key
              </Button>

              <input
                type="text"
                className={cn(
                  "border py-2 px-3 rounded-full",
                  labelError && "border-red-500"
                )}
                placeholder="Enter key's label"
                value={label}
                onChange={onLabelChange}
              />

              {newTokenData?.me?.tokens?.add?.value && (
                <CopyToClipboard
                  onCopy={onCopy}
                  text={newTokenData?.me?.tokens?.add?.value}
                >
                  <div className="flex px-4 py-3 bg-gray-100 rounded-full max-w-md placeholder:text-sm placeholder:italic hover:cursor-pointer">
                    <FontAwesomeIcon icon={["fal", "copy"]} className="!w-3" />
                    <span className="ml-2 text-xs truncate">
                      {newTokenData?.me?.tokens?.add?.value}
                    </span>
                  </div>
                </CopyToClipboard>
              )}
            </div>

            {newTokenData?.me?.tokens?.add?.value && (
              <p className="text-green-500 mt-2">
                For security purposes, this key is only shown to you once and
                will not be accessible once you leave this page. You will be
                able to create new keys at any point on this page however.
              </p>
            )}
          </div>
        ) : (
          <div className="my-4">
            <p className="text-black mb-2">
              Your current plan does not give you access to API integrations. If
              you want to take your automation to the next level, upgrade to a
              superior plan.
            </p>
            <Button color="primary" rectangular onClick={() => openModal()}>
              Upgrade
            </Button>
          </div>
        )}

        <h2>
          Once you have setup the integration, you will be able to create flows
          like this:
        </h2>
        <ol className="!list-decimal !list-inside !ml-4">
          <li>
            Receive Pitch Decks: Automatically receive pitch decks in your CRM
            (ie Affinity, Airtable, Salesforce).
          </li>
          <li>
            Automate Data Transfer: Zapier detects this event and sends the
            pitch deck to Sircular.
          </li>
          <li>
            Analysis Generation: Sircular processes the deck and generates a
            detailed analysis.
          </li>
          <li>
            Seamless Link Return: Sircular sends the analysis link back to
            Zapier.
          </li>
          <li>
            CRM Integration: Zapier integrates this link into your CRM,
            attaching it to the relevant startup entry.
          </li>
          <li>
            Direct Access: Access startup analyses directly from your CRM
            interface.
          </li>
        </ol>

        <h2 className="mt-2"> Benefits of Integration:</h2>
        <ul className="!list-disc !list-inside !ml-4">
          <li>
            Increased Efficiency: Eliminate the time spent on manual uploads and
            document handling.
          </li>
          <li>
            Quick Access to Key Insights: Directly read startup highlights and
            check if they align with your investment criteria.
          </li>
          <li>
            Comprehensive Analysis: Evaluate every startup in your deal flow for
            well-informed investment decisions.
          </li>
          <li>
            Enhanced Deal Flow Insights: Upcoming analytics from Sircular on
            deal flow level will provide deeper insights, improving your
            investment strategy. The more analysis you have, the deeper the
            insights.
          </li>
        </ul>

        <h2 className="mt-4">Step-by-step guide for integration</h2>
        <h2 className="mt-4 italic">CRM integrations</h2>
        <AffinityIntegrationDocs />
        <AirtableIntegrationDocs />
        <SalesforceIntegrationDocs />
        <h2 className="mt-4 italic">Communication tools integrations</h2>
        <SlackIntegrationDocs />
        <EmailIntegrationDocs />
      </div>
    </TabContainer>
  );
};

export default ApiKey;
