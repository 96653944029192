import { SingleSelect, SingleSelectChoice } from "molecules/SingleSelect";
import { FC, useEffect, useState } from "react";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import {
  ChurnRate,
  DemandFreqUnits,
  FrequencyTimeEnum,
  GrowthRateEnum,
  Periodicity,
  RatePeriod,
  RetentionPeriodEnum,
  SupplyFreqUnits,
  Time,
  Users,
} from "gql/graphql";

export type OnSaveFunc = (val: {
  inputValue: string | number | undefined | null;
  dropdownValue: string | number | undefined | null;
  secondDropdownValue: string | number | undefined | null;
  noteValue: string | undefined | null;
}) => void;

type DropdownValue =
  | Periodicity
  | Users
  | GrowthRateEnum
  | RetentionPeriodEnum
  | ChurnRate
  | RatePeriod
  | Time
  | DemandFreqUnits
  | SupplyFreqUnits
  | FrequencyTimeEnum
  | null;

interface Props {
  inputDefaultValue?: string | null;
  dropdownDefaultValue?: DropdownValue;
  secondDropdownDefaultValue?: DropdownValue;
  noteDefaultValue?: string | null;
  // Dropdown only rendered if choices are provided
  dropdownChoices?: SingleSelectChoice[];
  secondDropdownChoices?: SingleSelectChoice[];
  setIsEditMode: (v: boolean) => void;
  onSave: OnSaveFunc;
}

const TractionInputDropdownText: FC<Props> = ({
  inputDefaultValue,
  dropdownDefaultValue,
  secondDropdownDefaultValue,
  noteDefaultValue,
  dropdownChoices,
  secondDropdownChoices,
  setIsEditMode,
  onSave,
}) => {
  const [noteValue, setNoteValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState<SingleSelectChoice | null>(
    null
  );
  const [secondDropdownValue, setSecondDropdownValue] =
    useState<SingleSelectChoice | null>(null);

  useEffect(() => {
    if (noteDefaultValue) setNoteValue(noteDefaultValue);
  }, [noteDefaultValue]);

  useEffect(() => {
    if (inputDefaultValue) setInputValue(inputDefaultValue);
  }, [inputDefaultValue]);

  useEffect(() => {
    if (dropdownDefaultValue && dropdownChoices) {
      const val =
        dropdownChoices.find((c) => c.id === dropdownDefaultValue) || null;
      setDropdownValue(val);
    }
  }, [dropdownDefaultValue]);

  useEffect(() => {
    if (secondDropdownDefaultValue && secondDropdownChoices) {
      const val =
        secondDropdownChoices.find(
          (c) => c.id === secondDropdownDefaultValue
        ) || null;
      setSecondDropdownValue(val);
    }
  }, [secondDropdownDefaultValue]);

  const onNoteValueChange = (v: string) => {
    setNoteValue(v);
  };

  const onInputValueChange = (v: string) => {
    setInputValue(v);
  };

  const onDropdownValueChange = (v: SingleSelectChoice) => {
    setDropdownValue(v);
  };

  const onSecondDropdownValueChange = (v: SingleSelectChoice) => {
    setSecondDropdownValue(v);
  };

  const onCancel = () => {
    setIsEditMode(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex border p-1 rounded mb-2">
        <input
          value={inputValue}
          onChange={(e) => onInputValueChange(e.target.value)}
          className="grow pl-2 text-gray-500 min-w-0"
        />

        {dropdownChoices && (
          <SingleSelect
            choices={dropdownChoices}
            value={dropdownValue}
            onChange={(e: SingleSelectChoice) => onDropdownValueChange(e)}
          />
        )}

        {secondDropdownChoices && (
          <SingleSelect
            choices={secondDropdownChoices}
            value={secondDropdownValue}
            onChange={(e: SingleSelectChoice) => onSecondDropdownValueChange(e)}
          />
        )}
      </div>

      <textarea
        className="p-2 mb-2 text-gray-500 border rounded border-aqua-200 bg-aqua-50 placeholder:!text-gray-400 placeholder:text-sm placeholder:italic"
        rows={3}
        value={noteValue}
        onChange={(e) => onNoteValueChange(e.target.value)}
      />

      <SaveAndCancelButtons
        onSave={() =>
          onSave({
            inputValue,
            dropdownValue: dropdownValue?.id,
            secondDropdownValue: secondDropdownValue?.id,
            noteValue,
          })
        }
        onCancel={onCancel}
        className="self-end"
      />
    </div>
  );
};

export default TractionInputDropdownText;
