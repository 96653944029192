import { setGlobalState } from "models/globalState/sagaActions";
import { getGlobalState } from "models/globalState/selectors";
import {
  getActiveCohort,
  getCohort,
  getIsApplicationSent,
  getUser,
} from "models/user/selectors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useMySpace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [position, setPosition] = useState("relative");
  const [isAllowed, setIsAllowed] = useState(false);

  const isApplicationSent = useSelector(getIsApplicationSent);
  const cohort = useSelector(getCohort);
  const activeCohort = useSelector(getActiveCohort);
  const userData = useSelector(getUser);

  const isModalOpen = useSelector((state) =>
    getGlobalState(state, "isCohortModalOpen")
  );

  const invitation = useSelector((state: any) => state?.invitation);

  const handleCloseModal = () => {
    dispatch(setGlobalState({ isCohortModalOpen: false }));
  };

  const getHeight = async () => {
    var position = "relative";
    if (window.innerWidth <= 768) {
      position = "fixed";
    } else if (window.innerWidth > 768) {
      if ((await window.scrollY) >= 85) {
        position = "fixed";
      }
    }
    setPosition(position);
  };

  useEffect(() => {
    if (isApplicationSent) {
      setIsAllowed(true);
    }
  }, [isApplicationSent]);

  const isShowModal =
    cohort && isModalOpen && activeCohort?.slug !== cohort?.slug;

  useEffect(() => {
    window.addEventListener("scroll", getHeight);

    return () => window.removeEventListener("scroll", getHeight); // don't forget to clean up
  }, []);

  return {
    isAllowed,
    isShowModal,
    invitation,
    handleCloseModal,
  };
};

export default useMySpace;
