// @ts-nocheck
import { SidebarContent } from "ui/elements/PanelLayout.styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animation } from "ui/animations";
import { getTeam } from "./selectors";
import * as sagaActions from "./sagaActions";
import Loading from "atoms/Loading/Loading";
import TeamsForm from "./TeamsForm";

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sagaActions.getTeam());
  }, []);

  const data = useSelector(getTeam);

  return (
    <SidebarContent
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
    >
      {!data ? <Loading fullPage /> : <TeamsForm />}
    </SidebarContent>
  );
};
