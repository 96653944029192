// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import { toggleLoaderState } from "./reducer";
import { toggleLoader } from "./sagaActions";

export function* handleToggleLoader({ payload: { isLoading, component } }) {
  yield put(toggleLoaderState({ isLoading, component }));
}

export default function* rootSaga() {
  return [yield takeEvery(toggleLoader, handleToggleLoader)];
}
