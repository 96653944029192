import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import { Editor } from "react-draft-wysiwyg";
import CompanyRow from "./CompanyRow";
import { stateFromHTML } from "draft-js-import-html";
import { convertToRaw, EditorState } from "draft-js";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useApolloClient } from "@apollo/client";
import { updateCustomState } from "../../reducer";
import { useDispatch, useSelector } from "react-redux";
import { getCustomState } from "../../selectors";
import { useLang } from "core/lang/LangaugeSetting";
import draftToHtml from "draftjs-to-html";
import FormInput from "atoms/Form/FormInput";
import AddStartupsToBoard from "./AddStartupsToBoard";
import Checkbox from "atoms/Checkbox/Checkbox";
import cn from "classnames";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import {
  CREATE_STARTUP_BOARD,
  EDIT_STARTUP_BOARD,
} from "queries/investor/boards";
import { mixpanelCreateBoard } from "core/mixpanel/Mixpanel";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  rerenderBoard: () => void;
  initialValues?: Omit<typeof INITIAL_VALUES, "startups"> & { startups: any[] };
  editId?: any;
};

const INITIAL_VALUES = {
  title: "",
  description: "",
  logo: "",
  startups: [],
  isIdentityRequired: false,
  isDarkMode: false,
};

const replaceEmptyPTagWithBrTag = (htmlString: string) =>
  htmlString.replace(/<p><\/p>/gi, "<br/>");

const NewBoard: FC<Props> = ({
  isOpen,
  onClose,
  rerenderBoard,
  initialValues = INITIAL_VALUES,
  editId = false,
}) => {
  const [boardTitle, setBoardTitle] = useState(initialValues.title);
  const [boardDescription, setBoardDescription] = useState(
    initialValues.description
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(stateFromHTML(initialValues.description))
  );
  const [isIdentityRequired, setIsIdentityRequired] = useState(
    initialValues.isIdentityRequired
  );
  const [isDarkMode, setIsDarkMode] = useState(initialValues.isDarkMode);
  const [selectedCompanies, setSelectedCompanies] = useState(
    initialValues.startups
  );
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const state: any = useSelector(getCustomState);
  const dispatch = useDispatch();
  const setState = (params: any) => {
    dispatch(updateCustomState(params));
  };

  const client = useApolloClient();

  const setSnack = (snackParams: any) => {
    dispatch(triggerSnack(snackParams));
  };

  const { lang } = useLang();

  const onSaveButtonClicked = async () => {
    setIsSubmitted(true);

    setIsLoading(true);

    const newBoardDescription = replaceEmptyPTagWithBrTag(boardDescription);

    try {
      const dataKey = editId ? "editStartupBoard" : "createStartupBoard";
      const mutation = editId ? EDIT_STARTUP_BOARD : CREATE_STARTUP_BOARD;
      const variables: any = {
        input: {
          title: boardTitle,
          description: newBoardDescription,
          startups: selectedCompanies.map((c: any) => c._id),
          file,
          isIdentityRequired,
          isDarkMode,
        },
      };

      if (editId) {
        variables.id = editId;
      }

      const res = await client.mutate({
        mutation,
        variables,
      });

      if (res) {
        const { title: updatedTitle, description: updatedDescription } =
          (res.data as any)?.[dataKey] || {};

        setState({
          ...state,
          boardTitle: updatedTitle,
          boardDescription: updatedDescription,
          isEditBoardModalOpen: false,
        });

        setIsLoading(false);

        setSnack({
          type: "success",
          title: lang.savedAutomatically,
          message: "Board data successfully updated",
        });

        // Force rerendering the board in order to fetch the newly added company data
        rerenderBoard();
        onClose();
        mixpanelCreateBoard(selectedCompanies, dataKey);
      }
    } catch (error) {
      setSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: "We could not save board data",
      });
      setIsLoading(false);
    }
  };

  const addCompanyToSelectedCompanies = (company: any) => {
    setSelectedCompanies((prevSelectedCompanies: any) => [
      company,
      ...prevSelectedCompanies,
    ]);
  };

  const removeCompanyFromSelectedCompanies = (companyId: any) => {
    setSelectedCompanies((prevSelectedCompanies: any) =>
      prevSelectedCompanies.filter((c: any) => c._id !== companyId)
    );
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 md:z-10"
        onClose={onClose}
        static
      >
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative w-screen pointer-events-auto md:max-w-xl">
                  <div className="flex flex-col h-full py-6 overflow-y-scroll bg-white shadow-xl md:pt-20">
                    <div className="px-4 sm:px-6 md:pt-7">
                      <Dialog.Title className="text-lg font-bold text-[#153A47]">
                        {editId ? "Edit your board" : "Create a new Board"}
                      </Dialog.Title>
                      <Dialog.Description className="text-black">
                        Upload a logo that represents the board
                      </Dialog.Description>
                    </div>
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      <div className="absolute inset-0">
                        <div className="pb-20 mx-6 font-sans content-container">
                          <div className="flex justify-center">
                            <ProfilePictureSelect
                              label="Upload logo"
                              onComplete={(file) => setFile(file)}
                              preview={initialValues.logo}
                            />
                          </div>
                          <FormInput
                            id="board-name"
                            label="Board name"
                            type="text"
                            value={boardTitle}
                            onChange={(e: any) => setBoardTitle(e.target.value)}
                            error={
                              isSubmitted &&
                              !boardTitle?.trim() &&
                              "Please give a title to your startup board."
                            }
                          />

                          <div className="mt-4">
                            <Checkbox
                              label="Require email to view board and startups in board"
                              checked={isIdentityRequired}
                              onChange={setIsIdentityRequired}
                            />
                          </div>

                          <div className="mt-4">
                            <Checkbox
                              label="Use dark colors schema for the board"
                              checked={isDarkMode}
                              onChange={setIsDarkMode}
                            />
                            <div className="flex items-center mt-2 text-sm text-gray-600 ml-7">
                              <div
                                className={cn(
                                  "w-5 h-5 mr-2 rounded-md border border-gray-400",
                                  isDarkMode
                                    ? "bg-neutral-900"
                                    : "bg-neutral-100"
                                )}
                              />
                              <span>
                                Board's schema is{" "}
                                {isDarkMode ? "dark" : "light"}
                              </span>
                            </div>
                          </div>

                          <label
                            htmlFor="board-description"
                            className="block mt-4 mb-1"
                          >
                            Introduction
                          </label>
                          <Editor
                            editorStyle={{ maxHeight: "150px" }}
                            wrapperClassName="rounded p-1 border border-black border-opacity-25"
                            toolbarClassName="border-none"
                            editorClassName="text-sm text-black"
                            editorState={editorState}
                            stripPastedStyles={true}
                            onEditorStateChange={(newState) => {
                              setEditorState(newState);
                              setBoardDescription(
                                draftToHtml(
                                  convertToRaw(newState.getCurrentContent())
                                )
                              );
                            }}
                            toolbar={{
                              options: ["inline", "link", "list", "emoji"],
                              inline: {
                                options: ["bold", "italic", "underline"],
                              },
                              link: {
                                popupClassName: "text-editor-link-popup",
                                showOpenOptionOnHover: true,
                              },
                            }}
                          />

                          <AddStartupsToBoard
                            addCompanyToSelectedCompanies={
                              addCompanyToSelectedCompanies
                            }
                            selectedCompanies={selectedCompanies}
                          />

                          <div className="max-h-72 min-h-[18rem] overflow-y-auto px-2 mt-1">
                            {selectedCompanies?.map((company: any) => (
                              <CompanyRow
                                key={company._id}
                                company={company}
                                removeCompanyFromSelectedCompanies={
                                  removeCompanyFromSelectedCompanies
                                }
                                isSearchResult={false}
                              />
                            ))}
                          </div>

                          <div className="fixed bottom-0 flex items-end justify-end w-full py-4 pr-16 bg-white">
                            <Button
                              className="justify-center mr-4 w-28"
                              color="transparent"
                              rectangular
                              onClick={onClose}
                              disabled={isLoading}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="justify-center w-28"
                              color="primary"
                              rectangular
                              onClick={onSaveButtonClicked}
                              disabled={isLoading}
                              data-key="create-board"
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewBoard;
