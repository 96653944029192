import { Transition } from "@headlessui/react";

export const Page: React.FC = ({ children }) => (
  <Transition
    show={true}
    enter="transition-opacity duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="w-full bg-white dark:bg-neutral-900 container mx-auto px-4"
  >
    {children}
  </Transition>
);
