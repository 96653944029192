import { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Transition } from "@headlessui/react";
import cn from "classnames";
import PureHtml from "atoms/PureHtml";
import Button from "atoms/Button/Button";
import { ReactComponent as KeyIcon } from "images/key.svg";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as EyeIcon } from "images/eye.svg";
import { REMEMBER_ME_COOKIE_KEY } from "core/consts";
import ContactSupport from "organisms/ContactSupport/ContactSupport";
import useCookie from "hooks/useCookie";
import { Invitation } from "pages/invitation/types";
import { setGlobalState } from "models/globalState/sagaActions";


import { ACCEPT_INVITATION } from "queries/general/invitation";
import { acceptInvitation } from "./sagaActions";
import { isLoadingEnabled } from "models/loaders/selectors";

interface InvitationProps {
  invitation: Invitation;
}

const InvitationForm: FC<InvitationProps> = ({ invitation }) => {
  const dispatch = useDispatch();
  const { getCookie } = useCookie();
  const { active } = getCookie(REMEMBER_ME_COOKIE_KEY);
  const [isPassword, setIsPassword] = useState(true);
  const [emailValue, setEmailValue] = useState(invitation?.inviteesEmail);
  const [rememberMe, setRememberMe] = useState(active);
  const [password, setPassword] = useState("");

  const onHelpClicked = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };


  const onSubmit = async () => {

    dispatch(
      acceptInvitation({
        invitationSlug: invitation.invitationSlug,
        email: emailValue,
        password,
        policiesAndTermsAgreed: true,
        rememberMe: true,
      })
    );
  };

  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, ACCEPT_INVITATION)
  );

  const invitationDescription = `By accepting this invitation, you will be able to contribute to ${invitation.inviterCompanyName}'s investment journey.`;

  const getLogo = () => {
    if (invitation?.invitationId && invitation?.inviterCompanyLogo) {
      return (
        <img
          alt=""
          src={invitation.inviterCompanyLogo}
          className="w-[6rem] mx-auto object-cover h-[6rem] mb-2 rounded-full border-2 border-white/70"
        />
      );
    } else return;
  };

  const getHeader = () => {
    if (invitation?.invitationId) {
      return (
        <div className="flex flex-col flex-wrap items-center justify-center my-2 text-3xl font-normal text-center">
          <span>Welcome to</span>{" "}
          <span className="text-aqua-400">{invitation.inviterCompanyName}</span>
        </div>
      );
    } else return;
  };

  return (
    <div className="z-10">
      <h2 className="relative w-full mx-auto leading-7">
        <div className="flex-col hidden md:flex">{getHeader()}</div>
      </h2>
      <Transition appear show>
        <Transition.Child
          className="relative z-30 flex flex-col items-center overflow-y-auto transition no-srollbar invisible-scroll"
          enter="easy-in-out duration-1000"
          enterFrom="opacity-0 scale-95 translate-y-24"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="duration-700"
          leaveTo="scale-95 opacity-0 translate-y-96"
        >
          <div className="bg-white flex flex-col items-center px-4 md:w-[700px] mt-6 p-6 text-gray-800 rounded-lg">
            <div className="bg-[#202337] w-[70px] h-[5px] rounded-md mb-4"></div>
            <div className="flex gap-10 md:flex-row">
              <h2 className="relative items-center hidden w-full mx-auto leading-7 md:flex">
                <div className="flex-col items-center justify-center hidden md:flex">
                  {getLogo()}
                  <PureHtml
                    tag="p"
                    content={invitationDescription}
                    className="text-base text-center my-2 flex max-w-[300px] font-normal"
                  />
                </div>
              </h2>
              <div className="w-full md:mt-4">
                <div className="flex flex-col items-center justify-center my-2 text-center text-black md:hidden">
                  {getLogo()}
                  {getHeader()}
                  <PureHtml
                    tag="p"
                    content={invitationDescription}
                    className="text-base text-center my-2 flex max-w-[300px] font-normal"
                  />
                </div>

                <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                  <EmailIcon width="24" height="24" />
                  <input
                    type="text"
                    className="w-full ml-2 border-none"
                    placeholder="Email"
                    value={emailValue}
                    disabled={true}
                    onChange={({ target }) => setEmailValue(target.value)}
                  />
                </label>
                <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                  <KeyIcon width="24" height="24" />
                  <input
                    type={isPassword ? "password" : "text"}
                    className="w-full ml-2 border-none"
                    placeholder="Password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setIsPassword((prev) => !prev)}
                    className="rounded-full cursor-pointer"
                  >
                    <EyeIcon width="24" height="24" />
                  </button>
                </label>

                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <Switch
                      checked={rememberMe}
                      onChange={(e: any) => setRememberMe(e)}
                      className={cn(
                        rememberMe ? "bg-aqua-400" : "bg-aqua-200",
                        "relative inline-flex mb-0.5 h-[20px] w-[33px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={cn(
                          rememberMe ? "translate-x-3" : "translate-x-0.5",
                          "pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg my-auto ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <label
                      className={cn(
                        "ml-2 text-sm",
                        rememberMe ? "text-gray-800" : "text-gray-400"
                      )}
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                {invitation.isAlreadyAdminError && (
                  <div className="flex flex-col items-center my-2">
                    <span className="mt-4 text-center text-red-500">
                    You are already a part of an organization. Please contact our support team to accept this invite.
                    </span>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={onHelpClicked}
                      className="my-2 w-fit font-bold p-2 rounded-lg"
                    >
                      Contact
                    </Button>
                  </div>
                )}

                <Button
                  className="flex justify-center w-full py-2 mt-4 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                  type="submit"
                  onClick={onSubmit}
                  loading={isLoading}
                >
                  Accept Invitation
                </Button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
      <ContactSupport/>
    </div>
  );
};

export default InvitationForm;
