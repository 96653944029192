const Card = (props: any) => (
  <div
    className={`bg-white rounded-lg text-black border border-gray-100 ${
      props.wide ? "w-full" : "md:w-3/5  max-w-2xl"
    } m-auto`} /* shadow w-11/12 */
  >
    {props.children}
  </div>
);

export default Card;
