import { useLayoutEffect } from "react";

// headless ui introduces a bug with adding additional 15px right padding
// they assume that scrolling works for html element, but this is not the case for us
//
// @TODO: (1) investigate similar issues on their GH
// (2) or make a common solution for dialog elements
// (3) or review our scrolling behavior in order to fit into their approach
//
const FixScrollProblem = () => {
  useLayoutEffect(() => {
    const root = document.getElementById("root");

    if (!root) {
      return;
    }

    root.style.overflow = "hidden";
    root.style.paddingRight = "15px";

    return () => {
      root.style.overflow = "";
      root.style.paddingRight = "";
    };
  }, []);

  return null;
};

export default FixScrollProblem;
