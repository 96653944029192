import React from "react";
import CollapsiblePage from "./CollapsiblePage";

const AirtableIntegrationDocs = () => {
  return (
    <CollapsiblePage title="Airtable Integration" className="my-2">
      <p className="text-black my-2">
        By following these steps, whenever a company record in Airtable receives
        a pitch deck attachment, it will trigger an analysis in Sircular. The
        resulting analysis URL will then be sent back and updated in the
        Airtable base automatically.
      </p>

      <h4>Set up your Airtable</h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. Start by creating an Airtable base (skip this step if you have one
          already).
        </p>

        <img
          className=""
          src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-01.png"
        />

        <p className="text-black my-2">
          2. Ensure that your Airtable base has the following columns: Pitch
          deck and Sircular URL
        </p>

        <div className="ml-4">
          <p className="text-black my-2">
            a. Add an attachment column that contains the PDF you want to send
            to Sircular.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-02.png"
          />

          <p className="text-black my-2">
            b. Add a URL column where Zapier will put the URL to the analysis
            created by Sircular.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-03.png"
          />
        </div>

        <p className="text-black my-2">
          3. Create a new Grid View that tracks records with PDFs.
        </p>
        <img
          className="mb-4"
          src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-04.png"
        />
        <img
          className="mb-4"
          src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-05.png"
        />
      </div>

      <h4>Zapier - Trigger Analysis Creation</h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. Go to{" "}
          <a href="https://zapier.com/app/zaps">https://zapier.com/app/zaps.</a>
        </p>

        <p className="text-black my-2">2. Create a new Zap.</p>
        <img
          className="mb-4"
          src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-01.png"
        />

        <p className="text-black my-2">3. Select Airtable as the trigger.</p>

        <div className="ml-4">
          <p className="text-black my-2">
            a. Select "New Record" as the event.
          </p>

          <p className="text-black my-2">
            b. Set up Zapier authentication to access your Airtable base.
          </p>

          <div className="ml-4">
            <p className="text-black my-2">
              i. Grant access to the Airtable base you set up in the previous
              section.
            </p>
          </div>

          <p className="text-black my-2">
            c. Select the base and table you want to use for integration (the
            one you configured in the previous section).
          </p>

          <p className="text-black my-2">
            d. Select the view you created to track records with PDFs.
          </p>

          <p className="text-black my-2">
            e. Set "Include file content" to "True".
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-02.png"
          />

          <p className="text-black my-2">
            f. Test the trigger (you may need to add some records with PDFs to
            see results).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-03.png"
          />
        </div>

        <p className="text-black my-2">4. Select Sircular as the action.</p>
        <div className="ml-4">
          <p className="text-black my-2">
            a. Select "Create Profile" as the event.
          </p>

          <p className="text-black my-2">
            b. Authenticate with the token you obtained from the profile
            settings on Sircular.
          </p>

          <div className="ml-4">
            <p className="text-black my-2">
              i. You can label the API key "Zapier" for clarity.
            </p>

            <p className="text-black my-2">
              ii. Paste the API key inside of the open field in Zapier.
            </p>
            <img
              className="mb-4"
              src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-04.png"
            />
          </div>

          <p className="text-black my-2">
            c. Select the record ID as the identifier.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-05.png"
          />

          <p className="text-black my-2">
            {'d. Select the file as "<your PDF column name> hydrate || …".'}
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-06.png"
          />

          <p className="text-black my-2">e. Test the action.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-07.png"
          />
        </div>

        <p className="text-black my-2">
          4. Publish the Zap (you can turn it off until the Profile URL Zap is
          ready).
        </p>
      </div>

      <h4>Zapier - Return data from Sircular to Airtable</h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. Go to{" "}
          <a href="https://zapier.com/app/zaps">https://zapier.com/app/zaps.</a>
        </p>

        <p className="text-black my-2">2. Create a new Zap.</p>

        <p className="text-black my-2">3. Select Sircular as the trigger.</p>

        <div className="ml-4">
          <p className="text-black my-2">
            a. Select "New Profile" as the event.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-08.png"
          />

          <p className="text-black my-2">
            b. Authenticate with Sircular (follow the steps from the Create
            Profile Zap).
          </p>

          <p className="text-black my-2">
            c. Test the trigger (it should show results if you tested the Create
            Profile action before).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-09.png"
          />
        </div>

        <p className="text-black my-2">4. Select Airtable as the action.</p>

        <div className="ml-4">
          <p className="text-black my-2">
            a. Select "Update record" as the event.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-11.png"
          />

          <p className="text-black my-2">
            b. Authenticate with Airtable and grant permission to the same table
            as in the Create Profile Zap (this may already be set up).
          </p>

          <p className="text-black my-2">
            c. Select the same base and table as you selected in the Create
            Profile Zap.
          </p>

          <p className="text-black my-2">
            d. Select "Custom" for the update field.
          </p>

          <div className="ml-4">
            <p className="text-black my-2">
              i. Select "1. Original Id: [some value]".
            </p>
            <img
              className="mb-4"
              src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-12.png"
            />
          </div>

          <p className="text-black my-2">
            e. Now you can select any data point you want from Sircular, and
            match it with its equivalent column inside of Airtable. below, we
            show you an example selecting the profile name and the company url
            value from the trigger to update your column.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-13.png"
          />

          <p className="text-black my-2">f. Test the Zap.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-14.png"
          />
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-15.png"
          />
        </div>

        <p className="text-black my-2">5. Publish the Zap.</p>
      </div>
      <p className="text-black my-2">
        Once you have set up both Zaps, you should see data from Sircular in
        your Airtable record used for tests.
      </p>
      <img
        className="mb-4"
        src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/airtable-zapier-16.png"
      />
    </CollapsiblePage>
  );
};

export default AirtableIntegrationDocs;
