// @ts-nocheck
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

import { STEP } from "../../constants";
import { SAVE_PROFILE_INFO } from "queries/startup/startupSettings/profileStep";
import * as slice from "../../reducer";
import * as sagaActions from "./sagaActions";
import { GET_PROFILE_INFO } from "queries/general/settings";

const client = getClient();

function* getProfileInfo() {
  yield put(toggleLoader({ component: STEP.PROFILE, isLoading: true }));

  const { data } = yield client
    .query({
      query: GET_PROFILE_INFO,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  yield put(
    slice.updateProfileInfo({
      profileInfo: data?.me,
    })
  );

  yield put(toggleLoader({ component: STEP.PROFILE, isLoading: false }));
}

function* saveProfileInfo({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.PROFILE, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: SAVE_PROFILE_INFO,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not save.",
      })
    );
    yield put(toggleLoader({ component: STEP.PROFILE, isLoading: false }));
    return;
  }
  yield getProfileInfo();
  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(
    triggerSnack({
      type: "success",
      title: "Settings saved",
      message: "Your settings have been saved.",
    })
  );
  yield put(slice.goToNextStep());

  yield put(toggleLoader({ component: STEP.PROFILE, isLoading: false }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getProfileInfo, getProfileInfo),
    yield takeEvery(sagaActions.saveProfileInfo, saveProfileInfo),
  ];
}
