// @ts-nocheck
import { SETTINGS_STEPS } from "core/consts";
import Step from "molecules/CompanyStep/CompanyStep";
import { useLang } from "core/lang/LangaugeSetting";
import { useSelector } from "react-redux";
import { getCompletedStepsInfo } from "../selectors";

const CompanySteps = () => {
  const { lang } = useLang();

  const { completedSteps } = useSelector(getCompletedStepsInfo);
  const getStatus = (status: string) =>
    completedSteps?.includes(status) ? "done" : "";

  const slider = document.querySelector(".parent");
  let mouseDown = false;
  let startX: any, scrollLeft: any;

  let startDragging = function (e) {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };
  let stopDragging = function (event) {
    mouseDown = false;
  };

  if (slider) {
    slider.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }

      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
      slider.style.cursor = "grab";
    });

    // Add the event listeners
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  }

  return (
    <div className="flex overflow-hidden overflow-x-auto lg:block parent">
      <Step
        name="profile"
        title={lang.profile}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_1)}
      />
      <Step
        name="basic"
        title={lang.basics}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_2)}
      />
      <Step
        name="teams"
        title={lang.team}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_3)}
      />
      <Step
        name="elevator-pitch"
        title={lang.elevatorPitch}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_4)}
      />
      <Step
        name="fundraising"
        title={lang.fundraising}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_5)}
      />
      <Step
        name="pitch-deck"
        title={lang.pitchDeck}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_6)}
      />
      <Step
        name="social-networks"
        title={lang.socialNetworks}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_7)}
      />
      <Step
        name="impact"
        title={lang.impact}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_8)}
      />
      <Step
        name="traction"
        title={lang.traction}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_9)}
      />
      <Step
        name="product"
        title={lang.productDescription}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_10)}
      />
      <Step
        name="market"
        title={lang.market}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_11)}
      />
      <Step
        name="business-model"
        title={lang.businessModal}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_12)}
      />
      <Step
        name="timing"
        title={lang.timing}
        status={getStatus(SETTINGS_STEPS.COMPANY_TEASER_STEP_13)}
      />
    </div>
  );
};

export default CompanySteps;
