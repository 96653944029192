import "react-phone-number-input/style.css";

import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import TextArea from "atoms/Form/TextArea";
import { EMAIL_PATTERN } from "core/consts";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as EmailIcon } from "images/email-icon.svg";
import { ReactComponent as UserIcon } from "images/user-icon.svg";
import Modal from "molecules/Modal/Modal";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import { triggerInvestorContactRequestModal } from "../sagaActions";
import { ModalContent, SuccessContent } from "./ContactModal.style";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import PureHtml from "atoms/PureHtml";
import { CONTACT_WITH } from "queries/general/contact";

const ContactModal = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const { lang } = useLang();
  const [successModal, setSuccessModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      policiesAndTermsAgreed: Boolean(user),
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      message: "",
    },
  });

  const [contactWith, { loading }] = useMutation(CONTACT_WITH);

  const isPoliciesAndTermsAgreed = watch("policiesAndTermsAgreed");

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      dispatch(
        triggerInvestorContactRequestModal({ isEnabled: false, data: null })
      );
    }
  };

  // const onSubmit = handleSubmit(
  //   ({ firstName, lastName, policiesAndTermsAgreed, email, message }) => {
  //     if (!policiesAndTermsAgreed) {
  //       return;
  //     }

  //     contactWith({
  //       variables: {
  //         firstName,
  //         lastName,
  //         email,
  //         message,
  //         target: props.id,
  //         isPoliciesAndTermsAgreed,
  //       },
  //       onCompleted: (res) => {
  //         if (props.skipSuccessModal) {
  //           dispatch(
  //             triggerSnack({
  //               type: "success",
  //               title: "Sent successfully!",
  //               message:
  //                 "Our best homing pigeons will deliver your message in a flash",
  //             })
  //           );
  //           try {
  //             Mixpanel.track("Contact sent", {
  //               recieverEmail: props.recieverEmail,
  //               sender: email,
  //               numberOfcontacts: user?.limits?.contact?.remaining,
  //             });
  //           } catch (e) {
  //             console.log(e);
  //           }
  //           dispatch(refetchUserData({}));
  //           handleClose();
  //         } else {
  //           try {
  //             Mixpanel.track("Contact sent unauth", {
  //               recieverEmail: props.recieverEmail,
  //               sender: email,
  //             });
  //           } catch (e) {
  //             console.log(e);
  //           }
  //           setSuccessModal(true);
  //         }
  //       },
  //       onError: () => {
  //         dispatch(
  //           triggerSnack({
  //             type: "error",
  //             title: "Something went wrong",
  //             message: "Please try again later",
  //           })
  //         );
  //       },
  //     });
  //   }
  // );

  return (
    <Modal
      width="32rem"
      hideCloseButton
      type={null}
      onClick={null}
      className={cn(successModal && "investor-contact-modal")}
    >
      <div
        className={cn("flex", successModal ? "justify-end" : "justify-between")}
      >
        {!successModal && (
          <h4 className="pr-4 font-medium">{`${lang.thanksForCheckingOur} ${
            props?.type === "investor"
              ? lang.investor.charAt(0).toLocaleLowerCase() +
                lang.investor.slice(1)
              : lang.startup.charAt(0).toLocaleLowerCase() +
                lang.startup.slice(1)
          } ${
            lang.teaser.charAt(0).toLocaleLowerCase() + lang.teaser.slice(1)
          }!`}</h4>
        )}
        <button
          type="button"
          className="text-gray-900 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
          aria-label="Close"
          onClick={handleClose}
        >
          <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
        </button>
      </div>

      {!successModal && (
        <p
          className={cn(
            "text-sm",
            props.type === "investor" && "text-orange-500"
          )}
        >
          {props.type === "investor" ? (
            <PureHtml
              content={`You can only send <b>3</b> messages every week, you have <b>${user?.limits?.contact?.remaining}</b> remaining`}
            />
          ) : (
            lang.getInTouchWithUsToFollowUp
          )}
        </p>
      )}

      {!successModal ? (
        <ModalContent>
          <form>
            <div className="flex-none sm:flex __none__mrgn">
              <div className="w-full mr-2 sm:w-1/2">
                <div className="pt-5 mb-0 text-sm font-medium text-black text-opacity-100 capitalize">
                  {lang.firstName}
                </div>
                <div
                  className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border${
                    errors.firstName
                      ? " border-red-300 text-red-900 placeholder-red-300"
                      : " text-gray-700 border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 7 }}
                >
                  <div className="text-sm text-gray-500">
                    <UserIcon />
                  </div>
                  <input
                    type="text"
                    id="firstName"
                    className="w-full"
                    placeholder="Enter your first name"
                    style={{ outline: "none", paddingLeft: 12 }}
                    {...register("firstName", { required: true })}
                  />
                </div>
                {errors.firstName && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.mandatory}
                  </p>
                )}
              </div>
              <div className="w-full ml-2 sm:w-1/2">
                <div className="pt-5 mb-0 text-sm font-medium text-black text-opacity-100 capitalize">
                  {lang.lastName}
                </div>
                <div
                  className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border${
                    errors.lastName
                      ? " border-red-300 text-red-900 placeholder-red-300"
                      : " text-gray-700 border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 7 }}
                >
                  <div className="text-sm text-gray-500">
                    <UserIcon />
                  </div>
                  <input
                    type="text"
                    id="lastName"
                    className="w-full"
                    placeholder="Enter your last name"
                    style={{ outline: "none", paddingLeft: 12 }}
                    {...register("lastName", { required: true })}
                  />
                </div>
                {errors.lastName && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.mandatory}
                  </p>
                )}
              </div>
            </div>

            <div className="flex-none sm:flex __none__mrgn">
              <div className="w-full">
                <div className="pt-5 mb-0 text-sm font-medium text-black text-opacity-100 capitalize">
                  {lang.emailAddress}
                </div>
                <div
                  className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border${
                    errors.email
                      ? " border-red-300 text-red-900 placeholder-red-300"
                      : " text-gray-700 border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 7 }}
                >
                  <div className="text-sm text-gray-500">
                    <EmailIcon />
                  </div>
                  <input
                    type="email"
                    id="email"
                    className="w-full"
                    placeholder={lang.enterEmailAddress}
                    style={{ outline: "none", paddingLeft: 12 }}
                    {...register("email", {
                      required: true,
                      pattern: EMAIL_PATTERN,
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.thisIsNotValidEmail}
                  </p>
                )}
              </div>
            </div>
            <div className="relative w-full sm:col-span-2">
              <div className="pt-5 mb-0 text-sm font-medium text-black text-opacity-100 capitalize">
                {lang.enterYourMessage}
              </div>
              <TextArea
                rows="3"
                id="message"
                className={`form-textarea block mt-0 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                  errors.message
                    ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
                    : ""
                }`}
                placeholder={`${lang.typeHere}...`}
                {...register("message", {
                  required: true,
                })}
                maxLength={200}
              />
              {errors.message && (
                <p className="absolute text-sm text-fire-400 bottom-1">
                  {lang.mandatory}
                </p>
              )}
            </div>

            {!Boolean(user) && (
              <>
                <div className="inline-flex align-baseline">
                  <input
                    type="checkbox"
                    style={{
                      boxShadow: "none",
                      top: 3,
                      height: 12,
                      width: 12,
                    }}
                    id="policiesAndTermsAgreed"
                    className="relative mr-2 form-checkbox disabled:opacity-70"
                    {...register("policiesAndTermsAgreed", {
                      required: true,
                    })}
                  />
                  <label
                    htmlFor="policiesAndTermsAgreed"
                    className="text-sm label"
                  >
                    {lang.byClickingThisYouAgreeToOur + " "}
                    <a
                      href="https://sircular.io/privacy-policy"
                      target="_blank"
                      className="cursor-pointer text-aqua-400 hover:text-aqua-600"
                      rel="noreferrer"
                    >
                      {lang.privacyPolicy}
                    </a>{" "}
                    {lang.and}{" "}
                    <a
                      href="https://sircular.io/terms-of-service"
                      target="_blank"
                      className="cursor-pointer text-aqua-400 hover:text-aqua-600"
                      rel="noreferrer"
                    >
                      {lang.termsAndConditions}
                    </a>
                    .
                  </label>
                </div>
                {errors.policiesAndTermsAgreed && (
                  <p className="text-sm text-fire-400">{lang.mandatory}</p>
                )}
              </>
            )}

            <div className="w-full mt-3 text-right sm:col-span-2">
              <Button
                size="large"
                color="primary"
                loading={loading}
                disabled={user?.limits?.contact?.remaining === 0}
              >
                {lang.send}
              </Button>
            </div>
          </form>
        </ModalContent>
      ) : (
        <SuccessContent>
          <img
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-3.png"
            alt="icon"
          />
          <h2>{lang.wooLetsGrowTogether}</h2>
          <p className="text-sm capitalize">{lang.getYourSelfOnboardNow}</p>
          <Button
            size="large"
            color="primary"
            onClick={() => navigate("/onboard")}
          >
            {lang.signUp}
          </Button>
        </SuccessContent>
      )}
    </Modal>
  );
};
export default ContactModal;
