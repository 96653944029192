import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import { FC, Fragment, useState } from "react";
import { ReactComponent as DealFlowEmptyImage } from "images/dealflow_empty.svg";
import { useNavigate } from "react-router-dom";
import { DealFlowEmptyWarningProps } from "../../interfaces";

const DealFlowEmptyWarning: FC<DealFlowEmptyWarningProps> = ({}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 md:z-10"
        static
        onClose={() => setIsOpen(false)}
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative w-screen pointer-events-auto md:max-w-xl">
                <div className="flex flex-col items-center justify-center h-full py-6 overflow-y-scroll bg-white shadow-xl md:pt-20">
                  <div className="text-center w-[90%]">
                    <DealFlowEmptyImage className="mx-auto" />
                    <h2 className="mb-2 text-3xl font-bold">
                      Your dealflow is empty
                    </h2>
                    <p className="mb-4">
                      You need to add companies to your Dealflow Management
                      first before you create a board.
                    </p>
                    <Button
                      color="primary"
                      onClick={() => navigate("/dealflow")}
                    >
                      <span>Go to my dealflow</span>
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DealFlowEmptyWarning;
