// @ts-nocheck
import "react-image-crop/dist/ReactCrop.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Button from "atoms/Button/Button";
import Modal from "atoms/Modal/Modal";
import ReactCrop from "react-image-crop";
import { useLang } from "core/lang/LangaugeSetting";

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

const enableBody = () => {
  const target = document.body;
  enableBodyScroll(target);
};
const disableBody = () => {
  const target = document.body;
  disableBodyScroll(target);
};

const getResizedCanvas = (canvas, newWidth, newHeight) => {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
};

const generateFile = async (previewCanvas, crop) => {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  return await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/png",
      1
    );
  });
};

export default ({ file, onClose, onSave }) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    height: 30,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const { lang } = useLang();

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    disableBody();

    return () => {
      enableBody();
    };
  }, []);

  const closeModal = () => {
    enableBody();
    onClose();
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  return (
    <Modal width="48rem" onClose={closeModal} className="text-center">
      <ReactCrop
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <img ref={imgRef} src={upImg} />
      </ReactCrop>
      <div>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            display: "none",
            objectFit: "contain",
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>

      <div className="flex justify-end gap-4">
        <Button className="text-gray-500" color="white" onClick={closeModal}>
          {lang.cancel}
        </Button>
        <Button
          color="primary"
          disabled={!completedCrop}
          onClick={async (e) => {
            e.preventDefault();
            const res = await generateFile(
              previewCanvasRef.current,
              completedCrop
            );
            onSave(res);
            onClose();
          }}
        >
          {lang.ok}
        </Button>
      </div>
    </Modal>
  );
};
