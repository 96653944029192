// @ts-nocheck
import React, { useRef, useEffect } from "react";

function useOutsideAlerter(ref, onClick) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
}

export default (props) => {
  const { onClick, children, ...rest } = props;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClick);

  return (
    <div ref={wrapperRef} {...rest}>
      {children}
    </div>
  );
};
