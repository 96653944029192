import { getUser } from "models/user/selectors";
import { getStore } from "./store";

export const getRedirectNavRoute = () => {
  const userData = getUser(getStore().getState());

  if (!userData) return "/login";

  if (userData.isOnboarding) {
    return "/onboard";
  }

  if (userData.type === "company") {
    return "/company-profile";
  }

  if (userData.type === "investor") {
    return "/aia";
  }

  return "/not-found";
};
