import { FC } from "react";
import { useActivationContext } from "../context";
import AnalyticsActivation from "./AnalyticsActivation";

const AnalyticsActivationWrapper: FC = () => {
  const {
    activationStatus,
    setActivationStatus,
    setIsMinimized,
    setOpenSection,
    isFromOnboarding,
    isReady,
  } = useActivationContext();

  if (!isReady) return null;
  if (!isFromOnboarding) return null;
  if (activationStatus?.BoardsOrAnalytics) return null;

  return (
    <AnalyticsActivation
      activationStatus={activationStatus}
      setActivationStatus={setActivationStatus}
      setIsMinimized={setIsMinimized}
      setOpenSection={setOpenSection}
    />
  );
};

export default AnalyticsActivationWrapper;
