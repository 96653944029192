export const API_STATUS = {
  PENDING: "pending",
  FAILED: "failed",
  SUCCESS: "success",
};

export const LOGOUT_COMPONENT = "LOGOUT_COMPONENT";

export const LOGIN_PAGE = "LOGIN_PAGE";

export const LOGIN_PAGE_BUTTON = "LOGIN_PAGE_BUTTON";

export const REGISTER_PAGE_BUTTON = "REGISTER_PAGE_BUTTON";
