import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { GrowthRate as RevenueGrowthRateType } from "gql/graphql";
import { GROWTHPERRATEPERIOD, PERRATEPERIOD } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";

interface Props {
  revenueGrowthRate: Array<RevenueGrowthRateType | null> | null | undefined;
  note: string | null | undefined;
}

const RevenueGrowthRate: FC<Props> = ({ revenueGrowthRate, note }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const growthRateData = revenueGrowthRate?.[0];

  const renderContent = () => {
    return (
      <div>
        {growthRateData?.rate && growthRateData.period ? (
          <div>
            {growthRateData.rate}% {GROWTHPERRATEPERIOD[growthRateData.period]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, dropdownValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          revenueRate: {
            rate: inputValue,
            period: dropdownValue,
            // dummy values for 2 fields required by api but ignored in FE
            amount: "5",
            time: "MONTHS",
          },
          revenueRateNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={growthRateData?.rate}
        dropdownDefaultValue={growthRateData?.period}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(GROWTHPERRATEPERIOD).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="Revenue Growth Rate"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={growthRateData?.rate ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default RevenueGrowthRate;
