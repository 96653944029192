import { FC } from "react";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { FieldName } from "gql/graphql";
import { Value, ValueProps } from "./Value";

interface Props {
  founded?: string;
  uiRepresentation: string | null | undefined;
}

const EditableValue = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoFounded
);

const Founded: FC<Props> = ({ founded, uiRepresentation }) => {
  const getContent = () => {
    if (uiRepresentation) return uiRepresentation;
    if (founded) return founded;
    return "";
  };

  return (
    <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
      <h6 className="font-medium">Founded</h6>
      <EditableValue content={getContent()} />
    </div>
  );
};

export default Founded;
