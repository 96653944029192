import { FC, useState } from "react";
import type { FloatPanelProps } from "./interfaces";
import { useMutation, useQuery } from "@apollo/client";
import Button from "atoms/Button/Button";
import SingleSelect from "molecules/Form/SingleSelect";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { pluralize } from "utils/pluralize";
import {
  CHANGE_RECORD_STATUS,
  PUSH_TO_BOARD,
  REMOVE_DEALFLOW_RECORDS,
} from "queries/investor/investorDealflow";
import { GET_STARTUP_BOARDS } from "queries/investor/boards";

const MAX_SHORT_LIST = 10;

const FloatPanel: FC<FloatPanelProps> = ({
  records,
  visible,
  sections,
  refetch,
}) => {
  const { data: boardsData } = useQuery(GET_STARTUP_BOARDS, {
    fetchPolicy: "network-only",
  });
  const [pushToBoard] = useMutation(PUSH_TO_BOARD);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const [removeRecords] = useMutation(REMOVE_DEALFLOW_RECORDS);
  const [boardName, setBoardName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const dispatch = useDispatch();

  if (!visible) {
    return null;
  }

  const text =
    records.length <= MAX_SHORT_LIST
      ? records.map((record) => record.company.displayName).join(", ")
      : records.length + " companies selected";
  const boards = boardsData?.getStartupBoards || [];

  const raiseErrorSnack = () =>
    dispatch(
      triggerSnack({
        type: "error",
        title: "Error occurred",
        message: "Please try this operation later",
      })
    );

  const raiseSuccessSnack = (title: string, message: string) =>
    dispatch(
      triggerSnack({
        type: "success",
        title,
        message,
      })
    );

  const handleAddToBoard = async () => {
    const board = boards.find((board) => board?.title === boardName);

    if (!board) {
      return;
    }

    try {
      await pushToBoard({
        variables: {
          id: board.id,
          startups: records.map((record) => record.company.id),
        },
      });

      raiseSuccessSnack(
        "Board updated",
        "Companies have been successfully added"
      );
      setBoardName("");
    } catch (error: any) {
      raiseErrorSnack();
    }
  };

  const handleChangeStatus = async () => {
    const section = sections.find((section) => section.title === sectionName);

    if (!section) {
      return;
    }

    try {
      await changeRecordStatus({
        variables: {
          records: records.map((record) => record.id),
          status: section.id,
        },
      });

      raiseSuccessSnack(
        "Statuses updated",
        "Statuses have been successfully updated"
      );
      setSectionName("");
      refetch();
    } catch (error: any) {
      raiseErrorSnack();
    }
  };

  const handleRemoveRecords = async () => {
    try {
      await removeRecords({
        variables: { records: records.map((record) => record.id) },
      });
      raiseSuccessSnack(
        "Dealflow updated",
        "Companies have been removed from your dealflow"
      );
      refetch();
    } catch (error: any) {
      raiseErrorSnack();
    }
  };

  return (
    <div className="fixed z-[50] p-2 ml-3 text-sm bg-white border border-gray-300 rounded-md shadow-lg bottom-4 w-96">
      <p className="mb-4">
        <span>Chosen: {text}</span>
      </p>
      <div>
        <div className="flex items-center justify-between">
          <SingleSelect
            label="Choose the board"
            direction="top"
            options={boards.map((board) => board?.title)}
            onChange={setBoardName}
          />
          <Button onClick={handleAddToBoard} size="small" color="primary">
            Add to board
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-between pt-2 mt-2 border-t border-gray-300">
            <SingleSelect
              label="Choose the status"
              direction="top"
              options={sections.map((section) => section.title)}
              onChange={setSectionName}
            />
            <Button onClick={handleChangeStatus} size="small" color="primary">
              Set status
            </Button>
          </div>
          {sectionName && records.length > 1 && (
            <p className="mt-2 text-xs text-yellow-600">
              You are about to set status "{sectionName}" for all{" "}
              {records.length} companies
            </p>
          )}
        </div>
        <div className="flex items-center justify-between pt-2 mt-2 border-t border-gray-300">
          <span className="text-red-900">
            Remove {pluralize("record", records.length)} from my dealflow
          </span>
          <Button onClick={handleRemoveRecords} size="small" color="warning">
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FloatPanel;
