// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const loginUser = createAction("model/login/user", (payload) => ({
  payload,
}));

export const logoutUser = createAction("model/logout/user", (payload) => ({
  payload,
}));

export const triggerGoogleSignIn = createAction(
  "model/signIn/google",
  (payload) => ({
    payload,
  })
);

export const triggerGoogleSignUp = createAction(
  "model/signUp/google",
  (payload) => ({
    payload,
  })
);
