import { useMemo, useEffect, FC } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { getUser, getIsApplicationSent } from "models/user/selectors";
import MenuDrawer from "organisms/NavigationLayout/MenuDrawer";
import SideNavigationMobile from "organisms/NavigationLayout/SideNavigationMobile";
import TopNavBar from "atoms/TopNavBar/TopNavBar";
import { mobileSideNav, desktopSideNav } from "./navigationConfig";
import { UserRole } from "./interfaces";
import { IsMenuDrawerOpen } from "./selectors";
import { triggerMenuDrawer } from "./sagaActions";
import { getGlobalState } from "models/globalState/selectors";
import { setGlobalState } from "models/globalState/sagaActions";
import ContactSupport from "organisms/ContactSupport/ContactSupport";
import SideNavigationDesktop from "./SideNavigationDesktop";
import { getUserRole } from "./utils";

interface Props {
  component: FC;
}

export default function NavigationLayout({ component }: Props) {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector(getUser);
  const isApplicationSent = useSelector(getIsApplicationSent);
  const isSideNavShown = useSelector((state) => IsMenuDrawerOpen(state));
  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const userRole = getUserRole(user);

  const ComponentTemp = useMemo(() => component, [params, location, navigate]);

  const enableBody = (targetId: string) => {
    const target = document.querySelector(targetId);
    enableBodyScroll(target);
  };

  const disableBody = (targetId: string) => {
    const target = document.querySelector(targetId);
    disableBodyScroll(target);
  };

  const toggleMenuDrawer = () => {
    dispatch(triggerMenuDrawer({ isEnabled: !isSideNavShown }));
    isSideNavShown ? enableBody("#root") : disableBody("#root");
  };

  const handleNavItemClicked = () => {
    toggleMenuDrawer();
  };

  useEffect(() => {
    if (userRole == UserRole.STARTUP) {
      dispatch(
        setGlobalState({
          isNavigationEnabled: isApplicationSent,
        })
      );
    } else {
      dispatch(
        setGlobalState({
          isNavigationEnabled: true,
        })
      );
    }
  }, [userRole, isApplicationSent]);

  return (
    <div className="w-full min-h-full h-full">
      {/* on Mobile */}
      <div className="relative w-full h-full flex md:hidden flex-col bg-white dark:bg-[#141415] mx-auto">
        <TopNavBar toggleMenuDrawer={toggleMenuDrawer} />
        {userRole && isSideNavShown && (
          <MenuDrawer
            isSideNavShown={isSideNavShown}
            toggleMenuDrawer={toggleMenuDrawer}
          >
            <SideNavigationMobile
              config={mobileSideNav}
              userRole={userRole}
              isNavigationEnabled={isNavigationEnabled}
              onNavItemClicked={handleNavItemClicked}
              user={user}
            />
          </MenuDrawer>
        )}
        <ComponentTemp {...({ params, location, navigate } as any)} />
      </div>

      {/* On desktop */}
      <div className="relative w-full h-full hidden md:flex flex-row bg-white dark:bg-[#141415] mx-auto">
        {userRole && (
          <SideNavigationDesktop
            config={desktopSideNav}
            userRole={userRole}
            user={user}
          />
        )}
        <div className="w-full h-full overflow-y-scroll">
          <ComponentTemp {...({ params, location, navigate } as any)} />
        </div>
      </div>
      <ContactSupport />
    </div>
  );
}
