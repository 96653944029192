// @ts-nocheck
import { initialState } from "./reducer";
import { MAJORSTEPS } from "core/consts";

const companyProfile = (state) => state.companyProfile || initialState;

export const getCompanyDetails = (state) => {
  let data = _.cloneDeep(companyProfile(state).companyDetails);
  if (data?.companyBySlug?.businessModel?.includes("OTHERS")) {
    for (let i in data?.companyBySlug?.businessModel) {
      if (data?.companyBySlug?.businessModel[i] === "OTHERS") {
        data?.companyBySlug?.businessModel.splice(i, 1);
        data?.companyBySlug?.businessModel.push("OTHERS");
      }
    }
  }
  return data;
};

export const getCompanyAnalysis = (state) => state.analysis;

export const getCurrencyDetails = (state) =>
  companyProfile(state).currencyDetails || {};

export const getTractionDetails = (state) =>
  companyProfile(state).tractionDetails;

export const getAnalysisDetails = (state) =>
  companyProfile(state).analysisDetails;

export const getCompetitorsDetails = (state) =>
  companyProfile(state).competitorDetails;

//TODO We have the same selector in user module as well. but I had to implement here as well since
// we need isApplicationSent flag in public company profile too which is a unauthorized page and user is null
// completedMajorSteps is available in me query and companyBySlug query. investigate a better solution.
// may be a separate query to get this flag ?
export const getIsApplicationSent = (state) =>
  companyProfile(
    state
  ).companyDetails?.companyBySlug?.completedMajorSteps?.includes(
    MAJORSTEPS.APPLICATION_SENT
  ) || false;

//used by traction step
export const getCompanyStage = (state) =>
  companyProfile(state).companyDetails?.companyBySlug?.companyStage || null;

//used by traction step
export const getCompanyCurrency = (state) =>
  companyProfile(state).companyDetails?.companyBySlug?.currency || null;

export const getIsPublicProfile = (state) =>
  state.companyProfile?.isPublicProfile;
