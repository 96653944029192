// @ts-nocheck
import { initialState, name } from "./reducer";

const auth = (state) => state[name] || initialState;

export const getGlobalState = (state, key) => {
  if (key) {
    return auth(state)?.[key];
  }
  return auth(state);
};
