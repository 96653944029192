// @ts-nocheck
import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const MarketplaceVisibility = styled(motion.div)(
  ({ background, premiumScreen }) => [
    premiumScreen &&
      css`
        .modal-container {
          width: 75% !important;
          .modal-body {
            padding: 0 !important;
          }
          ${media.lessThan("medium")`
            width: calc(100% - 15px) !important;
        `}
        }
      `,
    css`
      align-items: center;
      justify-content: center;
      display: flex;
      height: 100vh;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      padding: 15px;
      background-color: #e7f8f7;
      background-image: url(${background});
      z-index: 10000;
      background-size: cover;
      .modal-container {
        width: 475px;
        .modal-body {
          background: #fff;
          border-radius: 7px;
          padding: 25px;
        }
      }
    `,
  ]
);

export const LeftContent = styled(motion.div)(() => [
  tw`p-7`,
  css`
    width: 55%;
    ${media.lessThan("medium")`
            width: 100%;
        `}
  `,
]);

export const RightContent = styled(motion.div)(({ background, height }) => [
  tw`flex items-center justify-center`,
  css`
    width: 45%;
    height: ${height};
    background: url(${background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    ${media.lessThan("medium")`
           display: none;
        `}
  `,
]);
