import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetails } from "./selectors";
import { PageHeader, StepActionButton } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import {
  addProductImage,
  removeProductImage,
  saveProductDetails,
} from "./sagaActions";
import ImageSelect from "organisms/ImageSelect/ImageSelect";
import FloatingLabelInput from "atoms/Form/FloatingLabelInput";
import { YOUTUBE_VIDEO_PATTERN, VIMEO_PATTERN } from "core/consts";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { ReactComponent as ProductDescriptionIcon } from "images/product-description-icon.svg";
import ImagePreview from "./ImagePreview";
import { ExistingImage } from "./interfaces";
import { useEffect } from "react";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import cn from "classnames";

type FormValues = {
  description: string;
  productImages: ExistingImage[];
  productDemo: string;
};

const NUM_OF_IMAGES_ALLOWED = 5;

export default () => {
  const data = useSelector(getProductDetails);
  const loading = useSelector((state) => isLoadingEnabled(state, STEP.PRODUCT));
  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const { lang } = useLang();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: data });

  // repopulate form default values with fresh data once product image is added or removed
  useEffect(() => {
    reset({ productImages: data.productImages });
  }, [data.productImages.length]);

  const productImages = watch("productImages");

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(
      saveProductDetails({
        lang,
        input: data,
      })
    );
  };

  const handleAddImage = (file: Blob) => {
    dispatch(
      addProductImage({
        lang,
        file,
      })
    );
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...productImages];
    newImages.splice(index, 1);
    dispatch(
      removeProductImage({
        lang,
        productImages: newImages,
      })
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <ProductDescriptionIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.productDescription}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>
              {lang.productDescriptionInstruction} <br />
              {lang.productDescriptionInfo1} <br />
              {lang.productDescriptionInfo2} <br />
              {lang.productDescriptionInfo3}
            </p>
          </div>
        </div>
      </PageHeader>
      <div>
        <div className="mb-2 text-sm font-medium">{`${lang.product} ${lang.details}`}</div>
        <TextArea
          rows="3"
          id="description"
          className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm height-set ${
            errors.description
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
              : ""
          }`}
          placeholder={lang.addYourProductSummaryHere}
          defaultValue={data?.description}
          {...register("description")}
          maxLength={1000}
        ></TextArea>
        <div className="mb-2 text-sm font-medium">{`Product Images  (${productImages.length}/${NUM_OF_IMAGES_ALLOWED})`}</div>
        <div className="flex flex-row my-5">
          {productImages?.map((image, index) => (
            <ImagePreview
              key={image.large.filename}
              image={image}
              index={index}
              onRemove={() => handleRemoveImage(index)}
            />
          ))}
        </div>
        {productImages.length <= NUM_OF_IMAGES_ALLOWED - 1 && (
          <Controller
            control={control}
            name="productImages"
            render={({ field: { value, onChange } }) => (
              <ImageSelect
                minWidth={50}
                minHeight={50}
                onChange={(image: Blob) => {
                  handleAddImage(image);
                }}
                small
                onRemove={() => {}}
                onError={(message: string) =>
                  dispatch(
                    triggerSnack({
                      type: "error",
                      title: lang.somethingWentWrong,
                      message,
                    })
                  )
                }
                imageDesc="Drop product images here or"
              />
            )}
          />
        )}

        <div className="mt-8 -mb-2 text-sm font-medium">Product demo</div>
        {/* @ts-ignore */}
        <FloatingLabelInput
          name="productDemo"
          type="text"
          defaultValue={data?.productDemo}
          reference={{
            ...register("productDemo", {
              required: false,
              validate: {
                urlPattern: (value) =>
                  !value ||
                  YOUTUBE_VIDEO_PATTERN.test(value) ||
                  VIMEO_PATTERN.test(value),
              },
            }),
          }}
          errors={
            errors.productDemo?.type === "urlPattern" &&
            "Please make sure to upload a YouTube or a Vimeo link"
          }
          className="normal-case"
        >
          Paste a YouTube or Vimeo link here
        </FloatingLabelInput>
      </div>
      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
