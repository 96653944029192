import { FC } from "react";
import { InvestmentSummaryMobileProps } from "./interfaces";
import {
  BUSINESS_MODEL,
  CURRENT_COMPANY_STAGE,
  BUSINESS_CATEGORY,
  TEAM_SIZE,
  FUNDRAISING_STAGES,
} from "core/consts";

const renderBusinessItem = (
  itemKey: string,
  value: string,
  greenText?: boolean
) => (
  <div className="text-left dark:bg-neutral-800">
    <p className="text-xs font-normal">{itemKey}</p>
    <p
      className={`-mr-3 text-base font-bold col-span-3 ${
        greenText ? "text-aqua-500" : "text-[#000]"
      } dark:text-white/80`}
    >
      {value}
    </p>
  </div>
);

const InvestmentSummaryMobile: FC<InvestmentSummaryMobileProps> = ({
  businessCategory,
  businessTypeNote,
  businessModel,
  businessCategoryNote,
  founded,
  companyStage,
  teamSize,
}) => (
  <div className="container w-full grid grid-cols-2 mx-auto px-6 dark:text-white/80 mb-4 gap-2 md:hidden">
    {businessCategory &&
      businessCategory?.length > 0 &&
      renderBusinessItem(
        "Product",
        Object.values(businessCategory)
          .map((key) => {
            if (key === "OTHER") {
              return businessTypeNote;
            } else {
              return `${
                BUSINESS_CATEGORY[key as keyof typeof BUSINESS_CATEGORY]
              }`;
            }
          })
          .join(", ")
      )}

    {companyStage &&
      companyStage.length > 0 &&
      renderBusinessItem(
        "Stage",
        CURRENT_COMPANY_STAGE[
          companyStage as keyof typeof CURRENT_COMPANY_STAGE
        ]
      )}
    {businessModel &&
      businessModel.length > 0 &&
      renderBusinessItem(
        "Business",
        Object.values(businessModel)
          .map((key) => {
            if (key === "OTHERS") {
              return businessCategoryNote;
            } else {
              return `${BUSINESS_MODEL[key as keyof typeof BUSINESS_MODEL]}`;
            }
          })
          .join(", ")
      )}

    {founded && renderBusinessItem("Founded", founded)}
    {teamSize && renderBusinessItem("Team size", (TEAM_SIZE as any)[teamSize])}
  </div>
);

export default InvestmentSummaryMobile;
