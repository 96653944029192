import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { AiaAnalysisExecutiveSummary } from "gql/graphql";
import ElevatorPitch from "./ElevatorPitch";
import Traction from "./Traction";
import Problem from "./Problem";
import MarketOpportunity from "./MarketOpportunity";
import Product from "./Product";
import BusinessModel from "./BusinessModel";
import Team from "./Team";
import GoToMarketStrategy from "./GoToMarketStrategy";

interface Props {
  isTractionSummaryLoading?: boolean | null;
  tractionSummary?: string;
  isExecutiveSummaryLoading?: boolean | null;
  executiveSummary?: AiaAnalysisExecutiveSummary;
  elevatorPitch?: string;
  goToMarketStrategy?: string;
}

const ExecutiveSummary: FC<Props> = ({
  isTractionSummaryLoading,
  tractionSummary,
  isExecutiveSummaryLoading,
  executiveSummary,
  elevatorPitch,
}) => {
  return (
    <div className="p-4 border border-gray-400 rounded-md aiaProfile">
      <div className="w-full grid grid-col-1 sm:grid-cols-2 gap-4">
        {isTractionSummaryLoading ? (
          <>
            <Skeleton count={1.3} />
            <Skeleton count={1.3} />
          </>
        ) : (
          <>
            {elevatorPitch && <ElevatorPitch content={elevatorPitch} />}
            {tractionSummary && <Traction content={tractionSummary} />}
          </>
        )}

        {isExecutiveSummaryLoading ? (
          <>
            <Skeleton count={2.7} />
            <Skeleton count={2.7} />
          </>
        ) : (
          <>
            {executiveSummary?.problem && (
              <Problem content={executiveSummary.problem} />
            )}
            {executiveSummary?.marketOpp && (
              <MarketOpportunity content={executiveSummary.marketOpp} />
            )}
            {executiveSummary?.product && (
              <Product content={executiveSummary.product} />
            )}
            {executiveSummary?.businessModel && (
              <BusinessModel content={executiveSummary.businessModel} />
            )}
            {executiveSummary?.team && <Team content={executiveSummary.team} />}
            {executiveSummary?.goToMarket && (
              <GoToMarketStrategy content={executiveSummary.goToMarket} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ExecutiveSummary;
