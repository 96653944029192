import type { FC } from "react";
import type { HeaderProps } from "./interfaces";
import DefaultImage from "images/dealroom-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SingleSelect } from "molecules/SingleSelect";
import { useMutation } from "@apollo/client";
import { CHANGE_RECORD_STATUS } from "queries/investor/investorDealflow";
import { DealflowSection } from "pages/dealflow/InvestorDealflow/interfaces";
import { profileUrl } from "pages/dealflow/InvestorDealflow/utils";

const Header: FC<HeaderProps> = ({
  isStatusHidden,
  sections,
  record,
  onClose,
  refetch,
}) => {
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const getSection = (id: string) =>
    sections.find((section) => section.id === id) ?? null;

  const getHandleStatusChange =
    (id: string) => async (status: DealflowSection) => {
      await changeRecordStatus({
        variables: {
          record: id,
          status: status.id,
        },
      });
      refetch();
    };

  const onLinkClicked = (e: any) => {
    e.stopPropagation();
    profileUrl(
      record?.company?.slug,
      record?.company?.id,
      record?.company?.isAiaGenerated
    );
  };

  return (
    <div className="flex h-24 p-4 bg-gradient-to-r from-aqua-600 to-aqua-400 w-100 rounded-t-md">
      <div className="flex">
        <span
          onClick={onLinkClicked}
          className="inline-block border-4 border-white rounded-full w-14 h-14"
        >
          <img
            className="rounded-full object-fit"
            src={record.company?.logo?.small?.location || DefaultImage}
            alt=""
          />
        </span>
        <div className="ml-2">
          <span
            onClick={onLinkClicked}
            className="inline-block text-white truncate max-w-[18rem]"
          >
            {record.company.displayName}
          </span>
          {!isStatusHidden && (
            <div className="w-40 text-sm status-select">
              <SingleSelect
                value={getSection(record.status.id)}
                choices={sections}
                onChange={getHandleStatusChange(record.id)}
                labelClassName="bg-white"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end w-full">
        <span className="text-xl text-white cursor-pointer" onClick={onClose}>
          <FontAwesomeIcon icon={["fal", "times"]} />
        </span>
      </div>
    </div>
  );
};

export default Header;
