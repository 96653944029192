// @ts-nocheck
import { put, select, takeEvery } from "redux-saga/effects";
import { MAJORSTEPS } from "core/consts";
import { getClient } from "core/utility";
import { handleFetchUser } from "models/user/sagas";
import { triggerSnack } from "organisms/Snack/sagaActions";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { getCompletedMajorSteps } from "./selectors";
import {
  GET_COMPLETED_STEPS,
  SAVE_WELCOME_PAGE_STATUS,
  SUBMIT_APPLICATION,
} from "queries/general/settings";

const client = getClient();

function* getPageStatus() {
  const { data, isError } = yield client
    .query({
      query: GET_COMPLETED_STEPS,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  if (isError) {
    return isError;
  }

  yield put(
    slice.updateStepsInfo({
      completedMajorSteps: data?.me?.completedMajorSteps,
      completedSteps: data?.me?.completedSteps,
    })
  );
}

function* saveWelcomePageStatus(variables) {
  yield client.mutate({
    mutation: SAVE_WELCOME_PAGE_STATUS,
    variables,
  });
}

function* initializeCompanySettings({ payload: { lang } }) {
  const isError = yield getPageStatus();
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: "Unable to load comany status data.",
      })
    );
    return;
  }

  const completedMajorSteps = yield select(getCompletedMajorSteps);

  if (!completedMajorSteps?.includes(MAJORSTEPS.FIRST_LOGIN_COMPLETED)) {
    yield saveWelcomePageStatus({ step: MAJORSTEPS.FIRST_LOGIN_COMPLETED });
    yield getPageStatus();
    yield handleFetchUser({ fetchPolicy: "cache-first", lang });
  }
}

function* submitCompanyApplication({ payload: { lang } }) {
  const { isError } = yield client
    .mutate({
      mutation: SUBMIT_APPLICATION,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    return;
  }

  yield put(slice.updateApplicationSubmitSuccess({ isShown: true }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(
      sagaActions.initializeCompanySettings,
      initializeCompanySettings
    ),
    yield takeEvery(
      sagaActions.submitCompanyApplication,
      submitCompanyApplication
    ),
  ];
}
