import { INDUSTRY_CATEGORIES_SWEDISH } from "core/consts";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getCurrencyList } from "models/user/selectors";
import ReactCountryFlag from "react-country-flag";
import { getCountryCode } from "utils/generalUtils";
import PureHtml from "atoms/PureHtml";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface CompanyBoardCards {
  _id: string;
  displayName: string;
  logo: string;
  pitch: string;
  industries: any[];
  founded: string;
  companyStage: string;
  valuation: string;
  investors: string;
  raisedTarget: string;
  raisedValue: string;
  currencyList: string;
  currency: string;
  country: string;
  city: string;
  keyFigures: any[];
  businessModel: any[];
  traction: string;
  founders: {
    name: string;
    firstName: string;
    lastName: string;
    linkedin: string;
    title: string;
    displayName: string;
    image: {
      small: {
        location: string;
      };
    };
  }[];
}

const CompanyCardComponent: FC<CompanyBoardCards> = ({
  displayName,
  logo,
  country,
  city,
  founders,
  pitch,
  industries,
  traction,
  valuation,
  raisedTarget,
  raisedValue,
  currency,
}) => {
  const currencies = useSelector(getCurrencyList);

  const currencyCode =
    currencies.find((item: any) => item._id === currency) || currencies[0];

  const foundersWithName = founders?.filter((founder) => founder?.name);
  const hasFoundersWithName = foundersWithName?.length > 0;

  return (
    <div className="relative w-[24rem] h-[22rem] mt-4 rounded-md px-4 py-4 shadow-lg dark:bg-[#3F3E3E] dark:border-2 dark:border-[#5F5F5F] md:hover:scale-[1.01] md:hover:shadow-xl md:hover:shadow-[#3F3E3E] md:hover:-translate-y-1">
      <div className="flex items-center pb-2 lg:flex-row">
        <div className="w-[3rem] h-[3rem]">
          <img
            className="h-[3rem] w-[3rem] mx-auto rounded-full border border-white md:border-none object-cover md:outline md:outline-white/50 md:outline-[2px] md:outline-offset-[-1px]"
            src={
              logo ||
              `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png`
            }
          />
        </div>
        <div className="flex flex-col ml-4">
          <span className="text-base font-bold text-left">{displayName}</span>
          <div className="flex">
            {industries?.slice(0, 1).map((industry, index) => (
              <span
                className="bg-[#E4E7EB] text-black border px-2 py-0.5 rounded-full whitespace-nowrap text-[10px]"
                key={index}
              >
                {(INDUSTRY_CATEGORIES_SWEDISH as any)[industry]}
              </span>
            ))}
          </div>
        </div>
      </div>
      {country && (
        <div className="flex flex-row py-2">
          <ReactCountryFlag
            countryCode={getCountryCode(country)}
            svg={true}
            className="mb-0.5 mr-2"
          />
          <span className="text-sm font-[450] text-[#535353] dark:text-[#C4C4C4]">
            {city}
            {city && ","} {country}
          </span>
        </div>
      )}
      {pitch && (
        <PureHtml
          content={pitch}
          tag="p"
          className="text-[#787486] text-sm text-left line-clamp-2 my-2 dark:text-[#C4C4C4]"
        />
      )}
      {traction && (
        <div className="flex flex-row py-2">
          <p className="text-sm font-bold dark:text-white">Traction:</p>
          <p className="text-sm font-normal text-ellipsis line-clamp-1 dark:text-[#C4C4C4]">
            &nbsp;{traction}
          </p>
        </div>
      )}
      <div className="flex flex-row gap-6 py-2">
        {valuation && (
          <div className="flex flex-col text-center">
            <span className="text-sm">
              {formatCurrencyValues(parseInt(valuation), currencyCode.code)}
            </span>
            <span className="text-[#737A87] text-xs dark:text-[#C4C4C4]">
              Valutation
            </span>
          </div>
        )}
        {raisedTarget && (
          <div className="flex flex-col text-center">
            <span className="text-sm">
              {formatCurrencyValues(parseInt(raisedTarget), currencyCode.code)}
            </span>
            <span className="text-[#737A87] text-xs dark:text-[#C4C4C4]">
              Target
            </span>
          </div>
        )}
        {raisedValue && (
          <div className="flex flex-col text-center">
            <span className="text-sm">
              {formatCurrencyValues(parseInt(raisedValue), currencyCode.code)}
            </span>
            <span className="text-[#737A87] text-xs dark:text-[#C4C4C4]">
              Raised
            </span>
          </div>
        )}
      </div>
      <ul>
        {hasFoundersWithName && (
          <>
            <h6 className="text-sm font-bold text-left text-black/60 dark:text-white">
              Founders
            </h6>
            <li className="flex py-2">
              {foundersWithName?.slice(0, 2).map((founder, index) => (
                <div
                  className="flex items-center p-1 mr-2 bg-white dark:bg-zinc-900 border rounded-lg w-[9rem] overflow-hidden"
                  key={index}
                >
                  <img
                    src={
                      founder.image?.small?.location ||
                      "https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png"
                    }
                    className="w-8 h-8 ml-1 rounded-full"
                    alt=""
                  />
                  <div className="pl-4 pr-2 text-xs">
                    <div className="text-left text-ellipsis max-w-[4rem] whitespace-nowrap overflow-hidden dark:text-white/90">
                      {founder?.name}
                    </div>
                    <div className="text-left text-ellipsis max-w-[3rem]  whitespace-nowrap overflow-hidden dark:text-white/90">
                      {founder.title}
                    </div>
                  </div>
                </div>
              ))}
            </li>
            {founders.length > 2 && (
              <p className="text-[#312C2C] font-normal text-xs whitespace-nowrap text-left dark:text-white/90">
                +{founders.length - 2}{" "}
                {founders.length - 2 > 1 ? "founders" : "founder"}
              </p>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default CompanyCardComponent;
