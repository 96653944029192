import { format, formatDistanceToNow } from "date-fns";
import { getAlpha2Code, registerLocale } from "i18n-iso-countries";
import countries from "i18n-iso-countries/langs/en.json";
import { sv, enUS } from "date-fns/locale";

const lang = localStorage.getItem("language");
var convertIn;
switch (lang) {
  case "en":
    convertIn = enUS;
    break;
  case "sv":
    convertIn = sv;
    break;
  default:
    break;
}

// @TODO: review using of this function
// seems it's used in a wrong way through the entire codebase
//
export const debounce = (callback, delay) => {
  let timeout = null;

  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);

    timeout = setTimeout(next, delay);
  };
};

export const asyncDebounce = (callback, delay) => {
  let calling = false;
  let timeout = null;
  return (...args) => {
    const next = async () => {
      if (calling) {
        return;
      }

      calling = true;
      const res = await callback(...args);
      calling = false;
      return res;
    };
    clearTimeout(timeout);
    //1.
    timeout = setTimeout(next, delay);
  };
};

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export function camelize(str) {
  if (!str) {
    return;
  }

  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase());
}

export const getFormattedDate = (date) => {
  if (!date) {
    return "~";
  }

  return format(new Date(date), "d MMM, u", {
    locale: convertIn,
  });
};

export const getFormattedDateWithMonthHour = (date) => {
  if (!date) {
    return;
  }

  return format(new Date(date), "d MMMM, u H:mm", {
    locale: convertIn,
  });
};

export const getTimeSince = (date) => {
  if (!date) {
    return "~";
  }

  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    onlyNumeric: true,
    locale: convertIn,
  });
};

export const forceLowerCase = (value) => {
  if (!value) {
    return value;
  }

  return value.toLowerCase();
};

export const prefixHttps = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
};

export const getCountryCode = (country) => {
  registerLocale(countries);
  const countryCode = getAlpha2Code(country, "en");
  return countryCode;
};
