export enum MemberStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

const Permissions = {
  SUBSCRIPTION: "SUBSCRIPTION",
  INVITE_MEMBER: "INVITE_MEMBER",
} as const;

export type Permission = typeof Permissions[keyof typeof Permissions];

export type RoleID = "ADMIN" | "COLLABORATOR";
export interface Role {
  id: RoleID;
  name: string;
  permissions: Permission[];
}

export const Roles = {
  Admin: {
    id: "ADMIN" as RoleID,
    name: "Admin",
    permissions: [Permissions.INVITE_MEMBER, Permissions.SUBSCRIPTION],
  },
  Collaborator: {
    id: "COLLABORATOR" as RoleID,
    name: "Collaborator",
    permissions: [],
  },
};

interface Principal {
  _id: string;
  email: string;
  password: string;
  socketIds: string[];
  updatedAt: Date;
  createdAt: Date;
}

export interface CompanyMember {
  id: string;
  status: MemberStatus;
  permissions: Permission[];
  user: Principal;
  company: any;
  lastActive: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface MemberInvitationInput {
  email: string;
  permissions: Permission[];
}

export interface Workspace {
  id: string;
  permissions: Permission[];
  isActive: boolean;
  inviter: any;
}
