import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as UserIcon } from "images/user-icon.svg";
import { ReactComponent as BuildingIcon } from "images/building-icon.svg";
import { FC, useState } from "react";

type CustomInvestorProps = {
  onCustomInvestorAdded: (name: string, firm: string) => void;
  onCustomInvestorCancel: () => void;
};

const CustomInvestor: FC<CustomInvestorProps> = ({
  onCustomInvestorAdded,
  onCustomInvestorCancel,
}) => {
  const [name, setName] = useState("");
  const [firm, setFirm] = useState("");
  const { lang } = useLang();

  const onAdd = () => {
    onCustomInvestorAdded(name, firm);
    setName("");
    setFirm("");
  };

  const onCancel = () => {
    onCustomInvestorCancel();
    setName("");
    setFirm("");
  };

  return (
    <>
      <div className="flex flex-row justify-around">
        <div className="flex flex-row items-center bg-white p-2 rounded border grow mr-1">
          <UserIcon />
          <input
            type="text"
            value={name}
            placeholder={lang.enterFullName}
            className="placeholder:text-sm placeholder:!text-slate-400 ml-2 grow"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-row items-center bg-white p-2 rounded border grow ml-1">
          <BuildingIcon />
          <input
            type="text"
            value={firm}
            placeholder={lang.enterFirmName}
            className="placeholder:text-sm placeholder:!text-slate-400 ml-2 grow"
            onChange={(e) => setFirm(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-2 text-right __button">
        <button
          type="button"
          className="mr-2 border-none text-sm"
          onClick={onCancel}
        >
          <span>{lang.cancel}</span>
        </button>
        <button
          type="button"
          className="border-none text-sm !text-aqua-400 mr-1"
          onClick={onAdd}
        >
          <span>{lang.add}</span>
        </button>
      </div>
    </>
  );
};

export default CustomInvestor;
