import type { Identification } from "./interfaces";

const HISTORY_STORAGE_KEY = "view_identity";
const REMEMBER_STORAGE_KEY = "view_identity_remember";

const fetchHistory = (): Record<string, any> => {
  try {
    return JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY) || "{}");
  } catch (error: any) {
    return {};
  }
};

const saveHistory = (history: Record<string, any>) => {
  localStorage.setItem(HISTORY_STORAGE_KEY, JSON.stringify(history));
};

const getKey = (type: string, slug: string) => `${type}_${slug}`;

const identityHistory = fetchHistory();

export const isIdentified = (type: string, slug: string) =>
  Boolean(identityHistory[getKey(type, slug)]);

export const identifyFor = (type: string, slug: string) => {
  identityHistory[getKey(type, slug)] = true;
  saveHistory(identityHistory);
};

export const rememberIdentity = (identity: Identification) => {
  localStorage.setItem(REMEMBER_STORAGE_KEY, JSON.stringify(identity));
};

export const getRememberIdentity = (): Identification | null => {
  try {
    const fromStorage = localStorage.getItem(REMEMBER_STORAGE_KEY);

    return fromStorage ? JSON.parse(fromStorage) : null;
  } catch (error: any) {
    return null;
  }
};
