import { REMEMBER_ME_COOKIE_KEY } from "core/consts";
import useCookie from "hooks/useCookie";
import { refetchUserData } from "models/user/sagaActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getEmailFromRegister } from "../utils";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "queries/auth/auth";
import { mixpanelSignIn } from "core/mixpanel/Mixpanel";
import { useParams } from "react-router-dom";

const useNewLoginPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { getCookie, setCookie } = useCookie();
  const [initialEmail] = useState(getEmailFromRegister);
  const { email, active } = getCookie(REMEMBER_ME_COOKIE_KEY);
  const [isPassword, setIsPassword] = useState(true);
  const [emailValue, setEmailValue] = useState(email || initialEmail);
  const [rememberMe, setRememberMe] = useState(active);
  const [password, setPassword] = useState("");

  const [signIn, { loading: isLoginLoading, error }] = useMutation(
    LOGIN_MUTATION,
    {
      onCompleted: (data) => {
        mixpanelSignIn(data?.signInV2);
      },
    }
  );

  const onSubmit = async () => {
    try {
      await signIn({
        variables: {
          email: emailValue,
          password: password,
          referrer: params.id,
        },
      });

      if (rememberMe) {
        setCookie(REMEMBER_ME_COOKIE_KEY, { email, active: true });
      } else {
        setCookie(REMEMBER_ME_COOKIE_KEY, {});
      }

      dispatch(refetchUserData({}));
    } catch (error: any) {
      console.log(error);
    }
  };

  return {
    emailValue,
    password,
    isPassword,
    error,
    rememberMe,
    isLoginLoading,
    setIsPassword,
    setEmailValue,
    setRememberMe,
    setPassword,
    onSubmit,
  };
};

export default useNewLoginPage;
