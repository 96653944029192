import { FC, createRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "../../sagaActions";
import EmbedVideo from "atoms/EmbedVideo";
import EditButton from "../reusables/EditButton";

interface Props {
  pitchVideoLink?: string | null;
}

const PitchVideo: FC<Props> = ({ pitchVideoLink }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = createRef<HTMLInputElement>();
  const dispatch = useDispatch();

  const handleSave = () => {
    const inputValue = inputRef.current?.value;
    dispatch(
      updateStartupProfile({
        pitch: {
          link: inputValue,
        },
      })
    );
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  return (
    <div className="w-full lg:max-w-sm h-56 p-4 mt-4 border border-dashed rounded-lg border-aqua-400">
      {isEditMode ? (
        <div className="flex flex-col">
          <span className="pl-2">Pitch video</span>
          <input
            type="text"
            placeholder="Paste a YouTube or Vimeo link here"
            className="p-2 mt-2 mb-4 text-gray-500 border rounded border-aqua-200 bg-aqua-50 placeholder:!text-gray-400 placeholder:text-sm placeholder:italic"
            defaultValue={pitchVideoLink || ""}
            ref={inputRef}
          />
          <SaveAndCancelButtons
            onSave={handleSave}
            onCancel={handleCancel}
            className="self-end"
          />
        </div>
      ) : (
        <>
          {pitchVideoLink ? (
            <div>
              <div className="flex justify-between px-2">
                <span>Pitch video</span>
                <EditButton onClick={() => setIsEditMode(true)} />
              </div>
              <div className="relative inline-block w-full h-40 mt-2 rounded-m">
                <EmbedVideo className="rounded-md" source={pitchVideoLink} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full rounded bg-aqua-50">
              <FontAwesomeIcon
                icon={["fab", "youtube"]}
                className="mb-4 text-aqua-300"
                size="4x"
              />
              <div
                className="cursor-pointer"
                onClick={() => setIsEditMode(true)}
              >
                <FontAwesomeIcon
                  icon={["fal", "plus-circle"]}
                  className="mr-2 text-aqua-400"
                />
                <span className="text-aqua-400">Add Pitch Video</span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PitchVideo;
