export const CSVToJSON = (text: string) => {
  const lines = text.split("\n");
  const headers = lines[0]
    .split(",")
    .map((header) => header.replace(/\W$/g, ""));
  const records = lines.slice(1).map((line) => {
    const values = line.split(",").map((value) => value.replace(/\W$/g, ""));

    return headers.reduce((obj, header, idx) => {
      obj[header] = values[idx];
      return obj;
    }, {} as Record<string, string>);
  });

  return { headers, records };
};
