import { ModalContentProps } from "./interfaces";
import type { GoogleInputPlace } from "molecules/Form/interfaces";
import { FC } from "react";
import ButtonGroup from "molecules/Form/ButtonGroup";
import {
  BUSINESS_CATEGORY,
  BUSINESS_MODEL,
  CURRENT_COMPANY_STAGE,
  FUNDRAISING_STAGES,
  TEAM_SIZE,
  YEAR_OPTIONS,
} from "core/consts";
import { TextField } from "pages/onboard/components";
import GoogleInput from "molecules/Form/GoogleInput";
import { prettyNum } from "utils/number";
import PitchDeckUpload from "./PitchDeckUpload";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import SdgSelect from "./SdgSelect";
import Button from "atoms/Button/Button";
import cn from "classnames";
import TextArea from "atoms/Form/TextArea";
import SingleSelect from "molecules/Form/SingleSelect";
import useAddNew from "./hooks/useAddNew";
import SelectIndustries from "pages/dealflow/SelectIndustries";

const AddNew: FC<ModalContentProps> = (props) => {
  const {
    record,
    name,
    site,
    founded,
    description,
    raisedTarget,
    productCategory,
    minTicketSize,
    valuation,
    companyStage,
    businessRelation,
    location,
    teamSize,
    pitchDeckItems,
    pitch,
    pitchVideoLink,
    sdg,
    options,
    selectedIndustries,
    loading,
    currency,
    validateVideoLink,
    marketOpp,
    businessTiming,
    investmentStage,
    setInvestmentStage,
    setDescription,
    setFounded,
    setRaisedTarget,
    setProductCategory,
    setMinTicketSize,
    setValuation,
    setCompanyStage,
    setBusinessRelation,
    setLocation,
    setPlace,
    setTeamSize,
    setPitchDeckItems,
    setPitch,
    setPitchVideoLink,
    setLogo,
    setName,
    setSite,
    setSdg,
    setMarketOpp,
    setBusinessTiming,
    onIndustriesChange,
    removeIndustry,
    handleSave,
  } = useAddNew(props);

  return (
    <div className={cn(loading && "opacity-60 pointer-events-none")}>
      <TextField
        className="mb-2"
        label="Company name"
        value={name}
        onChange={setName}
        disabled={Boolean(record)}
      />
      <TextField
        className="mb-2"
        label="Company website"
        value={site}
        onChange={setSite}
      />
      <div className="flex justify-center my-2">
        <ProfilePictureSelect
          label="Company logo"
          onComplete={setLogo}
          preview={record?.company.logo?.large?.location}
        />
      </div>
      <div className="my-2">
        <label className="mb-2 ml-2 text-sm text-gray-400" htmlFor="founded">
          Founded
        </label>
        <SingleSelect
          options={YEAR_OPTIONS}
          className="!mb-2"
          limit={1}
          onChange={setFounded}
          defaultValue={founded}
        />
      </div>
      <GoogleInput
        label="Location"
        labelClass="ml-2 text-sm font-normal !text-gray-400"
        getPlace={(newPlace: GoogleInputPlace) => {
          setPlace(newPlace);
          setLocation(newPlace.autoCompleteVal);
        }}
        value={location}
        onChange={(e: any) => setLocation(e.target.value)}
      />
      <div className="flex flex-col items-start mt-2">
        <label
          className="mb-2 ml-2 text-sm text-gray-400"
          htmlFor="business-relation"
        >
          Business relation
        </label>
        <ButtonGroup
          id="business-relation"
          options={Object.entries(BUSINESS_MODEL)}
          className="!mb-2 !mr-2"
          limit={3}
          onChange={setBusinessRelation}
          defaultValue={businessRelation}
        />
      </div>
      <div className="flex flex-col w-full md:w-auto">
        <SelectIndustries
          options={options}
          selectedIndustries={selectedIndustries}
          onIndustriesChange={onIndustriesChange}
          removeIndustry={removeIndustry}
        />
      </div>
      <div className="flex flex-col items-start mt-2">
        <label
          className="mb-2 ml-2 text-sm text-gray-400"
          htmlFor="company-stage"
        >
          Company Stage
        </label>
        <ButtonGroup
          id="company-stage"
          options={Object.entries(CURRENT_COMPANY_STAGE)}
          className="!mb-2 !mr-2"
          limit={1}
          onChange={setCompanyStage}
          defaultValue={companyStage}
        />
      </div>
      <div className="flex flex-col items-start mt-2">
        <label
          className="mb-2 ml-2 text-sm text-gray-400"
          htmlFor="product-category"
        >
          Product category
        </label>
        <ButtonGroup
          id="product-category"
          options={Object.entries(BUSINESS_CATEGORY)}
          className="!mb-2 !mr-2"
          onChange={setProductCategory}
          defaultValue={productCategory}
        />
      </div>
      <div className="flex flex-col items-start mt-2">
        <label className="mb-2 ml-2 text-sm text-gray-400" htmlFor="team-size">
          Team size
        </label>
        <ButtonGroup
          id="team-size"
          options={Object.entries(TEAM_SIZE)}
          className="!mb-2 !mr-2"
          limit={1}
          onChange={setTeamSize}
          defaultValue={teamSize}
        />
      </div>
      <label className="ml-2 text-sm text-gray-400">Elevator pitch</label>
      <TextArea
        rows="3"
        id="elevator-pitch"
        name="elevatorpitch"
        defaultValue={pitch}
        className="block w-full my-2 mt-1 transition duration-150 ease-in-out border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
        onChange={(e: any) => setPitch(e.target.value)}
        maxLength={300}
      />
      <TextField
        className="my-2"
        label="Pitch Video link"
        value={pitchVideoLink}
        onChange={setPitchVideoLink}
      />
      {pitchVideoLink && !validateVideoLink && (
        <div className="ml-2 text-sm text-fire-400">Invalid video link</div>
      )}
      <div className="flex flex-col items-start mt-2">
        <label className="mb-2 ml-2 text-sm text-gray-400" htmlFor="sdg">
          Investment stage
        </label>
        <ButtonGroup
          id="investment-stage"
          options={Object.entries(FUNDRAISING_STAGES)}
          className="!mb-2 !mr-2"
          limit={1}
          onChange={setInvestmentStage}
          defaultValue={investmentStage}
        />
      </div>
      <TextField
        className="my-2"
        label={`Valuation (${currency.symbol})`}
        value={valuation}
        onChange={(value) => setValuation(prettyNum(value))}
      />
      <TextField
        className="my-2"
        label={`Target (${currency.symbol})`}
        value={raisedTarget}
        onChange={(value) => setRaisedTarget(prettyNum(value))}
      />
      <TextField
        className="my-2"
        label={`Min ticket size (${currency.symbol})`}
        value={minTicketSize}
        onChange={(value) => setMinTicketSize(prettyNum(value))}
      />
      <div className="my-2">
        <div className="mb-2 ml-2 text-sm text-gray-400">Pitch deck</div>
        <PitchDeckUpload items={pitchDeckItems} onChange={setPitchDeckItems} />
      </div>
      <div>
        <div className="mb-2 ml-2 text-sm text-gray-400">SDGs</div>
        <SdgSelect items={sdg} onChange={setSdg} />
      </div>
      <div className="mt-2">
        <label className="my-2 ml-2 text-sm text-gray-400">
          Product details
        </label>
        <TextArea
          rows="3"
          id="description"
          name="description"
          defaultValue={description}
          className="block w-full my-2 mt-1 transition duration-150 ease-in-out border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
          onChange={(e: any) => setDescription(e.target.value)}
          maxLength={300}
        />
      </div>
      <label className="mb-2 ml-2 text-sm text-gray-400">
        Market opportunity
      </label>
      <TextArea
        rows="3"
        id="market-opportunity"
        name="market-opportunity"
        defaultValue={marketOpp}
        className="block w-full my-2 mt-1 transition duration-150 ease-in-out border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
        onChange={(e: any) => setMarketOpp(e.target.value)}
        maxLength={300}
      />
      <label className="mb-2 ml-2 text-sm text-gray-400">Business timing</label>
      <TextArea
        rows="3"
        id="business-model"
        name="business-model"
        defaultValue={businessTiming}
        className="block w-full my-2 mt-1 transition duration-150 ease-in-out border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
        onChange={(e: any) => setBusinessTiming(e.target.value)}
        maxLength={300}
      />
      <div className="flex justify-end mt-6">
        <Button
          onClick={handleSave}
          size="small"
          color="primary"
          disabled={!name}
          loading={loading}
        >
          {record ? "Save" : "Add Company"}
        </Button>
      </div>
    </div>
  );
};

export default AddNew;
