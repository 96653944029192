import React from "react";
import { ReactComponent as EmojiIcon } from "images/red-emoji-icon.svg";
import { ExpireContent } from "../MySpace.style";
import { useLang } from "core/lang/LangaugeSetting";
import Button from "atoms/Button/Button";

const ExpireScreen = () => {
  const { lang } = useLang();
  return (
    <ExpireContent>
      <h3 className="flex">
        <EmojiIcon className="mr-1" />
        {lang.yourFreeTrialIsExpireSoon}
      </h3>
      <Button
        className="justify-center"
        color="primary"
        size="large"
        style={{ borderRadius: 25 }}
        //   loading={state.createLoading}
      >
        {lang.upgradeToPremiumNow}
      </Button>
    </ExpireContent>
  );
};
export default ExpireScreen;
