import { FC, useState, useEffect } from "react";
import { ImagePreviewProps } from "./interfaces";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";

const ImagePreview: FC<ImagePreviewProps> = ({ image, index, onRemove }) => {
  const [src, setSrc] = useState<string>("");

  useEffect(() => {
    let url: string;

    if (image instanceof Blob) {
      url = URL.createObjectURL(image);
    } else {
      url = image.large.location;
    }

    setSrc(url);

    return () => URL.revokeObjectURL(url);
  }, [image]);

  return (
    <div className="relative w-1/5 mr-1">
      <img
        src={src}
        alt={`Product Image ${index}`}
        className="w-full h-20 rounded-lg hover:opacity-50"
      />
      <CloseIcon
        onClick={() => onRemove()}
        className="absolute w-5 h-5 cursor-pointer -right-1 -top-1 opacity-70 hover:opacity-100 "
      />
    </div>
  );
};

export default ImagePreview;
