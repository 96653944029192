export const toInt = (value: string | undefined | null) =>
  value ? parseInt(value.replace(/\D/g, "")) : undefined;

export const getStartupFields = (payload: Record<string, any>) => ({
  ...payload,
  basics: {
    ...payload.basics,
  },
  welcome: {
    ...payload.welcome,
    place: {
      city: payload.welcome?.place?.city,
      state: payload.welcome?.place?.state,
      country: payload.welcome?.place?.country,
      lat: payload.welcome?.place?.lat,
      long: payload.welcome?.place?.long,
      fullAddress: payload.welcome?.place?.fullAddress,
    },
  },
  investment: {
    stage: payload.investment?.stages?.[0],
    currency: payload.investment?.currency?.id,
    ticketSize: toInt(payload.investment?.ticketSize),
    raisedTarget: toInt(payload.investment?.raisedTarget),
    fundraisingCloseOn: payload.investment?.fundraisingCloseOn,
  },
  finish: true,
});

export const getInvestorFields = (
  payload: Record<string, any>,
  isAccelerator: boolean = false
) => ({
  ...payload,
  welcome: {
    ...payload.welcome,
    isAccelerator,
    type: payload.welcome?.type?.id,
    place: {
      city: payload.welcome?.place?.city,
      state: payload.welcome?.place?.state,
      country: payload.welcome?.place?.country,
      lat: payload.welcome?.place?.lat,
      long: payload.welcome?.place?.long,
      fullAddress: payload.welcome?.place?.fullAddress,
    },
  },
  finish: true,
});
