// @ts-nocheck
import tw, { css, styled } from "twin.macro";

export const Pagination = styled.div(() => [
  tw`overflow-auto`,
  css`
    .pagination {
      li {
        &.active {
          a {
            background: #e7f8f7;
            border-color: #c5efec;
            color: #18beb3;
          }
        }
        a {
          color: #7f7f7f;
        }
        &.disabled {
          a {
            opacity: 0.6;
          }
        }
      }
    }
    select {
      background: #fff;
      option {
        color: #000;
        &.active {
          color: #18beb3;
        }
      }
    }
  `,
]);
