import PureHtml from "atoms/PureHtml";
import { Section } from "atoms/Section.style";
import ProductVisuals from "molecules/ProductVisuals";
import { FC } from "react";
import cn from "classnames";
import SectionHeader from "../SectionHeader/SectionHeader";

interface ProductDescriptionProps {
  description: string;
  valueProposition: string;
  customers: string;
  productDemo: string;
  productImages: any[];
  isProfileGeneratedByAIA?: boolean;
}

const ProductDescription: FC<ProductDescriptionProps> = ({
  description,
  valueProposition,
  customers,
  productDemo,
  productImages,
  isProfileGeneratedByAIA,
}) => (
  <div>
    {(description ||
      valueProposition ||
      customers ||
      productDemo ||
      productImages?.length > 0) && (
      <div className="overflow-hidden dark:text-white/80 my-2">
        <SectionHeader title="Product" />

        <div className="flex flex-col lg:flex-row">
          <div className="w-full whitespace-pre-wrap dark:text-gray-400 flex-grow mb-4 lg:mb-0 border- border-red-400">
            {description && (
              <div className="mb-4">
                <h3 className="mr-2 text-lg text-gray-700 my-2">
                  Product/Service Description
                </h3>
                <div
                  className={cn(
                    isProfileGeneratedByAIA && "aiaProfile pl-4",
                    "text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper"
                  )}
                >
                  <PureHtml content={description} />
                </div>
              </div>
            )}

            {valueProposition && (
              <div className="mb-4">
                <h3 className="mr-2 text-lg text-gray-700 my-2">
                  Main Value Proposition
                </h3>
                <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper pl-4">
                  <PureHtml content={valueProposition} />
                </div>
              </div>
            )}

            {customers && (
              <div className="mb-4">
                <h3 className="mr-2 text-lg text-gray-700 my-2">Customers</h3>
                <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper pl-4">
                  <PureHtml content={customers} />
                </div>
              </div>
            )}
          </div>

          {(productDemo || productImages?.length > 0) && (
            <ProductVisuals
              productDemo={productDemo}
              productImages={productImages?.map(
                (image) => image.large.location
              )}
            />
          )}
        </div>
      </div>
    )}
  </div>
);

export default ProductDescription;
