import Button from "atoms/Button/Button";
import { FC } from "react";

interface Props {
  onSave: () => void;
  onCancel: () => void;
  className?: string;
}

const SaveAndCancelButtons: FC<Props> = ({ onSave, onCancel, className }) => {
  return (
    <div className={className}>
      <button
        type="submit"
        className="mr-2 px-3 py-1 text-sm font-normal leading-6 border border-transparent rounded-full focus:outline-none bg-aqua-400 hover:bg-aqua-500 bg-transparent shadow-none hover:text-white border-aqua-400 text-aqua-400"
        onClick={() => onCancel()}
      >
        Cancel
      </button>
      <button
        className="px-3 py-1 text-sm font-normal leading-6 border border-transparent rounded-full shadow-sm focus:outline-none text-white bg-aqua-400 hover:bg-aqua-500"
        onClick={() => onSave()}
      >
        Save
      </button>
    </div>
  );
};

export default SaveAndCancelButtons;
