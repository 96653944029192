import { createAction } from "@reduxjs/toolkit";

export const triggerSnack = createAction(
  "snack/triggerSnack",
  ({ type, title, message, timer }) => ({
    payload: {
      type,
      title,
      message,
      timer,
    },
  })
);
export const removeSnack = createAction("snack/remove", ({ id }) => ({
  payload: {
    id,
  },
}));
