//@ts-nocheck

import { useMutation, useQuery } from "@apollo/client";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { APPLICATION_BASE_URL } from "../../../utils/links";
import {
  CREATE_COHORT,
  IS_COHORT_SLUG_AVAILABLE,
  UPDATE_COHORT,
} from "queries/investor/cohort";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApplicationLinkModalProps } from "../interfaces";

const LOGO_MAX_WIDTH = 300;
const LOGO_MAX_HEIGHT = 100;

const SLUG_CONFIG = {
  INFO: {
    icon: "info-circle",
    text: "Please enter a link, users will be available to apply through this link",
    className: "bg-sky-50 text-gray-400",
  },
  LOADING: {
    icon: "hourglass",
    text: "Checking if the link is available...",
    className: "bg-sky-50 text-gray-400",
  },
  SUCCESS: {
    icon: "check-circle",
    text: "You can use this link!",
    className: "bg-green-50 text-green-400",
  },
  ERROR: {
    icon: "window-close",
    text: "This link is already taken, please try another one",
    className: "bg-fire-50 text-fire-400",
  },
  CLOSED: {
    icon: "info-circle",
    text: "Your application is closed now, go back to Applications page to open it",
    className: "bg-sky-50 text-gray-400",
  },
} as const;

const useApplicationLinkModal = ({
  onClose,
  onAdd,
  cohort,
}: ApplicationLinkModalProps) => {
  const dispatch = useDispatch();
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [logoPreview, setLogoPreview] = useState(
    cohort?.logo?.medium?.location || ""
  );
  const [posterPreview, setPosterPreview] = useState(
    cohort?.poster?.medium?.location || ""
  );
  const {
    register,
    handleSubmit: handleFormSubmit,
    watch,
    setValue,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      name: cohort?.name || "",
      header: cohort?.header || "",
      description: cohort?.description || "",
      closedHeader: cohort?.closedHeader || "",
      closedDescription: cohort?.closedDescription || "",
      slug: cohort?.slug || "",
      logo: undefined as File | undefined,
      poster: undefined as File | undefined,
    },
  });
  const slugValue = watch("slug");
  const isEdit = Boolean(cohort);
  const isClosed = cohort?.isOpen === false;

  const { data: { isCohortSlugAvailable } = {}, loading: isSlugLoading } =
    useQuery(IS_COHORT_SLUG_AVAILABLE, {
      skip: !slugValue || isEdit,
      variables: {
        slug: slugValue,
      },
    });

  const [createCohort, { loading: isCreateLoading }] =
    useMutation(CREATE_COHORT);

  const [updateCohort, { loading: isEditLoading }] = useMutation(UPDATE_COHORT);

  const getSlug = () => {
    if (!slugValue) {
      return SLUG_CONFIG.INFO;
    }

    if (isClosed) {
      return SLUG_CONFIG.CLOSED;
    }

    if (isSlugLoading) {
      return SLUG_CONFIG.LOADING;
    }

    return isCohortSlugAvailable ? SLUG_CONFIG.SUCCESS : SLUG_CONFIG.ERROR;
  };

  const handleImageError = (message: string) =>
    dispatch(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message,
      })
    );

  const handleLogoChange = (file: File) => {
    setLogoPreview(URL.createObjectURL(file));
    setValue("logo", file);
  };

  const handlePosterChange = (file: File) => {
    setPosterPreview(URL.createObjectURL(file));
    setValue("poster", file);
  };

  const handleSubmit = handleFormSubmit(async (data) => {
    if (isCreateLoading) {
      return;
    }

    // @TODO: use validation library like joi
    const missedFields = (["name", "header", "slug"] as const).filter(
      (field) => !data[field]
    );

    if (missedFields.length) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Bad input",
          message: `Please fill the following fields: ${missedFields.join(
            ", "
          )}.`,
        })
      );

      return;
    }

    if (cohort) {
      const { slug: _, ...rest } = data;
      const input = cohort.isOpen
        ? { ...rest }
        : {
            closedHeader: rest.closedHeader,
            closedDescription: rest.closedDescription,
          };

      await updateCohort({
        variables: {
          input: {
            id: cohort.id,
            ...input,
          },
        },
      });
    } else {
      const { closedHeader, closedDescription, ...rest } = data;

      await createCohort({
        variables: {
          input: {
            ...rest,
            registrationType: "company",
          },
        },
      });
    }

    onAdd();
    onClose();
  });

  const handleCancel = () => {
    const isFormEmpty = Object.values(getValues()).every((value) => !value);

    if (isFormEmpty || isEdit) {
      onClose();
    } else {
      setIsConfirmation(true);
    }
  };

  const handleCloseConfirmation = () => setIsConfirmation(false);

  const slug = getSlug();
  const applicationBaseUrl = APPLICATION_BASE_URL;
  const logoMaxWidth = LOGO_MAX_WIDTH;
  const logoMaxHeight = LOGO_MAX_HEIGHT;
  const loading = isCreateLoading || isEditLoading;
  const title = isClosed
    ? "Edit your closed application page"
    : isEdit
    ? "Edit your application link"
    : "Create a new application link";

  return {
    loading,
    isEdit,
    isClosed,
    title,
    applicationBaseUrl,
    logoMaxWidth,
    logoMaxHeight,
    slug,
    logoPreview,
    posterPreview,
    isConfirmation,
    register,
    handleSubmit,
    handleLogoChange,
    handlePosterChange,
    handleImageError,
    handleCancel,
    handleCloseConfirmation,
    control,
  };
};

export default useApplicationLinkModal;
