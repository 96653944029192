import { v4 as uuid } from "uuid";

const STORAGE_KEY = "AuthorIdentifier";

export const getAuthorId = () => {
  const authorId = localStorage.getItem(STORAGE_KEY);

  if (authorId) {
    return authorId;
  }

  const newAuthorId = uuid();

  localStorage.setItem(STORAGE_KEY, newAuthorId);

  return newAuthorId;
};
