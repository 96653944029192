// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const fetchUserData = createAction("model/user/me", (payload) => ({
  payload,
}));

export const refetchUserData = createAction(
  "model/user/me/refetch",
  (payload) => ({
    payload,
  })
);

export const fetchActiveCohort = createAction(
  "model/user/get/activeCohort",
  (payload) => ({ payload })
);

export const fetchCurrencyList = createAction(
  "model/user/get/currencyList",
  (payload) => ({
    payload,
  })
);

export const setUserHidden = createAction(
  "model/user/hidden/set",
  (payload) => ({
    payload,
  })
);

export const uploadLogo = createAction("models/user/uploadLogo", (payload) => ({
  payload,
}));
