import imagePlaceholder from "images/investor-placeholder-img.png";
import SectionHeader from "../SectionHeader/SectionHeader";

export default ({ priorInvestments, investorNote }) => {
  return (
    <>
      {priorInvestments?.length > 0 && (
        <div>
          <div>
            <SectionHeader title="Cap Table" />
            {investorNote && (
              <p className="text-base dark:text-gray-400">{investorNote}</p>
            )}
          </div>
          <div className="flex flex-wrap gap-8 mt-2">
            {priorInvestments.map((investment, index) => (
              <div key={index} className="text-gray-700 dark:text-white/80">
                <img
                  src={investment.logo ? investment.logo : imagePlaceholder}
                  className="object-cover w-20 h-20 rounded-full"
                />
                <a
                  target="_blank"
                  href={investment.linkedin}
                  rel="noreferrer"
                  className="block mt-2 text-base font-medium text-center !text-gray-700 text-white/80"
                >
                  {investment.displayName}
                  <p className="!text-gray-700 text-white/80">
                    {investment.name}
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
