// @ts-nocheck
import MultiSelect from "./MultiSelect";

const SelectionDropdownInput = ({
  textSize,
  isLabelHide,
  type,
  handleUpdateVal,
  icon,
  onSelectVal,
  inputVal,
  selectName,
  label,
  inputPlaceholder,
  selectOptions,
  selectValue,
  inputName,
  reference,
  errors,
  direction,
}: any) => (
  <div>
    {!isLabelHide && (
      <label
        className={`text-black text-opacity-100 mb-2 block ${
          textSize ? textSize + " font-normal" : "text-sm font-medium"
        }`}
      >
        {label}
      </label>
    )}
    <div
      className={[
        "flex items-center relative border rounded py-1",
        errors
          ? "border-red-300 text-red-900 border-opacity-1"
          : "text-black border-black border-opacity-25",
      ].join(" ")}
    >
      {direction === "right" && icon ? (
        <div style={{ width: 45, marginLeft: 7 }}>{icon}</div>
      ) : (
        ""
      )}
      <div
        style={{
          order: direction === "right" ? 2 : 0,
          marginRight: direction === "right" && 10,
          marginLeft: direction === "left" && 6,
        }}
      >
        <MultiSelect
          label=""
          single={true}
          defaultValue={selectValue}
          onChange={onSelectVal}
          options={selectOptions}
          selectDropdownInput={true}
          showChecked={true}
        />
      </div>
      <input
        className="w-full p-2 pl-2 text-sm text-gray-700 placeholder-gray-800 transition-all duration-200 ease-in-out bg-transparent rounded outline-none"
        id={inputName}
        name={inputName}
        type={type ? type : "text"}
        onChange={(e) =>
          handleUpdateVal ? handleUpdateVal(e.target.value) : ""
        }
        defaultValue={inputVal ? inputVal : ""}
        {...(reference && reference)}
        placeholder={inputPlaceholder}
        style={{
          marginLeft:
            direction === "right" ? (isLabelHide ? (icon ? 7 : 12) : 7) : 10,
          outline: "none",
        }}
      />
    </div>
    {errors && <p className="mt-2 mb-3 text-sm text-fire-400">{errors}</p>}
  </div>
);
export default SelectionDropdownInput;
