// @ts-nocheck

import AuthImage from "atoms/AuthImage/AuthImage";
import LanguageSelection from "atoms/Lang/LanguageSelection";
import Loading from "atoms/Loading/Loading";
import { withRouter } from "hooks/withRouter";
import { useLang } from "core/lang/LangaugeSetting";
import LanguageModal from "molecules/Lang/LanguageModal";
import { useEffect, useState } from "react";
import { pageAnimation } from "ui/animations";
import { Page } from "ui/elements/PanelLayout.styles";
import ResetPasswordForm from "./components/ResetPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { RESET_PASSWORD } from "./constants";
import { isLoadingEnabled } from "models/loaders/selectors";
import { initialize } from "./sagaActions";

const ResetPassword = (props) => {
  const params = props?.params;
  const token = params?.token;
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [openModal, setModal] = useState(false);

  // Selectors
  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, RESET_PASSWORD)
  );

  useEffect(() => {
    dispatch(initialize({ params, lang }));
  }, [token]);

  if (isLoading) {
    return <Loading fullPage />;
  }

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
    >
      <div className="flex min-h-screen bg-white">
        <div className="flex flex-col justify-center flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="w-full max-w-sm mx-auto">
            <ResetPasswordForm token={token} />
          </div>
        </div>

        <AuthImage />
      </div>
    </Page>
  );
};
export default withRouter(ResetPassword);
