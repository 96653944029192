import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeInvitationPage } from "./sagaActions";
import { isLoadingEnabled } from "models/loaders/selectors";
import { INVITATION } from "./constants";
import Loading from "atoms/Loading/Loading";

const InvitationLoader = () => {
  const { invitationslug } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => isLoadingEnabled(state, INVITATION));

  useEffect(() => {
    dispatch(initializeInvitationPage({ invitationSlug: invitationslug }));
  }, [invitationslug]);

  if (isLoading) {
    return <Loading fullPage />;
  }

  return null;
};

export default InvitationLoader;
