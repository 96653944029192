import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  onAddClicked: () => void;
}

const SdgPlaceholder: FC<Props> = ({ onAddClicked }) => {
  return (
    <div
      className="p-2 pb-1 border-2 border-dashed border-aqua-100 rounded-lg ml-1 mb-2 cursor-pointer"
      onClick={() => onAddClicked()}
    >
      <div className="w-12 h-12 bg-aqua-50 m-auto"></div>
      <div className="flex items-center mt-2">
        <FontAwesomeIcon
          icon={["fal", "plus-circle"]}
          className="mr-2 text-aqua-400"
        />
        <span className="text-aqua-400 text-sm">Add</span>
      </div>
    </div>
  );
};

export default SdgPlaceholder;
