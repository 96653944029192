import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FieldName } from "gql/graphql";

// Wrapper component props
interface Props {
  content: string;
}

const PotentialLossCases: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div className="rounded-md p-4 bg-[#FF8060]/10 h-full">
      <h6 className="font-semibold mb-2">Potential risks</h6>
      <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-base aiaProfile">
        <PureHtml content={content} />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(
  PotentialLossCases,
  FieldName.RiskAnalysis
);
