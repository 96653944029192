import React from "react";
import CollapsiblePage from "./CollapsiblePage";

const SlackIntegrationDocs = () => {
  return (
    <CollapsiblePage title="Slack Integration" className="my-2">
      <div>Coming soon...</div>
    </CollapsiblePage>
  );
};

export default SlackIntegrationDocs;
