import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import { logoutUser } from "models/auth/sagaActions";
import { useLang } from "core/lang/LangaugeSetting";
import { setGlobalState } from "models/globalState/sagaActions";
import SideNavItem from "atoms/NavItem/SideNavItem";
import { SideNavigationProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkspaceSelector from "./WorkspaceSelector";
import { DIVIDER } from "./constants";
import { Tier } from "gql/graphql";

const SideNavigation = ({
  config,
  user,
  userRole,
  className,
  isNavigationEnabled = true,
  onNavItemClicked = () => {},
}: SideNavigationProps) => {
  const dispatch = useDispatch();
  const { lang } = useLang();

  const handleLogout = async () => {
    dispatch(logoutUser({ lang }));
    clearAllBodyScrollLocks();
  };

  const isAngelInvestor = user?.investorType === "ANGEL_INVESTOR";
  const isVcOrFamilyOffice =
    user?.investorType === "VC" || user?.investorType === "FAMILY_OFFICE";

  const tier = user?.tier;

  const onHelpClicked = () => {
    onNavItemClicked();

    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  return (
    <div className={`sm:min-w-[250px] pt-6 pr-5 z-10 ${className}`}>
      <div className="md:hidden">
        <WorkspaceSelector isMobile tier={tier} />
      </div>

      {isNavigationEnabled && <hr className="mt-2 ml-4" />}

      <nav className="mt-4">
        {isNavigationEnabled &&
          config[userRole].map((configItem, index) => {
            if (configItem === DIVIDER) {
              return <hr className="my-2 ml-4" key={index} />;
            }

            if (isAngelInvestor && configItem.id === "MANAGE_TEAM") return null;
            if (
              !(tier === Tier.Trial || tier === Tier.Pending) &&
              configItem.id === "UPGRADE"
            )
              return null;
            if (
              (tier === Tier.Trial || tier === Tier.Pending) &&
              configItem.id === "BILLING"
            )
              return null;

            // show dealflow for VCs only if they have uploaded atleast one pitch deck
            if (
              isVcOrFamilyOffice &&
              user?.company.aiaTrial?.totalUploads < 1 &&
              configItem.id === "DEALFLOW"
            )
              return null;

            const { title, to, Icon, elementId, children } = configItem;

            // add end prop for the root route. Otherwise
            // isActive is true for it always
            const end = to === "/myspace" || to === "/dealflow";
            return (
              <div key={index}>
                <NavLink
                  to={to}
                  end={end}
                  onClick={() => {
                    onNavItemClicked();
                  }}
                >
                  {({ isActive }) => (
                    <SideNavItem
                      title={title}
                      Icon={Icon}
                      isActive={isActive}
                      to={to}
                      elementId={elementId}
                    />
                  )}
                </NavLink>
                {children?.map(({ id, title, to, Icon }, index) => (
                  <NavLink key={index} to={to} onClick={onNavItemClicked}>
                    {({ isActive }) => (
                      <SideNavItem
                        title={title}
                        isChild={true}
                        isActive={isActive}
                        Icon={Icon}
                        to={to}
                      />
                    )}
                  </NavLink>
                ))}
              </div>
            );
          })}

        <NavLink to="" onClick={onHelpClicked} className="block md:hidden">
          <SideNavItem title="Help">
            <FontAwesomeIcon icon={["fal", "question-circle"]} />
          </SideNavItem>
        </NavLink>

        <NavLink to="" onClick={handleLogout} className="block md:hidden">
          <SideNavItem title="Logout">
            <FontAwesomeIcon icon={["fal", "sign-out"]} />
          </SideNavItem>
        </NavLink>
      </nav>
    </div>
  );
};

export default SideNavigation;
