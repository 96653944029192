// @ts-nocheck
import { nanoid } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";

export default function* rootSaga() {
  function* handleTriggerSnack({ payload: { type, title, message, timer } }) {
    yield put(
      slice.handleUpdateSnacks({
        type,
        title,
        message,
        timer,
        key: nanoid(),
      })
    );
  }
  function* removeSnack({ payload: { id } }) {
    yield put(slice.removeSnack({ id }));
  }
  return [
    yield takeLatest(sagaActions.triggerSnack, handleTriggerSnack),
    yield takeLatest(sagaActions.removeSnack, removeSnack),
  ];
}
