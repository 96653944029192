import React from "react";
import { useParams, useLocation } from "react-router-dom";

export default ({ component }: any) => {
  let params = useParams();
  let location = useLocation();
  return (
    <div className="mx-auto h-full">
      {React.createElement(component, { params, location })}
    </div>
  );
};
