import { useQuery } from "@apollo/client";
import { getUser } from "models/user/selectors";
import { GET_STARTUP_BOARD_BY_SLUG } from "queries/investor/boards";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

// temporary solution, either store board data in redux state
// or consider passing some parameters in route config (as we do for showHeader)
//
const getBoardSlugMatch = (pathname: string, params: URLSearchParams) => {
  const match = pathname.match(/\/board\/public\/(.*)/);
  const isBoardSource = params.get("us") === "board";
  const boardSlug = params.get("uc");

  if (match) {
    return match[1];
  }

  if (isBoardSource && boardSlug) {
    return boardSlug;
  }

  return null;
};

const useBoardLogo = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const user = useSelector(getUser);
  const boardSlug = getBoardSlugMatch(location.pathname, searchParams);
  const { data: boardData } = useQuery(GET_STARTUP_BOARD_BY_SLUG, {
    variables: {
      slug: boardSlug as string,
    },
    skip: !boardSlug || user,
  });

  return boardData?.getStartupBoardBySlug?.logo?.large?.location || null;
};

export default useBoardLogo;
