import { gql } from "@apollo/client";
import { graphql } from "gql";

export const GET_DEALFLOW = gql`
  query getInvestorDealflow {
    dealflow {
      id
      invitationCohortSlug
      columns {
        title
        key
      }
      sections {
        id
        title
        order
      }
    }
  }
`;

export const GET_INVITATION_COHORT = gql`
  query getInvitationCohort {
    dealflow {
      id
      invitationCohortSlug
    }
  }
`;

// @TODO: remove _id fields
//
export const GET_DEALFLOW_RECORDS = gql`
  query getInvestorDealflowRecords(
    $page: Int
    $query: String
    $cohorts: [ID]
    $sections: [ID]
    $sort: SortConfig
    $industries: [IndustryCategory]
  ) {
    dealflow {
      id
      records(
        page: $page
        query: $query
        cohorts: $cohorts
        sections: $sections
        sort: $sort
        industries: $industries
      ) {
        total
        page
        perPage
        nodes {
          id
          entryDate
          cohort {
            name
          }
          isManual
          company {
            id
            _id
            slug
            currency
            displayName
            createdAt
            country
            logo {
              small {
                location
              }
              large {
                location
              }
            }
            ... on Startup {
              isAiaGenerated
              valuation
              industries
              website
              teamSize
              companyStage
              businessModel
              businessCategory
              pitchVideoLink
              pitch
              marketOpp
              description
              businessTiming
              founded
              finance {
                raisedTarget
                raisedValue
                minTicketSize
                investmentStage
              }
              teamMembers {
                name
              }
              documents {
                id
                title
                location
                mimetype
              }
              sustainableDevelopmentGoals {
                items
              }
              investors {
                name
                displayName
              }
            }
          }
          status {
            id
            _id
          }
        }
      }
    }
  }
`;

export const UPDATE_DEALFLOW_COLUMNS = gql`
  mutation updateDealflowColumns($columns: [DealflowColumnInput]) {
    dealflow(columns: $columns) {
      id
    }
  }
`;

export const CHANGE_RECORD_STATUS = gql`
  mutation changeRecordStatus($record: ID, $records: [ID], $status: ID!) {
    updateDealflowRecordStatus(
      record: $record
      records: $records
      status: $status
    )
  }
`;

export const GET_RECORD_NOTE = gql`
  query getRecordNote($id: ID!) {
    dealflowRecord(id: $id) {
      id
      note
    }
  }
`;

export const GET_RECORD_DOCUMENTS = gql`
  query getRecordDocuments($id: ID!) {
    dealflowRecord(id: $id) {
      id
      documents {
        id
        title
        location
        mime
      }
    }
  }
`;

export const GET_RECORD_COMMENTS = gql`
  query getRecordComments($id: ID!) {
    dealflowRecord(id: $id) {
      id
      comments {
        id
        text
        createdAt
        author {
          displayName
          logo {
            small {
              location
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_RECORD_NOTE = gql`
  mutation updateDealflowRecordNote($record: ID!, $note: String) {
    updateDealflowRecordNote(record: $record, note: $note)
  }
`;

export const ADD_DEALFLOW_DOCUMENT = gql`
  mutation addDealflowDocument($record: ID!, $file: Upload!) {
    addDealflowDocument(record: $record, file: $file)
  }
`;

export const REMOVE_DEALFLOW_DOCUMENT = gql`
  mutation removeDealflowDocument($record: ID!, $document: ID!) {
    removeDealflowDocument(record: $record, document: $document)
  }
`;

export const ADD_DEALFLOW_COMMENT = gql`
  mutation addDealflowComment($record: ID!, $text: String!) {
    addDealflowComment(record: $record, text: $text)
  }
`;

export const REMOVE_DEALFLOW_RECORDS = gql`
  mutation removeDealflowRecords($records: [ID]!) {
    removeDealflowRecords(records: $records)
  }
`;

export const PUSH_TO_BOARD = gql`
  mutation pushToBoard($id: ID!, $startups: [ID]!) {
    pushToBoard(id: $id, startups: $startups)
  }
`;

export const SEARCH_STARTUPS_TO_ADD = graphql(`
  query startupsToAdd($query: String) {
    startupsToAdd(query: $query) {
      id
      name
      logo {
        small {
          location
        }
      }
    }
  }
`);

export const ADD_TO_DEALFLOW = gql`
  mutation addToDealflow($companyId: ID!) {
    addToDealflow(companyId: $companyId)
  }
`;

export const ADD_CUSTOM_STARTUP_TO_DEALFLOW = gql`
  mutation addCustomStartupToDealflow(
    $name: String!
    $founded: String
    $site: String
    $location: CompanyPlace
    $valuation: Int
    $description: String
    $raisedTarget: Int
    $pitchVideoLink: String
    $businessModels: [BusinessModel]
    $businessCategories: [BusinessCategory]
    $industries: [IndustryCategory]
    $companyStages: [StartupStage]
    $investmentStages: [InvestmentStage]
    $teamSize: TeamSize
    $pitch: String
    $minTicketSize: Int
    $pitchDecks: [Upload]
    $logo: Upload
    $sdgItems: [Int]
    $businessTiming: String
    $marketOpp: String
  ) {
    addCustomStartupToDealflow(
      name: $name
      founded: $founded
      site: $site
      location: $location
      valuation: $valuation
      description: $description
      raisedTarget: $raisedTarget
      pitchVideoLink: $pitchVideoLink
      businessModels: $businessModels
      businessCategories: $businessCategories
      industries: $industries
      companyStages: $companyStages
      investmentStages: $investmentStages
      teamSize: $teamSize
      pitch: $pitch
      minTicketSize: $minTicketSize
      pitchDecks: $pitchDecks
      logo: $logo
      sdgItems: $sdgItems
      businessTiming: $businessTiming
      marketOpp: $marketOpp
    )
  }
`;

export const EDIT_CUSTOM_STARTUP = gql`
  mutation editCustomStartup(
    $recordId: String!
    $founded: String
    $site: String
    $location: CompanyPlace
    $valuation: Int
    $description: String
    $raisedTarget: Int
    $pitchVideoLink: String
    $businessModels: [BusinessModel]
    $businessCategories: [BusinessCategory]
    $industries: [IndustryCategory]
    $companyStages: [StartupStage]
    $investmentStages: [InvestmentStage]
    $teamSize: TeamSize
    $pitch: String
    $minTicketSize: Int
    $pitchDeckUploads: [Upload]
    $pitchDeckIds: [String]
    $logo: Upload
    $sdgItems: [Int]
    $businessTiming: String
    $marketOpp: String
  ) {
    editCustomStartup(
      recordId: $recordId
      founded: $founded
      site: $site
      location: $location
      valuation: $valuation
      description: $description
      raisedTarget: $raisedTarget
      pitchVideoLink: $pitchVideoLink
      businessModels: $businessModels
      businessCategories: $businessCategories
      industries: $industries
      companyStages: $companyStages
      investmentStages: $investmentStages
      teamSize: $teamSize
      pitch: $pitch
      minTicketSize: $minTicketSize
      pitchDeckUploads: $pitchDeckUploads
      pitchDeckIds: $pitchDeckIds
      logo: $logo
      sdgItems: $sdgItems
      businessTiming: $businessTiming
      marketOpp: $marketOpp
    )
  }
`;

export const BULK_INSERT_STARTUPS = gql`
  mutation bulkInsertToDealflow($items: [BulkInsertStartupInput!]!) {
    bulkInsertToDealflow(items: $items)
  }
`;

export const GET_SECTIONS = gql`
  query getSections {
    getSections {
      _id
      defaultTitle
      customTitle
      isDefault
      order
      isMovable
      isDeletable
      isEditable
      companiesCount
      isDefault
    }
  }
`;

export const UPDATE_DEALFLOW_SECTIONS = gql`
  mutation updateDealflowSectionList($sections: [DealflowSectionUpdateInput]!) {
    updateDealflowSectionList(sections: $sections) {
      customTitle
      order
      _id
    }
  }
`;

export const GET_INVESTOR_BY_SLUG_SHORT = gql`
  query investorBySlugShort($slug: String!) {
    investorBySlug(slug: $slug) {
      displayName
      industries
      supportAsAdvisor
      competences
      investorType
      businessModels
      investmentRounds
      investmentStage
      businessCategory
    }
  }
`;
