import { gql } from "@apollo/client";

export const CONTACT_WITH = gql`
  mutation contactWith(
    $firstName: String!
    $lastName: String!
    $email: String!
    $message: String!
    $target: String!
    $isPoliciesAndTermsAgreed: Boolean!
  ) {
    contactWith(
      firstName: $firstName
      lastName: $lastName
      email: $email
      message: $message
      target: $target
      isPoliciesAndTermsAgreed: $isPoliciesAndTermsAgreed
    ) {
      id
      ok
    }
  }
`;

export const GET_CONTACT_MESSAGES = gql`
  query getContactMessages($start: Int, $limit: Int) {
    getContactMessages(start: $start, limit: $limit) {
      totalCount
      searchCount
      messages {
        id
        email
        firstName
        lastName
        message
        createdAt
        sender {
          slug
          type
        }
      }
    }
  }
`;
