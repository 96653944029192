import React, { FC } from "react";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import PureHtml from "atoms/PureHtml";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FieldName } from "gql/graphql";

interface Props {
  content: string;
}

const Pricing: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div>
      <SectionHeader title="Pricing" />
      <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile">
        <PureHtml content={content} />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(Pricing, FieldName.Pricing);
