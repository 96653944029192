import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";

interface Props {
  contracts: Array<string | null> | null | undefined;
  note: string | null | undefined;
}

const ContractsInPipeline: FC<Props> = ({ contracts, note }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const contractsData = contracts?.[0];

  const renderContent = () => {
    return (
      <div>
        {contractsData ? <div>{contractsData}</div> : "---"}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          pipelineContracts: inputValue,
          pipelineContractsNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={contractsData}
        noteDefaultValue={note}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="# of contracts in pipeline"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={contractsData ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default ContractsInPipeline;
