import { createAction } from "@reduxjs/toolkit";

export const initializeInvitationPage = createAction(
  "invitation/initializeInvitationPage",
  (payload) => ({
    payload,
  })
);

export const acceptInvitation = createAction(
  "invitation/acceptInvitation",
  (payload) => ({
    payload,
  })
);
