import {
  AuthResponse,
  InvestorDetailsProps,
  StartupDetailsProps,
} from "./interfaces";
import { Mixpanel } from "./MixpanelWrapper";

// try catch is because mixpanel is blocked in Iran

// AUTH

const subscriptionSwitch = (subscriptionStatus: string, tier: string) => {
  const trial = subscriptionStatus === "TRIALING";

  if (trial) {
    return "Trialing";
  }

  switch (tier.trim()) {
    case "BUSINESS":
      return "Business";
    case "ENTERPRISE":
      return "Enterprise";
    case "SOLO":
      return "Solo";
    case "TEAM":
      return "Team";
    default:
      return "Pending";
  }
};

export const mixpanelSignUp = (
  email: string,
  userType: string | null | undefined,
  cohort?: string | null
) => {
  try {
    Mixpanel.people.set({
      $category: userType === "COMPANY" ? "startup" : "investor",
      $email: email,
      $distinct_id: email,
    });
    Mixpanel.identify(email);
    Mixpanel.track("Sign Up", { $source: "sircular", cohort: cohort });
  } catch {
    console.log("Mixpanel error: Sign Up");
  }
};
export const googleMixpanelSignUp = (
  email: string,
  userType: string | null | undefined,
  cohort?: string | null
) => {
  try {
    Mixpanel.people.set({
      $category: userType === "company" ? "startup" : "investor",
      $email: email,
      $name: email,
      $distinct_id: email,
    });
    Mixpanel.identify(email);
    Mixpanel.track("Sign Up", { $source: "google", cohort: cohort });
  } catch {
    console.log("Mixpanel error: Google Sign In");
  }
};

export const googleMixpanelSignIn = (email: string, type: string) => {
  try {
    Mixpanel.identify(email);
    Mixpanel.people.set({
      $distinct_id: email,
      $name: email,
      $email: email,
      $category: type === "company" ? "startup" : "investor",
    });
    Mixpanel.track("Sign In", { $signInSource: "google" });
    Mixpanel.people.setOnce({
      "First Login Date": new Date(),
    });
  } catch {
    console.log("Mixpanel error: Google Sign In");
  }
};

export const mixpanelSignIn = (authResponse: AuthResponse) => {
  try {
    Mixpanel.identify(authResponse?.email);
    Mixpanel.people.set({
      $distinct_id: authResponse?.email,
      $name: authResponse?.name,
      $email: authResponse?.email,
      $category: authResponse?.type === "company" ? "startup" : "investor",
    });
    Mixpanel.track("Sign In", { $signInSource: "sircular" });
    Mixpanel.people.setOnce({
      "First Login Date": new Date(),
    });
  } catch {
    console.log("Mixpanel error: Sign In");
  }
};

// SET USER PROPERTIES

export const setMixpanelInvestorProps = (
  investorDetails: InvestorDetailsProps,
  referrerEmail: string,
  userName: string,
  subscriptionStatus: string,
  tier: string
) => {
  try {
    const plan = subscriptionSwitch(subscriptionStatus, tier);

    Mixpanel.people.set({
      $name: userName,
      Plan: plan,
      Referrer: referrerEmail,
      Industries: investorDetails?.investorBySlug?.industries,
      SupportAsAdvisor: investorDetails?.investorBySlug?.supportAsAdvisor,
      Competences: investorDetails?.investorBySlug?.competences,
      InvestorType: investorDetails?.investorBySlug?.investorType,
      BusinessRelations: investorDetails?.investorBySlug?.businessModels,
      CompanyStage: investorDetails?.investorBySlug?.investmentRounds,
      InvestmentStage: investorDetails?.investorBySlug?.investmentStage,
      ProductType: investorDetails?.investorBySlug?.businessCategory,
    });
  } catch {
    console.log("Mixpanel error: Set Investor props");
  }
};

export const setMixpanelStartupProps = (
  startupDetails: StartupDetailsProps,
  activeCohort: string,
  referrerEmail: string,
  userName: string,
  subscriptionStatus: string,
  tier: string
) => {
  const plan = subscriptionSwitch(subscriptionStatus, tier);

  try {
    Mixpanel.people.set({
      $name: userName,
      Plan: plan,
      Referrer: referrerEmail,
      Industries: startupDetails?.companyBySlug?.industries,
      CompanyStage: startupDetails?.companyBySlug?.companyStage,
      ProductType: startupDetails?.companyBySlug?.businessCategory,
      InvestmentStage: startupDetails?.companyBySlug?.finance?.investmentStage,
      CohortID: activeCohort,
      BusinessRelation: startupDetails?.companyBySlug?.businessModel,
      Valuation: startupDetails?.companyBySlug?.valuation,
      Target: startupDetails?.companyBySlug?.finance?.raisedTarget,
    });
  } catch {
    console.log("Mixpanel error: Set Startup props");
  }
};

// REFERRALS

export const mixpanelReferred = (data: {
  referral: { id: any; referrer: { email: any } };
}) => {
  try {
    Mixpanel.track("Referred Sign Up", {
      referralId: data?.referral?.id,
      referrerEmail: data?.referral?.referrer?.email,
    });
  } catch {
    console.log("Mixpanel error: Sign up via referral");
  }
};

export const mixpanelInviteMember = (email: string, role: string) => {
  try {
    Mixpanel.track("Invite Member", {
      email: email,
      role: role,
    });
  } catch {
    console.log("Mixpanel error: Invite Member");
  }
};

export const mixpanelViewRestricted = (
  email: string,
  name: string,
  type: string
) => {
  if (type === "board") {
    try {
      Mixpanel.track("View Restricted Board", {
        email: email,
        name: name,
      });
    } catch {
      console.log("Mixpanel error: View Restricted Profile");
    }
  } else {
    try {
      Mixpanel.track("View Restricted Profile", {
        email: email,
        name: name,
      });
    } catch {
      console.log("Mixpanel error: View Restricted Profile");
    }
  }
};

export const mixpanelViewRegisterCohort = (cohort: string) => {
  try {
    Mixpanel.track("View Register Cohort", {
      cohort: cohort,
    });
  } catch {
    console.log("Mixpanel error: View Register Cohort");
  }
};

// PLAN

export const mixPanelClickCancelPlan = () => {
  try {
    Mixpanel.track("Click Cancel Plan");
  } catch {
    console.log("Mixpanel error: Click Cancel Plan");
  }
};

export const mixpanelClickUpgradePlan = () => {
  try {
    Mixpanel.track("Click Open Upgrade Plan");
  } catch {
    console.log("Mixpanel error: Click Upgrade Plan");
  }
};

export const mixpanelUpgradePlan = () => {
  try {
    Mixpanel.track("Upgrade Plan");
  } catch {
    console.log("Mixpanel error: Upgrade Plan");
  }
};

export const mixpanelClickDowngradePlan = () => {
  try {
    Mixpanel.track("Click Open Downgrade Plan");
  } catch {
    console.log("Mixpanel error: Click Downgrade Plan");
  }
};

export const mixpanelDownGradePlan = () => {
  try {
    Mixpanel.track("Downgrade Plan");
  } catch {
    console.log("Mixpanel error: Downgrade Plan");
  }
};

export const mixpanelClickCheckout = (
  price?: number,
  selectedFrequency?: string
) => {
  try {
    Mixpanel.track("Checkout click", {
      plan: "pro",
      price: price,
      frequency: selectedFrequency,
    });
  } catch (error) {
    console.error(error);
  }
};

export const mixpanelClickViewPlans = () => {
  try {
    Mixpanel.track("Click View Plans");
  } catch {
    console.log("Mixpanel error: Click View Plans");
  }
};

export const mixpanelCheckoutSuccess = () => {
  try {
    Mixpanel.track("Plan Upgrade", {
      plan: "pro",
    });
    Mixpanel.people.set({
      $plan: "pro",
    });
  } catch {
    console.log("Mixpanel error: Checkout Success");
  }
};

export const mixpanelClickSignUpTopNav = () => {
  try {
    Mixpanel.track("Click Sign Up Top Nav");
  } catch {
    console.log("Mixpanel error: Click Sign Up Top Nav");
  }
};

// DEALFLOW

export const mixpanelInviteStartupToDealflow = (text: string) => {
  const regex = /\/register\/([a-zA-Z0-9_-]+)/;
  const match = text.match(regex);
  const slug = match ? match[1] : null;
  try {
    Mixpanel.track("Invite Startup To Dealflow", {
      slug: slug,
    });
  } catch {
    console.log("Mixpanel error: Invite Startup To Dealflow");
  }
};

export const mixpanelAddCustomStartupToDealflow = (
  name: string,
  industries?: any[],
  investmentStage?: any[],
  companyStage?: any[],
  valuation?: string,
  raisedTarget?: string,
  founded?: number,
  location?: string,
  businessRelation?: any[],
  teamSize?: string
) => {
  try {
    Mixpanel.track("Add Custom Startup To Dealflow", {
      name: name,
      industries: industries,
      investmentStage: investmentStage,
      companyStage: companyStage,
      valuation: valuation,
      raisedTarget: raisedTarget,
      founded: founded,
      location: location,
      businessRelation: businessRelation,
      teamSize: teamSize,
    });
  } catch {
    console.log("Mixpanel error: Add Custom Startup To Dealflow");
  }
};

export const mixpanelAddExistingStartupToDealflow = (name: string) => {
  try {
    Mixpanel.track("Add Existing Startup To Dealflow", {
      name: name,
    });
  } catch {
    console.log("Mixpanel error: Add Existing Startup To Dealflow");
  }
};

export const mixpanelAddCustomInvestorToDealflow = (
  name: string,
  type: string,
  location?: string,
  industries?: any[],
  businessModels?: any[],
  investmentRangesMin?: number,
  investmentRangesMax?: number,
  supportAsAdvisor?: boolean,
  investmentStage?: any[]
) => {
  try {
    Mixpanel.track("Add Investor To Dealflow", {
      name: name,
      type: type,
      location: location,
      industries: industries,
      businessModels: businessModels,
      investmentRanges: {
        min: investmentRangesMin,
        max: investmentRangesMax,
      },
      supportAsAdvisor: supportAsAdvisor,
      investmentStage: investmentStage,
    });
  } catch {
    console.log("Mixpanel error: Add Investor To Dealflow");
  }
};

export const mixpanelAddExistingInvestorToDealflow = (name: string) => {
  try {
    Mixpanel.track("Add Existing Investor To Dealflow", {
      name: name,
    });
  } catch {
    console.log("Mixpanel error: Add Existing Investor To Dealflow");
  }
};

// boards

export const mixpanelCreateBoard = (
  selectedCompanies: any[],
  dataKey: string
) => {
  const companies = selectedCompanies.map((company) => company.displayName);

  if (dataKey === "createStartupBoard") {
    try {
      Mixpanel.track("Create Board", {
        companies: companies,
      });
    } catch {
      console.log("Mixpanel error: Click Create Board");
    }
  }
};

// AIA
export const mixpanelAiaUpload = (user: any, slug?: string) => {
  try {
    Mixpanel.track("AIA Upload", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
      companyUploaded: slug,
    });
  } catch (error) {
    console.error("Mixpanel error: AIA Upload");
  }
};

export const mixpanelAiaAddContext = (user: any) => {
  try {
    Mixpanel.track("AIA Add Context", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Add Context");
  }
};

export const mixpanelAiaChat = (user: any) => {
  try {
    Mixpanel.track("AIA Chat", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Chat");
  }
};

export const mixpanelShareAiaProfile = (user: any, companyName: string) => {
  try {
    Mixpanel.track("AIA Share Profile", {
      sharedProfile: companyName,
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Share Profile");
  }
};

export const mixpanelAiaDocSendUpload = (user: any, slug?: string) => {
  try {
    Mixpanel.track("AIA DocSend Upload", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
      companyUploaded: slug,
    });
  } catch (error) {
    console.error("Mixpanel error: DocSend Upload");
  }
};

export const mixpanelAiaFetchMoreCompetitors = (user: any) => {
  try {
    Mixpanel.track("AIA Fetch More Competitors", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Fetch More Competitors");
  }
};

export const mixpanelAiaDownloadProfile = (user: any, companyName: string) => {
  try {
    Mixpanel.track("AIA Download Profile", {
      downloadedProfile: companyName,
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Download Profile");
  }
};

export const mixpanelAiaGenerateEmail = (user: any) => {
  try {
    Mixpanel.track("AIA Generate Email", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Generate Email");
  }
};

export const mixpanelAiaUploadWebsiteAsContext = (
  user: any,
  companySlug: string
) => {
  try {
    Mixpanel.track("AIA Website As Context", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
      companyUploaded: companySlug,
    });
  } catch (error) {
    console.error("Mixpanel error: Upload Website As Context");
  }
};

export const mixpanelAiaDeepDive = (user: any, competitors: string[]) => {
  try {
    Mixpanel.track("AIA Deep Dive", {
      competitors: competitors,
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
    });
  } catch (error) {
    console.error("Mixpanel error: Deep Dive");
  }
};

// Edit AIA profile

export const mixpanelEditAiaProfile = (
  user: any,
  startupId: string,
  fieldName: string
) => {
  try {
    Mixpanel.track("Edit AIA Profile", {
      name: user?.firstName + " " + user?.lastName,
      investor: user?.displayName,
      email: user?.email,
      startupId,
      fieldName,
    });
  } catch (error) {
    console.error("Mixpanel error: Edit AIA Profile");
  }
};
