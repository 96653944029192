// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import {
  triggerCompanyContactRequestModalData,
  triggerInvestorContactRequestModalData,
} from "./reducer";
import {
  triggerCompanyContactRequestModal,
  triggerInvestorContactRequestModal,
} from "./sagaActions";

export function* handleCompanyContactRequestUpdate({
  payload: { isEnabled, data },
}) {
  yield put(triggerCompanyContactRequestModalData({ isEnabled, data }));
}

export function* handleInvestorContactRequestUpdate({
  payload: { isEnabled, data },
}) {
  yield put(triggerInvestorContactRequestModalData({ isEnabled, data }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(
      triggerCompanyContactRequestModal,
      handleCompanyContactRequestUpdate
    ),
    yield takeEvery(
      triggerInvestorContactRequestModal,
      handleInvestorContactRequestUpdate
    ),
  ];
}
