import { FC } from "react";
import cn from "classnames";
import { ProductVisualsNavProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProductVisualsNav: FC<ProductVisualsNavProps> = ({
  direction,
  isModal,
  onClick,
}: ProductVisualsNavProps): JSX.Element => (
  <div
    onClick={onClick}
    className={cn(
      "absolute flex justify-center items-center bg-white opacity-50 hover:opacity-70 cursor-pointer top-0 h-full w-10 group ",
      {
        "left-0 rounded-l-md ": direction === "PREV",
        "right-0 rounded-r-md": direction === "NEXT",
      }
    )}
  >
    <div
      className={cn(
        "transition ease-in translate-none md:group-hover:-translate-x-1 in",
        {
          "md:group-hover:-translate-x-1": direction === "PREV",
          "md:group-hover:translate-x-1": direction === "NEXT",
        }
      )}
    >
      {direction === "PREV" && (
        <FontAwesomeIcon
          icon={["fal", "chevron-left"]}
          size="2x"
          className="text-black"
        />
      )}
      {direction === "NEXT" && (
        <FontAwesomeIcon
          icon={["fal", "chevron-right"]}
          size="2x"
          className="text-black"
        />
      )}
    </div>
  </div>
);

export default ProductVisualsNav;
