// @ts-nocheck
import { forwardRef, useEffect } from "react";
import cn from "classnames";
import Autocomplete from "react-google-autocomplete";
import { googleMapApiKey } from "core/config";

export default forwardRef(
  (
    { icon, error, label, getPlace, labelClass, variant, ...props }: any,
    ref
  ) => {
    useEffect(() => {
      document.getElementById("headquartedAt").autocomplete = "off";
    }, []);
    const handleGetPlace = (place) => {
      var country, state, city;
      place?.address_components.forEach((address) => {
        if (address.types[0] === "country") {
          country = address.long_name;
        } else if (address.types[0] === "administrative_area_level_1") {
          state = address.long_name;
        } else if (address.types[0] === "administrative_area_level_2") {
          city = address.long_name;
        }
      });
      getPlace({
        country,
        state,
        city,
        autoCompleteVal: place.formatted_address,
        fullAddress: place.formatted_address,
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
      });
    };

    return (
      <div className="w-full">
        <label
          className={cn(
            "text-[#000] font-medium text-opacity-100 block mb-1",
            labelClass
          )}
        >
          {label}
        </label>
        <div
          className={cn(
            "outline-none form-input flex w-full transition duration-150 ease-in-out sm:leading-5  text-gray-700 shadow-sm border",
            variant === "rect"
              ? "py-3 rounded-md border-black border-opacity-25"
              : "py-1 rounded-xl  border-gray-200",
            error
              ? "border-red-300 text-red-900 placeholder-red-300"
              : "text-gray-700"
          )}
        >
          {icon && icon}
          <Autocomplete
            {...props}
            autoComplete="off"
            id="headquartedAt"
            className={`w-full outline-none, ${
              variant === "rect" ? "pl-2" : "pl-0"
            }`}
            language="en"
            apiKey={googleMapApiKey}
            ref={ref}
            onPlaceSelected={(place) => handleGetPlace(place)}
          />
        </div>
        {error && <p className="mt-1 mb-3 text-sm text-fire-400">{error}</p>}
      </div>
    );
  }
);
