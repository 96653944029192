// @ts-nocheck
import {
  UseFormRegister,
  RegisterOptions,
  FieldValues,
  Path,
  DeepMap,
  FieldError,
} from "react-hook-form";
import { ReactComponent as CloseIcon } from "images/close-icon.svg";
import Tooltip from "atoms/Tooltip/Tooltip";
import { useLang } from "core/lang/LangaugeSetting";

type TractionNoteProps<T> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  rules?: RegisterOptions;
  errors?: Partial<DeepMap<T, FieldError>>;
  placeholder?: string;
  defaultValue?: string;
  hideNote: () => void;
};

const TractionNote = <T extends FieldValues>({
  name,
  register,
  rules,
  errors,
  placeholder,
  defaultValue,
  hideNote,
}: TractionNoteProps<T>) => {
  const errorMessages = _.get(errors, name);
  const hasError = !!(errors && errorMessages);
  const { lang } = useLang();
  return (
    <>
      <div className="w-4/5">
        <input
          className={`py-3 outline-none form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border border-black border-opacity-25${
            hasError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
              : ""
          }`}
          id="activeUsersNote"
          type={"text"}
          placeholder={placeholder}
          {...register(name, rules)}
          defaultValue={defaultValue}
          style={{ outline: "none", paddingLeft: 12 }}
        />
      </div>
      <div className="flex items-center w-1/5 ml-3">
        <Tooltip content={lang.removeNote}>
          <CloseIcon className="cursor-pointer" onClick={hideNote} />
        </Tooltip>
      </div>
    </>
  );
};

export default TractionNote;
