import { TooltipRenderProps } from "react-joyride";

export const Tooltip: React.FC<TooltipRenderProps> = ({
  step,
  tooltipProps,
  primaryProps,
  skipProps,
  backProps,
  isLastStep,
  index,
  size,
}) => (
  <div
    {...tooltipProps}
    className={
      index === 0 && step.title === "dealflowActivationFirstStep"
        ? "mt-6"
        : undefined
    }
  >
    <div className="overflow-hidden rounded-md">
      {step.content}

      <div className="text-white text-sm bg-gradient-to-r from-[#18BEB3] to-[#05999F] flex flex-row justify-between items-center p-5">
        <button
          className="px-6 py-2 border border-white rounded-lg cursor-pointer"
          {...(index === 0 ? skipProps : backProps)}
        >
          {index === 0 ? "Skip" : "Back"}
        </button>

        <div>
          <span>
            {index + 1}/{size}
          </span>
          <button
            className="px-6 py-2 ml-2 bg-white rounded-lg cursor-pointer text-aqua-500"
            {...primaryProps}
          >
            {isLastStep ? "Done" : "Next"}
          </button>
        </div>
      </div>
    </div>
  </div>
);
