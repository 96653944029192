// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const triggerCompanyContactRequestModal = createAction(
  "organisms/sagaActions/contactRequestModal/company",
  (payload) => ({ payload })
);

export const triggerInvestorContactRequestModal = createAction(
  "organisms/sagaActions/contactRequestModal/investor",
  (payload) => ({ payload })
);
