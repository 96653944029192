import { gql } from "@apollo/client";
import { graphql } from "gql";

export const GET_DEALFLOW = gql`
  query getStartupDealflow {
    dealflow {
      id
      columns {
        title
        key
      }
      sections {
        id
        title
        order
      }
    }
  }
`;

export const GET_DEALFLOW_RECORDS = gql`
  query getStartupDealflowRecords(
    $page: Int
    $query: String
    $sort: SortConfig
    $sections: [ID]
    $investorTypes: [InvestorType]
  ) {
    dealflow {
      id
      records(
        page: $page
        query: $query
        sort: $sort
        sections: $sections
        investorTypes: $investorTypes
      ) {
        total
        page
        perPage
        nodes {
          id
          entryDate
          isManual
          company {
            id
            _id
            slug
            currency
            displayName
            createdAt
            country
            logo {
              small {
                location
              }
            }
            ... on Investor {
              investorType
              supportAsAdvisor
              industries
              investmentStage
              businessModels
              investmentRanges {
                min
                max
              }
            }
          }
          status {
            id
            _id
          }
        }
      }
    }
  }
`;

export const ADD_CUSTOM_INVESTOR_TO_DEALFLOW = gql`
  mutation addCustomInvestorToDealflow(
    $name: String!
    $investorType: InvestorType!
    $location: CompanyPlace
    $investmentRanges: InvestmentRangesInput
    $investmentStage: [InvestmentStage!]
    $businessModels: [BusinessModel!]
    $industries: [IndustryCategory!]
    $supportAsAdvisor: Boolean!
  ) {
    addCustomInvestorToDealflow(
      name: $name
      investorType: $investorType
      location: $location
      investmentRanges: $investmentRanges
      investmentStage: $investmentStage
      businessModels: $businessModels
      industries: $industries
      supportAsAdvisor: $supportAsAdvisor
    )
  }
`;

export const EDIT_CUSTOM_INVESTOR = gql`
  mutation editCustomInvestor(
    $recordId: ID!
    $investorType: InvestorType!
    $location: CompanyPlace
    $investmentRanges: InvestmentRangesInput
    $investmentStage: [InvestmentStage!]
    $businessModels: [BusinessModel!]
    $industries: [IndustryCategory!]
    $supportAsAdvisor: Boolean!
  ) {
    editCustomInvestor(
      recordId: $recordId
      investorType: $investorType
      location: $location
      investmentRanges: $investmentRanges
      investmentStage: $investmentStage
      businessModels: $businessModels
      industries: $industries
      supportAsAdvisor: $supportAsAdvisor
    )
  }
`;

export const SEARCH_INVESTORS_TO_ADD = graphql(`
  query investorsToAdd($query: String) {
    investorsToAdd(query: $query) {
      id
      name
      logo {
        small {
          location
        }
      }
    }
  }
`);
