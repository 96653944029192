// @ts-nocheck
import { takeEvery, takeLatest, put, select, delay } from "redux-saga/effects";
import { INVITATION } from "./constants";
import { acceptInvitation, initializeInvitationPage } from "./sagaActions";
import { getClient, History } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { getUser } from "models/user/selectors";
import { clearInvitationData, updateInvitationData, createIsAdminError } from "./reducer";
import {
  GET_INVITATION_BY_SLUG,
  ACCEPT_INVITATION,
} from "queries/general/invitation";
import { loginUser } from "models/auth/sagaActions";

const client = getClient();

const fetchInvitation = async (slug: string) => {
  try {
    return await client.query({
      query: GET_INVITATION_BY_SLUG,
      variables: { slug },
      fetchPolicy: "network-only",
    });
  } catch (error) {
    console.log(error);
    return { isError: true, error };
  }
};

function* handleInitializeInvitationPage({ payload: { invitationSlug } }) {
  yield put(
    toggleLoader({
      component: INVITATION,
      isLoading: true,
    })
  );

  const { data, isError, error } = yield fetchInvitation(invitationSlug);
  const user = yield select((state) => getUser(state));

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message:
          "We could not fetch the invitation data, please try again later",
      })
    );
  } else {
    yield put(
      updateInvitationData({
        invitationId: data?.getInvitationBySlug?.id,
        invitationSlug: data?.getInvitationBySlug?.slug,
        invitationStatus: data?.getInvitationBySlug?.status,
        invitateesEmail: data?.getInvitationBySlug?.email,
        inviterCompanyName: data?.getInvitationBySlug?.creator?.inviter?.name,
        inviterCompanyLogo:
          data?.getInvitationBySlug?.creator?.inviter?.logo?.small?.location,
      })
    );
  }

  if (user) {
    // User is logged in, thus she/he has an existing account, ignore invitation and redirect to root route.
    History.navigate("/");
  } else {
    // User is not logged in, redirect to login/invitation page, include invitation data from redux if such data was found in Redux.
    History.navigate("/login/invitation/" + invitationSlug);
  }

  yield put(
    toggleLoader({
      component: INVITATION,
      isLoading: false,
    })
  );
}

const doAcceptInvitation = async (input) => {
  try {
    return await client.mutate({
      mutation: ACCEPT_INVITATION,
      fetchPolicy: "network-only",
      variables: { input },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

function* handleAcceptInvitation({
  payload: {
    invitationSlug,
    email,
    password,
    policiesAndTermsAgreed,
    rememberMe,
  },
}) {
  yield put(
    toggleLoader({
      component: ACCEPT_INVITATION,
      isLoading: true,
    })
  );

  const { data, isError, error } = yield doAcceptInvitation({
    email,
    password,
    invitationSlug,
    policiesAndTermsAgreed,
  });

  if (isError) {
    if(error?.graphQLErrors[0].extensions.code === "ALREADY_ADMIN") {
      yield put(createIsAdminError())
    } else {
      yield put(
        triggerSnack({
          type: "error",
          title: "Something went wrong",
          message: "Invitation could not be accepted. " + error.message,
        })
      );
      
    }

    yield put(
      toggleLoader({
        component: ACCEPT_INVITATION,
        isLoading: false,
      })
    );

    return;
  }

  yield put(
    triggerSnack({
      type: "success",
      title: "Invitation accepted successfully",
      message: "Invitation accepted successfully",
    })
  );

  yield put(
    loginUser({
      formValues: {
        email,
        password,
      },
      rememberMe,
    })
  );

  // If invitaion data is cleared immediately, user might see a flash of not-found page
  yield delay(3000);

  yield put(clearInvitationData());

  // Switch workspaces here when we implement user being member of multiple companies

  yield put(
    toggleLoader({
      component: ACCEPT_INVITATION,
      isLoading: false,
    })
  );
}

export default function* rootSaga() {
  return [
    yield takeLatest(initializeInvitationPage, handleInitializeInvitationPage),
    yield takeEvery(acceptInvitation, handleAcceptInvitation),
  ];
}
