import { useDispatch, useSelector } from "react-redux";
import { CompanyStep } from "ui/elements/PanelLayout.styles";
import { updateCurrentStep } from "../../pages/companySettings/reducer";
import { getCurrentStep } from "../../pages/companySettings/selectors";

const Step = (props: any) => {
  const dispatch = useDispatch();
  const { name, title, status } = props;
  const currentStep = useSelector(getCurrentStep);
  const isCurrent = currentStep === name;

  const setStep = () => {
    dispatch(updateCurrentStep({ currentStep: name }));
  };

  return (
    <CompanyStep
      current={isCurrent}
      status={status}
      onClick={setStep}
      className={isCurrent ? "current-phase active" : "current-phase"}
    >
      <span className="font-medium truncate __hover__orange">{title}</span>
    </CompanyStep>
  );
};

export default Step;
