import type { FC } from "react";
import type { ApplicationLinkModalProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApplicationLinkModal } from "./hooks";
import Modal from "atoms/Modal/Modal";
import ImageSelect from "organisms/ImageSelect/ImageSelect";
import FormInput from "atoms/Form/FormInput";
import TextArea from "atoms/Form/TextArea";
import Button from "atoms/Button/Button";
import Loading from "atoms/Loading/Loading";
import CustomModal from "atoms/Modal/CustomModal";
import slugify from "slugify";
import { Controller } from "react-hook-form";
import WYSIWYGEditor from "./WYSIWYGEditor";

const ApplicationLinkModal: FC<ApplicationLinkModalProps> = (props) => {
  const {
    loading,
    isEdit,
    isClosed,
    title,
    applicationBaseUrl,
    logoMaxWidth,
    logoMaxHeight,
    slug,
    logoPreview,
    posterPreview,
    isConfirmation,
    register,
    handleLogoChange,
    handlePosterChange,
    handleImageError,
    handleSubmit,
    handleCancel,
    handleCloseConfirmation,
    control,
  } = useApplicationLinkModal(props);

  return (
    <Modal
      width="75rem"
      onClose={handleCancel}
      hideCloseButton
      // @TODO: redesign modal component in order to get rid of such things
      style={{
        maxHeight: "95vh",
        overflowY: "auto",
      }}
    >
      <CustomModal
        openModal={isConfirmation}
        handleCloseModal={handleCloseConfirmation}
      >
        <p className="my-6">
          Are you sure you want to cancel creation of this cohort link?
        </p>
        <div className="flex justify-end">
          <Button color="white" onClick={props.onClose} className="mr-4">
            Yes
          </Button>
          <Button color="primary" onClick={handleCloseConfirmation}>
            No
          </Button>
        </div>
      </CustomModal>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl">{title}</h2>
          {loading && <Loading size="medium" />}
        </div>
        {isClosed && (
          <p className="mb-4 text-gray-600">
            This view will guide users that access your link after you have
            closed it.
          </p>
        )}
        <div className="flex flex-wrap md:flex-nowrap">
          <div className="w-full pb-8 mr-4 md:w-1/2">
            <ImageSelect
              imageDesc="Upload your logo"
              onError={handleImageError}
              onChange={handleLogoChange}
              fullWidth
            >
              {logoPreview && (
                <div className="flex items-center">
                  <img
                    src={logoPreview}
                    style={{
                      height: "max-content",
                      maxWidth: logoMaxWidth,
                      maxHeight: logoMaxHeight,
                    }}
                    alt=""
                  />
                </div>
              )}
            </ImageSelect>
            <div className="mt-4">
              <FormInput
                {...register("name")}
                disabled={isClosed}
                placeholder="E.g Blockchain 2022"
                label="Name your application"
                labelClassName="font-bold"
              />
            </div>
            {!isClosed && (
              <>
                <div className="mt-4">
                  <FormInput
                    {...register("header")}
                    placeholder="E.g Apply to our 2022 program"
                    label="Application Header"
                    labelClassName="font-bold"
                  />
                </div>
                <div className="w-full mt-4">
                  <label className="block h-full mb-1 font-bold text-black text-opacity-100">
                    Description
                  </label>

                  <Controller
                    render={({ field }) => (
                      <WYSIWYGEditor
                        {...field}
                        placeholder="Enter a short description about the application process"
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </div>
              </>
            )}
            {isClosed && (
              <>
                <div className="mt-4">
                  <FormInput
                    {...register("closedHeader")}
                    placeholder="E.g Our application is closed"
                    label="Application Header"
                    labelClassName="font-bold"
                  />
                </div>
                <div className="w-full mt-4">
                  <label
                    htmlFor="link-closed-description"
                    className="block h-full mb-1 font-bold text-black text-opacity-100"
                  >
                    Description
                  </label>
                  <TextArea
                    {...register("closedDescription")}
                    id="link-closed-description"
                    rows="8"
                    placeholder="Enter a short description about the closed application and when they can apply again"
                    className="w-full border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
                  />
                </div>
              </>
            )}
            <div>
              <FormInput
                {...register("slug", {
                  onChange: (event) => {
                    event.target.value = slugify(event.target.value, {
                      trim: false,
                      lower: true,
                    });
                  },
                })}
                label="Choose a link"
                labelClassName="font-bold"
                prefix={applicationBaseUrl}
                disabled={isEdit}
                icon={
                  <FontAwesomeIcon
                    icon={["fas", "link"]}
                    className="text-gray-400"
                  />
                }
              />
            </div>
            {(!isEdit || isClosed) && (
              <div
                className={`mt-4 p-3 flex items-center text-sm rounded-md ${slug.className}`}
              >
                <FontAwesomeIcon icon={["fas", slug.icon]} className="mr-2" />
                <span>{slug.text}</span>
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 h-80 md:h-auto">
            <ImageSelect
              id="cover-file"
              imageDesc="Drop your cover photo here"
              fullHeight
              onChange={handlePosterChange}
              onError={handleImageError}
            >
              {posterPreview ? (
                <img
                  src={posterPreview}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt=""
                />
              ) : (
                <div className="flex flex-col items-center justify-center">
                  <span className="text-xl bold">
                    Drop you cover photo here
                  </span>
                  <span>
                    You can also{" "}
                    <label
                      className="border-b-2 border-gray-400 cursor-pointer"
                      htmlFor="cover-file"
                    >
                      browse your computer
                    </label>{" "}
                    for photos
                  </span>
                </div>
              )}
            </ImageSelect>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            color="white"
            className="mr-4"
            type="button"
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button loading={loading} color="primary" type="submit">
            SAVE
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ApplicationLinkModal;
