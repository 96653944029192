import { FC } from "react";
import { useDispatch } from "react-redux";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import { updateStartupProfile } from "../../sagaActions";

interface Props {
  logoPreview: string | undefined | null;
}

const Logo: FC<Props> = ({ logoPreview }) => {
  const dispatch = useDispatch();

  const onComplete = (data: File) => {
    dispatch(updateStartupProfile({ logo: data }));
  };

  return (
    <div className="m-2">
      <ProfilePictureSelect
        label="Upload logo"
        onComplete={onComplete}
        preview={logoPreview || undefined}
      />
    </div>
  );
};

export default Logo;
