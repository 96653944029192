// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const saveBasicInfo = createAction(
  "companySettings/basicInfo/save",
  (payload) => ({
      payload,
    })
);

export const getBasicInfo = createAction("companySettings/basicInfo/fetch");
