import { FC } from "react";

export interface ValueProps {
  content: string;
}

export const Value: FC<ValueProps> = ({ content }) => {
  return (
    <div className="inline-block whitespace-pre-wrap capitalize text-gray-600">
      {content}
    </div>
  );
};
