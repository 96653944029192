// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  contactMessages: {
    messages: [],
    searchCount: 0,
    totalCount: 0,
  },
};

export const name = "messages";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateContactMessages: (state, { payload }) => {
      state.contactMessages = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const { updateContactMessages } = slice.actions;
