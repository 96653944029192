import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "atoms/Button/Button";
import EmbedVideo from "atoms/EmbedVideo";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import ViewIdentification from "organisms/ViewIdentification/ViewIdentification";
import ContactRequestModal from "organisms/ContactRequestModal/ContactRequestModal";
import { getUser } from "models/user/selectors";
import { getCountryCode } from "utils/generalUtils";
import AddMeetingLinkModal from "../shared/Navbar/AddMeetingLinkModal";
import ViewSettings from "../shared/Navbar/ViewSettings";
import PitchDeck from "../shared/Navbar/PitchDeck";
import GeneralInfo from "../shared/About/GeneralInformation";

interface HeroProps {
  company: any;
  // TODO: type company properly
  // company: {
  //   id: string;
  //   _id: string;
  //   slug: string;
  //   country: string;
  //   city: string;
  //   displayName: string;
  //   logo: {
  //     large: {
  //       location: string;
  //     };
  //   };
  //   meetingLink: string;
  //   pitchVideoLink: string;
  //   publicUrl: string;
  //   email: string;
  //   pitch?: string;
  //   documents?: any[];
  // };
  currencyCode: any;
}

const Hero_Regular: FC<HeroProps> = ({ company, currencyCode }) => {
  /* Other hooks */
  const navigate = useNavigate();

  /* Selectors */
  const user = useSelector(getUser);
  const invitation = useSelector((state: any) => state?.invitation);

  /* States */
  const [isViewSettingsModalOpen, setIsViewSettingsModalOpen] = useState(false);
  const [isPublicShareModalOpen, setIsPublicShareModalOpen] = useState(false);
  const [showIdentification, setShowIdentification] = useState(false);
  const [isAddMeetingLinkModalOpen, setIsAddMeetingLinkModalOpen] =
    useState(false);
  const [isContactRequestModalOpen, setIsContactRequestModalOpen] =
    useState(false);

  /* Handlers */
  const isMe = user && user._id === company._id;

  const openMeetingLink = () => {
    window.open(company?.meetingLink, "_blank");
  };

  const goToSettings = () => {
    navigate("/edit-startup-profile");
  };

  const handleIdentification = (isIdentified: boolean) => {
    if (isIdentified) {
      openMeetingLink();
    }
    setShowIdentification(false);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 p-6 pb-2 mx-auto lg:gap-x-4 bg-gray-50 border border-aqua-100 rounded-lg my-4">
      <div className="flex flex-col">
        <div className="flex flex-col sm:flex-row justify-betweeen gap-4 sm:mb-2">
          <div className="">
            <img
              src={
                company?.logo?.large?.location ||
                "https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png"
              }
              alt={`${company?.displayName} logo`}
              className="shadow-xl h-[8rem] md:h-[10rem] min-w-[8rem] md:min-w-[10rem] mx-auto rounded-full object-cover md:outline md:outline-white/50 dark:md:outline-white/20 md:outline-[10px] md:outline-offset-[-9px]"
            />
          </div>
          <div className="my-auto lg:ml-8 md:px-0 text-center sm:text-left">
            <h2 className="text-[#000] dark:text-white/80 md:text-5xl">
              {company?.displayName}
            </h2>
            {company?.country && (
              <div className="pr-4 mr-4">
                <ReactCountryFlag
                  countryCode={getCountryCode(company?.country)}
                  svg={true}
                  className="mb-0.5 mr-2"
                />
                <span className="text-sm font-normal dark:text-white/80">
                  {company?.city}
                  {company?.city && ","} {company?.country}
                </span>
              </div>
            )}
            {invitation?.inviterCompanyName && (
              <p className="text-base text-[#292F2E]">
                Invited by "{invitation?.inviterCompanyName}"
              </p>
            )}
            {isMe && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsPublicShareModalOpen(true);
                }}
                color="primary"
                className="justify-center w-full mt-2 md:w-auto md:mt-2"
                size="small"
              >
                <FontAwesomeIcon
                  icon={["fas", "share-alt"]}
                  className="mr-2 text-white"
                />
                <span>Share</span>
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col my-2 sm:my-2 sm:flex-row sm:gap-2 flex-wrap">
          {!isMe && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setIsPublicShareModalOpen(true);
              }}
              color="primary"
              className="justify-center w-full md:w-auto"
              size="small"
            >
              <FontAwesomeIcon
                icon={["fas", "share-alt"]}
                className="mr-2 text-white"
              />
              <span>Share</span>
            </Button>
          )}

          {isMe && (
            <Button
              onClick={() => {
                setIsAddMeetingLinkModalOpen(true);
              }}
              color="transparent"
              className="flex justify-center w-full mt-2 md:w-auto md:mt-0"
              size="small"
            >
              <FontAwesomeIcon
                icon={["fal", "calendar"]}
                className="mr-2 mb-0.5"
              />
              <span className="truncate whitespace-nowrap">
                {user.meetingLink ? "Edit" : "Setup"} scheduling link
              </span>
            </Button>
          )}

          {!isMe && company.meetingLink && (
            <Button
              onClick={() => {
                setShowIdentification(true);
              }}
              className="flex items-center justify-center w-full mt-2 md:w-auto md:mt-0"
              color="transparent"
              size="small"
            >
              <FontAwesomeIcon className="mr-2" icon={["fal", "calendar"]} />
              <span className="whitespace-nowrap">Book a meeting</span>
            </Button>
          )}

          {!isMe && (
            <Button
              onClick={() => setIsContactRequestModalOpen(true)}
              color="transparent"
              className="flex justify-center w-full mt-2 md:w-auto md:mt-0"
              size="small"
            >
              <FontAwesomeIcon icon={["fal", "comment-alt"]} className="mr-2" />
              <span>Contact</span>
            </Button>
          )}

          {isMe && (
            <Button
              onClick={goToSettings}
              color="transparent"
              className="flex justify-center w-full mt-2 md:w-auto md:mt-0"
              size="small"
            >
              <FontAwesomeIcon icon={["fas", "pencil-alt"]} className="mr-2" />
              <span className="whitespace-nowrap">Edit Profile</span>
            </Button>
          )}

          {isMe && (
            <Button
              onClick={() => setIsViewSettingsModalOpen(true)}
              color="transparent"
              className="flex justify-center w-full mt-2 md:w-auto md:mt-0"
              size="small"
            >
              <FontAwesomeIcon icon={["fas", "user-lock"]} className="mr-2" />
              <span className="whitespace-nowrap">Page settings</span>
            </Button>
          )}
        </div>
        <div className="general-info-container hidden px-1 pt-2 pb-4 md:block">
          <GeneralInfo
            website={company?.website}
            industries={company?.industries}
            city={company?.city}
            fullAddress={company?.fullAddress}
            businessCategory={company?.businessCategory}
            businessModel={company?.businessModel}
            founded={company?.founded}
            numberOfEmployees={company?.teamSize}
            investors={company?.investors}
            founders={company?.founders !== null && company?.founders[0]}
            raisedValue={company?.finance?.raisedValue}
            raisedFrom={company?.finance?.raisedFrom}
            companyStage={company?.companyStage}
            socials={company?.socials}
            businessTypeNote={company?.businessTypeNote}
            businessCategoryNote={company?.businessCategoryNote}
            currencyCode={currencyCode}
          />
        </div>
      </div>

      <div className="flex flex-col">
        {company?.pitchVideoLink && (
          <div
            id={"iframe"}
            className="w-full relative rounded-md inline-block mt-4 sm:my-0 mx-auto lg:mx-0 grow border border-aqua-100"
          >
            <EmbedVideo
              className="rounded-md"
              source={company?.pitchVideoLink}
            />
          </div>
        )}
        {company?.documents && company?.documents.length > 0 && (
          <PitchDeck
            documents={company?.documents}
            hidePreview={company?.pitchVideoLink}
            containerClassName={company?.pitchVideoLink ? "" : "flex-grow"}
          />
        )}
      </div>

      {/* ========== Modals ========== */}
      {isPublicShareModalOpen && (
        <PublicShare
          title={company?.displayName}
          publicUrl={company?.publicUrl}
          onClose={() => setIsPublicShareModalOpen(false)}
        />
      )}

      {isContactRequestModalOpen && (
        <ContactRequestModal
          id={company.id}
          skipSuccessModal
          onClose={() => setIsContactRequestModalOpen(false)}
          recieverEmail={company?.email}
        />
      )}

      {isMe && (
        <AddMeetingLinkModal
          isOpen={isAddMeetingLinkModalOpen}
          onClose={() => {
            setIsAddMeetingLinkModalOpen(false);
          }}
          meetingLink={company.meetingLink}
        />
      )}

      {isMe && (
        <ViewSettings
          isOpen={isViewSettingsModalOpen}
          onClose={() => {
            setIsViewSettingsModalOpen(false);
          }}
        />
      )}

      {showIdentification && (
        <ViewIdentification
          onFinish={handleIdentification}
          startupSlug={company?.slug}
          isMeetingRequest
        >
          <p>
            The owner of "{company?.displayName}" has restricted access to their
            profile.
          </p>

          <p>
            Please introduce yourself in order to get their <b>meeting link</b>.
          </p>
        </ViewIdentification>
      )}
    </div>
  );
};
export default Hero_Regular;
