import PureHtml from "atoms/PureHtml";
import { FieldName } from "gql/graphql";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import React, { FC } from "react";

// Wrapper component props
interface Props {
  content: string;
}

const FiveQuestions: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div className="p-4 bg-gray-100 mt-4 rounded-lg container mx-auto">
      <h6 className="font-semibold mb-2">5 questions to ask the founders</h6>
      <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-base aiaProfile">
        <PureHtml content={content} />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(
  FiveQuestions,
  FieldName.FiveQuestions
);
