import { gql } from "@apollo/client";

export const GET_STARTUP_PROFILE_DATA = gql`
  query getStartupProfileData {
    me {
      user {
        id
        email
        firstName
        lastName
        isAdmin
      }
      company {
        id
        type
        slug
        name
        video
        website
        meetingUrl
        location {
          city
          state
          country
          lat
          long
          fullAddress
        }
        socials {
          linkedin
          youtube
          facebook
          instagram
          twitter
        }
        documents {
          id
          title
          file {
            filename
            location
          }
          filename
        }
        pitch {
          text
          link
        }
        logo {
          large {
            location
          }
        }
        founders {
          id
          name
          gender
          title
          linkedin
          twitter
          instagram
          email
          displayName
          logo
          image {
            small {
              location
            }
          }
          previouslyFounded
          successfulExits
        }
        investors {
          id
          name
          gender
          title
          linkedin
          twitter
          instagram
          email
          displayName
          logo
        }
        investment {
          industries
          stages
        }
        team {
          members {
            id
            name
            gender
            title
            linkedin
            twitter
            instagram
            email
            displayName
            logo
            image {
              small {
                location
              }
            }
          }
          note
        }
        fundraising {
          target
          currency
          valuation
          ticketSize
          raised
          raisedFrom
          phase
          committed
          closeOn
          beyondCapital
        }
        product {
          description
          demo
          images {
            id
            large {
              location
            }
          }
        }
        business {
          marketOpp
          description
          timing
          industries
          categories
          categoriesNote
          models
          modelsNote
          sdg {
            items
            comment
          }
        }
        traction {
          summary
          activeUsers {
            amount
            frequency
          }
          activeUsersNote
          totalUsers {
            count
            type
          }
          totalUsersNote
          growthRate {
            period
            amount
            rate
            time
          }
          growthRateNote
          retentionRate {
            period
            amount
            rate
            time
          }
          retentionRateNote
          churnRate {
            period
            percentage
          }
          churnRateNote
          salePriceAvg {
            amount
            currency
          }
          salePriceAvgNote
          netRevenue {
            time
            amount
            currency
          }
          netRevenueNote
          recurringRevenue {
            time
            amount
            currency
          }
          recurringRevenueNote
          revenueRate {
            period
            amount
            rate
            time
          }
          revenueRateNote
          smv_gsv {
            time
            amount
            currency
          }
          smv_gsvNote
          ratePercentage
          ratePercentageNote
          cacPayback {
            amount
            frequency
          }
          cacPaybackNote
          ltcCacRatio
          ltcCacRatioNote
          demandFreq {
            unit
            amount
            frequency
          }
          demandFreqNote
          supplyFreq {
            unit
            amount
            frequency
          }
          supplyFreqNote

          signedContracts
          signedContractsNote
          annualValueContracts {
            amount
            currency
          }
          annualValueContractsNote
          pipelineContracts
          pipelineContractsNote
          annualValueContractsPipeline {
            amount
            currency
          }
          annualValueContractsPipelineNote
          lettersOfIntent
          lettersOfIntentNote
          additionalTraction
        }
      }
    }
  }
`;

export const UPDATE_USER_DATA = gql`
  mutation updateUserDate($input: MeUserInput!) {
    me {
      user(input: $input) {
        id
        email
        firstName
        lastName
        isAdmin
      }
    }
  }
`;

export const UPDATE_STARTUP_PROFILE_DATA = gql`
  mutation updateStartupProfileData($input: MeCompanyInput!) {
    me {
      company(input: $input) {
        id
        type
        slug
        name
        video
        website
        meetingUrl
        location {
          city
          state
          country
          lat
          long
          fullAddress
        }
        socials {
          linkedin
          youtube
          facebook
          instagram
          twitter
        }
        documents {
          id
          title
          file {
            filename
            location
          }
          filename
        }
        pitch {
          text
          link
        }
        logo {
          large {
            location
          }
        }
        founders {
          id
          name
          gender
          title
          linkedin
          twitter
          instagram
          email
          displayName
          logo
          image {
            small {
              location
            }
          }
          previouslyFounded
          successfulExits
        }
        investors {
          id
          name
          gender
          title
          linkedin
          twitter
          instagram
          email
          displayName
          logo
        }
        investment {
          industries
          stages
        }
        team {
          members {
            id
            name
            gender
            title
            linkedin
            twitter
            instagram
            email
            displayName
            logo
            image {
              small {
                location
              }
            }
          }
          note
        }
        fundraising {
          target
          currency
          valuation
          ticketSize
          raised
          raisedFrom
          phase
          committed
          closeOn
          beyondCapital
        }
        product {
          description
          demo
          images {
            id
            large {
              location
            }
          }
        }
        business {
          marketOpp
          description
          timing
          industries
          categories
          categoriesNote
          models
          modelsNote
          sdg {
            items
            comment
          }
        }
        traction {
          summary
          activeUsers {
            amount
            frequency
          }
          activeUsersNote
          totalUsers {
            count
            type
          }
          totalUsersNote
          growthRate {
            period
            amount
            rate
            time
          }
          growthRateNote
          retentionRate {
            period
            amount
            rate
            time
          }
          retentionRateNote
          churnRate {
            period
            percentage
          }
          churnRateNote
          salePriceAvg {
            amount
            currency
          }
          salePriceAvgNote
          netRevenue {
            time
            amount
            currency
          }
          netRevenueNote
          recurringRevenue {
            time
            amount
            currency
          }
          recurringRevenueNote
          revenueRate {
            period
            amount
            rate
            time
          }
          revenueRateNote
          smv_gsv {
            time
            amount
            currency
          }
          smv_gsvNote
          ratePercentage
          ratePercentageNote
          cacPayback {
            amount
            frequency
          }
          cacPaybackNote
          ltcCacRatio
          ltcCacRatioNote
          demandFreq {
            unit
            amount
            frequency
          }
          demandFreqNote
          supplyFreq {
            unit
            amount
            frequency
          }
          supplyFreqNote

          signedContracts
          signedContractsNote
          annualValueContracts {
            amount
            currency
          }
          annualValueContractsNote
          pipelineContracts
          pipelineContractsNote
          annualValueContractsPipeline {
            amount
            currency
          }
          annualValueContractsPipelineNote
          lettersOfIntent
          lettersOfIntentNote
          additionalTraction
        }
      }
    }
  }
`;
