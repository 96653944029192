// @ts-nocheck
import { gql } from "@apollo/client";

export const SAVE_BASIC_INFO = gql`
  mutation saveCompanyBasics($input: StartupBasicStepInput!) {
    saveCompanyBasics(input: $input) {
      _id
      founded
      website
      country
      state
      city
      fullAddress
      lat
      long
      businessModel
      companyStage
      businessCategory
      industries
      businessCategoryNote
      businessTypeNote
      expectationsNote
    }
  }
`;

export const GET_BASIC_INFO = gql`
  query getBasicInfo {
    me {
      founded
      website
      country
      state
      city
      fullAddress
      lat
      long
      businessModel
      companyStage
      businessCategory
      industries
      businessCategoryNote
      businessTypeNote
      expectationsNote
    }
  }
`;
