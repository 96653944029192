// @ts-nocheck
import React from "react";
import { withRouter } from "hooks/withRouter";
import { animation, fade, staggerAnimation } from "ui/animations";
import {
  ErrorContainer,
  ContentSection,
} from "atoms/ServerError/ServerError.style";
import { useLang } from "core/lang/LangaugeSetting";
import Button from "atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackgroundImg from "images/modal-background.jpg";

const ServerError = (props) => {
  const { lang } = useLang();
  return (
    <ErrorContainer background={BackgroundImg}>
      <ContentSection
        variants={animation}
        animate="show"
        initial="hidden"
        className="shadow-xl"
      >
        <h3 className="text-2xl font-medium">{lang.serverErrorTitle}</h3>
        <p className="p-4 text-sm">
          {lang.serverErrorDesc}
          {/* <span>{` ${lang.contactUs}`}</span>. */}
        </p>
        <div className="w-full pb-1 text-center">
          <button
            className="px-5 py-2 refresh-btn"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon
              className="mr-2 text-aqua-400"
              icon={["fas", "sync-alt"]}
            />
            {lang.refresh}
          </button>
        </div>
        <div className="w-full text-center">
          <Button
            size="large"
            color="primary"
            onClick={() => props.navigate(-1)}
          >
            {lang.takeMeBack}
          </Button>
        </div>
      </ContentSection>
    </ErrorContainer>
  );
};
export default withRouter(ServerError);
