import Tooltip from "atoms/Tooltip/Tooltip";
import { format } from "date-fns";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import { formatCurrencyValues } from "pages/companyProfile/utils";
import { FC } from "react";
import { FundraisingOverviewProps } from "../shared/About/interfaces";

const FundraisingOverview_Regular: FC<FundraisingOverviewProps> = ({
  finance,
  valuation,
  currencyCode,
  raisedTarget,
  minTicketSize,
  fundraisingCloseOn,
  committed,
  fit,
}) => {
  return (
    <div className="w-full flex flex-wrap my-2 dark:text-white/80 mb-4 md:mb-6 gap-3">
      {raisedTarget && (
        <FundraisingItem
          title="Target"
          value={formatCurrencyValues(parseInt(raisedTarget), currencyCode)}
          tooltip={
            finance?.note ? (
              <Tooltip content={finance?.note}>
                <HelpIcon className="h-auto ml-1" />
              </Tooltip>
            ) : undefined
          }
        />
      )}

      {minTicketSize && (
        <FundraisingItem
          title="Ticket size"
          value={formatCurrencyValues(parseInt(minTicketSize), currencyCode)}
        />
      )}

      {valuation && (
        <FundraisingItem
          title="Valutation"
          value={formatCurrencyValues(parseInt(valuation), currencyCode)}
        />
      )}

      {committed && (
        <FundraisingItem
          title="Committed"
          value={formatCurrencyValues(parseInt(committed), currencyCode)}
        />
      )}

      {fundraisingCloseOn && (
        <FundraisingItem
          title="Round closing date"
          value={format(new Date(fundraisingCloseOn), "dd MMM yyyy")}
        />
      )}

      {fit && <FundraisingItem title="Desired investors" value={fit} />}
    </div>
  );
};

interface Props {
  title: string;
  value: string;
  tooltip?: JSX.Element;
}

const FundraisingItem: FC<Props> = ({ title, value, tooltip }) => {
  return (
    <div className="text-left dark:bg-neutral-800 bg-aqua-50 bg-opacity-10 border border-aqua-200 rounded-lg p-3">
      <p className="flex text-xs items-center text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
        {title}
        {tooltip}
      </p>
      <span className="text-base font-bold">{value}</span>
    </div>
  );
};

export default FundraisingOverview_Regular;
