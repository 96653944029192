import { FC, useState } from "react";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { CompanyMember, MemberStatus } from "./types";
import TeamPermission from "./TeamPermission";
import { format } from "date-fns";
import Loading from "atoms/Loading/Loading";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { GET_COMPANY_MEMBERS } from "queries/general/account/teamMembers";

interface Props {
  isCompanyAdmin: boolean;
  activeWorkspaceId: string | undefined;
  refetchRates: (variables: { companyId: string; features: string[] }) => void;
  companyId: string;
  features: string[];
}

const TeamMembersTableData: FC<Props> = ({
  isCompanyAdmin,
  activeWorkspaceId,
  companyId,
  refetchRates,
  features,
}) => {
  const {
    data,
    loading: loadingMembers,
    refetch,
  } = useQuery<{
    getCompanyMembers: CompanyMember[];
  }>(GET_COMPANY_MEMBERS, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [activeWorkspaceId]);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const [companyMemberIdToBeDeleted, setCompanyMemberIdToBeDeleted] =
    useState("");

  const onRemoveClicked = (id: string) => {
    if (!isCompanyAdmin) return;
    if (id === activeWorkspaceId) return;
    setCompanyMemberIdToBeDeleted(id);
    setIsDeleteConfirmationModalOpen(true);
  };

  if (loadingMembers) {
    return (
      <div className="my-4">
        <Loading size="medium" />
      </div>
    );
  }

  return (
    <>
      {data?.getCompanyMembers &&
        data.getCompanyMembers
          .filter((member) => member.status === MemberStatus.ACTIVE)
          .map((member) => (
            <tr className="text-sm border-b" key={member.id}>
              {/* Uncomment when user data becomes available from the api */}
              {/* <td className="flex items-center">
                <img
                  src={"/assets/images/investor-placeholder-img.png"}
                  alt="logo"
                  className="w-10 h-10 m-2 rounded-full"
                />
                <span>Alexis</span>
              </td> */}
              <td className="p-2 font-normal text-left text-gray-400">
                {member.user.email}
              </td>
              <td className="hidden p-2 font-medium text-left text-gray-600 sm:table-cell">
                {member?.lastActive &&
                  format(new Date(Number(member.lastActive)), "d MMM yyyy")}
              </td>
              <td className="p-2 font-medium text-left text-gray-600">
                <TeamPermission
                  permissions={member.permissions}
                  companyMemberId={member.id}
                  isUserCompanyAdmin={isCompanyAdmin}
                />
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon
                  icon={["fas", "trash-alt"]}
                  className={
                    !isCompanyAdmin || member.id === activeWorkspaceId
                      ? "text-aqua-100 cursor-not-allowed"
                      : "text-aqua-400"
                  }
                  onClick={() => onRemoveClicked(member.id)}
                />
              </td>
            </tr>
          ))}

      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        companyMemberId={companyMemberIdToBeDeleted}
        setIsOpen={setIsDeleteConfirmationModalOpen}
        refetchRates={refetchRates}
        companyId={companyId}
        features={features}
      />
    </>
  );
};

export default TeamMembersTableData;
