import { FC } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandableSectionProps } from "organisms/Activation/types";

const ExpandableSection: FC<ExpandableSectionProps> = ({
  number,
  title,
  isOpen,
  isDone,
  onClick,
  children,
}) => (
  <div>
    <div className="p-3 font-medium cursor-pointer" onClick={onClick}>
      <div className="inline-flex items-center">
        {isDone ? (
          <FontAwesomeIcon
            icon={["fas", "check-circle"]}
            className="!w-4 !h-4 mr-2"
          />
        ) : (
          <div className="inline-flex items-center justify-center w-4 h-4 mr-2 text-xs bg-white rounded-full text-aqua-400">
            {number}
          </div>
        )}
        <span className={cn(isDone && "line-through")}>{title}</span>
      </div>
      <FontAwesomeIcon
        icon={["fal", isOpen ? "chevron-up" : "chevron-down"]}
        className="float-right"
        size="lg"
      />
    </div>
    {isOpen && children}
  </div>
);

export default ExpandableSection;
