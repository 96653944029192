import { ModalMode, RecordModalProps } from "./interfaces";
import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useState } from "react";
import AddExisting from "./AddExisting";
import AddNew from "./AddNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddBulkUpload from "./AddBulkUpload";

const RecordModal: FC<RecordModalProps> = ({
  show,
  record,
  refetch,
  onClose,
}) => {
  const [mode, setMode] = useState<ModalMode>("search");

  useEffect(() => {
    if (record) {
      setMode("create");
    }
  }, [record]);

  useEffect(() => {
    if (!show) {
      return;
    }

    document.documentElement.style.overflow = "hidden";
    document.body.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [show]);

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <Dialog.Panel className="w-full max-w-md p-6 bg-white shadow-xl rounded-2xl">
              <div className="flex items-start justify-between">
                <h2 className="mb-4 text-xl">
                  {record ? "Edit company" : "Add new company to your Dealflow"}
                </h2>
                <button
                  type="button"
                  title="Bulk upload"
                  onClick={() => setMode("bulk")}
                  className="p-1 text-gray-500 rounded-full hover:text-aqua-500 focus:text-aqua-500"
                >
                  <FontAwesomeIcon icon={["fas", "upload"]} />
                </button>
              </div>
              {mode === "search" && (
                <AddExisting
                  refetch={refetch}
                  onChangeMode={setMode}
                  onClose={onClose}
                  record={record}
                />
              )}
              {mode === "create" && (
                <AddNew
                  refetch={refetch}
                  onChangeMode={setMode}
                  onClose={onClose}
                  record={record}
                />
              )}
              {mode === "bulk" && (
                <AddBulkUpload
                  refetch={refetch}
                  onChangeMode={setMode}
                  onClose={onClose}
                  record={record}
                />
              )}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default RecordModal;
