import React, { createContext, useState, useEffect, useContext } from "react";
import { en } from "./en";
import { sv } from "./sv";
export const LanguageContext = createContext();

const LanguageProvider = (props) => {
  const [state, setState] = useState({});

  useEffect(() => {
    localStorage.setItem("language", "en"); //remove comment when enable multi language selection
    getLanguage();
  }, []);

  const setLanguage = async (language) => {
    if (language) {
      await localStorage.setItem("language", language);
      setState({ ...state, language });
    } else {
      var setLanguage = localStorage.getItem("language");
      if (!language) {
        var systemLang = navigator.language;
        setLanguage = systemLang.substring(0, 2);
        await localStorage.setItem("language", setLanguage);
        setState({ ...state, language: setLanguage });
        return language;
      }
    }
    window.location.reload();
    getLanguage();
  };

  const getLanguage = () => {
    var language = localStorage.getItem("language");
    switch (language) {
      case "sv":
        language = sv;
        break;
      case "en":
      default:
        language = en;
        break;
    }
    if (language) {
      setState({ ...state, language });
      return language;
    } else {
      setLanguage();
    }
  };

  if (!state.language) {
    return null;
  }

  return (
    <LanguageContext.Provider
      value={{ lang: state.language, setLanguage }}
      {...props}
    />
  );
};

const useLang = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLang must be used within a LanguageProvider");
  }
  return context;
};
export { useLang, LanguageProvider };
