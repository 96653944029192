// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const initializeCompanyProfile = createAction(
  "companyProfille/init",
  (payload) => ({
    payload,
  })
);

export const refetchCompanyData = createAction(
  "companyProfille/refetchCompanyData",
  (payload) => ({
    payload,
  })
);

export const refetchAnalysisData = createAction(
  "companyProfille/refetchAnalysisData",
  (payload) => ({
    payload,
  })
);

export const refetchTractionData = createAction(
  "companyProfille/refetchTractionData",
  (payload) => ({
    payload,
  })
);

export const refetchCompetitorsData = createAction(
  "companyProfille/refetchCompetitorsData",
  (payload) => ({
    payload,
  })
);

export const refetchUiRepresentation = createAction(
  "companyProfille/refetchUiRepresentation",
  (payload) => ({
    payload,
  })
);
