import { FC, useRef, useState, MouseEvent, ReactNode } from "react";

interface DraggableProps {
  rootClass?: string;
  children: ReactNode;
}

const HorizontalScroll: FC<DraggableProps> = ({ rootClass = "", children }) => {
  const ourRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e: MouseEvent<HTMLDivElement>) => {
    if (!ourRef.current) return;
    const slider = (ourRef.current as HTMLDivElement)
      .children[0] as HTMLDivElement;
    const x = e.pageX - slider.offsetLeft;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
  };

  const handleDrag = (e: MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = (ourRef.current as HTMLDivElement)
      .children[0] as HTMLDivElement;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  return (
    <div
      ref={ourRef}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
      className={rootClass + "flex overflow-x-scroll no-scrollbar"}
    >
      {children}
    </div>
  );
};

export default HorizontalScroll;
