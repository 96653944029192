import { gql } from "@apollo/client";

export const MANUALLY_ADD_LINKEDIN_MEMBERS = gql`
  mutation manuallyAddTeamMemberLinkedIn(
    $companyId: String!
    $linkedinUrls: [String!]
  ) {
    aia {
      manuallyAddTeamMemberLinkedIn(
        companyId: $companyId
        linkedinUrls: $linkedinUrls
      ) {
        done
      }
    }
  }
`;
