// @ts-nocheck
import { useState, useRef, forwardRef, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as PencilIcon } from "images/pencil-icon.svg";
import { ReactComponent as TrashIcon } from "images/trash-icon.svg";
import { ACCEPT, MAX_FILE_SIZE } from "./constants";

// @TODO: use real typings instead of any
//
export default forwardRef((props: any, ref) => {
  let dragCounter = 0;
  const { lang } = useLang();

  const ACCEPT_TITLE = {
    image: `PNG, JPG, GIF ${lang.upToTill} 10MB`,
    document: `XLSX, XLS, PDF ${lang.upToTill} 20MB`,
    imageOrDocument: `PDF, Excel, Word, PPT, PNG, JPEG ${lang.upToTill} 10MB`,
    csv: `CSV ${lang.upToTill} 10MB`,
  };

  const type = props.type || "image";

  const inputRef = useRef();
  const [dragging, setDragging] = useState(false);

  const validateSize = (file) => {
    if (type !== "image") {
      return Promise.resolve({ isValid: true });
    }

    const fileSizeInMb = parseInt(file.size / (1024 * 1024));

    if (fileSizeInMb >= MAX_FILE_SIZE) {
      return Promise.resolve({
        isValid: false,
        message: `${lang.fileIsTooLarge} (max ${MAX_FILE_SIZE}mb)`,
      });
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = function () {
        const { width, height } = this;

        if (props.minWidth && props.minWidth > width) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsTooSmallInWidth} (min ${props.minWidth})`,
          });
        }

        if (props.minHeight && props.minHeight > height) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsTooSmallInHeight} (min ${props.minHeight})`,
          });
        }

        if (props.square && width !== height) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsNotSquare} (${width}x${height})`,
          });
        }

        resolve({ isValid: true });
      };

      img.src = objectUrl;
    });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter--;
    if (dragCounter > 0) return;

    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e?.dataTransfer?.files;

    setDragging(false);

    if (files && files?.length > 0) {
      const { isValid, message } = await validateSize(files[0]);

      if (!isValid) {
        if (typeof props.onError === "function") {
          return props.onError(message);
        }
      }

      if (typeof props.onChange === "function") {
        props.onChange(files[0]);
      }

      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  return (
    <div
      ref={ref}
      className="mb-3 sm:mt-0"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div
        className={`flex justify-center border-2 items-center px-6 pt-5 pb-6 border-dashed file-select relative rounded-md ${
          dragging ? "border-aqua-300" : "border-gray-300"
        } ${props.disabled && "border-none"} ${
          props.small && props.children && "w-40 h-40"
        }`}
        style={props.children ? { padding: "0" } : {}}
      >
        {!props.loading ? (
          <>
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              id="file"
              name="file"
              accept={ACCEPT[type]}
              multiple={!props.multiple ? true : false}
              onChange={async (e) => {
                const { target } = e;
                const file = e.target.files[0];

                const { isValid, message } = await validateSize(file);

                if (!isValid) {
                  if (typeof props.onError === "function") {
                    return props.onError(message);
                  }
                }

                if (typeof props.onChange === "function") {
                  props.onChange(file);
                }

                target.value = "";
              }}
            />
            {props.children && (
              <Fragment>
                {props.children}
                {props.onRemove && !props.disabled && (
                  <TrashIcon
                    onClick={props.onRemove}
                    className="absolute top-0 right-0 cursor-pointer"
                    style={{ height: 20, width: 20 }}
                  />
                )}
                {!props.onRemove && !props.disabled && (
                  <PencilIcon
                    onClick={() => inputRef.current.click()}
                    className="absolute top-0 right-0 cursor-pointer __edit__icon"
                    style={{
                      height: 30,
                      width: 30,
                      pointerEvents: props.isLoading ? "none" : "",
                      opacity: props.isLoading ? 0.7 : 1,
                    }}
                  />
                )}
              </Fragment>
            )}
            {!props.children && (
              <div className="text-center" style={{ pointerEvents: "none" }}>
                {
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                <p className="mt-1 text-sm text-gray-600">
                  {`${props.imageDesc} `}
                  <button
                    type="button"
                    className="transition duration-150 ease-in-out  text-aqua-400 hover:text-aqua-600 focus:outline-none focus:underline"
                    onClick={() => inputRef.current.click()}
                    style={{ pointerEvents: "initial" }}
                  >
                    {lang.browse}
                  </button>
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  {ACCEPT_TITLE[type]}
                  {/* PNG, JPG, GIF up to 10MB */}
                </p>
                {props.extraInfo && (
                  <p className="text-sm mt-4 px-6">{props.extraInfo}</p>
                )}
              </div>
            )}
          </>
        ) : (
          <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
        )}
      </div>
    </div>
  );
});
