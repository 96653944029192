import { FC, useEffect, useRef, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { BusinessModel } from "gql/graphql";
import ButtonGroup from "molecules/Form/ButtonGroup";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";
import { useLang } from "core/lang/LangaugeSetting";
import { BUSINESS_MODEL } from "core/consts";

interface Props {
  models: BusinessModel[] | undefined | null;
  modelsNote: string | undefined | null;
}

const BusinessRelation: FC<Props> = ({ models, modelsNote }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [modelNoteError, setModelNoteError] = useState(false);

  useEffect(() => {
    setSelected(models || []);
  }, [models]);

  const { lang } = useLang();
  const dispatch = useDispatch();
  const noteRef = useRef<HTMLTextAreaElement>(null);

  const onModelChange = (data: string[]) => {
    setSelected(data);
  };

  const onCancel = () => {
    setSelected(models || []);
    setIsEditMode(false);
  };

  const onSave = () => {
    if (noteRef.current && !noteRef.current.value) {
      setModelNoteError(true);
      return;
    }

    dispatch(
      updateStartupProfile({
        business: {
          models: selected,
          modelsNote: noteRef.current?.value,
        },
      })
    );
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="mt-2">
        <ButtonGroup
          id="categories"
          options={Object.entries(BUSINESS_MODEL)}
          onChange={onModelChange}
          limit={3}
          type="company"
          className="capitalize"
          defaultValue={models || undefined}
        />
        {selected?.includes("OTHERS" as BusinessModel) && (
          <>
            <textarea
              rows={3}
              id="businessCategoryNote"
              className={`form-textarea block mt-2 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                modelNoteError
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : ""
              }`}
              placeholder={lang.addNoteForBusinessCategory}
              defaultValue={modelsNote || ""}
              maxLength={200}
              ref={noteRef}
              onChange={(e: any) => {
                if (Boolean(e)) setModelNoteError(false);
              }}
            />
            {modelNoteError && (
              <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
            )}
          </>
        )}
        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right mt-2"
        />
      </div>
    );
  };

  const renderContent = () => (
    <div>
      <div>{models?.map((m) => m).join(", ")}</div>
      {models?.includes("OTHERS" as BusinessModel) && <p>{modelsNote}</p>}
    </div>
  );

  return (
    <EditablePlaceholder
      title="Business Relation"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={models && models.length > 0 ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default BusinessRelation;
