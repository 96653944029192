import { useEffect, useState } from "react";
import TabContainer from "../TabContainer";
import { useLang } from "core/lang/LangaugeSetting";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_LOGO,
  GET_PROFILE_INFO,
  SAVE_PROFILE_INFO,
  UPLOAD_LOGO,
} from "./queries";
import Button from "atoms/Button/Button";
import FormInput from "atoms/Form/FormInput";
import GoogleInput from "molecules/Form/GoogleInput";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";

const Account = () => {
  const [firmName, setFirmName] = useState();
  const [location, setLocation] = useState();
  const [profileLogo, setProfileLog] = useState();

  const { lang } = useLang();
  const dispatch = useDispatch();

  const { data: profileLogoData, refetch: refetchLogo } = useQuery(GET_LOGO);
  const { data: profileInfo, refetch: refetchProfileInfo } =
    useQuery(GET_PROFILE_INFO);

  const [uploadLogo] = useMutation(UPLOAD_LOGO, {
    onCompleted: () => {
      refetchLogo();
    },
  });

  const [saveProfileInfo, { loading: isSaving }] = useMutation(
    SAVE_PROFILE_INFO,
    {
      onCompleted: () => {
        refetchProfileInfo();
        dispatch(
          triggerSnack({
            type: "success",
            title: "Profile updated",
            message: "Profile data was saved successfully",
          })
        );
      },
      onError: () => {
        dispatch(
          triggerSnack({
            type: "error",
            title: "Something went wrong",
            message: "We could not save the data, please try again later",
          })
        );
      },
    }
  );

  useEffect(() => {
    setProfileLog(profileLogoData?.me?.logo?.large?.location);
  }, [profileLogoData]);

  useEffect(() => {
    setLocation(profileInfo?.me?.fullAddress);
    setFirmName(profileInfo?.me?.displayName);
  }, [profileInfo]);

  const handleGetPlace = (place: any) => {
    setLocation(place?.fullAddress);
  };

  function handleKeyPress(e: any) {
    e.preventDefault();
    if (e.target.value === "Enter" && !e.shiftKey) {
      document.getElementById("city")?.blur();
    } else {
      setLocation(e.target.value);
    }
  }

  const handleUploadLogo = (file: File) => {
    uploadLogo({ variables: { file } });
  };

  const onSubmit = () => {
    saveProfileInfo({
      variables: {
        input: {
          displayName: firmName,
          fullAddress: location,
        },
      },
    });
  };

  return (
    <TabContainer>
      <div className="p-2">
        <div className="w-full mb-4">
          <FormInput
            label={lang.firmName}
            placeholder={lang.enterFirmName}
            autoComplete="off"
            defaultValue={profileInfo?.me?.displayName}
            onChange={(e: any) => setFirmName(e.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <GoogleInput
            id="city"
            name="city"
            variant="rect"
            label={lang.whatIsYourPrimaryLocation}
            placeholder={lang.typeCountyCityName}
            labelClass="font-normal"
            defaultValue={location}
            onChange={(e: any) => {
              handleKeyPress(e);
            }}
            onBlur={(e: any) => {
              handleKeyPress(e);
            }}
            onKeyPress={(e: any) => e.key === "Enter" && handleKeyPress(e)}
            getPlace={handleGetPlace}
          />
        </div>

        <div>
          <label className="block mb-1 text-black">Logo</label>
          <ProfilePictureSelect
            onComplete={handleUploadLogo}
            preview={profileLogo}
            label={lang.logo}
          />
        </div>

        <Button
          color="primary"
          className="float-right w-20 flex justify-center"
          onClick={() => onSubmit()}
          disabled={isSaving}
        >
          {isSaving ? (
            <FontAwesomeIcon icon={["fal", "spinner-third"]} spin size="lg" />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </TabContainer>
  );
};

export default Account;
