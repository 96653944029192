// @ts-nocheck
import React, { Fragment, forwardRef, useRef, useState } from "react";
import Cropper from "./Cropper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as PencilIcon } from "images/pencil-icon.svg";
import Tooltip from "atoms/Tooltip/Tooltip";

const ACCEPT = {
  image: "image/png, image/jpeg, image/gif",
};

const MAX_FILE_SIZE = 10;

export default forwardRef((props: any, ref) => {
  let dragCounter = 0;

  const type = "image";

  const finalId = props.id || "file";
  const inputRef = useRef();
  const [dragging, setDragging] = useState(false);
  const [image, setImage] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const { lang } = useLang();

  const ACCEPT_TITLE = {
    image: `PNG, JPG, GIF ${lang.upToTill} 10MB`,
  };

  const validateSize = (file) => {
    if (type !== "image") {
      return Promise.resolve({ isValid: true });
    }

    const fileSizeInMb = parseInt(file.size / (1024 * 1024));

    if (fileSizeInMb >= MAX_FILE_SIZE) {
      return Promise.resolve({
        isValid: false,
        message: `${lang.fileIsTooLarge} (max ${MAX_FILE_SIZE}mb)`,
      });
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = function () {
        const { width, height } = this;

        if (props.minWidth && props.minWidth > width) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsTooSmallInWidth} (min ${props.minWidth})`,
          });
        }

        if (props.minHeight && props.minHeight > height) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsTooSmallInHeight} (min ${props.minHeight})`,
          });
        }

        if (props.square && width !== height) {
          return resolve({
            isValid: false,
            message: `${lang.imageIsNotSquare} (${width}x${height})`,
          });
        }

        resolve({ isValid: true });
      };

      img.src = objectUrl;
    });
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter--;
    if (dragCounter > 0) return;

    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e?.dataTransfer?.files;

    setDragging(false);

    if (files && files?.length > 0) {
      const { isValid, message } = await validateSize(files[0]);

      if (!isValid) {
        if (typeof props.onError === "function") {
          return props.onError(message);
        }
      }

      if (typeof props.onChange === "function") {
        // props.onChange(files[0])
        setShowCropper(true);
        setImage(files[0]);
      }

      e.dataTransfer.clearData();
      dragCounter = 0;
    }
  };

  return (
    <>
      <div
        ref={ref}
        className={`mb-3 sm:mt-0 ${props.fullHeight ? "h-full" : ""}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          className={`flex justify-center border-2 px-6 pt-5 file-select pb-6 border-dashed relative ${
            dragging ? "border-aqua-300" : "border-gray-300"
          } ${props.disabled && "border-none"} ${
            props.small && props.children
              ? "w-40 h-40 rounded-full"
              : "rounded-md"
          } ${props.fullHeight ? "h-full" : ""}`}
          style={
            props.children
              ? { padding: "0", width: props.fullWidth && "100%" }
              : {}
          }
        >
          <input
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
            id={finalId}
            name="file"
            accept={ACCEPT[type]}
            multiple
            onChange={async (e) => {
              const { target } = e;
              const file = e.target.files[0];

              const { isValid, message } = await validateSize(file);

              if (!isValid) {
                if (typeof props.onError === "function") {
                  return props.onError(message);
                }
              }

              if (typeof props.onChange === "function") {
                // props.onChange(file)
                setShowCropper(true);
                setImage(file);
              }

              target.value = "";
            }}
          />
          {props.children && (
            <Fragment>
              {props.children}
              {props.onRemove && !props.disabled && (
                <button
                  type="button"
                  className="absolute inline-flex items-center px-3 py-2 text-sm font-medium bg-white border leading-4 transition duration-150 ease-in-out rounded-md right-4 top-4 border-fire-300 text-fire-700 hover:text-fire-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-fire-800 active:bg-fire-50"
                  onClick={props.onRemove}
                >
                  <FontAwesomeIcon icon={["fal", "trash"]} />
                </button>
              )}
              {!props.onRemove && !props.disabled && (
                <div className="absolute top-0 right-0 cursor-pointer __edit__icon">
                  <Tooltip content={lang.changePicture}>
                    <PencilIcon
                      onClick={() => inputRef.current.click()}
                      style={{ height: 30, width: 30 }}
                    />
                  </Tooltip>
                </div>
              )}
            </Fragment>
          )}
          {!props.children && (
            <div className="text-center" style={{ pointerEvents: "none" }}>
              <p className="mt-1 text-sm text-gray-600">
                {`${props.title ? props.title : props.imageDesc} `}
                <button
                  type="button"
                  className="transition duration-150 ease-in-out text-aqua-400 hover:text-aqua-600 focus:underline focus-visible:!outline-none"
                  onClick={() => inputRef.current.click()}
                  style={{ pointerEvents: "initial" }}
                >
                  {lang.browse}
                </button>
              </p>
              <p className="mt-1 text-xs text-gray-500">{ACCEPT_TITLE[type]}</p>
            </div>
          )}
        </div>
      </div>
      {showCropper && image && (
        <Cropper
          file={image}
          onClose={() => setShowCropper(false)}
          onSave={(file) => props.onChange(file)}
        />
      )}
    </>
  );
});
