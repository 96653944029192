// @ts-nocheck
import {
  Controller,
  Control,
  UseControllerProps,
  useController,
  FieldValues,
  Path,
} from "react-hook-form";
import SelectionDropdownInput from "molecules/Form/SelectionDropdownInput";
import { ReactElement, useEffect, useState } from "react";

type TractionDropdownProps<T> = {
  valueControlProps: UseControllerProps<T>;
  optionsControlProps: UseControllerProps<T>;
  optionsMap: { [key: string]: string };
  icon?: ReactElement;
  placeholder?: string;
};

const TractionDropdown = <T extends FieldValues>({
  valueControlProps,
  optionsControlProps,
  optionsMap,
  icon,
  placeholder,
}: TractionDropdownProps<T>) => {
  const {
    field: valueField,
    fieldState: { error: valueError },
  } = useController(valueControlProps);

  const { field: optionField } = useController(optionsControlProps);

  const options = Object.values(optionsMap);

  const getKey = (value: string) =>
    Object.keys(optionsMap).find((key) => optionsMap[key] === value);

  return (
    <SelectionDropdownInput
      isLabelHide={true}
      selectName="frequency"
      direction="right"
      selectOptions={options}
      selectValue={optionsMap[optionField.value]} // getting the value from the options obj
      onSelectVal={(selected: string) => {
        optionField.onChange(getKey(selected));
      }}
      reference={{
        ref: valueField.ref,
      }}
      inputPlaceholder={placeholder}
      inputVal={valueField.value || ""}
      inputName={valueField.name}
      icon={icon}
      handleUpdateVal={(val: string) => {
        valueField.onChange(val);
      }}
      errors={valueError && valueError.message}
    />
  );
};

export default TractionDropdown;
