import { useSelector } from "react-redux";
import {
  getIsApplicationSent,
  getActiveCohort,
  getUser,
} from "models/user/selectors";
import { ReactComponent as Logo } from "images/logo-black.svg";
import { ReactComponent as DarkLogo } from "images/logo-white.svg";
import { Link } from "react-router-dom";
import useBoardLogo from "./useBoardLogo";

const NavbarLogo = () => {
  const isApplicationSent = useSelector(getIsApplicationSent);
  const activeCohort = useSelector(getActiveCohort);
  const user = useSelector(getUser);
  const boardLogo = useBoardLogo();

  const isVcOrFamilyOffice =
    user?.investorType === "VC" || user?.investorType === "FAMILY_OFFICE";

  if (boardLogo) {
    return (
      <div className="flex flex-col">
        <span className="text-gray-600 dark:text-gray-500">
          Powered by <span className="text-aqua-400">Sircular</span>
        </span>
      </div>
    );
  }

  if (!isApplicationSent && activeCohort?.logo?.large?.location) {
    return (
      <img
        src={activeCohort?.logo?.large?.location}
        className="h-8"
        alt="referrer-logo"
      />
    );
  }

  return (
    <Link to={isVcOrFamilyOffice ? "/aia" : "/"}>
      <DarkLogo className="hidden h-8 dark:block" />
      <Logo className="block h-8 dark:hidden" />
    </Link>
  );
};

export default NavbarLogo;
