// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const initializeMessagesPage = createAction(
  "messages/initializeMessagesPage"
);

export const getContactMessages = createAction(
  "messages/getContactMessages",
  (payload) => ({
    payload,
  })
);

export const startWatchWorker = createAction("messages/startWatchWorker");

export const stopPolling = createAction("messages/stopPolling");
