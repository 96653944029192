import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  description: string | undefined | null;
}

const BusinessModel: FC<Props> = ({ description }) => {
  return (
    <EditablePlaceholderText
      fieldName="business.description"
      title="Business Model"
      addValueTitle="Business model"
      value={description}
    />
  );
};

export default BusinessModel;
