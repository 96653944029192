import { FC } from "react";
import { useState, useEffect } from "react";

interface Props {
  targetDate: number;
}

const CountdownTimer: FC<Props> = ({ targetDate }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const setTime = (m: number) => {
    setDays(Math.floor(m / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((m / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((m / 1000 / 60) % 60));
    setSeconds(Math.floor((m / 1000) % 60));
  };

  useEffect(() => {
    setTime(targetDate);

    let leftMs = targetDate;

    const interval = setInterval(() => {
      setTime((leftMs -= 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  if (!targetDate) return null;

  return (
    <div className="mt-6 w-full item-center">
      <h1 className="text-white font-normal text-base text-center mb-3">
        Come back in
      </h1>
      <div className="flex items-center text-white gap-6 justify-center">
        <div className="flex flex-col w-[55px] text-center">
          <div>
            <span className="h-[44px] text-xl font-normal">
              {days < 10 ? `0${days}` : days}
            </span>
          </div>
          <span>Days</span>
        </div>
        <div className="flex flex-col w-[55px] text-center">
          <div>
            <span className="h-[44px] text-xl font-normal">
              {hours < 10 ? `0${hours}` : hours}{" "}
            </span>
          </div>
          <span>Hours</span>
        </div>
        <div className="flex flex-col w-[55px] text-center">
          <div>
            <span className="h-[44px] text-xl font-normal">
              {minutes < 10 ? `0${minutes}` : minutes}
            </span>
          </div>
          <span>Minutes</span>
        </div>
        <div className="flex flex-col w-[55px] text-center">
          <div>
            <span className="h-[44px] text-xl font-normal">
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>
          </div>
          <span>Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
