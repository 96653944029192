import { AiaRateLimit } from "gql/graphql";
import { FC } from "react";
import CountdownTimer from "./CountDownTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface prop {
  feature: string;
  aiaRates?: AiaRateLimit;
  onClose: () => void;
  translateX?: number;
  translateY?: number;
}

type IntervalEnum = {
  DAY: "day";
  WEEK: "week";
  MONTH: "month";
  YEAR: "year";
};

const intervalEnum: IntervalEnum = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
};

const AiaRateToolTip: FC<prop> = ({
  feature,
  aiaRates,
  onClose,
  translateX,
  translateY,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={`absolute rounded-xl w-[485px] translate-x-[${translateX}%] shadow-lg shadow-[#1D1F2D] -translate-y-[${translateY}%] p-8 z-50 bg-[#0B0D18] border-aqua-400 border-2`}
    >
      <button className="absolute top-4 right-4" onClick={onClose}>
        <FontAwesomeIcon
          className="text-xl text-white"
          icon={["fal", "times"]}
        />
      </button>

      {aiaRates && (
        <div className="mx-auto">
          <RenderRateText aiaRates={aiaRates} feature={feature} />
          {aiaRates?.timeUntilReset && (
            <CountdownTimer targetDate={aiaRates?.timeUntilReset} />
          )}
        </div>
      )}

      <div className="w-full rounded-lg flex flex-col justify-center">
        {aiaRates ? (
          <p className="text-center text-white mt-3">OR</p>
        ) : (
          <p className="text-center text-white mt-3 font-bold mb-2">
            Do you want to generate a deep analysis of top competitors? Unlock
            this feature by upgrading your account.
          </p>
        )}
        <button
          className="flex self-center justify-center px-4 py-2 mt-2 text-white font-bold text-lg opacity-90 border-aqua-400  border-2 rounded-lg"
          onClick={() => navigate("/plans")}
        >
          Upgrade plan
        </button>
      </div>
    </div>
  );
};

export default AiaRateToolTip;

interface PropsRenderRateText {
  aiaRates?: AiaRateLimit;
  feature?: string;
}

const RenderRateText: FC<PropsRenderRateText> = ({ aiaRates, feature }) => {
  const intervalValue = aiaRates?.interval
    ? intervalEnum[aiaRates.interval as keyof IntervalEnum]
    : "no interval set";

  let featureTitle;

  if (feature === "WEBSITE_AS_CONTEXT") {
    featureTitle = "website based search";
  } else if (feature === "DEEP_DIVE") {
    featureTitle = "deep dive analysis";
  }

  return (
    <div className="text-center">
      <p className="text-white text-lg">
        Your current plan gives you access to {aiaRates?.limit} {featureTitle}{" "}
        per {intervalValue}
      </p>
    </div>
  );
};
