import { SustainableDevelopmentGoal } from "gql/graphql";
import Sdg, { sdgIcons } from "molecules/Sdg/Sdg";
import { FC, useEffect, useState } from "react";
import SdgPlaceholder from "./SdgPlaceholder";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";
import { uniq } from "lodash";

interface Props {
  sdg: SustainableDevelopmentGoal | null | undefined;
}

const SdgGoals: FC<Props> = ({ sdg }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [sdgsToSave, setSdgsToSave] = useState<number[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sdg?.items) {
      setSdgsToSave(uniq(sdg.items));
    }
  }, [sdg?.items]);

  const onAddClicked = () => {
    setIsEditMode(true);
  };

  const onSave = () => {
    dispatch(
      updateStartupProfile({
        business: {
          sdg: {
            items: sdgsToSave,
          },
        },
      })
    );
    setIsEditMode(false);
  };

  const onCancel = () => {
    setIsEditMode(false);
    if (sdg?.items) {
      setSdgsToSave(sdg.items);
    }
  };

  const handleAddSdg = (index: number) => {
    setSdgsToSave((prev) => prev.concat(index + 1));
  };

  const handleRemoveSdg = (index: number) => {
    setSdgsToSave((prev) => prev.filter((s) => s !== index + 1));
  };

  return (
    <div className="w-full">
      <span className="font-semibold">SDG Goals</span>
      <div className="flex flex-wrap w-full">
        {sdgIcons.map((icon, index) => {
          const isActive = sdgsToSave.includes(index + 1);
          if (isActive)
            return (
              <div
                key={index}
                className="w-14 m-1 relative"
                onClick={isEditMode ? () => handleRemoveSdg(index) : undefined}
              >
                <Sdg key={index} number={index + 1} />
                {isEditMode && (
                  <CloseIcon className="absolute w-5 h-5 cursor-pointer -right-1 -top-1" />
                )}
              </div>
            );
          else return null;
        })}

        {!isEditMode && <SdgPlaceholder onAddClicked={onAddClicked} />}
      </div>

      {isEditMode && (
        <div className="flex flex-col my-2">
          <div>Choose SDG to add:</div>
          <div className="flex flex-wrap w-full">
            {sdgIcons.map((icon, index) => {
              if (sdgsToSave.includes(index + 1)) return null;
              return (
                <div
                  className="w-14 m-1"
                  onClick={() => handleAddSdg(index)}
                  key={index}
                >
                  <Sdg key={index} number={index + 1} />
                </div>
              );
            })}
          </div>
          <SaveAndCancelButtons
            onSave={onSave}
            onCancel={onCancel}
            className="self-end mt-2"
          />
        </div>
      )}
    </div>
  );
};

export default SdgGoals;
