import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./components";
import { ReactComponent as Logo } from "images/logo-black.svg";
import PlanFeature from "./components/PlanFeature";
import PlanFeatures from "./components/PlanFeatures";
import { Plans } from "./components";
import usePlan from "./hooks/usePlan";
import Loading from "atoms/Loading/Loading";

const StartupPlans: FC = () => {
  const { plans, allFeatures, isLoading } = usePlan();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loading fullPage />;
  }

  return (
  
      <div className="flex flex-col items-start w-full p-8 sm:p-6 pb-16 bg-[#EEEEEE] min-h-[calc(81px+100vh)]">
        <p
          onClick={() => navigate("/account/billing")}
          className="self-start mb-2 font-thin cursor-pointer"
        >
          &lt; Account settings
        </p>
        <div className="self-center md:pl-10">
          <>
            <Header />
            <div className="container flex-1 mx-auto mt-8 bg-white shadow-2xl rounded-3xl sm:shadow-none">
              <div className="flex">
                <div className="hidden sm:flex sm:flex-col sm:p-12 sm:pr-0">
                  <div className="mb-5 h-28">
                    <Logo />
                  </div>
                  <PlanFeatures
                    features={allFeatures}
                    renderFeature={(feature) => (
                      <PlanFeature feature={feature} key={feature.id} />
                    )}
                  />
                </div>
                <Plans plans={plans} />
              </div>
            </div>
          </>
        </div>
      </div>
  
  );
};

export default StartupPlans;
