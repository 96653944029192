import { SubscriptionUserType } from "../interfaces";

export const getPageHeaderTag = (userType: SubscriptionUserType) => {
    switch (userType) {
      case SubscriptionUserType.Startup:
        return "Meet your next investors.";
      case SubscriptionUserType.Angel:
        return "Discover your next investments.";
      case SubscriptionUserType.VC:
        return "Discover your next investments.";
      case SubscriptionUserType.Accelerator:
          return "Discover your next investments.";
      default:
        break;
    }
  };
  