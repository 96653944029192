import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { NetRevenue as NetRevenueType } from "gql/graphql";
import { PERRATEPERIOD } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  netRevenue: Array<NetRevenueType | null> | null | undefined;
  note: string | null | undefined;
  currencyCode: string;
}

const NetRevenue: FC<Props> = ({ netRevenue, note, currencyCode }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const netRevenueData = netRevenue?.[0];

  const renderContent = () => {
    return (
      <div>
        {netRevenueData?.amount && netRevenueData.time ? (
          <div>
            {formatCurrencyValues(Number(netRevenueData.amount), currencyCode)}
            {PERRATEPERIOD[netRevenueData.time]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, dropdownValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          netRevenue: {
            amount: inputValue,
            time: dropdownValue,
            // this won't affect functionality but still good to update the DB
            currency: currencyCode,
          },
          netRevenueNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={netRevenueData?.amount}
        dropdownDefaultValue={netRevenueData?.time}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(PERRATEPERIOD).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="Net Revenue"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={netRevenueData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default NetRevenue;
