import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import { analyticsLink } from "utils/links";
import { FC, Fragment, SyntheticEvent, useMemo, useState } from "react";
import NewBoard from "./BoardNew";
import DOMPurify from "dompurify";
import Truncate from "react-truncate";
import BoardDetails from "./BoardDetails";
import { BoardItemProps } from "../../interfaces";
import { convertTimeStampToDate } from "../../utils";
import { useStartupsBoard } from "../../hooks";
import { useNavigate } from "react-router-dom";

const NUMBER_OF_STARTUPS_TO_SHOW = 6;
const isTouchScreen = window.matchMedia("(pointer: coarse)").matches;

const BoardItem: FC<BoardItemProps> = ({
  board,
  onShareBoardClick,
  isOnProPlan,
}) => {
  const { refetch } = useStartupsBoard();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isBoardDetailsOpen, setIsBoardDetailsOpen] = useState(false);
  const [analyticsMenuItem, setAnalyticsMenuItem] = useState("View Analytics");
  const [isTouchScreenClickConsumed, setIsTouchScreenClickConsumed] =
    useState(false);

  const navigate = useNavigate();

  const firstSixStartupsWithLogo = useMemo(
    () =>
      board?.startups
        ?.filter((startup) => Boolean(startup?.logo?.large?.location))
        .slice(0, NUMBER_OF_STARTUPS_TO_SHOW),
    [board]
  );

  const anlyticsClickHandler = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (isTouchScreen && !isOnProPlan && !isTouchScreenClickConsumed) {
      e.stopPropagation();
      e.preventDefault();
      setAnalyticsMenuItem("Upgrade To Pro");
      setIsTouchScreenClickConsumed(true);
      return;
    }
    if (isOnProPlan) {
      const link = analyticsLink({ board: board.slug });
      window.open(link, "_blank", "noreferrer");
    } else {
      navigate("/plans");
    }
  };

  return (
    <>
      <div
        className="bg-white rounded-lg border border-solid border-[#00000024] flex flex-col overflow-hidden cursor-pointer mb-4 md:w-72 md:h-80 md:mr-4"
        onClick={() => setIsBoardDetailsOpen(true)}
      >
        <div className="px-4 pt-4 min-h-[48%]">
          <div className="flex items-center justify-between mb-1">
            <h3 className="text-lg">{board.title}</h3>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button
                onClick={(e: any) => e.stopPropagation()}
                className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-[#F5F5F5] cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={["fas", "ellipsis-h"]}
                  color="#0b0d189e"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute pl-8 py-2 right-0 z-10 w-56 mt-2 origin-top-right bg-[#202436] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  onClick={(e: SyntheticEvent) => e.stopPropagation()}
                >
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        className={cn(
                          active ? "text-[#18BEB3]" : "text-white opacity-70",
                          "block py-2 text-sm font-bold cursor-pointer"
                        )}
                        onClick={() => onShareBoardClick(board)}
                      >
                        Share
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        className={cn(
                          active ? "text-[#18BEB3]" : "text-white opacity-70",
                          "block py-2 text-sm font-bold cursor-pointer"
                        )}
                        onClick={anlyticsClickHandler}
                        onMouseEnter={
                          isOnProPlan
                            ? undefined
                            : () => setAnalyticsMenuItem("Upgrade To Pro")
                        }
                        onMouseLeave={() =>
                          isOnProPlan
                            ? undefined
                            : setAnalyticsMenuItem("View Analytics")
                        }
                      >
                        {analyticsMenuItem}
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        className={cn(
                          active ? "text-[#18BEB3]" : "text-white opacity-70",
                          "block py-2 text-sm font-bold cursor-pointer"
                        )}
                        onClick={() => setIsEditModalOpen(true)}
                      >
                        Edit
                      </span>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <span className="text-[#787486] font-medium">
            {convertTimeStampToDate(board.createdAt)}
          </span>

          <div>
            <Truncate lines={2}>
              <div
                className="mt-2 w-[100%] text-sm text-black"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(board.description),
                }}
              />
            </Truncate>
          </div>
        </div>

        <div className="bg-[#F7F8FA] py-3 px-4 mt-2 grow">
          <h5 className="mb-5">Startups</h5>
          <div className="flex">
            {firstSixStartupsWithLogo?.map((startup, index: number) => (
              <span
                className={cn(
                  "bg-[#ffffff66] w-15 h-15 p-[0.15rem] rounded-full flex-shrink-0 flex justify-center items-center",
                  index !== 0 && "-ml-6"
                )}
                key={index}
              >
                <img
                  src={startup?.logo?.large?.location}
                  alt={`${startup.displayName} logo`}
                  className="w-full h-full rounded-full"
                />
              </span>
            ))}

            {board?.startups?.length > NUMBER_OF_STARTUPS_TO_SHOW && (
              <span
                className={
                  "bg-[#ffffff66] w-15 h-15 p-[0.3rem] rounded-full flex-shrink-0 -ml-6"
                }
              >
                <span className="w-[100%] h-[100%] rounded-full bg-white text-[#18BEB3] flex justify-center items-center">
                  {board.startups.length - NUMBER_OF_STARTUPS_TO_SHOW} +
                </span>
              </span>
            )}
          </div>
        </div>
      </div>

      <NewBoard
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        rerenderBoard={refetch}
        initialValues={{
          title: board.title,
          description: board.description,
          startups: board.startups,
          logo: board.logo?.large?.location,
          isIdentityRequired: Boolean(board?.isIdentityRequired),
          isDarkMode: Boolean(board.isDarkMode),
        }}
        editId={board.id}
      />

      <BoardDetails
        startups={board.startups}
        board={board}
        isOpen={isBoardDetailsOpen}
        onClose={() => setIsBoardDetailsOpen(false)}
        onShareBoardClick={onShareBoardClick}
        onEditBoardClick={() => {
          setIsEditModalOpen(true);
        }}
        isOnProPlan={isOnProPlan}
      />
    </>
  );
};

export default BoardItem;
