import { FC } from "react";
import cn from "classnames";
import { ChoosePlanButtonProps } from "../../interfaces";
import Button from "atoms/Button/Button";

const ChoosePlanButton: FC<ChoosePlanButtonProps> = ({
  isMostPopular = false,
  checkout,
  isLoading,
}: ChoosePlanButtonProps) => (
  <Button
    className={cn(
      " flex justify-center text-white mt-5 opacity-50 z-30 w-2/3 self-center p-2 mx-3 hover:opacity-60",
      isMostPopular
        ? "bg-[#18BEB3] opacity-100 hover:opacity-90"
        : "bg-[#231D4F]"
    )}
    onClick={checkout}
    loading={isLoading}
  >
    Choose plan
  </Button>
);

export default ChoosePlanButton;
