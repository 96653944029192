import { FC, Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
interface Props {
  profileFailed: boolean;
  analysisFailed: boolean;
  tasksCompleted: boolean;
}

const isMobile = window.matchMedia("(max-width: 640px)").matches;

const ToastMessages: FC<Props> = ({
  profileFailed,
  analysisFailed,
  tasksCompleted,
}) => {
  return (
    <div className={cn("fixed right-6 top-6 z-50", isMobile && "left-6")}>
      <Toast
        type="success"
        show={tasksCompleted}
        message="Your Profile is done"
      />
      <Toast
        type="failure"
        show={profileFailed}
        message="Error loading the profile"
      />
      <Toast
        type="failure"
        show={analysisFailed}
        message="Failed loading the contextual analysis"
      />
    </div>
  );
};

interface ToastProps {
  type: "success" | "failure";
  show: boolean;
  message: string;
}

const Toast: FC<ToastProps> = ({ show, message, type }) => {
  const [closed, setClosed] = useState(false);
  const navigate = useNavigate();

  return (
    <Transition
      as={Fragment}
      show={!closed && show}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        className={cn(
          "rounded-md p-2 max-w-xl mb-2",
          type === "success"
            ? "bg-green-700"
            : " bg-fuchsia-50 border border-red-200 border-l-4 border-l-red-600"
        )}
      >
        <FontAwesomeIcon
          icon={["fal", "times"]}
          className="float-right cursor-pointer"
          color={type === "success" ? "white" : "gray"}
          onClick={() => setClosed(true)}
        />
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={["fal", type === "success" ? "check-circle" : "times-circle"]}
            color={type === "success" ? "white" : "red"}
          />
          <span
            className={cn(
              "font-bold ml-4",
              type === "success" ? "text-white" : "text-red-600"
            )}
          >
            {message}
          </span>
        </div>
        <p
          className={cn(
            "ml-8 mr-4 mb-2 text-sm",
            type === "success" ? "text-white" : "text-red-600"
          )}
        >
          {type === "success"
            ? "Profile loaded successfully. You can try and upload other pitch decks."
            : "We were not successful with loading the entire profile. You can try to reupload the pitch deck and try again."}
        </p>
        <div className="flex justify-end">
          <button
            className={cn(
              "text-sm py-1.5 px-4 rounded mr-2",
              type === "success"
                ? "bg-white text-aqua-400"
                : "bg-red-600 text-white"
            )}
            onClick={() => navigate("/aia")}
          >
            {type === "success"
              ? "Upload another pitch deck"
              : "Reupload the pitch deck"}
          </button>
          <button
            className={cn(
              "text-sm py-1.5 px-4 rounded border",
              type === "success"
                ? "border-white text-white"
                : "border-red-600 bg-fuchsia-100 text-gray-600"
            )}
            onClick={() => setClosed(true)}
          >
            Done
          </button>
        </div>
      </div>
    </Transition>
  );
};

export default ToastMessages;
