import { Link } from "react-router-dom";
import { FC } from "react";

interface ToProfileProps {
  startup: {
    _id: string;
    slug: string;
  };
  getLink: string;
}

const ToProfile: FC<ToProfileProps> = ({ startup, children, getLink }) => (
  <div key={startup._id} className="w-[24rem] h-[21rem] flex mx-auto lg:mx-0">
    {startup.slug ? (
      <Link to={getLink} state={{ isFromBoard: true }}>
        {children}
      </Link>
    ) : (
      {children}
    )}
  </div>
);

export default ToProfile;
