import Loading from "atoms/Loading/Loading";
import { Cohort } from "gql/graphql";
import { LOGIN_PAGE_BUTTON } from "models/auth/constants";
import { isLoadingEnabled } from "models/loaders/selectors";
import { getCohort, getUser } from "models/user/selectors";
import { BackgroundGradient, HeaderDecor } from "pages/onboard/desktop/decors";
import TeaserStep from "pages/onboard/desktop/TeaserStep";
import { useSteps } from "pages/onboard/hooks";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CircleDecor from "./components/CircleDecor";
import CohortForm from "./components/CohortForm";
import NewLoginForm from "./components/NewLoginForm";
import SuccessScreen from "./components/SuccessScreen";
import { initialize } from "./sagaActions";

const NewLoginPage: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cohort: Cohort = useSelector((state) => getCohort(state));
  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, LOGIN_PAGE_BUTTON)
  );
  const user = useSelector(getUser);
  const { isActive, next, payload } = useSteps(["login", "teaser", "success"]);

  useEffect(() => {
    dispatch(initialize({ params }));
  }, []);

  useEffect(() => {
    if (cohort && user && isActive("login")) {
      window.localStorage.setItem("loginToCohort", `/register/${cohort.slug}`);
      navigate("/");
    } else {
      window.localStorage.removeItem("loginToCohort");
    }
  }, [user, cohort, navigate]);

  if (isLoading) {
    return <Loading fullPage />;
  }

  return (
    <div className="relative w-screen h-screen text-white md:overflow-hidden">
      <BackgroundGradient type={"login"} />
      <HeaderDecor spread={isActive("teaser")} />
      <CircleDecor />
      {cohort ? (
        <CohortForm onNext={next} cohort={cohort} show={isActive("login")} />
      ) : (
        <NewLoginForm />
      )}
      <TeaserStep
        show={isActive("teaser")}
        onNext={next}
        onFinish={next}
        payload={payload}
      />
      {cohort && <SuccessScreen cohort={cohort} show={isActive("success")} />}
    </div>
  );
};

export default NewLoginPage;
