import type { ApplicationsListViewProps } from "../interfaces";
import { FC, useEffect, useState } from "react";
import { ReactComponent as CopyIcon } from "images/copy-btn.svg";
import { ReactComponent as TickIcon } from "images/tick-mark.svg";
import { ReactComponent as SettingsIcon } from "images/setting-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { format } from "date-fns";
import { pluralize } from "utils/pluralize";
import { cohortLink } from "../../../utils/links";
import Switch from "atoms/Switch/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";

const RESET_COPIED_AFTER_MS = 2000;

const ApplicationsListMobile: FC<ApplicationsListViewProps> = ({
  items,
  onEdit,
  getOnToggle,
}) => {
  const [copiedId, setCopiedId] = useState("");
  const [expandState, setExpandState] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const id = window.setInterval(() => setCopiedId(""), RESET_COPIED_AFTER_MS);

    return () => window.clearInterval(id);
  }, [copiedId]);

  const getHandleCopy = (id: string) => () => setCopiedId(id);

  const getHandleExpand = (id: string) => () =>
    setExpandState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

  const isExpanded = (id: string) => expandState[id];

  const getArrowIconClass = (id: string) =>
    ["ml-2 text-gray-400 transition", isExpanded(id) ? "rotate-90" : ""].join(
      " "
    );

  const iconButtonClass =
    "bg-white border border-gray-400 p-1.5 rounded-full flex justify-center items-center";

  return (
    <div className="pb-8">
      {items.map((row, idx) => (
        <div
          className="px-2 py-1 mb-6 border border-gray-200 rounded-md shadow-md"
          key={idx}
        >
          <div className="flex items-center justify-between">
            <div>
              <span className="inline-block leading-5">{row.name}</span>
              <div className="text-sm text-gray-400">
                <span>{format(row.createdAt, "dd MMM yyyy")}</span>,{" "}
                <span>{pluralize("application", row.stats.total)}</span>
              </div>
            </div>
            <div className="flex items-center">
              <CopyToClipboard
                text={cohortLink(row.slug)}
                onCopy={getHandleCopy(row.id)}
              >
                <button className={iconButtonClass}>
                  {copiedId === row.id ? (
                    <TickIcon width="18" height="18" />
                  ) : (
                    <CopyIcon width="18" height="18" />
                  )}
                </button>
              </CopyToClipboard>
              <FontAwesomeIcon
                onClick={getHandleExpand(row.id)}
                className={getArrowIconClass(row.id)}
                size="sm"
                icon={["fas", "chevron-right"]}
              />
            </div>
          </div>
          <div
            className={
              isExpanded(row.id) ? "flex justify-between mt-2" : "hidden"
            }
          >
            <Button
              onClick={() => onEdit(row)}
              color="white"
              size="small"
              className="mr-1"
            >
              <SettingsIcon width="16" height="16" className="mr-2" />
              <span>Edit</span>
            </Button>
            <Switch checked={row.isOpen} onChange={getOnToggle(row)}></Switch>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApplicationsListMobile;
