// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import { updateCountryCode, updateGlobalState } from "./reducer";
import { setCountryCode, setGlobalState } from "./sagaActions";

export function* handleGlobalState({ payload: { ...stateArgs } }) {
  yield put(updateGlobalState({ stateArgs }));
}

export function* handleUpdateCountryCode({ payload: { countryCode } }) {
  if (countryCode) yield put(updateCountryCode({ countryCode }));
  else {
    countryCode = yield fetch("https://ipinfo.io/json")
      .then((res) => res.json())
      .then((data) => data.country)
      .catch((err) => console.log(err.message));
    yield put(updateCountryCode({ countryCode }));
  }
}

export default function* rootSaga() {
  return [
    yield takeEvery(setGlobalState, handleGlobalState),
    yield takeEvery(setCountryCode, handleUpdateCountryCode),
  ];
}
