import { FC, useEffect, useMemo, useState } from "react";
import PureHtml from "atoms/PureHtml";
import DOMPurify from "dompurify";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "atoms/Loading/Loading";
import { shuffle } from "utils/shuffle";
import { getSortSeed } from "utils/seed";
import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import Button from "atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import ViewIdentification from "organisms/ViewIdentification/ViewIdentification";
import { pluralize } from "utils/pluralize";
import cn from "classnames";
import { GET_CURRENCY_LIST } from "queries/general/me";
import { GET_STARTUP_BOARD_BY_SLUG } from "queries/investor/boards";
import BoardStartups from "../components/BoardStartups";
import TopNavBar from "atoms/TopNavBar/TopNavBar";

const sortSeed = getSortSeed();

const PublicStartupBoard: FC = () => {
  const history = useNavigate();
  const { slug } = useParams();
  const {
    data: boardData,
    loading,
    error,
  } = useQuery(GET_STARTUP_BOARD_BY_SLUG, {
    variables: {
      slug: slug as string,
    },
  });

  const user = useSelector(getUser);
  const { data: currencyData } = useQuery(GET_CURRENCY_LIST);
  const [isSharing, setIsSharing] = useState(false);
  const board = boardData?.getStartupBoardBySlug;
  const startups = useMemo(
    () => shuffle(board?.startups || [], sortSeed),
    [board?.startups]
  );
  const [isIdentitySent, setIsIdentitySent] = useState(false);

  useEffect(() => {
    DOMPurify.setConfig({ ADD_ATTR: ["target"] });
  }, []);

  useEffect(() => {
    if (!board?.isDarkMode) {
      return;
    }

    const element = document.documentElement;

    element.classList.add("dark");

    return () => {
      element.classList.remove("dark");
    };
  }, [board]);

  useEffect(() => {
    if (error) {
      history("/");
    }
  }, [error]);

  if (loading) {
    return <Loading fullPage />;
  }

  const handleShare = () => setIsSharing(true);

  const handleCloseShare = () => setIsSharing(false);

  const getShareButton = () => (
    <Button color="primary" size="small" onClick={handleShare}>
      <FontAwesomeIcon icon={["fas", "share-alt"]} className="mr-2" />
      <span>Share</span>
    </Button>
  );

  const handleIdentification = (isIdentified: boolean) => {
    if (isIdentified) {
      setIsIdentitySent(true);
    } else {
      history("/");
    }
  };

  const isShowIdentification = board?.isIdentityRequired && !isIdentitySent;

  return (
    <div className="w-full dark:text-white/80 dark:bg-[#1C1C1C] pb-16 mx-auto">
      <TopNavBar />
      <div
        className={cn("container mx-auto", isShowIdentification && "blur-lg")}
      >
        <div className="flex flex-col p-12 lg:pr-36">
          {board?.logo?.large?.location && (
            <div>
              <img
                className="mb-8 sm:self-start max-w-[12rem] max-h-30"
                src={board.logo.large.location}
                alt="board-logo"
              />
            </div>
          )}

          <div className="items-center sm:flex sm:flex-col sm:items-start">
            <div className="flex flex-wrap justify-between w-full">
              <h1 className="mb-3 text-xl sm:text-5xl">{board?.title}</h1>
            </div>
            <div
              className={cn(
                "max-w-3xl mt-4 mb-4 text-sm boards",
                board?.isDarkMode ? "dark-board" : ""
              )}
            >
              <PureHtml content={board?.description as string} />
            </div>
            <div>{getShareButton()}</div>
          </div>
        </div>
        <div>
          <BoardStartups
            isPublic
            startups={startups}
            currencyData={currencyData}
            slug={board?.slug}
            isDarkMode={board?.isDarkMode}
          />
        </div>

        {isSharing && board && (
          <PublicShare
            title={board.title as string}
            publicUrl={`${window.location.origin}/board/public/${board.slug}`}
            onClose={handleCloseShare}
          />
        )}
      </div>

      {isShowIdentification && (
        <ViewIdentification
          onFinish={handleIdentification}
          boardSlug={board.slug as string}
        >
          <div className="flex flex-col items-center text-center text-gray-600 dark:text-white/80">
            {Boolean(board.logo?.large?.location) && (
              <img
                className="object-cover mb-4 max-w-[12rem] max-h-30"
                src={board.logo?.large?.location as string}
                alt="board-logo"
              />
            )}
            <h1>{board.title}</h1>
            <div className="px-4 mt-4 border-t border-gray">
              <p className="mt-4">
                The owner of the board has restricted access to this page.
              </p>
              <p>
                Please introduce yourself in order to view the{" "}
                {pluralize("startup", startups.length)}.
              </p>
            </div>
          </div>
        </ViewIdentification>
      )}
    </div>
  );
};

export default PublicStartupBoard;
