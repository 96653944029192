import { FC } from "react";
import { FieldName } from "gql/graphql";
import withValueEditable from "organisms/ValueEditable/ValueEditable";

interface Props {
  content: string;
}

const Value: FC<Props> = ({ content }) => {
  return (
    <a
      className="inline-block hover:underline text-aqua-400 whitespace-pre-wrap"
      href={content}
      target="_blank"
      rel="noreferrer"
    >
      {content}
    </a>
  );
};

const EditableValue = withValueEditable<Props>(
  Value,
  FieldName.GeneralInfoWebsite
);

const Website: FC<Props> = ({ content }) => {
  return (
    <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
      <h6 className="font-medium">Website</h6>
      <EditableValue content={content} />
    </div>
  );
};

export default Website;
