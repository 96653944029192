import Sdg, { sdgIcons } from "molecules/Sdg/Sdg";
import { ReactComponent as CheckIcon } from "images/circle-check-icon.svg";

type SdgProps = {
  sdgs: number[];
  updateSdg: (sdg: number) => void;
};

export default ({ sdgs, updateSdg }: SdgProps) => (
  <>
    {sdgIcons.map((sdg, index) => {
      const isActive = sdgs.includes(index + 1);
      return (
        <Sdg
          active={isActive}
          key={index}
          number={index + 1}
          onClick={() => updateSdg(index + 1)}
        >
          {sdgIcons[index]}
          {isActive && <CheckIcon className="check-icon" />}
        </Sdg>
      );
    })}
  </>
);
