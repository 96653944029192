import { FC, useState } from "react";
import type { TeaserStepProps } from "../interfaces";
import ButtonGroup from "molecules/Form/ButtonGroup";
import {
  BUSINESS_CATEGORY,
  BUSINESS_MODEL,
  CURRENT_COMPANY_STAGE,
  INDUSTRY_CATEGORIES_SWEDISH,
} from "core/consts";
import BackButton from "../BackButton";
import cn from "classnames";

const MODELS_WITHOUT_OTHER = Object.entries(BUSINESS_MODEL).filter(
  ([key]) => key !== "OTHERS"
);
const CATEGORY_WITHOUT_OTHER = Object.entries(BUSINESS_CATEGORY).filter(
  ([key]) => key !== "OTHER"
);

const Basics: FC<TeaserStepProps> = ({ onNext, onPrev }) => {
  const [stage, setStage] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);

  const isValid =
    stage.length && models.length && categories.length && industries.length;

  const handleNext = () => {
    setIsError(!isValid);

    if (isValid) {
      onNext({
        basics: {
          stage: stage[0],
          models,
          categories,
          industries,
        },
      });
    }
  };

  return (
    <div className="w-full h-full px-2 py-8 md:px-16">
      <BackButton onAction={onPrev} />
      <h2 className="text-xl text-center">Company information</h2>
      <div className="my-8 font-bold text-center text-gray-500">
        A few of the basics
      </div>
      <div>
        <div className="flex flex-col items-start mt-6">
          <label
            className={cn(
              "mb-2 text-sm ml-2",
              isError && !stage.length ? "text-red-400" : "text-gray-400"
            )}
            htmlFor="startup-stage"
          >
            What is your startup stage
          </label>
          <ButtonGroup
            id="startup-stage"
            options={Object.entries(CURRENT_COMPANY_STAGE)}
            className="!mb-2 !mr-2"
            onChange={setStage}
            limit={1}
          />
        </div>
        <div className="flex flex-col items-start mt-6">
          <label
            className={cn(
              "mb-2 text-sm ml-2",
              isError && !models.length ? "text-red-400" : "text-gray-400"
            )}
            htmlFor="business-model"
          >
            What is your business relationship model?
          </label>
          <ButtonGroup
            id="business-model"
            options={MODELS_WITHOUT_OTHER}
            className="!mb-2 !mr-2"
            onChange={setModels}
          />
        </div>
        <div className="flex flex-col items-start mt-6">
          <label
            className={cn(
              "mb-2 text-sm ml-2",
              isError && !categories.length ? "text-red-400" : "text-gray-400"
            )}
            htmlFor="product-category"
          >
            What product category are you most interested in?
          </label>
          <ButtonGroup
            id="product-category"
            options={CATEGORY_WITHOUT_OTHER}
            className="!mb-2 !mr-2"
            onChange={setCategories}
          />
        </div>
        <div className="flex flex-col items-start mt-6">
          <label
            className={cn(
              "mb-2 text-sm ml-2",
              isError && !industries.length ? "text-red-400" : "text-gray-400"
            )}
            htmlFor="industry-category"
          >
            What industry do you operate in?
          </label>
          <ButtonGroup
            id="industry-category"
            options={Object.entries(INDUSTRY_CATEGORIES_SWEDISH)}
            className="!mb-2 !mr-2"
            onChange={setIndustries}
          />
        </div>
      </div>
      <div className="flex items-center justify-between mt-8 pb-4">
        <span className="mr-8 text-sm text-red-500">
          {isError &&
            !isValid &&
            "Please fill all required fields in order to give you the best experience later"}
        </span>
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Basics;
