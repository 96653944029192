import { moduleTypes } from "core/modules";

const module = [
  {
    path: "/applications",
    key: "applications",
    componentId: "applications",
    component: import(/* webpackChunkName: "applications" */ "./Applications"),
    routes: [],
    module: moduleTypes.APPLICATIONS,
    isEnabled: () => true,
    importReducer: () => [],
    importSagas: () => [],
    title: "Applications",
    exact: true,
    name: "Applications",
  },
];

export default module;
