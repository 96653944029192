// @ts-nocheck
import { useLang } from "core/lang/LangaugeSetting";
import { getUserConfirmationStatus } from "models/auth/selectors";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Confirmation = () => {
  const { lang } = useLang();
  const isFailed = !useSelector((state) => getUserConfirmationStatus(state));

  return (
    <div
      className="px-4 pt-5 pb-4 mt-6 overflow-hidden bg-white rounded-lg shadow-xl transform transition-all  sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div>
        <div
          className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full ${
            isFailed ? "bg-yellow-100" : "bg-aqua-100"
          }`}
        >
          {isFailed ? (
            <svg
              className="w-6 h-6 text-yellow-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          ) : (
            <svg
              className="w-6 h-6 text-aqua-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg font-medium text-gray-900 leading-6"
            id="modal-headline"
          >
            {isFailed
              ? lang.accountIsAlreadyVerifiedWithThisEmail
              : lang.yourAccoundIsVerified}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500 leading-5">
              {isFailed
                ? lang.clickTheButtonBelowToLogin
                : lang.clickTheButtonBelowToLogin}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6">
        <span className="flex w-full rounded-md shadow-sm">
          <Link to="/" className="w-full">
            <button
              type="button"
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md bg-aqua-400 leading-6 shadow-sm hover:bg-aqua-500 focus:outline-none focus:border-aqua-700 focus:ring-aqua transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              {lang.login}
            </button>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Confirmation;
