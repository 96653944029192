import type { ApplicationsListProps, Cohort } from "../interfaces";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { ACTIVATE_COHORT, DEACTIVATE_COHORT } from "queries/investor/cohort";

const useApplicationsList = ({ onClose, refetch }: ApplicationsListProps) => {
  const dispatch = useDispatch();
  const [closingCohort, setClosingCohort] = useState<Cohort>();
  const [deactivateCohort] = useMutation(DEACTIVATE_COHORT);
  const [activateCohort] = useMutation(ACTIVATE_COHORT);

  const getHandleCopy = (cohort: Cohort) => () =>
    dispatch(
      triggerSnack({
        type: "success",
        title: "Link copied",
        message: cohort.isOpen
          ? "You can share the link with startups and they can apply"
          : "Pay attention that the link isn't activated",
      })
    );

  const getHandleToggle = (cohort: Cohort) => {
    if (!cohort.isOpen) {
      return async () => {
        try {
          await activateCohort({
            variables: {
              id: cohort.id,
            },
          });
          refetch();
        } catch (error: any) {
          dispatch(
            triggerSnack({
              type: "error",
              title: "Couldn't activate the link",
              message: "Something went wrong",
            })
          );
        }
      };
    }

    return () => {
      setClosingCohort(cohort);
    };
  };

  const handleCloseConfirmation = () => setClosingCohort(undefined);

  const handleConfirm = async () => {
    if (!closingCohort) {
      return;
    }

    if (!closingCohort.isOpen) {
      setClosingCohort(undefined);

      return;
    }

    try {
      await deactivateCohort({
        variables: {
          id: closingCohort.id,
        },
      });

      refetch();
      onClose({ ...closingCohort, isOpen: false });
    } catch (err: any) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Couldn't deactivate the link",
          message: "Something went wrong",
        })
      );
    } finally {
      setClosingCohort(undefined);
    }
  };

  const isConfirmation = Boolean(closingCohort);

  return {
    isConfirmation,
    getHandleCopy,
    getHandleToggle,
    handleCloseConfirmation,
    handleConfirm,
  };
};

export default useApplicationsList;
