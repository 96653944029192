// @ts-nocheck
import {
  SubmitHandler,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { nanoid } from "@reduxjs/toolkit";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getTeam } from "./selectors";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import {
  saveTeam,
  removeTeamMember,
  removeFounder as removeFounderAction,
} from "./sagaActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGroup from "molecules/Form/ButtonGroup";
import { GENDERS, TEAM_SIZE } from "core/consts";
import TeamMemberInput, { TeamMember } from "./TeamMemberInput";
import { ReactComponent as TeamsIcon } from "images/teams-icon.svg";
import { FC, useEffect } from "react";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import cn from "classnames";

type FieldValues = {
  teamSize: string;
  teamNote: string;
  teamMembers: TeamMember[];
  founders: TeamMember[];
};

type TeamData = FieldValues;

interface Props {
  inline?: boolean;
  onWatch?: (values: FieldValues) => void;
}

const TeamsForms: FC<Props> = ({ inline, onWatch }) => {
  const data: TeamData = useSelector(getTeam);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    unregister,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: data,
  });

  onWatch?.(watch());

  const { fields, append, remove } = useFieldArray({
    name: "teamMembers",
    control,
  });

  (window as any).tmp = getValues;

  const {
    fields: founders,
    append: appendFounderField,
    remove: removeFounderField,
  } = useFieldArray({
    name: "founders",
    control,
  });

  const { lang } = useLang();
  const dispatch = useDispatch();

  const loading = useSelector((state) => isLoadingEnabled(state, STEP.TEAMS));

  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    dispatch(
      saveTeam({
        lang,
        input: data,
      })
    );
  };

  const addTeamMember = () => {
    append({
      id: nanoid(),
      name: "",
      gender: Object.keys(GENDERS)[0],
      title: "",
      email: "",
      linkedin: "",
      image: undefined,
    });
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const removeMember = (id: string) => {
    const isExisting =
      data.teamMembers.findIndex((member) => member.id === id) >= 0;
    if (isExisting) {
      dispatch(removeTeamMember({ lang, id }));
    }

    const memberIndex = getValues("teamMembers").findIndex(
      (member) => member.id === id
    );

    remove(memberIndex);
  };

  const addFounder = () => {
    appendFounderField({
      id: nanoid(),
      name: "",
      gender: Object.keys(GENDERS)[0],
      title: "",
      email: "",
      linkedin: "",
      image: "",
      previouslyFounded: 0,
      successfulExits: 0,
    });

    const founderEls = document.querySelectorAll(".founders-item");

    if (founderEls.length) {
      const lastFounder = founderEls[founderEls.length - 1];
      const { bottom } = lastFounder.getBoundingClientRect();

      window.scrollTo({
        top: bottom,
        behavior: "smooth",
      });
    }
  };

  const removeFounder = (id: string) => {
    const isExisting =
      data?.founders.findIndex((founder) => founder.id === id) >= 0;
    if (isExisting) {
      dispatch(removeFounderAction({ lang, id }));
    }

    const founderIndex = getValues("founders").findIndex(
      (founder) => founder.id === id
    );

    removeFounderField(founderIndex);
  };

  useEffect(() => {
    if (getValues("founders")?.length === 0) {
      addFounder();
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      {!inline && (
        <PageHeader>
          <div className="flex-1 min-w-0 mb-6">
            <div className="flex items-center">
              <TeamsIcon />
              <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
                {lang.team}
              </h1>
            </div>
            <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
              <p>{lang.companyTeamsDesc}</p>
            </div>
          </div>
        </PageHeader>
      )}
      <div className="relative pb-12">
        {!inline && (
          <div className="">
            <label
              htmlFor="businessCategory"
              className="text-sm font-medium text-black capitalize text-opacity-100"
            >
              {lang.selectYourTeamSize}
            </label>
            <div className="mt-2">
              <Controller
                name="teamSize"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ButtonGroup
                    id="teamSize"
                    name="teamSize"
                    options={Object.entries(TEAM_SIZE)}
                    limit={1}
                    onChange={(data: [string, string]) => onChange(data[0])}
                    type="company"
                    defaultValue={value}
                  />
                )}
              />
            </div>
          </div>
        )}

        <div
          className="absolute right-0 block text-sm text-right cursor-pointer align-right"
          style={{ color: "#18BEB3" }}
          onClick={addFounder}
        >
          <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />
          {lang.addCoFounder}
        </div>

        {founders.map((item, index) => (
          <TeamMemberInput
            key={item.id}
            id={item.id}
            control={control}
            register={register}
            unregister={unregister}
            index={index}
            errors={errors}
            removeItem={removeFounder}
            fieldName="founders"
            label={lang.founderDetails}
          />
        ))}

        {!inline && (
          <>
            <div className="mt-10"></div>

            <div
              className="absolute right-0 block mb-4 text-sm text-right cursor-pointer align-right"
              style={{ color: "#18BEB3" }}
              onClick={addTeamMember}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} className="mr-1" />
              {lang.addAMember}
            </div>

            {fields.map((member, index) => (
              <TeamMemberInput
                key={member.id}
                id={member.id}
                control={control}
                register={register}
                unregister={unregister}
                index={index}
                errors={errors}
                removeItem={removeMember}
                fieldName="teamMembers"
                label={lang.memberDetails}
              />
            ))}
            <div className="pt-4">
              <TextArea
                rows="3"
                id="teamNote"
                className={`form-textarea block mt-4 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                  errors.teamNote
                    ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
                    : ""
                }`}
                placeholder={lang.addNoteAboutTeam}
                defaultValue={data?.teamNote}
                {...register("teamNote")}
                maxLength={200}
              ></TextArea>
            </div>
          </>
        )}
      </div>
      {!inline && (
        <StepActionButton_Stateful>
          <Button
            size="small"
            color="white"
            className="preview-btn"
            onClick={() => dispatch(goToPreviousStep())}
          >
            <span>{lang.back}</span>
          </Button>
          <Button size="small" color="primary" type="submit" loading={loading}>
            <span>{lang.saveAndContinue}</span>
          </Button>
        </StepActionButton_Stateful>
      )}
    </form>
  );
};

export default TeamsForms;
