import { FC } from "react";
import ContactModal from "./ContactModal/ContactModal";

// @TODO: refactor
//
// - Remove unused CompanyContactRequestModal component
// - Rename InvestorContactModal
// - Review state sharing
//

interface ContactRequestModalProps {
  id: string;
  recieverEmail?: string;
  skipSuccessModal: boolean;
  onClose?: () => void;
  type?: "investor" | "startup";
}

const ContactRequestModal: FC<ContactRequestModalProps> = ({
  id,
  recieverEmail,
  skipSuccessModal,
  onClose,
  type = "startup",
}) => (
  <ContactModal
    id={id}
    skipSuccessModal={skipSuccessModal}
    onClose={onClose}
    type={type}
    recieverEmail={recieverEmail}
  />
);

export default ContactRequestModal;
