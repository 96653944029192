import { Mixpanel } from "core/mixpanel/MixpanelWrapper";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useMixpanel = () => {
  const location = useLocation();

  useEffect(() => {
    const onPath = location.pathname.substring(1);

    Mixpanel.track("Page View", {
      $pageViewed: onPath,
    });
    Mixpanel.timeEvent("Page View");
  }, [location.pathname]);
};

export default useMixpanel;
