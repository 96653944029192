// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { STEP } from "./constants";

export const initialState = {
  currentStep: STEP.PROFILE,
  completedMajorSteps: [],
  completedSteps: [],
  profileInfo: null,
  impactSdgs: null,
  basicInfo: null,
  elevatorPitch: null,
  documents: null,
  socialNetworks: null,
  isApplicationSubmitSuccess: false,
};

export const name = "companySettings";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateStepsInfo: (
      state,
      { payload: { completedMajorSteps, completedSteps } }
    ) => ({
      ...state,
      completedMajorSteps,
      completedSteps,
    }),
    updateCurrentStep: (state, { payload: { currentStep } }) => ({
      ...state,
      currentStep,
    }),
    goToNextStep: (state) => {
      const { currentStep } = state;
      const stepsArr = Object.values(STEP);
      const currentStepIndex = stepsArr.indexOf(currentStep);
      if (currentStepIndex === stepsArr.length - 1) {
        return state;
      }
      const nextStep = stepsArr[currentStepIndex + 1];
      return {
        ...state,
        currentStep: nextStep,
      };
    },
    goToPreviousStep: (state) => {
      const { currentStep } = state;
      const stepsArr = Object.values(STEP);
      const currentStepIndex = stepsArr.indexOf(currentStep);
      if (currentStepIndex === 0) {
        return state;
      }
      const prevStep = stepsArr[currentStepIndex - 1];
      return {
        ...state,
        currentStep: prevStep,
      };
    },
    updateProfileInfo: (state, { payload: { profileInfo } }) => ({
      ...state,
      profileInfo: { ...state.profileInfo, ...profileInfo },
    }),
    updateBasicInfo: (state, { payload: { basicInfo } }) => ({
      ...state,
      basicInfo: { ...state.basicInfo, ...basicInfo },
    }),
    updateElevatorPitch: (state, { payload: { elevatorPitch } }) => ({
      ...state,
      elevatorPitch: { ...state.elevatorPitch, ...elevatorPitch },
    }),
    updateProductDetails: (state, { payload: { product } }) => ({
      ...state,
      product: { ...state.product, ...product },
    }),
    updateCompanyMarket: (state, { payload: { market } }) => ({
      ...state,
      market: { ...state.market, ...market },
    }),
    updateBusinessModel: (state, { payload: { businessModel } }) => ({
      ...state,
      businessModel: { ...state.businessModel, ...businessModel },
    }),
    updateBusinessTiming: (state, { payload: { timing } }) => ({
      ...state,
      timing: { ...state.timing, ...timing },
    }),
    updateCompanyImpact: (state, { payload: { impactSdgs } }) => {
      state.impactSdgs = impactSdgs;
    },
    updateIsImpact: (state, { payload: { isImpact } }) => {
      state.impactSdgs.impact.isImpact = isImpact;
    },
    updateTeam: (state, { payload: { team } }) => ({
      ...state,
      team: { ...state.team, ...team },
    }),
    updateDocuments: (state, { payload: { documents } }) => ({
      ...state,
      documents,
    }),
    updateSocialNetworks: (state, { payload: { socialNetworks } }) => ({
      ...state,
      socialNetworks: { ...state.socialNetworks, ...socialNetworks },
    }),
    updateFundraising: (state, { payload: { fundraising } }) => ({
      ...state,
      fundraising: { ...state.fundraising, ...fundraising },
    }),
    updateTraction: (state, { payload: { traction } }) => ({
      ...state,
      traction: { ...state.traction, ...traction },
    }),
    updateApplicationSubmitSuccess: (state, { payload: { isShown } }) => ({
      ...state,
      isApplicationSubmitSuccess: isShown,
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const {
  updateStepsInfo,
  updateCurrentStep,
  goToNextStep,
  goToPreviousStep,
  updateProfileInfo,
  updateBasicInfo,
  updateElevatorPitch,
  updateProductDetails,
  updateCompanyMarket,
  updateBusinessModel,
  updateBusinessTiming,
  updateCompanyImpact,
  updateIsImpact,
  updateTeam,
  updateDocuments,
  updateSocialNetworks,
  updateFundraising,
  updateTraction,
  updateApplicationSubmitSuccess,
} = slice.actions;
