import { FC } from "react";
import cn from "classnames";
import ExpandableSection from "./ExpandableSection";
import { ActivationSectionProps } from "organisms/Activation/types";

const BoardsSection: FC<ActivationSectionProps> = ({
  isOpen,
  isDone,
  onClick,
  onButtonClick,
  userType,
}) => (
  <ExpandableSection
    number="2"
    title={userType === "investor" ? "Create a Board" : "Check out Analytics"}
    isOpen={isOpen}
    isDone={isDone}
    onClick={onClick}
  >
    <div className="mb-4 ml-10 mr-4 -mt-2">
      <p
        className={cn(
          "max-w-[20rem] text-sm text-white",
          isDone && "line-through"
        )}
      >
        {userType === "investor"
          ? "This feature allows you to group a number of companies into a nice looking presentation. With a single link, you can share your entire portfolio or your absolute top pick - you get it - you choose!"
          : "See who visited your profile and when, adjust your privacy and decide if your profile should be available for everyone or keep it private within our network"}
      </p>
      {!isDone && (
        <button
          className="px-3 py-2 mt-3 text-sm bg-white rounded-md text-aqua-400"
          onClick={onButtonClick}
        >
          {userType === "investor" ? "Create my first board" : "View analytics"}
        </button>
      )}
    </div>
  </ExpandableSection>
);

export default BoardsSection;
