const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const restApiBaseUrl =
  process.env.REACT_APP_REST_URL || "http://localhost:3001";
const graphqlApiEndpoint =
  process.env.REACT_APP_GRAPHQL_URL || "http://localhost:3001/graphql";
const socketUrl = restApiBaseUrl;

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
const hotjarToken = Number(String(process.env.REACT_APP_HOTJAR_TOKEN));
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const linkedinClientID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;

export {
  graphqlApiEndpoint,
  stripePublicKey,
  googleClientID,
  linkedinClientID,
  googleMapApiKey,
  baseUrl,
  mixpanelToken,
  hotjarToken,
  restApiBaseUrl,
  socketUrl,
};
