import { Fragment, useState, FC, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { GENERATE_EMAIL, GET_EMAIL_SETTINGS } from "queries/aia/generateEmail";
import {
  EmailType,
  GenerateEmailMutation,
  AiaMutationGenerateEmailDraftArgs,
  GetEmailDraftSettingsQuery,
  Language,
} from "gql/graphql";
import EmailTypeSelectAndResult from "./EmailTypeSelectAndResult";
import { mixpanelAiaGenerateEmail } from "core/mixpanel/Mixpanel";

interface Props {
  companyId: string;
  user: any;
  isOpen: boolean;
  onClose: () => void;
}

const GenerateEmailModal: FC<Props> = ({
  companyId,
  user,
  isOpen,
  onClose,
}) => {
  const [additionalThoughts, setAdditionalThoughts] = useState("");
  const [emailType, setEmailType] = useState<EmailType>(EmailType.Rejection);
  const [emailLanguage, setEmailLanguage] = useState<Language>(
    Language.English
  );

  const { data: settings, loading: settingsLoading } =
    useQuery<GetEmailDraftSettingsQuery>(GET_EMAIL_SETTINGS);

  const [generateEmail, { data, loading }] = useMutation<
    GenerateEmailMutation,
    AiaMutationGenerateEmailDraftArgs
  >(GENERATE_EMAIL);

  useEffect(() => {
    if (settings?.getEmailDraftSettings?.language)
      setEmailLanguage(settings.getEmailDraftSettings.language);
  }, [settings]);

  const handleGenerateEmail = () => {
    generateEmail({
      variables: {
        companyId,
        emailType,
        language: emailLanguage,
        additionalThoughts,
      },
    });

    mixpanelAiaGenerateEmail(user);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center sm:p-10 text-center max-w-5xl mx-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <EmailTypeSelectAndResult
                  emailType={emailType}
                  setEmailType={setEmailType}
                  emailLanguage={emailLanguage}
                  setEmailLanguage={setEmailLanguage}
                  additionalThoughts={additionalThoughts}
                  setAdditionalThoughts={setAdditionalThoughts}
                  onClose={() => onClose()}
                  onGenerate={handleGenerateEmail}
                  generatedEmail={data?.aia?.generateEmailDraft?.text}
                  loading={loading}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateEmailModal;
