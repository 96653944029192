import { createAction } from "@reduxjs/toolkit";

export const getTeam = createAction("companySettings/team/fetch");

export const saveTeam = createAction(
  "companySettings/team/save",
  (payload) => ({
    payload,
  })
);

export const removeTeamMember = createAction(
  "companySettings/team/remove",
  (payload) => ({
    payload,
  })
);

export const removeFounder = createAction(
  "companySettings/profileInfo/removeFounder",
  (payload) => ({
    payload,
  })
);
