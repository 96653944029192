import type { Currency } from "pages/dealflow/InvestorDealflow/interfaces";
import type { FC, ReactNode } from "react";
import type { SectionProps } from "./interfaces";
import { MILIFY_CURRENCY_FORMAT } from "core/consts";

const getMoney = (value: string, currency: Currency | null) => {
  const formatted = MILIFY_CURRENCY_FORMAT(value);

  if (!currency) {
    return formatted;
  }

  return currency.symbol + formatted;
};

const getCell = (value: string | ReactNode, legend: string) => (
  <div className="flex flex-col p-2 bg-gray-100 border border-gray-200 rounded-lg">
    <span>{value}</span>
    <span className="text-xs text-gray-400">{legend}</span>
  </div>
);

const Finance: FC<SectionProps> = ({ startup, currency }) => (
  <div>
    <div className="grid grid-cols-3 gap-2 px-4">
      {startup.finance?.raisedTarget &&
        getCell(getMoney(startup.finance.raisedTarget, currency), "Target")}
      {startup.valuation &&
        getCell(getMoney(startup.valuation, currency), "Valuation")}
      {startup.finance?.raisedValue &&
        getCell(getMoney(startup.finance.raisedValue, currency), "History")}
      {startup.finance?.minTicketSize &&
        getCell(
          getMoney(startup.finance.minTicketSize, currency),
          "Ticket size"
        )}
      {startup?.investors &&
        startup?.investors?.length > 0 &&
        getCell(String(startup.investors.length), "Investors")}
    </div>
  </div>
);

export default Finance;
