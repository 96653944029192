import { FC } from "react";
import { CompanyTraction, FinancePhase } from "gql/graphql";
import ActiveUsers from "./ActiveUsers";
import TotalUsers from "./TotalUsers";
import GrowthRate from "./GrowthRate";
import RetentionRate from "./RetentionRate";
import ChurnRate from "./ChurnRate";
import AverageSalesPrice from "./AverageSalesPrice";
import NetRevenue from "./NetRevenue";
import RecurringRevenue from "./RecurringRevenue";
import RevenueGrowthRate from "./RevenueGrowthRate";
import GmvGsv from "./GmvGsv";
import CacPayback from "./CacPayback";
import LtvCacRatio from "./LtvCacRatio";
import DemandFrequency from "./DemandFrequency";
import SupplyFrequency from "./SupplyFrequency";
import SignedContracts from "./SignedContracts";
import ContractsInPipeline from "./ContractsInPipeline";
import ValueOfSignedContracts from "./ValueOfSignedContracts";
import ValueOfContractsInPipeline from "./ValueOfContractsInPipeline";
import LettersOfIntent from "./LettersOfIntent";
import AdditionalTraction from "./AdditionalTraction";

interface Props {
  traction: CompanyTraction | null | undefined;
  phase: FinancePhase | undefined | null;
  currencyCode: string;
}

const Traction: FC<Props> = ({ traction, phase, currencyCode }) => {
  return (
    <div className="mt-6 w-full">
      <h1 className="text-xl mb-2">Traction</h1>
      <p>
        What's the most exciting traction metric for your business? Choose at
        least one. If you do not see a metric that best showcases your traction,
        write it in the open text field at the bottom.
      </p>

      <div className="grid grid-cols-1 lg:grid-cols-3">
        <ActiveUsers
          activeUsers={traction?.activeUsers}
          note={traction?.activeUsersNote}
        />

        <TotalUsers
          totalUsers={traction?.totalUsers}
          note={traction?.totalUsersNote}
        />

        <GrowthRate
          growthRate={traction?.growthRate}
          note={traction?.growthRateNote}
        />

        <RetentionRate
          retentionRate={traction?.retentionRate}
          note={traction?.retentionRateNote}
        />

        {phase !== FinancePhase.PreProduct && (
          <ChurnRate
            churnRate={traction?.churnRate}
            note={traction?.churnRateNote}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <NetRevenue
            netRevenue={traction?.netRevenue}
            note={traction?.netRevenueNote}
            currencyCode={currencyCode}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <RecurringRevenue
            recurringRevenue={traction?.recurringRevenue}
            note={traction?.recurringRevenueNote}
            currencyCode={currencyCode}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <RevenueGrowthRate
            revenueGrowthRate={traction?.revenueRate}
            note={traction?.revenueRateNote}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <GmvGsv
            gmvGsv={traction?.smv_gsv}
            note={traction?.smv_gsvNote}
            currencyCode={currencyCode}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <AverageSalesPrice
            salePriceAvg={traction?.salePriceAvg}
            note={traction?.salePriceAvgNote}
            currencyCode={currencyCode}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <CacPayback
            cacPayback={traction?.cacPayback}
            note={traction?.cacPaybackNote}
          />
        )}

        {phase === FinancePhase.PostRevenue && (
          <LtvCacRatio
            ltvCacRatio={traction?.ltcCacRatio} // In the api it is called ltc
            note={traction?.ltcCacRatioNote}
          />
        )}

        {phase !== FinancePhase.PreProduct && (
          <DemandFrequency
            frequency={traction?.demandFreq}
            note={traction?.demandFreqNote}
          />
        )}

        {phase !== FinancePhase.PreProduct && (
          <SupplyFrequency
            frequency={traction?.supplyFreq}
            note={traction?.supplyFreqNote}
          />
        )}

        <SignedContracts
          signedContracts={traction?.signedContracts}
          note={traction?.signedContractsNote}
        />

        <ValueOfSignedContracts
          value={traction?.annualValueContracts}
          note={traction?.annualValueContractsNote}
          currencyCode={currencyCode}
        />

        <ContractsInPipeline
          contracts={traction?.pipelineContracts}
          note={traction?.pipelineContractsNote}
        />

        <ValueOfContractsInPipeline
          value={traction?.annualValueContractsPipeline}
          note={traction?.annualValueContractsPipelineNote}
          currencyCode={currencyCode}
        />

        <LettersOfIntent
          letters={traction?.lettersOfIntent}
          note={traction?.lettersOfIntentNote}
        />
      </div>

      <AdditionalTraction additionalTraction={traction?.additionalTraction} />
    </div>
  );
};

export default Traction;
