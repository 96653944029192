import { FC } from "react";
import cn from "classnames";

interface Props {
  onClick: () => void;
  size?: "normal" | "small";
  className?: string;
}

const EditButton: FC<Props> = ({ onClick, size = "normal", className }) => {
  return (
    <button
      className={cn(
        "underline text-aqua-400 underline-offset-2",
        size === "small" && "text-sm",
        className && className
      )}
      onClick={() => onClick()}
    >
      Edit
    </button>
  );
};

export default EditButton;
