import { FC, useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";
import { Images } from "gql/graphql";

export interface ImagePreviewProps {
  image: Blob | Images;
  index: number;
  onRemove: () => void;
}

const ImagePreview: FC<ImagePreviewProps> = ({ image, index, onRemove }) => {
  const [src, setSrc] = useState<string>("");

  useEffect(() => {
    let url: string | undefined | null;

    if (image instanceof Blob) {
      url = URL.createObjectURL(image);
    } else {
      url = image?.large?.location;
    }

    if (url) {
      setSrc(url);
    }

    return () => {
      if (url) URL.revokeObjectURL(url);
    };
  }, [image]);

  return (
    <div className="relative w-24 h-24 mr-2">
      <img
        src={src}
        alt={`Product Image ${index}`}
        className="w-full h-full rounded-lg hover:opacity-50"
      />
      <CloseIcon
        onClick={() => onRemove()}
        className="absolute w-5 h-5 cursor-pointer -right-1 -top-1 opacity-70 hover:opacity-100 "
      />
    </div>
  );
};

export default ImagePreview;
