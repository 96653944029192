import type { FC } from "react";
import type { PreviewSidebarProps } from "./interfaces";
import { useQuery } from "@apollo/client";

import DefaultImage from "images/dealroom-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "atoms/Loading/Loading";
import InvestorCard from "./InvestorCard";
import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import { isPublicShareModalOpen } from "organisms/ShareProfileModal/selectors";
import ShareButton from "molecules/ShareButton/ShareButton";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import { GET_INVESTOR_BY_SLUG } from "queries/investor/investorProfile";

const PreviewSidebar: FC<PreviewSidebarProps> = ({ record, onClose }) => {
  const { data, loading } = useQuery(GET_INVESTOR_BY_SLUG, {
    variables: {
      slug: record?.company.slug,
    },
    skip: !record,
  });
  const isShareModal = useSelector(isPublicShareModalOpen);
  const user = useSelector(getUser);

  if (!record) {
    return null;
  }

  const link = `/investor/public/${record.company.slug}?us=dealflow`;

  return (
    <aside className="min-w-[24rem] max-w-[24rem] max-h-[calc(100vh-165px)] bg-white ml-4 overflow-y-auto self-end hidden lg:block">
      <article className="min-h-[calc(100vh-165px)] border border-gray-300 rounded-md relative">
        <div className="sticky top-0 z-10 flex justify-between p-4 pb-6 border-b border-gray-300 rounded-t-md bg-gradient-to-r from-aqua-400 to-aqua-600">
          <div className="flex items-start text-white">
            <a
              target="_blank"
              href={link}
              className="inline-block border-4 border-white rounded-full w-14 h-14"
              rel="noreferrer"
            >
              <img
                className="rounded-full object-fit"
                src={record.company?.logo?.small?.location || DefaultImage}
                alt=""
              />
            </a>
            <div className="ml-2">
              <a
                target="_blank"
                href={link}
                className="inline-block text-white truncate max-w-[18rem]"
                rel="noreferrer"
              >
                {record.company.displayName}
              </a>
            </div>
          </div>
          <span className="text-xl text-white cursor-pointer" onClick={onClose}>
            <FontAwesomeIcon icon={["fal", "times"]} />
          </span>
        </div>
        <div>
          {loading && <Loading />}
          {data && (
            <div className="px-4 mb-2">
              <ShareButton small isMe={false} />
            </div>
          )}
          {data && (
            <InvestorCard
              currency={record.company.currencyData}
              investor={data.investorBySlug}
            />
          )}
        </div>
      </article>
      {isShareModal && data && (
        <PublicShare
          publicUrl={
            window.location.origin + `/investor/public/${record.company.slug}`
          }
          title={data.investorBySlug.displayName}
          us="df_share"
          uc={user?.slug}
        />
      )}
    </aside>
  );
};

export default PreviewSidebar;
