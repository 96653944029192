import Button from "atoms/Button/Button";
import { EMAIL_PATTERN } from "core/consts";
import { ReactComponent as Logo } from "images/logo-black.svg";
import { useLang } from "core/lang/LangaugeSetting";
import { getActiveCohort } from "models/user/selectors";
import { isLoadingEnabled } from "models/loaders/selectors";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { pageAnimation } from "ui/animations";
import { Page } from "ui/elements/PanelLayout.styles";
import { forgotPassword } from "../sagaActions";
import { getForgotPasswordStatus } from "../selectors";
import Confirmation from "./Confirmation";
import { FORGOT_PASSWORD_BUTTON } from "../constants";

export const ForgotPasswordForm: React.FC = () => {
  const { lang } = useLang();
  const dispatch = useDispatch();

  // Selectors
  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, FORGOT_PASSWORD_BUTTON)
  );
  const activeCohort = useSelector(getActiveCohort);
  const forgotStatus = useSelector((state) => getForgotPasswordStatus(state));

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const submitHandler = (e: Event) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const onSubmit = () => {
    const formValues = getValues();
    dispatch(
      forgotPassword({
        formValues,
        lang,
      })
    );
  };

  if (forgotStatus === "success") {
    return <Confirmation />;
  }

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
    >
      <div className="w-full max-w-sm mx-auto">
        <div>
          {activeCohort?.logo?.large?.location ? (
            <img
              src={activeCohort?.logo?.large?.location}
              className="w-40 __Logo"
            />
          ) : (
            <Logo className="w-auto h-12" />
          )}
          <h2 className="my-6 text-2xl font-extrabold text-black leading-7">
            {lang.forgotYourPassword}
          </h2>
          <p className="mb-6">{lang.forgotPasswordDesc}</p>
          <p>{lang.forgotPasswordDesc2}</p>
        </div>

        <div className="mt-6">
          <form>
            <div className="mb-4">
              <label
                className="block mb-1 text-sm text-gray-500"
                htmlFor="email"
              >
                {lang.email}
              </label>
              <input
                className={`shadow appearance-none ${
                  !!errors.email ? "border-red-500" : ""
                } border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:ring`}
                id="email"
                type="text"
                {...register("email", {
                  required: true,
                  pattern: EMAIL_PATTERN,
                })}
              />
              {errors.email && (
                <p className="text-xs text-fire-400">
                  {lang.thisIsNotValidEmail}
                </p>
              )}
            </div>

            <div className="mt-6 mb-6">
              <Button
                className="justify-center w-full"
                color="primary"
                size="large"
                type="submit"
                loading={isLoading}
                onClick={submitHandler as any}
              >
                {lang.resetPassword}
              </Button>
            </div>
            <div className="text-sm text-center right-6 top-6">
              <Link
                className="text-sm text-aqua-400 hover:text-aqua-500"
                to="/login"
              >
                {lang.back}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </Page>
  );
};

export default ForgotPasswordForm;
