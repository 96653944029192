// @ts-nocheck
import React, { useState, forwardRef } from "react";

import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import useOnClickOutside from "hooks/useOnClickOutside";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

export default forwardRef(
  ({ onChange, defaultValue, ...props }, forwardedRef) => {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(
      defaultValue ? new Date(defaultValue) : null
    );

    const ref = React.useRef();
    useOnClickOutside(ref, () => setOpen(false));

    const getFormattedDate = (date) => {
      if (!date && defaultValue) {
        return format(new Date(defaultValue), "d MMMM, u", {
          locale: enUS,
        });
      }

      if (!date) {
        return "";
      }

      return format(new Date(date), "d MMMM, u", {
        locale: enUS,
      });
    };

    return (
      <div className="relative" ref={ref}>
        <input
          ref={forwardedRef}
          className={`form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm border-black border-opacity-25`}
          type="text"
          placeholder="Pick a day"
          onClick={(e) => {
            setOpen((prevOpen) => !prevOpen);
          }}
          value={getFormattedDate(date)}
          {...props}
        />
        {open && (
          <div>
            <DayPicker
              mode="single"
              selected={date}
              modifiersStyles={{
                selected: {
                  backgroundColor: "#18BEB3",
                },
              }}
              fromDate={new Date()}
              onSelect={(date) => {
                setDate(date);
                if (typeof onChange === "function") {
                  onChange(date);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
