import { FC } from "react";
import {
  Permission,
  Workspace,
} from "pages/account/components/TeamMembers/types";
import { useDispatch, useSelector } from "react-redux";
import { switchWorkspaces } from "./sagaActions";
import { getWorkspaces } from "models/user/selectors";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as CheckMark } from "images/check-mark.svg";
import cn from "classnames";
import { Tier } from "gql/graphql";

interface Props {
  tier: Tier;
  isMobile?: boolean;
}

const WorkspaceSelector: FC<Props> = ({ tier, isMobile }) => {
  const workspaces: Workspace[] = useSelector(getWorkspaces) || [];
  const activeWorkspace = workspaces.find((ws) => ws.isActive);

  const dispatch = useDispatch();
  const { lang } = useLang();

  const changeWorkspaces = (workspace: Workspace) => {
    if (activeWorkspace?.id === workspace.id) return;
    dispatch(switchWorkspaces({ workspaceId: workspace.id, lang }));
  };

  return (
    <div className={cn(isMobile ? "ml-3" : "-ml-8")}>
      {workspaces
        .filter((w) => w)
        .sort((a, b) => (a.id > b.id ? -1 : 1))
        .map((ws) => (
          <div
            key={ws.id}
            className={cn(
              "flex flex-row items-center py-1  border-slate-600 hover:cursor-pointer  hover:bg-gray-50",
              !isMobile && "border-b  hover:bg-slate-600"
            )}
            onClick={() => changeWorkspaces(ws)}
          >
            <img
              src={
                ws?.inviter?.logo?.small?.location ||
                "/assets/images/investor-placeholder-img.png"
              }
              alt=""
              className="object-contain mb-1 ml-2 mr-2 bg-white rounded-full w-9 h-9"
            />
            <div className="flex flex-col text-xs basis-3/4">
              <span
                className={cn(isMobile ? "text-gray-600" : "text-gray-200")}
              >
                {ws.inviter.name}
              </span>
              <span className="text-gray-400">
                {tier === Tier.Pending ? "Free" : tier}
              </span>
            </div>
            {ws.isActive && (
              <span className="w-4 ml-1 mr-2">
                <CheckMark />
              </span>
            )}
          </div>
        ))}
    </div>
  );
};

export default WorkspaceSelector;
