// @ts-nocheck
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import { WEBSITE_OPTIONS } from "core/consts";

import { STEP } from "../../constants";

import {
  GET_BASIC_INFO,
  SAVE_BASIC_INFO,
} from "queries/startup/startupSettings/basicStep";

import * as slice from "../../reducer";
import * as sagaActions from "./sagaActions";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

function* getBasicInfo() {
  yield put(toggleLoader({ component: STEP.BASIC, isLoading: true }));
  const { data, isError } = yield client
    .query({
      query: GET_BASIC_INFO,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));
  yield put(
    slice.updateBasicInfo({
      basicInfo: {
        ...data?.me,
        websiteOption:
          data?.me?.website?.includes("http") &&
          data?.me?.website?.split("://")[0]
            ? data?.me?.website?.split("://")[0] + "://"
            : WEBSITE_OPTIONS[0],
        website:
          data?.me?.website?.includes("http") &&
          data?.me?.website?.split("://")[1]
            ? data?.me?.website?.split("://")[1]
            : data?.me?.website,
        industries: data?.me?.industries,
        founded: data?.me?.founded,

        country: data?.me?.country,
        businessModel: data?.me?.businessModel,
        companyStage: data?.me?.companyStage,
        businessCategory: data?.me?.businessCategory,
        state: data?.me?.state,
        city: data?.me?.city,
        lat: data?.me?.lat,
        long: data?.me?.long,
        fullAddress: data?.me?.fullAddress,
        smartCities: data?.me?.smartCityCategory,
      },
    })
  );

  yield put(toggleLoader({ component: STEP.BASIC, isLoading: false }));
}

function* saveBasicInfo({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.BASIC, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: SAVE_BASIC_INFO,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.BASIC, isLoading: false }));
    return;
  }

  yield getBasicInfo();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.BASIC, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(slice.goToNextStep());
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getBasicInfo, getBasicInfo),
    yield takeEvery(sagaActions.saveBasicInfo, saveBasicInfo),
  ];
}
