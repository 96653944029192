import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_AIA_TASK } from "queries/aia/task";
import { AiaTaskStatus, AiaTaskType, GetAiaTaskQuery } from "gql/graphql";
import CompanyProfileComponent from "pages/companyProfile/CompanyProfile";
import { useDispatch } from "react-redux";
import {
  refetchAnalysisData,
  refetchCompanyData,
  refetchCompetitorsData,
} from "pages/companyProfile/sagaActions";
import ProgressBar from "./components/ProgressBar";
import ToastMessages from "./components/ToastMessages";
import { refetchUserData } from "models/user/sagaActions";

const ITEM_LOADING_PERCENTAGE = 3.72;

const AiaProfileLoader: FC = () => {
  const { slug, id } = useParams();
  const dispatch = useDispatch();
  const [profileFailed, setProfileFailed] = useState(false);
  const [analysisFailed, setAnalysisFailed] = useState(false);
  const [tasksCompleted, setTasksCompleted] = useState(false);

  const {
    data: profileCreationTaskData,
    loading: isProfileTaskLoading,
    stopPolling: stopProfilePolling,
  } = useQuery<GetAiaTaskQuery>(GET_AIA_TASK, {
    skip: !slug,
    pollInterval: 10000,
    variables: {
      companySlug: slug,
      type: AiaTaskType.ProfileCreation,
    },
  });

  const {
    data: analysisTaskData,
    loading: isAnalysisTaskLoading,
    stopPolling: stopAnalysisPolling,
  } = useQuery<GetAiaTaskQuery>(GET_AIA_TASK, {
    skip:
      !slug ||
      profileCreationTaskData?.aia?.task?.status !== AiaTaskStatus.Completed,
    pollInterval: 10000,
    variables: {
      companySlug: slug,
      type: AiaTaskType.ContextualAnalysis,
    },
  });

  useEffect(() => {
    if (
      profileCreationTaskData?.aia?.task?.status === AiaTaskStatus.Completed ||
      profileCreationTaskData?.aia?.task?.status === AiaTaskStatus.Cancelled ||
      profileCreationTaskData?.aia?.task?.status === AiaTaskStatus.Failed
    ) {
      stopProfilePolling();
      dispatch(refetchUserData({})); // to refetch the aia trial status
    }

    if (profileCreationTaskData?.aia?.task?.status === AiaTaskStatus.Failed) {
      setProfileFailed(true);
    }
  }, [profileCreationTaskData?.aia?.task?.status]);

  useEffect(() => {
    if (
      analysisTaskData?.aia?.task?.status === AiaTaskStatus.Completed ||
      analysisTaskData?.aia?.task?.status === AiaTaskStatus.Cancelled ||
      analysisTaskData?.aia?.task?.status === AiaTaskStatus.Failed
    ) {
      stopAnalysisPolling();

      if (analysisTaskData?.aia?.task?.status === AiaTaskStatus.Failed) {
        setAnalysisFailed(true);
      }
    }
  }, [analysisTaskData?.aia?.task?.status]);

  const profileLoadingStatus =
    profileCreationTaskData?.aia?.task?.payload.profileLoaded;

  const analysisLoadingStatus = analysisTaskData?.aia?.task?.payload;

  useEffect(() => {
    dispatch(refetchCompanyData({ slug }));
    dispatch(refetchCompetitorsData({ id }));
  }, [
    profileLoadingStatus?.appStore,
    profileLoadingStatus?.basics,
    profileLoadingStatus?.businessModel,
    profileLoadingStatus?.businessRelation,
    profileLoadingStatus?.elevatorPitch,
    profileLoadingStatus?.industries,
    profileLoadingStatus?.marketOpportunity,
    profileLoadingStatus?.productCategory,
    profileLoadingStatus?.productDescription,
    profileLoadingStatus?.raisedValue,
    profileLoadingStatus?.raisedTarget,
    profileLoadingStatus?.sdg,
    profileLoadingStatus?.socials,
    profileLoadingStatus?.team,
    profileLoadingStatus?.investors,
    profileLoadingStatus?.timing,
    profileLoadingStatus?.valuation,
    profileLoadingStatus?.webScrape,
    profileLoadingStatus?.tractionSummary,
    profileLoadingStatus?.tractionVerbose,
    profileLoadingStatus?.perplexityCompetitors,
    profileLoadingStatus?.competitorsFromDeck,
  ]);

  useEffect(() => {
    dispatch(refetchCompetitorsData({ id }));
  }, [
    analysisLoadingStatus?.competitorValidation,
    analysisLoadingStatus?.competitorsFinished,
  ]); // refetch until competitors are loaded and validated profile will finish before this

  useEffect(() => {
    dispatch(refetchAnalysisData({ id }));
  }, [
    analysisLoadingStatus?.teamAnalysisFinished,
    analysisLoadingStatus?.investmentFinished,
    analysisLoadingStatus?.marketOppFinished,
    analysisLoadingStatus?.legislationFinished,
    analysisLoadingStatus?.executiveSummaryFinished,
    analysisLoadingStatus?.aiaCriteriaFinished,
  ]);

  // Total number of 27 properties comprise 100% loading status.
  // If we distribute evenly, each item will be 3.72 percent of the whole process.
  const loadedPercentage = useMemo(() => {
    const profilePercentage =
      Object.values(profileLoadingStatus || {}).filter(
        (i) => typeof i === "boolean" && i === true
      ).length * ITEM_LOADING_PERCENTAGE;

    const analysisPercentage =
      Object.values(analysisLoadingStatus || {}).filter(
        (i) => typeof i === "boolean" && i === true
      ).length * ITEM_LOADING_PERCENTAGE;

    return profilePercentage + analysisPercentage;
  }, [
    profileLoadingStatus?.appStore,
    profileLoadingStatus?.basics,
    profileLoadingStatus?.businessModel,
    profileLoadingStatus?.businessRelation,
    profileLoadingStatus?.elevatorPitch,
    profileLoadingStatus?.industries,
    profileLoadingStatus?.marketOpportunity,
    profileLoadingStatus?.productCategory,
    profileLoadingStatus?.productDescription,
    profileLoadingStatus?.raisedValue,
    profileLoadingStatus?.raisedTarget,
    profileLoadingStatus?.sdg,
    profileLoadingStatus?.socials,
    profileLoadingStatus?.team,
    profileLoadingStatus?.investors,
    profileLoadingStatus?.timing,
    profileLoadingStatus?.valuation,
    profileLoadingStatus?.tractionSummary,
    profileLoadingStatus?.tractionVerbose,
    profileLoadingStatus?.webScrape,
    profileLoadingStatus?.perplexityCompetitors,
    analysisLoadingStatus?.teamAnalysisFinished,
    analysisLoadingStatus?.investmentFinished,
    analysisLoadingStatus?.marketOppFinished,
    analysisLoadingStatus?.legislationFinished,
    analysisLoadingStatus?.executiveSummaryFinished,
    analysisLoadingStatus?.aiaCriteriaFinished,
  ]);

  useEffect(() => {
    if (loadedPercentage >= 100) setTasksCompleted(true);
  }, [loadedPercentage]);

  return (
    <div className="relative w-full h-full overflow-hidden">
      <ProgressBar percentage={loadedPercentage} />
      <CompanyProfileComponent
        params={{ slug: slug }}
        profileLoadingStatus={profileLoadingStatus}
        analysisLoadingStatus={analysisLoadingStatus}
      />
      <ToastMessages
        profileFailed={profileFailed}
        analysisFailed={analysisFailed}
        tasksCompleted={tasksCompleted}
      />
    </div>
  );
};

export default AiaProfileLoader;
