import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUser } from "models/user/selectors";
import NavigationLayout from "organisms/NavigationLayout/NavigationLayout";

type AppRouteProtectedProps = {
  Component: FC;
};

const AppRouteProtected: FC<AppRouteProtectedProps> = ({ Component }) => {
  const userData = useSelector(getUser);

  if (!userData) return <Navigate to="/login" />;

  if (userData?.isOnboarding) return <Navigate to="/onboard" />;

  return <NavigationLayout component={Component} />;
};

export default AppRouteProtected;
