// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getFundraising = createAction(
  "companySettings/fund-raising/fetch"
);

export const saveFundraising = createAction(
  "companySettings/fund-raising/save",
  (payload) => ({
    payload,
  })
);
