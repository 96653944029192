import { FC, useEffect } from "react";
import Button from "atoms/Button/Button";
import FormInput from "atoms/Form/FormInput";
import { SingleSelect } from "molecules/SingleSelect";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  InvestorCriteria,
  InvestorCriteriaResult,
  AiaMutationAddInvestorCriteriaArgs,
  InvestorCriteriaImportance,
} from "gql/graphql";
import { ADD_INVESTOR_CRITERIA, GET_INVESTOR_CRITERIA } from "./queries";
import TooltipWrapper from "atoms/Tooltip/Tooltip";
import { ReactComponent as InfoIcon } from "images/info-icon.svg";
import { ReactComponent as DeleteIcon } from "images/Delete_icon.svg";

const INVESTOR_CRITERIA_IMPORTANCE = [
  {
    id: "MUST_HAVE",
    title: "Must Have",
  },
  {
    id: "NICE_TO_HAVE",
    title: "Nice to Have",
  },
];

type FormValues = {
  criteria: {
    category: string;
    description: string;
    importance: (typeof INVESTOR_CRITERIA_IMPORTANCE)[0];
  }[];
};

interface InvestmentCriteriaProps {
  handleCloseInvestmentCriteria: () => void;
}

const InvestmentCriteria: FC<InvestmentCriteriaProps> = ({
  handleCloseInvestmentCriteria,
}) => {
  const { data: existingInvestorCriteria } = useQuery<{
    aia: { investorCriteria: InvestorCriteria[] };
  }>(GET_INVESTOR_CRITERIA, {
    fetchPolicy: "network-only",
  });

  const defaultValues = {
    category: "",
    description: "",
    importance: INVESTOR_CRITERIA_IMPORTANCE[0],
  };

  const { control, register, reset, formState, setValue, handleSubmit } =
    useForm<FormValues>({
      defaultValues: {
        criteria: [defaultValues],
      },
    });

  useEffect(() => {
    if (existingInvestorCriteria?.aia.investorCriteria) {
      setValue(
        "criteria",
        existingInvestorCriteria.aia.investorCriteria.map((item) => ({
          category: item.category,
          description: item.description,
          importance:
            INVESTOR_CRITERIA_IMPORTANCE.find(
              (importance) => importance.id === item.importance
            ) || INVESTOR_CRITERIA_IMPORTANCE[0],
        }))
      );
    }
  }, [existingInvestorCriteria, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "criteria",
  });

  const [updateInvestorCriteria, { loading, error }] = useMutation<
    { aia: { addInvestorCriteria: InvestorCriteriaResult } },
    AiaMutationAddInvestorCriteriaArgs
  >(ADD_INVESTOR_CRITERIA);

  const onSubmit = async (data: FormValues) => {
    await updateInvestorCriteria({
      variables: {
        input: data.criteria.map((item) => ({
          category: item.category,
          description: item.description,
          importance: item.importance.id as InvestorCriteriaImportance,
        })),
      },
    });

    handleCloseInvestmentCriteria();
  };

  return (
    <div className="flex flex-col items-center w-full p-5 pr-2 bg-white rounded-lg sm:px-12 sm:pr-3">
      <div className="flex flex-col w-full space-y-2">
        <h2 className="font-bold text-[#0B0D18] text-2xl">
          Automatically score every startup in your dealflow
        </h2>
        <div className="h-[450px] space-y-2 pr-4 overflow-auto">
          <p className="text-black">
            By setting your investment criteria below, AIA will score every
            single startup in your dealflow based on your unique preferences.
            Scoring the startup on the things that matter to you will enable you
            to understand the investment fit quicker.{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => {
              return (
                <div key={item.id} className="mt-10 space-y-2">
                  <div className="flex items-center space-x-2">
                    <p className="text-lg text-black">
                      {fields.length > 1
                        ? `Investment criterion ${index + 1}`
                        : "Create your first investment criterion"}
                    </p>

                    <button
                      onClick={() =>
                        fields.length === 1 ? reset() : remove(index)
                      }
                    >
                      <DeleteIcon className="w-4 h-4" />
                    </button>
                  </div>

                  <FormInput
                    label={
                      <div className="flex">
                        <p className="mr-2 text-black">Name</p>
                        <TooltipWrapper
                          place="right"
                          content={
                            <div className="text-white">
                              <p className="text-white">
                                This is the display name you will see in your
                                reports, so you can keep track of the criteria
                                startups fulfil at a glance.
                              </p>
                              <p className="text-white">Examples:</p>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "1em",
                                }}
                              >
                                <li>Wildlife</li>
                                <li>FinTech</li>
                                <li>Female founders</li>
                                <li>etc.</li>
                              </ul>
                            </div>
                          }
                        >
                          <InfoIcon />
                        </TooltipWrapper>
                      </div>
                    }
                    type="text"
                    {...register(`criteria.${index}.category` as const, {
                      required:
                        index !== 0
                          ? {
                              value: true,
                              message: "category is required",
                            }
                          : false,
                    })}
                    labelClassName="w-fit self-start h-6 font-bold"
                    placeholder="Describe the theme of this investment criterion in one to two words"
                  />
                  {formState.errors.criteria?.[index]?.category && (
                    <span className="text-red-500">
                      {formState.errors.criteria?.[index]?.category?.message}
                    </span>
                  )}

                  <FormInput
                    label={
                      <div className="flex">
                        <p className="mr-2 text-black">Description</p>
                        <TooltipWrapper
                          place="right"
                          content={
                            <div className="w-full text-white">
                              <p className="text-white">
                                AIA will assess every startup based on your
                                investment criterion description. Write your
                                description as briefly and directly as possible.
                              </p>
                              <p className="text-white">Examples:</p>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "1em",
                                }}
                              >
                                <li>
                                  The startup must have a positive impact on
                                  marine life
                                </li>
                                <li>
                                  The startup must be a FinTech working on
                                  payment solutions
                                </li>
                                <li>
                                  The startup must have at least 50% women in
                                  the founding team
                                </li>
                              </ul>
                            </div>
                          }
                        >
                          <InfoIcon />
                        </TooltipWrapper>
                      </div>
                    }
                    type="text"
                    {...register(`criteria.${index}.description` as const, {
                      required:
                        index !== 0
                          ? {
                              value: true,
                              message: "description is required",
                            }
                          : false,
                    })}
                    labelClassName="w-fit self-start h-6 font-bold"
                    className="text-black"
                    placeholder="Describe the investment criterion as a statement"
                  />

                  {formState.errors.criteria?.[index]?.description && (
                    <span className="text-red-500">
                      {formState.errors.criteria?.[index]?.description?.message}
                    </span>
                  )}

                  <div className="flex">
                    <p className="mr-2 font-black text-black">
                      Importance level
                    </p>
                    <TooltipWrapper
                      place="right"
                      content={
                        <div className="text-white">
                          <p className="text-white">
                            The importance level of your investment criterion
                            will emphasize different criteria based on your
                            selection. "Must have" will be highlighted stronger
                            than "Nice to have".
                          </p>
                        </div>
                      }
                    >
                      <InfoIcon />
                    </TooltipWrapper>
                  </div>

                  <Controller
                    render={({ field }) => (
                      <SingleSelect
                        wrapperClassName="w-full"
                        labelClassName="w-full text-sm rounded-lg h-12"
                        choices={INVESTOR_CRITERIA_IMPORTANCE}
                        value={field.value}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                      />
                    )}
                    name={`criteria.${index}.importance` as const}
                    control={control}
                  />
                </div>
              );
            })}

            <button
              onClick={() => append(defaultValues)}
              type="button"
              className="flex items-center my-4"
            >
              <div className="w-16 h-16 flex justify-center items-center rounded-full bg-[#DAFBF4] text-[#18BEB3]">
                <span className="text-5xl">+</span>
              </div>
              <p className="ml-2 font-extrabold text-md">
                Add new investment criterion
              </p>
            </button>
            {error && (
              <span className="mt-4 text-red-500">Somethig went wrong</span>
            )}
            <div className="flex space-x-2">
              <Button
                onClick={handleCloseInvestmentCriteria}
                type="button"
                color="transparent"
                className="flex w-full justify-center mt-4 text-[#18BEB3]  p-2 rounded-lg   hover:opacity-90"
              >
                <span className="font-bold ">Cancel</span>
              </Button>
              <Button
                loading={loading}
                type="submit"
                className=" flex w-full justify-center mt-4 text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
              >
                <span className="font-bold">Save</span>
              </Button>
            </div>
            <div className="flex justify-center mt-1 italic">
              <p className="self-center">
                The investment criteria is only visible to you and your team
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvestmentCriteria;
