import {
  INDUSTRY_CATEGORIES_SWEDISH,
  TEAM_SIZE,
  VIMEO_PATTERN,
  YEAR_OPTIONS,
  YOUTUBE_VIDEO_PATTERN,
} from "core/consts";
import { getCurrencyList } from "models/user/selectors";
import { GoogleInputPlace } from "molecules/Form/interfaces";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalContentProps, PitchDeckFile, PitchDeckItem } from "../interfaces";
import { useMutation } from "@apollo/client";
import {
  ADD_CUSTOM_STARTUP_TO_DEALFLOW,
  EDIT_CUSTOM_STARTUP,
} from "queries/investor/investorDealflow";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { IndustryKey } from "pages/dealflow/InvestorDealflow/utils";
import {
  BusinessCategory,
  BusinessModel,
  InvestmentStage,
  StartupStage,
} from "gql/graphql";
import { toInt } from "pages/onboard/desktop/utils";
import { mixpanelAddCustomStartupToDealflow } from "core/mixpanel/Mixpanel";

const useAddNew = ({ record, refetch, onClose }: ModalContentProps) => {
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencyList);
  const [name, setName] = useState(record?.company.displayName ?? "");
  const [site, setSite] = useState(record?.company.website ?? "");
  const [founded, setFounded] = useState(
    record?.company?.founded || YEAR_OPTIONS[0]
  );
  const [description, setDescription] = useState(
    record?.company.description ?? ""
  );
  const [raisedTarget, setRaisedTarget] = useState(
    String(record?.company.finance?.raisedTarget ?? "")
  );
  const [productCategory, setProductCategory] = useState<string[]>(
    (record?.company.businessCategory as BusinessCategory[]) ?? []
  );
  const [minTicketSize, setMinTicketSize] = useState(
    String(record?.company.finance?.minTicketSize ?? "")
  );
  const [valuation, setValuation] = useState(
    String(record?.company.valuation ?? "")
  );
  const [industries, setIndustries] = useState<IndustryKey[]>(
    (record?.company?.industries as IndustryKey[]) ?? []
  );

  const [companyStage, setCompanyStage] = useState<string[]>(
    (record?.company.companyStage as unknown as StartupStage[]) ?? []
  );
  const [businessRelation, setBusinessRelation] = useState<string[]>(
    (record?.company?.businessModel as BusinessModel[]) ?? []
  );
  const [location, setLocation] = useState(record?.company.country ?? "");
  const [place, setPlace] = useState<GoogleInputPlace>();
  const [teamSize, setTeamSize] = useState<string[]>(
    record?.company.teamSize ? [record.company.teamSize] : []
  );
  const [marketOpp, setMarketOpp] = useState(record?.company.marketOpp ?? "");
  const [pitchDeckItems, setPitchDeckItems] = useState<PitchDeckItem[]>(
    record?.company.documents || []
  );
  const [pitch, setPitch] = useState(record?.company.pitch ?? "");
  const [pitchVideoLink, setPitchVideoLink] = useState(
    record?.company.pitchVideoLink ?? ""
  );
  const [logo, setLogo] = useState<File | null>(null);
  const [sdg, setSdg] = useState<number[]>(
    record?.company.sustainableDevelopmentGoals?.items || []
  );
  const [businessTiming, setBusinessTiming] = useState(
    record?.company.businessTiming ?? ""
  );

  const [investmentStage, setInvestmentStage] = useState<string[]>(
    (record?.company.finance.investmentStage as unknown as InvestmentStage[]) ??
      []
  );

  const validateVideoLink =
    VIMEO_PATTERN.test(pitchVideoLink) ||
    YOUTUBE_VIDEO_PATTERN.test(pitchVideoLink);

  const options = Object.keys(INDUSTRY_CATEGORIES_SWEDISH)
    .filter((key) => !industries.includes(key as IndustryKey)) // exclude industries already selected
    .map((key) => ({
      key,
      value: INDUSTRY_CATEGORIES_SWEDISH[key as IndustryKey],
    }));

  const selectedIndustries = Object.keys(INDUSTRY_CATEGORIES_SWEDISH)
    .filter((key) => industries.includes(key as IndustryKey))
    .map((key) => ({
      key,
      value: INDUSTRY_CATEGORIES_SWEDISH[key as IndustryKey],
    }));

  const [addToDealflow, { loading: isAddLoading }] = useMutation(
    ADD_CUSTOM_STARTUP_TO_DEALFLOW
  );

  const [editStartup, { loading: isEditLoading }] =
    useMutation(EDIT_CUSTOM_STARTUP);

  const onIndustriesChange = (value: IndustryKey[]) => {
    setIndustries([...industries, value[value.length - 1]]);
  };

  const removeIndustry = (
    industry: string | [string, { key: string; value: any }]
  ) => {
    setIndustries(industries.filter((i) => i !== industry));
  };

  const loading = isAddLoading || isEditLoading;
  const currency = currencies?.[0] || { symbol: "€" };

  const handleSave = async () => {
    if (!name) {
      return;
    }
    const isEdit = Boolean(record);

    const finalTeamSize = teamSize[0] ? teamSize[0] : Object.keys(TEAM_SIZE)[0];

    const finalWebsite =
      site && site.match(/^https?:\/\//) ? site : site ? `https://${site}` : "";

    const pitchFiles = pitchDeckItems
      .filter((item): item is PitchDeckFile => "file" in item)
      .map((item) => item.file);
    const pitchIds = pitchDeckItems.map((item) => item.id);
    const location = {
      city: place?.city,
      state: place?.state,
      country: place?.country,
      lat: place?.lat,
      long: place?.long,
      fullAddress: place?.fullAddress,
    };

    try {
      const snackParams = {
        title: "Company added",
        message: "New company added to your dealflow!",
      };

      if (isEdit) {
        await editStartup({
          variables: {
            recordId: record?.id,
            site: finalWebsite,
            location: place ? location : undefined,
            valuation: toInt(valuation),
            raisedTarget: toInt(raisedTarget),
            industries: industries,
            teamSize: finalTeamSize,
            logo,
            pitchDeckUploads: pitchFiles,
            pitchDeckIds: pitchIds,
            sdgItems: sdg,
            pitch,
            pitchVideoLink,
            companyStages: companyStage,
            businessModels: businessRelation,
            businessCategories: productCategory,
            minTicketSize: toInt(minTicketSize),
            description,
            founded: String(founded),
            marketOpp,
            businessTiming,
            investmentStages: investmentStage,
          },
        });

        snackParams.title = "Company updated";
        snackParams.message = "Company has been successfully updated";
      } else {
        await addToDealflow({
          variables: {
            name,
            site: finalWebsite,
            location,
            valuation: toInt(valuation),
            raisedTarget: toInt(raisedTarget),
            industries,
            teamSize: finalTeamSize,
            pitchDecks: pitchFiles,
            logo,
            sdgItems: sdg,
            pitch: pitch,
            pitchVideoLink: pitchVideoLink,
            companyStages: companyStage,
            businessModels: businessRelation,
            businessCategories: productCategory,
            minTicketSize: toInt(minTicketSize),
            description,
            founded: String(founded),
            marketOpp,
            businessTiming,
            investmentStages: investmentStage,
          },
        });
        mixpanelAddCustomStartupToDealflow(
          name,
          industries,
          investmentStage,
          companyStage,
          valuation,
          raisedTarget,
          founded,
          location?.country,
          businessRelation,
          teamSize[0]
        );
      }

      refetch();
      onClose();

      dispatch(
        triggerSnack({
          type: "success",
          ...snackParams,
        })
      );
    } catch (error) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Error occurred",
          message: "Something went wrong, please try again later",
        })
      );
    }
  };

  return {
    record,
    name,
    site,
    founded,
    description,
    raisedTarget,
    productCategory,
    minTicketSize,
    valuation,
    companyStage,
    businessRelation,
    location,
    teamSize,
    pitchDeckItems,
    pitch,
    pitchVideoLink,
    sdg,
    options,
    selectedIndustries,
    loading,
    currency,
    validateVideoLink,
    marketOpp,
    businessTiming,
    investmentStage,
    setInvestmentStage,
    setMarketOpp,
    setDescription,
    setFounded,
    setRaisedTarget,
    setProductCategory,
    setMinTicketSize,
    setValuation,
    setCompanyStage,
    setBusinessRelation,
    setLocation,
    setPlace,
    setTeamSize,
    setPitchDeckItems,
    setPitch,
    setPitchVideoLink,
    setLogo,
    setName,
    setSite,
    setSdg,
    setBusinessTiming,
    onIndustriesChange,
    removeIndustry,
    handleSave,
  };
};

export default useAddNew;
