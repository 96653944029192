import { FC } from "react";
import cn from "classnames";
import { PlanHeaderProps } from "../interfaces";
import { mapBillingFrequency } from "../utils/mapBillingFrequency";
import { usePlanContext } from "../context/planContext";

const PlanHeader: FC<PlanHeaderProps> = ({
  title,
  description,
  prices,
  currency,
  isMostPopular,
}: PlanHeaderProps) => {
  const { selectedFrequency } = usePlanContext();

  const priceData = prices.find(
    (price) => price.interval === selectedFrequency
  );

  const isFreePlan = priceData?.price === 0;

  return (
    <div className="z-20 px-4 mb-5 h-28">
      <p
        className={cn(
          "text-3xl font-thin",
          isMostPopular ? "text-white" : "text-[#231D4F]"
        )}
      >
        {title}
      </p>
      <p
        className={cn(
          "text-md",
          isMostPopular ? "text-white" : "text-[#848199]"
        )}
      >
        {description}
      </p>
      <div className="flex items-end">
        <p
          className={cn(
            "text-3xl  mt-2 mr-1",
            isMostPopular ? "text-white" : "text-[#231D4F]"
          )}
        >
          {isFreePlan && "Free"}
        </p>
        {!isFreePlan && (
          <div
            className={cn(
              "text-md flex items-end",
              isMostPopular ? "text-white" : "text-[#231D4F]"
            )}
          >
            <p
              className={cn(
                "mt-2 mr-2 text-3xl",
                isMostPopular ? "text-white" : "text-[#231D4F]"
              )}
            >
              {priceData?.price}
            </p>
            <p className="mt-2 mr-1 text-inherit text-md">
              {currency || "EUR"} / {mapBillingFrequency(selectedFrequency)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlanHeader;
