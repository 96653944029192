import React, { FC, useRef, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { AIA_WEBSITE_UPLOAD } from "./queries";
import { useNavigate } from "react-router-dom";
import { getUser } from "models/user/selectors";
import { AiaPitchDeckResult } from "gql/graphql";
import Button from "atoms/Button/Button";
import { Input } from "atoms/Form/Inputs";
import { cn } from "utils/cn";
import { mixpanelAiaUploadWebsiteAsContext } from "core/mixpanel/Mixpanel";

const Dropdown: FC<{
  trigger: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ trigger, content, isOpen, setIsOpen }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      {trigger}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 flex justify-center items-center left-1/2 transform -translate-x-1/2">
          <div className="py-1 w-full" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

const WebUrl: FC = () => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [webUrlInputError, setWebUrlInputError] = useState<string | null>(null);
  const [webUrl, setWebUrl] = useState<string>("");

  const user = useSelector(getUser);

  const [
    uploadWebsite,
    { loading: websiteApiLoading, error: websiteUploadError },
  ] = useMutation<{ aia: { uploadWebsite: AiaPitchDeckResult } }>(
    AIA_WEBSITE_UPLOAD,
    {
      onCompleted: (data) => {
        const slug = data?.aia?.uploadWebsite?.slug;
        const id = data?.aia?.uploadWebsite?.id;
        if (slug && id) {
          mixpanelAiaUploadWebsiteAsContext(user, slug);
          handlePitchSuccess(slug, id);
        }
      },
    }
  );

  const handleWebUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setWebUrlInputError(null);
    setWebUrl(url);

    // Call API when a URL is pasted
    if (url && isValidUrl(url)) {
      uploadWebsite({
        variables: {
          url: url,
        },
      });
    } else if (url) {
      setWebUrlInputError("Please enter a valid URL");
    }
  };

  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handlePitchSuccess = (slug: string, id: string) => {
    mixpanelAiaUploadWebsiteAsContext(user, slug);
    navigate(`/aia-profile/${slug}/${id}`);
  };

  const toggleDropdown = () => {
    if (!websiteApiLoading) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const dropdownContent = (
    <div className="p-6 flex flex-col items-center">
      <Input
        type="url"
        id="website-src"
        placeholder="Paste startup URL"
        className={cn(
          "ring-1 ring-gray-300 focus:ring-[#18BEB3] focus:outline-none",
          webUrlInputError && "ring-red-500"
        )}
        value={webUrl}
        onChange={handleWebUrl}
        disabled={websiteApiLoading}
      />
      {webUrlInputError && (
        <p className="mt-2 text-sm text-red-500">{webUrlInputError}</p>
      )}
      {websiteApiLoading && (
        <p className="mt-2 text-sm text-gray-500">Processing URL...</p>
      )}
    </div>
  );

  return (
    <div className={cn("relative")}>
      <Dropdown
        trigger={
          <Button
            onClick={toggleDropdown}
            color="white"
            className="flex w-full bg-white border !border-black justify-center mt-6 h-12 text-black p-2 my-3 rounded-3xl hover:opacity-90"
            disabled={websiteApiLoading}
            loading={websiteApiLoading}
          >
            <span className="font-bold text-2xl">Website URL</span>
          </Button>
        }
        content={dropdownContent}
        isOpen={isDropdownOpen}
        setIsOpen={setIsDropdownOpen}
      />

      {websiteUploadError && (
        <span className="mt-4 text-red-500">
          Something went wrong. Please try again later
        </span>
      )}
    </div>
  );
};

export default WebUrl;
