import React from "react";
import CollapsiblePage from "./CollapsiblePage";

const EmailIntegrationDocs = () => {
  return (
    <CollapsiblePage title="Email Integration" className="my-2">
      <h4 className="mt-2">Setting Up Gmail for Integration</h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. If needed, create a dedicated email address:
        </p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Set up a specific Gmail account or a dedicated email address
            within your existing Gmail for receiving emails for analysis.
          </p>
        </div>

        <p className="text-black my-2">2. Configure Gmail for Zapier:</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Ensure that your Gmail account is configured to work with
            third-party apps like Zapier.
          </p>
        </div>
      </div>

      <h4 className="mt-4">
        Creating the Zap for Email Reception and Analysis Trigger
      </h4>

      <div className="ml-4">
        <p className="text-black my-2">1. Start a New Zap in Zapier:</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Visit <a href="https://zapier.com/app/zaps">Zapier</a> and start
            creating a new Zap.
          </p>
        </div>

        <p className="text-black my-2">2. Setting Up Gmail as the Trigger:</p>

        <div className="ml-4">
          <p className="text-black my-2">• Select Gmail as the trigger app.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-01.png"
          />
          <p className="text-black my-2">
            • Select “New Attachment” as your event.
          </p>
        </div>

        <p className="text-black my-2">3. Account</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Authenticate your Gmail account in Zapier.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-02.png"
          />
        </div>

        <p className="text-black my-2">4. Configuring Trigger Settings:</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Specify any additional filters needed, such as ensuring the email
            contains an attachment.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-03.png"
          />
        </div>

        <p className="text-black my-2">5. Testing the Gmail Trigger:</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Send a test email to the dedicated address with an attachment to
            ensure the trigger is properly set up, and then click on Test.
          </p>
          <p className="text-black my-2">
            • You receive one or several records. You can select any, but
            preferably the latest one.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-04.png"
          />
        </div>
      </div>

      <h4 className="mt-6">Setting Up Sircular as the Action for Analysis </h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. Configuring Sircular as the Action:
        </p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Select Sircular as the action app in the same Zap. Important -
            note that you should select the latest version of the Sircular app
            on Zapier, likely Sircular (1.1.1).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-05.png"
          />

          <p className="text-black my-2">
            • Choose “Create Profile” as the Event.
          </p>

          <p className="text-black my-2">
            • Authenticate with Sircular with your API key. You can find the API
            key by logging into Sircular, clicking on the three dots next to
            your organization name, and selecting API. Likely you are on that
            page already since you are reading this text.
          </p>

          <p className="text-black my-2">
            • Once setup, it will look as follows (with your org name).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-06.png"
          />
        </div>

        <p className="text-black my-2">2. Mapping Attachment to Sircular: </p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Select “Attachments Body Attachment Id” as your Identifier.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-07.png"
          />

          <p className="text-black my-2">• Select “Attachment” as your File.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-08.png"
          />
        </div>

        <p className="text-black my-2">3. Testing the Sircular Action:</p>

        <div className="ml-4">
          <p className="text-black my-2">
            • Verify that the attachment is correctly received and processed by
            Sircular through a test run. If correct, it should look somewhat
            like this:
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-09.png"
          />
        </div>
      </div>

      <h4 className="mt-6">
        Retrieving Analysis Result and Sending Back to Sender
      </h4>

      <div className="ml-4">
        <p className="text-black my-2">
          1. Creating Another Zap for Analysis Result:
        </p>
        <div className="ml-4">
          <p className="text-black my-2">• Set up a new Zap.</p>

          <p className="text-black my-2">
            • Choose Sircular (1.1.1) as the trigger.
          </p>

          <p className="text-black my-2">• Authenticate with Sircular.</p>

          <p className="text-black my-2">• Select New Profile as your Event.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-10.png"
          />

          <p className="text-black my-2">• Confirm the Sircular account.</p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-11.png"
          />

          <p className="text-black my-2">
            • Test (if you selected Profile A above, select Profile A here).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-12.png"
          />
        </div>
        <p className="text-black my-2">
          2. Configuring Gmail to Send Analysis Link:
        </p>
        <div className="ml-4">
          <p className="text-black my-2">• Set Gmail as the action app.</p>

          <p className="text-black my-2">
            • Choose "Send Email" as your Event.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-13.png"
          />

          <p className="text-black my-2">
            • Confirm the Email account (it should be the same as selected for
            your previous Zap).
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-14.png"
          />

          <p className="text-black my-2">
            • In this step, you have a higher degree of freedom, so configure it
            to your liking. In this guide, we have selected the minimum viable
            fields for the most basic use case: get the Sircular analysis link
            back into the inbox. For this, Use the sender's email address from
            the original trigger as the recipient, and make sure you include the
            Url tag inside of the Body.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-15.png"
          />
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-16.png"
          />
        </div>
        <p className="text-black my-2">3. Testing the Complete Workflow:</p>
        <div className="ml-4">
          <p className="text-black my-2">
            • Run a full test by sending an email with an attachment to the
            dedicated address.
          </p>

          <p className="text-black my-2">
            • Ensure that the analysis is completed and the result is sent back
            to the original sender’s email.
          </p>
          <img
            className="mb-4"
            src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/email-zapier-17.png"
          />
        </div>

        <p className="text-black my-2">
          Congratulations, you have successfully automated the analysis of pitch
          decks using emails as your trigger.
        </p>
      </div>
    </CollapsiblePage>
  );
};

export default EmailIntegrationDocs;
