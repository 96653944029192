import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  additionalTraction: string | null | undefined;
}

const AdditionalTraction: FC<Props> = ({ additionalTraction }) => {
  return (
    <EditablePlaceholderText
      fieldName="traction.additionalTraction"
      title="Additional Traction"
      addValueTitle="Add any additional traction here"
      value={additionalTraction}
    />
  );
};

export default AdditionalTraction;
