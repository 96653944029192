// @ts-nocheck
import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";

export const AdminHeader = styled.div(() => [
  tw`relative`,

  css`
    background: #f4f5f7;
    border-bottom: 1px solid #ddd;
    display: none;

    ${media.greaterThan("medium")`
      display: block;
    `}
  `,
]);

export const UserProfileIcon = styled.div(() => [
  css`
    margin-right: -20px;

    ${media.greaterThan("medium")`
      margin-right: 0px;
    `}
    ${media.lessThan("medium")`
    margin-right: 0px;
  `}

    svg {
      color: #000;
      font-size: 24px;
      margin-left: 15px;
    }
  `,
]);
