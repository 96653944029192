import { baseUrl, restApiBaseUrl } from "core/config";

interface AnalyticsLinkOptions {
  company?: string;
  board?: string;
}

export const APPLICATION_BASE_URL = baseUrl + "/register/";

const ANALYTICS_BASE_URL = `${restApiBaseUrl}/report/74bc4e20-cc60-429b-b14f`;

export const analyticsLink = ({ company, board }: AnalyticsLinkOptions) => {
  const queryParams = [
    ["board", board],
    ["company", company],
  ]
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  return `${ANALYTICS_BASE_URL}?${queryParams}`;
};

export const cohortLink = (slug: string) => `${APPLICATION_BASE_URL}${slug}`;
