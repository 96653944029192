import { gql } from "@apollo/client";

export const GET_REFERRALS = gql`
  query getReferrals {
    me {
      referral {
        id
        slug
        referees {
          name
          slug
          type
          logo {
            small {
              location
            }
          }
          createdAt
        }
        createdAt
      }
    }
  }
`;

export const CREATE_REFERRAL = gql`
  mutation createReferral {
    referral {
      create {
        slug
        id
      }
    }
  }
`;

export const CANCEL_REFERRAL = gql`
  mutation cancelReferral($id: ID!) {
    referral {
      cancel(id: $id) {
        id
      }
    }
  }
`;
