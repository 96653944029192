import { PitchDeckUrlType } from "gql/graphql";

const dataURLtoBlob = (dataURL: string) => {
  const parts = dataURL.split(";base64,");
  const contentType = parts[0].split(":")[1];
  const byteString = atob(parts[1]);

  const buffer = new ArrayBuffer(byteString.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    view[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: contentType });
};

export const createImages = async (pdf: any) => {
  const images = [];

  try {
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context?.drawImage(canvas, 0, 0);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
      const image = canvas.toDataURL("image/jpeg", 1.0);

      const imageBlob = dataURLtoBlob(image);

      const fileName = "image.png";
      const file = new File([imageBlob], fileName, {
        type: imageBlob.type,
      });

      images.push(file);
      canvas.remove();
    }
  } catch (error) {
    console.error(error);
  }
  return images;
};

export const validateDocsendUrl = (url: string) =>
  /^https:\/\/docsend\.com\/view\/[A-Za-z0-9_-]+$/.test(url);

export const validatePitchDotComUrl = (url: string) =>
  /^https:\/\/pitch\.com\/v\/[A-Za-z0-9_-]+$/.test(url);

export const validateWebsiteUrl = (url: string) =>
  /^(http|https):\/\/[^ "]+$/.test(url);

export const determinePitchDeckUrlType = (url: string): PitchDeckUrlType | null => {
  if (validateDocsendUrl(url)) {
    return PitchDeckUrlType.Docsend;
  } else if (validatePitchDotComUrl(url)) {
    return PitchDeckUrlType.Pitchdotcom;
  } else {
    return null;
  }
};

export const mapSourceLinkType = (
  source: PitchDeckUrlType
): string | undefined => {
  switch (source) {
    case "DOCSEND":
      return "docsend";
    case "PITCHDOTCOM":
      return "pitch.com";
    case "GDRIVE":
      return "Google drive"
  }
};
