import { FC, useEffect, useState } from "react";
import { EmbedVideoProps } from "./interfaces";
import { ReactComponent as PlayButton } from "images/video-play-btn.svg";
import { VIMEO_PATTERN, YOUTUBE_VIDEO_PATTERN } from "core/consts";
import cn from "classnames";

const EmbedVideo: FC<EmbedVideoProps> = ({
  source,
  className,
  isPreview,
  isModal,
}: EmbedVideoProps): JSX.Element => {
  const [sourceUrl, setSourceUrl] = useState(source);
  const [isPlaying, setIsPlaying] = useState(false);
  const isVimeo = VIMEO_PATTERN.test(source);
  const isYoutube = YOUTUBE_VIDEO_PATTERN.test(source);

  useEffect(() => {
    if (source) setSourceUrl(source);
  }, [source]);

  // Small hack to hide controls, titles, etc to match with out designs
  // to format yt and vimeo url to iframe compatible formats
  useEffect(() => {
    if (isVimeo) {
      const match = VIMEO_PATTERN.exec(sourceUrl);
      if (!match) return;
      const videoId = match[3];
      const vimeoUrl = new URL("https://player.vimeo.com/");
      const params = vimeoUrl.searchParams;
      vimeoUrl.pathname = `video/${videoId}`;
      params.set("byline", "false");
      params.set("portrait", "false");
      params.set("title", "false");
      params.set("controls", "false");
      params.set("autoplay", "false");

      setSourceUrl(vimeoUrl.toString());
    }

    if (isYoutube) {
      //gets the thumbnail
      // https://img.youtube.com/vi/lKI4eEtkPSc/0.jpg
      const ytUrl = new URL("https://www.youtube.com/");
      const params = ytUrl.searchParams;
      const match = YOUTUBE_VIDEO_PATTERN.exec(sourceUrl);
      if (!match) return;
      const videoId = match[1];
      ytUrl.pathname = `embed/${videoId}`;

      params.set("modestbranding", "1");

      setSourceUrl(ytUrl.toString());
    }
  }, [sourceUrl]);

  // adds back search params to the url so that it plays automatically
  // for vimeo videos. using existing controls for yt
  const play = () => {
    const url = new URL(sourceUrl);
    const params = url.searchParams;

    params.set("controls", "1");
    params.set("autoplay", "1");

    url.search = params.toString();
    setSourceUrl(url.toString());
    setIsPlaying(true);
  };

  return (
    <div className="abolute pb-[55%] h-0 overflow-hidden">
      <iframe
        src={sourceUrl}
        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; "
        className={cn(
          "absolute top-0 left-0 right-0 w-full h-full rounded-md",
          className,
          isModal && "w-5/6 h-full mx-auto"
        )}
        allowFullScreen
      ></iframe>
      {isVimeo && !isPlaying && (
        <button
          id="play"
          onClick={() => play()}
          className={cn(
            isPreview
              ? "absolute bottom-2 right-8"
              : "absolute bottom-5 right-10",
            isModal && "absolute bottom-10 right-56"
          )}
        >
          <div className="flex items-center justify-center mb-2">
            {!isPreview && (
              <p className="mr-2 font-medium text-white">View product demo</p>
            )}
            <PlayButton className={cn(isPreview ? "h-6" : "mr-4")} />
          </div>
        </button>
      )}
    </div>
  );
};

export default EmbedVideo;
