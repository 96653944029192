import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { RecurringRevenue as RecurringRevenueType } from "gql/graphql";
import { PERIODICITY } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  recurringRevenue: Array<RecurringRevenueType | null> | null | undefined;
  note: string | null | undefined;
  currencyCode: string;
}

const RecurringRevenue: FC<Props> = ({
  recurringRevenue,
  note,
  currencyCode,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const recurringRevenueData = recurringRevenue?.[0];

  const renderContent = () => {
    return (
      <div>
        {recurringRevenueData?.amount && recurringRevenueData.time ? (
          <div>
            {formatCurrencyValues(
              Number(recurringRevenueData.amount),
              currencyCode
            )}{" "}
            {PERIODICITY[recurringRevenueData.time]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, dropdownValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          recurringRevenue: {
            amount: inputValue,
            time: dropdownValue,
            // this won't affect functionality but still good to update the DB
            currency: currencyCode,
          },
          recurringRevenueNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={recurringRevenueData?.amount}
        dropdownDefaultValue={recurringRevenueData?.time}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(PERIODICITY).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="Recurring Revenue"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={recurringRevenueData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default RecurringRevenue;
