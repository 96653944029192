import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";

interface Props {
  signedContracts: Array<string | null> | null | undefined;
  note: string | null | undefined;
}

const SignedContracts: FC<Props> = ({ signedContracts, note }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const signedContractsData = signedContracts?.[0];

  const rendersContent = () => {
    if (signedContractsData) return signedContractsData;
    return "---";
  };

  const renderContent = () => {
    return (
      <div>
        {signedContractsData ? <div>{signedContractsData}</div> : "---"}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          signedContracts: inputValue,
          signedContractsNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={signedContractsData}
        noteDefaultValue={note}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="# of Signed Contracts"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={signedContractsData ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default SignedContracts;
