export const moduleTypes = {
  AUTH: "AUTH",
  COMPANY_PROFILE: "COMPANY_PROFILE",
  ADMIN: "ADMIN",
  COMPANY_SETTINGS: "COMPANY_SETTINGS",
  MESSAGES: "MESSAGES",
  MY_SPACE: "MY_SPACE",
  INVESTOR_DEALFLOW: "INVESTOR_DEALFLOW",
  STARTUP_DEALFLOW: "STARTUP_DEALFLOW",
  APPLICATIONS: "APPLICATIONS",
  PENDING_APPLICATIONS: "PENDING_APPLICATIONS",
  STARTUPS_BOARDS: "STARTUPS_BOARDS",
  PUBLIC_STARTUP_BOARD: "PUBLIC_STARTUP_BOARD",
  PLANS: "PLANS",
  ACCOUNT: "ACCOUNT",
  INVITATION: "INVITATION",
  ONBOARD: "ONBOARD",
};

export const moduleDefs = {
  AUTH: import(/* webpackChunkName: "auth" */ "pages/auth"),
  COMPANY_PROFILE: import(
    /* webpackChunkName: "companyProfile" */ "pages/companyProfile"
  ),
  ADMIN: import(/* webpackChunkName: "admin" */ "pages/admin"),
  COMPANY_SETTINGS: import(
    /* webpackChunkName: "companySettings" */ "pages/companySettings"
  ),
  MESSAGES: import(/* webpackChunkName: "exploreOffers" */ "pages/messages"),
  MY_SPACE: import(/* webpackChunkName: "exploreOffers" */ "pages/mySpace"),
  INVESTOR_DEALFLOW: import(
    /* webpackChunkName: "investorDealflow" */ "pages/dealflow/InvestorDealflow"
  ),
  STARTUP_DEALFLOW: import(
    /* webpackChunkName: "startupDealflow" */ "pages/dealflow/StartupDealflow"
  ),
  APPLICATIONS: import(
    /* webpackChunkName: "applications" */ "pages/applications"
  ),
  PENDING_APPLICATIONS: import(
    /* webpackChunkName: "pending_applications" */ "pages/pendingApplications"
  ),
  STARTUPS_BOARDS: import(
    /* webpackChunkName: "startupsBoard" */ "pages/startupsBoard/PrivateStartupsBoards"
  ),
  PUBLIC_STARTUP_BOARD: import(
    /* webpackChunkName: "publicStartupsBoard" */ "pages/startupsBoard/PublicStartupBoard"
  ),
  PLANS: import(/* webpackChunkName: "plans" */ "pages/plans"),
  ONBOARD: import(/* webpackChunkName: "onboard" */ "pages/onboard"),
  ACCOUNT: import(/* webpackChunkName: "account" */ "pages/account"),
  INVITATION: import(/* webpackChunkName: "invitation" */ "pages/invitation"),
};
