import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { Price } from "gql/graphql";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  salePriceAvg: Array<Price | null> | null | undefined;
  note: string | null | undefined;
  currencyCode: string;
}

const AverageSalesPrice: FC<Props> = ({ salePriceAvg, note, currencyCode }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const salePriceAvgData = salePriceAvg?.[0];

  const renderContent = () => {
    return (
      <div>
        {salePriceAvgData?.amount ? (
          <div>
            {formatCurrencyValues(
              Number(salePriceAvgData.amount),
              currencyCode
            )}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          salePriceAvg: {
            amount: inputValue,
            // this won't affect functionality but still good to update the DB
            currency: currencyCode,
          },
          salePriceAvgNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={salePriceAvgData?.amount}
        noteDefaultValue={note}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };
  return (
    <EditablePlaceholder
      title="Average Sale Price"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={salePriceAvgData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default AverageSalesPrice;
