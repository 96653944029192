import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import { nanoid } from "@reduxjs/toolkit";
import Button from "atoms/Button/Button";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { setGlobalState } from "models/globalState/sagaActions";
import { getGlobalState } from "models/globalState/selectors";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { RAISE_SUPPORT_REQUEST } from "queries/general/support";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Attachments from "./Attachments";

export type FileWithId = File & { id: string };

const ContactSupport = () => {
  const showSupportPanel = useSelector((state) =>
    getGlobalState(state, "showSupportPanel")
  );

  const [description, setDescription] = useState("");

  const [files, setFiles] = useState<FileWithId[]>([]);

  const [raiseSupportRequest, { loading }] = useMutation(
    RAISE_SUPPORT_REQUEST,
    {
      onCompleted: () => {
        dispatch(
          triggerSnack({
            type: "success",
            title: "Support request submitted successfully",
            message:
              "Your message has successfully been sent to our support team.",
          })
        );
        closeSupportPanel();
        setDescription("");
        setFiles([]);
      },
      onError: () => {
        dispatch(
          triggerSnack({
            type: "error",
            title: "Something went wrong",
            message: "We could not submit the request",
          })
        );
      },
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (showSupportPanel) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [showSupportPanel]);

  const onFileChange = (file: File) => {
    (file as FileWithId).id = nanoid();
    setFiles((prev) => [...prev, file as FileWithId]);
  };

  const onFileRemove = (id: string) => {
    setFiles((prev) => prev.filter((f) => f.id !== id));
  };

  const closeSupportPanel = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: false,
      })
    );
  };

  const handleSubmit = () => {
    raiseSupportRequest({
      variables: {
        input: {
          description: description,
          documents: files,
        },
      },
    });
  };

  return (
    <Transition
      appear
      show={showSupportPanel}
      as="div"
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed inset-0 top-0 bottom-0 left-0 right-0 z-50 bg-opacity-50 bg-midnight"
    >
      <Transition.Child
        as="div"
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
        className="absolute top-0 right-0 w-full h-full max-w-md p-6 bg-white"
      >
        <FontAwesomeIcon
          className="absolute text-2xl text-gray-300 cursor-pointer right-6 top-4"
          icon={["fal", "times"]}
          onClick={closeSupportPanel}
        />

        <h1 className="text-3xl">Contact support</h1>

        <p className="my-4 ">
          Our team may take up to 48 hours to respond to you due to the large
          amount of inquiries they are presently receiving.
        </p>

        <label htmlFor="support-description" className="block mb-1 text-sm">
          Description
        </label>
        <textarea
          id="support-description"
          value={description}
          placeholder="Write a description about the issue you are facing."
          onChange={({ target }) => setDescription(target.value)}
          className="w-full p-3 overflow-auto border border-gray-300 rounded-lg outline-none resize-none focus:border-gray-400 min-h-[8rem] placeholder:text-sm"
        />

        <label
          htmlFor="support-attachments"
          className="block mt-2 mb-1 text-sm"
        >
          Upload attachments
        </label>
        <Attachments
          onFileChange={onFileChange}
          onFileRemove={onFileRemove}
          loading={loading}
          files={files}
        />

        <Button
          size="small"
          color="primary"
          className="justify-center w-full mt-4"
          onClick={handleSubmit}
          disabled={loading}
        >
          Submit
        </Button>
      </Transition.Child>
    </Transition>
  );
};

export default ContactSupport;
