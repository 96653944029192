// @ts-nocheck
import CompanyProfileSlice from "./reducer";
import CompanyProfileSaga from "./sagas";
import { moduleTypes } from "core/modules";
import { getUser, isLoggedinUser } from "models/user/selectors";

const module = [
  {
    path: "/profile",
    key: "key",
    componentId: "userCompanyProfile",
    component: import(
      /* webpackChunkName: "companyProfile" */ "./CompanyProfile"
    ),
    routes: [],
    importReducer: () => [CompanyProfileSlice],
    module: moduleTypes.COMPANY_PROFILE,
    importSagas: () => [CompanyProfileSaga],
    isEnabled: (state) => isLoggedinUser(state) && getUser(state)?.isCompany,
    title: "Company Profile",
    exact: true,
    name: "Company Profile",
  },
  {
    path: "/public/:slug",
    key: "key",
    componentId: "publicCompanyProfile",
    component: import(
      /* webpackChunkName: "companyProfile" */ "./RedirectCompanyProfile"
    ),
    routes: [],
    importReducer: () => [], // CompanyProfileSlice is already injected
    module: moduleTypes.COMPANY_PROFILE,
    importSagas: () => [], // CompanyProfileSaga alrealy injected from the first object, no need to import again
    isEnabled: () => true,
    title: "Company Profile",
    exact: true,
    name: "Company Profile",
  },
  {
    path: "/private/:slug",
    key: "key",
    componentId: "privateCompanyProfile",
    component: import(
      /* webpackChunkName: "companyProfile" */ "./RedirectCompanyProfile"
    ),
    routes: [],
    importReducer: () => [], // CompanyProfileSlice is already injected
    module: moduleTypes.COMPANY_PROFILE,
    importSagas: () => [], // CompanyProfileSaga alrealy injected from the first object, no need to import again
    isEnabled: () => true,
    title: "Company Profile",
    exact: true,
    name: "Company Profile",
  },
  {
    path: "/company/:slug",
    key: "key",
    componentId: "authenticatedCompanyProfile",
    component: import(
      /* webpackChunkName: "companyProfile" */ "./CompanyProfile"
    ),
    routes: [],
    importReducer: () => [], // CompanyProfileSlice is already injected
    module: moduleTypes.COMPANY_PROFILE,
    importSagas: () => [], // CompanyProfileSaga alrealy injected from the first object, no need to import again
    isEnabled: (state) => isLoggedinUser(state),
    title: "Company Profile",
    exact: true,
    name: "Company Profile",
  },
];

export default module;
