import { SubmitHandler, useForm } from "react-hook-form";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getElevatorPitch } from "./selectors";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { saveElevatorPitch } from "./sagaActions";
import { ReactComponent as ElevatorPitchIcon } from "images/elevator-pitch-icon.svg";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";
import cn from "classnames";
import { VIMEO_PATTERN, YOUTUBE_VIDEO_PATTERN } from "core/consts";
import FloatingLabelInput from "atoms/Form/FloatingLabelInput";

type FormValues = {
  pitch: string;
  problem?: string;
  solution?: string;
  pitchVideoLink: string;
};

export default () => {
  const data = useSelector(getElevatorPitch);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { pitch: data?.pitch, pitchVideoLink: data?.pitchVideoLink },
  });

  const { lang } = useLang();
  const dispatch = useDispatch();

  const loading = useSelector((state) =>
    isLoadingEnabled(state, STEP.ELEVATOR_PITCH)
  );

  const onSubmit: SubmitHandler<FormValues> = async ({
    pitch,
    pitchVideoLink,
  }) => {
    dispatch(
      saveElevatorPitch({
        lang,
        pitch,
        pitchVideoLink,
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <ElevatorPitchIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.elevatorPitch}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>{lang.elevatorPitchDesc}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <TextArea
          rows="3"
          id="pitch"
          className={cn(
            "height-set form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm",
            errors.pitch &&
              "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
          )}
          placeholder={`${lang.addYourElevatorPitchHere}...`}
          defaultValue={data?.pitch}
          {...register("pitch")}
          maxLength={300}
        ></TextArea>
      </div>

      <div className="mt-8 -mb-2 text-sm font-medium">Pitch video</div>
      {/* @ts-ignore */}
      <FloatingLabelInput
        name="pitchVideoLink"
        type="text"
        defaultValue={data?.pitchVideoLink}
        reference={{
          ...register("pitchVideoLink", {
            required: false,
            validate: {
              urlPattern: (value) =>
                !value ||
                YOUTUBE_VIDEO_PATTERN.test(value) ||
                VIMEO_PATTERN.test(value),
            },
          }),
        }}
        errors={
          errors.pitchVideoLink?.type === "urlPattern" &&
          "Please make sure to upload a YouTube or a Vimeo link"
        }
        className="normal-case"
      >
        Paste a YouTube or Vimeo link here
      </FloatingLabelInput>
      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
