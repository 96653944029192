import { graphql } from "gql";

export const GET_STARTUP_BOARDS = graphql(`
  query getStartupBoards {
    getStartupBoards {
      id
      title
      createdAt
      description
      slug
      logo {
        large {
          location
        }
        small {
          location
        }
      }
      isIdentityRequired
      isDarkMode
      startups {
        _id
        country
        city
        traction {
          summary
        }
        slug
        displayName
        primaryColor
        banner {
          small {
            location
          }
        }
        logo {
          small {
            location
          }
          medium {
            location
          }
          large {
            location
          }
        }
        pitch
        industries
        sustainableDevelopmentGoals {
          items
        }
        keyFigures {
          key
          value
        }
        founded
        teamSize
        businessModel
        companyStage
        valuation
        investors {
          name
        }
        finance {
          raisedTarget
          raisedValue
        }
        currency
        fullAddress
        founders {
          name
          firstName
          lastName
          displayName
          linkedin
          title
          image {
            small {
              location
            }
          }
        }
      }
    }
  }
`);

export const GET_STARTUP_BOARD_BY_SLUG = graphql(`
  query getStartupBoardBySlug($slug: String!) {
    getStartupBoardBySlug(slug: $slug) {
      id
      title
      description
      slug
      logo {
        small {
          location
        }
        medium {
          location
        }
        large {
          location
        }
      }
      isIdentityRequired
      isDarkMode
      startups {
        _id
        slug
        displayName
        country
        city
        industries
        primaryColor
        traction {
          summary
        }
        banner {
          small {
            location
          }
        }
        logo {
          small {
            location
          }
          medium {
            location
          }
          large {
            location
          }
        }
        pitch
        industries
        sustainableDevelopmentGoals {
          items
        }
        keyFigures {
          key
          value
        }
        founded
        teamSize
        businessModel
        companyStage
        valuation
        investors {
          name
        }
        finance {
          raisedTarget
          raisedValue
        }
        founders {
          name
          firstName
          lastName
          displayName
          linkedin
          title
          image {
            small {
              location
            }
          }
        }
        currency
        fullAddress
      }
    }
  }
`);

export const CREATE_STARTUP_BOARD = graphql(`
  mutation createStartupBoard($input: BoardInput!) {
    createStartupBoard(input: $input) {
      id
      title
      description
    }
  }
`);

export const EDIT_STARTUP_BOARD = graphql(`
  mutation editStartupBoard($id: String!, $input: BoardInput!) {
    editStartupBoard(id: $id, input: $input) {
      id
      title
      description
    }
  }
`);
