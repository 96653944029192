import { FC, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import type { ProfilePictureSelectProps } from "./interfaces";
import ProfilePictureEditModal from "./ProfilePictureEditModal";

const ProfilePictureSelect: FC<ProfilePictureSelectProps> = ({
  accept = "image/*",
  label = "Upload image",
  onComplete,
  preview: defaultPreview,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [preview, setPreview] = useState("");
  const [type, setType] = useState("");
  const [fileName, setFilename] = useState("");

  useEffect(() => {
    defaultPreview && setPreview(defaultPreview);
  }, [defaultPreview]);

  const readFile = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });

  const handleFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      const imageDataUrl = await readFile(file);
      if (typeof imageDataUrl === "string") {
        setSrc(imageDataUrl);
        setIsModalOpen(true);
        setType(file.type);
        setFilename(file.name);
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSrc("");

    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleEditCompleted = (preview: string, file: File) => {
    setPreview(preview);
    onComplete?.(file);
  };

  return (
    <>
      <div className="flex flex-col my-2">
        <label className="flex w-[160px] h-[160px] cursor-pointer">
          <input
            ref={inputRef}
            className="w-0 h-0 opacity-0"
            type="file"
            accept={accept}
            onChange={handleFileChange}
          />

          <div
            className={cn(
              "relative w-full h-full rounded-full text-aqua-400 flex justify-center items-center flex-col",
              !preview && "border border-dashed border-aqua-400 bg-aqua-50"
            )}
          >
            {preview ? (
              <>
                <img
                  alt=""
                  src={preview}
                  className="object-cover w-full h-full border rounded-full shadow-lg"
                />
                <span className="absolute inline-flex items-center justify-center w-8 h-8 border rounded-full bottom-2 left-full border-aqua-400">
                  <FontAwesomeIcon icon={["fas", "pen"]} />
                </span>
              </>
            ) : (
              <>
                <span className="m-2 text-center">{label}</span>
                <FontAwesomeIcon icon={["fas", "upload"]} />
              </>
            )}
          </div>
        </label>
      </div>
      <ProfilePictureEditModal
        label={label}
        imageSrc={src}
        fileName={fileName}
        fileType={type}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCancel={handleCancel}
        onEditCompleted={handleEditCompleted}
      />
    </>
  );
};

export default ProfilePictureSelect;
