import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const Page = styled(motion.div)(() => [
  css`
    width: 100%;
  `,
]);

export const Main = styled.div(() => [tw`w-full pt-6 pb-6`]);

export const SidebarContent = styled(motion.div)(() => [
  tw`w-full pt-6 pl-6 pr-6`,
  css`
    ${media.greaterThan("large")`
      padding-left: calc(310px + 1.5rem);
    `}
    ${media.lessThan("medium")`
      padding-left: 15px;
      padding-right: 15px;
      height: 100%;
    `}
    height: 100%;
    overflow: auto;
    form {
      width: 80%;
      margin-left: 80;
      display: flex;
      flex-direction: column;
      ${media.lessThan("medium")`
        width: 100%;
      `}
    }
    ${media.greaterThan("large")`
      padding-left: calc(250px + 1.5rem);
    `}
    ${media.between("1024px", "1170px")`
      padding: 0px;
      padding-top: 10px;
      form{
        width: 90%;
		    padding-bottom: 40px;
      }
    `}
  `,
]);

export const HalfGrid = styled.div(() => [
  css`
    display: grid;
    margin-bottom: 15px;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    ${media.greaterThan("large")`
      margin-bottom: 60px;
      grid-gap: 30px;
      grid-template-columns: 1fr 1fr;
    `}
  `,
]);

export const Panel = styled.div(() => [
  tw`relative p-6 bg-white rounded-lg`,

  css`
    ${({ type }) =>
      type === "table" &&
      css`
	padding - top: 0 px!important;
	`}

    ${media.lessThan("large")`
      margin-bottom: 1.5rem;
    `}
  `,
]);

export const DarkPanelHead = styled.div(() => [
  tw`relative p-6 bg-midnight`,

  css`
    margin-bottom: 0px;
    border-radius: 0.5rem 0.5rem 0px 0px;
    background: #16192f;
    margin-bottom: -10px;
    z-index: 2;
    box-shadow: none;
  `,
]);

export const PageHeader = styled.div(() => [
  tw`mb-6`,
  css`
    margin-bottom: 0;
    padding-bottom: 1px;
    h1 {
      font-weight: 500;
    }
    ${media.lessThan("medium")`
      h1{
        font-size: 22px;
        margin-left: 8px;
      }
      svg{
        width: 35px;
      }
    `}
  `,
]);

export const StepsSidebar = styled.div(
  () => [tw`top-0 w-full p-6 pt-4 pb-0 mr-6 lg:w-2/6`],
  css`
    ${media.greaterThan("large")`
      max-width: 310px;
      height: 100%;
      min-height: 100%;
      position: absolute;
    `};
    margin: 0;
    top: 0;
    overflow: auto;
    ${media.lessThan("medium")`
      padding: 0;
    `}
    ${media.between("1024px", "1170px")`
      padding-right: 0px;
    `}
    &::after {
      content: "";
      position: fixed;
      left: 0px;
      bottom: 0px;
      width: 150px;
      background-image: url(/page-bottom-img.fe258ebd.png);
      background-repeat: no-repeat;
      height: 150px;
      background-size: cover;
      ${media.lessThan("medium")`
        content: none;
      `}
    }
    ${media.greaterThan("large")`
      max-width: 250px;
    `}
  `
);

export const StepsRightSidebar = styled.div(
  () => [tw`top-0 w-full pt-0 pb-0 mr-6 lg:w-2/6`],
  css`
    ${media.greaterThan("large")`
      max-width: 310px;
      height: calc(100vh - 150px);
      min-height: 100%;
      position: sticky;
    `}
    .instruction-section {
      ${media.lessThan("medium")`
        position: fixed;
        top: 0;
        z-index: 99;
        height: 100%;
        padding: 0;
      `}
      ${media.lessThan("large")`
	  padding: 0;
	  `}
    }
  `
);

export const StickyWrapper = styled.div(({ height }) => [
  tw`pt-6`,
  css`
    position: sticky;
    cursor: pointer;
    top: 0px;
    background: #fff;
    &.investor-steps {
      padding: 5px;
      z-index: 9;
      ${media.lessThan("large")`
	padding: 15px;
    `}
      ${media.lessThan("769px")`
		span{
			${media.lessThan("380px")`
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75px;	
        `}
		${media.lessThan("420px")`	
	font-size: 12px;
        `}
		}
    `}
    }
    &.teaser-steps {
      padding: 5px;
      z-index: 9;
      ${media.lessThan("medium")`
      padding: 10px 0;
      background: #fff;
      border-bottom: 1px solid #e5e7eb40;
    `}
      ${media.lessThan("769px")`
		span{
			${media.lessThan("380px")`
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 75px;	
        `}
		${media.lessThan("420px")`	
	font-size: 12px;
        `}
		}
    `}
    }
  `,
  height &&
    css`
      ${media.lessThan("medium")`
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
  `}
    `,
]);

export const CompanyStep = styled.div(({ current, status }) => [
  tw`relative flex items-center justify-start px-3 py-2 mb-1 text-base leading-5 text-black text-gray-500 capitalize transition duration-150 ease-in-out rounded-md hover:bg-gray-50 focus:outline-none focus:bg-gray-200`,
  css`
    &.current-phase {
      margin: 0;
      padding: 0.5rem 0.75rem 1rem 0.75rem;
      background: transparent;
      span {
        padding-left: 15px;
        ${media.lessThan("1023px")`
				  padding-left: 0;
			  `}
      }
      ${media.lessThan("1023px")`
			  padding: 0px 32px;
			  font-size: 14px;
		  `}
      &:hover {
        background: transparent;
      }
      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: #ffe6df;
        position: absolute;
        left: 7px;
        top: 10px;
        ${media.lessThan("1023px")`
				left: auto;
				right: 0;
				width: 25px;
				height: 1px;
			`}
      }
      &:before {
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid #ff8060;
        position: absolute;
        left: 0;
        top: 10px;
        z-index: 9;
        background: #fff;
        ${media.lessThan("1023px")`
				left: 11px;
				top: 2px;
			`}
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      &.active {
        color: #ff8060;
      }
    }

    svg {
      max-width: 20px;
      transition: all 0.2s ease-in-out;
      width: 20px;
    }

    .status-icon {
      margin-right: 10px;
      max-width: 20px;
    }

    .checkmark-icon {
      width: 20px;
      max-width: 20px;
      &.hide {
        width: 1px !important;
        margin-right: 0px !important;
      }
      &.show {
        width: 20px !important;
        margin-right: 10px !important;
      }
    }

    &.active {
      &:before {
        background: #ff8060;
      }
      color: var(--primary-color);
    }
  `,
  current && tw`text-black bg-white`,
  status === "done" &&
    css`
      .status-icon {
        color: var(--primary-color);
      }
    `,
  current &&
    css`
      &.current-phase {
        span {
          color: #ff8060 !important;
        }
        &:before {
          background: #ff8060;
        }
      }
    `,
  status === "done" &&
    css`
      &.current-phase {
        span {
          color: #000;
          ${media.lessThan("1023px")`
					left: 16px;
					top: 4px;
					padding-left: 0;
				`}
          &:after {
            content: "";
            display: block;
            width: 5px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            left: 5px;
            top: 12px;
            z-index: 9;
            background: #ff8060;
            ${media.lessThan("1023px")`
						left: 16px;
						top: 4px;
					`}
          }
        }
        &:after {
          background: #ff8060;
        }
        &:before {
          background: #ff8060;
        }
      }
    `,
  status === "missing" &&
    css`
      .status-icon {
        color: red;
      }
    `,
]);

export const InvestorStep = styled.div(({ current, status }) => [
  tw`relative flex items-center justify-start px-3 py-2 mb-1 text-base leading-5 text-black text-gray-500 capitalize transition duration-150 ease-in-out rounded-md hover:bg-gray-50 focus:outline-none focus:bg-gray-200`,
  css`
    &.current-phase {
      margin: 0;
      padding: 0.5rem 0.75rem 1.4rem 0.75rem;
      font-size: 14px;
      background: transparent;
      span {
        padding-left: 15px;
        ${media.lessThan("1023px")`
				  padding-left: 0;
			  `}
      }
      ${media.lessThan("1023px")`
			  padding: 0px 32px;
			  font-size: 14px;
		  `}
      &:hover {
        background: transparent;
      }
      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: #2dbbb25e;
        position: absolute;
        left: 7px;
        top: 10px;
        ${media.lessThan("1023px")`
				  left: auto;
				  right: 0;
				  width: 25px;
				  height: 1px;
			  `}
      }
      &:before {
        content: "";
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid #18beb3;
        position: absolute;
        left: 0;
        top: 10px;
        z-index: 9;
        background: #fff;
        ${media.lessThan("1023px")`
				  left: 11px;
				  top: 2px;
			  `}
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      &.active {
        span {
          color: #18beb3;
        }
      }
    }

    svg {
      max-width: 20px;
      transition: all 0.2s ease-in-out;
      width: 20px;
    }

    .status-icon {
      margin-right: 10px;
      max-width: 20px;
    }

    .checkmark-icon {
      width: 20px;
      max-width: 20px;
      &.hide {
        width: 1px !important;
        margin-right: 0px !important;
      }
      &.show {
        width: 20px !important;
        margin-right: 10px !important;
      }
    }

    &.active {
      &:before {
        background: #18beb3;
      }
      color: var(--primary-color);
    }
  `,
  current && tw`text-black bg-white`,
  status === "done" &&
    css`
      .status-icon {
        color: var(--primary-color);
      }
    `,
  current &&
    css`
      &.current-phase {
        span {
          color: #0b0d18 !important;
        }
        &:before {
          background: #18beb3;
        }
      }
    `,
  status === "done" &&
    css`
      &.current-phase {
        span {
          color: #3cb34b;
          ${media.lessThan("1023px")`
					  left: 16px;
					  top: 4px;
					  padding-left: 0;
				  `}
          &:after {
            content: "";
            display: block;
            width: 5px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            left: 5px;
            top: 12px;
            z-index: 9;
            background: #3cb34b;
            ${media.lessThan("1023px")`
						  left: 16px;
						  top: 4px;
					  `}
          }
        }
        &:after {
          background: #18beb3;
        }
        &:before {
          background: #3cb34b;
          border-color: #3cb34b;
        }
      }
    `,
  status === "missing" &&
    css`
      .status-icon {
        color: red;
      }
    `,
]);

export const StepActionButton = styled.div(({ isNavigationEnabled }) => [
  tw`flex justify-start mt-5 sm:justify-end`,
  css`
    &.hidden {
      display: none;
    }
    position: sticky;
    bottom: -5px;
    margin-bottom: 52px;
    padding: 10px 15px;
    background: #ffffffe8;
    align-self: flex-end;
    width: 100%;
    z-index: 50;
    ${media.lessThan("medium")`
      position: fixed;
      left: 0;
      ${isNavigationEnabled ? "margin-bottom: 52px;" : "margin-bottom: 10px;"}
    `}
    ${media.lessThan("420px")`
      span{
        font-size:12px;
      }
    `}
  `,
]);
