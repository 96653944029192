import { touch, TouchType, VisitPayload } from "analytics";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const UTM_KEYS = ["us", "um", "uc", "ut", "ux"] as const;

const origin = window.location.origin;

const useAnalyticsAnyVisit = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const url = origin + location.pathname;
    const payload: VisitPayload = { url };

    UTM_KEYS.forEach((key) => {
      const param = searchParams.get(key);

      if (param) {
        payload[key] = param;
      }
    });

    touch(TouchType.Visit, payload);
  }, [location.pathname]);
};

export default useAnalyticsAnyVisit;
