import { gql } from "@apollo/client";

export const GET_TOKENS = gql`
  query getTokens {
    me {
      tokens {
        id
        tail
        label
      }
    }
  }
`;

export const ADD_TOKEN = gql`
  mutation addToken($label: String!) {
    me {
      tokens {
        add(label: $label) {
          value
        }
      }
    }
  }
`;

export const ADD_WEBHOOK = gql`
  mutation addWebhook($input: MeWebhookAddInput!) {
    me {
      webhooks {
        add(input: $input) {
          id
          url
          label
        }
      }
    }
  }
`;

export const GET_WEBHOOKS = gql`
  query getWebhooks {
    me {
      webhooks {
        id
        url
        label
        createdAt
      }
    }
  }
`;
