import { FC, useEffect, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { IndustryCategory } from "gql/graphql";
import { INDUSTRY_CATEGORIES_SWEDISH } from "core/consts";
import ButtonGroup from "molecules/Form/ButtonGroup";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";

interface Props {
  industries: IndustryCategory[] | undefined | null;
}

const Industry: FC<Props> = ({ industries }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSeleccted] = useState<string[]>([]);

  useEffect(() => {
    setSeleccted(industries || []);
  }, [industries]);

  const dispatch = useDispatch();

  const onIdustryChange = (data: string[]) => {
    setSeleccted(data);
  };

  const onCancel = () => {
    setSeleccted(industries || []);
    setIsEditMode(false);
  };

  const onSave = () => {
    dispatch(updateStartupProfile({ business: { industries: selected } }));
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="mt-2">
        <ButtonGroup
          id="industries"
          options={Object.entries(INDUSTRY_CATEGORIES_SWEDISH)}
          onChange={onIdustryChange}
          limit={3}
          type="company"
          className="capitalize"
          defaultValue={industries || undefined}
        />
        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right"
        />
      </div>
    );
  };

  return (
    <EditablePlaceholder
      title="Industry"
      value={industries?.map((i) => INDUSTRY_CATEGORIES_SWEDISH[i]).join(", ")}
      style="secondary"
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default Industry;
