import { Area } from "react-easy-crop/types";

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc: string,
  fileName: string,
  type: string,
  pixelCrop: Area | null
) => {
  const image = await createImage(imageSrc);

  if (!pixelCrop) {
    return null;
  }
  const canvas = document.createElement("canvas");
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  //fills empty spaces with white
  ctx.fillStyle = "#ffffff";

  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  const preview = canvas.toDataURL("image/jpeg");
  const response = await fetch(preview);

  const blob = await response.blob();

  const file = new File([blob], fileName, {
    type,
  });

  return {
    preview,
    file,
  };
};
