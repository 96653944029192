import type { FC } from "react";
import type { PitchDeckUploadProps } from "./interfaces";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSelect from "atoms/Form/FileSelect";

const ALLOWED_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/pdf",
];
const MAX_FILE_SIZE = 20_000_000;

const PitchDeckUpload: FC<PitchDeckUploadProps> = ({
  items,
  onChange,
  extraInfo,
}) => {
  const dispatch = useDispatch();

  const triggerError = (title: string, message: string) =>
    dispatch(
      triggerSnack({
        type: "error",
        title,
        message,
      })
    );

  const handleUpload = (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      triggerError(
        "Wrong file type",
        "Please upload only .xlsx, .xls, .pdf format"
      );

      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      triggerError(
        "Too large file",
        "File size is too large, it must be less than 20 MB"
      );
      return;
    }

    onChange(
      items.concat({
        id: "upload",
        file,
      })
    );
  };

  const getHandleRemove = (index: number) => () =>
    onChange(items.filter((_, idx) => idx !== index));

  return (
    <div>
      <FileSelect
        multiple
        type="document"
        onChange={handleUpload}
        disabled={false}
        loading={false}
        imageDesc="Drop your files here or "
        extraInfo={extraInfo}
      />
      <div className="mt-4">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-2 mb-1 border border-gray-200 rounded"
          >
            <span className="text-gray-500">
              {"title" in item ? item.title : item.file.name}
            </span>
            <button
              title="Remove this file"
              type="button"
              className="flex p-1 text-sm text-red-400 rounded hover:text-red-600 focus:text-red-600"
              onClick={getHandleRemove(index)}
            >
              <FontAwesomeIcon icon={["fas", "trash"]} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PitchDeckUpload;
