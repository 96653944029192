import { ReactComponent as BinIcon } from "images/bin-icon.svg";
import { ReactComponent as GridIcon } from "images/drag-n-drop.svg";
import { FC } from "react";
import { StatusTableProps } from "./interfaces";
import cn from "classnames";

const StatusTable: FC<StatusTableProps> = ({ item, onClick }) => (
  <div
    className="flex w-full p-2 bg-white border border-gray-200 rounded"
  >
    <BinIcon
      className={cn(
        "w-6 m-auto ml-1.5 fill-current cursor-pointer",
        item.isDeletable
          ? "text-aqua-400 opacity-75"
          : "text-gray-400 opacity-75"
      )}
      onClick={onClick}
    />
    <GridIcon
      className={cn(
        "w-6 m-auto ml-1.5 fill-current",
        item.isDeletable
          ? "text-aqua-400 opacity-75"
          : "text-gray-400 opacity-75"
      )}
    />
    <p
      className={cn(
        "ml-3.5 w-full text-sm",
        item.isDeletable ? "text-black opacity-75" : "text-gray-400 opacity-75"
      )}
    >
      {item.customTitle}
    </p>
  </div>
);

export default StatusTable;
