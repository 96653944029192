import { FC } from "react";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import PureHtml from "atoms/PureHtml";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";
import { FieldName } from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";

interface Props {
  content: string;
}

const Traction: FC<Props> = ({ content }) => {
  const { profileSectionRef } = useProfileNavigation();

  return (
    <div
      id={PROFILE_SECTION_IDS.Traction}
      ref={profileSectionRef}
      className="my-4"
    >
      <SectionHeader title="Traction" />
      <div className="aiaProfile text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
        <PureHtml content={content} tag="p" />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(Traction, FieldName.Traction);
