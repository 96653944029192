export const generateNodeUploadCode = (
  nodeApiKey: string,
  nodeExternalId: string
) => {
  return `import axios from "axios";
  
      const apiKey = ${nodeApiKey ?? "apiKey"}
      
      const externalId = ${nodeExternalId ?? "externalId"}
      
      const headers = {
        'Authorization': 'Bearer ' + "apiKey"
      };
      
      const api_endpoint = '${process.env.REACT_APP_REST_URL}/ai/profiles'
      
      const file = file; 
      
      const formData = new FormData();
      formData.append('file', file); 
      formData.append('externalId', externalId);
      
      axios.post(api_endpoint, formData { headers: headers })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
      `;
};

export const generateNodeFetchCode = (
  nodeApiKey: string,
  nodeExternalId: string
) => {
  return `import axios from "axios";
  
      const apiKey = ${nodeApiKey ?? "api_key"}
      const externalId = ${nodeExternalId ?? "externalId"}
      
      const headers = {
          Authorization: "Bearer " + "apiKey",
        };
    
      const apiEndPoint = ${process.env.REACT_APP_REST_URL}/ai/profiles
  
      axios.get(apiEndPoint/ai/profiles/${nodeExternalId}, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((error) => {
            console.log(error);
          });`;
};
