import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as CloseIcon } from "images/close-icon-bg.svg";
import FoundersChart from "images/founders-chart.png";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { RightCardItem } from "../CompanySetting.style";
import { getCurrentStep } from "../selectors";

export default (props) => {
  const { lang } = useLang();
  const [state, setState] = useState({});
  const currentStep = useSelector(getCurrentStep);

  const handlePlayVideo = async (e) => {
    await setState({ ...state, playVideo: true });
    document.getElementById("play-link").src += "&autoplay=1";
  };

  useEffect(() => {
    handleData();
  }, [currentStep]);

  const handleData = () => {
    var settingUpDesc;
    var settingUpYourProfile = lang.settingUpYourProfile;
    var thisIsPrivateByDefault = lang.thisIsPrivateByDefault;
    var thisIsPrivateDescription = lang.thisIsPrivateDescription;
    switch (currentStep) {
      case "info":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpProfileDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.profileInvestorTipDesc;
        break;
      case "basic":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpBasicDesc;
        break;
      case "teams":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpTeams;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.teamInvestorTipDesc;
        break;
      case "elevator-pitch":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpElevatorPitchDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.elevatorPitchInvestorTipDesc;
        break;
      case "fundraising":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpFundraisingDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.fundraisingInvestorTipDesc;
        break;
      case "documents":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpDocumentDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.documentInvestorTipDesc;
        break;
      case "social-networks":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpSocialNetworkDesc;
        break;
      case "sdg":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpSDGsDesc;
        break;
      case "traction":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpTractionDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.tractionInvestorTipDesc;
        break;
      case "company":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpCompanyDesc;
        break;
      case "market":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpMarketDesc;
        break;
      case "business-modal":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpBusinessModelDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.businessModalInvestorTipDesc;
        break;
      case "timing":
        settingUpYourProfile = lang.didYouKnow;
        settingUpDesc = lang.settingUpTimingDesc;
        thisIsPrivateByDefault = lang.investorsTip;
        thisIsPrivateDescription = lang.timingInvestorTipDesc;
        break;
      case "appearance":
        settingUpYourProfile = lang.didYouKnow;
        thisIsPrivateByDefault = lang.investorsTip;
        settingUpDesc = lang.settingUpAppearanceDesc;
    }
    setState({
      ...state,
      settingUpYourProfile,
      settingUpDesc,
      thisIsPrivateByDefault,
      thisIsPrivateDescription,
      playVideo: false,
      videoLink: "https://www.youtube.com/embed/9B7te184ZpQ?rel=0",
    });
  };

  return (
    <RightCardItem className="hidden p-0 lg:block" height={props.height}>
      <PerfectScrollbar>
        <div className="mt-2 mb-2 content">
          <h1>{state.settingUpYourProfile}</h1>
          <p>{state.settingUpDesc}</p>
        </div>
        {/* <div className='relative flex items-center justify-center w-full video-section'>
                {
                    state.playVideo ?
                    <iframe
                    id='play-link'
                    src={state.videoLink}
                    frameborder="0"
                    allowfullscreen
                    className='w-full'
                    style={{borderRadius: 10}}
                    /> :
                    <>
                    <PlayIcon className='absolute cursor-pointer' onClick={handlePlayVideo}/>
                    <img src={VideoImage} className='w-full' style={{height: 'inherit'}}/>
                    </>
                }
                </div> */}
        <div className="mt-5 content">
          <h1>{state.thisIsPrivateByDefault}</h1>
          <p>{state.thisIsPrivateDescription}</p>
        </div>
        <div className="flex items-center mt-5 content chart-section">
          <div className="w-2/3">
            <h1>{lang.foundersReport}</h1>
            <p>{`85% ${lang.foundersReportDescription}`}</p>
          </div>
          <div className="w-1/3 chart">
            <img src={FoundersChart} className="w-full" />
          </div>
        </div>
        <div className="mt-5 content">
          <h3>
            <div className="profile-img">
              <img
                // src={UserProfile}
                src="https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-2.png"
              />
            </div>
            {lang.stockhomFounderRaised}
          </h3>
          <p className="italic">{lang.stockhomFounderRaisedDesc}</p>
        </div>
        <CloseIcon
          onClick={() => props.closeRightContent()}
          style={{ width: 50 }}
          className="block float-right mb-2 mr-2 cursor-pointer lg:hidden"
        />
      </PerfectScrollbar>
    </RightCardItem>
  );
};
