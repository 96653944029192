import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WYSIWYGEditor from "pages/applications/WYSIWYGEditor";
import SaveAndCancelButtons from "./SaveAndCancelButtons";
import {
  refetchAnalysisData,
  refetchCompanyData,
  refetchCompetitorsData,
} from "pages/companyProfile/sagaActions";
import Loading from "atoms/Loading/Loading";
import { FieldName } from "gql/graphql";
import { UPDATE_AIA_PROFILE_RICH_TEXT } from "queries/editAiaProfile/editAiaProfile";
import { mixpanelEditAiaProfile } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import {
  getCompanyDetails,
  getIsPublicProfile,
} from "pages/companyProfile/selectors";
import {
  shouldRefetchAnalysisData,
  shouldRefetchCompanyData,
  shouldRefetchCompetitorsData,
} from "./utils";

// HOC injected props
export interface HOCInjectedProps {}

// Necessary props, all components must send down
interface BaseProps {
  content: string;
}

// Arbitrary props, some components need to send extra props
interface Props extends BaseProps {
  competitorId?: string;
}

export default function withContentEditable<T extends Props>(
  WrappedComponent: React.ComponentType<T & HOCInjectedProps>,
  fieldName: FieldName
) {
  const WithContentEditable = (props: T) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [updatedValue, setUpdatedValue] = useState("");

    const user = useSelector(getUser);
    const companyData = useSelector(getCompanyDetails);
    const isPublicProfile = useSelector(getIsPublicProfile);
    const companyId = companyData?.companyBySlug?.id;

    const dispatch = useDispatch();

    useEffect(() => {
      setUpdatedValue(props.content);
    }, [props.content]);

    const [updateAiaProfile, { loading }] = useMutation(
      UPDATE_AIA_PROFILE_RICH_TEXT,
      {
        onCompleted() {
          if (shouldRefetchCompanyData(fieldName))
            dispatch(refetchCompanyData({ realId: companyId, slug: "" }));

          if (shouldRefetchAnalysisData(fieldName))
            dispatch(refetchAnalysisData({ id: companyId }));

          if (shouldRefetchCompetitorsData(fieldName)) {
            dispatch(refetchCompetitorsData({ id: companyId }));
          }
        },
      }
    );

    const handleSave = () => {
      setIsEditMode(false);
      updateAiaProfile({
        variables: {
          companyId,
          fieldName,
          updatedValue,
          competitorId: props.competitorId,
        },
      });
      mixpanelEditAiaProfile(user, companyId, fieldName);
    };

    const handleCancel = () => {
      setIsEditMode(false);
      setUpdatedValue(props.content);
    };

    const handleWysiwygChange = (e: any) => {
      const str = e.replaceAll("\n", "");
      setUpdatedValue(str);
    };

    const isTainted = props.content !== updatedValue;

    if (loading) return <Loading />;

    if (isPublicProfile || !companyData?.companyBySlug?.amITeamMember)
      return <WrappedComponent {...props} />;

    return (
      <div className="relative group">
        {isEditMode ? (
          <div className="flex flex-col">
            <WYSIWYGEditor
              value={props.content}
              onChange={handleWysiwygChange}
            />
            <SaveAndCancelButtons
              onSave={handleSave}
              onCancel={handleCancel}
              className="self-end"
              saveButtonDisabled={!isTainted}
            />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={["fal", "edit"]}
              className="absolute right-2 top-2 hidden group-hover:block cursor-pointer"
              onClick={() => setIsEditMode(true)}
            />
            <WrappedComponent {...props} />
          </>
        )}
      </div>
    );
  };

  return WithContentEditable;
}
