import type { FC } from "react";
import type { InvestorCardProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Diamond } from "images/diamond.svg";
import { INVESTOR_COMPETENCES, INVESTOR_TYPES } from "core/consts";
import SDG from "molecules/Sdg/Sdg";

const getSocialLink = (link: string) =>
  link.includes("https") ? link : `https://${link}`;

const InvestorCard: FC<InvestorCardProps> = ({ investor }) => (
  <div className="pb-4">
    <div className="flex items-center px-4 mb-4 text-center">
      {investor.socials?.linkedin && (
        <a
          className="flex justify-center w-6 h-6 mr-2 bg-[#0077b7] rounded-full overflow-hidden shadow-xl"
          href={getSocialLink(investor.socials?.linkedin)}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={["fab", "linkedin-in"]}
            size="xs"
            className="relative block h-4 text-white top-[4px] text-md"
          />
        </a>
      )}
      {investor.socials?.twitter && (
        <a
          className="flex justify-center w-6 h-6 mr-2 rounded-full bg-[#00adff]"
          href={getSocialLink(investor.socials?.twitter)}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={["fab", "twitter"]}
            size="xs"
            className="relative block h-4 text-white top-[4px] text-md"
          />
        </a>
      )}
      {investor.socials?.instagram && (
        <a
          className="flex justify-center w-6 h-6 mr-2 rounded-full"
          style={{
            background: `radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)`,
          }}
          href={getSocialLink(investor.socials?.instagram)}
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            size="xs"
            className="relative block h-4 text-white top-[4px] text-md"
          />
        </a>
      )}
    </div>
    {investor.fullAddress && (
      <div className="flex items-center px-4 mb-2">
        <FontAwesomeIcon
          icon={["fas", "street-view"]}
          className="text-[#E8AF3F] text-xl mr-2"
        />
        <span className="text-sm text-gray-500">{investor.fullAddress}</span>
      </div>
    )}
    {investor.investorType && (
      <div className="flex items-center px-4 mb-2">
        <Diamond className="mr-2" />
        <span className="text-sm text-gray-500">
          {INVESTOR_TYPES[investor.investorType as keyof typeof INVESTOR_TYPES]}
        </span>
      </div>
    )}
    {investor.supportAsAdvisor && (
      <div className="flex items-center px-4 mb-4">
        <Diamond className="mr-2" />
        <span className="text-sm text-gray-500">Advisor</span>
      </div>
    )}
    {investor.competences?.length > 0 && (
      <div className="flex flex-wrap items-center px-4 mb-4">
        {investor.competences.map((key: string) => (
          <span
            key={key}
            className="bg-[#18BEB31C]  text-aqua-400 rounded-lg mt-1 mr-4 py-1 px-3 text-sm"
          >
            {INVESTOR_COMPETENCES[key as keyof typeof INVESTOR_COMPETENCES]}
          </span>
        ))}
      </div>
    )}
    {investor.description && (
      <div className="px-4 mb-4">
        <h4 className="mb-1 font-bold">About</h4>
        <div className="text-sm text-gray-500">{investor.description}</div>
      </div>
    )}
    {investor.opportunitiesInterestedIn && (
      <div className="px-4 mb-4">
        <h4 className="mb-1 font-bold text-[#3cb34b]">Most Interested</h4>
        <div className="text-sm text-gray-500">
          {investor.opportunitiesInterestedIn}
        </div>
      </div>
    )}
    {investor.opportunitiesNotInterestedIn && (
      <div className="px-4 mb-4">
        <h4 className="mb-1 font-bold text-[#ff8060]">Less Interested</h4>
        <div className="text-sm text-gray-500">
          {investor.opportunitiesNotInterestedIn}
        </div>
      </div>
    )}
    {investor.sustainableDevelopmentGoals?.items?.length > 1 && (
      <div className="px-4 mt-2">
        <h4 className="mb-1 font-bold">SDG</h4>
        <div className="flex items-start py-3 overflow-x-auto">
          {investor.sustainableDevelopmentGoals.items.map((item: number) => (
            <div className="w-10 h-10 ml-2" key={item}>
              <SDG number={item} />
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default InvestorCard;
