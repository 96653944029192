import type { NotesSidebarProps } from "./interfaces";
import type { FC } from "react";
import Documents from "./Documents";
import Comments from "./Comments";
import Header from "./Header";

const Sidebar: FC<NotesSidebarProps> = ({
  sections,
  record,
  isStatusHidden,
  link,
  onClose,
  refetch,
}) => {
  if (!record) {
    return null;
  }

  return (
    <aside className="min-w-[24rem] max-w-[24rem] max-h-[calc(100vh-204px)] bg-white border border-gray-300 rounded-md ml-4 overflow-y-auto self-end hidden lg:block invisible-scroll no-scrollbar">
      <article className="min-h-[calc(100vh-204px)] relative">
        <section className="sticky top-0 z-10">
          <Header
            sections={sections}
            record={record}
            isStatusHidden={isStatusHidden}
            link={link}
            onClose={onClose}
            refetch={refetch}
          />
        </section>
        <section className="px-4 mt-4">
          <Documents id={record.id} name={record?.company?.displayName ?? ""} />
        </section>
        <section className="px-4 mt-4">
          <Comments id={record.id} />
        </section>
      </article>
    </aside>
  );
};

export default Sidebar;
