import { FC } from "react";
import cn from "classnames";
import { BillingWrapperProps } from "../interfaces";

const BillingWrapper: FC<BillingWrapperProps> = ({ children, className }) => (
  <div
    className={cn(
      className,
      "flex flex-col items-start border-b-[1px] md:pl-8 md:pr-2 pb-6 pt-4 lg:pt-9 w-full md:w-1/2 lg:w-1/4"
    )}
  >
    {children}
  </div>
);

export default BillingWrapper;
