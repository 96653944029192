import { createContext, useContext, useEffect, useState } from "react";
import { ActivationStatus, ContextType, OpenSection } from "./types";

const defaultActivationStatus: ActivationStatus = {
  Main: false,
  Dealflow: false,
  BoardsOrAnalytics: false,
};

export const ActivationContext = createContext<ContextType>({
  isMinimized: false,
  setIsMinimized: () => undefined,
  openSection: "none",
  setOpenSection: () => undefined,
  activationStatus: defaultActivationStatus,
  setActivationStatus: (pas) => undefined,
  isFromOnboarding: false,
  isReady: false,
});

ActivationContext.displayName = "ActivationContext";

export const ActivationProvider = (props: any) => {
  const [isReady, setIsReady] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [openSection, setOpenSection] = useState<OpenSection>();
  const [isFromOnboarding, setIsFromOnboarding] = useState(false);
  const [activationStatus, setActivationStatus] = useState<ActivationStatus>(
    defaultActivationStatus
  );

  // Read from localStorage and update state
  useEffect(() => {
    const status = localStorage.getItem("ACTIVATION_STATUS");
    setActivationStatus(status ? JSON.parse(status) : defaultActivationStatus);

    const isFromOnboarding = localStorage.getItem("isFromOnboarding");
    setIsFromOnboarding(Boolean(isFromOnboarding));

    setIsReady(true);
  }, []);

  // Watch for state changes and update localStorage.
  useEffect(() => {
    if (activationStatus) {
      localStorage.setItem(
        "ACTIVATION_STATUS",
        JSON.stringify(activationStatus)
      );
    }
  }, [activationStatus]);

  const setPartialActivationStatus = (
    partailActivationStatus: Partial<ActivationStatus>
  ) => {
    setActivationStatus((prev) => {
      if (!prev) return partailActivationStatus;
      return { ...prev, ...partailActivationStatus };
    });
  };

  return (
    <ActivationContext.Provider
      value={{
        isMinimized,
        setIsMinimized,
        openSection,
        setOpenSection,
        activationStatus,
        setActivationStatus: setPartialActivationStatus,
        isFromOnboarding,
        isReady,
      }}
      {...props}
    />
  );
};

export const useActivationContext = () => {
  const context = useContext(ActivationContext);

  if (!context) {
    throw new Error(
      "useActivationContext must be used within a ActivationProvider"
    );
  }

  return context;
};
