import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiaCompetitor } from "gql/graphql";
import { FC, useState } from "react";
import cn from "classnames";

export const Expandable: FC<{ competitor: null | AiaCompetitor }> = ({
  competitor,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClick = () => setIsExpanded((prev) => !prev);

  return (
    <div
      className={cn(
        "hover:bg-gray-100 py-3 px-2 cursor-pointer transition-all duration-500",
        isExpanded && "bg-gray-50"
      )}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <span>{competitor?.name}</span>
        <FontAwesomeIcon
          icon={["fal", isExpanded ? "chevron-up" : "chevron-down"]}
          color="#4fc2ba"
          size="lg"
        />
      </div>
      {isExpanded && (
        <div className="text-sm">
          <p className="mt-2 mb-2">{competitor?.pitch}</p>
          <h6 className="font-medium text-gray-500">Similarity</h6>
          <p className="mb-2">{competitor?.comparison?.similarities}</p>
          <h6 className="font-medium text-gray-500">Difference</h6>
          <p>{competitor?.comparison?.differences}</p>
        </div>
      )}
    </div>
  );
};
