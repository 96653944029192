// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const triggerUpdateLazyParams = createAction(
  "models/lazyload/update",
  (componentId, { sorter, filters, pagination }) => ({
    payload: {
      componentId,
      sorter,
      filters,
      pagination,
    },
  })
);

export const triggerLoadMore = createAction(
  "models/lazyload/loadmore",
  (componentId) => ({
    payload: {
      componentId,
    },
  })
);
