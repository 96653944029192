import { gql } from "@apollo/client";
import { WORKSPACES } from "./me";

export const SWITCH_WORKSPACE = gql`
  mutation switchWorkspace($companyMemberId: String!) {
    switchWorkspace(companyMemberId: $companyMemberId)
  }
`;

export const GET_WORKSPACES = gql`
  ${WORKSPACES}
  query getWorkspaces {
    me {
      __typename
      ...Workspaces
    }
  }
`;
