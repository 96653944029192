import type { OptionsProps, SingleSelectChoice } from "./interfaces";
import ReactDOM from "react-dom";

const Options = <Choice extends SingleSelectChoice>({
  top,
  left,
  width,
  choices,
  onChange,
  renderOption,
  value,
  actions,
}: OptionsProps<Choice>) =>
  ReactDOM.createPortal(
    <ul
      role="listbox"
      className="absolute z-[101] overflow-hidden bg-white border border-gray-200 shadow-md rounded-xl animate-dropdown"
      style={{
        top,
        left,
        minWidth: width,
      }}
    >
      {choices.map((choice, id) => (
        <li
          key={id}
          role="option"
          aria-selected={value === choice ? "true" : "false"}
          onMouseDown={() => onChange(choice)}
          className="px-2 py-1 text-sm cursor-pointer hover:bg-aqua-50 hover:text-aqua-800"
        >
          {renderOption(choice)}
        </li>
      ))}
      {actions?.map((action, id) => (
        <li
          key={id}
          onMouseDown={action.onClick}
          role="button"
          className="relative py-2 pl-3 border-t border-gray-300 cursor-pointer select-none pr-9 hover:bg-gray-100"
        >
          {action.content}
        </li>
      ))}
    </ul>,
    document.getElementById("dropdown-portal")!
  );

export default Options;
