import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { FC, useState } from "react";

interface CarouselSlideProps {
  active?: boolean;
  slide?: JSX.Element;
}
interface CarouselProps {
  children: JSX.Element[];
  defaultIndex: number;
}
interface IndicatorProps {
  active: boolean;
  onClick: (index: number) => void;
  index: number;
}

interface CarouselSliderProps {
  currentSlide: number;
}

const CarouselSlide: FC<CarouselSlideProps> = ({ active, slide }) => (
  <div
    className={cn(
      active ? "opacity-1" : "opacity-0.5",
      "flex-none w-full h-full rounded-sm transition-all duration-500 overflow-hidden p-1"
    )}
  >
    <div className="h-full overflow-hidden rounded-md"> {slide}</div>
  </div>
);

const Indicator: FC<IndicatorProps> = ({ active, onClick, index }) => (
  <div
    className={cn(
      active ? "bg-white" : "bg-slate-700 cursor-pointer",
      "mr-5 rounded-full w-3 h-3"
    )}
    onClick={() => onClick(index)}
  />
);

const CarouselSlider: FC<CarouselSliderProps> = ({
  children,
  currentSlide,
}) => (
  <div
    className="flex transition-all duration-1000 ease-in-out"
    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
  >
    {children}
  </div>
);

const Carousel: FC<CarouselProps> = ({ children, defaultIndex }) => {
  const [currentSlide, setCurrentSlide] = useState(defaultIndex);

  const clickIndicator = (index: number) => {
    setCurrentSlide(index);
  };

  const slides = children.map((slide, index) => (
    <CarouselSlide active={currentSlide === index} key={index} slide={slide} />
  ));

  const indicators = children.map((slide, index) => (
    <Indicator
      index={index}
      key={index}
      active={currentSlide === index}
      onClick={() => clickIndicator(index)}
    />
  ));

  return (
    <div className="relative flex flex-col justify-between">
      <div className="flex justify-center">
        <CarouselSlider currentSlide={currentSlide}>{slides}</CarouselSlider>
      </div>

      <div className="flex justify-between w-full px-24 my-20">
        <button
          className="z-[101] text-white rounded-md hover:bg-gradient-to-l hover:from-slate-700 py-2 px-20 hover:-translate-x-1"
          onClick={() => {
            setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
          }}
        >
          <FontAwesomeIcon
            icon={["fal", "chevron-left"]}
            className="z-[101] text-white"
            size="3x"
          />
        </button>
        <div className="flex items-center justify-center my-6">
          {indicators}
        </div>
        <button
          className="z-[101] text-white rounded-md hover:bg-gradient-to-r hover:from-slate-700 my-auto px-20 hover:translate-x-1"
          onClick={() => {
            setCurrentSlide((currentSlide + 1) % slides.length);
          }}
        >
          <FontAwesomeIcon
            icon={["fal", "chevron-right"]}
            className="z-[101] text-white/80"
            size="3x"
          />
        </button>
      </div>
    </div>
  );
};

export default Carousel;
