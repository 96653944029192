import { FC } from "react";
import { PlansProps } from "pages/plans/interfaces";
import PlansDesktop from "./PlansDesktop";
import PlansMobile from "./PlansMobile";

const Plans: FC<PlansProps> = ({ plans }: PlansProps) => (
  <>
    <div className="flex-1 hidden lg:flex">
      <PlansDesktop plans={plans} />
    </div>
    <div className="w-full lg:hidden">
      <PlansMobile plans={plans} />
    </div>
  </>
);

export default Plans;
