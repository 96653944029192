import type { FC } from "react";
import type { ApplicationsListProps } from "./interfaces";
import { ReactComponent as AboutToDeactivateIcon } from "images/about-to-deactivate.svg";
import Button from "atoms/Button/Button";
import useApplicationsList from "./hooks/useApplicationsList";
import CustomModal from "atoms/Modal/CustomModal";
import ApplicationsListDesktop from "./ApplicationsList/DesktopView";
import ApplicationsListMobile from "./ApplicationsList/MobileView";

const ApplicationsList: FC<ApplicationsListProps> = (props) => {
  const { items, onEdit } = props;
  const {
    isConfirmation,
    getHandleCopy,
    getHandleToggle,
    handleCloseConfirmation,
    handleConfirm,
  } = useApplicationsList(props);

  return (
    <>
      {isConfirmation && (
        <div className="about-to-close-application">
          <CustomModal
            openModal={isConfirmation}
            handleCloseModal={handleCloseConfirmation}
          >
            <div className="flex flex-col items-center p-12">
              <AboutToDeactivateIcon />
              <h3 className="mt-8 text-xl font-bold">
                You are about to close your application
              </h3>
              <p className="text-gray-400">
                No one can apply to your cohort after your application is set as
                closed.
              </p>
              <p className="text-gray-400">
                Are you sure you want to close your application?
              </p>
              <div className="flex justify-between w-full mt-4">
                <Button color="white" onClick={handleCloseConfirmation}>
                  Cancel
                </Button>
                <Button color="primary" onClick={handleConfirm}>
                  Close Application
                </Button>
              </div>
            </div>
          </CustomModal>
        </div>
      )}
      <div className="hidden overflow-auto md:block">
        <ApplicationsListDesktop
          items={items}
          onEdit={onEdit}
          getOnCopy={getHandleCopy}
          getOnToggle={getHandleToggle}
        />
      </div>
      <div className="block md:hidden">
        <ApplicationsListMobile
          items={items}
          onEdit={onEdit}
          getOnCopy={getHandleCopy}
          getOnToggle={getHandleToggle}
        />
      </div>
    </>
  );
};

export default ApplicationsList;
