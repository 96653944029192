import { UserRole } from "./interfaces";

export const getUserRole = (user: any): UserRole | null => {
  if (!user) return null;

  if (user.type === "investor") {
    if (user.isAdmin) return UserRole.ADMIN;
    if (user.isAccelerator) return UserRole.ACCELERATOR;
    return UserRole.INVESTOR;
  }

  if (user.type === "company") return UserRole.STARTUP;

  return null;
};
