import { useQuery } from "@apollo/client";
import { Plan, PlanFeature, PlanPrice } from "../interfaces";
import { GET_PLANS_BY_USER } from "queries/general/account/plan";
import { getPageHeaderTag } from "../utils/getPageHeaderTag";
import { mapUserType } from "../utils/mapUserType";
import { GET_SUBSCRIPTION } from "queries/general/account/billing";

const usePlan = () => {
  const { data, loading: plansLoading } = useQuery(GET_PLANS_BY_USER, {
    fetchPolicy: "cache-and-network",
  });

  const { data: activeSubscriptionData, loading: activeSubLoading } = useQuery(
    GET_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
    }
  );

  const plans = data?.plansByUserType as Plan[];
  const activeSubscription = activeSubscriptionData?.me?.subscription;

  let userType;
  let allFeatures: PlanFeature[] = [];
  let description;
  let defaultPrice: PlanPrice | undefined;

  if (plans) {

    userType = mapUserType(plans && plans[0].userType);

    description = getPageHeaderTag(plans && plans[0].userType);

    const proPlan = plans?.find((plan) => plan.title === "Pro");

    // Find the smallest price among the prices of the "Pro" plan to prompt as trial price
    defaultPrice = proPlan?.prices?.reduce((smallest, current) => {
      if (current.price < smallest.price) {
        return current;
      }
      return smallest;
    });
  }

  const isLoading = plansLoading || activeSubLoading;

  return {
    isLoading,
    plans,
    allFeatures,
    activeSubscription,
    defaultPrice,
    header: {
      userType,
      description,
    },
  };
};

export default usePlan;
