import { gql } from "@apollo/client";

export const SAVE_COMPANY_MARKET = gql`
  mutation saveCompanyMarket($input: MarketInput) {
    saveCompanyMarket(input: $input) {
      marketOpp
    }
  }
`;

export const GET_COMPANY_MARKET = gql`
  query getCompanyMarket {
    me {
      marketOpp
    }
  }
`;
