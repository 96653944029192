import type { FC } from "react";
import type { TextFieldProps } from "./interfaces";
import cn from "classnames";

const TextField: FC<TextFieldProps> = ({
  value,
  onChange,
  className,
  label,
  isError,
  disabled,
}) => (
  <label className={cn("flex flex-col w-full", className)}>
    {label && (
      <span
        className={cn(
          "ml-2 text-sm",
          isError ? "text-red-400" : "text-gray-400"
        )}
      >
        {label}
      </span>
    )}
    <input
      type="text"
      value={value}
      onChange={({ target }) => onChange(target.value)}
      disabled={disabled}
      className={cn(
        "w-full px-2 py-1 border rounded-xl focus:ring disabled:bg-gray-100 disabled:text-gray-500",
        isError
          ? "border-red-400 focus:ring-red-500"
          : "border-gray-200 focus:ring-aqua-500"
      )}
    />
  </label>
);

export default TextField;
