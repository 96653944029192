import { FC, useEffect } from "react";
import { Person } from "gql/graphql";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import InvestorCard from "./InvestorCard";
import AddInvestorCard from "./AddInvestorCard";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";

interface Props {
  investors: Person[] | undefined | null;
}

const FormerInvestors: FC<Props> = ({ investors }) => {
  const dispatch = useDispatch();

  /*
    Investor is actually just a name.
    very limited api
    logo is just a string
    you can save all Person type stuff but they are never used except for the name
    investors saved are not connected to those from search result
    Manually added investors lack id, hence it is generated FE side
  */

  /* 
    Although all investors added from Edit page have id, some old accounts still have investors missing id.
    Missing id makes those investor non-removable.
    Following effect checks the list of investors and adds an id to each one that is is missing it.
  */
  useEffect(() => {
    if (investors && investors.length > 0 && investors.some((i) => !i.id)) {
      const investorsWithId = investors.map((i) => {
        if (i.id) return i;
        else return { ...i, id: nanoid() };
      });
      dispatch(
        updateStartupProfile({
          investors: investorsWithId,
        })
      );
    }
  }, [investors]);

  const handleRemoveInvestor = (investorId: string) => {
    if (investors)
      dispatch(
        updateStartupProfile({
          investors: investors.filter((i) => i.id !== investorId),
        })
      );
  };

  return (
    <div className="w-full mt-4 z-10">
      <h2 className="whitespace-nowrap text-lg font-bold">Cap Table</h2>
      <div className="flex flex-col lg:flex-row lg:flex-wrap gap-2">
        {investors &&
          investors.map((investor) => (
            <InvestorCard
              key={investor.id}
              investor={investor}
              handleRemoveInvestor={handleRemoveInvestor}
            />
          ))}
      </div>
      <AddInvestorCard investors={investors} />
    </div>
  );
};

export default FormerInvestors;
