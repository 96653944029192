// @ts-nocheck
import { ReactComponent as EditIcon } from "images/edit-square-icon.svg";
import { ReactComponent as EditGrayIcon } from "images/gray-pencil-icon.svg";
import { useLang } from "core/lang/LangaugeSetting";
import Tooltip from "atoms/Tooltip/Tooltip";

type TractionNoteButtonProps = {
  isTractionNoteShown: boolean;
  showNoteInput: () => void;
  className?: string;
};

const TractionNoteButton = ({
  isTractionNoteShown,
  showNoteInput,
  className,
}: TractionNoteButtonProps) => {
  const { lang } = useLang();
  return (
    <div className={`sm:w-2/5 w-1/5 ml-2 flex ${className} `}>
      <>
        {isTractionNoteShown ? (
          <EditGrayIcon className="mt-3 cursor-pointer pointer-events-none" />
        ) : (
          <Tooltip content={lang.addNote}>
            <EditIcon className="mt-3 cursor-pointer" onClick={showNoteInput} />
          </Tooltip>
        )}
      </>
    </div>
  );
};

export default TractionNoteButton;
