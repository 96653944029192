// @ts-nocheck
import { moduleTypes } from "core/modules";
import invitationReducer from "./reducer";
import invitationSaga from "./sagas";

const module = [
  {
    path: "/invitation/:invitationslug",
    key: "invitation",
    componentId: "invitation",
    component: import(
      /* webpackChunkName: "invitation" */ "./InvitationLoader"
    ),
    routes: [],
    importReducer: () => [invitationReducer],
    module: moduleTypes.INVITATION,
    importSagas: () => [invitationSaga],
    isEnabled: (state) => true,
    title: "Invitation",
    exact: true,
    name: "Invitation",
  },
];

export default module;

/**
 * Invitation solution logic
 * 1: Invitation is sent to an email from account setting page.
 * 2: An invitation is created with a unique slug, an email will be sent with link to /invitation/:slug.
 * 3: When user visits that route slug is read and invitation is fetched from server, invitation data is saved on Redux.
 * 4: Logged in user will be redirected to home page, invitation is ignored.
 * 5: Logged out user will be redirected to to login/invitation/:slug page.
 * 6: User then can accept or ignore invitation, if accepted, invitation data will be removed from redux.
 *
 * Places to look when fixing issues: invitation page, invitation form
 */
