import { FC } from "react";

import { PlansProps } from "../../interfaces";
import Plan from "../Plan";

const Plans: FC<PlansProps> = ({ plans }: PlansProps) => (
    <div className="flex flex-1 mx-10 my-8 mb-14 sm:ml-0">
      {plans.map((plan) => (
        <Plan plan={plan} key={plan.id} />
      ))}
    </div>
  );

export default Plans;
