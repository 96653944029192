import { FC } from "react";
import PureHtml from "atoms/PureHtml";
import SectionHeader from "../shared/SectionHeader/SectionHeader";

interface Props {
  marketOpp?: string;
}

const MarketOpportunity: FC<Props> = ({ marketOpp }) => {
  return (
    <div className="mb-4">
      <SectionHeader title="Market opportunity" />
      <div className="w-full whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
        <PureHtml
          content={marketOpp ? marketOpp : "No data provided"}
          tag="p"
        />
      </div>
    </div>
  );
};

export default MarketOpportunity;
