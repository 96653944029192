import { FC, useState } from "react";
import { DesktopProps, SignUpType, StepPayload } from "./interfaces";
import { ReactComponent as Logo } from "images/logo-white.svg";
import { useSteps } from "../hooks";
import { HeaderDecor } from "./decors";
import TypeStep from "./TypeStep";
import AccountStep from "./AccountStep";
import TeaserStep from "./TeaserStep";
import BackgroundGradient from "./decors/BackgroundGradient";

const START_FROM_TEASER = 2;
const START_FROM_BEGINNING = 0;

const Desktop: FC<DesktopProps> = ({ type }) => {
  const [activeType, setActiveType] = useState<SignUpType | null>("investor");

  const initialIndex = type ? START_FROM_TEASER : START_FROM_BEGINNING;

  const { next, isActive, payload } = useSteps<StepPayload>(
    ["type", "account", "teaser"],
    {
      initialIndex,
      initialPayload: { type },
    }
  );

  return (
    <div className="relative w-screen h-screen max-h-screen overflow-hidden text-white max-w-screen">
      <BackgroundGradient type={activeType} />
      <Logo className="fixed z-10 -translate-x-1/2 left-1/2 top-4" />
      <HeaderDecor spread={isActive("teaser")} />
      <TypeStep
        show={isActive("type")}
        onNext={next}
        payload={payload}
        onFocusType={setActiveType}
      />
      <AccountStep show={isActive("account")} onNext={next} payload={payload} />
      <TeaserStep show={isActive("teaser")} onNext={next} payload={payload} />
    </div>
  );
};

export default Desktop;
