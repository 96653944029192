import { FC, Fragment, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import {
  EmailType,
  Language,
  MutationSetEmailDraftSettingsArgs,
} from "gql/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "atoms/Button/Button";
import { triggerSnack } from "organisms/Snack/sagaActions";
import PureHtml from "atoms/PureHtml";
import { useMutation } from "@apollo/client";
import { SET_EMAIL_SETTINGS } from "queries/aia/generateEmail";
import { Link } from "react-router-dom";

interface Props {
  emailType: EmailType;
  setEmailType: (value: SetStateAction<EmailType>) => void;

  emailLanguage: Language;
  setEmailLanguage: (value: SetStateAction<Language>) => void;

  additionalThoughts: string;
  setAdditionalThoughts: (value: SetStateAction<string>) => void;

  onClose: () => void;
  onGenerate: () => void;
  generatedEmail: string | undefined | null;
  loading: boolean;
}

const options = [
  { title: "Draft rejection email", value: EmailType.Rejection },
  {
    title: "Draft follow-up call email",
    value: EmailType.FollowUp,
  },
];

const EmailTypeSelectAndResult: FC<Props> = ({
  emailType,
  setEmailType,
  emailLanguage,
  setEmailLanguage,
  additionalThoughts,
  setAdditionalThoughts,
  onClose,
  onGenerate,
  generatedEmail,
  loading,
}) => {
  const dispatch = useDispatch();

  const [setEmailSettings] =
    useMutation<MutationSetEmailDraftSettingsArgs>(SET_EMAIL_SETTINGS);

  const onLanguageChange = (e: Language) => {
    setEmailLanguage(e);
    setEmailSettings({
      variables: {
        selectedLanguage: e,
      },
    });
  };

  const onCopy = () => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Email draft copied to clipboard!",
      })
    );
  };

  return (
    <div>
      <h4 className="text-lg text-gray-700">
        Generate custom emails at lightning speed
      </h4>
      <p className="my-2">
        Generate tailored email drafts based on your investment criteria and the
        company’s unique profile. Haven’t set your investment criteria yet?
        Click{" "}
        <Link to="/aia" state={{ showInvestmentCriteria: true }}>
          <button className="text-aqua-400">here</button>
        </Link>
        .
      </p>
      <div className="flex flex-col gap-2 items-center flex-wrap z-10 relative sm:flex-row sm:items-end">
        <div>
          <label className="ml-1 mb-1 inline-block">Email type</label>
          <Listbox value={emailType} onChange={(e) => setEmailType(e)}>
            <div className="relative w-64">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm border border-aqua-200">
                <span className="block truncate">
                  {emailType === EmailType.Rejection
                    ? options[0].title
                    : options[1].title}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FontAwesomeIcon
                    icon={["fas", "chevron-down"]}
                    style={{ color: "#18BEB3" }}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active ? "bg-aqua-50 text-amber-900" : "text-gray-900"
                        }`
                      }
                      value={option.value}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {option.title}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <FontAwesomeIcon
                                icon={["fas", "check"]}
                                className="text-[#18BEB3]"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        <div>
          <label className="ml-1 mb-1 inline-block">Email Language</label>
          <Listbox value={emailLanguage} onChange={onLanguageChange}>
            <div className="relative w-64">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm border border-aqua-200">
                <span className="block truncate">{emailLanguage}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <FontAwesomeIcon
                    icon={["fas", "chevron-down"]}
                    style={{ color: "#18BEB3" }}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  <Listbox.Option
                    key={"English"}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-aqua-50 text-amber-900" : "text-gray-900"
                      }`
                    }
                    value="English"
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          English
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <FontAwesomeIcon
                              icon={["fas", "check"]}
                              className="text-[#18BEB3]"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                  <div className="h-0.5 bg-aqua-100 mx-2"></div>
                  {Object.keys(Language)
                    .filter((l) => l !== "English")
                    .map((option) => (
                      <Listbox.Option
                        key={option}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${
                            active
                              ? "bg-aqua-50 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={option}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {option}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                <FontAwesomeIcon
                                  icon={["fas", "check"]}
                                  className="text-[#18BEB3]"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>

        <Button
          color="primary"
          onClick={() => onGenerate()}
          disabled={loading}
          className="mt-2"
        >
          {loading ? (
            <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
          ) : (
            <span>
              {generatedEmail ? "Regenerate" : "Generate email"} draft
            </span>
          )}
        </Button>
      </div>

      <div className="my-4">
        <label htmlFor="additional-thoughts" className="text-gray-600">
          Additional comments or prompts (optional)
        </label>
        <textarea
          id="additional-thoughts"
          placeholder="Add any relevant information such as specific feedback or email structure requirements"
          rows={4}
          className="border p-2 w-full border-aqua-200 focus:outline-none focus:border-aqua-400 rounded bg-aqua-50 text-gray-600"
          value={additionalThoughts}
          onChange={(e) => {
            setAdditionalThoughts(e.target.value);
          }}
        />
      </div>

      {generatedEmail && (
        <div className="relative whitespace-pre-wrap rich-text-content-wrapper p-4 my-4 border border-aqua-400 rounded-lg bg-aqua-50 min-h-[10rem] text-gray-600 focus:outline-none">
          <CopyToClipboard onCopy={onCopy} text={generatedEmail}>
            <FontAwesomeIcon
              icon={["fal", "copy"]}
              size="lg"
              className="absolute right-4 top-4 cursor-pointer text-aqua-500"
            />
          </CopyToClipboard>
          <PureHtml content={generatedEmail ?? ""} />
        </div>
      )}

      <div className="flex justify-end">
        <Button color="transparent" className="" onClick={() => onClose()}>
          {generatedEmail ? "Close" : "Cancel"}
        </Button>
      </div>
    </div>
  );
};

export default EmailTypeSelectAndResult;
