import type { FC } from "react";
import type { HeaderDecorProps } from "./interfaces";
import { useSvg } from "../../hooks";
import useAnimatedValue from "hooks/useAnimatedValue";
import { lerp } from "utils/math";

const HEIGHT = 100;

const HeaderDecor: FC<HeaderDecorProps> = ({ spread }) => {
  const { handleRef, box, viewBox } = useSvg();
  const t = useAnimatedValue(spread ? 1 : 0);

  const paths = [
    [
      `M 0 0 L 0 ${HEIGHT} S ${box.width / 4} ${HEIGHT} ${box.width / 2} 0 Z`,
      "#2B2E43",
    ],
    [
      `M ${lerp(box.width / 6, 0, t)} 0 C ${lerp(
        box.width * 0.25,
        0,
        t
      )} ${lerp(HEIGHT, box.height, t)} ${lerp(box.width * 0.9, 0, t)} ${lerp(
        HEIGHT,
        box.height,
        t
      )} ${box.width} ${lerp(HEIGHT * 0.4, box.height, t)} L ${box.width} 0 Z`,
      "#1D1F2D",
    ],
  ];

  return (
    <svg
      ref={handleRef}
      viewBox={viewBox}
      className="fixed top-0 left-0 w-full h-full"
    >
      {paths.map(([d, fill], index) => (
        <path key={index} d={d} fill={fill} stroke="none" />
      ))}
    </svg>
  );
};

export default HeaderDecor;
