import { motion } from "framer-motion";
import background from "images/sircular-graphic.png";
import background2 from "images/sircular-graphic-2.png";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as BannerLogin } from "images/illustration.svg";
import AngelAcademyImage from "images/angel-academy-poster.png";
import AngelAcademyLogo from "images/angel-academy-logo.png";
import { FC } from "react";

interface Props {
  background: string;
}

const AcceleratorBG: FC<Props> = ({ background }) => (
  <div
    style={{
      backgroundImage: `url(${background})`,
    }}
    className="w-full h-full bg-center bg-no-repeat bg-cover"
  />
);

const AuthImage = (props: any) => {
  const isParamsEmpty = props?.params
    ? Object.keys(props.params).length === 0
    : true;
  const isParamsIdEmpty = props?.params?.id === undefined;
  const { lang } = useLang();

  return (
    <div
      className={`hidden lg:block flex-1 w-1/2 ${
        props?.params
          ? "bg-white fixed	 h-screen right-0"
          : "relative bg-midnight"
      }`}
    >
      {isParamsEmpty || isParamsIdEmpty ? (
        <>
          <motion.img
            animate={{
              opacity: [1, 0],
            }}
            transition={{
              duration: 1.5,
              times: [0, 1],
              yoyo: Infinity,
              repeatDelay: 5,
              delay: 5,
              ease: [0.455, 0.03, 0.515, 0.955],
            }}
            className="absolute inset-0 object-cover w-full h-full"
            src={background}
            alt="background"
          />
          <motion.img
            animate={{
              opacity: [0, 1],
            }}
            initial={{
              opacity: 0,
            }}
            transition={{
              duration: 1.5,
              times: [0, 1],
              yoyo: Infinity,
              repeatDelay: 5,
              delay: 5,
              ease: [0.455, 0.03, 0.515, 0.955],
            }}
            className="absolute inset-0 object-cover w-full h-full"
            src={background2}
            alt="background2"
          />
        </>
      ) : props?.id === "angelacademy" ? (
        <>
          <AcceleratorBG background={AngelAcademyImage} />
          <img className="absolute bottom-4 right-4" src={AngelAcademyLogo} />
        </>
      ) : (
        <AcceleratorBG background={props.poster} />
      )}
      <div className="absolute left-0 right-0 flex-1 hidden w-full lg:flex __banners">
        {(isParamsEmpty || isParamsIdEmpty) && (
          <BannerLogin className="w-4/5" />
        )}
        {(isParamsEmpty || isParamsIdEmpty) && (
          <>
            <h2 className="mb-3 text-3xl">{lang.howSircularHelps}</h2>
            <p className="text-white">
              {lang.seeHowSircularCanTakeBusinessForward}
              <br />
              {lang.takeJustTwoMinutesToSignIn}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AuthImage;
