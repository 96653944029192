import { FetchPolicy } from "@apollo/client";
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import {
  GET_ACTIVE_COHORT,
  GET_COHORT_BY_SLUG,
  GET_CURRENCY_LIST,
  GET_ME,
  UPLOAD_LOGO,
} from "queries/general/me";
import { put, takeEvery } from "redux-saga/effects";
import { LOADER_CURRENCY_LIST, USER_DATA } from "./constants";

import * as slice from "./reducer";
import {
  fetchCurrencyList,
  fetchUserData,
  refetchUserData,
  setUserHidden,
  fetchActiveCohort,
  uploadLogo,
} from "./sagaActions";
import { GET_SUBSCRIPTION } from "queries/general/account/billing";

const getActiveCohort = async () => {
  try {
    const client = getClient();

    return await client.query({
      query: GET_ACTIVE_COHORT,
    });
  } catch (error) {
    return { isError: true, error };
  }
};

export const getCohortBySlug = async (slug: string) => {
  try {
    const client = getClient();

    return await client.query({
      query: GET_COHORT_BY_SLUG,
      variables: { slug },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

export const getUserSubscription = async (
  fetchPolicy: FetchPolicy = "network-only"
) => {
  try {
    const client = getClient();
    return await client.query({
      query: GET_SUBSCRIPTION,
      fetchPolicy,
    });
  } catch (error) {
    return { isError: true, error };
  }
};

export const getUserData = async (
  fetchPolicy: FetchPolicy = "network-only"
) => {
  try {
    let client = getClient();
    return await client.query({
      query: GET_ME,
      fetchPolicy,
    });
  } catch (error) {
    return { isError: true, error };
  }
};

const getCurrencyList = async () => {
  let client = getClient();

  return await client
    .query({ query: GET_CURRENCY_LIST })
    .catch((error) => ({ isError: true, error }));
};

export function* handleFetchUser(action: any) {
  const componentLoader = action?.payload?.component
    ? action?.payload?.component
    : USER_DATA;

  yield put(
    toggleLoader({
      component: componentLoader,
      isLoading: true,
    })
  );
  const fetchPolicy = action?.payload ? action.payload?.fetchPolicy : action;
  const { isError, error, data } = yield getUserData(fetchPolicy);
  if (isError) {
    let errorMessage = "";
    if (error?.graphQLErrors[0]?.message) {
      errorMessage = error.graphQLErrors[0].message;
      yield put(
        slice.updateUserData({ userData: null, isLoggedInUser: false })
      );
    }
    yield put(
      toggleLoader({
        component: componentLoader,
        isLoading: false,
      })
    );
    return {
      isError,
      error,
    };
  }

  const {
    isError: subscriptionError,
    error: subscriptionErrorData,
    data: subscriptionData,
  } = yield getUserSubscription(fetchPolicy);

  if (subscriptionError) {
    let errorMessage = "";
    if (subscriptionErrorData?.graphQLErrors[0]?.message) {
      errorMessage = subscriptionErrorData.graphQLErrors[0].message;
    }
    yield put(
      toggleLoader({
        component: componentLoader,
        isLoading: false,
      })
    );
    return {
      isError: subscriptionError,
      error: subscriptionErrorData,
    };
  }

  yield put(
    slice.updateUserData({
      userData: { ...data.me, isAnalyticTrial: true },
      isLoggedInUser: true,
      hidden: data?.me?.hidden,
    })
  );

  yield put(
    slice.updateUserSubscription({
      subscriptionData: subscriptionData?.me?.subscription,
    })
  );

  try {
    if (data?.me?.parentId || data?.me?.isAccelerator || data?.me?.isInvestor) {
      let id = "";
      if (data?.me?.parentId) {
        id = data?.me?.parentId;
      }
      if (data?.me?.isAccelerator || data?.me?.isInvestor) {
        id = data?.me?._id;
      }
      if (id) {
        yield put(fetchActiveCohort({}));
      }
    }
  } catch (e) {
    console.log(e);
  }

  yield put(
    toggleLoader({
      component: componentLoader,
      isLoading: false,
    })
  );
  return data;
}

export function* handleRefetchUser() {
  const { isError, error, data } = yield getUserData();
  if (isError) {
  }
  yield put(slice.updateUserData({ userData: data.me, isLoggedInUser: true }));
  if (data?.me?.parentId || data?.me?.isAccelerator) {
    let id = "";
    if (data?.me?.parentId) {
      id = data?.me?.parentId;
    }
    if (data?.me?.isAccelerator) {
      id = data?.me?._id;
    }
  }
  return data;
}

export function* handleFetchCurrency() {
  yield put(
    toggleLoader({
      component: LOADER_CURRENCY_LIST,
      isLoading: true,
    })
  );
  const { data, isError } = yield getCurrencyList();
  if (!isError) {
    yield put(
      slice.updateCurrencyList({ currencyList: data?.getCurrenciesList })
    );
  }

  yield put(
    toggleLoader({
      component: LOADER_CURRENCY_LIST,
      isLoading: false,
    })
  );
}

export function* handleFetchActiveCohort() {
  const { data, isError } = yield getActiveCohort();

  if (!isError && data) {
    yield put(slice.updateActiveCohort(data.activeCohort));
  }
}

// @ts-ignore
function* handleSetUserHidden({ payload: { hidden } }) {
  yield put(
    slice.updateUserHidden({
      hidden,
    })
  );
}

// @ts-ignore
function* handleUploadLogo({ payload: { lang, file } }) {
  const client = getClient();
  const { isError } = yield client
    .mutate({
      mutation: UPLOAD_LOGO,
      fetchPolicy: "network-only",
      variables: {
        file,
      },
    })
    .catch((error) => ({ isError: true, error }));

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSaveYourLogo,
      })
    );
    return;
  }
  yield put(refetchUserData({}));
  yield put(
    triggerSnack({
      type: "success",
      title: lang.savedAutomatically,
      message: lang.logoUpdated,
    })
  );
}

export default function* rootSaga(): any {
  return [
    yield takeEvery(fetchUserData, handleFetchUser),
    yield takeEvery(refetchUserData, handleRefetchUser),
    yield takeEvery(fetchActiveCohort, handleFetchActiveCohort),
    yield takeEvery(fetchCurrencyList, handleFetchCurrency),
    yield takeEvery(setUserHidden, handleSetUserHidden),
    yield takeEvery(uploadLogo, handleUploadLogo),
  ];
}
