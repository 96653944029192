import { FC } from "react";
import { Person } from "gql/graphql";

interface Props {
  founders: Person[] | undefined | null;
}

const Founders: FC<Props> = ({ founders }) => {
  if (!founders || founders.length === 0) return null;

  return (
    <div className="p-2">
      <h2 className="whitespace-nowrap text-sm mb-2 font-bold">CEO/Founder </h2>
      {founders.map((f) => (
        <p key={f.id}>{f.name}</p>
      ))}
    </div>
  );
};

export default Founders;
