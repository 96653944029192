import { restApiBaseUrl } from "core/config";
import { mixpanelToken } from "core/config";
import mixpanel from "mixpanel-browser";

if (process.env.NODE_ENV === "production") {
  mixpanel.init(mixpanelToken as string, {
    ignore_dnt: true,
    api_host: restApiBaseUrl + "/mp",
  });
} else {
  mixpanel.init(mixpanelToken as string, {
    debug: true,
    ignore_dnt: true,
    api_host: restApiBaseUrl + "/mp",
  });
}

const env_check = process.env.NODE_ENV === "production";

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  track: (name: string, props?: {}) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: {}) => {
      if (env_check) mixpanel.people.set(props);
    },
    setOnce: (props: {}) => {
      if (env_check) mixpanel.people.set_once(props);
    },
  },
  register: (props: {}) => {
    if (env_check) mixpanel.register(props);
  },
  registerOnce: (props: {}) => {
    if (env_check) mixpanel.register_once(props);
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
  timeEvent: (name: string) => {
    if (env_check) mixpanel.time_event(name);
  },
  getProperty: (name: string) => {
    if (env_check) mixpanel.get_property(name);
  },
};

export const Mixpanel = actions;
