import { Menu, MenuBackground, MenuDrawer } from "./MenuDrawer.style";
import { menuDrawer, menuDrawerFade } from "ui/animations";
import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withRouter } from "hooks/withRouter";

const MenuDrawerScreen = ({
  children,
  isSideNavShown,
  toggleMenuDrawer,
}: any) => (
  <AnimatePresence>
    <MenuDrawer>
      <MenuBackground
        variants={menuDrawerFade}
        initial="hidden"
        exit="hidden"
        animate={isSideNavShown ? "show" : "rest"}
        onClick={() => toggleMenuDrawer()}
      />
      <Menu
        variants={menuDrawer}
        initial="hidden"
        exit="hidden"
        animate={isSideNavShown ? "show" : "rest"}
      >
        <div
          className="z-50 text-gray-500 rounded-full close-menu hover:bg-gray-100"
          onClick={() => toggleMenuDrawer()}
        >
          <FontAwesomeIcon className="mb-2" icon={["fal", "times"]} />
        </div>
        {children}
      </Menu>
    </MenuDrawer>
  </AnimatePresence>
);
export default withRouter(MenuDrawerScreen);
