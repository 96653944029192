import Chart from "react-apexcharts";
import React, { forwardRef } from "react";
import { format } from "date-fns";

import { useLang } from "core/lang/LangaugeSetting";

const options = {
  stroke: {
    width: 2,
  },
  noData: {
    text: "Loading...",
  },
  responsive: [
    {
      breakpoint: 1000,
      options: {
        xaxis: {
          tickAmount: 3,
        },
      },
    },
  ],
  chart: {
    stacked: true,
    id: "area-datetime",
    type: "area",
    height: 350,
    toolbar: {
      show: false,
    },
    zoom: {
      autoScaleYaxis: false,
      enabled: false,
    },
  },
  annotations: {
    yaxis: [
      {
        // y: 30,
        borderColor: "#999",
        label: {
          show: true,
          text: "",
          style: {
            color: "#fff",
            background: "#00E396",
          },
        },
      },
    ],
    xaxis: [
      {
        // x: new Date('14 Nov 2012').getTime(),
        borderColor: "#999",
        yAxisIndex: 0,
        label: {
          show: true,
          text: "",
          style: {
            color: "#fff",
            background: "#000",
            // background: '#775DD0'
          },
        },
      },
    ],
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "category", //datetime/category
    position: "bottom",
    title: {
      text: "",
    },
    labels: {
      rotate: -0,
      show: true,
      formatter: function (val) {
        if (!val) {
          return "";
        }

        return format(new Date(val), "dd MMM");
      },
    },
    tickAmount: 15,
  },
  tooltip: {
    style: {
      background: "#000",
    },
  },
  colors: ["#18beb3"],
  grid: {
    show: false,
  },
  fill: {
    type: "gradient",
    colors: ["#18beb3"],
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
    },
  },
};

export default forwardRef(
  ({ title, chartData, filterType, year, ...props }, forwardedRef) => {
    const { lang } = useLang();
    let dateFormat = "dd MMM";
    var type =
      filterType === "visits"
        ? "Visits"
        : filterType === "downloads"
        ? "Downloads"
        : "Unique Visits";
    if (year === lang.past30Days) {
      dateFormat = "dd MMM";
    } else if (year === lang.pastThreeMonth) {
      dateFormat = "dd MMM";
    }

    return (
      <Chart
        key={year}
        // ref={forwardedRef}
        options={options}
        series={[
          {
            name: type,
            data: chartData,
          },
        ]}
        type="area"
        height={350}
      />
    );
  }
);
