import React, { FC } from "react";

interface Props {
  title: string;
  mb?: number;
  mr?: number;
}

const SectionHeader: FC<Props> = ({ title, mb = 2, mr = 0 }) => {
  return (
    <div className={`mb-${mb} mr-${mr}`}>
      <h1 className="text-2xl text-gray-700 dark:text-white/80">{title}</h1>
    </div>
  );
};

export default SectionHeader;
