import type { FC } from "react";
import type { BottomPanelProps } from "./interfaces";
import Pagination from "./Pagination";

const BottomPanel: FC<BottomPanelProps> = ({ ...paginationProps }) => (
  <div className="flex justify-end py-2 bg-gray-100 h-[66px] md:sticky bottom-0 z-[49]">
    <Pagination {...paginationProps} />
  </div>
);

export default BottomPanel;
