// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { LOAD_MORE } from "./constants";

export const initialState = {};

export const name = "models/lazyload";

export const defaultFilters = {
  pagination: {
    skip: 0,
    limit: LOAD_MORE,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateParams: (
      state,
      { payload: { componentId, filters, sorter, pagination } }
    ) => ({
        ...state,
        [componentId]: {
          filters,
          sorter,
          pagination: { ...defaultFilters.pagination, ...pagination },
        },
      }),
  },
});

export default slice;

export const { updateParams } = slice.actions;
