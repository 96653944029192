import { FC } from "react";
import { useActivationContext } from "../context";
import DealflowActivation from "./DealflowActivation";
import { ActivationWrapperProps } from "../types";

const DealflowActivationWrapper: FC<ActivationWrapperProps> = ({
  userType,
}) => {
  const {
    activationStatus,
    setActivationStatus,
    setIsMinimized,
    setOpenSection,
    isFromOnboarding,
    isReady,
  } = useActivationContext();

  if (!isReady) return null;
  if (!isFromOnboarding) return null;
  if (activationStatus?.Dealflow) return null;

  return (
    <DealflowActivation
      activationStatus={activationStatus}
      setActivationStatus={setActivationStatus}
      setIsMinimized={setIsMinimized}
      setOpenSection={setOpenSection}
      userType={userType}
    />
  );
};

export default DealflowActivationWrapper;
