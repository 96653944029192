import { FC } from "react";
import cn from "classnames";
import ExpandableSection from "./ExpandableSection";
import { ActivationSectionProps } from "organisms/Activation/types";

const DealflowSection: FC<ActivationSectionProps> = ({
  isOpen,
  isDone,
  onClick,
  onButtonClick,
  userType,
}) => (
  <ExpandableSection
    number="1"
    title="Start using Dealflow"
    isOpen={isOpen}
    isDone={isDone}
    onClick={onClick}
  >
    <div className="ml-10 mr-4 -mt-2">
      <p
        className={cn(
          "max-w-[20rem] text-sm text-white",
          isDone && "line-through"
        )}
      >
        You can keep track of added{" "}
        {userType === "company" ? " investors " : " companies "}
        in your Dealflow
      </p>
      {!isDone && (
        <button
          className="px-3 py-2 my-3 text-sm bg-white rounded-md text-aqua-400"
          onClick={onButtonClick}
        >
          Add {userType === "company" ? " an investor " : " a company "} to my
          Dealflow
        </button>
      )}
    </div>
  </ExpandableSection>
);

export default DealflowSection;
