import { FC } from "react";
import cn from "classnames";

interface Props {
  color: string;
}

const Chip: FC<Props> = ({ color, ...props }) => (
  <button
    className={cn(
      "inline-flex items-center px-3 mr-3 text-xs leading-6 text-black transition duration-150 ease-in-out bg-gray-200 border border-transparent rounded-full shadow-sm focus:outline-none whitespace-nowrap",
      color === "primary" && "text-white bg-aqua-400 hover:bg-aqua-500",
      color === "secondary" && "text-white bg-fire-400 hover:bg-fire-500",
      color === "warning" && "bg-yellow-400 hover:bg-yellow-500",
      color === "dark" && "bg-midnight",
      color === "white" &&
        "text-black bg-white border border-gray-200 border-solid hover:bg-gray-50"
    )}
    {...props}
  >
    {props.children}
  </button>
);

export default Chip;
