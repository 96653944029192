import type { AnimatedValueConfig } from "./interfaces";
import { useSpring } from "framer-motion";
import { useEffect, useState } from "react";

const useAnimatedValue = (
  value: number,
  { duration = 1000, damping = 10, ...rest }: AnimatedValueConfig = {}
) => {
  const spring = useSpring(value, { duration, damping, ...rest });
  const [currentValue, setCurrentValue] = useState(spring.get());

  useEffect(() => {
    const unsubscribe = spring.onChange(setCurrentValue);

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    spring.set(value);
  }, [value, spring]);

  return currentValue;
};

export default useAnimatedValue;
