import Tooltip from "atoms/Tooltip/Tooltip";
import { FC } from "react";
import { InfoButtonProps } from "./interfaces";

const InfoButton: FC<InfoButtonProps> = ({ info, children }) => {
  if (!info) {
    return <>{children}</>;
  }

  return <Tooltip content={info}>{children}</Tooltip>;
};

export default InfoButton;
