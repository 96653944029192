import { useQuery } from "@apollo/client";
import { GET_NUMBER_OF_UPLOADS } from "queries/editAiaProfile/editAiaProfile";
import { FC, PropsWithChildren } from "react";
import { useLocalStorage } from "react-use";
import AiaActivationBubble from "./AiaActivationBubble";
import { AiaActivationConfig } from "./consts";

interface AiaActivationConfig {
  id: string;
  numberOfUploads: number;
  content: string;
  placement: string;
}

interface Props {
  config: AiaActivationConfig;
  bottomOffset?: number;
}

const WithActivation: FC<PropsWithChildren<Props>> = ({
  config,
  children,
  bottomOffset,
}) => {
  const [seenAiaActivation, setSeenAiaActivation] = useLocalStorage(
    config.id,
    false
  );

  const { data, loading } = useQuery(GET_NUMBER_OF_UPLOADS);

  const uploads = data?.me?.company?.aiaTrial?.totalUploads || 999; // to show all activation for when uploades is null

  if (loading) return null;

  return (
    <div className="relative">
      {children}
      {!loading &&
        !seenAiaActivation &&
        config.numberOfUploads < Number(uploads) && (
          <AiaActivationBubble
            config={config}
            setSeenActivation={() => setSeenAiaActivation(true)}
            bottomOffset={bottomOffset}
          />
        )}
    </div>
  );
};

export default WithActivation;
