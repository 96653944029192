import { FC } from "react";
import {
  PitchDeckItem,
  PitchDeckFile,
} from "pages/dealflow/InvestorDealflow/components/RecordModal/interfaces";
import PitchDeckUpload from "pages/dealflow/InvestorDealflow/components/RecordModal/PitchDeckUpload";
import { Document } from "gql/graphql";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";

interface Props {
  pitchDeckItems: Document[] | undefined | null;
}

const PitchDeck: FC<Props> = ({ pitchDeckItems }) => {
  const dispatch = useDispatch();

  const setPitchDeckItems = (items: PitchDeckItem[]) => {
    const documents = items.map((i) => i.id);
    const documentUploads = items
      .filter((i): i is PitchDeckFile => i.id === "upload")
      .map((i) => i.file);

    dispatch(updateStartupProfile({ documentUploads, documents }));
  };

  return (
    <div className="w-full basis-1/2">
      <div className="mb-2 font-semibold">Pitch Deck</div>
      <PitchDeckUpload
        items={
          pitchDeckItems?.map((i) => ({ id: i.id!, title: i.title! })) || []
        }
        onChange={setPitchDeckItems}
        extraInfo="Upload your pitch deck and any other documents that you'd like to present to the investors"
      />
    </div>
  );
};

export default PitchDeck;
