import type { FC } from "react";
import type { BackgroundGradientProps } from "./interfaces";
import useAnimatedValue from "hooks/useAnimatedValue";

const TYPE_GRADIENTS = {
  startup: [12, 80, 80],
  investor: [49, 14, 104],
  accelerator: [56, 0, 54],
  login: [20, 58, 63],
} as const;

const NEUTRAL_GRADIENT = [43, 46, 67] as const;

const BackgroundGradient: FC<BackgroundGradientProps> = ({ type }) => {
  const colors = type ? TYPE_GRADIENTS[type] : NEUTRAL_GRADIENT;
  const r = useAnimatedValue(colors[0], { duration: 300 });
  const g = useAnimatedValue(colors[1], { duration: 300 });
  const b = useAnimatedValue(colors[2], { duration: 300 });

  const background = `linear-gradient(to top, rgb(${r}, ${g}, ${b}), #0B0D18 10%)`;

  return <div style={{ background }} className="absolute inset-0" />;
};

export default BackgroundGradient;
