// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import { updateShareProfileModalData } from "./reducer";
import { triggerShareModal } from "./sagaActions";

export function* handleShareModalUpdate({ payload }) {
  yield put(updateShareProfileModalData(payload));
}

export default function* rootSaga() {
  return [yield takeEvery(triggerShareModal, handleShareModalUpdate)];
}
