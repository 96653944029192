// @ts-nocheck

import Button from "atoms/Button/Button";
import { PASSWORD_PATTERN } from "core/consts";
import { ReactComponent as Logo } from "images/logo-black.svg";
import { useLang } from "core/lang/LangaugeSetting";
import { getActiveCohort } from "models/user/selectors";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { resetPassword } from "../sagaActions";
import { getResetStatus, isValidToken } from "../selectors";
import ConfirmResetPassword from "./ConfirmResetPassword";
import { isLoadingEnabled } from "models/loaders/selectors";
import { RESET_PASSWORD_BUTTON } from "../constants";
import Tooltip from "atoms/Tooltip/Tooltip";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";

export const ResetPasswordForm = (props) => {
  const { params, token } = props;
  const { lang } = useLang();
  const dispatch = useDispatch();

  // Selectors
  const isLoading = useSelector((state) =>
    isLoadingEnabled(state, RESET_PASSWORD_BUTTON)
  );
  const resetToken = useSelector((state) => isValidToken(state));
  const activeCohort = useSelector(getActiveCohort);
  const resetStatus = useSelector((state) => getResetStatus(state));

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const submitHandler = (e: Event) => {
    e.preventDefault();
    handleSubmit(onSubmit)();
  };

  const onSubmit = () => {
    const formValues = getValues();
    if (formValues.newPassword !== formValues.confirmPassword) {
      let a = [
        {
          type: "required",
          name: "newPassword",
          message: lang.passwordAreNotSame,
        },
        {
          type: "required",
          name: "confirmPassword",
          message: lang.passwordAreNotSame,
        },
      ].forEach(({ type, name, message }) => {
        setError(name, { type, message }, { shouldFocus: true });
      });
      return;
    }
    dispatch(
      resetPassword({
        formValues,
        token,
      })
    );
  };

  if (resetStatus === "success") {
    return <ConfirmResetPassword language={lang} />;
  }

  if (resetToken?.isValidResetToken) {
    return (
      <>
        <div>
          {activeCohort?.logo?.large?.location ? (
            <img
              src={activeCohort?.logo?.large?.location}
              className={`__Logo ${
                params.id === "time-to-raise" && "time-to-raise"
              }`}
            />
          ) : (
            <Logo className="w-auto h-12" />
          )}
          <h2 className="mt-6 text-2xl font-extrabold text-black leading-7">
            {lang.chooseNewPassword}
          </h2>
          <p>{lang.resetPasswordDesc}</p>
        </div>
        <div className="mt-6">
          <form>
            <div className="mb-4">
              <label
                className="flex items-center mb-1 text-sm text-gray-500 "
                htmlFor="newPassword"
              >
                {lang.newPassword}
                <Tooltip
                  content={
                    <div>
                      <p className="text-white">Minimum of 8 characters.</p>
                      <p className="text-white">
                        Minimum of 1 lowercase letter.
                      </p>
                      <p className="text-white">
                        Minimum of 1 uppercase letter.
                      </p>
                      <p className="text-white">Minimum of 1 number.</p>
                      <p className="text-white">
                        Minimum of 1 special character !@#$%^&*
                      </p>
                    </div>
                  }
                  place="right"
                  widerClickableArea
                >
                  <HelpIcon className="w-3 ml-2" />
                </Tooltip>
              </label>
              <input
                className={`shadow appearance-none ${
                  !!errors.newPassword ? "border-red-500" : ""
                } border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline`}
                id="newPassword"
                type="password"
                {...register("newPassword", {
                  required: true,
                  pattern: PASSWORD_PATTERN,
                })}
              />
              {errors.newPassword && (
                <p className="text-xs text-fire-400">
                  {errors.newPassword.message || lang.passwordError}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block mb-1 text-sm text-gray-500"
                htmlFor="confirmPassword"
              >
                {lang.confirmPassword}
              </label>
              <input
                className={`shadow appearance-none ${
                  !!errors.confirmPassword ? "border-red-500" : ""
                } border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline`}
                id="confirmPassword"
                type="password"
                {...register("confirmPassword", { required: true })}
              />
              {errors.confirmPassword && (
                <p className="text-xs text-fire-400">
                  {errors.confirmPassword.message || lang.mandatory}.
                </p>
              )}
            </div>

            <div className="mt-6">
              <Button
                className="justify-center w-full"
                color="primary"
                size="large"
                type="submit"
                loading={isLoading}
                onClick={submitHandler}
                disabled={undefined}
              >
                {lang.resetPassword}
              </Button>
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <>
      <div>
        {activeCohort?.logo?.large?.location ? (
          <img src={activeCohort?.logo?.large?.location} className="__Logo" />
        ) : (
          <Logo className="w-auto h-12" />
        )}
        <h2 className="mt-6 text-2xl font-extrabold text-black leading-7">
          {lang.resetLinkHasBeenDeleted}
        </h2>
        <p>
          {`${lang.stilDoNotRememberYourPassword} `}
          <Link to="/forgot-password" className="text-blue-600 hover:underline">
            {lang.thisLink}
          </Link>
          {" " + lang.toResetPassword}
        </p>
      </div>
    </>
  );
};

export default ResetPasswordForm;
