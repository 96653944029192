import { moduleTypes } from "core/modules";
import { isLoggedinUser } from "models/user/selectors";

const module = [
  {
    path: "/plans",
    key: "plans",
    componentId: "plans",
    component: import(/* webpackChunkName: "plans" */ "./PlansContainer"),
    routes: [],
    module: moduleTypes.PLANS,
    isEnabled: (state: any) => {
      // disabling route temporarilty on prod
      const url = new URL(window.location.toString());
      return isLoggedinUser(state) && url.hostname !== "app.sircular.io";
    },
    importReducer: () => [],
    importSagas: () => [],
    title: "Plans",
    exact: true,
    name: "Plans",
  },
];

export default module;
