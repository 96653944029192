import { gql } from "@apollo/client";

export const GET_RATE_LIMITS = gql`
  query getAiaRateLimits($companyId: ID!, $features: [String!]) {
    aiaRateLimits {
      aiaRateLimits(companyId: $companyId, features: $features) {
        feature
        limit
        timeUntilReset
        remaining
        interval
      }
    }
  }
`;
