import type { GoogleInputPlace, TeaserStepProps } from "../interfaces";
import { FC, useState } from "react";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import { SingleSelect } from "molecules/SingleSelect";
import GoogleInput from "molecules/Form/GoogleInput";
import { TextField } from "pages/onboard/components";

const INVESTOR_TYPES = [
  {
    id: "ANGEL_INVESTOR",
    title: "Angel Investor",
  },
  {
    id: "VC",
    title: "VC",
  },
  {
    id: "FAMILY_OFFICE",
    title: "Family Office",
  },
];
const Welcome: FC<TeaserStepProps> = ({ onNext, type: userType }) => {
  const [logo, setLogo] = useState<File | null>(null);
  const [location, setLocation] = useState("");
  const [place, setPlace] = useState<GoogleInputPlace>();
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [lastName, setLastName] = useState("");
  const [type, setType] = useState<(typeof INVESTOR_TYPES)[number] | null>(
    null
  );
  const [isError, setIsError] = useState(false);
  const [currentWorkAt, setCurrentWorkAt] = useState("");
  const [currentWorkTitle, setCurrentWorkTitle] = useState("");

  // There is no company name field for Angels;
  const isCompanyValid = type?.id === INVESTOR_TYPES[0].id ? true : companyName;

  const isValid =
    firstName &&
    lastName &&
    isCompanyValid &&
    place &&
    (type || userType === "accelerator");

  const handleNext = () => {
    setIsError(!isValid);

    if (isValid) {
      onNext({
        welcome: {
          logo,
          firstName,
          lastName,
          companyName,
          type,
          place,
          currentWorkAt,
          currentWorkTitle,
        },
      });
    }
  };

  return (
    <div className="flex flex-col justify-between w-full h-full px-2 py-6 md:px-16">
      <div>
        <h2 className="text-xl text-center">Welcome to Sircular!</h2>
        <div className="px-4 mt-4 text-center text-gray-600">
          Let's start by making your profile a bit more personal.
        </div>
        <div className="flex w-full px-6 mt-6">
          <TextField
            label="First name"
            value={firstName}
            onChange={setFirstName}
            isError={isError && !firstName}
          />
        </div>
        <div className="flex w-full px-6 mt-2">
          <TextField
            label="Last name"
            value={lastName}
            onChange={setLastName}
            isError={isError && !lastName}
          />
        </div>

        {userType === "investor" && (
          <div className="flex flex-col w-full px-6 mt-4">
            {isError && !type && (
              <span className="ml-2 text-sm text-red-400">
                Please choose the type
              </span>
            )}
            <SingleSelect
              emptyText="What type of investor are you?"
              choices={INVESTOR_TYPES}
              value={type}
              onChange={setType}
            />
            {type?.id === INVESTOR_TYPES[0].id && (
              <div>
                <TextField
                  label="At what company do you work?"
                  value={currentWorkAt}
                  onChange={setCurrentWorkAt}
                  className="mt-6"
                />
                <TextField
                  label="What is your title?"
                  value={currentWorkTitle}
                  onChange={setCurrentWorkTitle}
                  className="mt-6"
                />
              </div>
            )}
          </div>
        )}

        {type?.id !== INVESTOR_TYPES[0].id && (
          <div className="flex flex-col items-start w-full px-6">
            <TextField
              label="Company name"
              value={companyName}
              onChange={setCompanyName}
              className="mt-2"
              isError={isError && !companyName}
            />
          </div>
        )}
        <div className="flex flex-col items-start w-full px-6 mt-4">
          <GoogleInput
            label="Where are you located?"
            labelClass="ml-2 text-sm font-normal !text-gray-400"
            getPlace={(newPlace: GoogleInputPlace) => {
              setPlace(newPlace);
              setLocation(newPlace.autoCompleteVal);
            }}
            value={location}
            onChange={(e: any) => setLocation(e.target.value)}
            error={Boolean(isError && !place)}
          />
        </div>
        <div className="flex justify-center mt-6">
          <ProfilePictureSelect label="Profile picture" onComplete={setLogo} />
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <span className="mr-8 text-sm text-red-500">
          {isError && !isValid && "Please fill all required fields"}
        </span>
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default Welcome;
