import { FC, useState } from "react";
import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/20/solid";

import { ReactComponent as LoadingSpinner } from "images/loading-spinner.svg";
import Button from "atoms/Button/Button";
import { ReactComponent as InfoIcon } from "images/info-icon.svg";

import { AiaAnalyseStartupListResult } from "gql/graphql";
import {
  INITIALISE_AIA_STARTUP_LIST,
  ANALYSE_AIA_STARTUP_LIST,
} from "./queries";

import PitchDeckError from "./PitchDeckError";
import { useDropzone } from "react-dropzone";
import Checkbox from "atoms/Checkbox/Checkbox";
import TooltipWrapper from "atoms/Tooltip/Tooltip";
import { getUser } from "models/user/selectors";
import { useSelector } from "react-redux";

type Steps = "UPLOAD" | "ANALYSE" | "CONFIRM" | "FINISH";

const UploadStartupList: FC<{
  openCriteriaDialog: () => void;
  closeDialog: () => void;
}> = ({ openCriteriaDialog, closeDialog }) => {
  const [currentStep, setCurrentStep] = useState<Steps>("UPLOAD");
  const [file, setFile] = useState<File | null>(null);
  const [initResponse, setInitResponse] =
    useState<AiaAnalyseStartupListResult | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const user = useSelector(getUser);

  const [initCsv, { loading: initLoading, error: initError, reset: resetInit }] = useMutation(
    INITIALISE_AIA_STARTUP_LIST,
    {
      onCompleted: (data) => {
        if (data.aia.initialiseStartupList) {
          setInitResponse(data.aia.initialiseStartupList);
          setCurrentStep("CONFIRM");
        }
      },
    }
  );

  const [analyseCsv, { loading: analyseLoading, error: analyseError, reset: resetAnalyse }] =
    useMutation(ANALYSE_AIA_STARTUP_LIST, {
      onCompleted: () => {
        setCurrentStep("FINISH");
      },
    });

  const handleUpload = async () => {
    if (file) {
      return await initCsv({
        variables: {
          file,
        },
      });
    }
  };

  const handleApprove = async () => {
    setIsApproved(!isApproved);
  };

  const handleStep3 = async () => {
    const taskId = initResponse?.taskId;
    await analyseCsv({
      variables: {
        taskId,
      },
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "text/csv": [] },
    onDrop: (acceptedFiles) => {
      setCurrentStep("UPLOAD");
      setFile(acceptedFiles[0]);
    },
  });

  const removeFile = () => {
    setFile(null);
    setCurrentStep("UPLOAD");
  };

  const handleCloseDialog = () => {
    closeDialog();
    setCurrentStep("UPLOAD");
    setIsApproved(false);
    setFile(null);
    resetInit();
    resetAnalyse();
  };

  return (
    <div className="flex flex-col items-center w-full bg-white rounded-lg min-h-250 sm:p-6 sm:pb-7">
      <div className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col justify-between w-full text-md">
          <h2 className="font-bold text-[#0B0D18] text-2xl">
            Upload company list
          </h2>
        </div>

        {currentStep === "UPLOAD" && (
          <>
            <p className="text-black my-2">
              Upload up to 1000 companies at once, for efficient screening based
              on your{" "}
              <button
                onClick={() => {
                  openCriteriaDialog();
                }}
                className="text-blue-500"
              >
                investment criteria
              </button>
              . The list must include websites to the companies you are
              uploading, as a separate column.
            </p>

            <div className="flex items-center w-full">
              <TooltipWrapper
                place="right"
                content={
                  <div className="w-full text-white">
                    <ul
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "1em",
                      }}
                    >
                      <li>
                        <strong>Prepare Your List:</strong> Create a list of up
                        to 1,000 companies in a CSV file. Make sure to include a
                        column for the company websites.
                      </li>
                      <li>
                        <strong>Upload Your List: </strong>Click on the “Upload
                        Company List” button and select your CSV file.
                      </li>
                      <li>
                        <strong>Review and Confirm:</strong>
                        <ul
                          style={{
                            listStyleType: "none",
                            paddingLeft: "1em",
                          }}
                        >
                          <li>
                            Once you upload your list, a summary will appear
                            below the uploaded file.
                          </li>
                          <li>
                            The summary will include the number of companies you
                            are about to analyze and the estimated cost.
                          </li>
                          <li>
                            Cost Calculation: Each company costs{" "}
                            <strong>0.50 EUR</strong> to analyze.
                          </li>
                          <li>
                            Example: “You are about to analyze {" "}
                            <strong>900</strong>
                             companies at an estimated total cost of 
                            <strong>450 EUR</strong>"
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>Agreement:</strong> To proceed, you will need to
                        check a box confirming that you have seen the price and
                        agree to receive an invoice once the job is complete.
                      </li>
                    </ul>
                  </div>
                }
              >
                <p className="text-black mr-2">Detailed guide</p>
                <InfoIcon />
              </TooltipWrapper>
            </div>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex justify-center mt-3 mb-5 w-full cursor-pointer items-center border-[2px] h-48 border-dashed rounded-lg border-[#314B97] bg-[#EEF2FE]"
            >
              <input {...getInputProps()} />

              <div className="text-center text-black">
                <p className="text-inherit">Drag & Drop or</p>
                <div className="whitespace-nowrap">
                  <span className="text-[#18BEB3]"> Choose file</span>
                  <span> to upload</span>
                </div>
              </div>
            </div>
          </>
        )}
        {file && currentStep === "UPLOAD" ? (
          <div className="w-full">
            <div className="w-full flex p-3 items-center justify-between bg-gradient-to-r rounded-md from-[#3B897A] to-[#18BEB3]">
              {initLoading ? (
                <div>
                  <p className="text-white text-md">Uploading file...</p>
                  <p className="text-sm text-white">{file.name}</p>
                </div>
              ) : (
                <p className="text-sm text-white">{file.name}</p>
              )}
              {initLoading ? (
                <LoadingSpinner />
              ) : (
                <TrashIcon
                  onClick={removeFile}
                  className="w-4 h-4 text-white cursor-pointer"
                />
              )}
            </div>
          </div>
        ) : null}

        {(initError || analyseError) && (
          <>
            <span className="mt-4 text-red-500">
              Something went wrong. Please try again later
            </span>
            <PitchDeckError />
          </>
        )}

        {currentStep === "CONFIRM" &&
          initResponse &&
          (initResponse.websiteColumn ? (
            <div className="flex flex-col w-full">
              <div className="flex items-center w-full">
                <p className="text-black my-4 mr-2">
                  You are about to analyze {initResponse?.numOfRecords}{" "}
                  companies at an estimated cost of{" "}
                  <span> {initResponse?.estimatedCost} EUR</span>
                </p>
                <TooltipWrapper
                  place="right"
                  content={
                    <div className="text-white">
                      <p className="text-white">
                        “Upload company list” is a standalone product and is
                        billed separately from Sircular’s subscriptions. Once
                        the list has been analyzed, you will receive the updated
                        company list as well as an invoice based on the number
                        of companies successfully analyzed.
                      </p>
                    </div>
                  }
                >
                  <InfoIcon />
                </TooltipWrapper>
              </div>

              <div className="flex items-center font-medium mt-3">
                <Checkbox checked={isApproved} onChange={handleApprove} />
                <p className="-mt-2">
                  I have seen the price and agree to receiving an invoice once
                  the job is complete
                </p>
              </div>
            </div>
          ) : (
            <p className="text-orange-500 my-4 mr-2">
              We are unable to find the column with the website URLs. Please
              upload a new file with the correct column.
            </p>
          ))}

        {currentStep === "FINISH" && (
          <div className="flex flex-col w-full">
            <p className="text-black mt-4">
              Your company list was successfully uploaded.
            </p>
            <p className="text-black my-4">
              Hang tight, you will receive an email at{' '}
              <strong>{user.email}</strong> with your updated list within short.
            </p>
          </div>
        )}

        <div className="flex mt-6 w-full space-x-2">
          <Button
            onClick={handleCloseDialog}
            type="button"
            color="transparent"
            className="flex w-full justify-center  text-[#18BEB3]  p-2 rounded-lg   hover:opacity-90"
          >
            {currentStep === "FINISH" ? (
              <span className="font-bold ">Close</span>
            ) : (
              <span className="font-bold ">Cancel</span>
            )}
          </Button>

          {currentStep === "UPLOAD" && (
            <Button
              loading={initLoading}
              disabled={!file || initLoading}
              onClick={handleUpload}
              className=" flex w-full justify-center text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
            >
              <span className="font-bold">Next</span>
            </Button>
          )}

          {currentStep === "CONFIRM" && (
            <Button
              loading={analyseLoading}
              disabled={analyseLoading || !isApproved}
              onClick={handleStep3}
              className=" flex w-full justify-center  text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
            >
              <span className="font-bold">Start</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadStartupList;
