// @ts-nocheck
// import styled from 'styled-components'
import media from "styled-media-query";
import tw, { css, styled } from "twin.macro";

export const Team = styled.div`
  position: relative;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h2 {
    margin-bottom: 15px !important;
    font-size: 30px !important;
  }

  p {
    color: #6b7280;
  }
`;

export const HeaderAction = styled.div`
  font-size: 40px;
  color: #6b7280;
  color: #fff;
  display: flex;

  svg {
    cursor: pointer;
    color: #000;

    &:first-child {
      margin-right: 30px;
    }
  }
`;

export const Members = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${media.lessThan("1024px")`
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
    `}
  ${media.lessThan("480px")`
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;
    `}
`;

export const Member = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 30px;
`;

export const MemberImage = styled.div`
  img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
  }
  ${media.between("100px", "991px")`
  h5 {
    font-size: 14px;
  }
`}
`;

export const MemberContent = styled.div`
  margin-left: 15px;
  width: calc(100% - 85px);
  h5 {
    font-weight: 500;
    font-size: 20px;
    ${media.lessThan("medium")`
    font-size: 16px;
      `}
  }
  p {
    font-size: 14px;
    color: #6b7280;
    margin: 3px 0;
  }
`;
export const SocialLink = styled.a(({ type }) => [
  tw``,
  css`
    height: 22px;
    width: 22px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    ${type === "email" &&
    css`
      background: #18beb3;
    `}
    ${type === "linkedin" &&
    css`
      background: #0077b7;
    `}
    ${type === "twiiter" &&
    css`
      background: #00adff;
    `}
    ${type === "instagram" &&
    css`
      background: radial-gradient(
        circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    `} 
    svg {
      width: 11px !important;
      color: #fff;
    }
  `,
]);
