import { createAction } from "@reduxjs/toolkit";

export const getDocuments = createAction("companySettings/documents/fetch");

export const saveDocument = createAction(
  "companySettings/document/save",
  (payload) => ({
    payload,
  })
);

export const removeDocument = createAction(
  "companySettings/document/remove",
  (payload) => ({
    payload,
  })
);
