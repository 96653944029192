import { gql } from "@apollo/client";

export const SAVE_PROFILE_IMPACT = gql`
  mutation saveCompanyImpactStep($input: ImpactStepInput) {
    saveCompanyImpactStep(input: $input) {
      _id
      impact {
        isImpact
        impactNote
      }
      sustainableDevelopmentGoals {
        items
      }
    }
  }
`;

export const GET_PROFILE_IMPACT = gql`
  query getProfileImpact {
    me {
      impact {
        isImpact
        impactNote
      }
      sustainableDevelopmentGoals {
        items
      }
    }
  }
`;
