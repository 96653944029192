import { createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "models/auth/constants";
import type { PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  isLoading: true,
  isValidToken: false,
  status: API_STATUS.PENDING,
};

export const name = "resetPassword";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateValidToken: (
      state,
      { payload: { isValidToken } }: PayloadAction<{ isValidToken: boolean }>
    ) => ({
      ...state,
      isValidToken,
    }),
    updateResetPassword: (
      state,
      { payload: { status } }: PayloadAction<{ status: string }>
    ) => ({
      ...state,
      status,
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const { updateValidToken, updateResetPassword } = slice.actions;
