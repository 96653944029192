// @ts-nocheck
import styled, { css } from "styled-components";
import patternBg from "images/pattern.png";
import media from "styled-media-query";

export const Section = styled.div<{ padding?: any }>`
  ${({ padding }) =>
    padding !== "no-top" &&
    css`
      padding-top: 18px;
      ${media.greaterThan("medium")`
        padding-top: 10px;
      `}
    `}

  ${({ padding }) =>
    padding !== "no-bottom" &&
    css`
      padding-bottom: 0px;
      ${media.greaterThan("medium")`
        padding-bottom: 10px;
      `}
    `}

  ${({ pattern }) =>
    pattern === "left" &&
    css`
      background-image: url(${patternBg});
      background-repeat: no-repeat;
      background-position: left;
    `}

  ${({ pattern }) =>
    pattern === "right" &&
    css`
      background-image: url(${patternBg});
      background-repeat: no-repeat;
      background-position: right;
    `}
`;
