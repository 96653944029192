import { FC, useState } from "react";
import { NavLink } from "react-router-dom";
import { SideNavigationProps } from "./interfaces";
import { DIVIDER } from "./constants";
import { Tier } from "gql/graphql";
import cn from "classnames";
import SideNavLogo from "./SideNavLogo";
import SideNavMenu from "./SideNavMenu";
import { ReactComponent as Logo } from "images/logo-black.svg";

const SideNavigationDesktop: FC<SideNavigationProps> = ({
  config,
  user,
  userRole,
}) => {
  const [isMenuOpen, setisMenuOpen] = useState(false);

  const logo = user?.logo?.large?.location || user?.image?.large?.location;
  const isAngelInvestor = user?.investorType === "ANGEL_INVESTOR";
  const isVcOrFamilyOffice =
    user?.investorType === "VC" || user?.investorType === "FAMILY_OFFICE";

  const tier = user?.tier;

  const toggleSideNavMenu = () => {
    setisMenuOpen((prev) => !prev);
  };

  return (
    <div className="relative w-16 z-50">
      <nav
        className="group bg-neutral-200 absolute top-0 bottom-0 w-16 hover:w-64 hover:bg-white z-50 transition-all duration-500 flex flex-col border-r border-r-gray-300"
        onMouseLeave={() => setisMenuOpen(false)}
      >
        <NavLink
          to="/aia"
          className="p-4 flex border-b border-gray-300 mb-2 justify-center"
        >
          <Logo className="max-w-[6rem]" />
        </NavLink>

        {config[userRole].map((configItem, index) => {
          if (configItem === DIVIDER) {
            return <hr className="my-2 ml-4" key={index} />;
          }

          if (isAngelInvestor && configItem.id === "MANAGE_TEAM") return null;

          if (
            !(tier === Tier.Trial || tier === Tier.Pending) &&
            configItem.id === "UPGRADE"
          )
            return null;

          if (
            (tier === Tier.Trial || tier === Tier.Pending) &&
            configItem.id === "BILLING"
          )
            return null;

          // show dealflow for VCs only if they have uploaded atleast one pitch deck
          if (
            isVcOrFamilyOffice &&
            user?.company.aiaTrial?.totalUploads < 1 &&
            configItem.id === "DEALFLOW"
          )
            return null;

          const { title, to, Icon, placement } = configItem;

          // add end prop for the root route. Otherwise
          // isActive is true for it always
          const end = to === "/myspace" || to === "/dealflow";

          return (
            <NavLink
              to={to}
              end={end}
              key={index}
              className={cn(placement === "bottom" && "mt-auto")}
            >
              {({ isActive }) => (
                <div
                  className={cn(
                    "p-3 pl-5 my-1 flex items-center gap-x-4 hover:bg-neutral-200",
                    isActive
                      ? "opacity-100 text-aqua-400 group-hover:bg-neutral-100 transition-[background-color] duration-500"
                      : "opacity-50  text-gray-600"
                  )}
                >
                  <div className="w-6 h-6 shrink-0 p-0.5">
                    <Icon
                      // @ts-ignore
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                  <span className="inline-block w-0 overflow-hidden group-hover:w-52 transition-[width] duration-500 font-medium whitespace-nowrap">
                    {title}
                  </span>
                </div>
              )}
            </NavLink>
          );
        })}

        <SideNavLogo
          userRole={userRole}
          logo={logo}
          user={user}
          toggleSideNavMenu={toggleSideNavMenu}
        />

        <SideNavMenu
          userRole={userRole}
          tier={tier}
          isAngelInvestor={isAngelInvestor}
          isOpen={isMenuOpen}
        />
      </nav>
    </div>
  );
};

export default SideNavigationDesktop;
