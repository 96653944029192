import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import { format } from "date-fns";
import { CompanyUpdate } from "gql/graphql";
import { FC, useState } from "react";
import SectionHeader from "../SectionHeader/SectionHeader";
import useProfileNavigation from "../../aia/ProfileNavigation/useProfileNavigation";
import { PROFILE_SECTION_IDS } from "../../aia/ProfileNavigation/constants";

interface NewsProps {
  updates: CompanyUpdate[];
  news: string;
}

const SHOWN_UPDATES = 6;

const News: FC<NewsProps> = ({ news, updates }) => {
  const [showAll, setShowAll] = useState(false);
  const { profileSectionRef } = useProfileNavigation();

  const toggleShowMore = () => {
    setShowAll((prev) => !prev);
  };

  const handleErrorImage = (image: any) => {
    image.src = "";
  };

  const sortedUpdates = updates?.sort((a, b) => {
    const aScore = a?.postedOn || 0;
    const bScore = b?.postedOn || 0;

    return bScore - aScore;
  });

  const filteredUpdates = sortedUpdates?.filter((update) => update.body);

  const linkedInUpdatesLength = sortedUpdates?.length || 0;

  return (
    <div
      id={PROFILE_SECTION_IDS.News}
      ref={profileSectionRef}
      className="mx-auto my-6"
    >
      {(news || (sortedUpdates && sortedUpdates.length > 0)) && (
        <SectionHeader title="News" />
      )}
      {news && (
        <div>
          <h3 className="mr-2 text-lg text-gray-700 my-2">Investments</h3>
          <p className="text-base mt-2 !text-[#24272B]">{news}</p>
        </div>
      )}

      {sortedUpdates && sortedUpdates.length > 0 && (
        <div className="my-2">
          <h3 className="mr-2 text-lg text-gray-700 mb-2">LinkedIn</h3>
          <div className="grid sm:grid-cols-3 gap-4">
            {filteredUpdates.map((update, index) => (
              <div key={index} className="relative">
                {showAll || index < SHOWN_UPDATES ? (
                  <div
                    className="relative border border-gray-400 py-4 px-6 rounded-md shadow-xl flex flex-col justify-between h-full"
                    key={index}
                  >
                    <p className="italic text-xs">
                      {format(new Date(update.postedOn), "dd MMM yyyy")}
                    </p>
                    <p className="h-24 overflow-y-auto no-scrollbar invisible-scroll">
                      {update.body}
                    </p>
                    {update?.image && (
                      <div className="flex h-40 overflow-hidden items-center w-full mx-auto rounded-md my-2">
                        <img
                          src={update?.image}
                          onError={(e) => handleErrorImage(e.target)}
                          className="overflow-hidden object-cover items-center flex rounded-md"
                        />
                      </div>
                    )}
                    {update.articleLink && (
                      <div className="flex flex-row items-center justify-end">
                        <a href={update.articleLink} target="_blank">
                          <p>Read article</p>
                        </a>
                      </div>
                    )}
                    {update?.totalLikes && (
                      <div className="flex flex-row items-center">
                        <div className="h-5 w-5 rounded-full bg-blue-400 items-center">
                          <FontAwesomeIcon
                            icon={["fas", "thumbs-up"]}
                            className="text-blue-200 mb-1 ml-1"
                            size="xs"
                          />
                        </div>
                        <span className="ml-1 mt-1 italic text-xs text-gray-600">
                          {update?.totalLikes} likes
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-4">
            <Button onClick={toggleShowMore} color="transparent" size="small">
              <h1 className="text-base text-center">
                <span className="mr-2">Show More</span>
                <FontAwesomeIcon
                  icon={
                    !showAll && linkedInUpdatesLength > SHOWN_UPDATES
                      ? "chevron-down"
                      : "chevron-up"
                  }
                  className="text-aqua-400"
                />
              </h1>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default News;
