import { FC } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Button from "atoms/Button/Button";
import { GET_PLANS_BY_USER } from "queries/general/account/plan";
import { ReactComponent as CheckoutSuccessImage } from "images/checkout-success.svg";
import { SubscriptionUserType } from "pages/plans/interfaces";
import Loading from "atoms/Loading/Loading";
import { mixpanelCheckoutSuccess } from "core/mixpanel/Mixpanel";

const CheckoutSuccess: FC = () => {
  const navigate = useNavigate();
  const { data, loading: isLoading } = useQuery(GET_PLANS_BY_USER);

  if (isLoading) {
    return <Loading fullPage />;
  }

  const userType = data.plansByUserType[0].userType;

  if (userType === SubscriptionUserType.VC) {
    navigate("/aia?checkout_success=true");
  }

  const getCtaTitle = () => {
    switch (userType) {
      case SubscriptionUserType.Startup:
        return "Start finding Investors";
      case SubscriptionUserType.Accelerator:
        return "Start finding Startups";
      case SubscriptionUserType.Angel:
        return "Start finding Startups";
      case SubscriptionUserType.VC:
        return "Start finding Startups";
    }
  };

  mixpanelCheckoutSuccess();

  return (
    <div className="flex flex-col items-center justify-center w-full h-[calc(100vh-10rem)]">
      <div className="flex flex-col items-center justify-center px-3">
        <CheckoutSuccessImage />
        <h2 className="mt-12 text-xl font-medium text-center">
          Thanks for joining Sircular Professional
        </h2>
        <h4 className="mt-2 text-sm font-thin text-center">
          Your account has been successfully upgraded to professional account.
        </h4>
        <Button
          className="mt-5 text-white before:opacity-100 hover:opacity-80 bg-[#18BEB3]"
          onClick={() =>
            navigate("/", {
              replace: true,
            })
          }
        >
          {/* {getCtaTitle()} */}
          OK, let's go!
        </Button>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
