import { useEffect } from "react";
import { SidebarContent } from "ui/elements/PanelLayout.styles";
import { useDispatch, useSelector } from "react-redux";
import { animation } from "ui/animations";
import { getFundraising } from "./selectors";
import Loading from "atoms/Loading/Loading";
import FundraisingForm from "./FundraisingForm";
import * as sagaActions from "./sagaActions";

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sagaActions.getFundraising());
  }, []);

  const data = useSelector(getFundraising);

  return (
    <SidebarContent
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
    >
      {!data ? <Loading fullPage /> : <FundraisingForm />}
    </SidebarContent>
  );
};
