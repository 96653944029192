import { ReactElement } from "react";
import { DealflowRecord } from "../interfaces";
import DefaultImage from "images/dealroom-image.png";
import { getTimeSince } from "utils/generalUtils";
import {
  INDUSTRY_CATEGORIES_SWEDISH,
  INVESTOR_TYPES,
  MILIFY_CURRENCY_FORMAT,
} from "core/consts";
import Tooltip from "atoms/Tooltip/Tooltip";

const arrayColumn = <T extends any>(
  array: T[] | undefined | null,
  render: (item: T) => any
) => (
  <div className="flex justify-end md:justify-center">
    <div className="w-6">
      {(array?.length || 0) > 0 ? (
        <Tooltip
          place="left"
          content={
            array?.map((item, idx) => <div key={idx}>{render(item)}</div>) || ""
          }
        >
          <div className="w-6 h-6 leading-6 text-center bg-teal-100 rounded-full">
            {array ? array.length : "-"}
          </div>
        </Tooltip>
      ) : (
        "-"
      )}
    </div>
  </div>
);

export const CELL_RENDERERS: Partial<
  Record<string, (record: DealflowRecord) => ReactElement>
> = {
  displayName: ({ company: { slug, displayName, logo } }) => (
    <div className="relative flex items-center">
      <span className="hidden md:block absolute -top-10 -bottom-5 -right-1 w-[1px] bg-gray-300" />
      <a
        target="_blank"
        href={`/investor/public/${slug}?us=dealflow`}
        rel="noreferrer"
        className="flex items-center hover:underline"
        data-key="open-profile-dealflow"
      >
        <img
          src={logo?.small?.location || DefaultImage}
          width="16"
          height="16"
          className="rounded-full mr-2 min-w-[16px]"
          alt=""
        />
        <span>{displayName}</span>
      </a>
    </div>
  ),

  createdAt: ({ entryDate }) => (
    <span className="flex justify-end md:inline">
      {getTimeSince(entryDate)}
    </span>
  ),

  country: ({ company }) => <span>{company.country || "-"}</span>,

  isAdvisor: ({ company }) => (
    <span>{company.supportAsAdvisor ? "Yes" : "No"}</span>
  ),

  type: ({ company }) => (
    <span>{(INVESTOR_TYPES as any)[company.investorType] || "-"}</span>
  ),

  ticketSize: ({ company }) => (
    <span>
      {company.investmentRanges?.min
        ? `${
            company.currencyData ? company.currencyData.symbol : ""
          } ${MILIFY_CURRENCY_FORMAT(
            company.investmentRanges.min
          )} - ${MILIFY_CURRENCY_FORMAT(company.investmentRanges.max)}`
        : "-"}
    </span>
  ),

  stage: ({ company }) => (
    <span>{company.investmentStage?.join(", ") || "-"}</span>
  ),

  industries: ({ company }) =>
    arrayColumn(
      company.industries,
      (item) => (INDUSTRY_CATEGORIES_SWEDISH as any)[item]
    ),

  relation: ({ company }) => (
    <span>{company.businessModels?.join(", ") || "-"}</span>
  ),
};
