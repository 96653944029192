import { useEffect, useState } from "react";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import Logo from "./components/Logo/Logo";
import Name from "./components/Name/Name";
import Buttons from "./components/TopButtons/Buttons";
import PitchVideo from "./components/PitchVideo/PitchVideo";
import { useDispatch } from "react-redux";
import { initializeEditStartupProfilePage } from "./sagaActions";
import { useAppSelector } from "hooks/useAppSelector";
import PitchDeck from "./components/PitchDeck/PitchDeck";
import InvestmentSummary from "./components/InvestmentSummary/InvestmentSummary";
import MeetTheTeam from "./components/MeetTheTeam/MeetTheTeam";
import Traction from "./components/Traction/Traction";
import ElevatorPitch from "./components/ElevatorPitch/ElevatorPitch";
import TractionSummary from "./components/TractionSummary/TractionSummary";
import ProductDescription from "./components/ProductDescription/ProductDescription";
import ProductImagesAndVideo from "./components/ProductImagesAndVideo/ProductImagesAndVideo";
import MarketOpportunity from "./components/MarketOpportunity/MarketOpportunity";
import BusinessModel from "./components/BusinessModel/BusinessModel";
import Timing from "./components/Timing/Timing";
import SdgGoals from "./components/SdgGoals/SdgGoals";
import { getCurrencyList } from "models/user/selectors";

const EditCompanyProfile = () => {
  const [currencyCode, setCurrencyCode] = useState("SEK");

  const { company } = useAppSelector((state) => state.editStartupProfile);
  const currencyList = useAppSelector((state) => getCurrencyList(state));

  const dispatch = useDispatch();

  /**
   * Currency is read from fundraising data and used in all other places even where there is currency in the api data.
   * If there is no currecny 'SEK' will be SHOWN and also SAVED for each data point that has currency.
   */

  useEffect(() => {
    const defaultCurrency = currencyList?.find((c: any) => c.isDefault);
    const selectedCurrency = currencyList?.find(
      (c: any) => c._id === company?.fundraising?.currency
    );
    setCurrencyCode(selectedCurrency?.code || defaultCurrency?.code);
  }, [currencyList, company?.fundraising?.currency]);

  useEffect(() => {
    dispatch(initializeEditStartupProfilePage({}));
  }, []);

  /**
   * This page hadles 3 thing only:
   * 1. initial data loading
   * 2. delivering each component the data they need
   * 3. page layout
   * all functionality in terms of editing, saving etc is handled in the corresponding component
   */
  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="w-full rounded-md md:mb-0"
    >
      <div className="flex flex-col items-center mx-2">
        <div className="w-full flex flex-col items-center lg:justify-between lg:flex-row lg:mb-4 lg:gap-4">
          <div className="flex flex-col lg:flex-row items-center">
            <Logo logoPreview={company?.logo?.large?.location} />
            <Name name={company?.name} />
          </div>

          <div className="lg:hidden w-full">
            <Buttons company={company} />
          </div>

          <PitchVideo pitchVideoLink={company?.pitch?.link} />
        </div>

        <div className="hidden lg:block w-full">
          <Buttons company={company} />
        </div>

        <div className="flex flex-col lg:flex-row gap-6 my-4 w-full">
          <ElevatorPitch pitchText={company?.pitch?.text} />
          <PitchDeck pitchDeckItems={company?.documents} />
        </div>

        <TractionSummary tractionSummary={company?.traction?.summary} />

        <InvestmentSummary
          fundraising={company?.fundraising}
          investors={company?.investors}
          website={company?.website}
          socials={company?.socials}
          founders={company?.founders}
          location={company?.location}
          business={company?.business}
          currencyCode={currencyCode}
        />

        <MeetTheTeam team={company?.team} founders={company?.founders} />

        <Traction
          traction={company?.traction}
          phase={company?.fundraising?.phase}
          currencyCode={currencyCode}
        />

        <ProductImagesAndVideo product={company?.product} />

        <div className="grid grid-cols-1 w-full gap-4 lg:grid-cols-2 mt-4 mb-4">
          <ProductDescription description={company?.product?.description} />

          <MarketOpportunity opportunity={company?.business?.marketOpp} />

          <BusinessModel description={company?.business?.description} />

          <Timing timing={company?.business?.timing} />

          <SdgGoals sdg={company?.business?.sdg} />
        </div>
      </div>
    </Page>
  );
};

export default EditCompanyProfile;
