import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditButton from "../reusables/EditButton";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import ImagePlaceholder from "images/investor-placeholder-img.png";
import { Gender, Person, PersonInput } from "gql/graphql";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { useForm } from "react-hook-form";
import { SingleSelect, SingleSelectChoice } from "molecules/SingleSelect";
import { GENDERS } from "core/consts";
import { Role } from "./MeetTheTeam";
import { nanoid } from "@reduxjs/toolkit";

interface Props {
  member: Person | undefined | null;
  role?: Role;
  handleSave: (person: PersonInput, role: Role) => void;
  handleRemove: (id: string) => void;
}

const ROLES: { id: Role; title: string }[] = [
  { id: "FOUNDER", title: "Founder" },
  {
    id: "MEMBER",
    title: "Member",
  },
];

const GENDERS_CHOICES = Object.entries(GENDERS).map((i) => ({
  id: i[0],
  title: i[1],
}));

const MemberCard: FC<Props> = ({ member, role, handleSave, handleRemove }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState<SingleSelectChoice | null>(
    ROLES.find((r) => r.id === role) || null
  );
  const [roleErroe, setRoleError] = useState(false);
  // Sinse gender field is a dropdown, it is easier to handle it seperately
  const [gender, setGender] = useState<SingleSelectChoice | null>(null);
  const [selectedImage, setSelectedImage] = useState<File>();

  useEffect(() => {
    if (member?.gender) {
      const _gender = GENDERS_CHOICES.find((g) => g.id === member.gender);
      if (_gender) setGender(_gender);
    }
  }, [member?.gender]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Person>({
    defaultValues: {
      id: member?.id,
      title: member?.title,
      name: member?.name,
      gender: member?.gender,
      email: member?.email,
      linkedin: member?.linkedin,
      previouslyFounded: member?.previouslyFounded,
      successfulExits: member?.successfulExits,
    },
    shouldUnregister: true,
  });

  const onSubmit = (data: Person) => {
    // Data object needs some refinement
    const refinedData = {
      ...data,
      id: data.id || nanoid(), // Allocating id to a newly created Person
      gender: gender?.id as Gender,
      previouslyFounded: data.previouslyFounded
        ? Number(data.previouslyFounded)
        : null,
      successfulExits: data.successfulExits
        ? Number(data.successfulExits)
        : null,
      image: selectedImage,
    };

    if (selectedRole) {
      handleSave(refinedData, selectedRole.id as Role);
      setIsEditMode(false);
      setSelectedRole(null);
      setGender(null);
    } else {
      setRoleError(true);
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleRemoveMember = () => {
    if (member?.id) handleRemove(member.id);
  };

  const onPictureSelectComplete = (file: File) => {
    setSelectedImage(file);
  };

  const getEditModeContent = () => {
    const inputClassName =
      "w-full border p-2 bg-aqua-50 border-aqua-200 rounded-lg placeholder:italic placeholder:text-sm";

    const labelClassName = "text-sm text-gray-600";

    return (
      <div className="mt-2 flex flex-col border border-dashed rounded-lg border-aqua-400 p-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <ProfilePictureSelect
              label="Upload image"
              onComplete={onPictureSelectComplete}
              preview={member?.image?.small?.location || undefined}
            />
          </div>

          {/* The purpose of this input is only to smoothly include id in the data */}
          <input
            type="text"
            id="meatTheTeam/id"
            className="hidden"
            {...register("id")}
          />

          <div className="mt-2 flex">
            <div className="basis-1/2">
              <label className={labelClassName}>Title</label>
              <SingleSelect
                choices={ROLES}
                value={selectedRole}
                onChange={(e: SingleSelectChoice) => {
                  setSelectedRole(e);
                  setRoleError(false);
                }}
                wrapperClassName="grow"
                labelClassName="w-full bg-aqua-50 !border-aqua-200 !rounded-lg !p-2"
              />
              {roleErroe && (
                <div className="text-sm text-fire-400">
                  Title must be selected
                </div>
              )}
            </div>

            <div className="basis-1/2 !pr-0 -mr-1">
              <label className={labelClassName}>Gender</label>
              <SingleSelect
                choices={GENDERS_CHOICES}
                value={gender}
                onChange={(e: SingleSelectChoice) => setGender(e)}
                wrapperClassName="grow"
                labelClassName="w-full bg-aqua-50 !border-aqua-200 !rounded-lg !p-2 -mr-6"
              />
            </div>
          </div>

          <div className="mt-2">
            <label htmlFor="meatTheTeam/name" className={labelClassName}>
              Full Name
            </label>
            <input
              type="text"
              id="meatTheTeam/name"
              placeholder="Full name of team member"
              className={inputClassName}
              {...register("name")}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="meatTheTeam/email" className={labelClassName}>
              Email
            </label>
            <input
              type="text"
              id="meatTheTeam/email"
              placeholder="Enter member's email address"
              className={inputClassName}
              {...register("email")}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="meatTheTeam/title" className={labelClassName}>
              Title/Role
            </label>
            <input
              type="text"
              id="meatTheTeam/title"
              placeholder="Enter member's role"
              className={inputClassName}
              {...register("title")}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="meatTheTeam/linkedin" className={labelClassName}>
              LinkedIn
            </label>
            <input
              type="text"
              id="meatTheTeam/linkedin"
              placeholder="Enter member's LinkedIn profile"
              className={inputClassName}
              {...register("linkedin")}
            />
          </div>

          {selectedRole?.id === "FOUNDER" && (
            <div className="mt-2 flex">
              <div className="basis-1/2 mr-1">
                <label
                  htmlFor="meatTheTeam/prevFounded"
                  className={labelClassName}
                >
                  No. of Previously Founded
                </label>
                <input
                  type="number"
                  id="meatTheTeam/prevFounded"
                  className={inputClassName}
                  {...register("previouslyFounded")}
                />
              </div>

              <div className="basis-1/2 ml-1">
                <label
                  htmlFor="meatTheTeam/successfulExits"
                  className={labelClassName}
                >
                  No. of Successful Exits
                </label>
                <input
                  type="number"
                  id="meatTheTeam/successfulExits"
                  className={inputClassName}
                  {...register("successfulExits")}
                />
              </div>
            </div>
          )}

          <SaveAndCancelButtons
            onSave={() => undefined} // onSubmit will be executed on save click
            onCancel={handleCancel}
            className="mt-4 mb-2 float-right"
          />
        </form>
      </div>
    );
  };

  const getContent = () => {
    return (
      <div className="w-full p-4 mt-2 border border-dashed rounded-lg border-aqua-400 bg-white flex relative items-center">
        <img
          src={member?.image?.small?.location || ImagePlaceholder}
          className="object-cover w-20 h-20 rounded-full mr-2"
        />

        <div className="grow">
          <div>{member?.name}</div>
          <div>{member?.title}</div>
          {member?.linkedin && (
            <a
              target="_blank"
              href={member?.linkedin}
              rel="noreferrer"
              className=""
            >
              <LinkedinIcon />
            </a>
          )}
        </div>
        <EditButton
          size="small"
          onClick={() => setIsEditMode(true)}
          className="self-start"
        />
        <FontAwesomeIcon
          icon={["fal", "times"]}
          className="mr-3 cursor-pointer absolute -top-2 -right-4 text-aqua-400 border border-aqua-400 p-px !w-6 !h-6 rounded-full bg-aqua-100"
          onClick={handleRemoveMember}
        />
      </div>
    );
  };

  const getNoContentplaceholder = () => (
    <div className="w-full p-4 mt-2 border border-dashed rounded-lg border-aqua-400 bg-white">
      <div className="flex items-center h-full">
        <div className="border border-dashed rounded-full border-aqua-400 w-20 h-20 bg-aqua-50 ml-2 mr-6"></div>
        <div className="cursor-pointer" onClick={() => setIsEditMode(true)}>
          <FontAwesomeIcon
            icon={["fal", "plus-circle"]}
            className="mr-2 text-aqua-400"
          />
          <span className="text-aqua-400">Add team member</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full z-10 mt-4 lg:mt-0">
      {isEditMode ? (
        getEditModeContent()
      ) : (
        <>{member ? getContent() : getNoContentplaceholder()}</>
      )}
    </div>
  );
};

export default MemberCard;
