import { FC } from "react";
import DOMPurify from "dompurify";

interface PureHtmlProps extends React.HTMLAttributes<any> {
  tag?: keyof JSX.IntrinsicElements;
  content: string;
}

const purify = (content: string) =>
  DOMPurify.sanitize(content, {
    ALLOWED_ATTRS: ["class", "target", "rel"],
  });

const PureHtml: FC<PureHtmlProps> = ({ tag = "div", content, ...rest }) => {
  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag dangerouslySetInnerHTML={{ __html: purify(content) }} {...rest} />
  );
};

export default PureHtml;
