import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { INVITE_MEMBER } from "queries/general/account/teamMembers";
import { ChangeEvent, Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import RoleSelector from "./RoleSelector";
import { Role, Roles } from "./types";
import { mixpanelInviteMember } from "core/mixpanel/Mixpanel";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const InvitationModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const [email, setEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(Roles.Admin);

  const [inviteMember, { loading }] = useMutation(INVITE_MEMBER);

  const dispatch = useDispatch();

  const closeModal = () => {
    setIsOpen(false);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onRoleChange = (role: Role) => {
    setSelectedRole(role);
  };

  const onInviteClicked = () => {
    const input = {
      email: email,
      // Hotfix: The MULTIPLAYER permission should be added from BE side later to the workspaces that are on pro plan
      permissions: [...selectedRole.permissions, "MULTIPLAYER"],
    };

    mixpanelInviteMember(email, selectedRole.name);

    inviteMember({ variables: { input } })
      .then((data) => {
        console.log("Invitaion slug: ", data?.data?.inviteMember?.slug);
        dispatch(
          triggerSnack({
            type: "success",
            title: "Invitation sent successfully",
            message: "Invitation sent successfully",
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          triggerSnack({
            type: "error",
            title: "Something went wrong",
            message: "We could not send the invitation",
          })
        );
      })
      .finally(() => closeModal());
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="p-4 text-lg font-medium leading-6 text-gray-900"
                >
                  <div className="flex flex-row items-center justify-between">
                    <span>Invite team members</span>
                    <FontAwesomeIcon
                      icon={["fal", "times"]}
                      onClick={closeModal}
                      className="scale-150 hover:cursor-pointer"
                    />
                  </div>
                </Dialog.Title>
                <div className="p-4 border-t-2 border-t-gray-200">
                  <label htmlFor="invite-email" className="mb-2 text-sm">
                    Invite by Email
                  </label>
                  <div className="flex flex-row justify-between mt-2">
                    <div className="flex flex-row justify-between flex-grow mr-2 bg-gray-100">
                      <input
                        id="invite-email"
                        type="email"
                        placeholder="Enter user's email"
                        className="w-full h-full px-2 bg-gray-100 placeholder:italic placeholder:text-gray-100 placeholder:text-sm"
                        value={email}
                        onChange={onEmailChange}
                      />
                      <RoleSelector
                        selectedRole={selectedRole}
                        styleForInvitationModal
                        onChange={onRoleChange}
                      />
                    </div>
                    <Button
                      className="mt-1 w-36"
                      color="primary"
                      size="small"
                      onClick={onInviteClicked}
                    >
                      <span className="inline-block sm:hidden">
                        <FontAwesomeIcon
                          icon={["fas", "user-plus"]}
                          className="mr-2"
                        />
                        Invite
                      </span>
                      <span className="hidden sm:inline-block">
                        <FontAwesomeIcon
                          icon={["fas", "user-plus"]}
                          className="mr-2"
                        />
                        Send Invite
                      </span>
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InvitationModal;
