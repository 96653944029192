import cn from "classnames";

const SideNavItem: React.FC<{
  title: string;
  isActive?: boolean;
  isChild?: boolean;
  Icon?: React.FC;
  className?: string;
  to?: string;
  elementId?: string;
}> = ({
  title,
  isActive,
  // For later implementations
  isChild,
  Icon,
  className = "",
  children,
  to,
  elementId,
}) => (
  <div className="relative" id={elementId}>
    <div className={cn("flex flex-row h-12 mb-1", className)}>
      <div className={cn("w-1 mr-3 rounded-full", isActive && "bg-aqua-400")} />

      <div
        className={cn(
          "w-full rounded-lg flex items-center pl-5 text-[#0B0D18] opacity-50 hover:bg-[#F5F5F5]",
          isActive && "bg-[#F5F5F5] font-medium text-aqua-400 !opacity-100"
        )}
      >
        {children || (Icon && <Icon />)}
        <p className="ml-2 text-inherit lg:ml-6">{title}</p>
      </div>
    </div>
  </div>
);

export default SideNavItem;
