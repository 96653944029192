import { combineReducers } from "@reduxjs/toolkit";

import snackReducer from "organisms/Snack/reducer";
import offerModalReducer from "organisms/OfferModal/reducer";
import NavigationLayoutReducer from "organisms/NavigationLayout/reducer";
import shareProfleModalReducer from "organisms/ShareProfileModal/reducer";
import contactRequestModalReducer from "organisms/ContactRequestModal/reducer";

import authReducer from "models/auth/reducer";
import userReducer from "models/user/reducer";
import loadersReducer from "models/loaders/reducer";
import lazyLoadReducer from "models/lazyload/reducer";
import globalStateReducer from "models/globalState/reducer";

import registerReducer from "pages/auth/RegisterPage/reducer";
import resetPassworReducer from "pages/auth/ResetPassword/reducer";
import forgotPasswordReducer from "pages/auth/ForgotPassword/reducer";
import startupsBoardReducer from "pages/startupsBoard/reducer";
import companyProfileReducer from "pages/companyProfile/reducer";
import invitationReducer from "pages/invitation/reducer";
import messagesReducer from "pages/messages/reducer";
import companySettingsReducer from "pages/companySettings/reducer";
import editStartupProfileReducer from "pages/editStartupProfile/reducer";

const rootReducer = combineReducers({
  [snackReducer.name]: snackReducer.reducer,
  [offerModalReducer.name]: offerModalReducer.reducer,
  [NavigationLayoutReducer.name]: NavigationLayoutReducer.reducer,
  [shareProfleModalReducer.name]: shareProfleModalReducer.reducer,
  [contactRequestModalReducer.name]: contactRequestModalReducer.reducer,

  [authReducer.name]: authReducer.reducer,
  [userReducer.name]: userReducer.reducer,
  [loadersReducer.name]: loadersReducer.reducer,
  [lazyLoadReducer.name]: lazyLoadReducer.reducer,
  [globalStateReducer.name]: globalStateReducer.reducer,

  [registerReducer.name]: registerReducer.reducer,
  [resetPassworReducer.name]: resetPassworReducer.reducer,
  [forgotPasswordReducer.name]: forgotPasswordReducer.reducer,
  [startupsBoardReducer.name]: startupsBoardReducer.reducer,
  [companyProfileReducer.name]: companyProfileReducer.reducer,
  [invitationReducer.name]: invitationReducer.reducer,
  [messagesReducer.name]: messagesReducer.reducer,
  [companySettingsReducer.name]: companySettingsReducer.reducer,
  [editStartupProfileReducer.name]: editStartupProfileReducer.reducer,
});

export default rootReducer;
