// @ts-nocheck
import React, { useState, forwardRef } from "react";

import useClickOutside from "hooks/useOnClickOutside";
import { useEffect } from "react";
import ClickOutside from "atoms/ClickOutside";
import { ReactComponent as ArrowDownIcon } from "images/angle-down-icon.svg";
import "./Form.css";

// @TODO: use real typings instead of any
//
// @TODO: rewrite during UI kit refactoring
//
export default forwardRef(
  (
    {
      showLabel,
      label,
      options = [],
      defaultValue,
      onChange,
      disabled,
      direction,
      selectDropdownInput,
      isMobile,
      ...props
    }: any,
    forwardedRef
  ) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(defaultValue);

    useEffect(() => {
      setSelected(defaultValue);
    }, [defaultValue]);

    const ref = React.createRef();
    useClickOutside(ref, () => {
      setOpen(false);
    });

    const isSelected = (option) => selected === option;

    const getClassName = () => {
      let className = "";
      if (selectDropdownInput) className = className + "select-input-field";
      if (isMobile) return className;
      else return className + "space-y-1";
    };

    return (
      <ClickOutside onClick={() => setOpen(false)}>
        <div className={getClassName()}>
          {showLabel && (
            <label
              id="listbox-label"
              className="block text-sm font-bold leading-5 text-gray-700 truncate"
            >
              {label}
            </label>
          )}
          <input
            type="hidden"
            ref={forwardedRef}
            {...props}
            value={selected || ""}
            onChange={onChange}
          />
          <div className="relative">
            <span
              className={`inline-block w-full rounded-md ${
                disabled //border-none
                  ? ""
                  : ""
              }`}
            >
              <button
                onClick={() => {
                  if (!disabled) {
                    setOpen(!open);
                  }
                }}
                type="button"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                className={`cursor-default relative w-full rounded-xl border border-gray-300 bg-white pl-3 pr-5 py-2 text-left transition ease-in-out duration-150 sm:text-sm sm:leading-5 ${
                  disabled //border-none focus:shadow-none focus:border-none focus:outline-none
                    ? ""
                    : "shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300"
                }`}
                {...(open && { "aria-expanded": true })}
              >
                <span
                  className="block mr-4 truncate"
                  style={{
                    textOverflow: selectDropdownInput && "initial",
                    fontSize: selectDropdownInput && "12px",
                  }}
                >
                  {selected || label}
                </span>
                {!disabled && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    {selectDropdownInput ? (
                      <ArrowDownIcon />
                    ) : (
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="angle-down"
                        className="svg-inline--fa fa-angle-down fa-w-10 text-aqua-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="currentColor"
                          d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                        ></path>
                      </svg>
                    )}
                  </span>
                )}
              </button>
            </span>

            <div
              className={`absolute mt-1 w-full rounded-md bg-white shadow-lg z-20 __custom ${
                open ? "" : "hidden"
              } ${direction === "right" ? "right-0" : ""} ${
                direction === "top" ? "bottom-10" : ""
              }`}
            >
              <ul
                tabIndex="-1"
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-item-3"
                className="py-1 overflow-auto text-base leading-6 rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:leading-5"
              >
                {options.map((option) => (
                  <li
                    onClick={() => {
                      setSelected(option);
                      setOpen(false);

                      if (typeof onChange === "function") {
                        onChange(option);
                      }
                    }}
                    id="listbox-option-0"
                    role="option"
                    key={option}
                    className="relative py-2 pl-3 text-gray-900 cursor-pointer select-none pr-9 hover:bg-gray-100"
                  >
                    <span
                      style={{ fontSize: selectDropdownInput && "13px" }}
                      className="block font-normal truncate"
                    >
                      {option}
                    </span>

                    {isSelected(option) && (
                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-aqua-400">
                        <svg
                          className="w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </ClickOutside>
    );
  }
);
