import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import EditButton from "../reusables/EditButton";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import type { Images, Product } from "gql/graphql";
import ImagePreview from "./ImagePreview";
import EmbedVideo from "atoms/EmbedVideo";
import ImageSelect from "organisms/ImageSelect/ImageSelect";

interface Props {
  product: Product | undefined | null;
}

const ProductImagesAndVideo: FC<Props> = ({ product }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [existingImages, setExistingImages] = useState<Array<Images>>([]);
  const [newImages, setNewImages] = useState<Array<Blob>>([]);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (product?.images) {
      setExistingImages(product.images);
    }
  }, [product?.images]);

  const handleSave = () => {
    dispatch(
      updateStartupProfile({
        product: {
          demo: inputRef.current?.value,
          images: existingImages
            .map((ex) => ex.id)
            .concat(newImages.map((i) => "upload")),
          imagesToUpload: newImages,
        },
      })
    );
    setIsEditMode(false);
    // set images back to initial images
    setExistingImages(product?.images || []);
    setNewImages([]);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    // set images back to initial images
    setExistingImages(product?.images || []);
    setNewImages([]);
  };

  const handleRemoveExistingImage = (index: number) => {
    setExistingImages((prev) => prev.filter((i, idx) => idx !== index));
  };

  const handleRemoveNewImage = (index: number) => {
    setNewImages((prev) => prev.filter((i, idx) => idx !== index));
  };

  const handleAddImage = (image: Blob) => {
    setNewImages((prev) => prev.concat(image));
  };

  const getEditModeContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex flex-row my-2">
          {existingImages.map((image, index) => (
            <ImagePreview
              key={index}
              image={image}
              index={index}
              onRemove={() => handleRemoveExistingImage(index)}
            />
          ))}
          {newImages.map((image, index) => (
            <ImagePreview
              key={index}
              image={image}
              index={index}
              onRemove={() => handleRemoveNewImage(index)}
            />
          ))}
        </div>
        <ImageSelect
          minWidth={50}
          minHeight={50}
          onChange={(image: Blob) => {
            handleAddImage(image);
          }}
          small
          onRemove={() => {}}
          onError={(message: string) => {}}
          imageDesc="Drop product images here or"
        />
        <input
          type="text"
          placeholder="Paste a YouTube or Vimeo link here"
          className="p-2 mt-2 mb-4 text-gray-500 border rounded border-aqua-200 bg-aqua-50 placeholder:!text-gray-400 placeholder:text-sm placeholder:italic"
          defaultValue={product?.demo || ""}
          ref={inputRef}
        />
        <SaveAndCancelButtons
          onSave={handleSave}
          onCancel={handleCancel}
          className="self-end"
        />
      </div>
    );
  };

  const getContent = () => {
    return (
      <div>
        <div className="flex flex-row my-2">
          {product?.images?.map((image, index) => {
            return (
              <img
                key={index}
                src={image.large?.location || undefined}
                alt={`Product Image ${index}`}
                className="h-24 mr-2 rounded-lg hover:opacity-50 aspect-square"
              />
            );
          })}
        </div>
        {product?.demo && (
          <div className="relative inline-block w-full mt-2 rounded-md">
            <EmbedVideo className="rounded-md" source={product.demo} />
          </div>
        )}
      </div>
    );
  };

  const getNoContentplaceholder = () => (
    <div className="flex flex-col items-center justify-center h-full rounded bg-aqua-50 p-6 mt-2">
      <div className="mb-2">
        <FontAwesomeIcon
          icon={["fab", "youtube"]}
          className="text-aqua-300"
          size="3x"
        />
        <FontAwesomeIcon
          icon={["fal", "images"]}
          className="mb-6 text-aqua-300"
          size="3x"
        />
      </div>
      <div className="cursor-pointer" onClick={() => setIsEditMode(true)}>
        <FontAwesomeIcon
          icon={["fal", "plus-circle"]}
          className="mr-2 text-aqua-400"
        />
        <span className="text-aqua-400 text-sm">
          Upload your product images & videos
        </span>
      </div>
    </div>
  );

  return (
    <div className="w-full z-10 mt-4">
      <div className="flex justify-between">
        <span className="font-semibold">Add Product Images and Video</span>
        {!isEditMode && product && (
          <EditButton onClick={() => setIsEditMode(true)} />
        )}
      </div>

      {isEditMode ? (
        getEditModeContent()
      ) : (
        <>{product ? getContent() : getNoContentplaceholder()}</>
      )}
    </div>
  );
};

export default ProductImagesAndVideo;
