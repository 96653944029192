import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  timing: string | undefined | null;
}

const Timing: FC<Props> = ({ timing }) => {
  return (
    <EditablePlaceholderText
      fieldName="business.timing"
      title="Timing"
      addValueTitle="Add timing"
      value={timing}
    />
  );
};

export default Timing;
