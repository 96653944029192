import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import SaveAndCancelButtons from "./SaveAndCancelButtons";
import { updateStartupProfile } from "../../sagaActions";
import EditButton from "./EditButton";
import { generateInputObject } from "pages/editStartupProfile/helpers";
import WYSIWYGEditor from "pages/applications/WYSIWYGEditor";
import PureHtml from "atoms/PureHtml";

interface Props {
  title: string;
  value?: string | null;
  fieldName: string;
  addValueTitle: string;
  inputPlaceholder?: string;
  placeholderDescription?: string;
}

/**
 *  This component is similar to EditablePlaceholderInput, but instead of input field it has a rich text editor.
 *  Besides, the "No content / Add" mode has a differnt placeholder style.
 */
const EditablePlaceholderText: FC<Props> = ({
  title,
  value,
  fieldName,
  addValueTitle,
  inputPlaceholder,
  placeholderDescription,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const handleSave = () => {
    if (fieldName) {
      dispatch(
        updateStartupProfile(generateInputObject(inputValue, fieldName))
      );
    }
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleWysiwygChange = (e: any) => {
    setInputValue(e.replace(/\n/g, ""));
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const getEditModeContent = () => (
    <div className="flex flex-col">
      <div className="pr-2 mt-2">
        <WYSIWYGEditor
          value={value}
          onChange={handleWysiwygChange}
          placeholder={inputPlaceholder}
        />
      </div>
      <SaveAndCancelButtons
        onSave={handleSave}
        onCancel={handleCancel}
        className="self-end"
      />
    </div>
  );

  const getContent = () => {
    if (value)
      return (
        <div className="mt-2 rich-text-content-wrapper">
          <PureHtml content={value} />
        </div>
      );
    return null;
  };

  const getNoContentplaceholder = () => (
    <div className="w-full h-56 p-4 mt-2 border border-dashed rounded-lg border-aqua-400">
      <div className="flex flex-col items-center justify-between h-full">
        <div className="w-full">
          <div className="self-start w-1/2 h-4 mb-2 rounded-lg bg-aqua-50"></div>
          <div className="w-full h-4 mb-2 rounded-lg bg-aqua-50"></div>
          <div className="w-full h-4 mb-2 rounded-lg bg-aqua-50"></div>
        </div>
        <div className="cursor-pointer" onClick={() => setIsEditMode(true)}>
          <FontAwesomeIcon
            icon={["fal", "plus-circle"]}
            className="mr-2 text-aqua-400"
          />
          <span className="text-aqua-400">{addValueTitle}</span>
        </div>
        <p className="mx-6 text-sm text-center">{placeholderDescription}</p>
      </div>
    </div>
  );

  return (
    <div className="z-10 w-full">
      <div className="flex justify-between">
        <span className="font-semibold">{title}</span>
        {!isEditMode && value && (
          <EditButton onClick={() => setIsEditMode(true)} />
        )}
      </div>

      {isEditMode ? (
        getEditModeContent()
      ) : (
        <>{value ? getContent() : getNoContentplaceholder()}</>
      )}
    </div>
  );
};

export default EditablePlaceholderText;
