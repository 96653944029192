import Tooltip from "atoms/Tooltip/Tooltip";
import { format } from "date-fns";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import { formatCurrencyValues } from "pages/companyProfile/utils";
import { FC } from "react";
import cn from "classnames";
import { FundraisingOverviewProps } from "../shared/About/interfaces";
import Skeleton from "react-loading-skeleton";

const FundraisingOverview_Aia: FC<FundraisingOverviewProps> = ({
  finance,
  valuation,
  currencyCode,
  raisedTarget,
  minTicketSize,
  raisedValue,
  fit,
  fundraisingCloseOn,
  committed,
  isProfileGeneratedByAIA,
  isLoading,
}) => {
  if (isLoading) {
    <div className="hidden p-4 mx-auto md:block border">
      <Skeleton count={2.5} />
    </div>;
  }

  return (
    <div className="w-full mx-auto px-6 sm:px-0">
      {/* Todo create desktop view */}
      {(raisedTarget ||
        valuation ||
        raisedValue ||
        fit ||
        committed ||
        fundraisingCloseOn) && (
        <div className="inline-block w-full rounded-md dark:text-white/80 my-4">
          <div className="relative flex flex-col justify-start w-full lg:flex-row">
            <div
              className={cn(
                isProfileGeneratedByAIA ? "justify-between" : "px-0 sm:px-6",
                "grid grid-cols-2 lg:flex float-left lg:pr-4 gap-2 mb-2 lg:mb-0 w-full"
              )}
            >
              {raisedTarget && (
                <div
                  className={cn(
                    isProfileGeneratedByAIA ? "flex-1" : "mr-12",
                    "text-left bg-white rounded-md dark:bg-neutral-800"
                  )}
                >
                  <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                    Target
                    {finance?.note && (
                      <Tooltip content={finance?.note}>
                        <HelpIcon className="h-auto ml-1" />
                      </Tooltip>
                    )}
                  </p>
                  <span className="text-base sm:text-xl font-bold">
                    {formatCurrencyValues(parseInt(raisedTarget), currencyCode)}
                  </span>
                </div>
              )}

              {minTicketSize && (
                <div
                  className={cn(
                    isProfileGeneratedByAIA ? "flex-1" : " mr-12",
                    "text-left bg-white rounded-md dark:bg-neutral-800"
                  )}
                >
                  <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                    Ticket size
                  </p>
                  <span className="text-base sm:text-xl font-bold">
                    {formatCurrencyValues(
                      parseInt(minTicketSize),
                      currencyCode
                    )}
                  </span>
                </div>
              )}

              {valuation && (
                <div
                  className={cn(
                    isProfileGeneratedByAIA ? "flex-1" : " mr-12",
                    "text-left bg-white rounded-md dark:bg-neutral-800"
                  )}
                >
                  <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                    Valutation
                  </p>
                  <span className="text-base sm:text-xl font-bold">
                    {formatCurrencyValues(parseInt(valuation), currencyCode)}
                  </span>
                </div>
              )}

              {committed && (
                <div
                  className={cn(
                    isProfileGeneratedByAIA ? "flex-1" : " mr-12",
                    "text-left bg-white rounded-md dark:bg-neutral-800"
                  )}
                >
                  <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                    Committed
                  </p>
                  <span className="text-base sm:text-xl font-bold">
                    {formatCurrencyValues(parseInt(committed), currencyCode)}
                  </span>
                </div>
              )}

              {fundraisingCloseOn && (
                <div className="flex-1 text-left bg-white rounded-md dark:bg-neutral-800">
                  <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                    Round closing date
                  </p>
                  <span className="text-base sm:text-xl font-bold">
                    {format(new Date(fundraisingCloseOn), "dd MMM yyyy")}
                  </span>
                </div>
              )}
            </div>

            {fit && (
              <div className="float-left w-full lg:w-[370px] mx-auto lg:mx-0 md:text-center lg:text-left p-2 bg-white dark:bg-neutral-800 rounded-md">
                <p className="flex text-xs items-center mt-4 text-center font-normal md:text-sm whitespace-nowrap dark:text-gray-400">
                  Desired investors
                </p>
                <p className="flex text-sm font-bold text-[#000] dark:text-white/80">
                  {fit}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FundraisingOverview_Aia;
