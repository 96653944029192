import type { ReactElement } from "react";
import type {
  Currency,
  DealflowRecord,
} from "pages/dealflow/InvestorDealflow/interfaces";
import { ReactComponent as FileIcon } from "images/document-file-icon.svg";
import { ReactComponent as XlIcon } from "images/xl-icon.svg";
import DefaultImage from "images/dealroom-image.png";
import { getTimeSince } from "utils/generalUtils";
import {
  INDUSTRY_CATEGORIES_SWEDISH,
  MILIFY_CURRENCY_FORMAT,
} from "core/consts";
import Tooltip from "atoms/Tooltip/Tooltip";
import SDG from "molecules/Sdg/Sdg";
import { profileUrl } from "pages/dealflow/InvestorDealflow/utils";
import { prettyNum, roundToThousand } from "utils/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getMoneyTitle = (
  value: number | undefined | null,
  currency?: Currency | null
) => {
  if (!currency || !value) {
    return undefined;
  }

  const firstLine = prettyNum(value || 0) + " " + currency.code;

  if (currency.code === "EUR") {
    return firstLine;
  }

  const secondLine =
    "~" + prettyNum(roundToThousand(value * currency.toEurRatio || 0)) + " EUR";

  return " " + firstLine + "\n" + secondLine;
};

const numberColumn = (
  value: number | undefined | null,
  currency?: Currency | null
) => (
  <span
    title={getMoneyTitle(value, currency)}
    className="flex justify-end md:justify-center"
  >
    {value
      ? (currency ? currency.symbol : "") + MILIFY_CURRENCY_FORMAT(value)
      : "-"}
  </span>
);

const arrayColumn = <T extends any>(
  array: T[] | undefined | null,
  render: (item: T) => any
) => (
  <div className="flex justify-end md:justify-center">
    <div className="w-6">
      {(array?.length || 0) > 0 ? (
        <Tooltip
          place="left"
          content={
            array?.map((item, idx) => <div key={idx}>{render(item)}</div>) || ""
          }
        >
          <div className="w-6 h-6 text-center bg-teal-100 rounded-full leading-6">
            {array ? array.length : "-"}
          </div>
        </Tooltip>
      ) : (
        "-"
      )}
    </div>
  </div>
);

export const CELL_RENDERERS: Partial<
  Record<string, (record: DealflowRecord) => ReactElement>
> = {
  displayName: ({
    company: { slug, displayName, logo, website, id, isAiaGenerated },
  }) => (
    <div className="relative flex items-center">
      <span className="hidden md:block absolute -top-10 -bottom-5 -right-1 w-[1px] bg-gray-300" />
      <span
        onClick={(e) => {
          e.stopPropagation();
          profileUrl(slug, id, isAiaGenerated);
        }}
        className="flex items-center hover:underline"
        data-key="open-profile-dealflow"
      >
        <img
          src={logo?.small?.location || DefaultImage}
          width="16"
          height="16"
          className="rounded-full mr-2 min-w-[16px]"
          alt=""
        />
        <span>{displayName}</span>
      </span>
      {website && (
        <a
          title="Open their website"
          target="_blank"
          rel="noreferrer nofollow"
          href={website}
          className="ml-2 text-sm text-gray-500 hover:text-aqua-400"
        >
          <FontAwesomeIcon icon={["fal", "globe"]} />
        </a>
      )}
    </div>
  ),

  createdAt: ({ entryDate }) => (
    <span className="flex justify-end md:inline">
      {getTimeSince(entryDate)}
    </span>
  ),

  "finance.raisedTarget": ({ company }) =>
    numberColumn(company.finance?.raisedTarget, company.currencyData),

  "finance.raisedValue": ({ company }) =>
    numberColumn(company.finance?.raisedValue, company.currencyData),

  valuation: ({ company }) =>
    numberColumn(company.valuation, company.currencyData),

  teamMembers: ({ company }) =>
    arrayColumn(company.teamMembers, (member) => member.name),

  investors: ({ company }) =>
    arrayColumn(company.investors, (investor) => investor.displayName),

  cohort: ({ cohort }) => (
    <div
      title={cohort?.name}
      className="flex justify-end truncate md:justify-start"
    >
      <span className="inline-block max-w-[10rem]">{cohort?.name || "-"}</span>
    </div>
  ),

  sustainableDevelopmentGoals: ({
    company: { sustainableDevelopmentGoals: sdg },
  }) => (
    <div className="flex items-center justify-end md:justify-center">
      {sdg?.items
        ?.slice(0, 2)
        .sort((a, b) => a - b)
        .map((key) => (
          <div key={key} className="w-6 mr-1">
            <SDG number={key} />
          </div>
        ))}
      {!sdg?.items?.length && "-"}
    </div>
  ),

  documents: (company) => (
    <div className="flex items-center justify-end md:justify-center">
      {company.company.documents
        ?.slice(0, 2)
        .map(({ mimetype, location }, idx) => (
          <a target="_blank" key={idx} href={location} rel="noreferrer">
            {mimetype === "application/pdf" ? (
              <FileIcon className="w-6 h-6 mr-1" key={idx} />
            ) : (
              <XlIcon className="w-6 h-6 mr-1" key={idx} />
            )}
          </a>
        ))}
      {!company.company.documents?.length && "-"}
    </div>
  ),

  country: ({ company }) => <span>{company.country}</span>,

  elevator: ({ company }) =>
    company.pitch ? (
      <Tooltip content={company.pitch}>
        <span className="inline-block w-[90px] text-xs truncate">
          {company.pitch}
        </span>
      </Tooltip>
    ) : (
      <span>-</span>
    ),

  industries: ({ company: { industries = [] } }) =>
    industries.length ? (
      <Tooltip
        content={industries
          .map((key) => (INDUSTRY_CATEGORIES_SWEDISH as any)[key])
          .join(", ")}
      >
        <span className="inline-block w-[80px] text-xs truncate">
          {industries
            .map((key) => (INDUSTRY_CATEGORIES_SWEDISH as any)[key])
            .join(", ")}
        </span>
      </Tooltip>
    ) : (
      <span>-</span>
    ),
};
