import { FC, useMemo, useState } from "react";
import type { TableViewProps } from "./interfaces";
import { Table } from "organisms/Table";
import { CELL_RENDERERS } from "./Cell";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@apollo/client";
import { DealflowSection, GetDealflow } from "../interfaces";
import { GET_DEALFLOW } from "queries/startup/startupDealflow";
import { CHANGE_RECORD_STATUS } from "queries/investor/investorDealflow";
import { Mixpanel } from "core/mixpanel/MixpanelWrapper";
import { SingleSelect } from "molecules/SingleSelect";
import EditStatusModal from "pages/dealflow/InvestorDealflow/components/EditStatusModal";

const TableView: FC<TableViewProps> = ({
  isSearchEmpty,
  isNoResults,
  columns,
  records,
  sort,
  selected,
  sections,
  refetch,
  preview,
  onSort,
  onSelect,
  onEdit,
}) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const { refetch: refetchDealflow } = useQuery<GetDealflow>(GET_DEALFLOW);
  const finalColumns = useMemo(() => {
    const result = columns.slice();

    result.unshift({
      key: "edit",
      title: "",
    });

    return result;
  }, [columns]);

  const getSection = (id: string) =>
    sections.find((section) => section.id === id) ?? null;

  const getHandleStatusChange =
    (id: string) => async (status: DealflowSection) => {
      await changeRecordStatus({
        variables: {
          record: id,
          status: status.id,
        },
      });
      refetch();
      Mixpanel.track("Status Change Dealflow");
    };

  const handleOpenModal = () => setIsShowModal(true);

  const handleCloseModal = (needToRefetch?: boolean) => {
    setIsShowModal(false);

    if (needToRefetch) {
      refetchDealflow();
    }
  };

  return (
    <div
      className={cn(
        "relative w-full overflow-auto",
        "max-h-[calc(100vh-114px)] md:max-h-[calc(100vh-153px)]",
        selected && preview
          ? "md:max-w-[calc(100vw-50rem-250px)]"
          : selected || preview
          ? "md:max-w-[calc(100vw-25rem-250px)]"
          : "md:max-w-[calc(100vw-250px)]"
      )}
    >
      <Table
        rows={records}
        columns={finalColumns}
        cellRenderers={{
          ...CELL_RENDERERS,
          edit: (record) => (
            <div>
              {record.isManual && (
                <button
                  type="button"
                  className="text-sm text-gray-400 hover:text-aqua-400 focus:text-aqua-400"
                  onClick={() => onEdit(record)}
                >
                  <FontAwesomeIcon icon={["fas", "pen"]} />
                </button>
              )}
            </div>
          ),
          status: (record) => (
            <div className="relative pl-2 status-select max-w-[12rem]">
              <SingleSelect
                value={getSection(record.status.id)}
                choices={sections}
                onChange={getHandleStatusChange(record.id)}
                actions={[
                  {
                    onClick: handleOpenModal,
                    content: (
                      <span className="flex items-center text-sm text-black opacity-75">
                        <FontAwesomeIcon
                          className="mb-1"
                          icon={["fal", "pencil"]}
                        />
                        <span className="ml-2">Customize</span>
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          ),
        }}
        sort={sort}
        highlightIds={selected ? [selected.id] : []}
        onSort={onSort}
        onClick={onSelect}
      />
      {isSearchEmpty && (
        <div className="py-10 text-sm text-center text-gray-600">
          No appropriate results
        </div>
      )}
      {isNoResults && (
        <div className="flex flex-col items-center mt-8">
          <img
            alt=""
            className="mb-2 sm:h-48"
            src="/assets/images/dealflow-empty-state.png"
          />
          <h1 className="mb-2 text-xl font-bold text-black">
            Welcome to your Dealflow!
          </h1>
          <div className="text-center text-gray-500">
            You don't have any investors in your dealflow yet.
          </div>
        </div>
      )}
      {isShowModal && <EditStatusModal onClose={handleCloseModal} />}
    </div>
  );
};

export default TableView;
