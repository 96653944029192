// @ts-nocheck
import React, { useState, useEffect } from "react";

import useClickOutside from "hooks/useOnClickOutside";
import { ReactComponent as ArrowDownIcon } from "images/angle-down-icon.svg";
import { useLang } from "core/lang/LangaugeSetting";
import cn from "classnames";

// @TODO: refactor this
interface Props {
  showLabel?: boolean;
  label?: string;
  placeholder?: string;
  options?: any[];
  onChange?: (data: any) => void;
  single?: boolean;
  defaultValue?: any;
  inputValue?: string;
  handleInputChange?: (value: string) => void;
  selectDropdownInput?: (value: string) => void;
  resetText?: string;
  className?: string;
  additionalOptions?: any[];
  checkMark?: boolean;
  showChecked?: boolean;
  clear?: boolean;
  name?: string;
}
// @TODO: use real typings instead of any
//
// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  showLabel,
  label,
  placeholder,
  options = [],
  onChange,
  single,
  name,
  defaultValue,
  inputValue,
  handleInputChange,
  selectDropdownInput,
  resetText,
  className,
  additionalOptions = [],
  checkMark,
  showChecked,
  clear,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState();
  const { lang } = useLang();

  useEffect(() => {
    if (defaultValue) {
      setSelected([{ key: defaultValue, value: defaultValue }]);
      setSelectedLabel(`${defaultValue}`);
    }
  }, [defaultValue]);

  const close = () => {
    setOpen(false);

    if (selected.length > 0) {
      setSelectedLabel(selected.map((item) => item.value).join(", "));
    } else {
      setSelectedLabel();
    }
  };

  const ref = React.createRef();
  const inputRef = React.useRef();

  useClickOutside(ref, (e) => {
    if (!inputRef.current.contains(e.target)) {
      close();
    }
  });

  const isSelected = (option) =>
    selected.map((item) => item.key).includes(option.key);

  const emit = (data) => {
    if (onChange && typeof onChange === "function") {
      onChange(data.map((item) => item.key));
    }
  };

  const handleChange = (e) => {
    handleInputChange(e.target.value)(e.target.value ? setOpen(true) : close());
  };

  const handleShowDropdown = (e) => {
    if (e.target.value && options.length > 0) {
      setOpen(true);
    }
  };

  const getCheckMark = () => (
    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-aqua-400">
      <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </span>
  );

  const hasSelectedValues = selected?.length > 0;

  return (
    <div
      className={`${
        selectDropdownInput ? "select-input-field space-y-1" : "space-y-1"
      }`}
    >
      <div className="relative clear">
        {showLabel && (
          <label
            id="listbox-label"
            className="block ml-2 text-sm leading-5 text-gray-400 truncate"
          >
            {label}
          </label>
        )}
        {clear && hasSelectedValues && (
          <div
            className="absolute top-0 right-0 block text-sm leading-5 cursor-pointer text-aqua-300 hover:text-aqua-400"
            onClick={() => {
              setSelected([]);
              emit([]);
              setSelectedLabel();
            }}
          >
            {lang.clear}
          </div>
        )}
      </div>
      <div className="relative btn">
        {handleInputChange ? (
          <input
            onChange={handleChange}
            onClick={handleShowDropdown}
            defaultValue={inputValue}
            placeholder={placeholder}
            style={{ boxShadow: "none", color: inputValue && "#2d2d2d" }}
            ref={inputRef}
            className={cn(
              className,
              "w-full px-3 py-2 text-sm leading-tight text-gray-500 text-opacity-50 border rounded shadow-sm appearance-none focus:outline-none focus:ring __search"
            )}
          />
        ) : (
          <span className="inline-block w-full rounded-md shadow-sm">
            <button
              onClick={() => (open ? close() : setOpen(!open))}
              type="button"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              className={cn(
                className,
                "cursor-default relative w-full rounded-md border",
                hasSelectedValues
                  ? "border-aqua-400"
                  : "border-black border-opacity-25",
                "bg-white pl-3 pr-5 py-3 text-left transition ease-in-out duration-150 sm:text-sm"
              )}
              {...(open && { "aria-expanded": true })}
              ref={inputRef}
            >
              <span
                className="block truncate"
                style={{
                  textOverflow: selectDropdownInput && "initial",
                  fontSize: selectDropdownInput && "12px",
                }}
              >
                {(showChecked && selectedLabel) || placeholder || label}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center mt-0.5 pr-2 pointer-events-none">
                {selectDropdownInput ? (
                  <ArrowDownIcon />
                ) : (
                  <svg
                    className="w-5 h-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </span>
            </button>
          </span>
        )}

        {open ? (
          <div
            className={`absolute mt-1 w-full rounded-md bg-white shadow-lg z-20 __custom ${
              open ? "" : "hidden"
            }`}
            ref={ref}
          >
            <ul
              tabIndex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              className="py-1 overflow-auto text-base leading-6 rounded-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:leading-5"
            >
              {resetText && (
                <li
                  onClick={() => {
                    setSelected([]);
                    emit([]);
                  }}
                  role="option"
                  className="relative py-2 pl-3 text-gray-900 cursor-pointer select-none pr-9 hover:bg-gray-100"
                >
                  <span
                    className="block font-normal truncate"
                    style={{ fontSize: selectDropdownInput && "13px" }}
                  >
                    {resetText}
                  </span>
                  {!hasSelectedValues && getCheckMark()}
                </li>
              )}
              {options.map((option) => (
                <li
                  onClick={() => {
                    if (isSelected(option)) {
                      const res = selected.filter(
                        (item) => item.key !== option.key
                      );
                      emit(res);
                      return setSelected(res);
                    }

                    const res = single ? [option] : [...selected, option];
                    emit(res);
                    setSelected(res);
                  }}
                  id="listbox-option-0"
                  key={option.key}
                  role="option"
                  className="relative py-2 pl-3 text-gray-900 cursor-pointer select-none pr-9 hover:bg-gray-100"
                >
                  <span
                    className="block font-normal truncate"
                    style={{ fontSize: selectDropdownInput && "13px" }}
                  >
                    {option.value}
                  </span>

                  {isSelected(option) && checkMark && getCheckMark()}
                </li>
              ))}
              {additionalOptions.map((option, idx) => (
                <li
                  onClick={option.onClick}
                  key={idx}
                  role="option"
                  className="relative py-2 pl-3 text-gray-900 border-t border-gray-200 cursor-pointer select-none pr-9 hover:bg-gray-100"
                >
                  {option.content}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};
