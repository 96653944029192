import { FC, useState } from "react";
import { ReactComponent as PlanNavRight } from "images/plan-nav-right.svg";
import { ReactComponent as PlanNavLeft } from "images/plan-nav-left.svg";
import { PlansProps } from "../../interfaces";
import Plan from "../Plan";

const PlansMobile: FC<PlansProps> = ({ plans }: PlansProps) => {
  const [currentIndex, setCurrentIndex] = useState(plans.findIndex(plan => plan.isMostPopular));

  const handleMoveToNext = () => {
    if (currentIndex === plans.length - 1) return;
    setCurrentIndex(currentIndex + 1);
  };

  const handleMoveToPrev = () => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
  };

  const isMovePrveEnabled = currentIndex > 0;
  const isMoveNextEnabled = currentIndex < plans.length - 1;

  return (
    <div className="relative">
      {isMovePrveEnabled && (
        <button
          onClick={() => handleMoveToPrev()}
          className="absolute z-30 -left-5 top-40"
        >
          <PlanNavLeft />
        </button>
      )}
      <Plan plan={plans[currentIndex]} />
      {isMoveNextEnabled && (
        <button
          onClick={() => handleMoveToNext()}
          className="absolute z-30 -right-5 top-40 "
        >
          <PlanNavRight />
        </button>
      )}
    </div>
  );
};

export default PlansMobile;
