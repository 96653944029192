import type { FC } from "react";
import type { DocumentsProps } from "./interfaces";
import type { GetRecordDocuments } from "pages/dealflow/InvestorDealflow/interfaces";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSelect from "atoms/Form/FileSelect";
import {
  ADD_DEALFLOW_DOCUMENT,
  GET_RECORD_DOCUMENTS,
  REMOVE_DEALFLOW_DOCUMENT,
} from "queries/investor/investorDealflow";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Loading from "atoms/Loading/Loading";

const MAX_DOCUMENT_SIZE = 10000000;

const Documents: FC<DocumentsProps> = ({ id, name }) => {
  const dispatch = useDispatch();
  const {
    data,
    loading: isQueryLoading,
    refetch,
  } = useQuery<GetRecordDocuments>(GET_RECORD_DOCUMENTS, {
    variables: { id },
  });
  const [addDocument, { loading: isAddingLoading }] = useMutation(
    ADD_DEALFLOW_DOCUMENT
  );
  const [removeDocument, { loading: isRemovingLoading }] = useMutation(
    REMOVE_DEALFLOW_DOCUMENT
  );
  const documents = data?.dealflowRecord.documents || [];

  const raiseSnack = (type: string, title: string, message: string) =>
    dispatch(triggerSnack({ type, title, message }));

  const onUpload = async (file: File) => {
    if (file.size > MAX_DOCUMENT_SIZE) {
      raiseSnack("error", "Couldn't upload this file", "This file is too big");

      return;
    }

    try {
      await addDocument({
        variables: {
          record: id,
          file,
        },
      });
      refetch();
    } catch (error: any) {
      raiseSnack("error", "Couldn't upload this file", "Please try again");
    }
  };

  const getOnRemove = (documentId: string) => async () => {
    if (isRemovingLoading) {
      return;
    }

    try {
      await removeDocument({
        variables: {
          record: id,
          document: documentId,
        },
      });
      refetch();
    } catch (error: any) {
      raiseSnack("error", "Couldn't delete this file", "Please try again");
    }
  };

  const isLoading = isQueryLoading || isAddingLoading || isRemovingLoading;

  return (
    <div className="text-sm">
      <div className="mb-2 text-gray-500">
        For internal use, nothing you upload or share here will be shared with{" "}
        {name}
      </div>
      <div className="flex items-center mb-2 text-gray-500">
        <FontAwesomeIcon icon={["fas", "file"]} />
        <span className="mx-2">Attach documents</span>
        {isLoading && <Loading size="small" />}
      </div>
      <div>
        <FileSelect
          multiple
          className="bg-white"
          type="imageOrDocument"
          imageDesc="Drop your files here or "
          disabled={isAddingLoading}
          onChange={onUpload}
        />
      </div>
      <div className="mt-2">
        {documents.map((document) => (
          <div
            key={document.id}
            className="flex items-center justify-between p-2 mb-2 text-gray-600 border border-gray-300 rounded-md"
          >
            <a
              href={document.location}
              target="_blank"
              className="flex items-center"
              rel="noreferrer nofollow"
            >
              <FontAwesomeIcon icon={["fas", "file"]} />
              <span className="ml-2">{document.title}</span>
            </a>
            <div
              title="Remove this document"
              className="inline-block text-red-400 cursor-pointer"
              onClick={getOnRemove(document.id)}
            >
              <FontAwesomeIcon icon={["fas", "trash"]} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents;
