import { FC } from "react";
import { ProductVisualProps } from "./interfaces";
import EmbedVideo from "atoms/EmbedVideo";

const ProductVisual: FC<ProductVisualProps> = ({
  type,
  source,
  onClick,
}: ProductVisualProps) => (
  <div
    className="flex justify-center h-full p-1 rounded-md pointer-events-none sm:pointer-events-auto"
    onClick={type === "VIDEO" ? () => null : onClick}
  >
    {type === "VIDEO" && source && <EmbedVideo source={source} />}
    {type === "IMAGE" && (
      <img
        src={source}
        alt="product-image"
        className="object-contain rounded-md"
      />
    )}
  </div>
);

export default ProductVisual;
