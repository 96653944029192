import { FC, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import { MeCompany } from "gql/graphql";

import AddMeetingLinkModal from "pages/companyProfile/components/shared/Navbar/AddMeetingLinkModal";
import ViewSettings from "pages/companyProfile/components/shared/Navbar/ViewSettings";
import { useNavigate } from "react-router-dom";

interface Props {
  company: MeCompany | undefined | null;
}

const Buttons: FC<Props> = ({ company }) => {
  const [isPublicShareModalOpen, setIsPublicShareModalOpen] = useState(false);
  const [isViewSettingsModalOpen, setIsViewSettingsModalOpen] = useState(false);
  const [isAddMeetingLinkModalOpen, setIsAddMeetingLinkModalOpen] =
    useState(false);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-4/5 my-2 sm:my-2 lg:flex-row gap-2 m-auto max-w-sm lg:max-w-full">
      {/* ========== Buttons ========== */}
      <Button
        onClick={(e) => {
          e.preventDefault();
          setIsPublicShareModalOpen(true);
        }}
        color="primary"
        className="flex justify-center w-full"
      >
        <FontAwesomeIcon
          icon={["fas", "share-alt"]}
          className="mr-2 text-white"
        />
        <span>Share</span>
      </Button>

      <Button
        onClick={() => {
          setIsAddMeetingLinkModalOpen(true);
        }}
        color="transparent"
        className="flex justify-center w-full"
      >
        <FontAwesomeIcon icon={["fal", "calendar"]} className="mr-2 mb-0.5" />
        <span className="truncate">
          {company?.meetingUrl ? "Edit" : "Setup"} scheduling link
        </span>
      </Button>

      <Button
        color="transparent"
        onClick={() => setIsViewSettingsModalOpen(true)}
        className="flex justify-center w-full"
      >
        <FontAwesomeIcon icon={["fas", "user-lock"]} className="mr-2" />
        <span className="whitespace-nowrap">Page settings</span>
      </Button>

      <Button
        color="transparent"
        onClick={() => navigate("/company-profile")}
        className="flex justify-center w-full"
      >
        <FontAwesomeIcon icon={["fas", "eye"]} className="mr-2" />
        <span className="whitespace-nowrap">View profile</span>
      </Button>

      {/* ========== Modals ========== */}

      {isPublicShareModalOpen && company?.name && (
        <PublicShare
          title={company?.name}
          publicUrl={window.location.origin + "/public/" + company.slug}
          onClose={() => setIsPublicShareModalOpen(false)}
        />
      )}

      <AddMeetingLinkModal
        isOpen={isAddMeetingLinkModalOpen}
        onClose={() => {
          setIsAddMeetingLinkModalOpen(false);
        }}
        /* Narrowing down the type */
        meetingLink={company?.meetingUrl || undefined}
      />

      <ViewSettings
        isOpen={isViewSettingsModalOpen}
        onClose={() => {
          setIsViewSettingsModalOpen(false);
        }}
      />
    </div>
  );
};

export default Buttons;
