import { createAction } from "@reduxjs/toolkit";

export const getProductDetails = createAction("companySettings/product/fetch");

export const saveProductDetails = createAction(
  "companySettings/product/save",
  (payload) => ({
    payload,
  })
);

export const addProductImage = createAction(
  "companySettings/product/add",
  (payload) => ({ payload })
);

export const removeProductImage = createAction(
  "companySettings/product/remove",
  (payload) => ({ payload })
);
