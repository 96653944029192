import authSagas from "./auth/sagas";
import loadersSagas from "./loaders/sagas";
import userSagas from "./user/sagas";
import lazyLoadSagas from "./lazyload/sagas";
import globalStateSagas from "./globalState/sagas";

export const modelSagas = [
  authSagas,
  loadersSagas,
  userSagas,
  lazyLoadSagas,
  globalStateSagas,
];
