import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLang } from "core/lang/LangaugeSetting";
import Button from "atoms/Button/Button";
import { useDispatch } from "react-redux";
import { triggerShareModal } from "organisms/ShareProfileModal/sagaActions";
import { setGlobalState } from "models/globalState/sagaActions";
import { FC } from "react";

const ShareButton: FC<{
  small?: boolean;
  isMobile?: boolean;
  isMe: boolean;
}> = ({ small, isMobile, isMe }) => {
  const dispatch = useDispatch();
  const { lang } = useLang();

  const handleClickPublic = () => {
    dispatch(triggerShareModal({ isEnabled: true }));
    setGlobalState({ isPublicShareModal: true });
  };

  return (
    <Button
      onClick={() => handleClickPublic()}
      color="primary"
      className="flex justify-center w-full mt-2 md:w-auto md:mt-0 md:mr-2"
      id="share-btn"
      data-key={isMe ? "click-own-share-button" : "click-share-button"}
      size={small ? "small" : undefined}
    >
      <span>{lang.share}</span>
      {!isMobile && (
        <FontAwesomeIcon icon={["fas", "share-alt"]} className="ml-2" />
      )}
    </Button>
  );
};
export default ShareButton;
