import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import cn from "classnames";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import BusinessModel from "./BusinessModel";
import Pricing from "./Pricing";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";

interface Props {
  businessModelDescription?: string;
  pricing?: string;
  isLoading?: boolean;
}

const BusinessModelAndPricing: FC<Props> = ({
  businessModelDescription,
  isLoading,
  pricing,
}) => {
  const { profileSectionRef } = useProfileNavigation();

  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="Business Model" />
        <Skeleton count={3.6} />
      </div>
    );

  if (!businessModelDescription && !pricing) return null;

  return (
    <div
      id={PROFILE_SECTION_IDS["Business Model & Pricing"]}
      ref={profileSectionRef}
      className={cn(
        businessModelDescription ? "grid-cols-1 md:grid-cols-2" : "grid-cols-1",
        "mb-6 grid gap-4"
      )}
    >
      <BusinessModel
        content={
          businessModelDescription ||
          "We could not find any business model for this company."
        }
      />

      <Pricing
        content={
          pricing ||
          "We could not find any pricing information for this company."
        }
      />
    </div>
  );
};

export default BusinessModelAndPricing;
