import cn from "classnames";
import { FC } from "react";
import { BoardsPageHeaderProps } from "../../interfaces";
import InteractiveAnalyticsButton from "./InteractiveAnalyticsButton";

const Header: FC<BoardsPageHeaderProps> = ({
  companyId,
  boardsLength,
  searchTerm,
  isOnProPlan,
  isDealFlowEmpty,
  onSearchTermChange,
}) => (
  <>
    <header
      className={cn(
        "flex px-4",
        boardsLength > 0 ? "justify-between" : "justify-end",
        isDealFlowEmpty && "pr-[36rem]"
      )}
    >
      {boardsLength > 0 && (
        <input
          type="text"
          className="min-w-[10rem] mr-2 sm:min-w-[18.75rem] shadow-sm appearance-none border border-black border-opacity-25 rounded-full py-1 px-3 text-gray-500 leading-tight placeholder:italic"
          placeholder="Search for a board"
          value={searchTerm}
          onChange={(e) => onSearchTermChange(e.target.value)}
        />
      )}
      <InteractiveAnalyticsButton
        companyId={companyId}
        isOnProPlan={isOnProPlan}
      />
    </header>
    <div className="px-4 mt-8 mb-6">
      <h1 className="text-3xl leading-[3.5rem]">Boards</h1>
      <p className="text-sm text-black">
        Startup boards allows you to create boards that showcase selected
        startups.
      </p>
    </div>
  </>
);

export default Header;
