import { gql } from "@apollo/client";

export const SIGN_UP_MUTATION = gql`
  mutation signUpV2($input: SignUpInput!) {
    signUpV2(input: $input) {
      name
      profile
      type
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation signInV2($email: String!, $password: String!, $referrer: String) {
    signInV2(email: $email, password: $password, referrer: $referrer) {
      email
      name
      type
    }
  }
`;

export const LOGIN_AS_TRIAL = gql`
  mutation loginAsTrial($input: LoginTrialInput!) {
    loginAsTrial(input: $input)
  }
`;

export const GOOGLE_SIGNIN = gql`
  mutation googleSignIn($input: GoogleSignInInput) {
    googleSignIn(input: $input) {
      principal
      email
      type
    }
  }
`;

export const GOOGLE_SIGNUP = gql`
  mutation googleSignUp($input: GoogleSignUpInput) {
    googleSignUp(input: $input) {
      principal
      email
      type
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const RESET_PASSWORD_QUERY = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const IS_VALID_RESET_TOKEN = gql`
  query isValidResetToken($token: String!) {
    isValidResetToken(token: $token)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
