// @ts-nocheck
import { initialState } from "./reducer";
import { isLoadingEnabled } from "models/loaders/selectors";

const forgotPassword = (state) => state.forgotPassword || initialState;

export const isLoadingPage = (state) =>
  isLoadingEnabled(state, "forgotPassword");

export const getForgotPasswordStatus = (state) =>
  forgotPassword(state)["status"];
