import { gql } from "@apollo/client";

export const GET_SUBSCRIPTION = gql`
  query getSubscription {
    me {
      subscription {
        id
        nextBillingDate
        cancelAtPeriodEnd
        status
        plan {
          id
          title
        }
        price {
          id
          price
          currency
          interval
        }
      }
      paymentProfile {
        id
        email
        name
        defaultPaymentMethod {
          card {
            last4
          }
        }
      }
    }
  }
`;

export const GET_MANAGE_PAYMENTS_URL = gql`
  query getManagePaymentUrl {
    paymentMethodUrl
  }
`;

export const GET_INVOICE_HISTORY_URL = gql`
  query getInvoiceHistoryUrl {
    invoiceHistoryUrl
  }
`;

export const GET_CANCEL_SUBSCRIPTION_URL = gql`
  query getCancelSubscriptionUrll($subscription: String!) {
    cancelSubscriptionUrl(subscription: $subscription)
  }
`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($newPriceId: String!) {
    updatePlan(newPriceId: $newPriceId)
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptions {
    me {
      subscriptions {
        id
      }
    }
  }
`;
