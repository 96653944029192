import type { TableProps, WithId } from "./interfaces";
import React from "react";
import cn from "classnames";
import Checkbox from "atoms/Checkbox/Checkbox";
import useTable from "./useTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Table = <RecordData extends WithId>(props: TableProps<RecordData>) => {
  const {
    rows,
    columns,
    sort,
    isSelectable,
    isHeadChecked,
    isBodyFrozen,
    renderCell,
    handleHeadChecked,
    getIsChecked,
    getIsExpanded,
    getIsSortable,
    getIsHighlighted,
    getHandleClick,
    getHandleSort,
    getHandleCheck,
    getHandleExpand,
  } = useTable(props);

  return (
    <table className="w-full no-hover">
      <thead>
        <tr className="sticky top-0 z-10 text-sm text-gray-600 bg-gray-100 border-b border-gray-200 select-none lg:top-0">
          {isSelectable && (
            <th className="hidden p-3 lg:table-cell">
              <Checkbox checked={isHeadChecked} onChange={handleHeadChecked} />
            </th>
          )}
          {columns.map((column) => (
            <th
              key={column.key}
              align="left"
              className={cn(
                "py-3 pl-3 pr-2 bg-inherit relative",
                !column.isPreview && "hidden lg:table-cell",
                column.isRightSticky && "sticky right-0 z-20",
                column.isLeftSticky && "sticky left-0 z-20",
                getIsSortable(column.key) &&
                  "cursor-pointer hover:text-gray-800"
              )}
              onClick={getHandleSort(column.key)}
            >
              <div className="flex items-center justify-between pr-1">
                {column.isRightSticky && (
                  <span className="hidden md:block absolute -top-4 bottom-0 left-1 w-[1px] bg-gray-300" />
                )}
                {column.isLeftSticky && (
                  <span className="hidden md:block absolute -top-4 bottom-0 right-1 w-[1px] bg-gray-300" />
                )}
                <span>{column.title}</span>
                <FontAwesomeIcon
                  icon={["fas", "chevron-up"]}
                  className={cn(
                    "ml-2 transition",
                    getIsSortable(column.key)
                      ? sort?.key === column.key
                        ? ""
                        : "opacity-0"
                      : "hidden",
                    sort?.key === column.key && sort.order < 0 && "rotate-180"
                  )}
                />
              </div>
            </th>
          ))}
          <th className="lg:hidden" />
        </tr>
      </thead>
      <tbody
        className={cn(
          "text-sm",
          isBodyFrozen && "opacity-30 pointer-events-none"
        )}
      >
        {rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <tr
              key={row.id}
              onClick={getHandleClick(row)}
              className={cn(
                "border-gray-200 hover:bg-gray-100 bg-white",
                getIsChecked(row.id) && "bg-aqua-50 hover:bg-aqua-50",
                getIsHighlighted(row.id) && "!bg-aqua-100"
              )}
            >
              {isSelectable && (
                <td className="hidden px-3 py-1 lg:table-cell">
                  <Checkbox
                    checked={getIsChecked(row.id)}
                    onChange={getHandleCheck(row.id)}
                  />
                </td>
              )}
              {columns.map((column) => (
                <td
                  key={column.key}
                  className={cn(
                    "px-2 py-3 bg-inherit",
                    column.isLeftSticky && "sticky left-0 z-[2]",
                    column.isRightSticky && "sticky right-0",
                    !column.isPreview && "hidden lg:table-cell"
                  )}
                >
                  {renderCell(column.key, row, index)}
                </td>
              ))}
              <td
                className="p-3 lg:hidden ignore-click"
                onClick={getHandleExpand(row.id)}
              >
                <FontAwesomeIcon
                  className={cn(
                    "transition",
                    getIsExpanded(row.id) && "rotate-180"
                  )}
                  icon={["fas", "chevron-up"]}
                />
              </td>
            </tr>
            {getIsExpanded(row.id) &&
              columns
                .filter((column) => !column.isPreview)
                .map((column) => (
                  <tr key={column.key} className="border-b border-gray-200">
                    <td className="p-2">
                      <strong className="pl-6">{column.title}</strong>
                    </td>
                    <td colSpan={2} align="right" className="pr-10 md:pr-0">
                      <div>{renderCell(column.key, row, index)}</div>
                    </td>
                  </tr>
                ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
