import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import BoardStartups from "../../components/BoardStartups";
import { useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import { BoardDetailsProps } from "../../interfaces";
import PureHtml from "atoms/PureHtml";
import { convertTimeStampToDate } from "../../utils";
import InteractiveAnalyticsButton from "./InteractiveAnalyticsButton";
import { GET_CURRENCY_LIST } from "queries/general/me";

const BoardDetails: FC<BoardDetailsProps> = ({
  startups,
  board,
  isOpen,
  isOnProPlan,
  onClose,
  onShareBoardClick,
  onEditBoardClick,
}) => {
  const { data: currencyData } = useQuery(GET_CURRENCY_LIST);
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 "
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto rounded-lg">
          <div className="flex items-end justify-center p-0 text-center sm:items-center sm:py-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full sm:w-11/12 sm:h-[calc(100vh-4rem)] no-scrollbar rounded-lg invisible-scrollbar text-left transition-all transform bg-white shadow-xl">
                <Page
                  variants={pageAnimation}
                  exit="hidden"
                  initial="hidden"
                  animate="show"
                  className="flex-1 bg-white rounded-lg"
                >
                  <div className="relative flex flex-col md:flex-row">
                    <div className="flex flex-col h-full md:px-10">
                      {board?.logo?.large?.location && (
                        <div className="rounded-full w-[10rem] h-[10rem] mt-10 lg:my-10 mx-auto px-2">
                          <img
                            className="h-[8rem] w-[8rem] mx-auto rounded-full object-cover outline outline-white/50 outline-[10px] outline-offset-[-9px]"
                            src={board?.logo?.large?.location}
                            alt=""
                          />
                        </div>
                      )}
                      <div className="min-w-[12rem] md:justify-center flex flex-row mx-auto md:mx-0">
                        <InteractiveAnalyticsButton
                          boardSlug={board.slug}
                          isOnProPlan={isOnProPlan}
                        />
                        <div className="md:hidden">
                          <Button
                            color="primary"
                            className="ml-2 mr-2"
                            size="small"
                            onClick={() => {
                              onShareBoardClick(board);
                              onClose();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "share-alt"]}
                              size="sm"
                              className="mr-2"
                            />
                            <span className="text-sm">Share</span>
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            className="text-xs"
                            onClick={() => {
                              onEditBoardClick();
                              onClose();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "pen-alt"]}
                              size="sm"
                              className="mr-2"
                            />
                            <span className="text-sm">Edit</span>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col mx-auto md:mx-0">
                      <div className="right-0 z-10 flex-row hidden pr-20 md:flex md:absolute top-10">
                        <Button
                          color="primary"
                          className="ml-2 mr-2"
                          size="small"
                          onClick={() => {
                            onShareBoardClick(board);
                            onClose();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "share-alt"]}
                            size="sm"
                            className="mr-2"
                          />
                          <span className="text-sm">Share</span>
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          className="text-xs"
                          onClick={() => {
                            onEditBoardClick();
                            onClose();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fal", "pen-alt"]}
                            size="sm"
                            className="mr-2"
                          />
                          <span className="text-sm">Edit</span>
                        </Button>
                      </div>
                      <div className="relative flex flex-col justify-center mx-auto my-6 lg:text-start sm:px-16 md:px-0 md:py-16 lg:py-8 lg:pr-10 sm:items-start md:mt-6">
                        <div className="flex flex-col h-full mx-10 md:mx-0">
                          <h2 className="mb-3 text-xl sm:text-[2.5rem]">
                            {board.title}
                          </h2>
                          <span className="text-[#787486] font-medium mb-3">
                            {convertTimeStampToDate(board.createdAt)}
                          </span>
                          <div className="max-w-3xl mb-6">
                            <PureHtml
                              content={board.description}
                              className="max-w-3xl text-base board-description boards !text-[#0b0d18]"
                            />
                          </div>
                        </div>
                        <BoardStartups
                          isPublic
                          startups={startups}
                          currencyData={currencyData}
                        />
                      </div>
                    </div>
                  </div>
                </Page>
                <div className="absolute top-5 right-5">
                  <span
                    className="absolute top-0 right-0 cursor-pointer"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    <FontAwesomeIcon icon={["fal", "times"]} size="2x" />
                  </span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BoardDetails;
