import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FieldName } from "gql/graphql";

interface Props {
  content: string;
}

const Description: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div className="mb-4">
      <h3 className="mr-2 text-lg text-gray-700 my-2">
        Product/Service Description
      </h3>
      <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile pl-4">
        <PureHtml content={content} />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(
  Description,
  FieldName.ProductDescription
);
