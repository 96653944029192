import { gql } from "@apollo/client";

export const GET_FEEDBACK = gql`
  query getFeedback($companyId: String!, $key: String!) {
    aia {
      feedback(companyId: $companyId, key: $key) {
        id
      }
    }
  }
`;

export const SEND_FEEDBACK = gql`
  mutation sendFeedback(
    $companyId: String!
    $key: String!
    $isLike: Boolean!
    $comment: String
  ) {
    aia {
      feedback(
        companyId: $companyId
        key: $key
        isLike: $isLike
        comment: $comment
      ) {
        ok
      }
    }
  }
`;
