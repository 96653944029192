import { FC } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { generateNodeUploadCode } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useDropzone } from "react-dropzone";
import { TrashIcon } from "@heroicons/react/20/solid";

interface NodeUploadProps {
  apiKey: string;
  externalId: string;
  selectedFile: File | null;
  setApiKey: (apiKey: string) => void;
  setExternalId: (externalId: string) => void;
  setSelectedFile: (file: File | null) => void;
  onCopy: () => void;
}

const NodeUpload: FC<NodeUploadProps> = ({
  onCopy,
  apiKey,
  externalId,
  setSelectedFile,
  selectedFile,
  setApiKey,
  setExternalId,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
  });

  const removeFile = () => {
    setSelectedFile(null);
  };

  return (
    <div>
      <div className="w-full h-5 rounded-t-md p-2 mt-4"></div>
      <code className="relative flex flex-col">
        <span className="text-sm">
          import axios from "axios";
          <CopyToClipboard
            onCopy={onCopy}
            text={generateNodeUploadCode(apiKey, externalId)}
          >
            <FontAwesomeIcon
              icon={["fal", "copy"]}
              size="lg"
              className="cursor-pointer text-aqua-500 float-right"
            />
          </CopyToClipboard>
          <br /> <br />
        </span>
        <span>
          const apiKey =
          <input
            className="border border-gray-200 rounded-md px-2 py-1 ml-2 placeholder:italic"
            placeholder="'YOUR_API_KEY'"
            value={apiKey}
            onChange={(event) => setApiKey(event.target.value)}
          />
        </span>
        <span>
          const externalId =
          <input
            className="border border-gray-200 rounded-md px-2 py-1 ml-2 placeholder:italic"
            placeholder="'YOUR_EXTERNAL_ID'"
            value={externalId}
            onChange={(event) => setExternalId(event.target.value)}
          />
        </span>
        <br />
        <span className="whitespace-nowrap overflow-hidden text-ellipsis">
          const headers ={`{'Authorization': 'Bearer' +`}{" "}
          <span className="font-bold"> apiKey</span> {`}`}
        </span>
        <span className="">
          const apiEndPoint = {process.env.REACT_APP_REST_URL}
          /ai/profiles
        </span>
        <div className={cn(selectedFile && "flex flex-row")}>
          <span>const file = </span>&nbsp;
          {!selectedFile ? (
            <div
              {...getRootProps({ className: "dropzone" })}
              className="flex justify-center mt-3 mb-5 w-full cursor-pointer items-center border-[1px] border-dashed rounded-lg border-[#314B97] bg-[#EEF2FE]"
            >
              <input {...getInputProps()} />

              <div className="w-1/3 text-center text-black">
                <p className="text-inherit">Drag & Drop</p>
                <p className="text-inherit">or</p>
                <span className="text-[#18BEB3]"> Choose file</span>
                <span> to upload</span>
              </div>
            </div>
          ) : (
            <>
              <span> {selectedFile.name}</span>
              <TrashIcon
                onClick={removeFile}
                className="w-5 h-5 text-aqua-400 cursor-pointer ml-2"
              />
            </>
          )}
        </div>
        <span> const formData = new FormData();</span>
        <span>
          formData.append('file',
          <span className="font-bold"> file</span>
          );
        </span>
        <span>
          formData.append('id', <span className="font-bold">externalId</span>
          );
        </span>
        <br />
        <span className="w-full">
          <pre>
            <code>
              {`axios.post(apiEndPoint, formData { headers: headers })
  .then(response => {
    console.log(response.data);
  }).catch(error => {
    console.error('There was an error!', error);
  });`}
            </code>
          </pre>
        </span>
      </code>
    </div>
  );
};

export default NodeUpload;
