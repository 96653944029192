import contactRequestModalSagas from "organisms/ContactRequestModal/sagas";
import shareProfileModalSagas from "organisms/ShareProfileModal/sagas";
import offerModalSagas from "organisms/OfferModal/sagas";
import NavigationLayoutSagas from "organisms/NavigationLayout/sagas";
import snackSagas from "./Snack/sagas";

export const organismsSagas = [
  snackSagas,
  contactRequestModalSagas,
  shareProfileModalSagas,
  NavigationLayoutSagas,
  offerModalSagas,
];
