import { gql } from "@apollo/client";

export const SAVE_SOCIAL_NETWORKS = gql`
  mutation saveCompanySocialNetwork($input: StartupSocialNetworkInput!) {
    saveCompanySocialNetwork(input: $input) {
      socials {
        youtube
        facebook
        instagram
        linkedin
        twitter
      }
    }
  }
`;

export const GET_SOCIAL_NETWORKS = gql`
  query getSocialNetworks {
    me {
      socials {
        youtube
        facebook
        instagram
        linkedin
        twitter
      }
    }
  }
`;
