import { useProfileNavigationContext } from "./context";
import ProfileNavigationItem from "./ProfileNavigationItem";
import { PROFILE_SECTION_IDS } from "./constants";
import { FC } from "react";

interface Props {
  isPublic?: boolean;
}

const ProfileNavigation: FC<Props> = ({ isPublic }) => {
  const { availableSections, activeSectionId } = useProfileNavigationContext();

  return (
    <div className={isPublic ? "mt-8" : "mt-16"}>
      {Object.entries(PROFILE_SECTION_IDS).map(([key, value]) => {
        if (availableSections.has(value)) {
          return (
            <ProfileNavigationItem
              key={value}
              title={key}
              sectionId={value}
              activeSectionId={activeSectionId}
              sectionRef={availableSections.get(value)}
            />
          );
        }
      })}
    </div>
  );
};

export default ProfileNavigation;
