import { FC } from "react";
import usePlan from "./hooks/usePlan";
import { PlanProvider } from "./context/planContext";
import Loading from "atoms/Loading/Loading";
import { SubscriptionUserType } from "./interfaces";
import { InvestorPlans, InvestorPlanLayout } from "./InvestorPlans";
import StartupPlans from "./StartupPlans";

const PlansContainer: FC = () => {
  const { plans, isLoading } = usePlan();

  if (isLoading) {
    return <Loading fullPage />;
  }

  return (
    <PlanProvider>
      {plans[0].userType === SubscriptionUserType.VC ||
      plans[0].userType === SubscriptionUserType.Angel ? (
        <InvestorPlanLayout>
          <InvestorPlans />
        </InvestorPlanLayout>
      ) : (
        <StartupPlans />
      )}
    </PlanProvider>
  );
};

export default PlansContainer;
