import { Permission, Role, Roles } from "./types";

export const permissionsToRole = (permissions: Permission[]): Role => {
  const set = new Set(permissions);

  if (Roles.Admin.permissions.every((permission) => set.has(permission))) {
    return Roles.Admin;
  }

  return Roles.Collaborator;
};

export const isMemberCompanyAdmin = (permissions: Permission[]): boolean => {
  const role = permissionsToRole(permissions);
  return role.id === Roles.Admin.id;
};
