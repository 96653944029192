import { INDUSTRY_CATEGORIES_SWEDISH } from "core/consts";
import { getClient } from "core/utility";
import { AiaTaskStatus } from "gql/graphql";
import { GET_AIA_TASK_STATUS } from "queries/aia/task";

export const profileUrl = async (
  slug: string,
  id: string,
  isAiaGenerated: boolean = false
) => {
  const profileUrl = `/company/${slug}/?us=dealflow`;
  const profileLoaderUrl = `/aia-profile/${slug}/${id}?us=dealflow`;

  if (isAiaGenerated) {
    const apolloClient = getClient();

    const response = await apolloClient.query({
      query: GET_AIA_TASK_STATUS,
      fetchPolicy: "network-only",
      variables: {
        companySlug: slug,
      },
    });

    const status = response?.data?.aia?.task?.status;

    if (status === AiaTaskStatus.InProgress) {
      window.open(profileLoaderUrl, "_blank");
    } else {
      window.open(profileUrl, "_blank");
    }
  } else {
    window.open(profileUrl, "_blank");
  }
};

export type IndustryKey = keyof typeof INDUSTRY_CATEGORIES_SWEDISH;
