// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getElevatorPitch = createAction(
  "companySettings/elevatorPitch/fetch"
);

export const saveElevatorPitch = createAction(
  "companySettings/elevatorPitch/save",
  (payload) => ({
    payload,
  })
);
