import { useQuery } from "@apollo/client";
import { LIST_MY_COHORTS } from "queries/investor/cohort";
import { useState } from "react";
import { Cohort } from "../interfaces";

const useApplications = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCohort, setActiveCohort] = useState<Cohort>();
  const {
    data: { listMyCohorts } = {},
    loading,
    refetch,
  } = useQuery(LIST_MY_COHORTS);

  const showModal = () => setIsModalOpen(true);

  const hideModal = () => {
    setIsModalOpen(false);
    setActiveCohort(undefined);
  };

  const handleEdit = (cohort: Cohort) => {
    setActiveCohort(cohort);
    setIsModalOpen(true);
  };

  const cohorts = listMyCohorts || [];
  const isIntroMessage = !loading && cohorts.length === 0;

  return {
    activeCohort,
    cohorts,
    loading,
    isModalOpen,
    isIntroMessage,
    showModal,
    hideModal,
    handleEdit,
    refetch,
  };
};

export default useApplications;
