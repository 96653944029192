const stringValue = (value: string) => value;

const numberValue = (value: string) => Number(value.replace(/\D/g, ""));

const OUR_HEADERS = [
  {
    key: "name",
    label: "Name",
    process: stringValue,
  },
  {
    key: "site",
    label: "Website",
    process: stringValue,
  },
  {
    key: "valuation",
    label: "Valuation",
    process: numberValue,
  },
  {
    key: "raisedTarget",
    label: "Target",
    process: numberValue,
  },
  {
    key: "minTicketSize",
    label: "Min Ticket Size",
    process: numberValue,
  },
  {
    key: "elevatorPitch",
    label: "Elevator Pitch",
    process: stringValue,
  },
  {
    key: "tractionSummary",
    label: "Traction Summary",
    process: stringValue,
  },
  {
    key: "description",
    label: "Description",
    process: stringValue,
  },
  {
    key: "orgNumber",
    label: "Org Number",
    process: stringValue,
  },
  {
    key: "facebook",
    label: "Facebook",
    process: stringValue,
  },
  {
    key: "instagram",
    label: "Instagram",
    process: stringValue,
  },
  {
    key: "linkedin",
    label: "Linkedin",
    process: stringValue,
  },
  {
    key: "twitter",
    label: "Twitter",
    process: stringValue,
  },
];

export default OUR_HEADERS;
