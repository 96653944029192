import { FC, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { MemberType, MemberUiRepresentation } from "gql/graphql";
import PureHtml from "atoms/PureHtml";
import WYSIWYGEditor from "pages/applications/WYSIWYGEditor";
import SaveAndCancelButtons from "organisms/ContentEditable/SaveAndCancelButtons";
import { useMutation } from "@apollo/client";
import { UPDATE_AIA_PROFILE_UPDATE_MEMBER_UI } from "queries/editAiaProfile/editAiaProfile";
import Loading from "atoms/Loading/Loading";
import { mixpanelEditAiaProfile } from "core/mixpanel/Mixpanel";

interface Props {
  user: any;
  member: any;
  companyId: string;
  type: MemberType;
  uiRepresentation: MemberUiRepresentation | null | undefined;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  refetch: (variables: {
    companyId: string;
    memberId: string;
    type: MemberType;
  }) => void;
}

const MemberAnalysisResult: FC<Props> = ({
  user,
  member,
  companyId,
  type,
  uiRepresentation,
  isEditMode,
  setIsEditMode,
  refetch,
}) => {
  const [updatedEducation, setUpdatedEducation] = useState<
    string | undefined | null
  >();
  const [updatedExperience, setUpdatedExperience] = useState<
    string | undefined | null
  >();

  const [updateMemberUiRepresentation, { loading }] = useMutation(
    UPDATE_AIA_PROFILE_UPDATE_MEMBER_UI,
    {
      onCompleted() {
        refetch({
          memberId: member.id,
          companyId,
          type,
        });
      },
    }
  );

  useEffect(() => {
    setUpdatedEducation(uiRepresentation?.educationHtml);
  }, [uiRepresentation?.educationHtml]);

  useEffect(() => {
    setUpdatedExperience(uiRepresentation?.experienceHtml);
  }, [uiRepresentation?.experienceHtml]);

  const handleEducationChange = (e: any) => {
    const str = e.replaceAll("\n", "");
    setUpdatedEducation(str);
  };

  const handleExperienceChange = (e: any) => {
    const str = e.replaceAll("\n", "");
    setUpdatedExperience(str);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setUpdatedEducation(uiRepresentation?.educationHtml);
    setUpdatedExperience(uiRepresentation?.experienceHtml);
  };

  const handleSave = () => {
    setIsEditMode(false);
    updateMemberUiRepresentation({
      variables: {
        memberId: member.id,
        companyId,
        type,
        educationHtml: updatedEducation,
        experienceHtml: updatedExperience,
      },
    });
    mixpanelEditAiaProfile(user, companyId, "EditTeamMember");
  };

  const isEducationTainted =
    uiRepresentation?.educationHtml !== updatedEducation;

  const isExperienceTainted =
    uiRepresentation?.experienceHtml !== updatedExperience;

  if (loading) return <Loading />;

  return (
    <div className="my-2">
      <div className="whitespace-pre-wrap">
        {uiRepresentation?.experienceHtml && (
          <div>
            <h6 className="text-sm font-bold mt-2 !text-[#24272B]">
              Experience:
            </h6>
            {isEditMode ? (
              <WYSIWYGEditor
                value={uiRepresentation.experienceHtml}
                onChange={handleExperienceChange}
              />
            ) : (
              <div className="text-sm whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile">
                <PureHtml content={uiRepresentation.experienceHtml} tag="p" />
              </div>
            )}
          </div>
        )}

        {uiRepresentation?.educationHtml && (
          <div>
            <h6 className="text-sm font-bold mt-2 !text-[#24272B]">
              Education:
            </h6>
            {isEditMode ? (
              <WYSIWYGEditor
                value={uiRepresentation.educationHtml}
                onChange={handleEducationChange}
              />
            ) : (
              <div className="text-sm whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper aiaProfile">
                <PureHtml content={uiRepresentation.educationHtml} tag="p" />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Repeat founder does not have ui representation yet! */}
      {member?.repeatFounder && member.repeatFounder.length > 0 && (
        <div className="flex flex-col my-1">
          <h6 className="text-sm font-bold mt-2 !text-[#24272B]">
            Previously founded:
          </h6>
          {member?.repeatFounder.map((company: any, index: number) => (
            <a
              key={index}
              className={cn(
                company?.companyUrl ? "" : "pointer-events-none",
                "capitalize items-center flex"
              )}
              href={company?.companyUrl}
              target="_blank"
            >
              <span className="text-sm text-gray-700">{company?.company}</span>
              {company?.companyUrl && (
                <FontAwesomeIcon
                  icon={["fab", "linkedin"]}
                  className="text-[#0077b7] ml-2 mb-1"
                />
              )}
            </a>
          ))}
        </div>
      )}

      {isEditMode && (
        <SaveAndCancelButtons
          onSave={handleSave}
          onCancel={handleCancel}
          className="self-end float-right"
          saveButtonDisabled={!isEducationTainted && !isExperienceTainted}
        />
      )}
    </div>
  );
};

export default MemberAnalysisResult;
