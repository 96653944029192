import { FC } from "react";
import { FieldName } from "gql/graphql";
import PureHtml from "atoms/PureHtml";
import withContentEditable from "organisms/ContentEditable/ContentEditable";

interface Props {
  content: string;
  competitorId: string;
}

const Similarity: FC<Props> = ({ content }) => {
  return (
    <div>
      <div className="h-6">
        {/* This div is an empty place holder to make room for the edit icon */}
      </div>
      <PureHtml content={content} tag="p" />
    </div>
  );
};

export default withContentEditable<Props>(
  Similarity,
  FieldName.CompetitorSimilarity
);
