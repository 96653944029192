import { FC } from "react";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import { ProfileAnalytics } from "./Steps";
import { Routes, Route } from "react-router-dom";
import useMySpace from "./Components/hooks/useMySpace";
import CohortApplicationModal from "./Components/CohortApplicationModal/CohortApplicationModal";

const MySpace: FC = () => {
  const { isAllowed, isShowModal, handleCloseModal } = useMySpace();

  if (!isAllowed) {
    return null;
  }

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="w-full mb-4 bg-white sm:relative md:mb-0"
    >
      {isShowModal && <CohortApplicationModal handleClose={handleCloseModal} />}
      <div className="w-full">
        <Routes>
          <Route path="/" element={<ProfileAnalytics />} />
        </Routes>
      </div>
    </Page>
  );
};

export default MySpace;
