// @ts-nocheck
import { twMerge } from "tailwind-merge";
import { forwardRef } from "react";

// @TODO: use real type instead of any
//
export default forwardRef(
  (
    {
      iconPosition,
      onChange,
      defaultValue,
      icon,
      error,
      label,
      labelClassName,
      isInline,
      setValue,
      prefix,
      ...props
    }: any,
    ref
  ) => {
    // const componentProps = _.omit(props, ["setValue"]);
    // React.useEffect(() => {
    //   if (defaultValue) {
    //     setValue(props?.name, defaultValue);
    //   }
    // }, [defaultValue]);
    if (isInline) {
      return (
        <input
          ref={ref}
          onChange={(e) => {
            if (typeof onChange === "function") {
              onChange(e);
            }
          }}
          defaultValue={defaultValue}
          {...props}
        />
      );
    }
    return (
      <>
        {label && (
          <label
            className={twMerge(
              labelClassName,
              "text-opacity-100 text-black dark:text-white block mb-3"
            )}
          >
            {label}
          </label>
        )}
        <label
          className={`flex w-full items-center border rounded p-1 text-black dark:text-white ${
            error
              ? "border-red-300 text-red-900 border-opacity-100"
              : "border-black border-opacity-25 dark:border-white"
          }`}
        >
          {icon && (
            <div
              className={`pl-2 svg-icon ${
                iconPosition === "right" ? "order-1" : "order-0"
              }`}
            >
              {icon}
            </div>
          )}
          {prefix && (
            <span className="inline-block text-gray-400 text-xs md:text-sm ml-1 -mr-1.5">
              {prefix}
            </span>
          )}
          <input
            ref={ref}
            onChange={(e) => {
              if (typeof onChange === "function") {
                onChange(e);
              }
            }}
            defaultValue={defaultValue}
            {...props}
            className="w-full p-2 text-sm text-gray-700 bg-transparent rounded outline-none dark:text-white"
          />
        </label>
        {error && <p className="mt-1 mb-3 text-sm text-fire-400">{error}</p>}
      </>
    );
  }
);
