// @ts-nocheck
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animation } from "ui/animations";
import { ModalAnimation } from "./Modal.style";

export default (props) => props.openModal ? (
    <ModalAnimation
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
    >
      <div className="modal">
        <div className="modal-popup">
          <div className="modal-header">
            {props.title}
            {!props.hideCloseButton && (
              <button
                type="button"
                className="text-black sm:text-white hover:text-gray-300 focus:outline-none transition ease-in-out duration-150"
                aria-label="Close"
                onClick={props.handleCloseModal}
              >
                <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
              </button>
            )}
          </div>
          <div className="modal-body">{props.children}</div>
        </div>
      </div>
    </ModalAnimation>
  ) : null;
