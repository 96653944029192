import { FC } from "react";
import EditablePlaceholderInput from "../reusables/EditablePlaceholderInput";
import HeadOffice from "./HeadOffice";
import { Business, Fundraising, Location, Person, Socials } from "gql/graphql";
import SocialMedia from "./SocialMedia";
import Founders from "./Founders";
import StartupStage from "./StartupStage";
import FormerInvestors from "./FormerInvestors/FormerInvestors";
import Industry from "./Industry";
import Product from "./Product";
import BusinessRelation from "./BusinessRelation";
import TargetAndCurrency from "./TargetAndCurrency";
import InvestmentClosingDate from "./InvestmentClosingDate";

interface Props {
  fundraising: Fundraising | undefined | null;
  investors: Person[] | undefined | null;
  website: string | undefined | null;
  socials: Socials | undefined | null;
  founders: Person[] | undefined | null;
  location: Location | undefined | null;
  business: Business | undefined | null;
  currencyCode: string;
}

const InvestmentSummary: FC<Props> = ({
  fundraising,
  investors,
  website,
  socials,
  founders,
  location,
  business,
  currencyCode,
}) => {
  return (
    <div className="bg-slate-100 w-full p-2 mt-4">
      <div className="font-bold text-xl mb-4 mt-2">Investment Summary</div>
      <div className="grid grid-cols-2 gap-2 mb-2 lg:grid-cols-5">
        {/* Currency selection is handled only once in TargetAndCurrency component and used for all other components */}
        {/* Note that other instances of currency from api are ignored for now until the api is inproved. (It must accept one cuerrncy and use it for all other cases) */}
        <TargetAndCurrency
          target={fundraising?.target}
          currencyCode={currencyCode}
        />
        <EditablePlaceholderInput
          title="Ticket size"
          value={fundraising?.ticketSize}
          currencyCode={currencyCode}
          showCurrency
          fieldName="fundraising.ticketSize"
          inputType="number"
        />
        <EditablePlaceholderInput
          title="Valuation"
          value={fundraising?.valuation}
          currencyCode={currencyCode}
          showCurrency
          fieldName="fundraising.valuation"
          inputType="number"
        />
        <EditablePlaceholderInput
          title="Committed"
          value={fundraising?.committed}
          currencyCode={currencyCode}
          showCurrency
          fieldName="fundraising.committed"
          inputType="number"
        />
        <EditablePlaceholderInput
          title="Desired investors"
          value={fundraising?.beyondCapital}
          fieldName="fundraising.beyondCapital"
          className="col-span-2 lg:col-span-1"
        />
      </div>

      <div className="flex flex-col gap-y-2 lg:flex-row lg:gap-x-4">
        <div className="flex flex-col gap-y-2 basis-1/2">
          <EditablePlaceholderInput
            title="Website"
            value={website}
            style="secondary"
            fieldName="website"
          />

          <HeadOffice location={location} />

          <EditablePlaceholderInput
            title="Fundraising History"
            value={fundraising?.raised}
            style="secondary"
            fieldName="fundraising.raised"
            currencyCode={currencyCode}
            showCurrency
            inputType="number"
            // Hiding tooltip until furthur notice
            // tooltipContent={
            //   fundraising?.raisedFrom && (
            //     <span>
            //       Previously Raised From &nbsp;
            //       {Object.values(fundraising?.raisedFrom)
            //         ?.map((key) => {
            //           if (key) return RAISEDFROM[key];
            //         })
            //         .join(", ")}
            //     </span>
            //   )
            // }
          />

          <SocialMedia socials={socials} />
        </div>

        <div className="flex flex-col gap-y-2 basis-1/2">
          <Industry industries={business?.industries} />

          <Product
            categories={business?.categories}
            categoriesNote={business?.categoriesNote}
          />

          <BusinessRelation
            models={business?.models}
            modelsNote={business?.modelsNote}
          />

          <Founders founders={founders} />

          <StartupStage phase={fundraising?.phase} />

          <InvestmentClosingDate closingDate={fundraising?.closeOn} />
        </div>
      </div>
      <FormerInvestors investors={investors} />
    </div>
  );
};

export default InvestmentSummary;
