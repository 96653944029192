// @ts-nocheck
import { MAJORSTEPS } from "core/consts";
import { initialState, name } from "./reducer";

const user = (state) => state[name] || initialState;

export const getActiveCohort = (state) => user(state).activeCohort;

export const getCohort = (state) => user(state).cohort;

export const getUser = (state) => user(state)["userData"];

export const getUserType = (state) => user(state)["userData"].type;

export const getIsApplicationSent = (state) =>
  user(state)["userData"]?.completedMajorSteps?.includes(
    MAJORSTEPS.APPLICATION_SENT
  );

export const getWorkspaces = (state) => user(state)["userData"].workspaces;

export const isLoggedinUser = (state) => user(state)["isLoggedInUser"];

export const isUserLinkRemoved = (state) => user(state)?.isLinkRemoved;

export const getUserLocation = (state) => user(state)?.location;

export const getCurrencyList = (state) => user(state)?.currencyList;

export const getUserHidden = (state) => user(state)?.hidden;

export const getUserSubscription = (state) => user(state)?.subscription;
