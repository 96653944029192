import { FC, useEffect, useState } from "react";
import Joyride from "react-joyride";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ActivationProps, OpenSection } from "../types";
import Chart from "images/chart.png";
import { Tooltip } from "../Tooltips/Tooltip";

const AnalyticsActivation: FC<ActivationProps> = ({
  activationStatus,
  setActivationStatus,
  setIsMinimized,
  setOpenSection,
}) => {
  const [runActivationTour, setRunActivationTour] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      disableBodyScroll(document.body);
      setIsMinimized(true);
      setRunActivationTour(true);
    }, 1000);

    return () => {
      enableBodyScroll(document.body);
    };
  }, []);

  const getNextSection = (): OpenSection => {
    if (!activationStatus.Dealflow) return "dealflow";
    return "none";
  };

  return (
    <Joyride
      run={runActivationTour}
      continuous
      steps={[
        {
          target: "body",
          content: (
            <div className="text-white bg-gradient-to-r from-[#18BEB3] to-[#05999F] -mb-px min-w-[20rem]">
              <div className="p-6 bg-white">
                <img src={Chart} className="m-auto" />
              </div>
              <h3 className="px-5 mt-4 text-xl">
                Contact the right investors at the right time
              </h3>
              <p className="text-white max-w-[26rem] mt-2 px-5">
                See who visited your profile and when. You can share your
                profile with investors that do not have a Sircular account. They
                will be able to access your profile, and you will be able to
                track what they did, how many times they visited your profile,
                and whether they shared the link with anyone else.
              </p>
            </div>
          ),
          placement: "center",
          disableBeacon: true,
          spotlightClicks: false,
        },
        {
          target: "#create-link-button",
          content: (
            <div className="text-white bg-gradient-to-r from-[#18BEB3] to-[#05999F] -mb-px pt-5 px-5">
              <h3 className="text-xl ">Unique profile links</h3>
              <p className="text-white max-w-[24rem] mt-2">
                Click here to create and share a unique link with an investor so
                you can track when they have visited your profile and how many
                times.
              </p>
            </div>
          ),
          placement: "left",
          disableBeacon: true,
          spotlightClicks: false,
        },
        {
          target: "#analytics-page-settings-button",
          content: (
            <div className="text-white bg-gradient-to-r from-[#18BEB3] to-[#05999F] -mb-px pt-5 px-5">
              <h3 className="text-xl">Profile access</h3>
              <p className="text-white max-w-[24rem] mt-2">
                Make your profile accessible only to people who enter their info
                so you can track who has viewed your profile
              </p>
            </div>
          ),
          placement: "bottom-end",
          disableBeacon: true,
          spotlightClicks: false,
        },
      ]}
      beaconComponent={undefined}
      tooltipComponent={Tooltip}
      disableOverlayClose={true}
      scrollToFirstStep={true}
      showSkipButton={true}
      styles={{
        options: {
          overlayColor: "rgba(0, 0, 0, 0.68)",
          zIndex: 1000,
          arrowColor: "transparent",
        },
        spotlight: {
          borderRadius: 100,
        },
      }}
      callback={({ status }) => {
        if (status === "finished") {
          setActivationStatus({ BoardsOrAnalytics: true });
          setIsMinimized(false);
          setOpenSection(getNextSection());
          enableBodyScroll(document.body);
        } else if (status === "skipped") {
          setActivationStatus({ BoardsOrAnalytics: true });
          enableBodyScroll(document.body);
        }
      }}
    />
  );
};

export default AnalyticsActivation;
