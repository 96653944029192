import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { FC, Fragment, useRef } from "react";
import Carousel from "./VisualsCarousel";
import { Visual } from "./interfaces";
import EmbedVideo from "atoms/EmbedVideo";

interface ProductVisualsModalProps {
  productImages: string[];
  productDemo?: string;
  isVisualsModalOpen: boolean;
  defaultIndex: number;
  handleCloseVisuals: () => void;
}

const ProductVisualsModal: FC<ProductVisualsModalProps> = ({
  productImages,
  isVisualsModalOpen,
  productDemo,
  defaultIndex,
  handleCloseVisuals,
}: ProductVisualsModalProps) => {
  const cancelButtonRef = useRef(null);
  const formattedImages: Visual[] = productImages.map((source) => ({
    source,
    type: "IMAGE",
  }));

  const productVisuals: Visual[] = formattedImages;

  if (productDemo) {
    productVisuals.unshift({
      source: productDemo,
      type: "VIDEO",
    });
  }

  return (
    <Transition.Root show={isVisualsModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 overflow-hidden"
        initialFocus={cancelButtonRef}
        onClose={handleCloseVisuals}
        static
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto rounded-lg">
          <div className="flex items-end justify-center p-0 text-center sm:items-center sm:py-12">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full p-20 overflow-y-auto text-left transition-all transform bg-gray-800 rounded-md shadow-xl h-[800px] sm:w-11/12 sm:max-h-full no-scrollbar invisible-scrollbar invisible-scroll ">
                <div className="h-auto overflow-hidden">
                  <Carousel
                    defaultIndex={defaultIndex}
                    children={productVisuals.map((visual, index) => (
                      <div className="block h-full w-auto mx-auto max-h-[481px] object-contain rounded-md">
                        {visual.type === "VIDEO" && (
                          <EmbedVideo
                            source={visual.source}
                            isModal={true}
                            key={index}
                          />
                        )}

                        {visual.type === "IMAGE" && (
                          <img
                            key={index}
                            src={visual.source}
                            alt="product-image"
                            className="block mx-auto w-auto h-full max-h-[481px] object-contain rounded-md"
                          />
                        )}
                      </div>
                    ))}
                  />
                  <div className="absolute top-8 right-8">
                    <span
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={handleCloseVisuals}
                      ref={cancelButtonRef}
                    >
                      <FontAwesomeIcon
                        icon={["fal", "times"]}
                        size="2x"
                        className="text-white"
                      />
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProductVisualsModal;
