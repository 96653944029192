import { gql } from "@apollo/client";

export const WORKSPACES = gql`
  fragment Workspaces on Me {
    workspaces {
      id
      isActive
      inviter {
        id
        name
        email
        logo {
          small {
            location
          }
          large {
            location
          }
        }
      }
    }
  }
`;

export const GET_ME = gql`
  ${WORKSPACES}
  query getMe {
    me {
      __typename
      id
      _id
      createdAt
      slug
      anonymousSlug
      email
      website
      displayName
      firstName
      lastName
      description
      fullAddress
      referrer {
        email
      }
      hidden
      type
      isAdmin
      isCompany
      isInvestor
      meetingLink
      logo {
        large {
          location
        }
      }
      founders {
        id
        name
        email
        linkedin
      }
      pitch
      completedMajorSteps
      signUpRequestSent
      isAccelerator
      isOnboarding
      parentId
      isAnalyticTrial
      publicUrl
      subscription {
        id
        status
        plan {
          title
        }
      }
      tier
      limits {
        contact {
          remaining
        }
      }
      investorType
      countriesInvestIn {
        countries
        regions
      }
      company {
        aiaTrial {
          isOver
          totalUploads
        }
      }
      ...Workspaces
    }
  }
`;

export const GET_COHORT_BY_SLUG = gql`
  query getCohortBySlug($slug: String!) {
    getCohortBySlug(slug: $slug) {
      id
      slug
      name
      header
      description
      closedHeader
      closedDescription
      isOpen
      isInvitation
      logo {
        large {
          location
        }
      }
      poster {
        original {
          location
        }
      }
      isOpen
      registrationType
      owner {
        displayName
      }
    }
  }
`;

export const GET_ACTIVE_COHORT = gql`
  query getActiveCohort {
    activeCohort {
      name
      slug
      logo {
        large {
          location
        }
      }
    }
  }
`;

export const GET_CURRENCY_LIST = gql`
  query getCurrenciesList {
    getCurrenciesList {
      _id
      isDefault
      code
      symbol
      toEurRatio
    }
  }
`;

export const UPLOAD_LOGO = gql`
  mutation updateCompanyProfileImage($file: Upload!) {
    updateCompanyProfileImage(file: $file) {
      _id
      logo {
        large {
          location
        }
      }
    }
  }
`;
