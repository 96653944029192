import { FC } from "react";
import PureHtml from "atoms/PureHtml";

interface Props {
  pitch?: string;
  tractionSummary?: string;
}

const ExecutiveSummary_Regular: FC<Props> = ({ pitch, tractionSummary }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 my-2 align-top dark:text-white/80">
      <>
        {pitch && (
          <div className="flex flex-col max-w-2xl">
            <h2 className="items-center mb-2 text-lg">Elevator Pitch</h2>
            <p className="text-[#24272B] dark:text-gray-400 font-normal mb-2">
              <PureHtml content={pitch} tag="p" />
            </p>
          </div>
        )}

        {tractionSummary && (
          <div className="flex flex-col max-w-2xl">
            <h2 className="items-center mb-2 text-lg">Traction</h2>
            <p className="text-[#24272B] dark:text-gray-400 font-normal mb-2">
              <PureHtml content={tractionSummary} tag="p" />
            </p>
          </div>
        )}
      </>
    </div>
  );
};

export default ExecutiveSummary_Regular;
