import NotesSidebar from "pages/dealflow/components/Sidebar/NotesSidebar";
import type { FC } from "react";
import { pageAnimation } from "ui/animations";
import { Page } from "ui/elements/PanelLayout.styles";
import { FilterPanel } from "./FilterPanel";
import PreviewSidebar from "./Sidebar/PreviewSidebar";
import { TableView } from "./TableView";
import useDealflow from "./useDealflow";
import InvestorRecordModal from "./RecordModal/InvestorRecordModal";
import { BottomPanel } from "./BottomPanel";
import DealflowActivationWrapper from "organisms/Activation/Dealflow/DealflowActivationWrapper";

const StartupDealflow: FC = () => {
  const {
    isLoading,
    isSearchEmpty,
    isNoResults,
    isAddModal,
    isShowModal,
    showingFrom,
    showingTo,
    total,
    totalPages,
    page,
    columns,
    records,
    sections,
    sort,
    query,
    selectedRecord,
    previewRecord,
    editRecord,
    refetch,
    handlePageChange,
    handleQueryChange,
    handleSortChange,
    handleSectionsChange,
    handleInvestorTypeChange,
    handleSelect,
    handleCloseSidebar,
    handleClosePreview,
    handleOpenAddModal,
    handleCloseAddModal,
    handleOpenModal,
    handleCloseModal,
    handleEdit,
  } = useDealflow();

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="w-full bg-white h-screen"
    >
      <div className="w-full h-full md:pb-0 flex flex-col justify-between">
        <FilterPanel
          query={query}
          sections={sections}
          onQueryChange={handleQueryChange}
          onAddInvestor={handleOpenAddModal}
          onSectionsChange={handleSectionsChange}
          onShowModal={handleOpenModal}
          isShowModal={isShowModal}
          onCloseModal={handleCloseModal}
          onInvestorChange={handleInvestorTypeChange}
        />
        <InvestorRecordModal
          refetch={refetch}
          show={isAddModal}
          onClose={handleCloseAddModal}
          record={editRecord ?? null}
        />
        {/* Empty div for placement of the activation tooltip */}
        <div className="dealflow-table" />
        <div className="flex w-full invisible-scroll grow">
          <TableView
            columns={columns}
            records={records}
            sections={sections}
            sort={sort}
            preview={previewRecord}
            selected={selectedRecord}
            onSelect={handleSelect}
            refetch={refetch}
            onSort={handleSortChange}
            isSearchEmpty={isSearchEmpty}
            isNoResults={isNoResults}
            onEdit={handleEdit}
          />
          <PreviewSidebar record={previewRecord} onClose={handleClosePreview} />
          <NotesSidebar
            sections={sections}
            record={selectedRecord}
            onClose={handleCloseSidebar}
            refetch={refetch}
            isStatusHidden
            link={
              selectedRecord
                ? `/investor/public/${selectedRecord.company.slug}?us=dealflow`
                : "#"
            }
          />
        </div>
        <BottomPanel
          isLoading={isLoading}
          onPageChange={handlePageChange}
          page={page}
          showingFrom={showingFrom}
          showingTo={showingTo}
          total={total}
          totalPages={totalPages}
        />
      </div>
      <DealflowActivationWrapper userType="company" />
    </Page>
  );
};

export default StartupDealflow;
