// @ts-nocheck
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  StepsSidebar,
  StickyWrapper,
  StepsRightSidebar,
} from "ui/elements/PanelLayout.styles";
import CompanySteps from "./components/CompanySteps";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import SidebarToggleIcon from "images/sidebar-toggle-icon.png";
import RightSideContent from "./components/RightSideContent";
import MarketplaceVisibleModal from "organisms/MarketplaceVisibleModal/MarketplaceVisibleModal";
import { STEPS } from "./constants";
import { useLang } from "core/lang/LangaugeSetting";
import * as slice from "./reducer";
import { History } from "core/utility";
import { initializeCompanySettings } from "./sagaActions";
import { getUser, getCohort } from "models/user/selectors";
import { refetchUserData } from "models/user/sagaActions";
import { getCurrentStep, getIsApplicationSubmitSuccess } from "./selectors";

export default () => {
  const { lang } = useLang();
  const dispatch = useDispatch();

  /* States */
  const [height, setHeight] = useState(false);
  const [showRightContent, setShowRightContent] = useState(false);

  /* Selectors */
  const currentStep = useSelector(getCurrentStep);
  const isApplicationSubmitSuccess = useSelector(getIsApplicationSubmitSuccess);
  const user = useSelector(getUser);
  const invitation = useSelector((state: any) => state?.invitation);

  /* Effects */
  useEffect(() => {
    dispatch(initializeCompanySettings({ lang }));
  }, []);

  /* Handlers */
  const getHeight = async () => {
    var scrollHeight = false;
    if (window.innerWidth <= 768) {
      if ((await window.scrollY) >= 70) {
        scrollHeight = true;
      }
    } else if (window.innerWidth > 768) {
      if ((await window.scrollY) >= 70) {
        scrollHeight = true;
      }
    }
    setHeight(scrollHeight);
  };

  let windowWidth = null;
  if (window.innerWidth > 768) {
    windowWidth = (window.innerWidth - 1280) / 2;
  }

  window.addEventListener("scroll", getHeight);

  const handleCloseModal = () => {
    dispatch(slice.updateApplicationSubmitSuccess({ isShown: false }));
    dispatch(refetchUserData()); // to get the updated isApplicationSent flag
    History.navigate("/company-profile");
  };

  const cohortData = useSelector(getCohort);

  const showSuccessScreen = () => (
    <MarketplaceVisibleModal
      handleCloseModal={handleCloseModal}
      acceleratorName={cohortData?.owner.displayName}
    />
  ); //accelerator startup

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      // initial="hidden"
      animate="show"
      className="w-full bg-white bottom-icon h-[calc(100vh-80px)]"
    >
      {isApplicationSubmitSuccess ? (
        showSuccessScreen()
      ) : (
        <div className="container flex flex-col h-full mx-auto bg-white lg:flex-row">
          <StepsSidebar>
            <StickyWrapper className="teaser-steps" height={height}>
              <CompanySteps />
            </StickyWrapper>
          </StepsSidebar>
          <AnimatePresence exitBeforeEnter={true}>
            {STEPS[currentStep]}
          </AnimatePresence>
          <StepsRightSidebar>
            <StickyWrapper className="instruction-section">
              {!showRightContent && (
                <RightSideContent
                  height={height}
                  closeRightContent={() =>
                    setShowRightContent(!showRightContent)
                  }
                />
              )}
              <img
                src={SidebarToggleIcon}
                className={`bottom-0 lg:block hidden right-0 cursor-pointer w-16 ${
                  !showRightContent ? "absolute" : "fixed"
                }`}
                style={{
                  right: !showRightContent ? -20 : windowWidth! - 4,
                  bottom: !showRightContent ? -85 : 0,
                }}
                onClick={() => setShowRightContent(!showRightContent)}
              />
            </StickyWrapper>
          </StepsRightSidebar>
        </div>
      )}
    </Page>
  );
};
