import { useEffect, useRef } from "react";
import { useProfileNavigationContext } from "./context";
import { SectionId } from "./constants";

const useProfileNavigation = () => {
  const { observer, availableSections } = useProfileNavigationContext();
  const profileSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (profileSectionRef.current) {
      observer.observe(profileSectionRef.current);
      availableSections.set(
        profileSectionRef.current.id as SectionId,
        profileSectionRef.current
      );
    }
  });

  return {
    profileSectionRef,
  };
};

export default useProfileNavigation;
