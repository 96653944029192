import { ApolloClient } from "@apollo/client";
import { InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { graphqlApiEndpoint } from "./config";

export const getClient = () =>
  new ApolloClient({
    credentials: "include",
    link: createUploadLink({
      uri: graphqlApiEndpoint,
      credentials: "include",
      headers: {
        "x-localization": localStorage.getItem("language"),
      },
    }),
    cache: new InMemoryCache({ addTypename: false }),
  });

export const History = {
  navigate: null,
  push: (page, ...rest) => History.navigate(page, ...rest),
};

export const convertStringToBool = (str) => str === "True" || str === "true" ? true : false;
