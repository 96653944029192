import { gql } from "@apollo/client";

export const GET_PLANS_BY_USER = gql`
  query getPlansByUserType {
    plansByUserType {
      id
      title
      description
      userType
      isMostPopular
      tagline
      features
      prices {
        id
        price
        discount
        priceBeforeDiscount
        interval
        currency
      }
    }
  }
`;

export const GET_PAYMENT_SESSION_URL = gql`
  query getPaymentPageUrl($priceId: String!) {
    paymentPageUrl(priceId: $priceId)
  }
`;
