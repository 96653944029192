import { FC, useState } from "react";
import { Socials } from "gql/graphql";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { useForm } from "react-hook-form";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch } from "react-redux";
import { URL_PATTERN } from "core/consts";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
interface Props {
  socials: Socials | undefined | null;
}

const SocialMedia: FC<Props> = ({ socials }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const { lang } = useLang();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Socials>({ shouldUnregister: true });

  const onSubmit = (data: Socials) => {
    dispatch(updateStartupProfile({ socials: data }));
    setIsEditMode(false);
  };

  const renderEditModeContent = () => (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center border p-1 bg-white rounded">
          <LinkedinIcon className="mx-2" />
          <input
            id="linkedin"
            type="text"
            className="min-w-0 grow bg-white"
            defaultValue={socials?.linkedin || ""}
            {...register("linkedin", { pattern: URL_PATTERN })}
          />
        </div>
        {errors.linkedin && (
          <span className="text-sm text-red-400">{lang.websiteValidation}</span>
        )}

        <div className="flex items-center border p-1 bg-white rounded mt-2">
          <FacebookIcon className="mx-2" />
          <input
            id="facebook"
            type="text"
            className="min-w-0 grow bg-white"
            defaultValue={socials?.facebook || ""}
            {...register("facebook", { pattern: URL_PATTERN })}
          />
        </div>
        {errors.facebook && (
          <span className="text-sm text-red-400">{lang.websiteValidation}</span>
        )}

        <div className="flex items-center border p-1 bg-white rounded mt-2">
          <TwitterIcon className="mx-2" />
          <input
            id="twitter"
            type="text"
            className="min-w-0 grow bg-white"
            defaultValue={socials?.twitter || ""}
            {...register("twitter", { pattern: URL_PATTERN })}
          />
        </div>
        {errors.twitter && (
          <span className="text-sm text-red-400">{lang.websiteValidation}</span>
        )}

        <div className="flex items-center border p-1 bg-white rounded mt-2">
          <InstagramIcon className="mx-2" />
          <input
            id="instagram"
            type="text"
            className="min-w-0 grow bg-white"
            defaultValue={socials?.instagram || ""}
            {...register("instagram", { pattern: URL_PATTERN })}
          />
        </div>
        {errors.instagram && (
          <span className="text-sm text-red-400">{lang.websiteValidation}</span>
        )}

        <div className="flex items-center border p-1 bg-white rounded mt-2">
          <YoutubeIcon className="mx-2" />
          <input
            id="youtube"
            type="text"
            className="min-w-0 grow bg-white"
            defaultValue={socials?.youtube || ""}
            {...register("youtube", { pattern: URL_PATTERN })}
          />
        </div>
        {errors.youtube && (
          <span className="text-sm text-red-400">{lang.websiteValidation}</span>
        )}

        <SaveAndCancelButtons
          onCancel={() => setIsEditMode(false)}
          onSave={() => undefined} // onSubmit will be executed on save click
          className="float-right mt-2"
        />
      </form>
    </div>
  );

  const renderContent = () => {
    return (
      <div className="flex gap-x-2">
        {socials?.linkedin && (
          <a href={socials.linkedin} rel="noreferrer noopener" target="_blank">
            <LinkedinIcon />
          </a>
        )}
        {socials?.facebook && (
          <a href={socials.facebook} rel="noreferrer noopener" target="_blank">
            <FacebookIcon />
          </a>
        )}
        {socials?.twitter && (
          <a href={socials.twitter} rel="noreferrer noopener" target="_blank">
            <TwitterIcon />
          </a>
        )}
        {socials?.instagram && (
          <a href={socials.instagram} rel="noreferrer noopener" target="_blank">
            <InstagramIcon />
          </a>
        )}
        {socials?.youtube && (
          <a href={socials.youtube} rel="noreferrer noopener" target="_blank">
            <YoutubeIcon />
          </a>
        )}
      </div>
    );
  };

  return (
    <EditablePlaceholder
      title="Social Media"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={
        socials && Object.values(socials).filter((i) => i).length > 0
          ? renderContent
          : undefined
      }
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default SocialMedia;
