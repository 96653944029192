import { FC } from "react";
import ProductVisuals from "molecules/ProductVisuals";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import Description from "./Description";
import ValueProposition from "./ValueProposition";
import Customers from "./Customers";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";

interface Props {
  description: string;
  valueProposition: string;
  customers: string;
  productDemo: string;
  productImages: any[];
  isProfileGeneratedByAIA?: boolean;
}

const Product: FC<Props> = ({
  description,
  valueProposition,
  customers,
  productDemo,
  productImages,
}) => {
  const { profileSectionRef } = useProfileNavigation();

  return (
    <div id={PROFILE_SECTION_IDS.Product} ref={profileSectionRef}>
      {(description ||
        valueProposition ||
        customers ||
        productDemo ||
        productImages?.length > 0) && (
        <div className="overflow-hidden dark:text-white/80 my-2 aiaProfile">
          <SectionHeader title="Product" />

          <div className="flex flex-col lg:flex-row">
            <div className="w-full whitespace-pre-wrap dark:text-gray-400 flex-grow mb-4 lg:mb-0 border- border-red-400">
              {description && <Description content={description} />}
              {valueProposition && (
                <ValueProposition content={valueProposition} />
              )}
              {customers && <Customers content={customers} />}
            </div>

            {(productDemo || productImages?.length > 0) && (
              <ProductVisuals
                productDemo={productDemo}
                productImages={productImages?.map(
                  (image) => image.large.location
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
