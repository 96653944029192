import { GENDERS } from "core/consts";
import { useLang } from "core/lang/LangaugeSetting";
import SingleSelectBase from "molecules/Form/SingleSelect";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";

const SingleSelect: any = SingleSelectBase; // to suppress ts errors

const SELECT_OPTIONS = Object.values(GENDERS);

const getGenderValue = (name: string) =>
  Object.keys(GENDERS).find(
    (key) => GENDERS[key as keyof typeof GENDERS] === name
  );

interface NameGenderFieldProps {
  icon: JSX.Element;
  label: string;
  control: Control;
  fieldName: string;
  index: number;
}

const NameGenderField: FC<NameGenderFieldProps> = ({
  label,
  icon,
  control,
  fieldName,
  index,
}) => {
  const { lang } = useLang();
  const error = false;

  return (
    <div>
      <label className={"text-opacity-100 mb-1 block text-sm font-medium"}>
        {label}
      </label>
      <div
        className={[
          "flex items-center relative border rounded pb-1 pt-1",
          error
            ? "border-red-300 text-red-900 border-opacity-1"
            : "text-black border-black border-opacity-25",
        ].join(" ")}
      >
        {icon && <div className="px-2 svg-icon">{icon}</div>}
        <div
          style={{
            order: 2,
            marginRight: 10,
          }}
        >
          <Controller
            control={control}
            name={`${fieldName}.${index}.gender`}
            render={({ field }) => (
              <SingleSelect
                options={SELECT_OPTIONS}
                label={SELECT_OPTIONS[0]}
                defaultValue={field.value}
                selectDropdownInput={true}
                onChange={(value: string) =>
                  field.onChange(getGenderValue(value))
                }
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name={`${fieldName}.${index}.name`}
          render={({ field }) => (
            <>
              <input
                className="w-full p-2 pl-0 text-sm text-gray-700 placeholder-gray-800 bg-transparent rounded outline-none transition-all duration-200 ease-in-out"
                value={field.value}
                onChange={({ target }) => field.onChange(target.value)}
                placeholder={lang.enterName}
                style={{
                  marginLeft: 7,
                  outline: "none",
                }}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default NameGenderField;
