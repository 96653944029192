import type { FC } from "react";
import type { FilterPanelProps } from "./interfaces";
import Button from "atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiSelect from "atoms/Form/MultiSelect";
import EditStatusModal from "pages/dealflow/InvestorDealflow/components/EditStatusModal";
import { INVESTOR_TYPES } from "core/consts";

const FilterPanel: FC<FilterPanelProps> = ({
  query,
  isShowModal,
  sections,
  onQueryChange,
  onSectionsChange,
  onInvestorChange,
  onAddInvestor,
  onShowModal,
  onCloseModal,
}) => (
  <div className="flex items-center justify-between flex-col md:flex-row mx-2 my-3 md:mx-0 flex-wrap">
    <div className="flex flex-col sm:flex-row sm:gap-1 items-center py-3 w-full max-w-[40rem]">
      <input
        placeholder="Search..."
        value={query}
        onChange={({ target }) => onQueryChange(target.value)}
        className="w-full px-2 py-1.5 mt-2 mb-0 text-sm border border-gray-200 rounded-md shadow-sm sm:mt-1 md:w-52 focus:border-gray-400 md:mr-0 md:mb-0"
      />
      <div className="flex w-full md:w-auto">
        <div className="z-40 w-full md:w-52 dealflow-multi-select dealflow-status-filter">
          <MultiSelect
            label="All statuses"
            name="statuses"
            showChecked
            checkMark
            options={sections.map((section) => ({
              key: section.id,
              value: section.title,
            }))}
            onChange={onSectionsChange}
            resetText="All statuses"
            additionalOptions={[
              {
                onClick: onShowModal,
                content: (
                  <span className="flex items-center">
                    <FontAwesomeIcon icon={["fal", "pencil"]} />
                    <span className="ml-2 font-normal whitespace-nowrap">
                      Edit your statuses
                    </span>
                  </span>
                ),
              },
            ]}
          />
        </div>
        {isShowModal && <EditStatusModal onClose={onCloseModal} />}
      </div>
      <div className="flex w-full md:w-auto">
        <div className="z-40 w-full md:w-52 dealflow-multi-select">
          <MultiSelect
            label="Investor type"
            name="investorType"
            showChecked
            checkMark
            onChange={onInvestorChange}
            options={Object.entries(INVESTOR_TYPES).map(([key, value]) => ({
              key,
              value,
            }))}
            resetText="Investor type"
          />
        </div>
      </div>
    </div>

    <div className="flex items-center justify-center w-full md:w-auto mb-3 lg:mb-0 md:justify-end md:mr-2">
      <Button
        onClick={onAddInvestor}
        className="flex w-full max-w-sm justify-center"
        size="small"
        color="primary"
        id="add-investor-button"
      >
        <span className="mr-1 truncate">Add Investor</span>
        <FontAwesomeIcon icon={["fas", "plus"]} className="ml-1" />
      </Button>
    </div>
  </div>
);

export default FilterPanel;
