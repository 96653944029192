import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrashIcon from "@heroicons/react/20/solid/TrashIcon";
import Button from "atoms/Button/Button";
import TextArea from "atoms/Form/TextArea";
import {
  AiaMutationAddContextAndReloadArgs,
  AiaPutTaskResult,
  AiaTaskStatus,
  AiaVisionAiResult,
  GetAiaTaskQuery,
  GetAiaTaskQueryVariables,
} from "gql/graphql";
import { ReactComponent as LoadingSpinner } from "images/loading-spinner.svg";
import { setGlobalState } from "models/globalState/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import {
  refetchAnalysisData,
  refetchCompanyData,
} from "pages/companyProfile/sagaActions";
import { GET_AIA_TASK } from "queries/aia/task";
import { FC, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { ADD_CONTEXT, VISION_AI } from "../../../../aia/queries";
import { createImages } from "../../../../aia/utils";
import AddedContextLoader from "./AddedContextLoader";
import Tooltip from "atoms/Tooltip/Tooltip";
import { mixpanelAiaAddContext } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";

// Investors can add a file, context or both.
// Adding a file will result in the context being added to the addContext mutation even though it is empty.
// This is why we check if(context && !file) so that we can safely upload when it is just context and also why we return after each of mutations have been completed
// If the file is not a pdf, we return early and show an error message
// The AddedContextLoader component is shown when the task status for addedContext is completed and show which fields have been updated by checking addedContextLoaded statuses

const LIB_CDN_SRC =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.min.js";

declare const pdfjsLib: any;

interface AddContextModalProps {
  companyId: string;
  slug: string;
  onClose: () => void;
}

const AddContextModal: FC<AddContextModalProps> = ({
  companyId,
  slug,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [context, setContext] = useState("");
  const [isNewContextLoaded, setIsNewContextLoaded] = useState(false);
  const [parsingError, setParsingError] = useState(false);
  const [isLibLoading, setIsLibLoading] = useState(true);
  const [isCreatingImages, setIsCreatingImages] = useState(false);
  const [isParsing, setIsParsing] = useState(false);

  const user = useSelector(getUser);

  const removeFile = () => {
    setSelectedFile(null);
    setParsingError(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
      setParsingError(false);
    },
  });

  const [
    addContext,
    { data: newContext, loading: isApiLoading, error: addContextError },
  ] = useMutation<
    { aia: { addContextAndReload: AiaPutTaskResult } },
    AiaMutationAddContextAndReloadArgs
  >(ADD_CONTEXT);

  const {
    data: taskData,
    loading: isTaskLoading,
    startPolling,
    stopPolling,
  } = useQuery<GetAiaTaskQuery, GetAiaTaskQueryVariables>(GET_AIA_TASK, {
    skip: !newContext?.aia?.addContextAndReload?.id,
    variables: {
      id: newContext?.aia?.addContextAndReload?.id,
    },
  });

  const [visionAi, { loading: isVisionLoading, error: visionError }] =
    useMutation<{
      aia: { visionAI: AiaVisionAiResult };
    }>(VISION_AI);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = LIB_CDN_SRC;
    script.async = true;
    script.onload = () => setIsLibLoading(false);

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const task = taskData?.aia?.task;

    if (task?.status === AiaTaskStatus.Completed) {
      stopPolling();
      mixpanelAiaAddContext(user);
      setIsNewContextLoaded(true);
    }

    if (task?.status === AiaTaskStatus.Failed) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Analysis failed",
          message: "Please try again later",
        })
      );
      stopPolling();
    }
    if (task?.status === AiaTaskStatus.Cancelled) {
      stopPolling();
    }
  }, [taskData?.aia?.task?.status]);

  const contextLoadingStatus = taskData?.aia?.task?.payload;

  useEffect(() => {
    dispatch(refetchCompanyData({ slug }));
    dispatch(refetchAnalysisData({ id: companyId }));
  }, [contextLoadingStatus?.addedContext]);

  const onHelpClicked = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  const handleUpload = async (file?: File | null, context?: string) => {
    setParsingError(false);

    if (file) {
      if (file.type !== "application/pdf") {
        dispatch(
          triggerSnack({
            type: "error",
            message: "File type must be PDF.",
          })
        );

        return;
      }

      setIsParsing(true);

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result as ArrayBuffer);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        let contents = "";

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();

          if (textContent.items.length === 0) continue;

          contents += textContent.items
            .filter((item: any) => "str" in item)
            .map((item: any) => item.str)
            .join(" ");
          contents += "\n";
        }

        setIsParsing(false);

        try {
          setIsCreatingImages(true);
          const images = await createImages(pdf);

          if (!images) {
            setParsingError(true);
            return;
          }

          setIsCreatingImages(false);

          const text = await visionAi({
            variables: {
              images: images,
            },
          });

          if (!text?.data?.aia?.visionAI?.text) {
            setParsingError(true);
            return;
          }

          if (text?.data?.aia?.visionAI?.text) {
            await addContext({
              variables: {
                companyId: companyId,
                contents: text?.data?.aia?.visionAI?.text + " " + context,
              },
            });

            startPolling(10000);
          }
          return;
        } catch (e) {
          setParsingError(true);
        }
      };
    }
    if (context && !file) {
      await addContext({
        variables: {
          companyId: companyId,
          contents: context,
        },
      });

      startPolling(10000);
      return;
    }
  };

  const isRegenerating =
    isParsing ||
    isApiLoading ||
    isTaskLoading ||
    taskData?.aia?.task?.status === AiaTaskStatus.InProgress;

  if (isRegenerating || isVisionLoading || isCreatingImages) {
    return (
      <div className="fixed right-4 z-50 bg-gradient-to-r mt-4 w-[25rem] rounded-md from-[#3B897A] to-[#18BEB3] flex p-4 text-center items-center">
        <p className="text-white text-base font-medium mx-auto">
          {isVisionLoading || isCreatingImages
            ? "Analyzing the document..."
            : "Adding context to profile"}
        </p>

        <LoadingSpinner className="animate-spin" />
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 right-0 h-screen z-50">
      {isNewContextLoaded ? (
        <div className="bg-gradient-to-r mt-4 w-full sm:w-[400px] mx-auto rounded-md from-[#3B897A] to-[#18BEB3] flex p-4 text-center items-center">
          <button
            className="absolute top-8 right-5 text-white"
            onClick={onClose}
          >
            <FontAwesomeIcon className="text-xl" icon={["fal", "times"]} />
          </button>
          <AddedContextLoader contextTask={taskData?.aia?.task?.payload} />
        </div>
      ) : (
        <div className="fixed top-0 right-0 h-screen sm:w-[446px] bg-white py-4 px-6 rounded-l-md border border-gray-400 my-auto backdrop-brightness-50">
          <button className="absolute top-4 right-4" onClick={onClose}>
            <FontAwesomeIcon className="text-xl" icon={["fal", "times"]} />
          </button>
          <h1 className="my-2 flex place-items-center">
            Add additional context to your analysis
            <Tooltip
              content={
                <p
                  className="fixed top-12 right-5 z-[100] border text-white/90 py-2 px-4 border-aqua-400 rounded-md bg-black/90 text-sm mx-2"
                  style={{ boxShadow: "0px 0px 40px #18be8747" }}
                >
                  "Add files" allows you to add additional information to an
                  analysis. By dropping a PDF or pasting/writing text in the
                  open text field, AIA will rerun the analysis with the
                  additional context, giving you a more precise and complete
                  picture of the company.
                </p>
              }
            >
              <FontAwesomeIcon
                className="mx-2 text-[#18BEB3]"
                icon={["fal", "info-circle"]}
              />
            </Tooltip>
            <span className="bg-[#18BEB3] px-2 font-bold text-white pt-0.5 rounded-2xl text-sm">
              BETA
            </span>
          </h1>
          <p className="my-2"></p>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="flex justify-center mt-3 mb-5 w-full cursor-pointer items-center border-[1px] h-48 border-dashed rounded-lg border-[#314B97] bg-[#EEF2FE]"
          >
            <input {...getInputProps()} />
            <div className="w-1/3 text-center text-black">
              <p className="text-inherit">Drag & Drop</p>
              <p className="text-inherit">or</p>
              <span className="text-[#18BEB3]"> Choose file</span>
              <span> to upload</span>
            </div>
          </div>
          {selectedFile ? (
            <div className="w-full my-2">
              <div className="w-full flex p-3 items-center justify-between bg-gradient-to-r rounded-md from-[#3B897A] to-[#18BEB3]">
                <p className="text-sm text-white">{selectedFile.name}</p>
                {isLibLoading ? (
                  <LoadingSpinner />
                ) : (
                  <TrashIcon
                    onClick={removeFile}
                    className="w-4 h-4 text-white cursor-pointer"
                  />
                )}
              </div>
            </div>
          ) : null}
          <TextArea
            className="w-full h-32 border border-gray-200 rounded-md p-4"
            maxLength={10000}
            placeholder="Paste your text here"
            value={context}
            onChange={(e: any) => setContext(e.target.value)}
          />
          {parsingError || addContextError || visionError ? (
            <>
              <span className="mt-4 text-center text-red-500">
                Sorry, I was unable to read relevant information in this
                document. Please try again. If you still get an error, contact
                our product team below and we will take care of it. Please
                attach the pitch deck in the form.
              </span>
              <Button
                size="small"
                color="secondary"
                onClick={onHelpClicked}
                className="my-2 font-bold"
              >
                Contact
              </Button>
            </>
          ) : null}
          <Button
            onClick={() => {
              if (!selectedFile && !context) {
                return;
              } else {
                handleUpload(selectedFile, context);
              }
            }}
            disabled={
              (!selectedFile && !context) || isRegenerating || isVisionLoading
            }
            className=" flex w-full justify-center mt-4 text-white p-2 rounded-lg  bg-[#18BEB3] hover:opacity-90"
          >
            <span className="font-bold">Upload</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddContextModal;
