import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  pitchText: string | undefined | null;
}

const ElevatorPitch: FC<Props> = ({ pitchText }) => {
  return (
    <div className="basis-1/2">
      <EditablePlaceholderText
        title="Elevator pitch"
        value={pitchText}
        fieldName="pitch.text"
        addValueTitle="Enter your elevator pitch"
        inputPlaceholder="Enter your elevator pitch"
        placeholderDescription="Let VCs know that you mean business in under 30 seconds! Write a compelling story of your idea in one line, or at max two."
      />
    </div>
  );
};

export default ElevatorPitch;
