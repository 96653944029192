import type { FC } from "react";
import { format } from "date-fns";
import type { ApplicationsListViewProps, Cohort } from "../interfaces";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { cohortLink } from "../../../utils/links";
import { ReactComponent as CopyIcon } from "images/copy-btn.svg";
import Tooltip from "atoms/Tooltip/Tooltip";
import Button from "atoms/Button/Button";
import Switch from "atoms/Switch/Switch";

const ApplicationsListDesktop: FC<ApplicationsListViewProps> = ({
  items,
  getOnCopy,
  getOnToggle,
  onEdit,
}) => {
  const cells = [
    {
      title: "Name",
      render: (row: Cohort) => (
        <div className="flex items-center max-w-[16rem]">
          {row.logo?.small?.location && (
            <img
              className="hidden mr-2 max-w-8 max-h-4 xl:inline-block"
              src={row.logo?.small?.location}
              alt={`${row.name} logo`}
            />
          )}
          <span className="font-bold">{row.name}</span>
        </div>
      ),
    },
    {
      title: "Application Link",
      render: (row: Cohort) => (
        <CopyToClipboard text={cohortLink(row.slug)} onCopy={getOnCopy(row)}>
          <div className="flex justify-between text-gray-500 max-w-[18rem] xl:max-w-sm p-2 bg-gray-100 rounded" data-key="copy-application-link">
            <span
              dir="rtl"
              className="inline-block max-w-xs overflow-hidden text-sm whitespace-nowrap text-ellipsis"
            >
              {cohortLink(row.slug)}
            </span>
            <CopyIcon className="ml-2 min-w-[16px]" width="16" height="16" />
          </div>
        </CopyToClipboard>
      ),
    },
    {
      title: "Created on",
      render: (row: Cohort) => (
        <div className="font-bold text-gray-600">
          {format(row.createdAt, "dd MMM yyyy")}
        </div>
      ),
    },
    {
      title: "Applications",
      render: (row: Cohort) => (
        <div className="inline-block">
          <Tooltip
            content={`${row.stats.pending} pending, ${row.stats.total} total`}
          >
            {row.stats.total}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "",
      render: (row: Cohort) => (
        <div className="flex justify-end">
          <Button
            size="small"
            color="white"
            className="mr-2"
            onClick={() => onEdit(row)}
          >
            Edit
          </Button>
          <Switch checked={row.isOpen} onChange={getOnToggle(row)} />
        </div>
      ),
    },
  ] as const;

  return (
    <table className="w-full table-auto min-w-max">
      <thead>
        <tr className="text-sm font-normal capitalize bg-gray-100">
          {cells.map(({ title }, idx) => (
            <th
              key={idx}
              className="px-3 py-3 font-medium text-left text-gray-400 whitespace-pre"
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((row) => (
          <tr key={row.id}>
            {cells.map((cell, idx) => (
              <td key={idx} className="px-3 py-1">
                {cell.render(row)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ApplicationsListDesktop;
