import styled from "styled-components";

export const TableContainer = styled.div`
  > div {
    width: 100%;
    padding: 0;
    margin-bottom: -1.5rem;
  }
`;

export const InfoLink = styled.a`
  &.website {
    display: inline-block;

    svg {
      transform: rotate(-45deg);
      font-size: 18px;
      margin-left: 3px;
    }
  }
`;
