import type { FC } from "react";
import type { ImageTransitionProps } from "./interfaces";
import { Transition } from "@headlessui/react";
import cn from "classnames";

const ImageTransition: FC<ImageTransitionProps> = ({
  show,
  right,
  children,
}) => (
  <Transition
    className={cn(
      "transition flex items-center fixed top-0 w-[50vw] h-screen",
      right
        ? "left-1/2 duration-[1100ms] pr-20 justify-end"
        : "left-0 duration-[1600ms] pl-20"
    )}
    enter="easy-in"
    enterFrom="-translate-y-[70vh] opacity-0"
    enterTo="translate-y-0 opacity-100"
    leave="ease-in-out"
    leaveFrom="translate-y-0"
    leaveTo="translate-y-[90vh]"
    show={show}
  >
    {children}
  </Transition>
);

export default ImageTransition;
