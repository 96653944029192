// @ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animation } from "ui/animations";
import { SidebarContent } from "ui/elements/PanelLayout.styles";

import { getUser } from "models/user/selectors";

import { getBasicInfo } from "./selectors";
import * as sagaActions from "./sagaActions";
import Loading from "atoms/Loading/Loading";
import BasicStepForm from "./BasicStepForm";

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sagaActions.getBasicInfo());
  }, []);

  const basicInfo = useSelector(getBasicInfo);
  const user = useSelector(getUser);

  return (
    <SidebarContent
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
      className="company"
    >
      {!(basicInfo && user) ? <Loading /> : <BasicStepForm />}
    </SidebarContent>
  );
};
