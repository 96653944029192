// @ts-nocheck
import { initialState, name } from "./reducer";

const companySettings = (state) => state[name] || initialState;

export const getCurrentStep = (state) => companySettings(state).currentStep;
export const getCompletedMajorSteps = (state) => companySettings(state).completedMajorSteps;

export const getCompletedStepsInfo = (state) => _.pick(companySettings(state), [
    "completedMajorSteps",
    "completedSteps",
  ]);
export const getCompletedSteps = (state) => companySettings(state).completedSteps;

export const getCurrencyDetails = (state) => companySettings(state).currencyDetails || {};

export const getInvestorAnonymous = (state) => companySettings(state).investorAnonymous || {};

export const getIsApplicationSubmitSuccess = (state) => companySettings(state).isApplicationSubmitSuccess || false;
