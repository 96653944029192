import React from "react";
import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { MemberStatus } from "./types";
import { triggerSnack } from "organisms/Snack/sagaActions";
import {
  GET_COMPANY_MEMBERS,
  UPDATE_COMPANY_MEMBER_STATUS,
} from "queries/general/account/teamMembers";

interface Props {
  isOpen: boolean;
  companyMemberId: string;
  setIsOpen: (status: boolean) => void;
  refetchRates: (variables: { companyId: string; features: string[] }) => void;
  companyId: string;
  features: string[];
}

const DeleteConfirmationModal: React.FC<Props> = ({
  isOpen,
  companyMemberId,
  setIsOpen,
  refetchRates,
  companyId,
  features,
}) => {
  const dispatch = useDispatch();

  const [updateCompanyMemberStatus, { loading }] = useMutation(
    UPDATE_COMPANY_MEMBER_STATUS,
    {
      refetchQueries: [{ query: GET_COMPANY_MEMBERS }],
      onCompleted: () => {
        dispatch(
          triggerSnack({
            type: "success",
            title: "Member removed successfully",
            message: "Member removed successfully",
          })
        );
        refetchRates({ companyId: companyId, features: features });
        closeModal();
      },
      onError: () =>
        dispatch(
          triggerSnack({
            type: "error",
            title: "Something went wrong",
            message: "We could not remove the member",
          })
        ),
    }
  );

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCancellation = () => {
    closeModal();
  };

  const handleDeletion = () => {
    updateCompanyMemberStatus({
      variables: {
        companyMemberId: companyMemberId,
        status: MemberStatus.INACTIVE,
      },
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle bg-white shadow-xl transition-all transform rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-gray-900 leading-6"
                >
                  Are you sure you want to delete this member?
                </Dialog.Title>

                <div className="flex justify-center mt-6 ">
                  <Button
                    color="primary"
                    onClick={handleCancellation}
                    className="flex justify-center mr-2 basis-20"
                  >
                    <span>No</span>
                  </Button>

                  <Button
                    color="primary"
                    onClick={handleDeletion}
                    className="flex justify-center basis-20"
                  >
                    <span>Yes</span>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteConfirmationModal;
