// @ts-nocheck

import Snack from "./Snack";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSnacks } from "./selectors";
import { removeSnack } from "./sagaActions";

const SnackPortal = (props) => {
  let snacks = useSelector((state) => getSnacks(state));
  const dispatch = useDispatch();

  const handleRemoveSnack = (id) => {
    dispatch(removeSnack({ id }));
  };

  const items = (
    <div className="fixed top-0 right-0 z-50 flex flex-col items-end justify-center w-full px-4 py-6 pointer-events-none sm:p-6 sm:justify-end">
      {snacks.map((snack) => (
        <Snack
          key={snack.key}
          id={snack.key}
          type={snack.type}
          title={snack.title}
          message={snack.message}
          timer={snack.timer}
          handleRemoveSnack={handleRemoveSnack}
        />
      ))}
    </div>
  );

  return ReactDOM.createPortal(items, document.getElementById("snack-portal"));
};

export default SnackPortal;
