import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import cn from "classnames";
import PureHtml from "atoms/PureHtml";
import SectionHeader from "../SectionHeader/SectionHeader";
interface Props {
  businessModelDescription?: string;
  pricing?: string;
  isLoading?: boolean;
  isProfileGeneratedByAIA?: boolean;
}

const BusinessModel: FC<Props> = ({
  businessModelDescription,
  isLoading,
  pricing,
  isProfileGeneratedByAIA,
}) => {
  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="Business Model" />
        <Skeleton count={3.6} />
      </div>
    );

  if (!businessModelDescription) return null;

  return (
    <div
      className={cn(
        businessModelDescription && isProfileGeneratedByAIA
          ? "grid-cols-1 md:grid-cols-2"
          : "grid-cols-1",
        "mb-6 grid gap-2"
      )}
    >
      <div>
        <SectionHeader title="Business Model" />
        <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
          <PureHtml content={businessModelDescription} />
        </div>
      </div>

      {pricing ? (
        <div>
          <SectionHeader title="Pricing" />
          <div className="text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
            <PureHtml content={pricing} />
          </div>
        </div>
      ) : (
        <>
          {isProfileGeneratedByAIA && (
            <div>
              <SectionHeader title="Pricing" />
              <p className="text-base text-black whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
                We could not find any pricing information for this company.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessModel;
