// @ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animation } from "ui/animations";
import { SidebarContent } from "ui/elements/PanelLayout.styles";
import Loading from "atoms/Loading/Loading";
import _ from "lodash";
import * as sagaActions from "./sagaActions";
import { getProfileInfo } from "./selectors";
import ProfileStepForm from "./ProfileStepForm";

export default () => {
  const dispatch = useDispatch();

  const data = useSelector(getProfileInfo);

  useEffect(() => {
    dispatch(sagaActions.getProfileInfo());
  }, []);

  return (
    <SidebarContent
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
    >
      {!data ? <Loading fullPage /> : <ProfileStepForm />}
    </SidebarContent>
  );
};
