import type { TextareaProps } from "./interfaces";
import { ChangeEvent, FC, useLayoutEffect, useRef, useState } from "react";

const Textarea: FC<TextareaProps> = ({
  autoResize,
  style,
  onChange,
  ...props
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [height, setHeight] = useState<number>();

  useLayoutEffect(() => {
    if (!autoResize || !ref.current) {
      return;
    }

    setHeight(ref.current.scrollHeight);
  }, [autoResize, ref.current]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const element = ref.current;

    if (element && autoResize) {
      element.style.height = "0";
      element.style.height = element.scrollHeight + "px";
    }

    onChange?.(event);
  };

  return (
    <textarea
      ref={ref}
      onChange={handleChange}
      style={{ height, ...style }}
      {...props}
    />
  );
};

export default Textarea;
