import { FC } from "react";
import cn from "classnames";
import { VisualPreviewProps } from "./interfaces";
import EmbedVideo from "atoms/EmbedVideo";

const VisualPreview: FC<VisualPreviewProps> = ({
  source,
  type,
  onClick,
  isCurrent,
}: VisualPreviewProps) => (
  <div
    onClick={onClick}
    className={cn("mr-1 h-16 w-28 cursor-pointer rounded-md", {
      "border-[4px] border-solid border-[#18beb3]": isCurrent,
    })}
  >
    {type === "IMAGE" && (
      <img
        src={source}
        alt="image"
        className="w-[400px] h-full hover:opacity-50 object-cover rounded-sm "
      />
    )}
    {type === "VIDEO" && (
      <div className="relative pointer-events-none">
        <EmbedVideo source={source} isPreview={true} />
      </div>
    )}
  </div>
);

export default VisualPreview;
