import { FC } from "react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CriteriaFulfillmentStatus,
  InvestorCriteria,
  InvestorCriteriaEvaluation,
  InvestorCriteriaResult,
} from "gql/graphql";
import SectionHeader from "../shared/SectionHeader/SectionHeader";
import TooltipWrapper from "atoms/Tooltip/Tooltip";
import Button from "atoms/Button/Button";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "images/close-icon.svg";
import { useLocalStorage } from "react-use";
import { useQuery } from "@apollo/client";
import { GET_INVESTOR_CRITERIA } from "pages/aia/queries";
import Skeleton from "react-loading-skeleton";

interface Props {
  company: any;
  criteriaAnalysis: InvestorCriteriaResult;
}

interface TooltipContentProps {
  evaluation: InvestorCriteriaEvaluation;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipContentProps> = ({ evaluation, children }) => (
  <TooltipWrapper
    place="right"
    content={
      <div className="max-w-xl">
        <p className="text-white">
          <strong>Your criterion: </strong>
          {evaluation?.description}
        </p>
        <p className="text-white">
          <strong> AIA comment:</strong> {evaluation?.comment}
        </p>
        <p className="text-white">
          <strong>Importance: </strong>
          {evaluation?.importance === "MUST_HAVE"
            ? "Must have"
            : "Nice to have"}
        </p>
      </div>
    }
  >
    {children}
  </TooltipWrapper>
);

const CriteriaScoring: FC<Props> = ({ company, criteriaAnalysis }) => {
  const [seenCriteriaScoring, setSeenCriteriaScoring] = useLocalStorage(
    "seen-criteria-scoring"
  );

  const { data: existingInvestorCriteria, loading: criteriaLoading } =
    useQuery<{
      aia: { investorCriteria: InvestorCriteria[] };
    }>(GET_INVESTOR_CRITERIA);

  if (criteriaLoading) {
    <Skeleton count={1.5} />;
  }

  const notMatching = criteriaAnalysis?.evaluations?.filter(
    (item) => item?.criteriaFulfillmentStatus === "NO"
  );

  const notMatchingMustHave = notMatching?.filter(
    (item) => item?.importance === "MUST_HAVE"
  );

  const matching = criteriaAnalysis?.evaluations?.filter(
    (item) => item?.criteriaFulfillmentStatus === "YES"
  );
  const unknown = criteriaAnalysis?.evaluations?.filter(
    (item) => item?.criteriaFulfillmentStatus === "NOT_SURE"
  );

  /**
   * Result of an anlysis is more importan, so it comes first. This matters only in special cases when
   * user has already ran an anlysis, but now deleted their criteria. So although there is no criteria
   * the activation is not shown.
   */
  if (criteriaAnalysis?.evaluations)
    return (
      <div className="p-4">
        <div className="flex items-center mb-4">
          <SectionHeader title="Your investment criteria match" mb={0} />
          <TooltipWrapper
            place="right"
            content={`This company fulfils ${matching?.length} out of ${criteriaAnalysis.evaluations?.length} of your investment criteria.
              If you wish to update your investment criteria, go to AI Analyst in the left menu and click on Customize investment criteria.`}
          >
            <div className="px-3 py-1 ml-2 border-2 rounded-md cursor-pointer">
              <h1 className="text-2xl font-bold text-gray-700 dark:text-white/80">{`${matching?.length}/${criteriaAnalysis.evaluations?.length}`}</h1>
            </div>
          </TooltipWrapper>
        </div>
        <div className="w-full gap-4 mb-2">
          {notMatchingMustHave?.length ? (
            <div className="flex items-center mb-4 flex-wrap gap-1">
              <FontAwesomeIcon icon={["fal", "exclamation-triangle"]} />
              <p className="text-black my-2 mx-1">
                {company.displayName} does not match your “Must have”{" "}
                {notMatchingMustHave.length > 1 ? "criterias" : "criteria"}:
              </p>

              {notMatching
                ?.filter((item) => item?.importance === "MUST_HAVE")
                .map((item, index) =>
                  item ? <CriteriaTag criteria={item} key={index} /> : null
                )}
            </div>
          ) : null}

          <div className="flex justify-between md:justify-start gap-x-2 md:gap-x-8">
            {notMatching && notMatching.length ? (
              <div className="flex flex-col">
                <p className="mb-2 ml-2 font-medium text-gray-700 dark:text-white/80">
                  Not Matching
                </p>
                <div className="space-y-1 cursor-pointer w-fit">
                  {notMatching.map((item, index) =>
                    item ? (
                      <Tooltip key={index} evaluation={item}>
                        <CriteriaTag criteria={item} />
                      </Tooltip>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}

            {matching && matching.length ? (
              <div className="flex flex-col">
                <p className="mb-2 ml-2 font-medium text-gray-700 dark:text-white/80">
                  Matching
                </p>
                <div className="space-y-1 cursor-pointer w-fit">
                  {matching.map((item, index) =>
                    item ? (
                      <Tooltip key={index} evaluation={item}>
                        <CriteriaTag criteria={item} />
                      </Tooltip>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}

            {unknown && unknown.length ? (
              <div className="flex flex-col">
                <p className="mb-2 ml-2 font-medium text-gray-700 dark:text-white/80">
                  Unknown
                </p>
                <div className="space-y-1 cursor-pointer w-fit">
                  {unknown.map((item) =>
                    item ? (
                      <Tooltip evaluation={item}>
                        <CriteriaTag criteria={item} />
                      </Tooltip>
                    ) : null
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  else if (
    !existingInvestorCriteria?.aia?.investorCriteria?.length &&
    !seenCriteriaScoring
  ) {
    return (
      <div className="bg-aqua-50 w-1/3 p-4 mb-4 rounded-lg">
        <CloseIcon
          className="cursor-pointer float-right w-3"
          onClick={() => setSeenCriteriaScoring(true)}
        />
        <div className="text-xl font-bold text-gray-700">
          Get more out of Sircular
        </div>
        <div className="text-sm text-gray-700 mt-2 mb-4">
          Set your investment criteria to automatically score every company in
          your dealflow.
        </div>
        <Link to="/aia" state={{ showInvestmentCriteria: true }}>
          <Button color="primary" size="small">
            Add your investment criteria
          </Button>
        </Link>
      </div>
    );
  } else return null;
};

export default CriteriaScoring;

const CriteriaTag = ({
  criteria,
}: {
  criteria: InvestorCriteriaEvaluation;
}) => {
  const className = cn({
    "h-auto p-2 w-fit rounded-md": true,
    "bg-[#0B0D1821] text-[#0B0D18]":
      criteria.criteriaFulfillmentStatus === CriteriaFulfillmentStatus.NotSure,

    "bg-[#E5F1F1] text-[#124F03]":
      criteria.criteriaFulfillmentStatus === CriteriaFulfillmentStatus.Yes,

    "bg-[#FFF1EE] text-[#7F0202]":
      criteria.criteriaFulfillmentStatus === CriteriaFulfillmentStatus.No &&
      criteria.importance === "MUST_HAVE",

    "bg-[#FFFABA] text-[#5D5F01]":
      criteria.criteriaFulfillmentStatus === CriteriaFulfillmentStatus.No &&
      criteria.importance === "NICE_TO_HAVE",
  });

  return <div className={className}>{criteria?.category}</div>;
};
