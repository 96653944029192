// @ts-nocheck
import { moduleTypes } from "core/modules";
import { getUser } from "models/user/selectors";

const module = [
  {
    module: moduleTypes.MY_SPACE,
    path: "/myspace",
    componentId: "mySpace",
    component: import(/* webpackChunkName: "myspace" */ "./MySpace"),
    routes: [],
    importReducer: () => [],
    importSagas: () => [],
    isEnabled: (state) => {
      const user = getUser(state);
      return user?.isCompany;
    },
    title: "My space Page",
    exact: true,
    showHeader: true,
    name: "MySpace",
  },
];

export default module;
