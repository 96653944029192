import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUser } from "models/user/selectors";
import FullscreenLayout from "atoms/FullscreenLayout/FullscreenLayout";

type AppRouteProps = {
  Component: FC;
};

const AppRoute: FC<AppRouteProps> = ({ Component }) => {
  const userData = useSelector(getUser);

  if (userData) return <Navigate to="/" />;

  return <FullscreenLayout component={Component} />;
};

export default AppRoute;
