import { moduleTypes } from "core/modules";

const module = [
  {
    path: "/pending-applications",
    key: "pending-applications",
    componentId: "pending-applications",
    component: import(/* webpackChunkName: "pendingApplications" */ "./PendingApplications"),
    routes: [],
    module: moduleTypes.PENDING_APPLICATIONS,
    isEnabled: () => true,
    importReducer: () => [],
    importSagas: () => [],
    title: "Pending Applications",
    exact: true,
    name: "PendgingApplications",
  },
];

export default module;
