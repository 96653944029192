import type { SingleSelectChoice, SingleSelectProps } from "./interfaces";
import ClickOutside from "atoms/ClickOutside";
import { useRef, useState, SyntheticEvent } from "react";
import Options from "./Options";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { twMerge } from "tailwind-merge"

const OPTIONS_PADDING = 4;

const SingleSelect = <Choice extends SingleSelectChoice = SingleSelectChoice>({
  choices,
  value,
  onChange,
  renderOption: initialRenderOption,
  renderPreview: initialRenderPreview,
  emptyText = "Select",
  actions,
  labelClassName,
  wrapperClassName,
}: SingleSelectProps<Choice>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rect, setRect] = useState({ left: 0, top: 0, width: 0 });
  const labelRef = useRef<HTMLLabelElement>(null);

  const renderDefault = (choice: Choice) => <>{choice.title}</>;

  const renderOption = initialRenderOption || renderDefault;

  const renderPreview = initialRenderPreview || renderDefault;

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
    handleOpen(e);
  };

  const handleFocus = (e: SyntheticEvent) => {
    e.preventDefault();
    setTimeout(() => {
      setIsOpen(true);
      handleOpen(e);
    }, 200);
  };

  const handleOpen = (e: SyntheticEvent) => {
    if (labelRef.current) {
      const rect = labelRef.current.getBoundingClientRect();

      setRect({
        left: rect.left,
        top: rect.height + rect.top + window.scrollY + OPTIONS_PADDING,
        width: rect.width,
      });
    }
  };

  const handleClose = () => setIsOpen(false);

  return (
    <ClickOutside onClick={handleClose} className={wrapperClassName}>
      <div className="flex w-full">
        <label
          ref={labelRef}
          tabIndex={0}
          onClick={(e) => handleClick(e)}
          onFocus={(e) => handleFocus(e)}
          onBlur={handleClose}
          className={twMerge(
            "flex items-center justify-between px-2 py-1 truncate border border-gray-300 cursor-pointer rounded-xl focus:outline-2 outline-aqua-300",
            labelClassName
            )}
        >
          <span>{value ? renderPreview(value) : emptyText}</span>
          <span>
            <FontAwesomeIcon
              className={cn("ml-1 text-xs transition", isOpen && "rotate-180")}
              icon={["fas", "chevron-down"]}
            />
          </span>
        </label>
        {isOpen && (
          <Options
            choices={choices}
            renderOption={renderOption}
            value={value}
            onChange={onChange}
            left={rect.left}
            top={rect.top}
            width={rect.width}
            actions={actions}
          />
        )}
      </div>
    </ClickOutside>
  );
};

export default SingleSelect;
