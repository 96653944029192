import { gql } from "@apollo/client";

export const GET_REFERRAL = gql`
  query referral($slug: String!) {
    referral(slug: $slug) {
      id
      referrer {
        email
      }
    }
  }
`;

export const ONBOARD_STARTUP = gql`
  mutation onboardStartup(
    $welcome: StartupWelcome
    $basics: StartupBasics
    $investment: StartupInvestment
    $elevatorPitch: StartupElevatorPitch
    $team: StartupTeam
    $pitchDeck: StartupPitchDeck
    $finish: Boolean
  ) {
    onboarding {
      startup(
        welcome: $welcome
        basics: $basics
        investment: $investment
        elevatorPitch: $elevatorPitch
        team: $team
        pitchDeck: $pitchDeck
        finish: $finish
      )
    }
  }
`;

export const ONBOARD_INVESTOR = gql`
  mutation onboardInvestor(
    $welcome: InvestorWelcome
    $finish: Boolean
  ) {
    onboarding {
      investor(
        welcome: $welcome
        finish: $finish
      )
    }
  }
`;
