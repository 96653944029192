import type { FC } from "react";
import type { SectionProps } from "./interfaces";
import { getCurrencyList } from "models/user/selectors";
import { Currency } from "pages/dealflow/InvestorDealflow/interfaces";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";
import TractionItem from "./TractionItem";
import { prettyNum } from "utils/number";
import { MILIFY_CURRENCY_FORMAT } from "core/consts";

const Traction: FC<SectionProps> = ({ startup }) => {
  const currencies: Currency[] = useSelector(getCurrencyList);
  const traction = startup.traction;

  if (!traction) {
    return null;
  }

  const getCurrency = (id: string) =>
    currencies.find((currency) => currency._id === id);

  return (
    <div className="flex flex-col items-start">
      {traction.activeUsers
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Active Users"
            figure={MILIFY_CURRENCY_FORMAT(item.amount)}
            suffix={capitalize(item.frequency)}
            key={idx}
          />
        ))}

      {traction.totalUsers
        ?.filter((item) => item.count)
        .map((item, idx) => (
          <TractionItem
            label="Total Users"
            figure={MILIFY_CURRENCY_FORMAT(item.count)}
            suffix={capitalize(item.type)}
            key={idx}
          />
        ))}

      {traction.growthRate
        ?.filter((item) => item.rate)
        .map((item, idx) => (
          <TractionItem
            label="User Growth Rate"
            figure={item.rate + "%"}
            suffix={"/" + capitalize(item.period)}
            middle={
              item.amount && item.time
                ? `${item.amount} ${capitalize(item.time)}`
                : undefined
            }
            key={idx}
          />
        ))}

      {traction.retentionRate
        ?.filter((item) => item.rate)
        .map((item, idx) => (
          <TractionItem
            label="Retention Rate"
            figure={item.rate + "%"}
            suffix={capitalize(item.period)}
            middle={
              item.amount && item.time
                ? `${item.amount} ${capitalize(item.time)}`
                : undefined
            }
            key={idx}
          />
        ))}

      {traction.churnRate
        ?.filter((item) => item.percentage)
        .map((item, idx) => (
          <TractionItem
            label="Churn Rate"
            figure={item.percentage + "%"}
            suffix={"/" + capitalize(item.period)}
            key={idx}
          />
        ))}

      {traction.netRevenue
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Net Revenue"
            figure={
              MILIFY_CURRENCY_FORMAT(item.amount) +
              " " +
              getCurrency(item.currency)?.code
            }
            suffix={"/" + capitalize(item.time)}
            key={idx}
          />
        ))}

      {traction.recurringRevenue
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Recurring Revenue"
            figure={
              MILIFY_CURRENCY_FORMAT(item.amount) +
              " " +
              getCurrency(item.currency)?.code
            }
            suffix={capitalize(item.time)}
            key={idx}
          />
        ))}

      {traction.revenueRate
        ?.filter((item) => item.rate)
        .map((item, idx) => (
          <TractionItem
            label="Revenue Growth Rate"
            figure={item.rate + "%"}
            suffix={"/" + capitalize(item.period)}
            middle={
              item.amount && item.time
                ? `${item.amount} ${capitalize(item.time)}`
                : undefined
            }
            key={idx}
          />
        ))}

      {traction.smv_gsv
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="GMV/GSV"
            figure={
              MILIFY_CURRENCY_FORMAT(item.amount) +
              " " +
              getCurrency(item.currency)?.code
            }
            suffix={capitalize(item.time)}
            key={idx}
          />
        ))}

      {traction.salePriceAvg
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Average Sales Price"
            figure={item.amount + " " + getCurrency(item.currency)?.code}
            key={idx}
          />
        ))}

      {traction.ratePercentage
        ?.filter((item) => item)
        .map((item, idx) => (
          <TractionItem label="Rake Percentage" figure={item + "%"} key={idx} />
        ))}

      {traction.cacPayback
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="CAC Payback"
            figure={MILIFY_CURRENCY_FORMAT(item.amount)}
            suffix={capitalize(item.frequency)}
            key={idx}
          />
        ))}

      {traction.ltcCacRatio
        ?.filter((item) => item)
        .map((item, idx) => (
          <TractionItem label="LTV: CAC Ratio" figure={item} key={idx} />
        ))}

      {traction.demandFreq
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Demand Frequency"
            figure={item.amount}
            suffix={capitalize(item.unit) + "/" + capitalize(item.frequency)}
            key={idx}
          />
        ))}

      {traction.supplyFreq
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Supply Frequency"
            figure={item.amount}
            suffix={capitalize(item.unit) + "/" + capitalize(item.frequency)}
            key={idx}
          />
        ))}

      {traction.signedContracts
        ?.filter((item) => item)
        .map((item, idx) => (
          <TractionItem label="Signed Contracts" figure={item} key={idx} />
        ))}

      {traction.annualValueContracts
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Annual Value of Signed Contracts"
            figure={
              MILIFY_CURRENCY_FORMAT(item.amount) +
              " " +
              getCurrency(item.currency)?.code
            }
            key={idx}
          />
        ))}

      {traction.pipelineContracts
        ?.filter((item) => item)
        .map((item, idx) => (
          <TractionItem
            label="# of Contracts in Pipeline"
            figure={item}
            key={idx}
          />
        ))}

      {traction.annualValueContractsPipeline
        ?.filter((item) => item.amount)
        .map((item, idx) => (
          <TractionItem
            label="Annual Value of Signed in Pipeline"
            figure={
              MILIFY_CURRENCY_FORMAT(item.amount) +
              " " +
              getCurrency(item.currency)?.code
            }
            key={idx}
          />
        ))}

      {traction.lettersOfIntent
        ?.filter((item) => item)
        .map((item, idx) => (
          <TractionItem
            label="Letters of Intent Signed"
            figure={item}
            key={idx}
          />
        ))}
    </div>
  );
};

export default Traction;
