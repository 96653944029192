export const AiaActivationConfig = {
  GenerateEmail: {
    id: "aia-activation-generate-email",
    numberOfUploads: 2,
    content: `<div>
      <span style='font-weight: bold; font-size:19px; display: inline-block; margin-bottom: 10px;'>Automated email drafts</span><br />
      <span>Did you know that you can generate customized email drafts for founders? Click below to check it out.</span>  
     </div>`,
    placement: "top",
  },
  AddFiles: {
    id: "aia-activation-add-files",
    numberOfUploads: 4,
    content: `<div>
      <span style='font-weight: bold; font-size:19px; display: inline-block; margin-bottom: 10px;'>Add more files to your analysis</span><br />
      <span>Did you know that you can deepen the analysis of the company by adding files and free text? Click above to check it out.</span>  
     </div>`,
    placement: "bottom",
  },
  FetchMoreCompetitors: {
    id: "aia-activation-fetch-more-competitors",
    numberOfUploads: 3,
    content: `<div>
      <span style='font-weight: bold; font-size:19px; display: inline-block; margin-bottom: 10px;'>Improve competitor list</span><br />
      <span>Did you know that you can actively impact the quality of the competitor list?</span>
      <ol style='list-style: decimal inside none;'>
      <li>Rate competitors with a <span>&#128077;</span> or <span style='display: inline-block; transform: translateY(3px);'>&#128078;</span></li>
      <li>Click on Fetch more competitors</li>
      </ol>
      <span>Doing so will complete your list with a more targeted search.</span>
     </div>`,
    placement: "top",
  },
  CompetitorsDeepDive: {
    id: "aia-activation-competitors-deep-dive",
    numberOfUploads: 5,
    content: `<div>
      <span style='font-weight: bold; font-size:19px; display: inline-block; margin-bottom: 10px;'>Competitor deep dive</span><br />
      <span>Did you know that you can do a deep analysis of the main competitors? Click below to check it out.</span>  
     </div>`,
    placement: "bottom",
  },
};
