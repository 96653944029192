import type { FC } from "react";
import { useGuard } from "hooks/useGuard";
import { getUser } from "models/user/selectors";
import { useSelector } from "react-redux";
import { Desktop } from "./desktop";

const Onboard: FC = () => {
  useGuard([
    (userData) => {
      if (userData?.isInvestor && userData?.isOnboarding === false) {
        return "/aia";
      } else if (userData?.isCompany && userData?.isOnboarding === false) {
        return "/company-profile";
      }
    },
  ]);

  const user = useSelector(getUser);
  const type =
    user?.type === "company"
      ? "startup"
      : user?.type === "investor"
      ? "investor"
      : null;

  return (
    <div>
      <Desktop type={type} />
    </div>
  );
};

export default Onboard;
