import { gql } from "@apollo/client";

export const GET_PROFILE_INFO = gql`
  query getProfileInfo {
    me {
      _id
      completedPercentage
      completedSteps
      displayName
      pitch
      vision
      mission
      description
      city
      website
      socials {
        twitter
        linkedin
        instagram
      }
      lastName
      firstName
      meetingUrl
      country
      state
      fullAddress
      lat
      long
      investorType
      supportAsAdvisor
      competences
      orgNumber
      phone
    }
  }
`;

export const SAVE_PROFILE_INFO = gql`
  mutation saveInvestorProfileStepNew($input: InvestorProfileStepInput!) {
    saveInvestorProfileStepNew(input: $input) {
      _id
    }
  }
`;

export const GET_LOGO = gql`
  query getLogo {
    me {
      _id
      logo {
        large {
          location
        }
      }
    }
  }
`;

export const UPLOAD_LOGO = gql`
  mutation updateInvestorProfileImage($file: Upload!) {
    updateInvestorProfileImage(file: $file) {
      _id
      logo {
        large {
          location
        }
      }
    }
  }
`;
