import { Transition } from "@headlessui/react";
import { clearInvitationData } from "pages/invitation/reducer";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

interface ExpiredInvitationProps {
  logo: string;
  header: string;
  invitationStatus: string;
}

const ExpiredInvitation: FC<ExpiredInvitationProps> = ({
  header,
  logo,
  invitationStatus,
}) => {
  const dispatch = useDispatch();
  const clearData = () => dispatch(clearInvitationData());

  const getHeader = () => {
    switch (invitationStatus) {
      case "EXPIRED":
        return `${header}´s invitation has expired.`;
      case "REJECTED":
        return `The invitation from ${header} has already been rejected.`;
      case "ACCEPTED":
        return `The invitation from ${header} has already been accepted!`;
      default:
        return "Oops! This invitation has expired";
    }
  };

  return (
    <>
      <h2 className="relative z-10 items-center hidden w-full mx-auto leading-7 md:flex top-8">
        <div className="flex-col items-center justify-center hidden w-full mx-auto md:flex">
          <img
            alt=""
            src={logo}
            className="w-[6rem] mx-auto object-cover h-[6rem] mb-2 rounded-full border-2 border-white/70"
          />
        </div>
      </h2>
      <Transition appear show={true}>
        <Transition.Child
          className="relative z-30 flex flex-col items-center w-screen min-h-screen mt-10 overflow-y-auto transition no-srollbar invisible-scroll"
          enter="easy-in-out duration-1000"
          enterFrom="opacity-0 scale-95 translate-y-24"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="duration-700"
          leaveTo="scale-95 opacity-0 translate-y-96"
        >
          <div className="bg-white flex flex-col items-center md:w-[400px] mt-10 pt-4 px-6 pb-12 text-gray-800 rounded-lg">
            <div className="bg-[#202337] w-[70px] h-[5px] rounded-md mb-4"></div>
            <h3 className="mb-3 text-center text-gray-600">{getHeader()}</h3>
            <label className="flex items-center justify-center w-full mt-4 text-sm">
              <span className="text-gray-400">Already have an account?</span>
            </label>
            <Link
              className="flex justify-center w-full py-2 mt-2 font-bold bg-white border rounded-lg text-aqua-400 hover:text-white border-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
              to="/login"
              onClick={clearData}
            >
              Login
            </Link>
            <label className="flex items-center justify-center w-full mt-4 text-sm">
              <span className="text-gray-400">Don't have an account?</span>
            </label>
            <Link
              className="flex justify-center w-full py-2 my-1 font-bold bg-white border rounded-lg text-aqua-400 hover:text-white border-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
              to="/onboard"
              onClick={clearData}
            >
              Create Account
            </Link>
          </div>
        </Transition.Child>
      </Transition>
    </>
  );
};

export default ExpiredInvitation;
