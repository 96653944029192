import { FC, useEffect } from "react";
import { format } from "date-fns";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useLang } from "core/lang/LangaugeSetting";
import { useQuery } from "@apollo/client";
import { BillingWrapper, UpgradePlan, DowngradePlan } from "./components";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Loading from "atoms/Loading/Loading";
import { Plan, PlanPrice, Subscription } from "pages/plans/interfaces";
import TabContainer from "../TabContainer";
import {
  GET_CANCEL_SUBSCRIPTION_URL,
  GET_INVOICE_HISTORY_URL,
  GET_MANAGE_PAYMENTS_URL,
  GET_SUBSCRIPTION,
} from "queries/general/account/billing";
import { GET_PLANS_BY_USER } from "queries/general/account/plan";
import { mixPanelClickCancelPlan } from "core/mixpanel/Mixpanel";
import { mapBillingFrequency } from "pages/plans/utils/mapBillingFrequency";
import { InvestorPlans } from "pages/plans/InvestorPlans";
import { getUser } from "models/user/selectors";
import { Tier } from "gql/graphql";

const Billing: FC = () => {
  const dispatch = useDispatch();
  const { lang } = useLang();
  const { data, loading, refetch } = useQuery(GET_SUBSCRIPTION);

  const { data: plansData } = useQuery(GET_PLANS_BY_USER);

  const subscription: Subscription = data?.me?.subscription;
  const user = useSelector(getUser);
  const tier = user?.tier;

  const [
    getManagePaymentUrl,
    { data: managePaymentData, error: managePaymentError },
  ] = useLazyQuery(GET_MANAGE_PAYMENTS_URL);

  const [
    getInvoiceHistoryUrl,
    { data: invoiceHistoryData, error: invoiceHistoryError },
  ] = useLazyQuery(GET_INVOICE_HISTORY_URL);

  const [
    getCancelSubscriptionUrl,
    { data: cancelSubscriptionData, error: cancelSubscriptionError },
  ] = useLazyQuery(GET_CANCEL_SUBSCRIPTION_URL, {
    variables: { subscription: subscription?.id },
  });

  const plans: Plan[] = plansData?.plansByUserType;

  const priceData: PlanPrice = subscription?.price;
  const planData: Plan = subscription?.plan;
  const paymentProfile = data?.me?.paymentProfile;

  const formattedNextBillingDate =
    subscription &&
    format(new Date(subscription.nextBillingDate), "dd MMM yyyy");

  const { email, defaultPaymentMethod } = paymentProfile || {};

  const handleManagePayments = () => {
    getManagePaymentUrl();
  };

  const handleInvoiceHistory = () => {
    getInvoiceHistoryUrl();
  };

  const handleCancelSubscription = () => {
    mixPanelClickCancelPlan();
    getCancelSubscriptionUrl();
  };

  useEffect(() => {
    const managePaymentUrl = managePaymentData?.paymentMethodUrl;
    const cancelSubscriptionUrl = cancelSubscriptionData?.cancelSubscriptionUrl;
    const invoiceHistoryUrl = invoiceHistoryData?.invoiceHistoryUrl;

    if (managePaymentUrl) {
      window.location.replace(managePaymentUrl);
    }

    if (cancelSubscriptionUrl) {
      window.location.replace(cancelSubscriptionUrl);
    }

    if (invoiceHistoryUrl) {
      window.location.replace(invoiceHistoryUrl);
    }
  }, [managePaymentData, cancelSubscriptionData, invoiceHistoryData]);

  useEffect(() => {
    let errorMessage: string;

    if (managePaymentError) {
      errorMessage = "Unable to visit payment method management page";
    } else if (cancelSubscriptionError) {
      errorMessage = "Unable to visit subscriptions page";
    } else if (invoiceHistoryError) {
      errorMessage = "Unable to visit invoice history page";
    } else {
      errorMessage = "unable to process the request";
    }

    if (managePaymentError || cancelSubscriptionError || invoiceHistoryError) {
      dispatch(
        triggerSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: errorMessage,
        })
      );
    }
  }, [managePaymentError, cancelSubscriptionError, invoiceHistoryError]);

  const isSubscriptionCanceled = subscription?.cancelAtPeriodEnd;
  const isTrial = subscription?.status === "TRIALING";

  if (loading) return <Loading fullPage />;

  return (
    <TabContainer>
      <div className="flex flex-col w-full h-full">
        <div className={cn("sm:p-3 p-4", isSubscriptionCanceled && "pt-0")}>
          <div className="mt-2 sm:mb-6 sm:mt-0">
            <h4 className="text-xl font-medium">Billing</h4>
          </div>
          {isTrial && (
            <div className="flex flex-col items-start ">
              <p className="mb-3 text-sm text-black opacity-50">
                Trial ends on
              </p>
              {!isSubscriptionCanceled && (
                <p className="text-black">{formattedNextBillingDate}</p>
              )}
              <div className="flex flex-col items-start">
                {isSubscriptionCanceled ? (
                  <p className="text-sm text-black">
                    {formattedNextBillingDate}
                  </p>
                ) : (
                  <button
                    onClick={handleCancelSubscription}
                    className="text-[#0077D1] text-sm mt-3"
                  >
                    Cancel subscription
                  </button>
                )}
              </div>
            </div>
          )}
          {subscription && !isTrial && (
            <div className="flex flex-wrap file:divide-y lg:divide-y-0 md:divide-x md:border-2">
              <BillingWrapper>
                <p className="mb-3 text-sm text-black opacity-50">
                  {planData?.title} plan{" "}
                  {mapBillingFrequency(priceData?.interval)} renewal
                </p>
                {!isSubscriptionCanceled && (
                  <p className="text-black">{formattedNextBillingDate}</p>
                )}
                <div className="flex flex-col items-start">
                  {subscription && !isSubscriptionCanceled && (
                    <>
                      <UpgradePlan
                        currentPlan={planData}
                        currentPrice={priceData}
                        allPlans={plans}
                        refetchSubscription={refetch}
                      />
                      <DowngradePlan
                        currentPlan={planData}
                        currentPrice={priceData}
                        allPlans={plans}
                        refetchSubscription={refetch}
                      />
                    </>
                  )}
                  {isSubscriptionCanceled ? (
                    <p className="text-sm text-black">
                      Your current plan ends on {formattedNextBillingDate}
                    </p>
                  ) : (
                    <button
                      onClick={handleCancelSubscription}
                      className="text-[#0077D1] text-sm mt-3"
                    >
                      Cancel subscription
                    </button>
                  )}
                </div>
              </BillingWrapper>
              <BillingWrapper>
                <p className="mb-3 text-sm font-thin text-black opacity-50">
                  Card Details
                </p>
                {defaultPaymentMethod && defaultPaymentMethod.card && (
                  <p className="text-black">
                    **** **** **** {defaultPaymentMethod.card.last4}
                  </p>
                )}

                {!subscription?.cancelAtPeriodEnd && (
                  <button
                    onClick={handleManagePayments}
                    className="text-[#0077D1] text-sm mt-3"
                  >
                    Manage payment methods
                  </button>
                )}
              </BillingWrapper>

              <BillingWrapper>
                <p className="mb-3 text-sm text-black opacity-50">
                  Invoice sent to
                </p>
                <p className="text-black">{email}</p>
                <button
                  onClick={handleInvoiceHistory}
                  className="text-[#0077D1] text-sm mt-3"
                >
                  View invoice history
                </button>
              </BillingWrapper>
            </div>
          )}
          <div className="mt-2">
            {tier !== Tier.Business && <InvestorPlans isUpgradeModal/>}
          </div>
        </div>
      </div>
    </TabContainer>
  );
};

export default Billing;
