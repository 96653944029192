export const downloadAndRenameFile = (url: string, newName: string) => {
  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.blob();
    })
    .then((blob) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = newName;
      downloadLink.click();
    })
    .catch((error) => {
      console.error("Error downloading the file:", error);
    });
};
