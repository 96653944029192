import { Tab } from "@headlessui/react";
import Button from "atoms/Button/Button";
import cn from "classnames";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { FC } from "react";
import { useDispatch } from "react-redux";
import NodeUpload from "./NodeUpload";

interface UploadFeatureProps {
  apiKey: string;
  externalId: string;
  selectedFile: File | null;
  setExternalId: (externalId: string) => void;
  setSelectedFile: (file: File | null) => void;
  setApiKey: (apiKey: string) => void;
  onCopy: () => void;
  handleUpload: (file: File) => void;
  error: boolean;
  isUploadLoading: boolean;
}

const Upload: FC<UploadFeatureProps> = ({
  setExternalId,
  handleUpload,
  setSelectedFile,
  setApiKey,
  onCopy,
  apiKey,
  externalId,
  selectedFile,
  error,
  isUploadLoading,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="px-6 my-6">
        <h3 className="my-2">File Upload:</h3>
        <p className="text-black">
          Real-time file uploading using our API. The API supports PDF files.
        </p>
        <ol className="!list-inside !ml-4 !list-decimal text-base">
          <li>Enter your generated API key.</li>
          <li>
            External id can be a specific id used for your own reference. This
            can be the pitchdeck id. If no external id is provided, a random id
            will be generated and returned in the response data as id.
          </li>
          <li>Drag and drop/or choose a PDF file you wish to upload.</li>
          <li>Try it out!</li>
          <li>
            The response from the upload will be returned on the right side.
          </li>
        </ol>
      </div>
      <div className="w-full rounded-b-md bg-gray-100 border-gray-300 border py-6 px-12 mt-4">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-md w-52 float-right bg-gray-100 p-1 mx-6">
            <Tab
              className={({ selected }) =>
                cn(
                  //Pointer events none is used to prevent the tab from being clicked until we have more options
                  "w-full rounded-lg py-1 text-sm font-medium leading-5 text-aqua-400 pointer-events-none",
                  "ring-white focus:outline-none focus:ring-0",
                  selected
                    ? "bg-white shadow"
                    : "text-gray-600 hover:text-aqua-400"
                )
              }
            >
              Node.js
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className="rounded-xl py-2 ring-white ring-opacity-0 focus:outline-none focus:ring-0 px-6">
              <NodeUpload
                apiKey={apiKey}
                externalId={externalId}
                selectedFile={selectedFile}
                setApiKey={setApiKey}
                setExternalId={setExternalId}
                setSelectedFile={setSelectedFile}
                onCopy={onCopy}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <div className="w-full flex justify-end">
          <Button
            onClick={() => {
              if (error) {
                dispatch(
                  triggerSnack({
                    type: "error",
                    title: " ",
                    message: `Missing parameters! ${
                      !selectedFile ? "File not selected" : ""
                    }`,
                  })
                );
                return;
              }
              if (selectedFile) {
                handleUpload(selectedFile);
              }
            }}
            color="primary"
            loading={isUploadLoading}
            disabled={!apiKey && !selectedFile && isUploadLoading}
            className="rounded-lg"
          >
            <span className="font-bold">Try it out!</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Upload;
