import { InvestorPlans } from "pages/plans/InvestorPlans";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const UpgradePlanModalContext = createContext({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const UpgradePlanModalProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <UpgradePlanModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
      {isOpen && <UpgradePlanModal onClose={closeModal} isOpen={isOpen} />}
    </UpgradePlanModalContext.Provider>
  );
};

export const useUpgradePlanModal = () => useContext(UpgradePlanModalContext);

export const UpgradePlanModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const closeModal = () => {
    if (dialogRef.current) {
      dialogRef.current.close();
      onClose();
    }
  };

  return (
    <dialog
      className="fixed top-24 z-[70] p-0 bg-transparent w-full transform -translate-x-1/2 left-1/2"
      ref={dialogRef}
    >
      <div>
        <InvestorPlans isUpgradeModal={true} closeModal={closeModal} />
      </div>
    </dialog>
  );
};
