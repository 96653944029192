import { BillingFrequency } from "../interfaces";

export const mapBillingFrequency = (frequency: BillingFrequency) => {
  switch (frequency) {
    case "MONTHLY":
      return "Month";
    case "YEARLY":
      return "Year";
    case "SIX_MONTHLY":
      return "6 months";
    case "THREE_MONTHLY":
      return "3 months";
    default:
      break;
  }
};
