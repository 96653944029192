import { FC } from "react";
import withValueEditable from "organisms/ValueEditable/ValueEditable";
import { BUSINESS_CATEGORY } from "core/consts";
import { FieldName } from "gql/graphql";
import { Value, ValueProps } from "./Value";

interface Props {
  businessCategory: any[];
  businessCategoryNote: string;
  uiRepresentation: string | null | undefined;
}

const EditableValue = withValueEditable<ValueProps>(
  Value,
  FieldName.GeneralInfoProduct
);

const Product: FC<Props> = ({
  businessCategory,
  businessCategoryNote,
  uiRepresentation,
}) => {
  const getContent = () => {
    if (uiRepresentation) return uiRepresentation;
    if (businessCategory && businessCategory.length > 0)
      return businessCategory
        .map((category) => {
          if (category === "OTHER") {
            return businessCategoryNote;
          } else {
            return (BUSINESS_CATEGORY as any)[category];
          }
        })
        .join(", ");
    return "";
  };

  return (
    <div className="grid grid-cols-2 md:auto-rows-auto md:grid-rows-1 dark:text-white/80">
      <h6 className="font-medium">Product</h6>
      <EditableValue content={getContent()} />
    </div>
  );
};

export default Product;
