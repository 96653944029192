import type { TimeLabelProps } from "./interfaces";
import { FC, useEffect, useReducer } from "react";
import { getTimeSince } from "utils/generalUtils";

const UPDATE_LABEL_AFTER = 1000 * 60;

const TimeLabel: FC<TimeLabelProps> = ({ time }) => {
  const [, update] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const id = window.setInterval(update, UPDATE_LABEL_AFTER);

    return () => window.clearInterval(id);
  }, []);

  return <span>{getTimeSince(time)}</span>;
};

export default TimeLabel;
