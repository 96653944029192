// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const updateStatusID = createAction("page/auth/confirm", (statusID) => ({
  payload: { token },
}));

export const initialize = createAction(
  "page/auth/confirm/initialitze",
  ({ token, lang }) => ({
    payload: { token, lang },
  })
);
