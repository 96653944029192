import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import MainPanel from "./MainPanel";
import { useActivationContext } from "../context";

const MainPanelWrapper = () => {
  const user = useSelector(getUser);
  const { activationStatus, isFromOnboarding } = useActivationContext();

  if (!user) return null;
  if (!isFromOnboarding) return null;
  if (activationStatus?.Main) return null;
  if (activationStatus?.Dealflow && activationStatus?.BoardsOrAnalytics)
    return null;

  // Disabling activation for investors
  if (user.type === "investor") return null;

  return <MainPanel userType={user.type} />;
};

export default MainPanelWrapper;
