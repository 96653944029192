import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "models/user/selectors";
import { useEffect } from "react";

type Policy = (userData: any | null) => string | undefined;

type Guard = Policy[];

export const useGuard = (guard: Guard) => {
  const userData = useSelector(getUser);
  const navigate = useNavigate();

  useEffect(() => {
    for (const policy of guard) {
      const path = policy(userData);
      if (path) {
        navigate(path);
        break;
      }
    }
  }, []);

  return null;
};
