//@ts-nocheck
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMessages } from "../selectors";
import { getContactMessages } from "../sagaActions";
import Pagination from "atoms/Pagination/Pagination";
import Message from "./Message";
import { startWatchWorker, stopPolling } from "../sagaActions";

const LIMIT = 10;

const MessageList = () => {
  const dispatch = useDispatch();
  const { messages, totalCount, searchStart, searchCount } =
    useSelector(getMessages);

  useEffect(() => {
    dispatch(startWatchWorker());

    return () => {
      dispatch(stopPolling());
    };
  }, []);

  return (
    <div>
      <div className="flex-col justify-between hidden pt-4 mx-12 mt-2 -mb-2 text-sm text-gray-500 sm:flex sm:flex-row ">
        <span className="sm:flex-1">Sender</span>
        <span className="sm:flex-1">Received</span>
        <span className="sm:flex-1">Email address</span>
        <span className="sm:flex-[2]">Message</span>
      </div>

      {messages.map((item) => (
        <Message key={item.id} message={item} />
      ))}

      <div className="mx-4">
        <Pagination
          pageCount={Math.ceil(totalCount / LIMIT)}
          onPageChange={(page) => {
            dispatch(
              getContactMessages({
                start: page.selected * LIMIT,
                limit: LIMIT,
              })
            );
          }}
          forcePage={searchStart / LIMIT}
          entries={
            <div className="font-normal text-gray-500">
              {`Showing ${searchStart + 1}-${
                searchStart + searchCount
              } of ${totalCount} requests`}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default MessageList;
