const easeInOut = [0.455, 0.03, 0.515, 0.955];

export const pageAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: { opacity: 1, transition: { ease: easeInOut, duration: 0.3 } },
};

export const dropdownAnimation = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    transition: { ease: easeInOut, duration: 0.2 },
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: { ease: easeInOut, duration: 0.2 },
  },
};

export const modalBgAnimation = {
  hidden: {
    opacity: 0,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
  show: {
    opacity: 1,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
};

export const modalAnimation = {
  hidden: {
    opacity: 0,
    y: 40,
    scale: 0.95,
    transition: { ease: "easeInOut", duration: 0.3 },
  },
  show: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { ease: "easeInOut", duration: 0.3, delay: 0.2 },
  },
};

export const staggerAnimation = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const animation = {
  hidden: {
    opacity: 0,
    y: 40,
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: easeInOut, duration: 0.3 },
  },
};

export const animationCenter = {
  hidden: {
    opacity: 0,
    y: "-30%",
    x: "-50%",
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    opacity: 1,
    y: "-50%",
    x: "-50%",
    transition: { ease: easeInOut, duration: 0.3 },
  },
};

export const fade = {
  hidden: {
    opacity: 0,
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    opacity: 1,
    transition: { ease: easeInOut, duration: 0.3 },
  },
};

export const menuDrawerFade = {
  hidden: {
    opacity: 0,
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    opacity: 1,
    transition: { ease: easeInOut, duration: 0.3, delay: 0.1 },
  },
};

export const menuDrawer = {
  hidden: {
    x: "100%",
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    x: "0%",
    transition: { ease: easeInOut, duration: 0.3 },
  },
};

export const podcastPlayerAnimation = {
  hidden: {
    y: 140,
    transition: { ease: easeInOut, duration: 0.3 },
  },
  show: {
    y: 0,
    transition: { ease: easeInOut, duration: 0.3, staggerChildren: 0.1 },
  },
};
