import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import withContentEditable, {
  HOCInjectedProps,
} from "organisms/ContentEditable/ContentEditable";
import { FieldName } from "gql/graphql";

interface Props {
  content: string;
}

const Team: FC<Props & HOCInjectedProps> = ({ content }) => {
  return (
    <div>
      <h2 className="mb-1 font-bold capitalize">Team</h2>
      <div className="text-[#24272B] dark:text-gray-400 rich-text-content-wrapper">
        <PureHtml content={content} tag="p" />
      </div>
    </div>
  );
};

export default withContentEditable<Props>(Team, FieldName.ExecuteveSummaryTeam);
