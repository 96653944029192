// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const triggerMenuDrawer = createAction(
  "organisms/navigationLayout/menuDrawer",
  (payload) => ({
    payload,
  })
);

export const switchWorkspaces = createAction(
  "organisms/navigationLayout/switchWorkspaces",
  (payload) => ({
    payload,
  })
);
