import React, { FC } from "react";
import PureHtml from "atoms/PureHtml";
import { FieldName } from "gql/graphql";
import withContentEditable from "organisms/ContentEditable/ContentEditable";

interface Props {
  content: string;
}

const BlueOcean: FC<Props> = ({ content }) => {
  return (
    <div className="mt-4">
      <h2 className="text-lg text-gray-700 dark:text-white/80 mb-2">
        Blue ocean
      </h2>
      <PureHtml content={content} tag="p" />
    </div>
  );
};

export default withContentEditable<Props>(
  BlueOcean,
  FieldName.MarketOppBlueOcean
);
