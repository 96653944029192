// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getSocialNetworks = createAction(
  "companySettings/social-networks/fetch"
);

export const saveSocialNetworks = createAction(
  "companySettings/social-networks/save",
  (payload) => ({
    payload,
  })
);
