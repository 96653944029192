import {
  ProfileStep,
  BasicStep,
  TeamsStep,
  ElevatorPitchStep,
  FundraisingStep,
  PitchDeckStep,
  SocialNetworksStep,
  ImpactStep,
  TractionStep,
  ProductStep,
  MarketStep,
  BusinessModelStep,
  TimingStep,
} from "./steps";

export const STEP = {
  PROFILE: "profile",
  BASIC: "basic",
  TEAMS: "teams",
  ELEVATOR_PITCH: "elevator-pitch",
  FUNDRAISING: "fundraising",
  PITCH_DECK: "pitch-deck",
  SOCIAL_NETWORKS: "social-networks",
  IMPACT: "impact",
  TRACTION: "traction",
  PRODUCT: "product",
  MARKET: "market",
  BUSINESS_MODEL: "business-model",
  TIMING: "timing",
};

export const STEPS = {
  [STEP.PROFILE]: <ProfileStep />,
  [STEP.BASIC]: <BasicStep />,
  [STEP.TEAMS]: <TeamsStep />,
  [STEP.ELEVATOR_PITCH]: <ElevatorPitchStep />,
  [STEP.FUNDRAISING]: <FundraisingStep />,
  [STEP.PITCH_DECK]: <PitchDeckStep />,
  [STEP.SOCIAL_NETWORKS]: <SocialNetworksStep />,
  [STEP.IMPACT]: <ImpactStep />,
  [STEP.TRACTION]: <TractionStep />,
  [STEP.PRODUCT]: <ProductStep />,
  [STEP.MARKET]: <MarketStep />,
  [STEP.BUSINESS_MODEL]: <BusinessModelStep />,
  [STEP.TIMING]: <TimingStep />,
};
