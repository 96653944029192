import { NavigationConfig } from "./interfaces";
import { ReactComponent as AnalyticsIcon } from "images/navigations/analytics.svg";
import { ReactComponent as DealflowIcon } from "images/navigations/dealflow.svg";
import { ReactComponent as MessagesIcon } from "images/navigations/message.svg";
import { ReactComponent as PendingApplicationIcon } from "images/navigations/pending-applications.svg";
import { ReactComponent as ProfileIcon } from "images/navigations/profile.svg";
import { ReactComponent as ApplicationsIcon } from "images/navigations/applications.svg";
import { ReactComponent as AdminCompanies } from "images/navigations/admin-companies.svg";
import { ReactComponent as SettingsIcon } from "images/navigations/gear.svg";
import { ReactComponent as RocketIcon } from "images/navigations/rocket-launch.svg";
import { ReactComponent as GiftIcon } from "images/navigations/gift.svg";
import { ReactComponent as TeamIcon } from "images/navigations/team.svg";
import { ReactComponent as BookIcon } from "images/navigations/book.svg";
import { ReactComponent as UploadIcon } from "images/navigations/upload.svg";
import { ReactComponent as StarIcon } from "images/navigations/star.svg";

import { DIVIDER } from "./constants";

export const mobileSideNav: NavigationConfig = {
  STARTUP: [
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/startup-dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    {
      id: "MESSAGES",
      title: "Messages",
      to: "/messages",
      Icon: MessagesIcon,
    },
    {
      id: "ANALYTICS",
      title: "Analytics",
      to: "/myspace",
      Icon: AnalyticsIcon,
    },
    DIVIDER,
    {
      id: "PROFILE",
      title: "Profile",
      to: "/company-profile",
      Icon: ProfileIcon,
    },
    {
      id: "ACCOUNT",
      title: "Account",
      to: "/account",
      Icon: SettingsIcon,
    },
  ],
  ACCELERATOR: [
    {
      id: "AIA",
      title: "Upload",
      to: "/aia",
      Icon: UploadIcon,
      elementId: "aia-menu-item",
    },
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    {
      id: "APPLICATIONS",
      title: "Applications",
      to: "/applications",
      Icon: ApplicationsIcon,
    },
    {
      id: "PENDING",
      title: "Pending",
      to: "/pending-applications",
      Icon: PendingApplicationIcon,
    },
    DIVIDER,
    {
      id: "PROFILE",
      title: "Account",
      to: "/account/account",
      Icon: ProfileIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
    {
      id: "MANAGE_TEAM",
      title: "Manage team",
      to: "/account/team-members",
      Icon: TeamIcon,
    },
    {
      id: "API_DOCS",
      title: "API Docs",
      to: "/account/api-docs",
      Icon: BookIcon,
    },
    {
      id: "BILLING",
      title: "Billing",
      to: "/account/billing",
      Icon: RocketIcon,
    },
    {
      id: "UPGRADE",
      title: "Upgrade to Pro",
      to: "/plans",
      Icon: RocketIcon,
    },
  ],
  INVESTOR: [
    {
      id: "AIA",
      title: "Upload",
      to: "/aia",
      Icon: UploadIcon,
      elementId: "aia-menu-item",
    },
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    DIVIDER,
    {
      id: "PROFILE",
      title: "Account",
      to: "/account/account",
      Icon: ProfileIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
    {
      id: "MANAGE_TEAM",
      title: "Manage team",
      to: "/account/team-members",
      Icon: TeamIcon,
    },
    {
      id: "API_DOCS",
      title: "API Docs",
      to: "/account/api-docs",
      Icon: BookIcon,
    },
    {
      id: "BILLING",
      title: "Billing",
      to: "/account/billing",
      Icon: RocketIcon,
    },
    {
      id: "UPGRADE",
      title: "Upgrade to Pro",
      to: "/plans",
      Icon: RocketIcon,
    },
  ],
  ADMIN: [
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
    },
    {
      id: "APPLICATIONS",
      title: "Applications",
      to: "/applications",
      Icon: ApplicationsIcon,
    },
    {
      id: "DISCOVER_COMPANIES",
      title: "Discover Admin",
      to: "/admin/discover-companies",
      Icon: AdminCompanies,
    },
    {
      id: "PENDING",
      title: "Pending",
      to: "/pending-applications",
      Icon: PendingApplicationIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
    {
      id: "MANAGE_TEAM",
      title: "Manage team",
      to: "/account/team-members",
      Icon: TeamIcon,
    },
    {
      id: "API_DOCS",
      title: "API Docs",
      to: "/account/api-docs",
      Icon: BookIcon,
    },
    {
      id: "BILLING",
      title: "Billing",
      to: "/account/billing",
      Icon: RocketIcon,
    },
    {
      id: "UPGRADE",
      title: "Upgrade to Pro",
      to: "/plans",
      Icon: RocketIcon,
    },
  ],
};

export const desktopSideNav: NavigationConfig = {
  STARTUP: [
    // {
    //   id: "EXPLORE",
    //   title: "Explore",
    //   to: "/explore",
    //   Icon: RocketIcon,
    //   elementId: "explore-menu-item",
    // },
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/startup-dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    {
      id: "MESSAGES",
      title: "Messages",
      to: "/messages",
      Icon: MessagesIcon,
    },
    {
      id: "ANALYTICS",
      title: "Analytics",
      to: "/myspace",
      Icon: AnalyticsIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
      placement: "bottom", // Only the first bottom item must be flagged
    },
  ],
  ACCELERATOR: [
    {
      id: "AIA",
      title: "Upload",
      to: "/aia",
      Icon: UploadIcon,
      elementId: "aia-menu-item",
    },
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    {
      id: "APPLICATIONS",
      title: "Applications",
      to: "/applications",
      Icon: ApplicationsIcon,
    },
    {
      id: "PENDING",
      title: "Pending",
      to: "/pending-applications",
      Icon: PendingApplicationIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
  ],
  INVESTOR: [
    {
      id: "AIA",
      title: "Upload",
      to: "/aia",
      Icon: UploadIcon,
      elementId: "aia-menu-item",
    },
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
      elementId: "dealflow-menu-item",
    },
    {
      id: "Upgrade",
      title: "Upgrade plan",
      to: "/plans",
      Icon: StarIcon,
      placement: "bottom", // Only the first bottom item must be flagged
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
  ],
  ADMIN: [
    {
      id: "DEALFLOW",
      title: "Dealflow",
      to: "/dealflow",
      Icon: DealflowIcon,
    },
    {
      id: "DISCOVER_COMPANIES",
      title: "Discover Admin",
      to: "/admin/discover-companies",
      Icon: AdminCompanies,
    },
    {
      id: "APPLICATIONS",
      title: "Applications",
      to: "/applications",
      Icon: ApplicationsIcon,
    },
    {
      id: "PENDING",
      title: "Pending",
      to: "/pending-applications",
      Icon: PendingApplicationIcon,
    },
    {
      id: "Referral",
      title: "Referral",
      to: "/account/referral",
      Icon: GiftIcon,
    },
  ],
};
