// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isEnabled: false,
};

export const name = "organisms/navigationLayout";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    triggerMenuDrawer(state, { payload: { isEnabled } }) {
      return {
        ...state,
        isEnabled,
      };
    },
  },
});

export default slice;

export const { triggerMenuDrawer } = slice.actions;
