// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const setGlobalState = createAction(
  "model/globalState/update",
  (stateArgs) => ({
    payload: {
      ...stateArgs,
    },
  })
);

export const setCountryCode = createAction(
  "model/globalState/countryCode/update",
  (countryCode) => ({
    payload: {
      countryCode,
    },
  })
);
