// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import { triggerMenuDrawer, switchWorkspaces } from "./sagaActions";
import { triggerMenuDrawer as triggerMenuDrawerReducer } from "./reducer";
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { WORKSPACE_SELECTOR } from "./constants";
import { fetchUserData } from "models/user/sagaActions";
import { SWITCH_WORKSPACE } from "queries/general/workspaces";

const client = getClient();

function* handleTriggerMenuDrawer({ payload: { isEnabled } }) {
  yield put(triggerMenuDrawerReducer({ isEnabled }));
}

const doSwitchWorkspace = async (workspaceId) => {
  try {
    return await client.mutate({
      mutation: SWITCH_WORKSPACE,
      variables: { companyMemberId: workspaceId },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

function* switchWorkspacesSaga({ payload: { workspaceId, lang } }) {
  yield put(
    toggleLoader({
      component: WORKSPACE_SELECTOR,
      isLoading: true,
    })
  );

  const { isError } = yield doSwitchWorkspace(workspaceId);

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not switch workspace, please try again later",
      })
    );

    yield put(
      toggleLoader({
        component: WORKSPACE_SELECTOR,
        isLoading: false,
      })
    );

    return;
  }

  // Temporay solution until we list all data points that need updating/refetching after changing WS
  window.location.reload();

  // yield put(fetchUserData({ lang }));

  yield put(
    toggleLoader({
      component: WORKSPACE_SELECTOR,
      isLoading: false,
    })
  );
}

export default function* rootSaga() {
  return [
    yield takeEvery(triggerMenuDrawer, handleTriggerMenuDrawer),
    yield takeEvery(switchWorkspaces, switchWorkspacesSaga),
  ];
}
