// @ts-nocheck
import { SubmitHandler, useForm } from "react-hook-form";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessTiming } from "./selectors";
import { PageHeader, StepActionButton } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { saveBusinessTiming } from "./sagaActions";
import { ReactComponent as TimingIcon } from "images/timing-icon.svg";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";

type FormValues = {
  businessTiming: string;
};

export default () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();
  const { lang } = useLang();
  const dispatch = useDispatch();

  const data = useSelector(getBusinessTiming);
  const loading = useSelector((state) => isLoadingEnabled(state, STEP.TIMING));

  const onSubmit: SubmitHandler<FormValues> = async () => {
    const { businessTiming } = getValues();
    dispatch(
      saveBusinessTiming({
        lang,
        input: {
          businessTiming,
        },
      })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <TimingIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.timing}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>{lang.timingDescription}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <div className="mb-2 text-sm font-medium">
          {lang.whyIsItGreatTimeForYoueCompany}
        </div>
        <TextArea
          rows="3"
          id="businessTiming"
          name="businessTiming"
          className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm height-set ${
            errors.businessTiming
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
              : ""
          }`}
          placeholder={lang.addHowYouThinkAboutYourMarketTimingHere}
          defaultValue={data?.businessTiming}
          {...register("businessTiming")}
          maxLength={1000}
        ></TextArea>
      </div>

      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          type="button"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
