import type { GoogleInputPlace } from "molecules/Form/interfaces";
import { FC, useState } from "react";
import ButtonGroup from "molecules/Form/ButtonGroup";
import {
  BUSINESS_MODEL,
  CURRENCY_FORMAT,
  FUNDRAISING_STAGES,
  INDUSTRY_CATEGORIES_SWEDISH,
} from "core/consts";
import { TextField } from "pages/onboard/components";
import GoogleInput from "molecules/Form/GoogleInput";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyList } from "models/user/selectors";
import Button from "atoms/Button/Button";
import { useMutation } from "@apollo/client";
import { triggerSnack } from "organisms/Snack/sagaActions";
import cn from "classnames";
import {
  ADD_CUSTOM_INVESTOR_TO_DEALFLOW,
  EDIT_CUSTOM_INVESTOR,
} from "queries/startup/startupDealflow";
import { ModalContentProps } from "./interfaces";
import { BusinessModel, InvestmentStage } from "gql/graphql";
import { Listbox, RadioGroup } from "@headlessui/react";
import InvestmentRange from "./InvestmentRange";
import SelectIndustries from "pages/dealflow/SelectIndustries";
import { mixpanelAddCustomInvestorToDealflow } from "core/mixpanel/Mixpanel";

const INVESTOR_TYPES = [
  {
    id: "ANGEL_INVESTOR",
    title: "Angel Investor",
  },
  {
    id: "VC",
    title: "VC",
  },
  {
    id: "FAMILY_OFFICE",
    title: "Family Office",
  },
];

const RADIO_OPTIONS = { yes: "Yes", no: "No" };

type IndustryKey = keyof typeof INDUSTRY_CATEGORIES_SWEDISH;

const AddNewInvestor: FC<ModalContentProps> = ({
  onClose,
  refetch,
  record,
}) => {
  const dispatch = useDispatch();
  const currencies = useSelector(getCurrencyList);
  const [name, setName] = useState(record?.company?.displayName ?? "");
  const [type, setType] = useState(record?.company?.investorType ?? "");
  const [industries, setIndustries] = useState<IndustryKey[]>(
    (record?.company?.industries as IndustryKey[]) ?? []
  );

  const [location, setLocation] = useState(record?.company?.country ?? "");

  const [businessModels, setBusinessModels] = useState<string[]>(
    (record?.company.businessModels as BusinessModel[]) || []
  );

  const [investmentRangesMin, setInvestmentRangesMin] = useState(
    record?.company?.investmentRanges?.min ?? 1000
  );

  const [investmentRangesMax, setInvestmentRangesMax] = useState(
    record?.company?.investmentRanges?.max ?? 1000
  );

  const [supportAsAdvisor, setSupportAsAdvisor] = useState(
    record?.company?.supportAsAdvisor ?? false
  );
  const [investmentStage, setInvestmentStage] = useState<string[]>(
    (record?.company?.investmentStage as InvestmentStage[]) || []
  );

  const [place, setPlace] = useState<GoogleInputPlace>();

  const options = Object.keys(INDUSTRY_CATEGORIES_SWEDISH)
    .filter((key) => !industries.includes(key as IndustryKey)) // exclude industries already selected
    .map((key) => ({
      key,
      value: INDUSTRY_CATEGORIES_SWEDISH[key as IndustryKey],
    }));

  const selectedIndustries = Object.keys(INDUSTRY_CATEGORIES_SWEDISH)
    .filter((key) => industries.includes(key as IndustryKey))
    .map((key) => ({
      key,
      value: INDUSTRY_CATEGORIES_SWEDISH[key as IndustryKey],
    }));

  const [addToDealflow, { loading: isAddLoading }] = useMutation(
    ADD_CUSTOM_INVESTOR_TO_DEALFLOW
  );
  const [editInvestor, { loading: isEditLoading }] =
    useMutation(EDIT_CUSTOM_INVESTOR);

  const loading = isAddLoading || isEditLoading;
  const currency = currencies?.[0] || { symbol: "€" };
  const currencyCode = currency?.code || "EUR";
  const isValid = name && type;

  const changeInvestmentRanges = (value: number[]) => {
    setInvestmentRangesMin(value[0]);
    setInvestmentRangesMax(value[1]);
  };

  const onIndustriesChange = (value: IndustryKey[]) => {
    setIndustries([...industries, value[value.length - 1]]);
  };

  const removeIndustry = (
    industry: string | [string, { key: string; value: any }]
  ) => {
    setIndustries(industries.filter((i) => i !== industry));
  };

  const setInvestorType = (id: string) => {
    setType(id);
  };

  const handleSave = async () => {
    if (!isValid) {
      return;
    }
    const isEdit = Boolean(record);
    const location = {
      city: place?.city,
      state: place?.state,
      country: place?.country,
      lat: place?.lat,
      long: place?.long,
      fullAddress: place?.fullAddress,
    };

    try {
      const snackParams = {
        title: "Investor added",
        message: "New investor added to your dealflow!",
      };

      if (isEdit) {
        await editInvestor({
          variables: {
            recordId: record?.id,
            location: place ? location : undefined,
            investorType: type,
            industries: industries,
            businessModels: businessModels,
            investmentRanges: {
              min: investmentRangesMin,
              max: investmentRangesMax,
            },
            supportAsAdvisor: supportAsAdvisor,
            investmentStage: investmentStage,
          },
        });

        snackParams.title = "Investor updated";
        snackParams.message = "The investor has been successfully updated";
      } else {
        await addToDealflow({
          variables: {
            name,
            location,
            investorType: type,
            industries: industries,
            businessModels,
            investmentRanges: {
              min: investmentRangesMin,
              max: investmentRangesMax,
            },
            supportAsAdvisor,
            investmentStage,
          },
        });
        mixpanelAddCustomInvestorToDealflow(
          name,
          type,
          location.country,
          industries,
          businessModels,
          investmentRangesMin,
          investmentRangesMax,
          supportAsAdvisor,
          investmentStage
        );
      }

      refetch();
      onClose();

      dispatch(
        triggerSnack({
          type: "success",
          ...snackParams,
        })
      );
    } catch (error) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Error occurred",
          message: "Something went wrong, please try again later",
        })
      );
    }
  };

  return (
    <div className={cn(loading && "opacity-60 pointer-events-none")}>
      <TextField
        className="mb-2"
        label="Full name"
        value={name}
        onChange={setName}
        disabled={Boolean(record)}
      />
      <div className="mb-2">
        <Listbox onChange={setInvestorType} value={type}>
          <Listbox.Label className="ml-2 text-sm text-gray-400">
            Type of investor
          </Listbox.Label>
          <Listbox.Button className="relative py-1 px-2 cursor-pointer !font-normal w-full pr-10 !text-sm text-left text-gray-500 bg-white border border-gray-200 shadow-sm rounded-xl focus:outline-none focus:ring-1 sm:text-sm">
            {type
              ? INVESTOR_TYPES.find((item) => item.id === type)?.title
              : "-- Please select --"}
          </Listbox.Button>
          <Listbox.Options className="absolute w-full max-w-[400px] py-1 mt-1 overflow-auto text-base bg-white border rounded-xl max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {INVESTOR_TYPES.map((item) => (
              <Listbox.Option key={item.id} value={item.id}>
                <span className="block px-2 py-1 text-sm font-normal truncate cursor-pointer">
                  {item.title}
                </span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Listbox>
      </div>
      <GoogleInput
        label="Location"
        labelClass="ml-2 text-sm font-normal !text-gray-400"
        getPlace={(newPlace: GoogleInputPlace) => {
          setPlace(newPlace);
          setLocation(newPlace.autoCompleteVal);
        }}
        value={location}
        onChange={(e: any) => setLocation(e.target.value)}
      />
      <div className="relative py-3 mt-2 mb-3 ml-2">
        <label
          htmlFor="investmentRounds"
          className="block mb-1 text-sm text-gray-400 text-opacity-100"
        >
          Ticket size
        </label>
        <div className="mb-2 text-xs text-gray-400">
          <span className="text-xs font-medium text-aqua-400">
            {currencyCode} {CURRENCY_FORMAT(investmentRangesMin)} -{" "}
            {currencyCode} {CURRENCY_FORMAT(investmentRangesMax)}
          </span>
        </div>
        <div className="relative mx-2 my-2">
          <InvestmentRange
            currencyName={currencyCode}
            minRange={investmentRangesMin}
            maxRange={investmentRangesMax}
            changeInvestmentRange={(range: any) => {
              changeInvestmentRanges(range);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col items-start mt-2">
        <label
          className="mb-2 ml-2 text-sm text-gray-400"
          htmlFor="investment-stage"
        >
          Investment stage
        </label>
        <ButtonGroup
          id="investment-stage"
          options={Object.entries(FUNDRAISING_STAGES)}
          className="!mb-2 !mr-2"
          onChange={setInvestmentStage}
          defaultValue={investmentStage}
        />
      </div>
      <div className="flex flex-col w-full md:w-auto">
        <SelectIndustries
          options={options}
          selectedIndustries={selectedIndustries}
          onIndustriesChange={onIndustriesChange}
          removeIndustry={removeIndustry}
        />
      </div>
      <div className="flex flex-col items-start mt-2">
        <label
          className="mb-2 ml-2 text-sm text-gray-400"
          htmlFor="business-relations"
        >
          Business relations
        </label>
        <ButtonGroup
          id="business-relations"
          options={Object.entries(BUSINESS_MODEL)}
          className="!mb-2 !mr-2"
          onChange={setBusinessModels}
          defaultValue={businessModels}
        />
      </div>
      <RadioGroup
        value={supportAsAdvisor}
        onChange={setSupportAsAdvisor}
        className="gap-2 cursor-pointer"
      >
        <RadioGroup.Label className="mb-2 ml-2 text-sm text-gray-400">
          Advisor
        </RadioGroup.Label>
        <div className="flex flex-row gap-2">
          <RadioGroup.Option value={true}>
            {({ checked }) => (
              <div
                className={
                  checked
                    ? "bg-aqua-400 text-white px-4 rounded-full py-2"
                    : "border-gray-200 border px-4 py-2 rounded-full"
                }
              >
                Yes
              </div>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value={false}>
            {({ checked }) => (
              <div
                className={
                  checked
                    ? "bg-aqua-400 text-white px-4 rounded-full py-2"
                    : "border-gray-200 border px-4 py-2 rounded-full"
                }
              >
                No
              </div>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>

      <div className="flex justify-end mt-6">
        <Button
          onClick={handleSave}
          size="small"
          color="primary"
          disabled={!isValid}
          loading={loading}
        >
          {record ? "Save" : "Add Investor"}
        </Button>
      </div>
    </div>
  );
};

export default AddNewInvestor;
