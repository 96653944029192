// @ts-nocheck
import { takeEvery, put } from "redux-saga/effects";
import {
  initializeEditStartupProfilePage,
  updateStartupProfile,
} from "./sagaActions";
import { getClient } from "core/utility";
import {
  GET_STARTUP_PROFILE_DATA,
  UPDATE_STARTUP_PROFILE_DATA,
} from "queries/general/startupProfile";
import { EDIT_STARTUP_PROFILE } from "./constants";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { updateStartupProfileData } from "./reducer";

const client = getClient();

const fetchStartupProfile = async () => {
  try {
    return await client.query({
      query: GET_STARTUP_PROFILE_DATA,
      fetchPolicy: "network-only",
    });
  } catch (error) {
    console.log(error);
    return { isError: true, error };
  }
};

function* handleInitializeEditStartupProfilePage() {
  yield put(
    toggleLoader({
      component: EDIT_STARTUP_PROFILE,
      isLoading: true,
    })
  );

  const { data, isError, error } = yield fetchStartupProfile();

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not fetch startup data, please try again later",
      })
    );
  } else {
    yield put(updateStartupProfileData(data));
  }

  yield put(
    toggleLoader({
      component: EDIT_STARTUP_PROFILE,
      isLoading: false,
    })
  );
}

function* handleUpdateStartupProfile({ payload }) {
  try {
    const { data } = yield client.mutate({
      mutation: UPDATE_STARTUP_PROFILE_DATA,
      fetchPolicy: "network-only",
      variables: { input: payload },
    });

    yield put(updateStartupProfileData(data));
  } catch (error) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not update startup data, please try again later",
      })
    );
  }
}

export default function* rootSaga() {
  return [
    yield takeEvery(
      initializeEditStartupProfilePage,
      handleInitializeEditStartupProfilePage
    ),
    yield takeEvery(updateStartupProfile, handleUpdateStartupProfile),
  ];
}
