import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GetStartupProfileDataQuery } from "gql/graphql";

const initialState: GetStartupProfileDataQuery["me"] = {
  user: null,
  company: null,
};

const name = "editStartupProfile";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateStartupProfileData: (
      state,
      { payload: { me } }: PayloadAction<GetStartupProfileDataQuery>
    ) => {
      if (me?.user) state.user = me?.user;
      if (me?.company) state.company = me?.company;
    },
  },
});

export default slice;

export const { updateStartupProfileData } = slice.actions;
