import { FC, useState, useCallback, Fragment } from "react";
import Slider from "rc-slider";
import type { ProfilePictureEditModalProps } from "./interfaces";
import Cropper from "react-easy-crop";
import { Dialog, Transition } from "@headlessui/react";
import { Point, Area } from "react-easy-crop/types";
import { getCroppedImg } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "rc-slider/assets/index.css";

const INITIAL_ZOOM = 1;
const INITIAL_CROP = { x: 0, y: 0 };
const MIN_ZOOM = 0.1;
const MAX_ZOOM = 1.9;
const ZOOM_STEP = 0.1;

const ProfilePictureEditModal: FC<ProfilePictureEditModalProps> = ({
  label,
  imageSrc,
  fileName,
  fileType,
  isOpen,
  onClose,
  onCancel,
  onEditCompleted,
}) => {
  const [crop, setCrop] = useState<Point>(INITIAL_CROP);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const handleSave = async () => {
    const croppedImage = await getCroppedImg(
      imageSrc,
      fileName,
      fileType,
      croppedAreaPixels
    );
    if (croppedImage) {
      const { preview, file } = croppedImage;
      onEditCompleted(preview, file);
      onClose();
    }
    setZoom(INITIAL_ZOOM);
    setCrop(INITIAL_CROP);
  };

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[200]" onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-3xl transition-all transform bg-white shadow-xl h-[600px] rounded-2xl">
                <h2 className="mt-6 ml-6 text-xl font-normal text-left">
                  {label || " Upload profile picture"}
                </h2>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  maxZoom={MAX_ZOOM}
                  minZoom={MIN_ZOOM}
                  aspect={1}
                  restrictPosition={false}
                  cropShape="round"
                  cropSize={{ width: 250, height: 250 }}
                  showGrid={false}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  classes={{
                    containerClassName: "m-6 mb-40 mt-16",
                  }}
                />
                <div className="absolute bottom-0 left-0 right-0 flex flex-col p-6">
                  <div className="flex flex-col">
                    <p className="self-start text-sm font-normal text-black">
                      Zoom
                    </p>
                    <div className="flex items-center">
                      <button
                        className="mt-2 mr-4 text-xl hover:text-[#18BEB3]"
                        disabled={zoom <= MIN_ZOOM!}
                        type="button"
                        onClick={() => setZoom(zoom - ZOOM_STEP)}
                      >
                        <FontAwesomeIcon icon={["fal", "minus"]} />
                      </button>

                      <Slider
                        min={MIN_ZOOM}
                        max={MAX_ZOOM}
                        onChange={(value) => setZoom(value as number)}
                        step={ZOOM_STEP}
                        value={zoom}
                        handleStyle={{
                          borderColor: "#EFF1F3",
                          width: 24,
                          height: 24,
                          marginTop: -8,
                          opacity: 1,
                        }}
                        trackStyle={{ opacity: 0 }}
                        railStyle={{ height: 8 }}
                      />
                      <button
                        className="mt-2 ml-4 text-xl hover:text-[#18BEB3]"
                        disabled={zoom >= MAX_ZOOM!}
                        type="button"
                        onClick={() => setZoom(zoom + ZOOM_STEP)}
                      >
                        <FontAwesomeIcon icon={["fal", "plus"]} />
                      </button>
                    </div>
                  </div>
                  <div className="self-end mt-3">
                    <button
                      className="px-4 py-2 mr-2  border-[1px] rounded-lg hover:opacity-60 text-aqua-400 border-aqua-400"
                      type="button"
                      onClick={() => {
                        onCancel();
                        setZoom(INITIAL_ZOOM);
                        setCrop(INITIAL_CROP);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
                      type="button"
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ProfilePictureEditModal;
