export const sv = {
  lang: "Swedish",
  langSort: "sv",
  doNotHaveAnAccount: "Har du inget konto?",
  createOneAccountHere: "Skapa ett här",
  loginTo: "Logga in",
  sircular: "Sircular",
  email: "Email",
  password: "Lösenord",
  rememberMe: "Kom ihåg mig",
  forgotYourPassword: "Glömt ditt lösenord?",
  login: "Logga in",
  thisIsNotValidEmail: "Detta är inte en giltig email adress.",
  mandatory: "Obligatorisk",
  doYouAlreadyHaveAnAccount: "Har du redan ett konto?",
  signIn: "Logga in",
  registerTo: "Registera dig för att",
  createSircularAccount: "skapa ett Sircular konto",
  investor: "investerare",
  theNameOfTheCompany: "Företagets namn",
  confirmPassword: "Bekräfta lösenord",
  createAccount: "Skapa konto",
  firstName: "Förnamn",
  surname: "Efternamn",
  confirmYourEmail: "Bekräfta din e-post.",
  emailConfirmDesc:
    "Ditt konto har blivit registrerat. Innan du kan logga in behöver du verifiera din e-post. Följ instruktionerna i det mail vi skickat till den adress du angivit.",
  okayThanks: "Okej, tack!",
  forgotPasswordDesc:
    "Inga problem! Ange den e-post du använde när du startade ditt konto så skickar vi instruktioner om hur du skapar ett nytt.",
  back: "Tillbaka",
  resetPassword: "Återställ lösenord",
  checkYourInbox: "Kolla din inkorg!",
  instructionHowToResetPass:
    "Instruktioner om hur du återställer ditt lösenord har skickats till din e-post.",
  startups: "börja",
  howDidYouHearAboutUs: "Hur hörde du om oss?",
  businessInformation: "Företagsinformation",
  details: "Detaljer",
  industry: "Bransch",
  fas: "fas",
  market: "Marknad",
  sdg: "SDG",
  theTeam: "Teamet",
  keyFigures: "Nyckeltal",
  gallery: "Galleri",
  apperarance: "Utseende",
  submitApplication: "Skicka ansökan",
  changePassword: "Byt lösenord",
  investoreVisibilityDesc:
    "Här ser du din synlighet för investerare. Fyll i mer information för att få bättre synlighet!",
  companyVisibilityDesc:
    "Här ser du din synlighet för företag. Fyll i mer information för att få bättre synlighet!",
  solverOrderDoesNotMatch: "Lösernorden matchar inte",
  passwordSaved: "Lösenord sparat",
  newPasswordHasBeenSaved: "newPasswordHasBeenSaved",
  somethingWentWrong: "Något gick fel",
  weCouldNotSave: "Vi kunde inte spara.",
  save: "Spara",
  currentPassword: "Nuvarande lösenord",
  newPassword: "Nytt lösenord",
  settingSaved: "Inställningar sparade",
  yourSettingSaved: "Dina inställningar har sparats.",
  settingDetailsDescription:
    "Denna information kommer att visas på din företagsprofil och presentation när investerare letar efter din profil. Fyll imycket information för att synas bättre.",
  website: "Hemsida",
  websiteValidation:
    "Skriv en giltig URL. Måste innehålla https:// eller http://",
  allCompanies: "Allabolag",
  city: "Stad",
  foundedYear: "Grundat år",
  enterValidYearValidation: "Skriv ett giltigt årtal mellan 1000 och 2999.",
  noOfEmployees: "Antal anställda",
  socialMediaLinks: "Social media länkar",
  socialMediaLinkDesc:
    "Länkarna nedan är länkar till bolagets sociala medier som kommer att synas på din företagsprofil.",
  youtube: "Youtube",
  facebook: "Facebook",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  twitter: "Twitter",
  previousStep: "Föregående steg",
  nextStep: "Nästa steg",
  unableToSubmitApplication: "Vi kunde inte skicka in din ansökan.",
  readyToSubmitApplication: "Redo att skicka in din ansökan?",
  finalStepDescription:
    "Snyggt jobbat! Nu är du redo att skicka in din ansökan. Kom ihåg att du när som helst kan gå tillbaka och ändra eller lägga till information.",
  holdOnReviewingYourApplication: "Håll ut! Vi granskar din ansökan.",
  asSoonAsWeAreReadyReceiveAnEmail:
    "Så fort vi är klara kommer du att få ett mail med vidare instruktioner.",
  galleryInstruction:
    "Hjälp dina investerare att förstå vilka ni är och gör profilsidan mer intressant genom att lägga upp bilder i galleriet. Du kan t.ex. ladda upp produktbilder eller bilder från ert kontor.",
  photoForGallery: "Bilder för galleri",
  howDidYouHearAboutSircular: "Hur hörde du om Sircular?",
  chooseAnOptionAboutSircular:
    "Välj ett alternativ som stämmer med hur du hörde om Sicular.",
  canYouExplainWhere: "Kan du förklara vart?",
  chooseAnIndustriesInWhichYouOperate:
    "Välj upp till 3 branscher som ni verkar inom.",
  businessInfoInstruction:
    "Redo att skapa ett fantastiskt första intryck? Visa upp er från er bästa sida genom att ladda upp en högupplöst logotyp och skriv några rader om er som företag. Ju mer information du lägger in desto bättre kommer er profil att synas. Nu kör vi!",
  companyName: "Företagsnamn",
  companyNameIsMandatory: "Företagsnamn är obligatoriskt!",
  startupNameIsMandatory: "Startnamn är obligatoriskt!",
  hisspitch: "Hisspitch",
  writeAnElevatorPitchForCompanyDesc:
    "Skriv en hisspitch för ert bolag. Denna visas högst upp på företagsprofilen. Håll det kort!",
  elevatorPitchIsMandatory: "Hisspitch är obligatoriskt!",
  description: "Beskrivning",
  writeAMoreDetailsDescriptionForCompany:
    "Skriv en utförligare beskrivning om ert företag. Vad är er produkt? Vilka är ni? Vad är era mål?",
  descriptionOfCompanyMandatory: "Beskrivning av företaget är obligatoriskt!",
  vision: "Vision",
  whatIsCompanyVision: "Vad är företagets vision?",
  mission: "Mission",
  whatIsCompanyMission: "Vad är företagets mission?",
  name: "Namn",
  remove: "Ta bort",
  whoHasInvestedInYourCompanyBefore:
    "Vilka har investerat i ert bolag tidigare?",
  addInvestors: "Lägg till investerare",
  enterNameOfFile: "Enter a name for the file",
  keyFigureDesc:
    "Nu grottar vi in oss i detaljerna! Utgå ifrån senaste bokslut.",
  revenue: "Omsättning",
  youMustCompleteATurnover: "Du måste fylla i en omsättning.",
  NoOfCustomers: "Antal kunder",
  profitMargin: "Vinstmarginal",
  inPercent: "i procent",
  totalAssets: "Summa tillgångar",
  writeTheTotalAmount: "Skriv totala beloppet",
  caseLiquidity: "Kassalikviditet",
  solidity: "Soliditet",
  grossProfitMargin: "Bruttovinstmarginal",
  forecastEstimatedTuroverInFuture:
    "Prognos - Uppskattad omsättning i kommande bokslut",
  documentsAndFiles: "Dokument och filer",
  keyFigureDocumentUploadDesc:
    "Hjälp potentiella investerare att få en helhetsbild av ert bolag. Här kan du t.ex. ladda upp årsredovisningar, produktblad eller andra dokument som du tror skapar värde för en investerare.",
  addDocuments: "Lägg till dokument",
  marketAndBusinessModal: "Marknad och affärsmodell",
  whichMarketDoYouFocusOn: "Vilka marknader fokuserar ni på?",
  whatIsYourBusinessModal: "Vilken är er affärsmodell?",
  whatPhaseAreYouIn: "Vilken fas befinner ni er i?",
  chooseWhichPhaseYourCompanyIn: "Välj vilken fas ert bolag befinner sig i.",
  chooseWhichInvestmentStateYourAreIn:
    "Välj vilket investeringsstadie ni befinner er i.",
  areYouLookingForInvestmentsRightNow: "Söker ni investeringar just nu?",
  whatTypeOfInvestmentAreYouLookingFor: "Vilken typ av investering söker ni?",
  whenDoYouNeedFinancing: "När behöver ni finansiering?",
  haveYouRaisedMoneyBefore: "Har ni rest pengar innan?",
  whatValueDidYouRaiseMoney: "Till vilket värde reste ni pengar?",
  writeSumInIntegers: "Skriv en summa i heltal",
  whatYearDidYourRaiseMoney: "Vilket år reste ni pengar?",
  enterValidYear: "Skriv ett giltigt årtal",
  decorateYourProfile: "Piffa upp din profil",
  stylingInstruction:
    "Stick ut från mängden genom att ladda upp en bannerbild och välja färger som går i linje med er grafiska profil.",
  bannerImage: " Bannerbild",
  bannerImgVisibleOnYourCompanyProfileOnTop:
    "Bannerbilden syns på din företagsprofil i ytan högst upp.",
  video: "Video",
  pasteAndEmbedCodeFromAnyService:
    "Klistra in embed-koden från valfri videotjänst.",
  pasteValidCodeWithIframe: "Klistra in en giltig embed-kod med en iframe!",
  primer: "Primarfärg",
  embedCodeForVideo: "Embed-kod till video",
  sdgTargets: "SDG-mål",
  chooseYourComapnyWorksSDGTargets:
    "Välj om ditt företag arbetar mot ett eller flera särskilda SDG-mål.",
  tellWhyGoalaImportantAndHowYouAchieve:
    "Berätta varför just dessa mål är viktiga för er och hur ni arbetar för att uppnå dem.",
  nameAndSurname: "Namn och efternamn",
  sex: "Kön",
  selectGender: "Välj kön",
  title: "Titel",
  linkToLinkedin: "Länk till LinkedIn",
  profilePicture: "Profilbild",
  uploadProfilePicture: "Ladda upp en profilbild",
  foundersAndTeams: "Grundare och team",
  foundersAndTeamsDesc:
    "Berätta mer om vilka som står bakom ert företag. Lägg till en eller flera grundare och medarbetare.",
  addFounder: "Lägg till grundare",
  team: "Teamet",
  addTeamMember: "Lägg till team-medlem",
  information: "Information",
  typeOfInvestor: "Typ av investerare",
  aboutYouAsAnInvestor: "Om dig som investerare",
  previousInvestments: "Tidigare investeringar",
  savedAutomatically: "Sparat automatiskt",
  bannerUpdated: "Banner uppdaterad",
  weCouldNotUpdateBanner: "Vi kunde inte uppdatera bannern",
  weCouldNotSaveImage: "Vi kunde inte spara bilden",
  weCouldDeleteImage: "Vi kunde ta bort denna bild",
  logoUpdated: "Logotyp uppdaterad",
  weCouldNotSaveYourLogo: "Vi kunde inte spara din logotyp",
  logo: "Logotyp",
  updateCompanyLogo: "Ladda upp företagets logotyp.",
  minimumSize300: "Minimum storlek 300x300 px",
  passwordAreNotSame: "Lösenorden är inte samma",
  passwordCouldNotResetTryAgain:
    "Det gick inte att återställa lösenordet. Försök igen lite senare.",
  chooseNewPassword: "Välj ett nytt lösenord",
  resetPasswordDesc:
    "Du har valt att återställa ditt lösenord. Ange ditt nya lösenord nedan.",
  resetLinkHasBeenDeleted: "Denna återställningslänk har utgått",
  stilDoNotRememberYourPassword:
    "Om fortfarande inte kommer ihåg ditt lösenord. Följ",
  toResetPassword: "för att återsälla lösenordet.",
  thisLink: "denna länk",
  passwordReset: "Lösenord återställt",
  clickTheButtonBelowToLoginWithNewPassword:
    "Klicka på knappen nedan för att logga in med ditt nya lösenord.",
  yourNewPasswordHasBeenSaved: "Ditt nya lösenord har blivit sparat.",
  niceWorkReadyToSubmitAppYouCanGoBackAndChangePass:
    "Snyggt jobbat! Nu är du redo att skicka in din ansökan. Kom ihåg att du när som helst kan gå tillbaka och ändra eller lägga till information.",
  industries: "Branscher",
  chooseOneOrMoreIndustriesTechnologiesForInvestment:
    "Välj en eller flera branscher och teknologier som du letar investeringsmöjligheter inom.",
  selectIndustries: "Välj branscher",
  investmentName: "Investeringsnamn",
  investmentNameIsMandatory: "Investeringsnamn är obligatoriskt",
  emailIsMandatory: "Email är obligatoriskt",
  phone: "Telefon",
  enterValidAddressForWebsite: "Skriv en giltig adress för er hemsida",
  shortDescription: "Kort beskrivning",
  writeFewLineAboutYourSelf: "Skriv några rader om dig själv som investerare.",
  shortDescriptionIsMandatory: "En kort beskrivning är obligatorisk!",
  anonymous: "Anonym",
  enableOptionIfRemainAnonymous:
    "Aktivera detta val om du vill vara anonym. Din bild, hemsida, namn och teamet kommer då att döljas i din profil.",
  investorDescription:
    "Låt företagen lära känna dig lite bättre. Vilka mervärden kan du skapa i bolaget? Vad är viktigt för dig som investerare? Hur ser din investeringsfilosofi ut?",
  descriptionIsMandatory: "En beskrivning är obligatorisk!",
  investorTypeInstruction:
    "Ange vilken typ av investerare du är så att vi kan matcha dig mot rätt företag.",
  howBigInvestmentAreYOuInterested:
    "Hur stora investeringar är du intresserad av att göra?",
  whatPhaseWantToCompanyYouInvest:
    "Vilken fas vill du att bolagen du investerar i är?",
  whatStageWantToInvest: "I vilket stadie vill du investera?",
  whatBusinessModalWantToInvestIn: "Vilka affärsmodeller investerar du i?",
  shareYourPreviousInvestments: "Dela med dig av dina tidigare investeringar.",
  addInvestment: "Lägg till investering",
  chooseWhetherYouInvestOneOrMoreSpecificSDGS:
    "Välj om du investerar inom en eller flera särskilda SDG-mål",
  tellWhyGoalImportantHowYouWorkingWithThem:
    "Berätta varför just dessa mål är viktiga för dig och hur du arbetar för att uppfylla dem.",
  tellUsMoreAboutYouAndYOurTeam:
    "Berätta mer om dig och ditt team. Lägg till en eller flera av dina medarbetare.",
  accountAccepted: "Konto accepterat",
  accountHasBeenAccepted: "Kontot har blivit accepterat",
  weCouldNotAcceptTheAccount: "Vi kunde inte acceptera kontot.",
  accountBlocked: "Konto blockerat",
  accountHasBeenBlocked: "Kontot har blivit blockerat",
  unableToBlockAccount: "Vi kunde inte blockera kontot.",
  accountUnblocked: "Konto avblockerat",
  accountHasBeenUnblocked: "Kontot har blivit avblockerat",
  couldNotUnblockAccount: "Vi kunde inte avblockerat kontot.",
  unblock: "Avblockera",
  block: "Blockera",
  contact: "Kontakta",
  approve: "Approve",
  employees: "Anställda",
  registeredIt: "Registrerade sig den",
  hasSentApplication: "Har skickat ansökan",
  approve: "Godkänn",
  couldNotCreateNewCompaign: "Vi kunde inte skapa någon ny kampanj.",
  offers: "Erbjudanden",
  belowYouWillFindAllCreatedOffers:
    "Nedan finner du alla skapade erbjudanden och om de är aktiva eller inte.",
  clickReadMoreToSeeMoreOffers:
    "Klicka på läs mer för att ser mer infomration om erbjudandet.",
  createOffer: "Skapa erbjudande",
  status: "Status",
  order: "Ordning",
  inquiries: "Förfrågningar",
  onGoing: "Pågår till",
  show: "Visa",
  copyId: "Kopiera id",
  couldNotCreateNewPodcast: "Vi kunde inte skapa någon ny podcast.",
  podcasts: "Podcasts",
  belowYouWilFindAllPodcasts:
    "Nedan finner du alla skapade podcasts och om de är aktiva eller inte.",
  clickOnReadMoreToSeeMoreInfoAboutPodcasts:
    "Klicka på läs mer för att ser mer infomration om varje podcast.",
  createPodCast: "Skapa podcast",
  publisher: "Utgivare",
  length: "Längd",
  createdIt: "Skapad den",
  play: "Spela",
  bannerImageVisibleOnTheNews: "Bannerbilden syns på nyheten.",
  size200By160: "Storlek: 200x160",
  adSaved: "Annons sparad",
  adHasBeenSaved: "Annonsen har blivit sparad",
  unableToSave: "Det gick inte att spara.",
  bannerRemoved: "Banner borttagen",
  adHasBeenRemoved: "Annons har blivit borttagen",
  adCouldNotBeSaved: "Det gick inte att ta bort Annonsen.",
  adSpaceHomeTop: "Annonsyta - Startsidan topp",
  commercialAreaSettingInstruction:
    "Information som du fyller på nedan påverkar hela systemet och skickar ut information till olika användargrupper och användare.",
  saveChanges: "Spara ändringar",
  displayOptions: "Visningsalternativ",
  sameMessage: "Samma meddelande",
  seperateMessage: "Separata meddelanden",
  preTitle: "Pre titel",
  titleIsMandatory: "Titel är obligatorisk!",
  link: "Länk",
  doNotForgetExternalLink: "Glöm inte https:// vid externa länkar",
  linkIsMandatory: "Länk är obligatoriskt!",
  linkText: "Länktext",
  linkTextIsMandatory: "Länktext är obligatoriskt!",
  writeTextThatDescribeOffer: "Skriv en text som beskriver erbjudandet",
  size400By190: "Storlek: 400x190",
  adSpaceHomePageCenter: "Annonsyta - Startsidan mitten",
  noMessage: "Inget meddelande",
  bannerSaved: "Banner sparad",
  bannerHasBeenSaved: "Bannern har blivit sparad",
  bannerHasBeenRemoved: "Bannern har blivit borttagen",
  bannerCouldNotBeRemoved: "Det gick inte att ta bort bannern.",
  bannerAtTheTop: "Banner i toppen",
  cancelSubscription: "Avbryt prenumeration",
  subscriptionCancelled: "Prenumeration avbruten",
  receipts: "Kvitton",
  date: "Datum",
  period: "Period",
  open: "Öppna",
  download: "Ladda ner",
  completed: "Avslutad",
  active: "Aktiv",
  inactive: "Inaktiv",
  paymentOptions: "Betalningsalternativ",
  memberSince: "Medlem sedan",
  subscription: "Prenumeration",
  pleaseTryAgain: "Var god försök igen senare.",
  youAre: "Du är",
  one: "ett",
  clickAwayToTakepartInSircular: "klick ifrån att ta del av Sircular-världen!",
  favorablePriceFromOurPartners:
    "Förmånliga priser från våra Partners (värde 19 999 kr/mån)",
  exposureToSuccessfullInvestors: "Exponering mot framgångsrika Investerare",
  presentYourCompanyInDigitalAttractiveFormat:
    "Presentera ditt Företag i ett digitalt och attraktivt format",
  collectCompanyInfoInSircularSys:
    "Samla bolagsinformation i Sircular’s säkra system",
  uploadCompanyNewsAndUpdates: "Ladda upp företags nyheter och uppdateringar",
  networkAndCommunicateWithInvestors:
    "Nätverka och kommunicera med Investerare",
  legalTemplatesAndFrameworkAgreements: "Legala mallar och Ramavtal",
  sircularDDApproval: "Sircular DD approval",
  invitationsToEntrepreneurialEvents: "Injudningar till entreprenörsevent",
  accessToVariousCoursesWithPartners:
    "Tillgång till olika kurser med våra partners",
  chooseHowYouWantToPay: "Välj hur du vill betala",
  monthly: "Månadsvis",
  mon: "mån",
  startMonthlySubscription: "Starta månadsprenumeration",
  yearly: "Årssvis",
  year: "år",
  startAnnualSubscription: "Starta årsprenumeration",
  couldNotLoadCompaniesTryAgainLater:
    "Vi kunde inte ladda företag. Försök igen senare.",
  search: "Sök",
  allSDGTargets: "Alla SDG-mål",
  financingStage: "Finansieringsstadie",
  allFinancingStage: "Alla finansieringsstadier",
  startupPhase: "börjafas",
  allStartupPhase: "Alla börjafas",
  businessModal: "Affärsmodell",
  allBusinessModal: "Alla affärsmodeller",
  shows: "Visar",
  of: "av",
  allIndustries: "Alla branscher",
  allTypes: "Alla typer",
  showAllCompanies: "Visa allabolag",
  headOffice: "Huvudkontor",
  founded: "Grundat",
  lookingForInvestment: "Söker investering",
  investmentPhase: "Investeringsfas",
  remainingCapital: "Rest kapital",
  investmentYear: "Investeringsår",
  socialMedia: "Social media",
  sdgGolas: "SDG mål",
  whyDidWeChooseThese: "Varför har vi valt dessa?",
  playVideo: "Spela video",
  scrollToReadMore: "Scrolla för att läsa mer",
  allKeyFiguresShownInThousand: "Alla siffror visas i",
  thousands: "Tusentals",
  keyfigureDescForComapny:
    "Här finner du de senaste nyckeltalen från bolaget och rapporter / dokument som visar bolagets status.",
  salesForecast: "Omsättningsprognos",
  reportAndDocuments: "Rapporter & dokument",
  keyFiguresAndReport: "Nyckelsiffror och rapporter",
  newsAndUpdates: "Nyheter & Uppdateringar",
  startChatting: "Börja chatta",
  belowYouWillFindNewsAndUpdatesSuchAs:
    "Nedan finner du de senaste nyheterna och uppdateringarna som",
  compnayItselfPublishes: "företaget själv publicerar.",
  weCouldNotSavePromotion: "Vi kunde inte spara denna kampanj",
  yourAccountCouldNotBeVerified: "Det gick inte att verifiera ditt konto",
  accountIsAlreadyVerifiedWithThisEmail:
    "Det finns redan ett verifierat konto kopplat till denna e-post.",
  yourAccoundIsVerified: "Ditt konto är nu verifierat.",
  clickTheButtonBelowToLogin: "Klicka på knappen nedan för att logga in.",
  verifyingYourAccount: "Verifierar ditt konto",
  takeAdvantageOfTheOffer: "Ta del av erbjudandet",
  hello: "Hej",
  followers: "följare",
  guards: "Bokmärke",
  visitThisWeek: "besök denna vecka",
  whatHasHappenedInLastWeek: "Vad har hänt den senaste veckan?",
  inTheLastWeek: "I de sista veckar",
  and: "och",
  joinedAndSircular: "ha gick med Sircular.",
  company: "bolag",
  weHave: "Vi har",
  new: "nytt",
  exciting: "spännande",
  offer: "erbjudande",
  forYouAsMember: "för dig som medlem!",
  thisWeekWeHaveAlsoUploaded: "Denna vecka har vi också laddat upp med",
  podcastsEpisodeWeRecommendSoYouCanStayUpToDate:
    "podcastavsnitt som vi rekommenderar så att du kan hålla dig up-to-date inom startup världen.",
  myTeaser: "Min kuggfråga",
  editTeaser: "Redigera kuggfråga",
  latestCompanyOnSircular: "Senaste på Sircular",
  newsFromCompaniesInYourCoverage:
    "Nyheter från nystartade företag i din bevakning",
  ourLatestOffer: "Våra senaste erbjudanden",
  latestPodcasts: "Senaste poddarna",
  mostPopularCompaniesRightNow: "Populäraste nystartade företag just nu",
  whoVisitedYourProfile: "som besökt din profil",
  visitedYourProfile: "Besökte din profil",
  bannerImageUpdated: "Bannerbild uppdaterad",
  bannerImageIsVisibleOnTheOffer: "Bannerbilden syns på erbjudandet.",
  addLine: "Lägg till rad",
  belowYouCanSeeWhichCompanyHaveMadeRequestForOffer:
    "Nedan ser du vilka företag som har gjort en förfrågan om att ta del av erbjudandet.",
  type: "Typ",
  companySize: "Bolagsstorlek",
  requestWasMade: "Förfrågan skedde den",
  weWereUnableToRemoveThisOffer: "Vi kunde inte ta bort detta erbjudande.",
  visibility: "Synlighet",
  created: "Skapades",
  updated: "Uppdaterades",
  delete: "Radera",
  published: "Publicerad",
  notVisible: "Ej synlig",
  offerSaved: "Erbjudande sparat",
  saved: "Sparat",
  visibilityOfTheOfferHasChanged: "Erbjudandets synlighet har ändrats",
  visibilityOfTheOfferCouldNotBeChanged:
    "Det gick inte att ändra synlighet på detta erbjudande",
  thisInfoWillDisplayedForAllCompanies:
    "Denna information kommer att visas för samtliga företag.",
  unpublish: "Avpublicera",
  publish: "Publicera",
  offerPeriod: "Erbjudandeperiod",
  startDate: "Startdatum",
  endDate: "Slutdatum",
  emailForApplication: "E-post för ansökningar",
  thisIsNotValidEmailAddress: "Detta är inte en gilitig email adress!",
  linkToTheOffer: "Länk till erbjudandet",
  describeWhatOfferContains: "Beskriv vad erbjudandet innehåller",
  points: "Punkter",
  brieflyWritePointsThatAre: "Skriv kortfattat punkter som är",
  mainContentOfTheOffer: "huvudinnehållet i erbjudandet",
  category: "Kategori",
  chooseWhichCategoryOfferBelongsTo:
    "Välj vilken kategori erbjudandet hör till.",
  podcastSaved: "Podcast sparat",
  visibilityOfThePodcastsHasChanged: "Podcastens synlighet har ändrats",
  visibilityCouldNotBeChangedForPodcosts:
    "Det gick inte att ändra synlighet på denna podcast",
  infoWillAppearInThePorcostsDescToAllMembers:
    "Denna information kommer att visas i podcastbeskrivningen som visas för alla Sircular medlemmar.",
  update: "Uppdatera",
  publishingIsMandatory: "Utgivare är obligatoriskt!",
  lengthIsMandatory: "Längd är obligatoriskt!",
  writeDescriptiveTextAsInDetail: "Skriv en beskrivande text som i detalj",
  explainWhatOfferContains: "förklarar vad erbjudanten innehåller.",
  embedLink: "Embed-länk",
  podcastLinkIsRequired: "Podcast embed länk är obligatoriskt!",
  investingInStage: "Investerar i stadie",
  investmentOpportunities: "Investeringsmöjligheter",
  focusAreas: "Fokusområden",
  primaryMarkets: "Primära marknader",
  investsInTheFollowingSDGS: "Investerar i följande SDGs",
  explanationOfSDGs: "Förklaring till SDGs",
  messages: "Meddelanden",
  conversationDeleted: "Konversation borttagen",
  pushHere: "Tryck här",
  decline: "Avböj",
  accept: "Acceptera",
  viewProfile: "Visa profil",
  send: "Skicka",
  published: "Publicerat",
  draft: "Utkast",
  newsSaved: "Nyhet sparad",
  couldNotSaveNews: "Vi kunde inte spara denna nyhet",
  writeYourNews: "Skriv din nyhet",
  weCouldNotCreateNewNews: "Vi kunde inte skapa någon ny nyhet.",
  newsFor: "Nyheter för",
  writeNews: "Skriv nyhet",
  uniqueOffersTo: "unika erbjudanden till",
  youAsASircularMember: "dig som Sircular-medlem",
  faildedToSendExpressionOfInterest:
    "Det gick inte att skicka din intresseanmälan",
  wantToTakeAdvantageOfTheOffer: "Jag vill ta del av erbjudandet!",
  weHaveSentYourExpressionOfInterestTo:
    "Vi har skickat din intresseanmälan till",
  youWillReceiveAnEmailWithinFewDays:
    "Du kommer inom några arbetsdagar att få ett email skickat",
  toYourEmail: "till din email",
  withInformation: "med information",
  aboutHowToTakePartAndTakeAdvOfThisOffer:
    "om hur du tar del av och utnyttjar detta erbjudande!",
  showsAllOffers: "Visar samtliga erbjudanden.",
  chooseCategory: "Välj kategori",
  sort: "Sortera",
  yourGaurds: "Dina bevakningar",
  youHaveNoGaurds: "Du har inga bevakningar",
  newBookmark: "bokmärke",
  companyWasAddedToYourWatchdog: "Uppstart har bokmärks.",
  companyCouldNotBeAdded: "Uppstart kunde inte läggas till",
  bookmarkedRemoved: "Bokmärke har tagits bort",
  companyRemovedFromYourGuards: "Startens bokmärke har tagits bort.",
  companyCouldNotBeRemoved: "Uppstart kunde inte tas bort",
  watch: "Bevaka",
  endBookmark: "Avsluta bokmärket",
  subscriptionCompleted: "Prenumeration avslutad",
  writeYourFeedbackHere: "Skriv din feedback här",
  cancel: "Avbryt",
  closeAccount: "Avsluta konto",
  SEKTurnover: "SEK omsättning",
  initiateContactWith: "Initiera kontakt med",
  after: "Efter att",
  once: "En gång",
  fileIsTooLarge: "Filen är för stor",
  imageIsTooSmallInWidth: "Bilden är för liten på bredden",
  imageIsTooSmallInHeight: "Bilden är för liten på höjden",
  imageIsNotSquare: "Bilden är inte kvadratiskt",
  uploadOne: "Ladda upp en",
  fileOr: "fil eller",
  changePicture: "Byt bild",
  changeFile: "Byt fil",
  clear: "Rensa",
  ok: "Ok",
  investsInSDGS: "Investerar i SDGs",
  menu: "Meny",
  homePage: "Startsida",
  yourAccount: "Ditt konto",
  settings: "Inställningar",
  manageNews: "Hantera nyheter",
  manageSubscription: "Hantera prenumeration",
  admin: "Admin",
  manageCompanies: "Hantera företag",
  manageInvestors: "Hantera investerare",
  manageOffers: "Hantera erbjudanden",
  managePodcasts: "Hantera podcasts",
  logOut: "Logga ut",
  accepted: "accepterat",
  declined: "avböjt",
  hasPublishedNewOneOffer: "har publicerat ett nytt erbjudande",
  wroteAnUpdate: "skrev en uppdatering",
  hasSentChatRequest: "har skickat en chattförfrågan till dig!",
  your: "din",
  chatRequest: "chattförfrågan!",
  unread: "olästa",
  markEveryoneAsRead: "Markera alla som lästa",
  loadMore: "Ladda mer",
  notifications: "Notifikationer",
  readMore: "Läs mer",
  NoResultFoundFourYourSearch: "Inga resultat hittades för din sökning",
  trySearchingForSomethingElse: "Försök att söka efter något annat!",
  teamDescription: "Nedan finner du de team som arbetar aktivt i vårt bolag.",
  inquiry: "förfrågning",
  registrationFailed: "Registreringen misslyckades",
  emailAlreadyBeenTaken: "Email är redan tagen",
  selectLanguage: "Välj språk",
  google: "Google",
  friend: "Vän",
  event: "Event",
  other: "Annat",
  id: "Ide",
  productOrProtoType: "Produkt eller prototyp",
  goToMarket: "Go to market",
  growthAndExpansion: "Tillväxt och expansion",
  venture: "Venture",
  accelerator: "Accelerator",
  angel: "Ängel",
  bank: "Bank",
  familyOffice: "Familjekontor",
  gender: "Kön",
  man: "Man",
  woman: "Kvinna",
  nonBinary: "Icke-binär",
  developed: "Tagit fram",
  emerging: "Framväxande",
  developedAndEmerging: "Utvecklad och framväxande",
  yes: "Ja",
  no: "Nej",
  months: "månader",
  contribution: "Bidrag",
  loansAndCredit: "Lån/Kredit",
  investment: "Investering",
  bootstraping: "Bootstrapping",
  preSeed: "Pre-seed",
  seed: "Utsäde",
  series: "Serier",
  legalAidManagementAndDocTemplates:
    "Juridisk hjälp, krishantering och dokumentmallar",
  insurance: "Försäkringar",
  economyAndProfitability: "Ekonomi och lönsamhet",
  officeAndShipping: "Kontor och frakt",
  training: "Utbildning",
  carsAndFuel: "Bilar och drivmedel",
  travelAndHotels: "Resor och hotell",
  healthAndWellNess: "Hälsa och friskvård",
  advertisingAndPR: "Reklam och PR",
  agriculture: "Jordbruk",
  consumerGoods: "Konsumtionsvaror",
  energyAndGreentech: "Energi & Greentech",
  homeAndLeisure: "Hem & Fritid",
  fashion: "Mode",
  fintech: "Fintech",
  medtech: "Medtech",
  financeAndInsurance: "Finans & försäkring",
  realEstateAndProptech: "Fastigheter & Proptech",
  metalsAndMines: "Metaller & gruvor",
  foodAndDrinks: "Mat och dryck",
  health: "Hälsa",
  gaming: "Gaming",
  eSport: "E-sport",
  lifeScienceAndBiotech: "Life Science & Biotech",
  itAndSoftware: "IT & programvara",
  hrAndRecruitment: "HR & rekrytering",
  law: "Juridik",
  manufacturing: "Tillverkning",
  shipping: "Sjöfart",
  transportAndLogistics: "Transport & logistik",
  eCommerce: "E-handel",
  mediaAndEntertainment: "Media & Entrainment",
  musicAndSound: "Musik och ljud",
  retail: "Detaljhandel",
  automation: "Automation",
  salesAndMarketing: "Försäljning och marknadsföring",
  scienceAndTechnology: "Vetenskap & teknik",
  service: "Service",
  sportAndTraining: "Sport & träning",
  teleCommunication: "Telekommunikation",
  travelAndTourism: "Resor & turism",
  food: "Livsmedel",
  restaurant: "Restaurang",
  pets: "Husdjur",
  drug: "Läkemedel",
  environmentCleanTech: "Miljö och återvinning",
  planned: "Planerad",
  onGoing: "Pågående",
  noPoverty: "Ingen fattigdom",
  noHunger: "Ingen hunger",
  goodHealthAndWellBeing: "God hälsa och välbefinnande",
  goodEducationForEveryone: "God utbildning för alla",
  equality: "Jämställdhet",
  cleanWaterAndSenitationForEveryOne: "Rent vatten och sanitet för alla",
  sustainableEnergyForEveryone: "Hållbar energi för alla",
  decentWorkingConditionsAndEconomicGrowth:
    "Anständiga arbetsvillkor och ekonomisk tillväxt",
  sustainableIndustryInnovationsAndInfrastructure:
    "Hållbar industri, innovationer och infrastruktur",
  reducedInequality: "Minskad ojämlikhet",
  sustainableCitiesAndCommunities: "Hållbara städer och samhällen",
  sustainableConsumptionAndProduction: "Hållbar konsumtion och produktion",
  fightingClimateChange: "Bekämpa klimatförändringarna",
  marineAndMarineResources: "Hav och marina resurser",
  ecosystemsAndBiodiversity: "Ekosystem och biologisk mångfald",
  peacefulAndInclusiveSocieties: "Fredliga och inkluderande samhällen",
  implementationAndGlobalPartnership: "Genomförande och globalt partnerskap",
  changeLanguage: "Ändra språk",
  theOffer: "Erbjudandet",
  writeYourMessage: "Skriv ditt meddelande",
  oldest: "Äldsta",
  newest: "Senaste",
  mostListened: "Mest lyssnade",
  shareTeaser: "Dela Teaser",
  youCanChooseMaximum: "Du får max välja",
  choices: "val",
  pasteLinkToWebsite: "Klistra in länk till hemsida",
  profileCompleted: "Profilen slutförd",
  startPhase2: "Starta fas 2",
  pictureMissing: "Bild Saknas",
  profile: "Profilen",
  enterCEOFounderName: "Ange VD / grundarens namn",
  enterCEOFoundersEmail: "Ange VD / grundarens e-post",
  enterEmailAddress: "Skriv in epostadress",
  enterLinkedinLink: "Ange Linkedin Link",
  browse: "bläddra",
  dropYourLogoHereOr: "Släpp din logotyp här eller",
  saveAndContinue: "Spara & Fortsätt",
  preview: "Förhandsvisning",
  addCoFounder: "Lägg till VD / medgrundare",
  basics: "Grunderna",
  less: "Mindre",
  more: "Mer",
  whatIsCurrentStageOfStartup:
    "Vad är det aktuella stadiet i ditt nystartade företag?",
  whoDidYouRaiseFrom: "Vem tog du upp från?",
  preProduct: "Förprodukt",
  preRevenue: "Före intäkter",
  postRevenue: "Efter intäkter",
  marketNetwork: "Marknadsnätverk",
  marketPlace: "Marknad",
  hardware: "Hårdvara",
  foundingDetails: "Grundande detaljer",
  companyWebsite: "Företagshemsida",
  enterLocation: "Ange plats",
  team: "team",
  founderDetails: "Information om grundare",
  memberDetails: "Medlemsinformation",
  addAMember: "Lägg till en medlem",
  enterName: "Skriv namn",
  titleRole: "Titelroll",
  settingUpYourProfile: "Ställa in din profil",
  thisIsPrivateByDefault: "Alla ögon är riktade mot din idé!",
  thisIsPrivateDescription:
    "Presentera din idé och produkt så väl som möjligt för potentiella investerare. Att göra ett bra första intryck är viktigt för att få de investeringar du behöver. Låt det räkna!",
  foundersReport: "Från grundarens skrivbord",
  foundersReportDescription:
    "av investerare går vidare om din teaser inte lyser upp ditt företag och din potentiella produkt.",
  elevatorPitch: "hiss pitch",
  elevatorPitchDesc:
    "Låt VC: er veta att du menar affärer på under 30 sekunder! Skriv en övertygande historia om din idé i en rad, eller max. två.",
  addYourElevatorPitchHere: "Lägg till din hiss pitch här",
  socialNetworks: "Sociala nätverk",
  sdgGoalsDesc:
    "Visa ditt företags engagemang för hållbarhet genom att välja ett eller flera SDG-mål.",
  uploadDocuments: "Ladda upp dokument",
  documents: "Dokument",
  selectYourTeamSize: "Välj din lagstorlek",
  documentDescription:
    "Kom ihåg att detta är privat. Du kan inkludera ett förkortat däck eller ditt långa däck. Bästa praxis är 6-14 bilder.",
  dropProfilePictureHereOr: "Släpp profilbild här eller",
  dropYourFilesHereOr: "Släpp dina filer här eller",
  fundraising: "Insamling",
  fundraisingDescription:
    "Nämn detaljer om din insamlingshistoria och dina mål i den nya omgången",
  howMuchMoneyHaveYouRaised: "Hur mycket pengar har du samlat in?",
  enterAmount: "Ange belopp",
  addANote: "Lägg till en anteckning",
  canNotFindYourInvestor: "Hittar du inte din investerare?",
  addManually: "Lägg till manuellt",
  whatIsYourFundraisingTarget: "Vad är ditt insamlingsmål?",
  add: "Lägg till",
  enterFullName: "Ange fullständigt namn",
  enterFirmName: "Ange företagsnamn",
  addAdditionalNotesAboutYourFundRaising:
    "Lägg till ytterligare anteckningar om din insamlingshistoria här.",
  euro: "EURO",
  searchAddByInvestorOrFirmName:
    "Sök eller lägg till efter investerare eller företagsnamn",
  selfFunded: "Självförsörjande",
  friendsAndFamily: "Vänner och familj",
  previous: "Tidigare",
  addNoteForBusinessCategory: "Lägg till anteckning för företagskategori",
  excellentWork: "Utmärkt arbete!",
  youHaveTakenFirstStepTowardsSuccess:
    "Du har tagit det första steget mot framgång! Bra gjort!",
  explore: "Utforska",
  continue: "Fortsätta",
  letDoThatIn: "Låt oss göra det i",
  company: "Företag",
  companyDescription:
    "Beskriv ditt företag i en mening. Lägg till din vision och ditt uppdrag för att komplettera din företagsbeskrivning.",
  addYourCompanySummaryInOneSentence:
    "Lägg till din företagsöversikt i en mening här ...",
  addYourMarketOpportunityDescription:
    "Lägg till din beskrivning av marknadsmöjligheter här ...",
  marketOpportunity: "Marknadsmöjlighet",
  addYourBusinessModalHere: "Lägg till ditt företagsmodal här ...",
  businessModalDescription:
    "Hur kommer ditt företag att tjäna pengar? Vem betalar för dina produkter? Vad är vinstmarginalen per annat?",
  timing: "Tidpunkt",
  addWhyIsTheRightTimeToBeInBusiness:
    "Lägg till varför är rätt tid att vara i affärer här ...",
  timingDescription:
    "Vad är den enda förändringen i teknik, plattformar, lagar eller kundbeteende som kommer att säkerställa affärsframgång?",
  whyIsItGreatTimeForYoueCompany:
    "Varför är det bra tid för ditt företag att arbeta?",
  traction: "Dragning",
  tractionSummary: "How would you summarise your traction?",
  tractionSummaryPlaceholder: "Summarise your traction.",
  mostExcitingTractionMetricForBusiness:
    "Vad är det mest spännande dragmåttet för ditt företag?",
  tractionDescription:
    "Välj minst en. Om du inte ser ett mätvärde som bäst visar din dragkraft, skriv det i det öppna textfältet längst ner.",
  letterOfIntentSigned: "Avsiktsförklaringar undertecknade",
  addAnyAdditionalTractionMetricsHere:
    "Lägg till eventuella ytterligare dragmätvärden här.",
  valuation: "Värdering",
  daily: "Dagligen",
  weekly: "Varje vecka",
  annually: "Årligen",
  quarterly: "Kvartals",
  SMBs: "SMB",
  users: "Användare",
  customers: "Kunder",
  suppliers: "Leverantörer",
  subscribers: "Prenumeranter",
  usersPaying: "Användare som betalar",
  day: "Dag",
  week: "Vecka",
  month: "Månad",
  quarter: "Fjärdedel",
  hours: "Timmar",
  visits: "Besök",
  purchases: "Inköp",
  items: "Objekt",
  contracts: "Kontrakt",
  transactioins: "Transaktioner",
  companyProfileDesc:
    "Det första intrycket är ofta det som betyder något. Ta de första stegen för att berätta för människor mer om ditt företag. Redo? Nu gör vi det!",
  settingUpProfileDesc:
    "Ärlighet är viktigt för investerare. Sätt din bästa fot framåt, ärligt talat.",
  companyBasicDesc:
    "Lägg till branschspecifik och affärskategorispecifik information som hjälper VC: er att slutföra investeringsbeslut snabbare.",
  settingUpBasicDesc:
    "Denna information hjälper investerare att veta mer om ditt företag. Lägg till så mycket information du kan och förbättra din synlighet!",
  companyTeamsDesc:
    "Investerare älskar att veta om dina grundande teammedlemmar för att ta reda på om ditt företag är i goda händer.",
  settingUpTeams:
    "Att ha ett team med olika kompetenser förenat kring en gemensam passion är den mest kraftfulla kraft investerare letar efter.",
  settingUpElevatorPitchDesc:
    "Din bästa one-liner är emotionell, inte bokstavlig.",
  settingUpFundraisingDesc:
    "Investerare kommer att fråga dig om din brännhastighet och vad du kommer att spendera kapitalet på. Men bortom kapitalet, hur kan de hjälpa dig att uppnå dina mål?",
  companyDocumentsDesc:
    "Ladda upp ditt pitch deck och alla andra dokument som du vill presentera för investerarna",
  settingUpDocumentDesc:
    "I ett nötskal är tonhöjden ditt sätt att förklara varför problemet du löser är värt att lösa och varför du är rätt team för att göra det.",
  companySocialNetworkDesc:
    "Lägg till länkar till ditt företags sociala mediehandtag.",
  settingUpSDGsDesc:
    "Investerare älskar att veta om du har en grupp passionerade användare som kommer att driva din produkt till framgång.",
  settingUpSocialNetworkDesc: `Hållbarhetsmålen eller globala mål är en samling av 17 sammanlänkade globala mål som är utformade för att vara en "plan för att uppnå en bättre och mer hållbar framtid för alla".`,
  settingUpTractionDesc:
    "Visa mätvärden som är viktiga och inte hockeysticknummer.",
  settingUpCompanyDesc:
    "Nämn de viktigaste aspekterna av din produkt eller tjänst, unikhet, produktfördelar eller andra höjdpunkter som du vill nämna för investerarna",
  companyMarketDesc:
    "Varför finns det en stor marknadsmöjlighet för din produkt? Skapar du en helt ny marknad med din produkt?",
  settingUpMarketDesc:
    "Fonder och användare kommer att strömma in när tiden är rätt. Berätta för dina investerare att idag är den bästa tiden för ditt företag att vara där!",
  settingUpTimingDesc:
    "Timing är nyckeln och underskattas ofta. Förklara varför du varken är för sent eller för tidigt till middagsfesten.",
  settingUpBusinessModelDesc:
    "Investerare är trötta på filosofin ”växa först och affärsmodellen kommer att följa”. Se till att din affärsmodell är genomtänkt, även om den inte är i sten.",
  settingUpAppearanceDesc:
    "Vi har steg-för-steg-handledning för att lära dig grunderna och de inte så grundläggande.",
  activeUsers: "Aktiva användare",
  totalUsers: "Totalt antal användare",
  optionalNoteToProvideFurtherContect:
    "Valfri anmärkning för att ge ytterligare sammanhang ...",
  days: "Dagar",
  weeks: "Veckor",
  years: "År",
  quarters: "Kvartal",
  userGrowthRate: "Användartillväxt",
  enter: "Stiga på",
  retentionRate: "Bibehållande",
  churnRate: "Churn Rate",
  netRevenue: "Nettointäkt",
  recurringRevenue: "Återkommande intäkter",
  revenueGrowthRate: "Inkomsttillväxt",
  SMVperGSV: "GMV/GSV",
  averageSalesPrice: "Genomsnittligt försäljningspris",
  rakePercentage: "Rake procent",
  CacPayback: "CAC-återbetalning",
  ltvCacRatio: "LTV: CAC-förhållande",
  demandFrequency: "Efterfrågefrekvens",
  supplyFrequency: "Tillförselfrekvens",
  signedContracts: "Tecknade kontrakt",
  annualValueOfSignedInContract: "Årligt värde på undertecknade kontrakt",
  hashOfContractsInPipeline: "antal kontrakt i rörelse",
  annualValueOfSignedInPipeline: "Årligt värde på signerad i rörledning",
  additionalTraction: "Ytterligare dragkraft",
  enterYourEmailAddress: "Skriv in din mailadress",
  loginWith: "Logga in med",
  google: "Google",
  linkedin: "Linkedin",
  or: "Eller",
  howSircularHelps: "Hur cirkulärt hjälper",
  seeHowSircularCanTakeBusinessForward:
    "Se hur cirkulär kan föra vår verksamhet framåt.",
  takeJustTwoMinutesToSignIn: "Det tar bara två minuter att logga in.",
  youAreAllSetToSucceed: "Du är redo att lyckas!",
  startNow: "Börja nu",
  businessRelation: "Business relation",
  whatBestDescribeYourBusinessCategory: "Vad beskriver företagskategorin bäst?",
  whatBestDescibesYourBusinessRelations:
    "Vad beskriver dina business relationer bäst?",
  whatIndustryDoesYourBusinessOperateIn:
    "Inom vilken bransch bedriver du ditt företag?",
  whatIndustryCategoryDoesYourBusinessOperateIn:
    "Vilken branschkategori bedriver ditt företag?",
  whatIsYourProductCategory: "Vad är din produktkategori?",
  youCanChooseUpTo3Categories: "Du kan välja upp till tre kategorier",
  addNoteForBusinessType: "Lägg till anteckning för företagstyp",
  dropYourProfilePictureHereOr: "Släpp profilbild här eller",
  whatIsYourValuation: "vad är din värdering?",
  apperance: "Appearance",
  apperanceDescription:
    "Sticka ut från mängden genom att ladda upp en bannerbild och välja färger som stämmer överens med din grafiska profil.",
  primaryColor: "Primarfärg ",
  enterNumber: "Skriv in nummer",
  teaser: "Smakprov",
  thereIsProblemInTheSystemContactToAdmin:
    "Det finns ett problem i systemet, kontakta admin",
  selectAccountType: "Välj Kontotyp",
  upToTill: "upp till",
  emailAddress: "E-postadress",
  enterPassword: "Skriv in lösenord",
  registerTo: "Registrera dig till",
  enterStartupName: "stiga på börja namn",
  enterCompleteName: "Ange fullständigt namn",
  companyName: "Företagsnamn",
  completeName: "Fullständigt namn",
  asStartup: "Som börja",
  asInvestor: "Som investerare",
  startTeaserDesc:
    "Det bästa sättet att kommunicera ditt företag till de bästa VC: erna och änglarna",
  startYourTeaser: "Starta din teaser",
  exploreThePlatform: "Utforska plattformen",
  start30DayFreeTrial: "Starta en 30 dagars gratis testperiod",
  removeNote: "Ta bort anteckningen",
  coFounderAndCEO: "Medgrundare & VD",
  noRecordFound: "Inget rekord funnet",
  pleaseUploadPExcelFormat: "Ladda bara upp xlsx, .xls, PDF-format",
  pleaseUploadPDFormat: "Ladda bara upp PDF-format",
  addNoteAboutTeam: "Lägg till anteckning om ditt team ...",
  sdgLeftContentDesc:
    'Målen för hållbar utveckling eller globala mål är en samling av 17 sammanlänkade globala mål som är utformade för att vara en "plan för att uppnå en bättre och mer hållbar framtid för alla".',
  visionDescription:
    "Beskriv ditt företag i en mening. Lägg till din vision och uppdrag för att komplettera din företagsbeskrivning.",
  addHowYouThinkAboutYourMarketTimingHere:
    "Lägg till hur du tänker på din marknadsplanering här ...",
  agTech: "AgTech",
  AI: "AI",
  dataAnalytics: "Dataanalys",
  arVr: "AR/VR",
  audioTech: "AudioTech",
  autoTech: "AutoTech",
  bioTech: "BioTech",
  blockchain: "Blockchain",
  chemicals: "Kemikalier",
  cleanTechEnviorment: "CleanTech / miljö",
  cloudInfrastructur: "Cloud Infrastructure IT",
  construction: "Construction",
  consumerHealth: "Konsumenthälsa",
  consumerInternet: "Konsument Internet",
  cosmetics: "Kosmetika",
  cryptocurrency: "Kryptovaluta",
  cybersecurity: "Cybersäkerhet",
  dataServices: "Datatjänster",
  developerTools: "Utvecklarverktyg",
  diagnostics: "Diagnostik",
  digitalHealth: "Digital hälsa",
  directToConsumerDTC: "Direkt till konsument (DTC)",
  drugDelivery: "Drogleverans",
  energy: "Energy",
  enterprise: "Företag",
  enterpriseApplications: "Enterprise-applikationer",
  enterpriseInfrastructure: "Företagsinfrastruktur",
  entertainmentAndSports: "Underhållning och sport",
  futureOfWork: "Arbets framtid",
  games: "Spel",
  gamingAndEsports: "Spel / e-sport",
  gigEconomy: "Gig Economy",
  government: "Regering",
  healthAndHospitalServices: "Hälso- och sjukhustjänster",
  healthIT: "Hälsa IT",
  humanCapitalHRTech: "Humankapital / HRTech",
  impact: "Påverkan",
  legalTech: "LegalTech",
  localServices: "Lokala tjänster",
  hospitality: "Boende",
  logistics: "Logistik",
  marketingComms: "Marketing Comms",
  marketplaces: "Marknadsplatser",
  material: "Material",
  mediaContent: "Media / innehåll",
  medicalDevices: "Medicinska apparater",
  messaging: "Meddelanden",
  mobilityAuto: "Rörlighet",
  parentingFamilies: "Föräldraskap / familjer",
  payments: "Betalningar",
  robotics: "Robotik",
  saas: "SaaS",
  salesAndCRM: "Försäljning & CRM",
  security: "säkerhet",
  semiconductors: "Halvledare",
  smbSoftware: "SMB-programvara",
  socialCommerce: "Social handel",
  space: "Plats",
  transportationTech: "Transportteknik",
  travel: "Resa",
  wellnessAndFitness: "Hälsa och fitness",
  selectAtleastOneSDG: "Välj minst en SDG.",
  pleaseSelectTeamSize: "Välj lagstorlek",
  pleaseSelectAtleastOneBusinessCategory: "Välj minst en affärskategori",
  selectAtleastOneIndustriy: "Välj minst en affärsbransch",
  selectcompanyCurrentState: "Välj ditt nuvarande steg i ditt företag",
  selectYourBusinessType: "Välj din företagstyp",
  amountValidation: "Var god skriv ett numeriskt värde",
  selectAtleastOneRaiseFrom: "Välj minst en höjd från",
  enterOnlyNumericNumbers: "Ange endast numeriska nummer",
  fileSizeIsTooLargeFor10MB:
    "Filstorleken är för stor, den måste vara mindre än 10 MB",
  foundedIn: "Grundad i",
  target: "Mål",
  raised: "Uppfostrad",
  investors: "Investerare",
  education: "Utbildning",
  Pharmaceuticals: "Läkemedel",
  musicAndEntertainment: "Musik & underhållning",
  mode: "Läge",
  forNextProcessPleaseFillTeaserOne:
    "För nästa process, slutför stegen Profil, Grundläggande och lag",
  startupStage: "Börja skede",
  ceoFounderName: "VD / grundare namn",
  investorRegisterSuceessMessage:
    "Din registrering lyckades! Använd de uppgifter du angav under registreringsprocessen för att logga in på Sircular.",
  success: "Framgång",
  internetConnection: "Internet anslutning",
  connectionIsOut: "Kontrollera din internetanslutning",
  connectionIsBack: "Din internetanslutning är tillbaka",
  stockhomFounderRaised: "Stockholm Grundare som samlade in 2,000,000 SEK",
  stockhomFounderRaisedDesc: `"Att slutföra teasern övertygade mig om att min start var värt en investering. Det var det som lockade investerare in"`,
  socialNetwork: "Socialt nätverk",
  registerWith: "Registrera med",
  allowedOnly30Charcters: "Tillåtet endast 30 tecken",
  cityIsRequired: "Stad krävs",
  selectValidCityFromTheDropdown: "Välj en giltig stad i rullgardinsmenyn",
  viewProductDemo: "Visa produktdemo",
  fundraisingAndbusinessOverflow: "Insamling och affärsöversikt",
  productDescription: "Produktbeskrivning",
  theTeamText: "Laget",
  next: "Nästa",
  prev: "Föregående",
  pitchDeck: "Pitchdäck",
  by: "Förbi",
  viewed: "Tittade",
  previouslyRaisedFrom: "Tidigare upp från",
  businessSectors: "Affärssektorer",
  teamSize: "Lagstorlek",
  whatAreWelookingForInvestors: "Vad letar vi efter hos våra investerare?",
  whatAreYoulookingForInvestors:
    "Utöver finansiellt kapital, Vad letar du efter hos våra investerare?",
  ceoFounder: "VD / grundare",
  coCeoFounder: "MedvD / medgrundare",
  emailAddressText: "E-postadress",
  fundraisingHistory: "Insamlings historia",
  productDescriptionInstruction:
    "Tänk på följande när du skriver beskrivningar som innehåller funktioner och fördelar:",
  productDescriptionInfo1:
    "1. Du behöver inte lista fördelarna med varje funktion. Välj de tre högsta värdena.",
  productDescriptionInfo2:
    "2. Beskriv fördelarna med funktionerna och vad de ger till bordet.",
  productDescriptionInfo3:
    "3. Förklara hur det löser ett problem eller hjälper kunden.",
  product: "Produkt",
  addYourProductSummaryHere: "Lägg till din produktöversikt här ...",
  upload: "Ladda upp",
  ventureCapital: "VCs",
  angels: "Änglar",
  addNote: "Lägg till anteckning",
  removeNote: "Ta bort anteckningen",
  urlMustBeLInkedinUrl: "Webbadressen måste vara kopplad till webbadressen",
  loading: "Läser in",
  allowedOnly25Charcters: "Endast 25 tecken tillåtna",
  share: "Dela med sig",
  shareProfileHeading:
    "Vill du slutföra ditt företags teaser innan du delar det?",
  shareProfileDesc:
    "En undersökning av NFX visar att 78% av investerarna föredrar en företags teaser framför en pitch deck. Det bästa? När du uppdaterar din teaser får alla investerare med länken tillgång till de senaste uppgifterna.",
  whatsApp: "WhatsApp",
  copyLink: "Kopiera länk",
  linkHasBeenCopied: "Länken har kopierats",
  signUp: "Bli Medlem",
  pasteTheUrlForVisitCompany: "Klistra in webbadressen för besöksföretag",
  thanksForCheckingOur: "Tack för att du kollade vår",
  getInTouchWithUsToFollowUp: "Kontakta oss så kommer vi att följa upp.",
  enterFirstName: "Ange förnamn",
  lastName: "Efternamn",
  enterLastName: "Ange efternamn",
  enterYourMessage: "Ange ditt meddelande",
  submit: "Skicka in",
  typeHere: "Skriv här",
  enterContactNumber: "Ange kontaktnummer",
  invalidPhoneNumber: "Ogiltigt telefonnummer",
  wooLetsGrowTogether: "Woo Hoo! Låt oss växa tillsammans.",
  getYourSelfOnboardNow: "Få dig ombord nu.",
  completeMyCompanyTeaser: "Slutför mitt företags teaser",
  sms: "SMS",
  optional: "Frivillig",
  profileLink: "Profillänk",
  defaultDescription:
    "Vårt företags teaser fungerar som en enda sanningskälla om oss. När vi innoverar och arbetar för en hållbar, ekonomiskt sund framtid anpassar vi oss också och lär oss. Vi tar steg mot transparens och förbättrar vår synlighet för våra potentiella investerare och teammedlemmar.",
  weInviteYouToKnowUsBetter:
    "Vi inbjuder dig att lära känna oss bättre genom vår företagsprofil på Sircular",
  seeMore: "Se mer",
  reject: "Avvisa",
  youDontHaveAnyDataAsOfNow: "Åh nej, du har inga data för närvarande.",
  addCompany: "Lägg till företag",
  dataroom: "Dataroom",
  dealroom: "Dealroom",
  rejected: "avvisade",
  typeToSearchStartups: "Skriv för att söka start",
  couldNotFindTheStartup: "Kunde du inte hitta startupen?",
  enterIndustryType: "Ange industury-typ",
  enterCompanyUrl: "Ange företagets webbadress",
  dealflow: "Dealflow",
  companyAddedSuccessfully: "Företaget har lagts till",
  sendInviteToRegister: "Skicka inbjudan att registrera",
  addMoreCompanies: "Lägg till fler företag",
  willAddLater: "Kommer att lägga till senare",
  addCompanyDetails: "Lägg till företagsinformation",
  statusUpdatedSuccessfully: "Status uppdaterades.",
  serverErrorTitle: "Tillfälligt serverfel",
  serverErrorDesc:
    "Förlåt, något gick fel. Anslutning till servern är för närvarande inte tillgänglig. Om du tror att det finns ett problem i systemet, snälla",
  contactUs: "Kontakta oss",
  refresh: "Uppdatera",
  takeMeBack: "Ta mig tillbaka",
  didYouKnow: "Visste du?",
  investorsTip: "Investerarens tips",
  teamInvestorTipDesc:
    "Ta fram hur varje nyckelspelare i laget kommer att bidra till att din produkt blir slagen och tänd!",
  profileInvestorTipDesc:
    "Din profil är det första investerare kommer att titta på, få den att räkna.",
  elevatorPitchInvestorTipDesc:
    "Få människor lika upphetsade som dig över din tonhöjd! Engagera din publik med en spännande, engagerande och medkännande tonhöjd.",
  documentInvestorTipDesc:
    "Ditt introduktionsdäck bör inte vara mer än tio bilder. Ge en överblick på hög nivå istället för den nitty-gritty.",
  fundraisingInvestorTipDesc:
    "Investerare gillar inte underprenumererade investeringsrundor, så be om det minsta du behöver och var öppen för att ta in mer kapital om intresset är stort.",
  businessModalInvestorTipDesc:
    "Se till att knyta din affärsmodell till ditt värdeförslag, inte till dina funktioner. På det sättet är dina mål anpassade till dina användares.",
  timingInvestorTipDesc:
    "Känn marknads- och teknologidynamiken som stöder varför tidpunkten för ditt företag är precis rätt.",
  tractionInvestorTipDesc:
    "Förklara dina siffror i förhållande till var du är idag. Visa din retentionskurva för att visa tillväxt eller antagande.",
  headquarteredAt: "Huvudkontor kl",

  youAreNotOneWhoBlockedThisConversation:
    "Det är inte du som blockerade det här samtalet.",
  deleted: "raderade",
  has: "har",
  typing: "Typing",
  noInternetConnection: "Ingen internetanslutning",
  internetConnectionError:
    "Du kan inte använda den här funktionen offline. Kontrollera din anslutning och försök igen.",
  contactRequestExist: "Du har redan skickat begäran till den här användaren",
  phoneLengthError: "Telefonen ska vara minst 13 siffror",
  teaserTwoImpactDescription:
    "Undersökningen föreslår att 78% av investerarna föredrar ett företagsprojekt framför ett pitchdäck.",
  skip: "Hoppa",
  completeTeaser: "Komplett Teaser",
  enterPhone: "Ange telefon",
  moveTo: "Flytta till",
  typeToSearchSelection: "Skriv för att söka val",
  startupName: "börja namn",
  contactNumber: "Kontaktnummer",
  enterContactNumber: "Ange kontaktnummer",
  thisIsNotAValidContactNumber: "Detta är inte ett giltigt kontaktnummer.",
  wouldYouBeVisibleInSircularMarketplace:
    "Vill du också vara synlig på Sirculars marknadsplats?",
  startYour4MonthsFreeTrial: "Börja din 4 månaders gratis testperiod",
  marketplaceVisibility: "Marknadssynlighet",
  welcomeTo: "Välkommen till",
  youHaveGot4Months: "Du har fyra månader",
  premiumForFree: "premie gratis",
  whatIsSircular: "Vad är sircular?",
  freemiumDesc1:
    "För många nystartade företag är insamling av pengar en viktig del i att bygga upp det kapital som behövs för att lyckas med sitt företag.",
  freemiumDesc2:
    "Även om vi kommer att hjälpa er som gör det till programmet att bygga ett starkt fall till investerare, erbjuder vi också tillgång till Sircular för alla som söker sig till",
  freemiumDesc3:
    "Informationen du har delat i din ansökan har sammanställts till en profil. Denna profil gör att vi kan få en bättre överblick över din ansökan och fatta ett mer välgrundat beslut.",
  freemiumDesc4:
    "Du kan kolla in det och uppdatera informationen genom att klicka på Visa profil nedan.",
  freemiumDesc5:
    "Observera att denna profil endast är synlig för dig och för vårt interna team.",
  joinSircular: "Ansluta sig Sircular",
  willDoLater: "Kommer att göra senare",
  switchTo: "Byta till",
  getYourProfileListed: "Få din profil listad",
  switchNow: "Byt nu",
  notNow: "Inte nu",
  proceedToApplication: "Fortsätt till ansökan",
  weHadWelcomeYourArrival: "Vi välkomnar din ankomst",
  itSeemsYourEmailIsAlreadyRegistered:
    "Det verkar som om din e-postadress redan är registrerad hos Sircular.",
  wouldYourLikeToProceedUsingSameDetails:
    "Vill du fortsätta med samma information.",
  proceed: "Fortsätt",
  addMembersOnSircular: "Lägg till medlemmar på Sircular",
  MakeSircularWorkBetterForYou:
    "Få Sircular att fungera bättre för dig, ställ in föräldrakontroller för dina medlemmar.",
  addMembers: "Lägg till medlemmar",
  addMemberDetails: "Lägg till medlemsinformation",
  enterMemberName: "Ange medlemsnamn",
  view: "Se",
  members: "medlemmar",
  allMembersDetails: "Alla medlemmar detaljer",
  createdAt: "Skapad vid",
  addMoreDetailedDescription: "Lägg till en mer detaljerad beskrivning",
  activity: "Aktivitet",
  added: "Lagt till",
  newMemberAdded: "Ny medlem tillagd.",
  couldNotLoadDescription: "Det gick inte att läsa in beskrivningen",
  whatAreYourThoughts: "Vad är dina tankar?",
  activityAddedSuccessfully: "Aktiviteten har lagts till",
  activityDeletedSuccssfully: "Aktiviteten har tagits bort",
  activityUpdatedsuccessfully: "Aktiviteten har uppdaterats",
  requestHasBeenAccepted: "Begäran har accepterats.",
  startup: "Börja",
  applyTo: "Ansöka till",
  apply: "Tillämpa",
  yourApplicationTo: "Din ansökan till",
  hasBeenSubmitted: "har lämnats in!",
  doYouAlreadyHaveAnApplication: "Har du redan en ansökan?",
  applyWith: "Ansök med",
  doNotHaveAnApplication: "Har du ingen ansökan?",
  createApplication: "Skapa applikation",
  willGetBackToYouWithinComingWeeks:
    "Vi återkommer till dig inom de närmaste veckorna.",
  marketplaceDescription1:
    "Sircular är ett marknadsnätverk som låter dig träffa investerare och samla in pengar snabbare.",
  marketplaceDescription2:
    "Om du vill gå med klickar du bara på Gå med i Sircular nedan.",
  regAcceleratorDescription:
    "Vi är glada över att du vill ansöka. Fyll i informationen nedan för att skicka din ansökan. Den information du tillhandahåller gör det också möjligt för dig att snabbt dela din profil med mentorer, investerare och andra intressenter om du blir vald till programmet.",
  betOfLuck: "Lycka till!",
  whichOfTheseSubcategoriesWithinSmartCitiesDoYouIdentifyWith:
    "Vilken av dessa underkategorier inom Smartstäder identifierar du dig med?",
  pleaseWriteThingsThat: "Skriv 3 saker som",
  acceleratorCanHelpYouWith: "kan hjälpa dig med.",
  explainHere: "Förklara här",
  max200CharLimit: "Max 200 räkningsgräns",
  whatSupportAreWeLookingFor: "Vilket stöd letar vi efter",
  categoriesOfSmartCitiesThatWeIdentifyWith:
    "Kategorier av smarta städer som vi identifierar oss med",
  realEstateFintech: "Fastigheter Fintech",
  planning: "Planera",
  contech: "Contech",
  alternativeMaterials: "Alternativa material",
  materials: "material",
  greenBuildings: "Gröna byggnader",
  energyEfficiency: "Energieffektivitet",
  digitalisationOfRealEstate: "Digitalisering av fastigheter",
  futureOfWork: "Arbets framtid",
  energyManagement: "Energi förvaltning",
  futureOfCityLife: "Framtida av stad liv",
  infrastructure: "Infrastruktur",
  utilitiesAndWatermanagement: "Verktyg & Vatten förvaltning",
  services: "Tjänster",
  selectAtleaseOneSmartCity: "Välj minst en smart stad",
  loginImpactDesc:
    "Där effektstartar får stöd för att skapa globala företag som gör världen till en bättre plats.",
  received: "mottagen",
  application: "Ansökan",
  selectAtleaseOneSubCategory: "Välj minst en underkategori",
  welcome: "Välkommen",
  management: "Förvaltning",
  financials: "Ekonomi",
  tech: "Teknik",
  legal: "Rättslig",
  hr: "Hr",
  successfullyDeleted: "Framgångsrikt raderade.",
  weCouldNotDeleted: "Vi kunde inte radera.",
  documentUpdatedSuccessfully: "Dokumentet har uppdaterats.",
  areYouSure: "Är du säker?",
  youWillNotAbleToRecoverThisFile:
    "Du kommer inte att kunna återställa den här filen.",
  byClickingThisYouAgreeToOur: "Genom att klicka på detta godkänner du vårt",
  privacyPolicy: "Integritetspolicy",
  termsAndConditions: "Villkor",
  tearmsAndPrivacyMissing: "Terms and privacy missing",
  registerRoyaltyDesc:
    "Om jag lyckades samla in kapital genom att investerare träffades på Sircular, förstår jag att jag är skyldig att betala 3% av det totala beloppet från dessa investerare till Sircular.",
  moreInformation: "Mer information",
  moreInformationPara1: "Den genomsnittliga provisionen i branschen är 5-8%.",
  moreInformationPara2:
    "Sircular tar 3% provision för att generera de intäkter som behövs för att utveckla och underhålla Sircular -nätverket och -verktyget.",
  moreInformationPara3:
    "Denna 3% provision debiteras endast för de investeringar som samlats in genom investerare som du har träffat i Sircular -nätverket.",
  moreInformationPara4:
    "Detta innebär att även om du delar din Sircular -profil med investerare utanför Sircular -nätverket för att bygga ditt investeringsfall, behöver du inte betala 3% provision på de externa investerarna.",
  moreInformationPara5:
    "Exempel: Start A höjer 10 miljoner euro. Hälften av investeringsomgången (5 miljoner euro) samlas in från tre investerare som Startup A träffade genom Sircular. Resterande 5 miljoner euro kommer från investerare som Startup A träffade utanför Sircular -nätverket.",
  moreInformationPara6:
    "I det här exemplet är Start A bara skyldigt att betala provision på 5 miljoner euro som de samlat in från investerare som träffades på Sircular -nätverket.",
  termsAndPrivacyPolicyCheckBoxError:
    "Vänligen kontrollera villkoren och sekretesspolicyn om du vill fortsätta.",
  source: "Källa",
  pendingApplications: "Väntande ansökningar",
  pendingApplicationsFromStatups: "Väntande ansökan från startups",
  addOrRemoveStatusDnD:
    "Lägg till eller ta bort en status ur ditt dealflow. Använd drag-and-drop för att ändra ordning på dina statusar",
  addANewStatus: "Lägg till en ny status",
  enterYourNewStatus: "Ange din nya status",
  statusInUse:
    "Denna status är tilldelad till något av dina bolag. Var god ändra deras status innan du tar bort denna status.",
  statusIsNotDeletable: "Denna status går ej att ta bort",
  youCanOnlyAdd30Statuses: "Du kan maximalt lägga till 30 statusar",
  statusAlreadyInList: "Denna status finns redan i din lista",
  customizeStatuses: "Anpassa dina statusar",
  applicationsAreNowClosed: "Ansökningarna är nu stängda.",
  weWishYouTheBestWithYourVenture: "Vi önskar dig det bästa med din satsning.",
  stayTurnedOn: "Stanna på",
  eventsAndInitiativesByFollowingUsOnSocialMedia:
    "evenemang och initiativ genom att följa oss på sociala medier:",
  dontAddHttpInInput:
    "Lägg inte till http/https i ingången, har redan lagt till.",
  pastThreeMonth: "De senaste 3 månaderna",
  custom: "Beställnings",
  profileAnalytics: "Profilanalys",
  filterBy: "Filtrera efter",
  totalVisits: "Totalt antal besök",
  downloads: "Nedladdningar",
  profileLink: "Profillänk",
  uniqueProfileLinks: "Privata länkar",
  enterUniqueLinkName: "Ange ett unikt länknamn",
  create: "Skapa",
  uniqueVisitors: "Unika besökare",
  pitchDownloads: "Pitch -nedladdningar",
  copy: "Kopiera",
  chat: "Chatt",
  keep: "Ha kvar",
  track: "Spår",
  trialScreenDesc:
    "Dela en unik länk med en investerare och få insikter som ger dig möjlighet att göra rätt drag vid rätt tidpunkt.",
  didTheyViewYourProfile: "Visade de din profil?",
  howManyTimes: "Hur många gånger?",
  didTheyShareItWithTheirNetwork: "Delade de det med sitt nätverk?",
  didTheyDownloadYourPitchDeck: "Har de laddat ner ditt pitchdäck?",
  andManyMore: "Och många fler...",
  tryFreeForTwoWeeks: "Prova gratis i två veckor",
  afterTheFreeTrial: "Efter den kostnadsfria testperioden",
  weCouldNotLoadData: "Vi kunde inte ladda data.",
  myplace: "Mitt ställe",
  linkCreatedSuccessfully: "Länken skapades.",
  mySpace: "Mitt utrymme",
  viewYourAnalyticsIn: "Visa din analys i",
  pageLink: "Sid länk",
  public: "offentlig",
  private: "Privat",
  profile: "Profil",
  unique: "Unik",
  thisWeek: "Denna vecka",
  thisMonth: "Den här månaden",
  byPremium: "Köp premium",
  recentlyCreated: "Nyligen skapat",
  trackAllYourPrivateLinksIn: "Spåra alla dina privata länkar i",
  trackAllYourUniqueLinksIn: "Spåra alla dina unika länkar i",
  pastOneYear: "Senaste 1 året",
  selectOne: "Välj en",
  allPeriod: "Hela perioden",
  upgradeToPremiumNow: "Uppgradera till premium nu",
  yourFreeTrialIsExpireSoon: "Din gratis provperiod går snart ut!",
  unlinkAvailableDesc:
    "Den här delade länken har tagits bort eller är inte tillgänglig för dig.",
  linkHasBeen: "Länk har varit",
  enabled: "aktiverad",
  disabled: "Inaktiverad",
  searchLinkName: "Söklänknamn",
  copied: "Kopierat",
  goPrivate: "Gå privat",
  joinNetwork: "Gå med i nätverket",
  referral: "remiss",
  invalidReferralCode: "Ogiltig hänvisningskod",
  thisReferralCodeIsInvalid: "Den här hänvisningskoden är ogiltig.",
  invite: "inbjudan",
  clickToCopyYourReferralLink: "Klicka för att kopiera din hänvisningslänk",
  orShareAcross: "eller dela över",
  YourListIsEmpty: "Din lista är tom",
  startInviting: "Börja bjuda in!",
  membersJoined: "medlemmar anslöt sig.",
  membersPending: "medlemmar väntande.",
  showPending: "visa väntar",
  hidePending: "Göm väntande",
  Name: "Namn",
  Type: "Typ",
  Angel: "Ängel",
  hasInvited: "har bjudit in",
  youToJoin: "dig att gå med",
  Sircular: "Sircular",
  joinSircularAs: "Gå med i Sircular as",
  Investor: "investerare",
  Startup: "börja",
  createdOn: "Skapad på",
  applyFilter: "Använd filter",
  uniqueLink: "unik länk",
  past7Days: "Senaste 7 dagarna",
  past30Days: "Senaste 30 dagarna",
  lastVisited: "Senast besökt",
  done: "Gjort",
  aboutYou: "Om dig",
  firmName: "Firma namn",
  areYouOpenToSupportStartupAsAnAdvisor:
    "Är du öppen för att stödja startups som rådgivare?",
  whatIsYourPrimaryLocation: "Vilken är din primära plats?",
  shareShortDescriptionAsInvestor:
    "Dela en kort beskrivning om dig själv som investerare",
  introduceYourSelf: "Introducera dig själv",
  investmentStrategy: "Investeringsstrategi",
  howMuchCapitalLookingInvestInStartup:
    "Hur mycket kapital vill du för närvarande investera i startups?",
  whatStagesOfStartupMostlyInterestedInInvesting:
    "Vilka stadier av en startup är du mest intresserad av att investera?",
  whatBusinessRelationshipModelsAreYouMostinterestedIn:
    "Vilka affärsrelationsmodeller är du mest intresserad av?",
  whatIndustriesAreYouMostInterested:
    "Vilka branscher är du mest intresserad av?",
  whatInvestmentOppertunitiesAreYouInterested:
    "Vilka investeringsmöjligheter är du mest intresserad av?",
  whatInvestmentOppertunitiesAreYouNotInterested:
    "Vilka investeringsmöjligheter är du inte intresserad av?",
  sdgFocus: "SDG fokus",
  whichSDGAreMostInterestedInInvesting:
    "Vilka SDG är du mest intresserad av att investera i?",
  ifSDGsAreImportantToYouShareWhy: "Om SDG är viktiga för dig, berätta varför?",
  tellUsAboutYourTeamIfYouHaveOne:
    "Berätta för oss om ditt team, om du har ett",
  fillAtleastOneMemberDetails: "Fyll i minst en medlemsinformation",
  yourInvestmentPortfolio: "Din investeringsportfölj",
  whatStartupsHaveYouPreviouslyInvestedIn:
    "Vilka startups har du tidigare investerat i?",
  addMember: "Lägg till medlem",
  canNotFindYourStartup: "Hittar du inte din startup?",
  investmentDate: "Investeringsdatum",
  countryName: "Lands namn",
  selectStartupInvestingStage: "Välj startupens investeringsstadium",
  selectIndustyType: "Välj branschtyp",
  selectSDGsYourStartupIsContributingTo:
    "Välj de SDG som din start bidrar till",
  typeCountyCityName: "Skriv läns-/stadsnamn",
  thisStartupHasBeenAlreadySelected: "Denna start har redan valts",
  homeLeisure: "Hem Fritid",
  hrRecruitment: "HR Rekrytering",
  naturalResources: "Naturliga resurser",
  smartCities: "Smarta städer",
  meetLink: "Möt länk",
  AddaLinkToBookMeeting: "Lägg till länk för att boka ett möte (dvs. Calendly)",
  youWillNotAbleToRecoverData: "Du kommer inte att kunna återställa data.",
  primaryLocationIsRequired: "Primär plats krävs",
  selectValidLocationFromDropdown: "Välj giltig plats från rullgardinsmenyn",
  investableCapital: "Investerbart kapital",
  ticketSize: "Biljettstorlek",
  investingStage: "investeringsstadiet",
  beyondCapital: "Bortom kapital",
  mostInterestedIn: "Mest intresserad av",
  NotInterestedIn: "Inte intresserad av",
  advisor: "Rådgivare",
  countries: "Länder",
  gotIt: "Jag förstår",
  thanksForSendingApplicationOnSircularNetwork:
    "Tack för att du skickade din ansökan till Sircular-nätverket.",
  weWillGetBackToYouAsap: "Vi återkommer till dig snarast!",
  beAnonymous: "Var anonym",
  allStatrupStages: "Alla startsteg",
  stages: "Etapper",
  location: "Plats",
  meetTheTeam: "Möt laget",
  teamDescriptionForInvestor:
    "Toppproffs från hela världen deltar i att forma framtidens arbete.",
  whiteSpaceIsNotAllowed: "Vitt utrymme är inte tillåtet",
  notYet: "Inte än",
  aboutYouSavedSuccessfully: "Om du har sparats.",
  investmentStrategySavedSuccessfully:
    "Investeringsstrategin har sparats framgångsrikt.",
  sdgFocusSavedSuccessfully: "SDG-fokus har sparats.",
  teamSavedSuccessfully: "Teamet har sparats.",
  addedSuccessfully: "har lagts till.",
  previousIntestment: "Tidigare investering",
  saveAndNext: "Spara & nästa",
  investorName: "Investerarnamn",
  locationOfStartupsHQ: "Plats för startups huvudkontor",
  updatedSuccessfully: "Uppdateringen lyckades.",
  priceRange: "Prisklass",
  whatTicketSizeAreYouLookingToInvestInStartup:
    "Vilken biljettstorlek vill du investera i en given startup?",
  deletedSuccessfully: "raderades framgångsrikt.",
  yesIHaveATeam: "Ja, jag har ett team",
  saveAndSubmit: "Spara & skicka",
  foodDelivery: "Matleverans",
  foodSafetyTracebility: "Spårbarhet för livsmedelssäkerhet",
  nextgenFoodDrinks: "Nextgen Mat Drycker",
  consumerAppServices: "Apptjänster för konsumenter",
  foodProcessing: "Livsmedelsbearbetning",
  withinAgrifoodWhichOfTheseSubcategoriesIdentifyWith:
    "Vilken av dessa underkategorier identifierar du dig med inom Agrifood?",
  searchHere: "Sök här",
  dealroom: "Dealroom",
  bookmarked: "Bokmärkt",
  editProfile: "Redigera profil",
  myProfile: "My profile",
  passwordError:
    "Lösenordet måste vara minst 8 tecken långt med 1 versal, 1 gemen, 1 numeriskt och 1 specialtecken (!@#$%^&*).",
  notes: "Anteckningar",
  comments: "Kommentarer",
  addComment: "Lägg till kommentar...",
  pending: "I väntan på",
  approved: "Godkänd",
  all: "Allt",
  invalidPassword: "felaktigt lösenord",
  applications: "Ansökningar",
  weCouldNotFindDataForProvidedFilters:
    "Vi kunde inte hitta data för tillhandahållna filter",
};
