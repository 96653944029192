import { gql } from "@apollo/client";

export const GET_AIA_CHAT = gql`
  query getAiaChat($companyId: String!, $sessionId: String!) {
    aia {
      chat(companyId: $companyId, sessionId: $sessionId) {
        id
        question
        answer
      }
    }
  }
`;
