// @ts-nocheck
import { put, select, takeEvery } from "redux-saga/effects";
import {
  getProductDetails as getProductDetailsSelector,
  getProductImages,
} from "./selectors";
import * as sagaActions from "./sagaActions";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  ADD_PRODUCT_IMAGE,
  GET_PRODUCT_DETAILS,
  SAVE_PRODUCT_DETAILS,
} from "queries/startup/startupSettings/productStep";
import { goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { updateProductDetails } from "../../reducer";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

function* getProductDetails() {
  yield put(toggleLoader({ component: STEP.PRODUCT, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_PRODUCT_DETAILS,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  yield put(
    updateProductDetails({
      product: { ...data?.me, productImages: data?.me.productImages || [] },
    })
  );

  yield put(toggleLoader({ component: STEP.PRODUCT, isLoading: false }));
}

function* saveProductDetails({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.PRODUCT, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: SAVE_PRODUCT_DETAILS,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.PRODUCT, isLoading: false }));
    return;
  }
  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield getProductDetails();

  yield put(toggleLoader({ component: STEP.PRODUCT, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

function* saveImagesInProductDetails(lang, newImages) {
  const existingProductData = yield select(getProductDetailsSelector);

  const { isError } = yield client
    .mutate({
      mutation: SAVE_PRODUCT_DETAILS,
      fetchPolicy: "network-only",
      variables: {
        input: {
          ...existingProductData,
          productImages: newImages,
        },
      },
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    return;
  }

  yield getProductDetails();
}

function* addProductImage({ payload: { lang, file } }) {
  const { isError, data } = yield client
    .mutate({
      mutation: ADD_PRODUCT_IMAGE,
      fetchPolicy: "network-only",
      variables: {
        file,
      },
    })
    .catch((error) => ({ isError: true, error }));

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    return;
  }

  const existingProductImages = yield select(getProductImages);

  yield saveImagesInProductDetails(lang, [
    ...(existingProductImages || []),
    data.addProductImage,
  ]);
}

function* removeProductImage({ payload: { lang, productImages } }) {
  yield saveImagesInProductDetails(lang, productImages);
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getProductDetails, getProductDetails),
    yield takeEvery(sagaActions.saveProductDetails, saveProductDetails),
    yield takeEvery(sagaActions.addProductImage, addProductImage),
    yield takeEvery(sagaActions.removeProductImage, removeProductImage),
  ];
}
