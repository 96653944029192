//@ts-nocheck
import { getClient, History } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { refetchUserData } from "models/user/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import { LOGIN_PAGE, LOGIN_PAGE_BUTTON, LOGOUT_COMPONENT } from "./constants";
import * as slice from "./reducer";
import {
  loginUser,
  logoutUser,
  triggerGoogleSignIn,
  triggerGoogleSignUp,
} from "./sagaActions";
import { socialLoginReducer } from "./reducer";
import { Mixpanel } from "core/mixpanel/MixpanelWrapper";
import {
  googleMixpanelSignIn,
  googleMixpanelSignUp,
  mixpanelSignIn,
} from "core/mixpanel/Mixpanel";

import {
  GOOGLE_SIGNIN,
  GOOGLE_SIGNUP,
  LOGIN_MUTATION,
  LOGOUT,
} from "queries/auth/auth";
import { REMEMBER_ME_COOKIE_KEY } from "core/consts";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const setCookie = (key, value) => {
  cookies.set(key, value, { path: "/" });
};

const doGoogleSignIn = async (input) => {
  try {
    let client = getClient();
    await client
      .mutate({
        mutation: GOOGLE_SIGNIN,
        variables: { input },
      })
      .then((res) => {
        googleMixpanelSignIn(
          res.data?.googleSignIn?.email,
          res.data?.googleSignIn?.type
        );
      });
    return { isError: false };
  } catch (error) {
    console.log({ error });
    return { isError: true, error };
  }
};

const doGoogleSignUp = async (input) => {
  try {
    let client = getClient();
    await client
      .mutate({
        mutation: GOOGLE_SIGNUP,
        fetchPolicy: "network-only",
        variables: { input },
      })
      .then((res) => {
        googleMixpanelSignUp(
          res.data?.googleSignUp?.email,
          res.data?.googleSignUp?.type,
          input.cohort
        );
      });
    return { isError: false };
  } catch (error) {
    console.log({ error });
    return { isError: true, error };
  }
};

const doLogin = async ({ variables, fetchPolicy = "network-only" }) => {
  try {
    let client = getClient();
    return await client.mutate({
      mutation: LOGIN_MUTATION,
      fetchPolicy,
      variables,
    });
  } catch (error) {
    return { isError: true, error };
  }
};

function* googleSignInSaga({ payload: { lang, input } }) {
  yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: true }));

  yield put(socialLoginReducer({ googleLoading: true }));

  try {
    const { isError, error } = yield doGoogleSignIn(input);

    if (!isError) {
      yield put(refetchUserData({}));
      return;
    }

    let errorMessage;
    if (error?.graphQLErrors[0]?.extensions.code === "EMAIL_NOT_REGISTERED") {
      errorMessage =
        "Email is not registered with sircular. Please create an account";
    } else {
      errorMessage = lang.thereIsProblemInTheSystemContactToAdmin;
    }

    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: errorMessage,
      })
    );
  } catch (error) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.thereIsProblemInTheSystemContactToAdmin,
      })
    );
  } finally {
    yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: false }));
  }
}

function* googleSignUpSaga({ payload: { lang, input } }) {
  yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: true }));

  yield put(socialLoginReducer({ googleLoading: true }));

  try {
    const { isError, error } = yield doGoogleSignUp(input);

    if (!isError) {
      yield put(refetchUserData({}));
      yield put(slice.setGoogleSignUpSuccess());
      return;
    }

    let errorMessage;
    if (
      error?.graphQLErrors[0]?.extensions.code === "EMAIL_ALREADY_REGISTERED"
    ) {
      errorMessage = "You already have a account with us. Please sign in";
    } else {
      errorMessage = lang.thereIsProblemInTheSystemContactToAdmin;
    }

    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: errorMessage,
      })
    );
  } catch (error) {
    console.log(error);
    const errorMessage = lang.thereIsProblemInTheSystemContactToAdmin;

    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: errorMessage,
      })
    );
  } finally {
    yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: false }));
  }
}

function* handleLogin({ payload: { formValues, rememberMe, referrerId } }) {
  let { email, password } = formValues;
  yield put(toggleLoader({ component: LOGIN_PAGE_BUTTON, isLoading: true }));

  try {
    const variables = { email, password };

    if (referrerId) {
      variables.referrer = referrerId;
    }

    const { data, error, isError } = yield doLogin({
      variables,
    });

    if (isError) {
      throw error;
    }

    if (rememberMe) {
      setCookie(REMEMBER_ME_COOKIE_KEY, { email, active: true });
    } else {
      setCookie(REMEMBER_ME_COOKIE_KEY, {});
    }

    // if (referrerId) {
    //   History.navigate("/edit-startup-profile");
    // } else {
    //   History.navigate("/");
    // }
    yield put(refetchUserData({}));
    mixpanelSignIn(data?.signInV2);
  } catch (error) {
    yield put(
      slice.updateLoginData({
        login: { hasError: true, loginError: error?.message ?? null },
      })
    );
  }
  yield put(toggleLoader({ component: LOGIN_PAGE_BUTTON, isLoading: false }));
}

function* doLogout() {
  const client = getClient();
  const response = yield client.mutate({
    mutation: LOGOUT,
    fetchPolicy: "network-only",
  });
  return response;
}

function* handleLogout({ payload: { lang } }) {
  yield put(toggleLoader({ component: LOGOUT_COMPONENT, isLoading: true }));

  try {
    yield doLogout();
    yield put(
      slice.logout({
        login: { hasError: false, loginError: "" },
      })
    );
    /* 
      There is no need to redirect to "/login" route since new 
      routing system will so that automatically after userData is cleared
    */
    yield put({ type: "resetState" });
  } catch (err) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.somethingWentWrong,
      })
    );
  }

  History.navigate("/login");

  try {
    Mixpanel.track("Logout");
    Mixpanel.reset();
  } catch (error) {
    console.log(error);
  }

  yield put(toggleLoader({ component: LOGOUT_COMPONENT, isLoading: false }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(loginUser, handleLogin),
    yield takeEvery(logoutUser, handleLogout),
    yield takeEvery(triggerGoogleSignIn, googleSignInSaga),
    yield takeEvery(triggerGoogleSignUp, googleSignUpSaga),
  ];
}
