import Tooltip from "atoms/Tooltip/Tooltip";
import { AiaCompetitor } from "gql/graphql";
import AiaFeedback from "organisms/AiaFeedback/AiaFeedback";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { CompetitorProps, CompetitorSource } from "./interfaces";
import Checkbox from "atoms/Checkbox/Checkbox";
import Similarity from "./Similarity";
import Difference from "./Difference";

const validationLoader = (competitor: AiaCompetitor | null | undefined) => {
  if (!competitor?.isValidated) {
    return (
      <div className="animate-pulse text-xs text-aqua-400">Validating</div>
    );
  }
  if (
    competitor.source === CompetitorSource.PROFILE ||
    (competitor?.website && competitor?.isValidated)
  ) {
    return (
      <Tooltip content={"This competitor has been validated"}>
        {
          <FontAwesomeIcon
            icon={["fal", "check-circle"]}
            color="#4fc2ba"
            size="sm"
          />
        }
      </Tooltip>
    );
  } else {
    return (
      <Tooltip content={"This competitor couldn't be validated"}>
        <FontAwesomeIcon
          icon={["fal", "times-circle"]}
          color="#ff6b6b"
          size="sm"
        />
      </Tooltip>
    );
  }
};

const Competitor: FC<CompetitorProps> = ({
  item,
  handleAddToDeepDive,
  idsDeepDive,
  companyId,
  isPublic,
  amITeamMember,
  noOfDeepDives,
}) => {
  const disabled =
    !item?.website ||
    !item?.isValidated ||
    item.isDeepDive ||
    idsDeepDive.length + noOfDeepDives >= 10 ||
    item?.website.includes("amazon");

  let disabledReason = !item?.website
    ? "we haven't been able to find a website."
    : !item?.isValidated
    ? "it hasn't been validated."
    : item.isDeepDive
    ? "it's already in deep dive."
    : "";

  switch (true) {
    case !item?.website || item?.website?.includes("amazon"):
      disabledReason = "we haven't been able to find a website.";
      break;
    case !item?.isValidated:
      disabledReason = "it hasn't been validated.";
      break;
    case item.isDeepDive:
      disabledReason = "it's already in deep dive.";
      break;
    case idsDeepDive.length + noOfDeepDives >= 10 &&
      !idsDeepDive.includes(item.id):
      disabledReason =
        "you have reached the maximum number (10) of competitors to deep dive.";
      break;
    default:
      disabledReason = "";
  }

  const tooltipContent = disabledReason
    ? `You cannot deep dive on this competitor since ${disabledReason}`
    : `${
        idsDeepDive.includes(item.id) ? "De-select" : "Select"
      } competitor to deep dive.`;

  return (
    <div
      className={cn(
        idsDeepDive.includes(item.id) && "bg-aqua-100",
        "flex flex-row items-center px-2 rounded-md"
      )}
    >
      <Tooltip content={tooltipContent}>
        <Checkbox
          checked={idsDeepDive.includes(item.id)}
          onChange={() => handleAddToDeepDive(item.id)}
          disabled={disabled && !idsDeepDive.includes(item.id)}
        />
      </Tooltip>
      <div className="flex px-3 py-3 border-b border-b-gray-200 hover:border-gray-100 items-center hover:transition-all ease-in-out">
        <div className="p-1 basis-1/3">
          {!isPublic && amITeamMember && (
            <AiaFeedback
              companyId={companyId}
              target={`competitor-${item?.id}`}
              noComment
              likeInfo="Thumbs up tells AIA that the competitor is relevant. This impacts your next search."
              dislikeInfo="Thumbs down tells AIA that the competitor is not relevant. This impacts your next search and removes it from the list."
            />
          )}
          <div className="my-2 text-sm font-semibold pointer text-gray-600 flex flex-row gap-1 item-center">
            <a
              className={cn(
                item?.website
                  ? "cursor-pointer hover:text-aqua-400"
                  : "pointer-events-none cursor-default"
              )}
              href={item?.website || ""}
              target="_blank"
              rel="noreferrer"
            >
              {item?.name}{" "}
            </a>
            {validationLoader(item)}
          </div>
          <div className="text-sm text-gray-500">{item?.pitch}</div>
        </div>
        <div className="p-1 mr-2 text-sm text-gray-600 basis-1/3">
          <Similarity
            content={item?.comparison?.similarities || ""}
            competitorId={item.id}
          />
        </div>
        <div className="p-1 mr-2 text-sm text-gray-600 basis-1/3">
          <Difference
            content={item?.comparison?.differences || ""}
            competitorId={item.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Competitor;
