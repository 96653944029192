import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import {
  ManuallyAddTeamMemberLinkedInMutation,
  ManuallyAddTeamMemberLinkedInMutationVariables,
} from "gql/graphql";
import { refetchUserData } from "models/user/sagaActions";
import {
  refetchCompanyData,
  refetchUiRepresentation,
} from "pages/companyProfile/sagaActions";
import { MANUALLY_ADD_LINKEDIN_MEMBERS } from "queries/aia/team";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

interface ManuallyAddLinkedInMemberProps {
  companyId: string;
  isOpen: boolean;
  slug?: string;
  onClose: () => void;
}

const isValidUrl = (url: string) => {
  const regex = new RegExp("^(http:\\/\\/|https:\\/\\/)");
  return regex.test(url);
};

const ManuallyAddLinkedInMember: FC<ManuallyAddLinkedInMemberProps> = ({
  companyId,
  isOpen,
  slug,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [urls, setUrls] = useState<string[]>([""]);

  const [manuallyAddTeamMembersLinkedIn, { loading, data, error }] =
    useMutation<
      ManuallyAddTeamMemberLinkedInMutation,
      ManuallyAddTeamMemberLinkedInMutationVariables
    >(MANUALLY_ADD_LINKEDIN_MEMBERS);

  const handleUrlChange = (value: string, index: number) => {
    setErrorMessage("");
    const newUrls = [...urls];
    newUrls[index] = value;
    setUrls(newUrls);
  };

  const handleAddField = () => {
    if (urls.length < 5) {
      setUrls([...urls, ""]);
    }
  };

  const handleAddManually = async (urls: string[]) => {
    if (urls[0] === "") {
      setErrorMessage("Please enter at least one team member URL");
      return;
    }
    if (urls.some((url) => !isValidUrl(url))) {
      setErrorMessage("Please enter valid URL http:// or https://");
      return;
    }
    await manuallyAddTeamMembersLinkedIn({
      variables: { companyId: companyId, linkedinUrls: urls },
    });
  };

  const handleCloseModal = () => {
    setErrorMessage("");
    setUrls([""]);
    onClose();
  };

  useEffect(() => {
    if (data?.aia?.manuallyAddTeamMemberLinkedIn?.done) {
      dispatch(refetchUiRepresentation({ id: companyId }));
      dispatch(refetchCompanyData({ slug }));
      handleCloseModal();
    }
  }, [data]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto ">
          <div className="flex min-h-full items-center justify-center mt-10 sm:mt-0 sm:p-10 text-center max-w-3xl mx-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white px-12 py-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Manually Add Team Members
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Add team members you have found by entering the LinkedIn
                    <br />
                    URL of the team member. You can add 5 team members at a
                    time.
                  </p>
                  <div className="flex flex-col mt-2">
                    {urls.map((url, index) => (
                      <input
                        key={index}
                        type="text"
                        placeholder="Enter team member URL"
                        value={url}
                        onChange={(e) => handleUrlChange(e.target.value, index)}
                        className="border px-2 py-1 rounded-md my-1 border-gray-500 w-3/4 placeholder:italic focus:outline-none focus:border-aqua-400"
                      />
                    ))}
                  </div>
                  {errorMessage && (
                    <p className="text-fire-400 text-sm mt-1 ml-1">
                      {errorMessage}
                    </p>
                  )}
                  {error && (
                    <p className="text-fire-400 text-sm mt-1 ml-1">
                      {error.message}
                    </p>
                  )}
                  <Button
                    className="my-2"
                    color="transparent"
                    disabled={loading}
                    onClick={() => handleAddField()}
                  >
                    Add more member URLs +
                  </Button>
                </div>

                <Button color="transparent" onClick={() => handleCloseModal()}>
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  color="primary"
                  loading={loading}
                  disabled={loading || urls[0] === ""}
                  onClick={() => handleAddManually(urls)}
                >
                  Add Team Members
                </Button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManuallyAddLinkedInMember;
