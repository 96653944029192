import { useQuery, useMutation } from "@apollo/client";
import { useLang } from "core/lang/LangaugeSetting";
import {
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { DropResult } from "react-beautiful-dnd";
import {
  GET_SECTIONS,
  UPDATE_DEALFLOW_SECTIONS,
} from "queries/investor/investorDealflow";
import { Status, EditStatusModalProps, NewStatusItem } from "./interfaces";

const REMAINING_CHAR_COUNT = 20;
const MAXIMUM_LENGTH = 30;

const useEditStatusModal = ({ onClose }: EditStatusModalProps) => {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [remaining, setRemaining] = useState(REMAINING_CHAR_COUNT);
  const [newStatus, setNewStatus] = useState("");
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [newListItem, setNewListItem] = useState<NewStatusItem>({
    _id: null,
    customTitle: "",
    title: "",
    order: 0,
    isDeletable: true,
  });
  const [isStatusInList, setIsStatusInList] = useState(false);
  const [listLengthError, setListLengthError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    data: listMyStatuses = {},
    loading: isMyListsLoading,
    refetch,
  } = useQuery(GET_SECTIONS);

  const statuses = listMyStatuses?.getSections || [];

  const isLoading = !isMyListsLoading && statuses.length === 0;

  const [updateDealflowSectionList, { loading: isUpdating }] = useMutation(
    UPDATE_DEALFLOW_SECTIONS
  );

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;

    if (!destination) return;

    const updatedList = [...statusList];
    const [reorderedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, reorderedItem);

    setStatusList(updatedList);
  };

  const handleStatusChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewStatus(e.target.value);
      setNewListItem({
        _id: null,
        customTitle: e.target.value,
        title: e.target.value,
        order: statusList.length + 1,
        isDeletable: true,
      });
    },
    [newListItem]
  );

  const handleKeyUp = () => {
    setRemaining(REMAINING_CHAR_COUNT - newStatus.length);
  };

  const onAddButtonClicked = () => {
    addStatusToList();
  };

  const onKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
    setErrorMsg("");
    setIsStatusInList(false);

    if (e.key === "Enter") {
      addStatusToList();
    }
  };

  const addStatusToList = () => {
    if (
      statusList.some(
        (i: { customTitle: string }) => i.customTitle === newStatus
      )
    ) {
      return setIsStatusInList(true);
    }
    if (statusList.length < MAXIMUM_LENGTH) {
      setStatusList((statusList: any) => {
        const newList = [...statusList, newListItem];
        setNewStatus("");
        return newList;
      });
    } else return setListLengthError(true);
  };

  const onDeleteStatusClicked = (item: Status) => {
    setErrorMsg("");

    if (!item.isDeletable) {
      return setErrorMsg(lang.statusIsNotDeletable);
    }
    if (item.companiesCount > 0) {
      return setErrorMsg(lang.statusInUse);
    }
    setStatusList((statusList: any[]) =>
      statusList.filter((i: { _id: string | null }) => i._id !== item._id)
    );
    setListLengthError(false);
  };

  const handleSubmit = async () => {
    const result = statusList.map(({ customTitle, order, _id }) => ({
      title: customTitle,
      order: order,
      sectionId: _id,
    }));

    result.map((i, index) => (i.order = index));

    try {
      updateDealflowSectionList({
        variables: {
          sections: result,
        },
      });
      await refetch();
    } catch (error: any) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Couldn't update list",
          message: "Something went wrong",
        })
      );
    }
    refetch();
    onClose(true);
  };

  useEffect(() => {
    if (listMyStatuses.getSections) {
      setStatusList(listMyStatuses.getSections);
    }
  }, [listMyStatuses]);

  return {
    statuses,
    lang,
    newStatus,
    errorMsg,
    isStatusInList,
    listLengthError,
    remaining,
    isLoading,
    isUpdating,
    statusList,
    refetch,
    handleStatusChange,
    onKeyPressed,
    onAddButtonClicked,
    onDeleteStatusClicked,
    handleSubmit,
    handleKeyUp,
    onDragEnd,
  };
};

export default useEditStatusModal;
