import type { FC } from "react";
import type { SdgSelectProps } from "./interfaces";
import { SDG } from "core/consts";
import Sdg from "molecules/Sdg/Sdg";

const toggle = (array: number[], item: number) => {
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  }

  return array.concat([item]);
};

const SdgSelect: FC<SdgSelectProps> = ({ items, onChange }) => (
  <div className="mt-1 grid grid-cols-6 gap-2 sm:mt-0 sm:col-span-2 sm:gap-6">
    {SDG.map((_, idx) => (
      <Sdg
        key={idx}
        number={idx + 1}
        onClick={() => onChange(toggle(items, idx + 1))}
        active={items.includes(idx + 1)}
      />
    ))}
  </div>
);

export default SdgSelect;
