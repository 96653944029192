import { createAction } from "@reduxjs/toolkit";

export const getTraction = createAction("companySettings/traction/fetch");

export const saveTraction = createAction(
  "companySettings/traction/save",
  (payload) => ({
    payload,
  })
);

export const updateTraction = createAction(
  "companySettings/traction/udpate",
  (payload) => ({
    payload,
  })
);
