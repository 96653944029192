// @ts-nocheck
import { initialState, name } from "./reducer";

const auth = (state) => state[name] || initialState;

export const isLoadingPage = (state) => auth(state)["isLoading"];

export const getLogin = (state) => auth(state)["login"];

export const getLoginError = (state) => getLogin(state)?.loginError;

export const getReferrerApplicationStatus = (state) =>
  auth(state)["isApplicationClosed"];

export const getUserConfirmationStatus = (state) =>
  auth(state)["isUserConfirmed"];

export const getAlreadyRegistered = (state) => auth(state)["alreadyRegistered"];

export const isGoogleLoading = (state) => auth(state).googleLoading;

export const formElementValue = (state) => auth(state).formElementVal;

export const getGoogleSignUpSuccess = (state) => auth(state).googleSignUpSuccess;
