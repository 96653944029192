import { useEffect } from "react";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalState } from "models/globalState/selectors";
import { NetworkStatus } from "models/globalState/constants";
import { setGlobalState } from "models/globalState/sagaActions";

const ConnectionStatus = () => {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const { status, showStausBar } = useSelector((state) =>
    getGlobalState(state, "network")
  );

  useEffect(() => {
    function handleNetworkOffline() {
      dispatch(
        setGlobalState({
          network: { status: NetworkStatus.Offline, showStausBar: true },
        })
      );
    }

    function handleNetworkOnline() {
      dispatch(
        setGlobalState({
          network: { status: NetworkStatus.Online, showStausBar: true },
        })
      );
      setTimeout(() => {
        dispatch(
          setGlobalState({
            network: { showStausBar: false },
          })
        );
      }, 3000);
    }

    window.addEventListener("online", handleNetworkOnline);
    window.addEventListener("offline", handleNetworkOffline);

    return () => {
      window.removeEventListener("online", handleNetworkOnline);
      window.removeEventListener("offline", handleNetworkOffline);
    };
  }, []);

  if (!showStausBar) return null;

  return (
    <h1
      className={`${
        status === NetworkStatus.Offline ? "bg-red-500" : "bg-green-500"
      }  text-white p-3.5 font-normal text-center z-50 w-full sticky top-0`}
    >{`${
      status === NetworkStatus.Offline
        ? lang.connectionIsOut
        : lang.connectionIsBack
    }.`}</h1>
  );
};

export default ConnectionStatus;
