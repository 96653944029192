// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  invitationId: undefined,
  invitationSlug: undefined,
  invitationStatus: undefined,
  inviteesEmail: undefined,
  inviterCompanyName: undefined,
  inviterCompanyLogo: undefined,
  isAlreadyAdminError: undefined,
};

export const name = "invitation";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateInvitationData: (
      state,
      {
        payload: {
          invitationId,
          invitationSlug,
          invitationStatus,
          invitateesEmail,
          inviterCompanyName,
          inviterCompanyLogo,
        },
      }
    ) => {
      state.invitationId = invitationId;
      state.invitationSlug = invitationSlug;
      state.invitationStatus = invitationStatus;
      state.inviteesEmail = invitateesEmail;
      state.inviterCompanyName = inviterCompanyName;
      state.inviterCompanyLogo = inviterCompanyLogo;
    },
    /* clearing invitation data will make modals close as well, no need to handle it seperately */
    clearInvitationData: (state) => {
      state.invitationId = undefined;
      state.invitationSlug = undefined;
      state.invitationStatus = undefined;
      state.inviteesEmail = undefined;
      state.inviterCompanyName = undefined;
      state.inviterCompanyLogo = undefined;
      state.isAlreadyAdminError = undefined;
    },
    createIsAdminError: (state) => {
      state.isAlreadyAdminError = true;
    },
  },
});

export default slice;

export const { updateInvitationData, clearInvitationData, createIsAdminError } = slice.actions;
