// @ts-nocheck
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "./selectors";
import { PageHeader, StepActionButton } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToNextStep, goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { saveDocument, removeDocument } from "./sagaActions";

import { triggerSnack } from "organisms/Snack/sagaActions";

import FileSelect from "atoms/Form/FileSelect";
import { ReactComponent as FileIcon } from "images/document-file-icon.svg";
import { ReactComponent as XlIcon } from "images/xl-icon.svg";
import { ReactComponent as CloseIcon } from "images/close-icon.svg";
//@ts-ignore
import { ReactComponent as DocumentIcon } from "images/document-icon.svg";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";

type Document = {
  filename: string;
  id: string;
  location: string;
  mimetype: string;
  title: string;
};

export default () => {
  const { lang } = useLang();
  const dispatch = useDispatch();

  const data: Document[] = useSelector(getDocuments);
  const loading = useSelector((state) =>
    isLoadingEnabled(state, STEP.PITCH_DECK)
  );

  const onSubmit = async ({}) => {
    dispatch(goToNextStep());
  };

  const onInputChange = async (data) => {
    if (
      data.file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      data.file.type !== "application/vnd.ms-excel" &&
      data.file.type !== "application/pdf"
    ) {
      dispatch(
        triggerSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: lang.pleaseUploadPExcelFormat,
        })
      );
      return null;
    }
    if (data.file.size > 20000000) {
      dispatch(
        triggerSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: lang.fileSizeIsTooLargeFor20MB,
        })
      );
      return null;
    }
    dispatch(
      saveDocument({
        lang,
        file: data.file,
      })
    );
  };

  const handleRemoveDoc = async (id) => {
    dispatch(
      removeDocument({
        lang,
        id,
      })
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <PageHeader>
        <div className="flex-1 min-w-0 mb-4">
          <div className="flex items-center">
            <DocumentIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.pitchDeck}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p> {lang.companyDocumentsDesc}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <label
          htmlFor="companyName"
          className="block mb-3 text-sm font-bold text-black sm:mb-0 sm:mt-px sm:pt-2"
        >
          {lang.upload}
        </label>

        <FileSelect
          multiple={true}
          type="document"
          onChange={(file) => onInputChange({ file })}
          disabled={loading}
          onError={(message) =>
            dispatch(
              triggerSnack({
                type: "error",
                title: lang.somethingWentWrong,
                message,
              })
            )
          }
          loading={loading}
          imageDesc={lang.dropYourFilesHereOr}
        ></FileSelect>
        <div className="block mt-4">
          {data &&
            data.length > 0 &&
            data.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between pt-2 pb-2"
              >
                <div className="flex items-center">
                  {item.mimetype === "application/pdf" ? (
                    <FileIcon className="w-5 h-5 mr-2" />
                  ) : (
                    <XlIcon className="w-5 h-5 mr-2" />
                  )}
                  {item.title}
                </div>
                <CloseIcon
                  className="ml-2 cursor-pointer"
                  onClick={() => handleRemoveDoc(item.id)}
                />
              </div>
            ))}
        </div>
      </div>
      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
