import { gql } from "@apollo/client";
import { graphql } from "gql";

export const GET_COMPLETED_STEPS = gql`
  query getCompletedSteps {
    me {
      _id
      completedMajorSteps
      completedPercentage
      completedSteps
    }
  }
`;

export const GET_PROFILE_INFO = gql`
  query getProfileInfo {
    me {
      _id
      completedPercentage
      completedSteps
      displayName
      pitch
      vision
      mission
      description
      city
      website
      socials {
        twitter
        linkedin
        instagram
      }
      lastName
      firstName
      meetingUrl
      country
      state
      fullAddress
      lat
      long
      investorType
      supportAsAdvisor
      competences
      orgNumber
      phone
    }
  }
`;

export const SUBMIT_APPLICATION = gql`
  mutation submitApplication {
    submitApplication {
      completedMajorSteps
    }
  }
`;

export const SAVE_WELCOME_PAGE_STATUS = graphql(`
  mutation saveWelcomePageStatus($step: String!) {
    saveWelcomePageStatus(step: $step) {
      id
    }
  }
`);
