import { hotjar } from "react-hotjar";
import { hotjarToken } from "./config";

// version is for updates and discontinuation,
// Hotjar will know what version and contact if update is needed.
const HOTJAR_VERSION = 6;
const env_check = process.env.NODE_ENV === "production";

export const initHotjar = () => {
  if (env_check) {
    hotjar.initialize(hotjarToken, HOTJAR_VERSION);
  }
};
