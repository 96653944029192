// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  GET_TRACTION,
  SAVE_TRACTION,
  UPDATE_TRACTION,
} from "queries/startup/startupSettings/tractionStep";
import { goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import * as stepsReducer from "../../reducer";
import { FieldValues } from "./TractionForm";
import {
  DEMANDFREQUNITS,
  PERRATEPERIOD,
  SUPPLYFREQUNITS,
  CHURNRATEPERIODS,
  GROWTHPERRATEPERIOD,
  TIME,
  USERS,
  GROWTHTIME,
  PERIODICITY,
} from "core/consts";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

type TractionData = {
  _id: string;
  summary: string;
  activeUsers: {
    amount: string;
    frequency: string;
  }[];
  activeUsersNote: string;

  totalUsers: {
    type: string;
    count: string;
  }[];
  totalUsersNote: string;

  growthRate: {
    period: string;
    amount: string;
    rate: string;
    time: string;
  }[];
  growthRateNote: string;

  retentionRate: {
    period: string;
    amount: string;
    rate: string;
    time: string;
  }[];
  retentionRateNote: string;

  churnRate: {
    period: string;
    percentage: string;
  }[];
  churnRateNote: string;

  netRevenue: {
    time: string;
    amount: string;
    currency: string;
  }[];
  netRevenueNote: string;

  recurringRevenue: {
    time: string;
    amount: string;
    currency: string;
  }[];
  recurringRevenueNote: string;

  revenueRate: {
    period: string;
    amount: string;
    rate: string;
    time: string;
  }[];
  revenueRateNote: string;

  smv_gsv: {
    time: string;
    amount: string;
    currency: string;
  }[];
  smv_gsvNote: string;

  salePriceAvg: {
    amount: string;
    currency: string;
  }[];
  salePriceAvgNote: string;

  ratePercentage: string[];
  ratePercentageNote: string;

  cacPayback: {
    amount: string;
    frequency: string;
  }[];
  cacPaybackNote: string;
  ltcCacRatio: string[];
  ltcCacRatioNote: string;

  demandFreq: {
    unit: string;
    amount: string;
    frequency: string;
  }[];
  demandFreqNote: string;

  supplyFreq: {
    unit: string;
    amount: string;
    frequency: string;
  }[];
  supplyFreqNote: string;
  signedContracts: string[];
  signedContractsNote: string;
  annualValueContracts: {
    amount: string;
    currency: string;
  }[];
  annualValueContractsNote: string;

  pipelineContracts: string[];
  pipelineContractsNote: string;
  annualValueContractsPipeline: {
    amount: string;
    currency: string;
  }[];
  annualValueContractsPipelineNote: string;
  lettersOfIntent: string[];
  lettersOfIntentNote: string;
  additionalTraction: string;
};

/*
// puting back the data into arrays that
// was formatted by formatGetTractionData
*/
const formatSaveTractionData = (data: FieldValues): TractionData => {
  const {
    activeUsers,
    totalUsers,
    growthRate,
    retentionRate,
    churnRate,
    netRevenue,
    recurringRevenue,
    revenueRate,
    smv_gsv,
    salePriceAvg,
    ratePercentage,
    cacPayback,
    ltcCacRatio,
    demandFreq,
    supplyFreq,
    signedContracts,
    annualValueContracts,
    pipelineContracts,
    annualValueContractsPipeline,
    lettersOfIntent,
    ...rest
  } = data;
  return {
    activeUsers: [activeUsers],
    totalUsers: [totalUsers],
    growthRate: [growthRate],
    retentionRate: [retentionRate],
    churnRate: [churnRate],
    netRevenue: [netRevenue],
    recurringRevenue: [recurringRevenue],
    revenueRate: [revenueRate],
    smv_gsv: [smv_gsv],
    salePriceAvg: [salePriceAvg],
    ratePercentage: [ratePercentage],
    cacPayback: [cacPayback],
    ltcCacRatio: [ltcCacRatio],
    demandFreq: [demandFreq],
    supplyFreq: [supplyFreq],
    signedContracts: [signedContracts],
    pipelineContracts: [pipelineContracts],
    annualValueContracts: [annualValueContracts],
    annualValueContractsPipeline: [annualValueContractsPipeline],
    lettersOfIntent: [lettersOfIntent],
    ...rest,
  };
};

const getDefaultOption = (oprionsMap: Object) => Object.keys(oprionsMap)[0];

/*
// This formating is required since most of query data 
// is inside an array. ex: activeUsers is an array
// which is confusing for UI logic as only one value for
// that property. so here we return just the value.
*/
const formatGetTractionData = (data: TractionData): FieldValues => {
  const {
    activeUsers,
    totalUsers,
    growthRate,
    retentionRate,
    churnRate,
    netRevenue,
    recurringRevenue,
    revenueRate,
    smv_gsv,
    salePriceAvg,
    ratePercentage,
    cacPayback,
    ltcCacRatio,
    demandFreq,
    supplyFreq,
    signedContracts,
    annualValueContracts,
    pipelineContracts,
    annualValueContractsPipeline,
    lettersOfIntent,
    ...rest
  } = data;
  return {
    activeUsers: {
      frequency: activeUsers?.[0].frequency || getDefaultOption(PERIODICITY),
      amount: activeUsers?.[0].amount || "",
    },
    totalUsers: {
      type: totalUsers?.[0].type || getDefaultOption(USERS),
      count: totalUsers?.[0].count || "",
    },
    growthRate: {
      ...growthRate?.[0],
      period: growthRate?.[0].period || Object.keys(GROWTHPERRATEPERIOD)[0],
      time: growthRate?.[0].time || Object.keys(GROWTHTIME)[0],
      rate: growthRate?.[0].rate || "",
      amount: growthRate?.[0].amount || "",
    },
    retentionRate: {
      period: retentionRate?.[0].period || getDefaultOption(PERIODICITY),
      time: retentionRate?.[0].time || getDefaultOption(TIME),
      rate: retentionRate?.[0].rate || "",
      amount: retentionRate?.[0].amount || "",
    },
    churnRate: {
      period: churnRate?.[0].period || Object.keys(CHURNRATEPERIODS)[0],
      percentage: churnRate?.[0].percentage || "",
    },
    netRevenue: {
      time: netRevenue?.[0].time || getDefaultOption(PERRATEPERIOD),
      amount: netRevenue?.[0].amount || "",
      currency: netRevenue?.[0].currency || "",
    },
    recurringRevenue: {
      time: recurringRevenue?.[0].time || getDefaultOption(PERIODICITY),
      amount: recurringRevenue?.[0].amount || "",
      currency: recurringRevenue?.[0].currency || "",
    },
    revenueRate: {
      period: revenueRate?.[0].period || Object.keys(GROWTHPERRATEPERIOD)[0],
      time: revenueRate?.[0].time || Object.keys(GROWTHTIME)[0],
      amount: revenueRate?.[0].amount || "",
      rate: revenueRate?.[0].rate || "",
    },
    smv_gsv: {
      time: smv_gsv?.[0].time || getDefaultOption(PERIODICITY),
      amount: smv_gsv?.[0].amount || "",
      currency: smv_gsv?.[0].currency || "",
    },
    salePriceAvg: {
      amount: salePriceAvg?.[0].amount || "",
      currency: salePriceAvg?.[0].currency || "",
    },
    ratePercentage: ratePercentage?.[0] || "",
    cacPayback: {
      frequency: cacPayback?.[0].frequency || getDefaultOption(TIME),
      amount: cacPayback?.[0].amount || "",
    },
    ltcCacRatio: ltcCacRatio?.[0] || "",
    demandFreq: {
      unit: demandFreq?.[0].unit || Object.keys(DEMANDFREQUNITS)[0],
      frequency: demandFreq?.[0].frequency || Object.keys(PERRATEPERIOD)[0],
      amount: demandFreq?.[0].amount || "",
    },
    supplyFreq: {
      unit: supplyFreq?.[0].unit || Object.keys(SUPPLYFREQUNITS)[0],
      frequency: supplyFreq?.[0].frequency || Object.keys(PERRATEPERIOD)[0],
      amount: supplyFreq?.[0].amount || "",
    },
    signedContracts: signedContracts?.[0] || "",
    pipelineContracts: pipelineContracts?.[0] || "",
    annualValueContracts: {
      amount: annualValueContracts?.[0].amount || "",
      currency: annualValueContracts?.[0].currency || "",
    },
    annualValueContractsPipeline: {
      amount: annualValueContractsPipeline?.[0].amount || "",
      currency: annualValueContractsPipeline?.[0].currency || "",
    },
    lettersOfIntent: lettersOfIntent?.[0] || "",
    ...rest,
  };
};

function* getTraction() {
  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_TRACTION,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  let traction;

  traction = formatGetTractionData(data?.getCompanyTraction || {});

  yield put(
    stepsReducer.updateTraction({
      traction,
    })
  );

  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: false }));
}

function* saveTraction({ payload: { lang, data } }) {
  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: SAVE_TRACTION,
      fetchPolicy: "network-only",
      variables: {
        input: formatSaveTractionData(data),
      },
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.TRACTION, isLoading: false }));
    return;
  }

  yield getTraction();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

function* updateTraction({ payload: { lang, data } }) {
  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: UPDATE_TRACTION,
      fetchPolicy: "network-only",
      variables: {
        input: formatSaveTractionData(data),
      },
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.TRACTION, isLoading: false }));
    return;
  }

  yield getTraction();

  yield put(toggleLoader({ component: STEP.TRACTION, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getTraction, getTraction),
    yield takeEvery(sagaActions.saveTraction, saveTraction),
    yield takeEvery(sagaActions.updateTraction, updateTraction),
  ];
}
