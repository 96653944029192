import { gql } from "@apollo/client";

export const GET_STARTUP_ANALYSIS = gql`
  query getStartupAnalysis($companyId: String!) {
    aia {
      analysis(companyId: $companyId) {
        team {
          sameIndustryExperience
          criticalCompetences
          coExperiences
          previouslyStudiedTogether
        }
        investors {
          strengths
          coInvestments
          investors {
            title
            name
            experience
            education
            linkedin
            image {
              large {
                location
              }
            }
          }
        }
        investment {
          questionsFromFounders
          riskAnalysis
          opportunityAnalysis
        }
        marketOpp {
          marketOpp
          blueOcean
          sources {
            url
            title
          }
        }
        legislation {
          todaysLegislation
          futureLegislation
        }
        executiveSummary {
          problem
          product
          team
          marketOpp
          businessModel
          goToMarket
        }
        criteriaAnalysis {
          score
          evaluations {
            category
            description
            criteriaFulfillmentStatus
            comment
            importance
          }
        }
      }
    }
  }
`;
