// @ts-nocheck
import { useState, useEffect, ReactElement } from "react";
import { useLang } from "core/lang/LangaugeSetting";
import cn from "classnames";

type ButtonGroupProps = {
  id: string;
  options: [string, string][];
  onChange: (data: [string, string]) => void;
  name: string;
  limit: number;
  defaultValue: string | string[];
  type: string;
  className: string;
  disabled?: boolean;
};

const ButtonGroup = ({
  options = [],
  onChange,
  limit = undefined,
  defaultValue,
  type,
  className,
  disabled,
}: Partial<ButtonGroupProps>): ReactElement => {
  const [items] = useState(8);
  const [state, setState] = useState([]);
  const [limitReached, setLimitReached] = useState(false);
  const [isMore, setIsMore] = useState(options.length > 8 ? true : false);

  const { lang } = useLang();

  useEffect(() => {
    if (defaultValue && !Array.isArray(defaultValue)) {
      setState([defaultValue]);
    }

    if (Array.isArray(defaultValue)) {
      setState(defaultValue);
    }
  }, [defaultValue]);

  const emit = (data) => {
    if (onChange && typeof onChange === "function") {
      onChange(data);
    }
  };

  // TODO update setCategories
  const setCategories = (value) => {
    let newState;
    if (state.includes(value)) {
      newState = state.filter((category) => category !== value);
      setState(newState);
      setLimitReached(false);
      return emit(newState);
    }
    if (limit == 1) {
      newState = [value];
      setState(newState);
      emit(newState);
    } else if (limit === undefined || state.length < limit) {
      newState = [...state, value];
      setState(newState);
      emit(newState);
    } else {
      setLimitReached(true);
    }
  };

  return (
    <div>
      <div className="flex flex-wrap mt-0">
        {options.slice(0, isMore ? items : options.length).map((category) => {
          const selected = state.includes(category[0]);
          return (
            <div
              key={category[0]}
              className={cn(
                className,
                "border border-gray-200 border-solid rounded-full text-sm cursor-pointer py-2 px-4 mr-4 mb-4 hover:bg-gray-200 select-none",
                selected &&
                  (type === "company"
                    ? "bg-fire-400 hover:!bg-fire-500 hover:border-fire-500 border-fire-400 text-white"
                    : "bg-aqua-400 hover:!bg-aqua-500 hover:border-aqua-500 border-aqua-400 text-white"),
                disabled && "text-[#DDDDDD] bg-white hover:bg-white"
              )}
              onClick={() => {
                if (!disabled) setCategories(category[0]);
              }}
            >
              {category[1]}
            </div>
          );
        })}
        {options.length > 8 && (
          <div
            className={cn(
              className,
              "border border-gray-900 hover:border-gray-400 border-solid rounded-full text-sm cursor-pointer py-2 px-4 mr-4 mb-4 select-none bg-midnight hover:bg-gray-400 text-white"
            )}
            onClick={() => setIsMore(!isMore)}
          >
            {!isMore ? lang.less : `+ ${options.length - items} ${lang.more}`}
          </div>
        )}
      </div>

      {limitReached && (
        <p className="mt-2 ml-2 text-sm text-fire-400">{`${lang.youCanChooseMaximum} ${limit} ${lang.choices}`}</p>
      )}
    </div>
  );
};

export default ButtonGroup;
