import {  FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {  useQuery } from "@apollo/client";

import { AIA_TRIAL_STATUS } from "./queries";
import { useLocation, useNavigate } from "react-router-dom";
import AiaCheckoutSuccess from "./components/AiaCheckoutSuccess";
import { getUser } from "models/user/selectors";
import pitchDeckImgUrl from "images/pitch-deck.png";
import Loading from "atoms/Loading/Loading";
import AiaHeader from "./AiaHeader";
import InvestmentCriteria from "./InvestmentCriteria"
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import {
  UpgradePlanModal,
} from "organisms/AccessControl/UpgradePlanModal";
import Button from "atoms/Button/Button";
import UploadStartupList from "./UploadStartupList";
import PitchDeckPdf from "./PitchDeckPDF";
import WebUrl from "./WebUrl";
import { Tier } from "gql/graphql";

const AIA_FEATURE_LIMITS = ["WEBSITE_AS_CONTEXT", "INVESTMENT_CRITERIA"];

const Aia: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const criteriaDialogRef = useRef<HTMLDialogElement | null>(null);
  const uploadListDialogRef = useRef<HTMLDialogElement | null>(null);

  const closeCriteriaDialog = () => {
    if (criteriaDialogRef.current) criteriaDialogRef.current.close();
  };

  const openUploadListDialog = () => {
    if (uploadListDialogRef.current) uploadListDialogRef.current.showModal();
  };

  const closeUploadListDialog = () => {
    if (uploadListDialogRef.current) uploadListDialogRef.current.close();
  };

  const [showCheckoutSuccessModal, setShowCheckoutSuccessModal] =
    useState(false);
  const user = useSelector(getUser);
  const [showInvestmentCriteria, setShowInvestmentCriteria] = useState(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const tier = user.tier as Tier;

  useEffect(() => {
    if (window.location.search.includes("checkout_success=true")) {
      setShowCheckoutSuccessModal(true);
    }
  }, [window.location.search]);

  useEffect(() => {
    if (state?.showInvestmentCriteria) {
      setShowInvestmentCriteria(true);
    }
  }, [state]);

  const { data: limitQueryData, loading: trialStatusLoading } = useQuery<{
    me: {
      company: {
        aiaTrial: {
          isOver: boolean;
          totalUploads: number;
          trialDaysLeft: number;
        };
      };
    };
  }>(AIA_TRIAL_STATUS, {
    fetchPolicy: "network-only",
  });


  const aiaRates = useAiaRateLimits(user.id, AIA_FEATURE_LIMITS);

  const investmentCriteriaRates =
    aiaRates?.formattedAiaRates &&
    aiaRates?.formattedAiaRates["INVESTMENT_CRITERIA"];

  const isGrantedInvestmentCriteria = Boolean(investmentCriteriaRates?.limit);

  const openCriteriaDialog = () => {
    if (!isGrantedInvestmentCriteria) {
      return setShowUpgradePlanModal(true);
    }
    if (criteriaDialogRef.current) criteriaDialogRef.current.showModal();
  };

  if (trialStatusLoading || aiaRates?.isRatesLoading) {
    return <Loading fullPage />;
  }

  if (tier === Tier.Pending) {
    navigate("/plans");
  }

  return (
    <>
      <div className="flex font-lato flex-col items-center px-2 md:min-h-full bg-white">
        <div className="flex flex-col items-center justify-center  mx-6 lg:max-w-[900px] w-full lg:justify-start">
          <AiaHeader
            tier={tier}
            showInvestmentCriteria={showInvestmentCriteria}
            isGrantedInvestmentCriteria={isGrantedInvestmentCriteria}
            handleOpenInvestmentCriteria={() => setShowInvestmentCriteria(true)}
            handleCloseInvestmentCriteria={() =>
              setShowInvestmentCriteria(false)
            }
          />

          <div className="w-full mb-3 md:mb-16 flex">
            <div className="flex w-full px-28 gap-6 mx-auto">
              <div className="w-1/2">
                <PitchDeckPdf />
              </div>
              <div className="w-1/2">
                <WebUrl />
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col bg-[#F7F7F7]  w-full h-[350px] rounded-2xl mx-auto gap-6 md:gap-12">
            <div className="flex justify-center items-center w-full md:w-1/2">
              <img
                src={pitchDeckImgUrl}
                className="w-2/3 object-contain"
                alt="Pitch Deck"
              />
            </div>
            <div className="flex items-center justify-center md:flex-col mb-2 gap-4 px-2 sm:px-6 md:px-6 w-full md:w-2/5">
              <Button
                onClick={openUploadListDialog}
                className="flex w-full bg-black border-2 border-black justify-center text-white p-2 rounded-3xl hover:opacity-90"
              >
                <span className="font-bold text-md">Upload company list (csv)</span>
              </Button>
              <Button
                onClick={openCriteriaDialog}
                className="flex w-full bg-aqua-200  justify-center text-black p-2 rounded-3xl hover:opacity-90"
              >
                <span className="font-bold text-md"> Customize investment criteria</span>
              </Button>
            </div>
          </div>
        </div>

        <AiaCheckoutSuccess
          isShown={showCheckoutSuccessModal}
          close={() => {
            setShowCheckoutSuccessModal(false);
            navigate("/aia");
          }}
        />
      </div>
      <dialog
        id="criteria-modal"
        className="w-1/2 rounded-lg"
        ref={criteriaDialogRef}
      >
        <InvestmentCriteria
          handleCloseInvestmentCriteria={closeCriteriaDialog}
        />
      </dialog>
      <dialog
        id="company-list-modal"
        className="w-1/2 rounded-lg"
        ref={uploadListDialogRef}
      >
        <UploadStartupList
          openCriteriaDialog={openCriteriaDialog}
          closeDialog={closeUploadListDialog}
        />
      </dialog>
      <UpgradePlanModal
        isOpen={showUpgradePlanModal}
        onClose={() => setShowUpgradePlanModal(false)}
      />
    </>
  );
};

export default Aia;
