// @ts-nocheck
import tw, { styled, css } from "twin.macro";
import { motion } from "framer-motion";
import media from "styled-media-query";

export const ErrorContainer = styled(motion.div)(({ background }) => [
  tw`w-full h-screen flex items-center justify-center`,
  css`
    height: calc(100vh - 81px);
    ${background &&
    css`
      background: url(${background});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `}
  `,
]);

export const ContentSection = styled(motion.div)(() => [
  tw`bg-white lg:rounded-lg overflow-hidden p-8 z-10 relative text-center`,
  css`
    width: 450px;
    ${media.lessThan("500")`
            width: 100%;
        `}
    p {
      span {
        color: #18beb3;
      }
    }
    .refresh-btn {
      color: #18beb3;
    }
  `,
]);

export const UnavailableLink = styled(motion.div)(() => [
  tw`w-full h-screen flex items-center justify-center bg-white`,
  css`
    height: calc(100vh - 81px);
    .circle-icon {
      background: #fff3f0;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 20px auto;
      svg {
        height: 45px;
      }
    }
  `,
]);
