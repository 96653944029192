import { moduleTypes } from "core/modules";

const module = [
  {
    path: "/board/public/:slug",
    key: "publicBoard",
    componentId: "publicBoard",
    component: import(/* webpackChunkName: "publicStartupsBoard" */ "./PublicStartupBoard"),
    routes: [],
    module: moduleTypes.PUBLIC_STARTUP_BOARD,
    isEnabled: () => true,
    importReducer: () => [],
    importSagas: () => [],
    title: "Board",
    exact: true,
    name: "Board",
  },
];

export default module;
