import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";
import cn from "classnames";

interface Props {
  advisors: any[];
  isLoading?: boolean;
}

const Advisors: FC<Props> = ({ advisors, isLoading }) => {
  const advisorsWithExperience = advisors?.filter(
    (investor) => investor.experience?.length > 0
  );
  const advisorsWithoutExperience = advisors?.filter(
    (investor) =>
      investor.experience === null || investor.experience?.length === 0
  );

  return (
    <div className="mx-auto">
      <SectionHeader title="Advisors" />
      <div className="-mt-4">
        {isLoading ? (
          <div className="grid grid-cols-2 gap-4 w-full mb-2">
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
            <div className="p-1">
              <Skeleton count={0.3} />
              <Skeleton count={3} />
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full my-6">
            {advisorsWithExperience?.map((advisors, index) => (
              <div
                className="flex flex-col border border-gray-400 rounded-lg p-4"
                key={advisors?.name}
              >
                <div className="flex flex-row">
                  <div className="mr-2 rounded-full w-14 h-14 my-auto">
                    <img
                      className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full my-auto"
                      src={
                        advisors?.image?.large?.location
                          ? advisors?.image?.large?.location
                          : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                              ((index + 1) % 6) + 1
                            }.png`
                      }
                      alt={`member-${index}`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h5 className="text-lg font-bold capitalize">
                      {advisors?.name}
                    </h5>
                    {advisors?.linkedin && (
                      <a
                        type="linkedin"
                        href={
                          advisors?.linkedin.includes("https")
                            ? advisors?.linkedin
                            : `https://${advisors?.linkedin}`
                        }
                        target="_blank"
                        className="h-5 w-5 rounded-full bg-blue-600 text-white flex items-center justify-center"
                      >
                        <FontAwesomeIcon icon={["fab", "linkedin"]} size="sm" />
                      </a>
                    )}
                  </div>
                </div>
                <div
                  className={cn(
                    "aiaProfile",
                    "whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-sm text-gray-700"
                  )}
                >
                  {advisors?.experience && advisors?.experience.length > 0 && (
                    <>
                      <p className="text-sm font-bold !text-[#24272B] mt-2">
                        Experience:{" "}
                      </p>
                      <div className="flex flex-col">
                        {advisors?.experience?.map(
                          (experience: any, index: number) => (
                            <div key={index}>
                              {experience?.company && (
                                <ul>
                                  <li className="text-sm text-gray-700">
                                    {experience?.title
                                      ? experience?.title + " at "
                                      : ""}
                                    {experience?.company}
                                  </li>
                                </ul>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {advisorsWithoutExperience?.length > 0 && (
          <div className="flex flex-row overflow-x-scroll">
            {advisorsWithoutExperience.map((advisors, index) => (
              <div className="mr-6 flex flex-row">
                <div className="mr-2 rounded-full w-14 h-14 my-auto">
                  <img
                    className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full my-auto"
                    src={
                      advisors?.image?.large?.location
                        ? advisors?.image?.large?.location
                        : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                            ((index + 1) % 6) + 1
                          }.png`
                    }
                    alt={`member-${index}`}
                  />
                </div>
                <div className="flex flex-col justify-center">
                  <h5 className="text-lg font-bold capitalize">
                    {advisors?.name}
                  </h5>
                  {advisors?.linkedin && (
                    <a
                      type="linkedin"
                      href={
                        advisors?.linkedin.includes("https")
                          ? advisors?.linkedin
                          : `https://${advisors?.linkedin}`
                      }
                      target="_blank"
                      className="h-5 w-5 rounded-full bg-blue-600 text-white flex items-center justify-center"
                    >
                      <FontAwesomeIcon icon={["fab", "linkedin"]} size="sm" />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Advisors;
