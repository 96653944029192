export const PASSWORD_RULES = [
  [/[$&+,:;=?@#|'<>.^*()%!-]/, "Special character"],
  [/[0-9]/, "At least one number"],
  [/.{8,}/, "At least 8 characters"],
] as const;

export const getEmailFromRegister = () => {
  if (
    window.localStorage.getItem("preserve_email") === "1" &&
    window.localStorage.getItem("email")
  ) {
    const email = window.localStorage.getItem("email");

    window.localStorage.removeItem("preserve_email");
    window.localStorage.removeItem("email");

    return email;
  }

  return "";
};
