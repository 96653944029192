import { FC } from "react";
import { PercentageBarProps, RatingListProps } from "./interfaces";

const RATINGS = [1, 2, 3, 4, 5];

const PercentageBar: FC<PercentageBarProps> = ({ percentage, rating }) => {
  return (
    <div className="flex flex-row items-center my-1 text-center">
      <p>{rating}</p>
      <div className="flex flex-row mx-3">
        <div className="relative items-center w-40 h-4 py-1 bg-gray-300 rounded-md">
          <div
            className="h-full bg-yellow-400 rounded-md"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
      <p>{percentage}%</p>
    </div>
  );
};

const RatingList: FC<RatingListProps> = ({ histogram }) => {
  const ratingCount: number[] = [];

  const totalCount =
    histogram?.reduce((acc, value, index) => {
      ratingCount[index] = value ?? 0;
      return (acc ?? 0) + (value ?? 0);
    }, 0) || 0;

  const percentageArray = ratingCount.map((count) =>
    totalCount > 0 ? (count / totalCount) * 100 : 0
  );

  return (
    <div className="flex flex-col-reverse mt-6 lg:ml-0">
      {RATINGS.map((rating, index) => (
        <PercentageBar
          key={index}
          rating={rating}
          percentage={Math.round(percentageArray[index]) || 0}
        />
      ))}
    </div>
  );
};

export default RatingList;
