import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSelect from "atoms/Form/FileSelect";
import { FileWithId } from "./ContactSupport";

interface Props {
  onFileChange: (file: File) => void;
  onFileRemove: (id: string) => void;
  loading: boolean;
  files: FileWithId[];
}

const Attachments: FC<Props> = ({
  onFileChange,
  onFileRemove,
  loading,
  files,
}) => (
  <div>
    <FileSelect
      multiple
      className="bg-white"
      type="imageOrDocument"
      imageDesc="Drop your files here or "
      disabled={loading}
      onChange={onFileChange}
    />
    <div className="mt-2">
      {files.map((file) => (
        <div
          key={file.id}
          className="flex items-center justify-between p-2 mb-2 text-gray-600 border border-gray-300 rounded-md"
        >
          <FontAwesomeIcon icon={["fas", "file"]} />
          <span className="ml-2">{file.name}</span>

          <div
            title="Remove this document"
            className="inline-block text-red-400 cursor-pointer"
            onClick={() => onFileRemove(file.id)}
          >
            <FontAwesomeIcon icon={["fas", "trash"]} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Attachments;
