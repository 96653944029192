// @ts-nocheck
import { moduleTypes } from "core/modules";
import { isLoggedinUser } from "models/user/selectors";
import CompanySettingSlice from "./reducer";
import CompanySettingsSagas from "./sagas";

import ProfileStepSaga from "./steps/ProfileStep/sagas";
import BasicStepSaga from "./steps/BasicStep/sagas";
import TeamsSaga from "./steps/TeamsStep/sagas";
import ElevatorPitchSaga from "./steps/ElevatorPitchStep/sagas";
import ProductSaga from "./steps/ProductStep/sagas";
import MarketSaga from "./steps/MarketStep/sagas";
import BusinessModelSaga from "./steps/BusinessModelStep/sagas";
import Timing from "./steps/TimingStep/sagas";
import ImpactSaga from "./steps/ImpactStep/sagas";
import TractionSaga from "./steps/TractionStep/sagas";
import PitchDeckSaga from "./steps/PitchDeckStep/sagas";
import SocialNetworksSaga from "./steps/SocialNetworksStep/sagas";
import FundrasingSaga from "./steps/FundraisingStep/sagas";

const module = [
  {
    module: moduleTypes.COMPANY_SETTINGS,
    // path: "/company/settings",
    path: "/edit-startup-profile",
    componentId: "companySettings",
    component: import(
      /* webpackChunkName: "companySettings" */ "./CompanySettings"
    ),
    routes: [],
    importReducer: () => [CompanySettingSlice],
    importSagas: () => [
      ProfileStepSaga,
      BasicStepSaga,
      TeamsSaga,
      ElevatorPitchSaga,
      FundrasingSaga,
      PitchDeckSaga,
      SocialNetworksSaga,
      ImpactSaga,
      TractionSaga,
      ProductSaga,
      MarketSaga,
      BusinessModelSaga,
      Timing,
      CompanySettingsSagas,
    ],
    isEnabled: (state: any) => isLoggedinUser(state),
    title: "Company Settigs Page",
    exact: true,
    showHeader: true,
    name: "Company Settings",
  },
];

export default module;
