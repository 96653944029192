import type { SignUpType, TypeStepProps } from "./interfaces";
import { FC, useEffect, useRef, useState } from "react";
import { ReactComponent as StartupIcon } from "images/onboard/startup.svg";
import { ReactComponent as InvestorIcon } from "images/onboard/investor.svg";
import { ReactComponent as AcceleratorIcon } from "images/onboard/accelerator.svg";
import IntroCard from "./IntroCard";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

const TypeStep: FC<TypeStepProps> = ({ show, onNext, onFocusType }) => {
  const [activeType, setActiveType] = useState<SignUpType>("startup");
  const timeout = useRef(0);

  const handleFocus = (type: typeof activeType) => {
    window.clearTimeout(timeout.current);
    setActiveType(type);
    onFocusType(type);
  };

  const handleBlur = () => {
    onFocusType(null);
  };

  const getHandleSelect = (type: SignUpType) => () => {
    onNext({ type });

    window.dataLayer?.push({
      event: "Pageview",
      "Page Path": "/signup start",
      "Data Layer": `from ${type} portal`,
    });
  };

  // To mimic selecting an investor type
  useEffect(() => {
    getHandleSelect("investor")();
  }, []);

  return null;
  /*
  return (
    <Transition
      show={show}
      className="relative z-20 flex flex-col items-center justify-center w-full h-full"
      leave="duration-300 transition"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 translate-y-12"
    >
      <div className="text-xl text-center text-gray-400 hide-on-small-height">
        <div>We use data to connect right investors</div>
        <div>with right founders</div>
      </div>
      <div className="text-2xl text-center mt-28 md:mt-10">
        <strong>Who are you?</strong>
      </div>
      <div className="mt-1 mb-10">
        Or{" "}
        <Link to="/login" className="text-aqua-500 hover:text-aqua-400">
          login
        </Link>{" "}
        if you're already with us
      </div>
      <div className="flex flex-wrap px-4 mb-12 md:flex-nowrap">
        <div
          className="w-full md:w-[288px]"
          onFocus={() => handleFocus("startup")}
          onBlur={handleBlur}
          onMouseEnter={() => handleFocus("startup")}
          onMouseLeave={handleBlur}
        >
          <IntroCard
            onClick={getHandleSelect("startup")}
            title="A startup"
            description="Track how investors interact with your teaser"
          >
            <StartupIcon />
          </IntroCard>
        </div>
        <div
          className="mx-0 my-4 md:my-0 md:mx-4 w-full md:w-[288px]"
          onFocus={() => handleFocus("investor")}
          onBlur={handleBlur}
          onMouseEnter={() => handleFocus("investor")}
          onMouseLeave={handleBlur}
        >
          <IntroCard
            onClick={getHandleSelect("investor")}
            title="An investor"
            description="Duplicate the powers of your investment team"
          >
            <InvestorIcon />
          </IntroCard>
        </div>
        <div
          className="w-full md:w-[288px]"
          onFocus={() => handleFocus("accelerator")}
          onBlur={handleBlur}
          onMouseEnter={() => handleFocus("accelerator")}
          onMouseLeave={handleBlur}
        >
          <IntroCard
            onClick={getHandleSelect("accelerator")}
            title="An accelerator"
            description="Select the right startups for your program"
          >
            <AcceleratorIcon />
          </IntroCard>
        </div>
      </div>
    </Transition>
  );
  */
};

export default TypeStep;
