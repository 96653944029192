import { FC, useState } from "react";
import { SimpleInputProps } from "./SimpleInput.interfaces";

const SimpleInput: FC<SimpleInputProps> = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  icon,
  defaultValue,
  error,
}) => {
  const isParentControlled = typeof onChange !== "undefined";
  const [ownValue, setOwnValue] = useState(defaultValue || "");
  const finalValue = isParentControlled ? value || "" : ownValue;

  const finalHandleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    isParentControlled ? onChange(target.value) : setOwnValue(target.value);

  const containerClassName = [
    "flex w-full items-center relative border rounded pt-1 pb-1 mt-6",
    error
      ? "border-red-300 text-red-900 border-opacity-1"
      : "text-black border-black border-opacity-25",
  ].join(" ");

  return (
    <div>
      <div className={containerClassName}>
        {icon && <div className="px-2 svg-icon">{icon}</div>}
        <input
          className="w-full p-2 pl-0 text-sm text-gray-700 bg-transparent rounded outline-none transition-all duration-200 ease-in-out"
          id={name}
          name={name}
          type={type}
          onChange={finalHandleChange}
          value={finalValue}
          placeholder={placeholder}
          style={{ outline: 0, paddingLeft: 0, color: "#16192F" }}
        />
      </div>
      {error && <p className="mt-2 mb-3 text-sm text-fire-400">{error}</p>}
    </div>
  );
};

export default SimpleInput;
