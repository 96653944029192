import { FC } from "react";
import PlanToggleButton from "./PlanToggleButton";
import { usePlanContext } from "pages/plans/context/planContext";

const PlanToggle: FC = () => {
  const { billingFrequencies, selectedFrequency, toggleBillingFrequency } =
    usePlanContext();

  return (
    <div className="flex bg-white rounded-full w-fit h-11 ">
      {billingFrequencies.map((bf) => (
        <PlanToggleButton
          billingFrequency={bf}
          isSelected={selectedFrequency === bf}
          toggle={() => toggleBillingFrequency(bf)}
        />
      ))}
    </div>
  );
};

export default PlanToggle;
