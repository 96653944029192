import { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Board } from "../interfaces";
import { useStartupsBoard } from "../hooks";

import Header from "./components/BoardsPageHeader";
import NewBoard from "./components/BoardNew";
import Loading from "atoms/Loading/Loading";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import { GetDealflowRecords } from "pages/dealflow/InvestorDealflow/interfaces";
import DealFlowEmptyWarning from "./components/DealflowEmptyWarning";
import BoardItem from "./components/BoardItem";
import { GET_DEALFLOW_RECORDS } from "queries/investor/investorDealflow";
import BoardsActivationWrapper from "organisms/Activation/Borads/BoardsActivationWrapper";

const StartupsBoard = () => {
  const { data: companiesData } = useQuery<GetDealflowRecords>(
    GET_DEALFLOW_RECORDS,
    {
      variables: {},
    }
  );
  const { startupBoards, isBoardsLoading, companyId, isOnProPlan, refetch } =
    useStartupsBoard();
  const [searchTerm, setSearchTerm] = useState("");
  const [isNewBoardPanelOpen, setIsNewBoardPanelOpen] = useState(false);
  const [currentBoardToBeShared, setCurrentBoardToBeShared] = useState<Board>();

  const filteredStartups = useMemo(() => {
    if (!searchTerm) return startupBoards;

    return startupBoards?.filter((board) =>
      board?.title?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, startupBoards]);

  const isDealFlowEmpty = useMemo(
    () => !(!companiesData || companiesData?.dealflow.records.total),
    [companiesData]
  );

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="w-full bg-white"
    >
      <div className="pt-6 md:px-6">
        <Header
          companyId={companyId}
          boardsLength={startupBoards?.length || 0}
          searchTerm={searchTerm}
          onSearchTermChange={(val) => setSearchTerm(val)}
          isOnProPlan={isOnProPlan}
          isDealFlowEmpty={isDealFlowEmpty}
        />

        {!filteredStartups?.length && searchTerm && (
          <div className="text-gray-500">
            No boards found with for the given search term
          </div>
        )}

        {isBoardsLoading && <Loading />}

        <div className="flex flex-col flex-wrap mb-12 md:flex-row">
          {!searchTerm && (
            <div
              onClick={() => {
                if (isDealFlowEmpty) return;
                setIsNewBoardPanelOpen(true);
              }}
              className={cn(
                "bg-[#f2f2f554] py-16 px-11 rounded-lg border-dashed border-gray-500 border-2 text-center mb-4 md:w-72 md:h-80 md:mr-4",
                isDealFlowEmpty ? "cursor-default" : "cursor-pointer"
              )}
            >
              <h2
                className={cn(
                  "mb-4",
                  isDealFlowEmpty ? "text-[#979797]" : "text-black"
                )}
              >
                Create a new Board
              </h2>

              <p className="mb-8 text-sm text-black">
                Click here to create a new board and share intresting startups
                with others in your network
              </p>

              <span
                className={cn(
                  "w-[45px] h-[45px] rounded-sm flex justify-center items-center mx-auto",
                  isDealFlowEmpty ? "bg-[#C4C4C4]" : "bg-[#0077D1]"
                )}
              >
                <FontAwesomeIcon
                  icon={["fas", "plus"]}
                  className="text-3xl text-white"
                />
              </span>
            </div>
          )}

          {filteredStartups?.map((board) => (
            <BoardItem
              board={board as any}
              onShareBoardClick={(board) => {
                setCurrentBoardToBeShared(board);
              }}
              key={board?.id}
              isOnProPlan={isOnProPlan}
            />
          ))}

          {/* Temporarily disabling the empty items as it needs more complex calculations based on the number of items rendered on the first row */}
          {/* {startupBoards.length + COUNT_OF_CREATE_NEW_BOARD_CELL <
          COUNT_OF_BOARD_CELLS &&
          !searchTerm &&
          Array.from(
            {
              length:
                COUNT_OF_BOARD_CELLS -
                startupBoards.length -
                COUNT_OF_CREATE_NEW_BOARD_CELL,
            },
            (_, index) => <EmptyBoardItem key={index} />
          )} */}
        </div>

        <NewBoard
          isOpen={isNewBoardPanelOpen}
          onClose={() => setIsNewBoardPanelOpen(false)}
          rerenderBoard={refetch}
        />

        {isDealFlowEmpty && <DealFlowEmptyWarning />}

        {currentBoardToBeShared && (
          <PublicShare
            title={currentBoardToBeShared.title}
            publicUrl={`${window.location.origin}/board/public/${currentBoardToBeShared.slug}`}
            onClose={() => setCurrentBoardToBeShared(undefined)}
          />
        )}
      </div>
      {/* Disabling activation for investors */}
      {/* <BoardsActivationWrapper /> */}
    </Page>
  );
};

export default StartupsBoard;
