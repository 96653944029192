import { FC } from "react";
import { generateNodeFetchCode } from "../utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NodeFetchProps {
  apiKey: string;
  externalId: string;
  setApiKey: (apiKey: string) => void;
  setExternalId: (externalId: string) => void;
  onCopy: () => void;
}

const NodeFetch: FC<NodeFetchProps> = ({
  onCopy,
  apiKey,
  externalId,
  setApiKey,
  setExternalId,
}) => {
  return (
    <div>
      <div className="w-full h-5 rounded-t-md p-2 mt-4"></div>
      <code className="relative flex flex-col">
        <span className="text-sm">
          import axios from "axios";
          <CopyToClipboard
            onCopy={onCopy}
            text={generateNodeFetchCode(apiKey, externalId)}
          >
            <FontAwesomeIcon
              icon={["fal", "copy"]}
              size="lg"
              className="cursor-pointer text-aqua-500 float-right"
            />
          </CopyToClipboard>
          <br /> <br />
        </span>
        <span>
          const apiKey =
          <input
            className="border border-gray-200 rounded-md px-2 py-1 ml-2 placeholder:italic"
            placeholder="'YOUR_API_KEY'"
            value={apiKey}
            onChange={(event) => setApiKey(event.target.value)}
          />
        </span>
        <span>
          const externalId =
          <input
            className="border border-gray-200 rounded-md px-2 py-1 ml-2 placeholder:italic"
            placeholder="'YOUR_EXTERNAL_ID'"
            value={externalId}
            onChange={(event) => setExternalId(event.target.value)}
          />
        </span>
        <br />
        <pre className="whitespace-pre-wrap">
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            const headers ={`{'Authorization': 'Bearer' +`}{" "}
            <span className="font-bold"> apiKey</span> {`}`}
          </span>
          <br />
          <br />
          <code>
            {`axios.get(${process.env.REACT_APP_REST_URL}/ai/profiles/`}
            <span className="text-ellipsis font-bold">externalId</span>,{" "}
            {`{
    headers: headers,
  }).then((response) => {
    console.log(response.data);
  }).catch((error) => {
    console.log(error);
  });`}
          </code>
        </pre>
      </code>
    </div>
  );
};

export default NodeFetch;
