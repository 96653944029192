import { FC } from "react";

interface AppScreenShotsProps {
  screenshots: any[];
}

const AppScreenShots: FC<AppScreenShotsProps> = ({ screenshots }) => {
  return (
    <>
      <div className="mx-auto px-6 container my-6 md:block hidden">
        <div className="flex justify-center">
          {screenshots.map((img: string) => (
            <div className="md:-ml-20 -ml-12" key={img}>
              <img
                src={img}
                className="h-52 w-full md:h-96 md:hover:h-[700px] transform md:hover:scale-105 ease-in-out duration-1000 rounded-lg shadow-2xl"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mx-auto my-4 flex md:hidden">
        <div className="flex justify-center">
          <div className="overflow-x-auto whitespace-nowrap no-scrollbar invisible-scroll p-2">
            {screenshots.map((img: string) => (
              <img
                key={img}
                src={img}
                className="h-52 -mr-4 md:hover:h-[700px] transform md:hover:scale-105 ease-in-out duration-1000 rounded-lg shadow-md inline-block"
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppScreenShots;
