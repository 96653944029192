import { UserProfileIcon } from "organisms/NavigationLayout/NavigationLayout.style";
import { useSelector } from "react-redux";
import { getUser } from "models/user/selectors";
import { Link } from "react-router-dom";

const UserProfileIconComponent = () => {
  const user = useSelector(getUser);

  const logo = user?.logo?.large?.location || user?.image?.large?.location;

  return (
    <UserProfileIcon>
      <div className="relative z-30 flex items-center justify-center">
        <Link
          to="/profile"
          className="flex items-center max-w-xs px-2 py-2 text-sm text-white rounded-full focus:outline-none focus:shadow-solid hover:bg-gray-100"
          id="user-menu"
          aria-label="User menu"
          aria-haspopup="true"
        >
          {logo && (
            <img
              className="object-cover w-10 h-10 rounded-full"
              src={logo}
              alt=""
            />
          )}
          {!logo && (
            <span className="inline-flex items-center justify-center object-cover w-10 h-10 bg-gray-500 rounded-full">
              <span className="font-medium leading-none text-white capitalize">
                {user?.displayName?.charAt(0)}
              </span>
            </span>
          )}
        </Link>
      </div>
    </UserProfileIcon>
  );
};
export default UserProfileIconComponent;
