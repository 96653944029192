// @ts-nocheck
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { useDispatch, useSelector } from "react-redux";
import { getTraction } from "./selectors";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import Button from "atoms/Button/Button";
import { goToPreviousStep } from "../../reducer";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { getCurrencyList } from "models/user/selectors";
import { saveTraction, updateTraction } from "./sagaActions";
import SingleSelect from "molecules/Form/SingleSelect";
import { ReactComponent as TractionIcon } from "images/traction-icon.svg";
import { ReactComponent as PercentageIcon } from "images/percentage-icon.svg";
import TractionDropdown from "./TractionDropdown";
import TractionNote from "./TractionNote";
import TractionNoteButton from "./TractionNoteButton";
import {
  getCompanyCurrency,
  getCompanyStage,
} from "pages/companyProfile/selectors";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import cn from "classnames";
import {
  AMOUNT_PATTERN,
  DEMANDFREQUNITS,
  PERIODICITY,
  PERRATEPERIOD,
  CHURNRATEPERIODS,
  GROWTHPERRATEPERIOD,
  SUPPLYFREQUNITS,
  TIME,
  USERS,
  GROWTHTIME,
} from "core/consts";

export type FieldValues = {
  _id: string;
  summary: string;
  activeUsers: {
    amount: string;
    frequency: string;
  };
  activeUsersNote: string;
  totalUsers: {
    type: string;
    count: string;
  };
  totalUsersNote: string;
  growthRate: {
    period: string;
    rate: string;
    time: string;
    amount: string;
  };
  growthRateNote: string;
  retentionRate: {
    period: string;
    amount: string;
    rate: string;
    time: string;
  };
  retentionRateNote: string;
  churnRate: {
    period: string;
    percentage: string;
  };
  churnRateNote: string;
  netRevenue: {
    time: string;
    amount: string;
    currency: string;
  };
  netRevenueNote: string;
  recurringRevenue: {
    time: string;
    amount: string;
    currency: string;
  };
  recurringRevenueNote: string;

  revenueRate: {
    rate: string;
    period: string;
    amount: string;
    time: string;
  };
  revenueRateNote: string;
  smv_gsv: {
    time: string;
    amount: string;
    currency: string;
  };
  smv_gsvNote: string;
  salePriceAvg: {
    amount: string;
    currency: string;
  };
  salePriceAvgNote: string;
  ratePercentage: string;
  ratePercentageNote: string;
  cacPayback: {
    amount: string;
    frequency: string;
  };
  cacPaybackNote: string;
  ltcCacRatio: string;
  ltcCacRatioNote: string;
  demandFreq: {
    unit: string;
    amount: string;
    frequency: string;
  };
  demandFreqNote: string;
  supplyFreq: {
    unit: string;
    amount: string;
    frequency: string;
  };
  supplyFreqNote: string;
  signedContracts: string;
  signedContractsNote: string;
  annualValueContracts: {
    amount: string;
    currency: string;
  };
  annualValueContractsNote: string;
  pipelineContracts: string;
  pipelineContractsNote: string;
  annualValueContractsPipeline: {
    amount: string;
    currency: string;
  };
  annualValueContractsPipelineNote: string;
  lettersOfIntent: string;
  lettersOfIntentNote: string;
  additionalTraction: string;
};

type TractionNoteType = keyof Pick<
  FieldValues,
  | "activeUsersNote"
  | "totalUsersNote"
  | "growthRateNote"
  | "retentionRateNote"
  | "churnRateNote"
  | "netRevenueNote"
  | "recurringRevenueNote"
  | "revenueRateNote"
  | "smv_gsvNote"
  | "salePriceAvgNote"
  | "ratePercentageNote"
  | "cacPaybackNote"
  | "ltcCacRatioNote"
  | "demandFreqNote"
  | "supplyFreqNote"
  | "signedContractsNote"
  | "annualValueContractsNote"
  | "pipelineContractsNote"
  | "annualValueContractsPipelineNote"
  | "lettersOfIntentNote"
>;

type Currency = { _id: string; code: string; isDefault: boolean };

export default () => {
  const tractionData: FieldValues & { _id: string } = useSelector(getTraction);

  const companyStage: ("PRE_PRODUCT" | "PRE_REVENUE" | "POST_REVENUE")[] =
    useSelector(getCompanyStage);

  const companyCurrencyId: string = useSelector(getCompanyCurrency);

  const currencyData: Currency[] = useSelector((state) =>
    getCurrencyList(state)
  );

  const {
    register,
    handleSubmit,
    getValues,
    control,
    resetField,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({ defaultValues: tractionData });
  const [companyCurrency, setCompanyCurrency] = useState<Currency | null>(null);
  const { lang } = useLang();
  const dispatch = useDispatch();

  const loading = useSelector((state) =>
    isLoadingEnabled(state, STEP.TRACTION)
  );

  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const [tractionNoteShown, setTractionNoteShown] = useState<
    TractionNoteType[]
  >([]);

  const getDefaultCurrency = () =>
    currencyData.find((currency) => currency.isDefault) as Currency;

  const getCurrencyById = (id: string): Currency | null => {
    const currency = currencyData.find((currency) => currency._id === id);
    if (currency) {
      return currency;
    }
    return null;
  };

  useEffect(() => {
    if (!companyCurrencyId) {
      // use default currency
      const defaultCurrency = getDefaultCurrency();
      return setCompanyCurrency(defaultCurrency);
    }

    setCompanyCurrency(getCurrencyById(companyCurrencyId));
  }, [currencyData, companyCurrencyId]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (tractionData._id) {
      dispatch(
        updateTraction({
          lang,
          data,
        })
      );
      return;
    }
    dispatch(
      saveTraction({
        lang,
        data,
      })
    );
  };

  const showTractionNote = (type: TractionNoteType) => {
    setTractionNoteShown([...tractionNoteShown, type]);
  };

  const hideTractionNote = (type: TractionNoteType) => {
    resetField(type, { defaultValue: "" });
    setTractionNoteShown(
      tractionNoteShown.filter((noteType) => noteType !== type)
    );
  };

  const isTractionNoteShown = (type: TractionNoteType): boolean => {
    const fieldValue = watch(type);
    return !!(tractionNoteShown.includes(type) || fieldValue);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <TractionIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              {lang.traction}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>
              {lang.mostExcitingTractionMetricForBusiness} <br />{" "}
              {lang.tractionDescription}
            </p>
          </div>
        </div>
      </PageHeader>
      <div>
        {/************************************************
          //
          // TRACTION SUMMARY
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.tractionSummary}
        </div>

        <TextArea
          rows="3"
          id="summary"
          className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
            errors.summary
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
              : ""
          }`}
          placeholder={lang.tractionSummaryPlaceholder}
          defaultValue={getValues("summary")}
          {...register("summary")}
          maxLength={80}
        ></TextArea>
        {/************************************************
          //
          // ACTIVE USERS
          //
          ************************************************/}
        <div className="mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.activeUsers}
        </div>
        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <TractionDropdown<FieldValues>
              valueControlProps={{
                name: "activeUsers.amount",
                control,
                rules: {
                  pattern: {
                    value: AMOUNT_PATTERN,
                    message: lang.enterOnlyNumericNumbers,
                  },
                },
              }}
              optionsControlProps={{ name: "activeUsers.frequency", control }}
              optionsMap={PERIODICITY}
            />
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("activeUsersNote")}
            showNoteInput={() => showTractionNote("activeUsersNote")}
          />
        </div>
        {isTractionNoteShown("activeUsersNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="activeUsersNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              hideNote={() => hideTractionNote("activeUsersNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // TOTAL USERS
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.totalUsers}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <TractionDropdown<FieldValues>
              valueControlProps={{
                name: "totalUsers.count",
                control,
                rules: {
                  pattern: {
                    value: AMOUNT_PATTERN,
                    message: lang.enterOnlyNumericNumbers,
                  },
                },
              }}
              optionsControlProps={{ name: "totalUsers.type", control }}
              optionsMap={USERS}
            />
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("totalUsersNote")}
            showNoteInput={() => showTractionNote("totalUsersNote")}
          />
        </div>
        {isTractionNoteShown("totalUsersNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="totalUsersNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              hideNote={() => hideTractionNote("totalUsersNote")}
            />
          </div>
        )}
        {/************************************************
          //
          // USER GROWTH RATE
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.userGrowthRate}
        </div>
        <div className="relative flex-none mb-3 gap-2 sm:flex md:flex">
          <div className="w-4/5 sm:w-5/12 __bottom_res">
            <TractionDropdown<FieldValues>
              valueControlProps={{
                name: "growthRate.rate",
                control,
                rules: {
                  pattern: {
                    value: AMOUNT_PATTERN,
                    message: lang.enterOnlyNumericNumbers,
                  },
                },
              }}
              optionsControlProps={{ name: "growthRate.period", control }}
              optionsMap={GROWTHPERRATEPERIOD}
              placeholder={`${lang.enter} %`}
            />
          </div>
          <div className="w-4/5 sm:w-2/5">
            <TractionDropdown<FieldValues>
              valueControlProps={{ name: "growthRate.amount", control }}
              optionsControlProps={{ name: "growthRate.time", control }}
              optionsMap={GROWTHTIME}
            />
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("growthRateNote")}
            showNoteInput={() => showTractionNote("growthRateNote")}
            className="sm:w-1/5"
          />
        </div>
        {isTractionNoteShown("growthRateNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="growthRateNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              hideNote={() => hideTractionNote("growthRateNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // RETENTION RATE
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.retentionRate}
        </div>
        <div className="relative flex-none mb-3 gap-2 sm:flex md:flex">
          <div className="w-4/5 sm:w-5/12 __bottom_res">
            <TractionDropdown<FieldValues>
              valueControlProps={{
                name: "retentionRate.rate",
                control,
                rules: {
                  pattern: {
                    value: AMOUNT_PATTERN,
                    message: lang.enterOnlyNumericNumbers,
                  },
                },
              }}
              optionsControlProps={{ name: "retentionRate.period", control }}
              optionsMap={PERIODICITY}
              placeholder={`${lang.enter} %`}
            />
          </div>
          <div className="w-4/5 sm:w-2/5">
            <TractionDropdown<FieldValues>
              valueControlProps={{
                name: "retentionRate.amount",
                control,
                rules: {
                  pattern: {
                    value: AMOUNT_PATTERN,
                    message: lang.enterOnlyNumericNumbers,
                  },
                },
              }}
              optionsControlProps={{ name: "retentionRate.time", control }}
              optionsMap={TIME}
            />
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("retentionRateNote")}
            showNoteInput={() => showTractionNote("retentionRateNote")}
            className="sm:w-1/5"
          />
        </div>
        {isTractionNoteShown("retentionRateNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="retentionRateNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              hideNote={() => hideTractionNote("retentionRateNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // CHURN RATE
          //
          ************************************************/}

        {companyStage?.[0] !== "PRE_PRODUCT" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.churnRate}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "churnRate.percentage",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{ name: "churnRate.period", control }}
                  optionsMap={CHURNRATEPERIODS}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("churnRateNote")}
                showNoteInput={() => showTractionNote("churnRateNote")}
              />
            </div>
            {isTractionNoteShown("churnRateNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="churnRateNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  hideNote={() => hideTractionNote("churnRateNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // NET REVENUE
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.netRevenue}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "netRevenue.amount",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{ name: "netRevenue.time", control }}
                  icon={
                    <div className="text-sm text-gray-500">
                      {companyCurrency?.code}
                    </div>
                  }
                  optionsMap={PERRATEPERIOD}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("netRevenueNote")}
                showNoteInput={() => showTractionNote("netRevenueNote")}
              />
            </div>
            {isTractionNoteShown("netRevenueNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="netRevenueNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  hideNote={() => hideTractionNote("netRevenueNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // RECURRING REVENUE
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.recurringRevenue}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "recurringRevenue.amount",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{
                    name: "recurringRevenue.time",
                    control,
                  }}
                  icon={
                    <div className="text-sm text-gray-500">
                      {companyCurrency?.code}
                    </div>
                  }
                  optionsMap={PERIODICITY}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown(
                  "recurringRevenueNote"
                )}
                showNoteInput={() => showTractionNote("recurringRevenueNote")}
              />
            </div>
            {isTractionNoteShown("recurringRevenueNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="recurringRevenueNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  hideNote={() => hideTractionNote("recurringRevenueNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // REVENUE GROWTH RATE
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.revenueGrowthRate}
            </div>
            <div className="relative flex-none mb-3 gap-2 sm:flex md:flex">
              <div className="w-4/5 sm:w-5/12 __bottom_res">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "revenueRate.rate",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{ name: "revenueRate.period", control }}
                  optionsMap={GROWTHPERRATEPERIOD}
                />
              </div>
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "revenueRate.amount",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{ name: "revenueRate.time", control }}
                  optionsMap={GROWTHTIME}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("revenueRateNote")}
                showNoteInput={() => showTractionNote("revenueRateNote")}
                className="sm:w-1/5"
              />
            </div>
            {isTractionNoteShown("revenueRateNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="revenueRateNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  hideNote={() => hideTractionNote("revenueRateNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // SMV PER GSV
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.SMVperGSV}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "smv_gsv.amount",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{
                    name: "smv_gsv.time",
                    control,
                  }}
                  icon={
                    <div className="text-sm text-gray-500">
                      {companyCurrency?.code}
                    </div>
                  }
                  optionsMap={PERIODICITY}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("smv_gsvNote")}
                showNoteInput={() => showTractionNote("smv_gsvNote")}
              />
            </div>
            {isTractionNoteShown("smv_gsvNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="smv_gsvNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  hideNote={() => hideTractionNote("smv_gsvNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // AVERAGE SALES PRICE
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.averageSalesPrice}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.salePriceAvg
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <div className="text-sm text-gray-500">
                {companyCurrency?.code}
              </div>
              <input
                id={"salePriceAvg"}
                type="text"
                defaultValue={getValues("ratePercentage")}
                {...register("salePriceAvg.amount", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.salePriceAvg?.amount && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("salePriceAvgNote")}
            showNoteInput={() => showTractionNote("salePriceAvgNote")}
          />
        </div>
        {isTractionNoteShown("salePriceAvgNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="salePriceAvgNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("salePriceAvgNote")}
              hideNote={() => hideTractionNote("salePriceAvgNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // RATE PERCENTAGE
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.rakePercentage}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <div
                  className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                    errors.ratePercentage
                      ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                      : "border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 7 }}
                >
                  <input
                    id={"ratePercentage"}
                    type="text"
                    defaultValue={getValues("ratePercentage")}
                    {...register("ratePercentage", {
                      pattern: AMOUNT_PATTERN,
                    })}
                    style={{ outline: "none", paddingLeft: 12 }}
                  />
                  <div>
                    <PercentageIcon className="relative" />
                  </div>
                </div>
                {errors.ratePercentage && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.enterOnlyNumericNumbers}
                  </p>
                )}
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("ratePercentageNote")}
                showNoteInput={() => showTractionNote("ratePercentageNote")}
              />
            </div>
            {isTractionNoteShown("ratePercentageNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="ratePercentageNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  defaultValue={getValues("ratePercentageNote")}
                  hideNote={() => hideTractionNote("ratePercentageNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // CAC PAYBACK
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.CacPayback}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <TractionDropdown<FieldValues>
                  valueControlProps={{
                    name: "cacPayback.amount",
                    control,
                    rules: {
                      pattern: {
                        value: AMOUNT_PATTERN,
                        message: lang.enterOnlyNumericNumbers,
                      },
                    },
                  }}
                  optionsControlProps={{
                    name: "cacPayback.frequency",
                    control,
                  }}
                  optionsMap={TIME}
                />
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("cacPaybackNote")}
                showNoteInput={() => showTractionNote("cacPaybackNote")}
              />
            </div>
            {isTractionNoteShown("cacPaybackNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="cacPaybackNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  defaultValue={getValues("cacPaybackNote")}
                  hideNote={() => hideTractionNote("cacPaybackNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // LTV CAC RATIO
          //
          ************************************************/}

        {companyStage?.[0] === "POST_REVENUE" && (
          <>
            <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.ltvCacRatio}
            </div>

            <div className="flex mb-3 gap-2">
              <div className="w-4/5 sm:w-2/5">
                <div
                  className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                    errors.ltcCacRatio
                      ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                      : "border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 7 }}
                >
                  <input
                    id={"ltcCacRatio"}
                    type="text"
                    defaultValue={getValues("ltcCacRatio")}
                    {...register("ltcCacRatio", {
                      pattern: AMOUNT_PATTERN,
                    })}
                    style={{ outline: "none", paddingLeft: 12 }}
                  />
                </div>
                {errors.ltcCacRatio && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.enterOnlyNumericNumbers}
                  </p>
                )}
              </div>
              <TractionNoteButton
                isTractionNoteShown={isTractionNoteShown("ltcCacRatioNote")}
                showNoteInput={() => showTractionNote("ltcCacRatioNote")}
              />
            </div>
            {isTractionNoteShown("ltcCacRatioNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="ltcCacRatioNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  defaultValue={getValues("ltcCacRatioNote")}
                  hideNote={() => hideTractionNote("ltcCacRatioNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // DEMAND FREQUENCY
          //
          ************************************************/}

        {companyStage?.[0] !== "PRE_PRODUCT" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.demandFrequency}
            </div>

            <div className="flex mb-3 gap-2 demand">
              <div className="sm:w-1/1 md:w-4/5">
                <div
                  className={`outline-none justify-between py-0.5 pr-2 form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                    errors.demandFreq?.amount
                      ? "border-red-300 text-red-900 placeholder-red-300 border-opacity-1"
                      : "border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 12 }}
                >
                  <input
                    id={`demandFreq`}
                    type="text"
                    defaultValue={getValues("demandFreq.amount")}
                    style={{ outline: "none" }}
                    {...register("demandFreq.amount", {
                      pattern: AMOUNT_PATTERN,
                    })}
                    className="w-3/6"
                  />
                  <div className="flex justify-between sm:w-3/6 md:w-4/7 traction-dropdown new">
                    <Controller
                      control={control}
                      name="demandFreq.unit"
                      render={({ field: { value, onChange } }) => (
                        <SingleSelect
                          id="unit"
                          name="unit"
                          options={Object.values(DEMANDFREQUNITS)}
                          defaultValue={DEMANDFREQUNITS[value]}
                          selectDropdownInput={true}
                          onChange={(val: string) =>
                            onChange(
                              Object.keys(DEMANDFREQUNITS).find(
                                (key) => DEMANDFREQUNITS[key] === val
                              )
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="demandFreq.frequency"
                      render={({ field: { value, onChange } }) => (
                        <SingleSelect
                          id="frequency"
                          name="frequency"
                          options={Object.values(PERRATEPERIOD)}
                          defaultValue={PERRATEPERIOD[value]}
                          selectDropdownInput={true}
                          onChange={(val: string) =>
                            onChange(
                              Object.keys(PERRATEPERIOD).find(
                                (key) => PERRATEPERIOD[key] === val
                              )
                            )
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                {errors.demandFreq?.amount && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.enterOnlyNumericNumbers}
                  </p>
                )}
              </div>
              <div className="flex w-1/5 ml-2 sm:w-1/5">
                <TractionNoteButton
                  isTractionNoteShown={isTractionNoteShown("demandFreqNote")}
                  showNoteInput={() => showTractionNote("demandFreqNote")}
                />
              </div>
            </div>
            {isTractionNoteShown("demandFreqNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="demandFreqNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  defaultValue={getValues("demandFreqNote")}
                  hideNote={() => hideTractionNote("demandFreqNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // SUPPLY FREQUENCY
          //
          ************************************************/}

        {companyStage?.[0] !== "PRE_PRODUCT" && (
          <>
            <div className="mb-2 text-sm font-medium text-black text-opacity-100">
              {lang.supplyFrequency}
            </div>

            <div className="flex mb-3 gap-2 demand">
              <div className="sm:w-1/1 md:w-4/5">
                <div
                  className={`outline-none justify-between py-0.5 pr-2 form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                    errors.supplyFreq?.amount
                      ? "border-red-300 text-red-900 placeholder-red-300 border-opacity-1"
                      : "border-black border-opacity-25"
                  }`}
                  style={{ paddingLeft: 12 }}
                >
                  <input
                    id={`supplyFreq`}
                    type="text"
                    defaultValue={getValues("supplyFreq.amount")}
                    style={{ outline: "none" }}
                    {...register("supplyFreq.amount", {
                      pattern: AMOUNT_PATTERN,
                    })}
                    className="w-3/6"
                  />
                  <div className="flex justify-between sm:w-3/6 md:w-4/7 traction-dropdown new">
                    <Controller
                      control={control}
                      name="supplyFreq.unit"
                      render={({ field: { value, onChange } }) => (
                        <SingleSelect
                          id="unit"
                          name="unit"
                          options={Object.values(SUPPLYFREQUNITS)}
                          defaultValue={SUPPLYFREQUNITS[value]}
                          selectDropdownInput={true}
                          onChange={(val: string) =>
                            onChange(
                              Object.keys(SUPPLYFREQUNITS).find(
                                (key) => SUPPLYFREQUNITS[key] === val
                              )
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="supplyFreq.frequency"
                      render={({ field: { value, onChange } }) => (
                        <SingleSelect
                          id="frequency"
                          name="frequency"
                          options={Object.values(PERRATEPERIOD)}
                          defaultValue={PERRATEPERIOD[value]}
                          selectDropdownInput={true}
                          onChange={(val: string) =>
                            onChange(
                              Object.keys(PERRATEPERIOD).find(
                                (key) => PERRATEPERIOD[key] === val
                              )
                            )
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                {errors.supplyFreq?.amount && (
                  <p className="mt-2 mb-2 text-sm text-fire-400">
                    {lang.enterOnlyNumericNumbers}
                  </p>
                )}
              </div>
              <div className="flex w-1/5 ml-2 sm:w-1/5">
                <TractionNoteButton
                  isTractionNoteShown={isTractionNoteShown("supplyFreqNote")}
                  showNoteInput={() => showTractionNote("supplyFreqNote")}
                />
              </div>
            </div>
            {isTractionNoteShown("supplyFreqNote") && (
              <div className="flex mb-3 gap-2">
                <TractionNote
                  name="supplyFreqNote"
                  register={register}
                  placeholder={lang.optionalNoteToProvideFurtherContect}
                  defaultValue={getValues("supplyFreqNote")}
                  hideNote={() => hideTractionNote("supplyFreqNote")}
                />
              </div>
            )}
          </>
        )}

        {/************************************************
          //
          // NUMBER OF SIGNED CONTRACTS
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {`# ${lang.signedContracts}`}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.signedContracts
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <input
                id={"signedContracts"}
                type="text"
                defaultValue={getValues("signedContracts")}
                {...register("signedContracts", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.signedContracts && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("signedContractsNote")}
            showNoteInput={() => showTractionNote("signedContractsNote")}
          />
        </div>
        {isTractionNoteShown("signedContractsNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="signedContractsNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("signedContractsNote")}
              hideNote={() => hideTractionNote("signedContractsNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // ANNUAL VALUE OF SIGNED CONTRACTS 
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.annualValueOfSignedInContract}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.annualValueContracts
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <div className="text-sm text-gray-500">
                {companyCurrency?.code}
              </div>
              <input
                id={"annualValueContracts"}
                type="text"
                defaultValue={getValues("annualValueContracts.amount")}
                {...register("annualValueContracts.amount", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.salePriceAvg?.amount && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown(
              "annualValueContractsNote"
            )}
            showNoteInput={() => showTractionNote("annualValueContractsNote")}
          />
        </div>
        {isTractionNoteShown("annualValueContractsNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="annualValueContractsNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("annualValueContractsNote")}
              hideNote={() => hideTractionNote("annualValueContractsNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // NUMBER OF CONTRACTS IN THE PIPELINE
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.hashOfContractsInPipeline}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.pipelineContracts
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <input
                id={"pipelineContracts"}
                type="text"
                defaultValue={getValues("pipelineContracts")}
                {...register("pipelineContracts", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.pipelineContracts && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("pipelineContractsNote")}
            showNoteInput={() => showTractionNote("pipelineContractsNote")}
          />
        </div>
        {isTractionNoteShown("pipelineContractsNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="pipelineContractsNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("pipelineContractsNote")}
              hideNote={() => hideTractionNote("pipelineContractsNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // ANNUAL VALUE OF SIGNED IN THE PIPELINE
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.annualValueOfSignedInPipeline}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.annualValueContractsPipeline
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <div className="text-sm text-gray-500">
                {companyCurrency?.code}
              </div>
              <input
                id={"annualValueContractsPipeline"}
                type="text"
                defaultValue={getValues("annualValueContractsPipeline.amount")}
                {...register("annualValueContractsPipeline.amount", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.salePriceAvg?.amount && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown(
              "annualValueContractsPipelineNote"
            )}
            showNoteInput={() =>
              showTractionNote("annualValueContractsPipelineNote")
            }
          />
        </div>
        {isTractionNoteShown("annualValueContractsPipelineNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="annualValueContractsPipelineNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("annualValueContractsPipelineNote")}
              hideNote={() =>
                hideTractionNote("annualValueContractsPipelineNote")
              }
            />
          </div>
        )}
        {/************************************************
          //
          // LETTERS OF INTENTS SIGNED
          //
          ************************************************/}
        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.letterOfIntentSigned}
        </div>

        <div className="flex mb-3 gap-2">
          <div className="w-4/5 sm:w-2/5">
            <div
              className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 border ${
                errors.lettersOfIntent
                  ? "border-opacity-1 border-red-300 text-red-900 placeholder-red-300"
                  : "border-black border-opacity-25"
              }`}
              style={{ paddingLeft: 7 }}
            >
              <input
                id={"lettersOfIntent"}
                type="text"
                defaultValue={getValues("lettersOfIntent")}
                {...register("lettersOfIntent", {
                  pattern: AMOUNT_PATTERN,
                })}
                style={{ outline: "none", paddingLeft: 12 }}
              />
            </div>
            {errors.lettersOfIntent && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.enterOnlyNumericNumbers}
              </p>
            )}
          </div>
          <TractionNoteButton
            isTractionNoteShown={isTractionNoteShown("lettersOfIntentNote")}
            showNoteInput={() => showTractionNote("lettersOfIntentNote")}
          />
        </div>
        {isTractionNoteShown("lettersOfIntentNote") && (
          <div className="flex mb-3 gap-2">
            <TractionNote
              name="lettersOfIntentNote"
              register={register}
              placeholder={lang.optionalNoteToProvideFurtherContect}
              defaultValue={getValues("lettersOfIntentNote")}
              hideNote={() => hideTractionNote("lettersOfIntentNote")}
            />
          </div>
        )}

        {/************************************************
          //
          // ADDITIONAL TRACTION
          //
          ************************************************/}

        <div className="pt-5 mb-2 text-sm font-medium text-black text-opacity-100">
          {lang.additionalTraction}
        </div>

        <TextArea
          rows="3"
          id="additionalTraction"
          className={`form-textarea block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
            errors.additionalTraction
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red"
              : ""
          }`}
          placeholder={lang.addAnyAdditionalTractionMetricsHere}
          defaultValue={getValues("additionalTraction")}
          {...register("additionalTraction")}
          maxLength={300}
        ></TextArea>

        <StepActionButton_Stateful>
          <Button
            size="small"
            color="white"
            className="preview-btn"
            onClick={() => dispatch(goToPreviousStep())}
          >
            <span>{lang.back}</span>
          </Button>
          <Button size="small" color="primary" type="submit" loading={loading}>
            <span>{lang.saveAndContinue}</span>
          </Button>
        </StepActionButton_Stateful>
      </div>
    </form>
  );
};
