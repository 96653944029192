import { gql } from "@apollo/client";

export const GET_DOCUMENTS = gql`
  query getDocuments {
    me {
      _id
      documents {
        id
        title
        mimetype
        filename
        location
      }
    }
  }
`;

export const SAVE_DOCUMENTS = gql`
  mutation addDocument($file: Upload!) {
    addDocument(file: $file) {
      _id
      documents {
        id
        title
        mimetype
        filename
        location
      }
    }
  }
`;

export const REMOVE_DOCUMENT = gql`
  mutation removeDocument($id: ID!) {
    removeDocument(id: $id) {
      _id
    }
  }
`;
