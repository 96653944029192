import Button from "atoms/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getWorkspaces } from "models/user/selectors";
import { useSelector } from "react-redux";
import InvitationModal from "./InvitationModal";
import { useState } from "react";
import TabContainer from "../TabContainer";
import TeamMembersTableData from "./TeamMembersTableData";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import Loading from "atoms/Loading/Loading";

const AIA_FEATURE_LIMITS = ["INVITE_MEMBER"];

const TeamMembers = () => {
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const { openModal } = useUpgradePlanModal();

  const workspaces = useSelector(getWorkspaces);
  const activeWorkspace = workspaces?.find((ws: any) => ws.isActive);

  const { formattedAiaRates, isRatesLoading, refetch } = useAiaRateLimits(
    activeWorkspace.inviter.id,
    AIA_FEATURE_LIMITS
  );

  const invitememberRates =
    formattedAiaRates && formattedAiaRates["INVITE_MEMBER"];

  const isGranted = Boolean(invitememberRates?.limit);

  const onInviteClicked = () => {
    (isGranted && invitememberRates.limit - invitememberRates?.remaining > 0) ||
    invitememberRates?.limit === -1
      ? setIsInvitationModalOpen(true)
      : openModal();
  };

  if (isRatesLoading) {
    return <Loading />;
  }

  const inviteLimit =
    invitememberRates?.limit > 0
      ? `team consists of ${invitememberRates?.remaining}/${invitememberRates?.limit} members.`
      : false;

  return (
    <>
      <TabContainer>
        <div className="flex items-center justify-between px-2 py-4">
          <div>
            <p className="text-xl text-black">Members</p>
            <p className="text-sm">Manage members here.</p>
            <div>
              {inviteLimit && <p className="text-sm">Your {inviteLimit}</p>}
            </div>
          </div>

          <Button color="primary" size="small" onClick={onInviteClicked}>
            <FontAwesomeIcon icon={["fas", "user-plus"]} className="mr-2" />
            <span>Invite</span>
          </Button>
        </div>
        <table className="w-full">
          <thead className="bg-gray-100">
            <tr className="text-xs text-gray-500">
              {/* Uncomment when user data becomes available from the api */}
              {/* <th className="w-1/4 p-2 font-medium text-left">User</th> */}
              <th className="w-2/5 p-2 font-medium text-left sm:w-2/6">
                Email
              </th>
              <th className="hidden w-2/6 p-2 font-medium text-left sm:block">
                Last Active
              </th>
              <th className="w-2/5 p-2 font-medium text-left sm:w-1/6">
                Team Permission
              </th>
              <th className="p-2 font-medium">Remove</th>
            </tr>
          </thead>
          <tbody>
            <TeamMembersTableData
              isCompanyAdmin={isGranted}
              activeWorkspaceId={activeWorkspace?.id}
              refetchRates={refetch}
              companyId={activeWorkspace?.inviter.id}
              features={AIA_FEATURE_LIMITS}
            />
          </tbody>
        </table>
      </TabContainer>
      <InvitationModal
        isOpen={isInvitationModalOpen}
        setIsOpen={setIsInvitationModalOpen}
      />
    </>
  );
};

export default TeamMembers;
