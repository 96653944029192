// @ts-nocheck
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { getCurrencyList, getUser } from "models/user/selectors";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, select, spawn, takeEvery, takeLatest } from "redux-saga/effects";
import { LOADER_COMPANY_BY_SLUG } from "./constants";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { Mixpanel } from "core/mixpanel/MixpanelWrapper";
import {
  GET_COMPANY_BY_SLUG,
  GET_TRACTION,
} from "queries/startup/startupProfile";
import { GET_STARTUP_ANALYSIS } from "queries/aia/analysis";
import { GET_COMPETITORS } from "queries/aia/competitors";
import { GET_UI_REPRESENTATION } from "queries/editAiaProfile/editAiaProfile";

const getTractionData = (id) => {
  let client = getClient();
  return client
    .query({
      query: GET_TRACTION,
      fetchPolicy: "network-only",
      variables: { id },
    })
    .catch((error) => ({ error, isError: true }));
};

const getAnalysisData = (id) => {
  let client = getClient();

  return client
    .query({
      query: GET_STARTUP_ANALYSIS,
      variables: { companyId: id },
    })
    .catch((error) => ({ error, isError: true }));
};

const getCompetitorData = (variables) => {
  let client = getClient();
  return client
    .query({
      query: GET_COMPETITORS,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ error, isError: true }));
};

const getUiRepresentationData = (variables) => {
  let client = getClient();
  return client
    .query({
      query: GET_UI_REPRESENTATION,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ error, isError: true }));
};

const getCompanyBySlug = (variables) => {
  let client = getClient();
  return client
    .mutate({
      mutation: GET_COMPANY_BY_SLUG,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ error, isError: true }));
};

function* updateCurrencyCode(companyDetails) {
  const currencyList = yield select((state) => getCurrencyList(state));

  const defaultCurrency = currencyList?.find((c) => c.isDefault);

  const selectedCurrency = currencyList?.find(
    (c) => c._id === companyDetails?.currency
  );

  if (!defaultCurrency && !selectedCurrency) return;

  yield put(
    slice.setCurrencyDetails({
      currencyDetails: {
        currencyCode: selectedCurrency?.code || defaultCurrency?.code,
      },
    })
  );
}

function* getTractionTask(id) {
  let _id = id;

  // In case of responding to refetchTractionData action, entire action objects will be recieved instead of id
  if (typeof id === "object") {
    _id = id.payload.id;
  }

  const traction = yield getTractionData(_id);
  if (traction.isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not load traction data",
      })
    );
  }

  yield put(
    slice.setTractionDetails({
      tractionDetails: traction?.data?.getCompanyTractionByCompanyId,
    })
  );
}

function* getAnalysisTask(id) {
  let _id = id;

  // In case of responding to refetchAnalysisData action, entire action objects will be recieved instead of id
  if (typeof id === "object") {
    _id = id.payload.id;
  }

  const analysis = yield getAnalysisData(_id);
  if (analysis.isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not load analysis data",
      })
    );
  }

  yield put(
    slice.setAnalysisDetails({
      analysisDetails: analysis?.data?.aia?.analysis,
    })
  );
}

function* getCompetitors(id) {
  let _id = id;

  // In case of responding to refetchCompetitors action, entire action objects will be recieved instead of id
  if (typeof id === "object") {
    _id = id.payload.id;
  }

  const response = yield getCompetitorData({ companyId: _id });

  if (response.isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not load competitors data",
      })
    );
  }

  yield put(
    slice.setCompetitorDetails({
      competitorDetails: response?.data?.aiaCompetitor?.competitors,
    })
  );
}

function* getUiRepresention(id) {
  let _id = id;

  // In case of responding to getUiRepresention action, entire action objects will be recieved instead of id
  if (typeof id === "object") {
    _id = id.payload.id;
  }

  const response = yield getUiRepresentationData({ companyId: _id });

  yield put(
    slice.setUiRepresentationDetails({
      uiRepresentationDetails: response?.data?.getUiRepresentation,
    })
  );
}

function* initializeCompanyProfile({
  payload: { params, lang, isPublicCompanyProfile },
}) {
  yield put(
    toggleLoader({ component: LOADER_COMPANY_BY_SLUG, isLoading: true })
  );
  const user = yield select((state) => getUser(state));
  let variables = params?.slug
    ? { slug: params?.slug }
    : { id: user?._id, slug: user?.slug };

  let { data, error, isError } = yield getCompanyBySlug(variables);

  if (isError) {
    // No need to trigger snack here as lack of data will render <ServerError /> component.
    yield put(
      toggleLoader({ component: LOADER_COMPANY_BY_SLUG, isLoading: false })
    );
    yield put(slice.updateCompanyDetails({ companyDetails: null }));
    return;
  }

  yield put(slice.updateCompanyDetails({ companyDetails: data }));

  yield updateCurrencyCode(data?.companyBySlug);
  yield spawn(getCompetitors, data?.companyBySlug?.id);
  yield spawn(getTractionTask, data?.companyBySlug?._id);
  yield spawn(getAnalysisTask, data?.companyBySlug?.id);
  yield spawn(getUiRepresention, data?.companyBySlug?.id);

  if (!isPublicCompanyProfile) {
    if (data?.companyBySlug?.id !== user?.id) {
      try {
        Mixpanel.track("Company Profile View", {
          profileViewedBy: user?.displayName,
          profileViewed: data?.companyBySlug?.displayName,
        });
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    Mixpanel.track("Public Company Profile View", {
      publicProfileViewed: data?.companyBySlug?.displayName,
    });
  }

  if (isPublicCompanyProfile) {
    yield put({ type: "isPublicProfile" });
  }

  yield put(
    toggleLoader({ component: LOADER_COMPANY_BY_SLUG, isLoading: false })
  );
}

function* refetchCompanyData({ payload }) {
  const variables = {
    slug: payload?.slug,
    id: payload?.id,
    realId: payload?.realId,
  };
  const { data } = yield getCompanyBySlug(variables);
  yield put(slice.updateCompanyDetails({ companyDetails: data }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(
      sagaActions.initializeCompanyProfile,
      initializeCompanyProfile
    ),
    yield takeLatest(sagaActions.refetchCompanyData, refetchCompanyData),
    yield takeLatest(sagaActions.refetchAnalysisData, getAnalysisTask),
    yield takeLatest(sagaActions.refetchTractionData, getTractionTask),
    yield takeLatest(sagaActions.refetchCompetitorsData, getCompetitors),
    yield takeLatest(sagaActions.refetchUiRepresentation, getUiRepresention),
  ];
}
