import type { FC, ReactElement, ReactNode } from "react";
import type { TooltipProps } from "react-tooltip";
import ReactTooltip from "react-tooltip";
import { nanoid } from "nanoid";
import { ToolTip } from "./Tooltip.style";
import cn from "classnames";

interface Props {
  children: ReactNode;
  place?: TooltipProps["place"];
  content: string | ReactElement | ReactElement[];
  extraClass?: string;
  widerClickableArea?: boolean;
  arrowColor?: string;
}

const TooltipWrapper: FC<Props> = ({
  children,
  place = "top",
  content,
  extraClass = "block",
  widerClickableArea = false,
  arrowColor = "",
}) => {
  const id = nanoid();

  return (
    <div className={cn("relative", widerClickableArea && "w-16")}>
      <div data-for={id} data-tip="test" className="flex items-center">
        {children}
      </div>
      <ToolTip>
        <ReactTooltip
          className={cn("custom-tooltip hidden sm:block", extraClass)}
          id={id}
          place={place}
          effect="solid"
          delayShow={300}
          delayHide={500}
          arrowColor={arrowColor}
          multiline={true}
          scrollHide={true}
          clickable={true}
        >
          {content}
        </ReactTooltip>
      </ToolTip>
    </div>
  );
};

export default TooltipWrapper;
