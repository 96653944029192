// @ts-nocheck
import { AnimatePresence, motion } from "framer-motion";
import { modalAnimation, modalBgAnimation } from "ui/animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal as ModalContainer } from "atoms/Modal/Modal.style";
import ReactDOM from "react-dom";
import { enableBodyScroll } from "body-scroll-lock";
import { useDispatch } from "react-redux";
import { setGlobalState } from "models/globalState/sagaActions";

const enableBody = (target) => enableBodyScroll(target);

const Modal = ({
  type,
  width,
  onClick,
  children,
  hideCloseButton,
  ...props
}) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      setGlobalState({
        offerModalIsOpen: false,
        showOfferRequestVerification: false,
      })
    );
    enableBody();

    if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  const modal = (
    <AnimatePresence>
      <div className="fixed inset-0 bottom-0 z-[99] flex items-center justify-center h-full max-h-full p-0 overflow-y-auto lg:h-auto lg:px-4 lg:py-12">
        <motion.div
          onClick={() => {
            closeModal();
          }}
          variants={modalBgAnimation}
          initial="hidden"
          exit="hidden"
          animate="show"
          className="fixed inset-0 transition-opacity"
        >
          <div className="absolute inset-0 opacity-75 bg-midnight"></div>
        </motion.div>

        <ModalContainer
          variants={modalAnimation}
          initial="hidden"
          exit="hidden"
          animate="show"
          type={type}
          width={width}
          {...props}
        >
          {!hideCloseButton && (
            <div className="absolute top-0 right-0 z-20 pt-4 pr-5">
              <button
                type="button"
                className="leading-none transition duration-150 ease-in-out sm:text-white hover:text-gray-300 focus:outline-none __close"
                aria-label="Close"
                onClick={() => {
                  closeModal();
                }}
              >
                <FontAwesomeIcon className="text-2xl" icon={["fal", "times"]} />
              </button>
            </div>
          )}
          {children}
        </ModalContainer>
      </div>
    </AnimatePresence>
  );

  return ReactDOM.createPortal(modal, document.getElementById("modal-portal"));
};

export default Modal;
