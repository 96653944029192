import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  opportunity: string | undefined | null;
}

const MarketOpportunity: FC<Props> = ({ opportunity }) => {
  return (
    <EditablePlaceholderText
      fieldName="business.marketOpp"
      title="Market Opportunity"
      addValueTitle="Market opportunity"
      value={opportunity}
    />
  );
};

export default MarketOpportunity;
