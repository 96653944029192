// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getCompanyMarket = createAction("companySettings/market/fetch");

export const saveCompanyMarket = createAction(
  "companySettings/market/save",
  (payload) => ({
    payload,
  })
);
