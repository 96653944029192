import { gql } from "@apollo/client";

export const GET_TRACTION = gql`
  query getCompanyTraction {
    getCompanyTraction {
      _id
      summary
      activeUsers {
        amount
        frequency
      }
      activeUsersNote
      totalUsers {
        type
        count
      }
      totalUsersNote
      churnRate {
        period
        percentage
      }
      churnRateNote
      growthRate {
        period
        amount
        rate
        time
      }
      growthRateNote
      revenueRate {
        period
        amount
        rate
        time
      }
      revenueRateNote
      retentionRate {
        period
        amount
        rate
        time
      }
      retentionRateNote
      netRevenue {
        time
        amount
        currency
      }
      netRevenueNote
      recurringRevenue {
        time
        amount
        currency
      }
      recurringRevenueNote
      smv_gsv {
        time
        amount
        currency
      }
      smv_gsvNote
      salePriceAvg {
        amount
        currency
      }
      salePriceAvgNote
      ratePercentage
      ratePercentageNote
      cacPayback {
        amount
        frequency
      }
      cacPaybackNote
      ltcCacRatio
      ltcCacRatioNote
      demandFreq {
        unit
        amount
        frequency
      }
      demandFreqNote
      supplyFreq {
        unit
        amount
        frequency
      }
      supplyFreqNote
      signedContracts
      signedContractsNote
      pipelineContracts
      pipelineContractsNote
      annualValueContracts {
        amount
        currency
      }
      annualValueContractsNote
      lettersOfIntent
      lettersOfIntentNote
      additionalTraction
      annualValueContractsPipeline {
        amount
        currency
      }
      annualValueContractsPipelineNote
    }
  }
`;

export const UPDATE_TRACTION = gql`
  mutation updateCompanyTraction($input: UpdateTractionInput!) {
    updateCompanyTraction(input: $input) {
      summary
      activeUsers {
        amount
        frequency
      }
      activeUsersNote
      totalUsers {
        type
        count
      }
      totalUsersNote
      churnRate {
        period
        percentage
      }
      churnRateNote
      growthRate {
        period
        amount
        rate
        time
      }
      growthRateNote
      revenueRate {
        period
        amount
        rate
        time
      }
      revenueRateNote
      retentionRate {
        period
        amount
        rate
        time
      }
      retentionRateNote
      netRevenue {
        time
        amount
        currency
      }
      netRevenueNote
      recurringRevenue {
        time
        amount
        currency
      }
      recurringRevenueNote
      smv_gsv {
        time
        amount
        currency
      }
      smv_gsvNote
      salePriceAvg {
        amount
        currency
      }
      salePriceAvgNote
      ratePercentage
      ratePercentageNote
      cacPayback {
        amount
        frequency
      }
      cacPaybackNote
      ltcCacRatio
      ltcCacRatioNote
      demandFreq {
        unit
        amount
        frequency
      }
      demandFreqNote
      supplyFreq {
        unit
        amount
        frequency
      }
      supplyFreqNote
      signedContracts
      signedContractsNote
      pipelineContracts
      pipelineContractsNote
      annualValueContracts {
        amount
        currency
      }
      annualValueContractsNote
      lettersOfIntent
      lettersOfIntentNote
      additionalTraction
      annualValueContractsPipeline {
        amount
        currency
      }
      annualValueContractsPipelineNote
    }
  }
`;

export const SAVE_TRACTION = gql`
  mutation saveCompanyTraction($input: TractionInput!) {
    saveCompanyTraction(input: $input) {
      _id
      summary
      activeUsers {
        amount
        frequency
      }
      activeUsersNote
      totalUsers {
        type
        count
      }
      totalUsersNote
      churnRate {
        period
        percentage
      }
      churnRateNote
      growthRate {
        period
        amount
        rate
        time
      }
      growthRateNote
      revenueRate {
        period
        amount
        rate
        time
      }
      revenueRateNote
      retentionRate {
        period
        amount
        rate
        time
      }
      retentionRateNote
      netRevenue {
        time
        amount
        currency
      }
      netRevenueNote
      recurringRevenue {
        time
        amount
        currency
      }
      recurringRevenueNote
      smv_gsv {
        time
        amount
        currency
      }
      smv_gsvNote
      salePriceAvg {
        amount
        currency
      }
      salePriceAvgNote
      ratePercentage
      ratePercentageNote
      cacPayback {
        amount
        frequency
      }
      cacPaybackNote
      ltcCacRatio
      ltcCacRatioNote
      demandFreq {
        unit
        amount
        frequency
      }
      demandFreqNote
      supplyFreq {
        unit
        amount
        frequency
      }
      supplyFreqNote
      signedContracts
      signedContractsNote
      pipelineContracts
      pipelineContractsNote
      annualValueContracts {
        amount
        currency
      }
      annualValueContractsNote
      lettersOfIntent
      lettersOfIntentNote
      additionalTraction
      annualValueContractsPipeline {
        amount
        currency
      }
      annualValueContractsPipelineNote
    }
  }
`;
