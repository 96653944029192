import type { FC } from "react";
import type { BackButtonProps } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BackButton: FC<BackButtonProps> = ({ onAction }) => (
  <button
    type="button"
    onClick={onAction}
    className="absolute flex items-center justify-center p-2 bg-white rounded group hover:bg-aqua-100 focus:outline-aqua-300 left-2 top-2 hover:text-aqua-500"
  >
    <FontAwesomeIcon icon={["fas", "chevron-left"]} />
    <span className="ml-2">Back</span>
  </button>
);

export default BackButton;
