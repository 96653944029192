import type { TableProps } from "./interfaces";
import type {
  DealflowRecord,
  DealflowSection,
  GetDealflow,
} from "pages/dealflow/InvestorDealflow/interfaces";
import { FC, useMemo, useState } from "react";
import { SingleSelect } from "molecules/SingleSelect";
import FloatPanel from "./FloatPanel";
import { useMutation, useQuery } from "@apollo/client";
import { Table } from "organisms/Table";
import { CELL_RENDERERS } from "./Cell";
import DealFlowEmptyState from "./DealFlowEmptyState";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditStatusModal from "pages/dealflow/InvestorDealflow/components/EditStatusModal";
import { Mixpanel } from "core/mixpanel/MixpanelWrapper";
import {
  CHANGE_RECORD_STATUS,
  GET_DEALFLOW,
} from "queries/investor/investorDealflow";

const TableView: FC<TableProps> = ({
  columns,
  records,
  isSearchEmpty,
  isNoResults,
  preview,
  sections,
  sort,
  selected,
  onSelect,
  onSortChange,
  onColumnsChange,
  onEdit,
  refetch,
}) => {
  const [checkedRecords, setCheckedRecords] = useState<DealflowRecord[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [changeRecordStatus] = useMutation(CHANGE_RECORD_STATUS);
  const { refetch: refetchDealflow } = useQuery<GetDealflow>(GET_DEALFLOW);

  const finalColumns = useMemo(() => {
    const result = columns.slice();

    result.unshift({
      key: "edit",
      title: "",
    });

    return result;
  }, [columns]);

  const getSection = (id: string) =>
    sections.find((section) => section.id === id) ?? null;

  const getHandleStatusChange =
    (id: string) => async (status: DealflowSection) => {
      await changeRecordStatus({
        variables: {
          record: id,
          status: status.id,
        },
      });
      refetch();
      Mixpanel.track("Status Change Dealflow");
    };

  const handleOpenModal = () => setIsShowModal(true);

  const handleCloseModal = (needToRefetch?: boolean) => {
    setIsShowModal(false);

    if (needToRefetch) {
      refetchDealflow();
    }
  };

  return (
    <div
      className={cn(
        "relative w-full overflow-auto no-scrollbar max-h-full",
        selected && preview
          ? "md:max-w-[calc(100vw-50rem-4rem)]"
          : selected || preview
          ? "md:max-w-[calc(100vw-25rem-4rem)]"
          : "md:max-w-[calc(100vw-4rem)]"
      )}
    >
      <Table
        rows={records}
        columns={finalColumns}
        sort={sort}
        onSort={onSortChange}
        onCheck={setCheckedRecords}
        onClick={onSelect}
        onColumnsReorder={onColumnsChange}
        highlightIds={selected ? [selected.id] : []}
        cellRenderers={{
          ...CELL_RENDERERS,
          edit: (record) => (
            <div>
              {record.isManual && (
                <button
                  type="button"
                  className="text-sm text-gray-400 hover:text-aqua-400 focus:text-aqua-400"
                  onClick={() => onEdit(record)}
                >
                  <FontAwesomeIcon icon={["fas", "pen"]} />
                </button>
              )}
            </div>
          ),
          status: (record) => (
            <div className="relative pl-2 status-select max-w-[12rem]">
              <SingleSelect
                value={getSection(record.status.id)}
                choices={sections}
                onChange={getHandleStatusChange(record.id)}
                actions={[
                  {
                    onClick: handleOpenModal,
                    content: (
                      <span className="flex items-center text-sm text-black opacity-75">
                        <FontAwesomeIcon
                          className="mb-1"
                          icon={["fal", "pencil"]}
                        />
                        <span className="ml-2">Customize</span>
                      </span>
                    ),
                  },
                ]}
              />
            </div>
          ),
        }}
      />
      <FloatPanel
        records={checkedRecords}
        visible={checkedRecords.length > 0}
        sections={sections}
        refetch={refetch}
      />
      {isSearchEmpty && (
        <div className="py-10 text-sm text-center text-gray-600">
          No appropriate results
        </div>
      )}
      {isNoResults && <DealFlowEmptyState />}
      {isShowModal && <EditStatusModal onClose={handleCloseModal} />}
      <div className="mb-8" />
    </div>
  );
};

export default TableView;
