// @ts-nocheck
import { ClientJS } from "clientjs";
import { getClient } from "core/utility";
import { updateUserConfirmStatus } from "models/auth/reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import { CONFIRM_REGISTRATION } from "./query";
import * as sagaActions from "./sagaActions";
import { CONFIRM_REGISTRATION_BUTTON } from "./constants";

const clientId = new ClientJS();

const fingerprint = clientId.getFingerprint();

const confirmToken = async (token) => {
  try {
    let client = getClient();
    return await client.mutate({
      mutation: CONFIRM_REGISTRATION,
      fetchPolicy: "network-only",
      variables: { input: { token } },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

function* initializeConfirm({ payload: { token, lang } }) {
  yield put(
    toggleLoader({ component: CONFIRM_REGISTRATION_BUTTON, isLoading: true })
  );
  const { isError } = yield confirmToken(token);
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang?.somethingWentWrong,
        message: lang?.yourAccountCouldNotBeVerified,
      })
    );
    yield put(updateUserConfirmStatus({ isUserConfirmed: false }));
    yield put(
      toggleLoader({
        component: CONFIRM_REGISTRATION_BUTTON,
        isLoading: false,
      })
    );
    return;
  }
  yield put(updateUserConfirmStatus({ isUserConfirmed: data }));
  yield put(
    toggleLoader({ component: CONFIRM_REGISTRATION_BUTTON, isLoading: false })
  );
}

export default function* rootSaga() {
  return [yield takeEvery(sagaActions.initialize, initializeConfirm)];
}
