import media from "styled-media-query";
import styled from "styled-components";

export const DescriptionGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;

  ${media.greaterThan("medium")`
    grid-gap: 60px;
    grid-template-columns: 1fr auto;
  `}
`;

export const DescriptionContainer = styled.div`
  font-size: 18px;

  & > div:first-child {
    margin-top: 0px;
  }
`;

export const VisionMission = styled.div`
  display: grid;
  margin-top: 30px;

  ${media.greaterThan("medium")`
    grid-template-columns: 1fr;
    grid-gap: 2px;
    margin-top: 60px;
  `}

  p {
    font-size: 16px;
  }
`;

export const SDGContainer = styled.div`
  width: 300px;
`;

export const SDGItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
`;

export const SDGItem = styled.div`
  width: 120px;
`;
