import Button from "atoms/Button/Button";
import { useLang } from "core/lang/LangaugeSetting";
import { getGlobalState } from "models/globalState/selectors";
import { getIsApplicationSent, getUser } from "models/user/selectors";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StepActionButton } from "ui/elements/PanelLayout.styles";
import { submitCompanyApplication } from "../sagaActions";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

interface Props {
  className?: string;
  style?: object;
}

const StepActionButton_Stateful: FC<Props> = ({
  children,
  className,
  style,
}) => {
  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const user = useSelector(getUser);
  const isApplicationSent = useSelector(getIsApplicationSent);
  const dispatch = useDispatch();
  const { lang } = useLang();

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const submitApplication = () => {
    dispatch(submitCompanyApplication({ lang }));
  };

  return (
    <>
      <StepActionButton
        isNavigationEnabled={isNavigationEnabled}
        className={className}
        style={style}
      >
        {children}
        {!isApplicationSent && (
          <Button
            color="primary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
            className="ml-2 capitalize rounded-full submit-application-btn"
          >
            <span>{lang.submitApplication}</span>
          </Button>
        )}
      </StepActionButton>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle bg-white shadow-xl transition-all transform rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-gray-900 leading-6"
                  >
                    Are you ready to submit your application?{" "}
                  </Dialog.Title>

                  <div className="mt-6">
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        submitApplication();
                        closeModal();
                      }}
                      className="capitalize rounded-full submit-application-btn"
                    >
                      <span>Yes, submit</span>
                    </Button>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                      }}
                      className="ml-2 capitalize rounded-full"
                    >
                      <span>No</span>
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default StepActionButton_Stateful;
