import { moduleTypes } from "core/modules";
import { isLoggedinUser } from "models/user/selectors";
import MessagesSlice from "./reducer";
import MessagesSaga from "./sagas";

const module = [
  {
    module: moduleTypes.MESSAGES,
    path: "/messages",
    key: "messages",
    componentId: "messages",
    component: import(/* webpackChunkName: "messages" */ "."),
    routes: [],
    importReducer: () => [MessagesSlice],
    importSagas: () => [MessagesSaga],
    isEnabled: (state: any) => isLoggedinUser(state),
    title: "Messages",
    exact: true,
    showHeader: true,
    name: "Messages",
  },
];

export default module;
