import type { TeaserStepProps } from "../interfaces";
import { FC, useState } from "react";
import { Textarea } from "atoms/Textarea";
import BackButton from "../BackButton";

const MAX_PITCH_LENGTH = 300;
const MAX_SUMMARY_LENGTH = 80;

const Pitch: FC<TeaserStepProps> = ({ onNext, onPrev }) => {
  const [pitch, setPitch] = useState("");
  const [tractionSummary, setTractionSummary] = useState("");

  const handleNext = () =>
    onNext({
      elevatorPitch: {
        pitch,
        tractionSummary,
      },
    });

  return (
    <div className="w-full h-full px-2 py-8 md:px-16">
      <BackButton onAction={onPrev} />
      <h2 className="text-xl text-center">Elevator pitch</h2>
      <div className="my-8 font-bold text-center text-gray-500">
        Lastly, add an elevator pitch to show investors what your product is
        really about. You know the drill, keep it short!
      </div>
      <div className="px-8">
        <label className="flex flex-col">
          <span className="ml-2 text-sm text-gray-400">Elevator pitch</span>
          <Textarea
            value={pitch}
            onChange={({ target }) =>
              setPitch(target.value.slice(0, MAX_PITCH_LENGTH))
            }
            className="w-full px-2 py-1 border border-gray-200 outline-none resize-none rounded-xl h-[200px] focus:ring-aqua-500 focus:ring"
          />
        </label>
        <div className="flex justify-end text-sm text-gray-500">
          {pitch.length} / {MAX_PITCH_LENGTH}
        </div>
      </div>
      <div className="px-8">
        <label className="flex flex-col">
          <span className="ml-2 text-sm text-gray-400">
            Summarize your traction
          </span>
          <Textarea
            value={tractionSummary}
            onChange={({ target }) =>
              setTractionSummary(target.value.slice(0, MAX_SUMMARY_LENGTH))
            }
            className="w-full px-2 py-1 border border-gray-200 outline-none resize-none rounded-xl h-[90px] focus:ring-aqua-500 focus:ring"
          />
        </label>
        <div className="flex justify-end text-sm text-gray-500">
          {tractionSummary.length} / {MAX_SUMMARY_LENGTH}
        </div>
      </div>
      <div className="flex justify-end mt-8 pb-4">
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          To final step
        </button>
      </div>
    </div>
  );
};

export default Pitch;
