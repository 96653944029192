import { useSvg } from "pages/onboard/hooks";
import { FC, useEffect, useMemo, useState } from "react";

const CIRCLE_SIZE = 20;
const GROUP_LENGTH = 4;
const GROUPS_SIZE = 6;
const SMOOTH_FACTOR = 0.06;
const GROUP_SMOOTH_STEP = 0.06;

const drawCircle = (x: number, y: number, s = CIRCLE_SIZE) =>
  `M ${x - s} ${y} A ${s / 2} ${s / 2} 0 0 0 ${x + s} ${y} A ${s / 2} ${
    s / 2
  } 0 0 0 ${x - s} ${y}`;

const split = <T extends any>(array: T[], length: number) => {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += length) {
    result.push(array.slice(i, i + length));
  }

  return result;
};

const CicleDecor: FC = () => {
  const { handleRef, viewBox, box } = useSvg();
  const [transforms, setTransforms] = useState<string[]>([]);

  const w = (p: number) => box.width * p;

  const h = (p: number) => box.height * p;

  const paths = useMemo(
    () => [
      [drawCircle(w(0.92), h(0.6)), "rotate(148)", "#18BEB3"],
      [drawCircle(w(0.8), h(0.5)), "rotate(127)", "#149E99"],
      [drawCircle(w(0.9), h(0.9)), "rotate(140) scale(.3)", "#0C5251"],
      [drawCircle(w(0.12), h(0.48)), "rotate(64) scale(.4)", "#18BEB3"],
      [drawCircle(w(0.85), h(0.16)), "rotate(12) scale(.5)", "#107775"],
      [drawCircle(w(0.9), h(0.28)), "rotate(68) scale(.6)", "#0C5251"],
      [drawCircle(w(0.38), h(0.9)), "rotate(148) scale(.2)", "#0C5251"],
      [drawCircle(w(0.55), h(0.74)), "rotate(148) scale(.85)", "#0C5251"],
      [drawCircle(w(0.29), h(0.84)), "rotate(148) scale(.83)", "#0C5251"],
      [drawCircle(w(0.22), h(0.56)), "rotate(91) scale(.73)", "#0A4140"],
      [drawCircle(w(0.14), h(0.7)), "rotate(148) scale(.8)", "#18BEBA"],
      [drawCircle(w(0.1), h(0.9)), "rotate(148) scale(.16)", "#18BEBA"],
      [drawCircle(w(0.19), h(0.35)), "rotate(128) scale(.9)", "#149E99"],
      [drawCircle(w(0.25), h(0.23)), "rotate(91) scale(.9)", "#061D1C"],
      [drawCircle(w(0.35), h(0.16)), "rotate(91) scale(.6)", "#18BEB3"],
      [drawCircle(w(0.26), h(0.12)), "rotate(12) scale(.11)", "#082F2E"],
      [drawCircle(w(0.1), h(0.56)), "rotate(91) scale(.2)", "#082F2E"],
      [drawCircle(w(0.65), h(0.56)), "rotate(91) scale(.4)", "#16B2A9"],
      [drawCircle(w(0.4), h(0.6)), "rotate(91) scale(1)", "#18BEB3"],
      [drawCircle(w(0.8), h(0.84)), "rotate(91) scale(.32)", "#061D1C"],
      [drawCircle(w(0.2), h(0.84)), "rotate(100) scale(.26)", "#061D1C"],
      [drawCircle(w(0.25), h(0.5)), "rotate(218) scale(.2)", "#149E99"],
      [drawCircle(w(0.7), h(0.7)), "rotate(218) scale(.2)", "#0C5251"],
      [drawCircle(w(0.38), h(0.38)), "rotate(148) scale(.7)", "#18BEB3"],
      [drawCircle(w(0.62), h(0.62)), "rotate(148) scale(.7)", "#0C5251"],
      [drawCircle(w(0.74), h(0.74)), "rotate(182) scale(.7)", "#0C5251"],
      [drawCircle(w(0.22), h(0.22)), "rotate(148) scale(.21)", "#0C5251"],
      [drawCircle(w(0.58), h(0.58)), "rotate(148) scale(.46)", "#0C5251"],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [box.width, box.height]
  );

  const groups = split(paths, GROUP_LENGTH);

  useEffect(() => {
    const handler = ({ clientX, clientY }: MouseEvent) => {
      const cx = window.innerWidth / 2;
      const cy = window.innerHeight / 2;

      const tx = -(clientX - cx) * SMOOTH_FACTOR;
      const ty = -(clientY - cy) * SMOOTH_FACTOR;

      const getTransform = (index: number) =>
        `translate(${tx * (index + 1) * GROUP_SMOOTH_STEP} ${
          ty * (index + 1) * GROUP_SMOOTH_STEP
        })`;

      setTransforms(
        Array.from(
          {
            length: GROUPS_SIZE,
          },
          (_, index) => getTransform(index)
        )
      );
    };

    window.addEventListener("mousemove", handler);

    return () => {
      window.removeEventListener("mousemove", handler);
    };
  }, []);

  const renderCircle = ([d, transform, stroke]: string[], index: number) => (
    <path
      key={index}
      d={d}
      style={{ transformBox: "fill-box" }}
      transform={transform}
      stroke={stroke}
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );

  return (
    <div className="fixed inset-0 hidden w-screen h-screen sm:block animate-account-decor">
      <svg ref={handleRef} viewBox={viewBox} className="w-full h-full">
        {groups.map((paths, index) => (
          <g key={index} transform={transforms[index] ?? ""}>
            {paths.map(renderCircle)}
          </g>
        ))}
      </svg>
    </div>
  );
};

export default CicleDecor;
