import React, { FC } from "react";
import { SocialLink } from "./Team.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatDuration } from "date-fns";

interface Props {
  member: any;
  index: number;
}

const MemberInfo: FC<Props> = ({ member, index }) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center">
      <div className="md:my-auto my-2 mr-4 rounded-full">
        <img
          className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full my-auto w-16 h-16"
          src={
            member?.image?.large?.location
              ? member?.image?.large?.location
              : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                  ((index + 1) % 6) + 1
                }.png`
          }
          alt={`member-${index}`}
        />
      </div>
      <div className="flex flex-col ">
        <h5 className="font-medium dark:text-white/80 capitalize">
          {member.name}
        </h5>

        <div className="flex mt-1">
          <small className="mr-2 text-base text-gray-700 dark:text-white">
            {member.title?.toLowerCase() === "i don't know"
              ? ""
              : member.title || ""}
          </small>
          {member?.email && (
            <SocialLink
              type="email"
              href={`mailto:${member.email}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={["fas", "envelope"]} />
            </SocialLink>
          )}

          {member?.twitter && (
            <SocialLink
              type="twiiter"
              href={
                member?.twitter.includes("https")
                  ? member?.twitter
                  : `https://${member?.twitter}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </SocialLink>
          )}

          {member?.linkedin && (
            <SocialLink
              type="linkedin"
              href={
                member?.linkedin.includes("https")
                  ? member?.linkedin
                  : `https://${member?.linkedin}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </SocialLink>
          )}

          {member?.instagram && (
            <SocialLink
              type="instagram"
              href={
                member?.instagram.includes("https")
                  ? member?.instagram
                  : `https://${member?.instagram}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </SocialLink>
          )}
        </div>
        {member?.employedAt && (
          <div className="flex flex-row mt-1">
            <p className="text-[#24272B] whitespace-nowrap">
              {format(new Date(member.employedAt), "MMM yyyy")} - now,&nbsp;
            </p>
            <p className="text-[#24272B] whitespace-nowrap">
              {formatDuration({
                years:
                  new Date().getFullYear() -
                  new Date(member.employedAt).getFullYear(),
                months:
                  new Date().getMonth() -
                  new Date(member.employedAt).getMonth(),
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MemberInfo;
