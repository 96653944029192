import { FC } from "react";
import cn from "classnames";
import { PlanToggleButtonProps } from "pages/plans/interfaces";
import { mapBillingFrequency } from "pages/plans/utils/mapBillingFrequency";

const PlanToggleButton: FC<PlanToggleButtonProps> = ({
  billingFrequency,
  isSelected = false,
  toggle,
}) => (
    <button
      onClick={() => toggle(billingFrequency)}
      className={cn(
        "flex justify-center items-center h-full px-4 cursor-pointer",
        isSelected && "bg-[#18BEB3] rounded-full"
      )}
    >
      <span
        className={cn(
          "text-sm font-medium",
          isSelected ? "text-white" : "text-[#3B3472]"
        )}
      >
        {mapBillingFrequency(billingFrequency)}
      </span>
    </button>
  );

export default PlanToggleButton;
