import type { StepsParams } from "./interfaces";
import { useState, useEffect } from "react";
import { Mixpanel } from "core/mixpanel/MixpanelWrapper";

const useSteps = <
  Payload extends Record<string, any> = Record<string, any>,
  Step extends string = string
>(
  steps: readonly Step[],
  { initialIndex = 0, initialPayload = {}, onFinish }: StepsParams<Payload> = {}
) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [payload, setPayload] = useState<Partial<Payload>>(initialPayload);
  const [state, setState] = useState<Partial<Record<Step, boolean>>>({});

  const next = (addToPayload?: Partial<Payload>) => {
    const current = steps[activeIndex];
    const newPayload = {
      ...payload,
      ...addToPayload,
    };

    if (current) {
      setState((prev) => ({
        ...prev,
        [current]: true,
      }));
    }

    if (addToPayload) {
      setPayload(newPayload);
    }

    if (activeIndex + 1 < steps.length) {
      setActiveIndex(activeIndex + 1);
    } else {
      onFinish?.(newPayload);
    }
  };

  const prev = () => {
    if (activeIndex - 1 >= 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  const go = (step: Step) => {
    const index = steps.indexOf(step);

    setActiveIndex(index);
  };

  const isActive = (step: Step) => steps[activeIndex] === step;

  const isPassed = (step: Step) => Boolean(state[step]);

  const active = steps[activeIndex];

  useEffect(() => {
    if (steps[activeIndex] !== "teaser") {
      const stepName = "Onboarding " + steps[activeIndex];
      Mixpanel.track(stepName);
      Mixpanel.timeEvent(stepName);
    }
  }, [activeIndex]);

  return {
    steps,
    activeIndex,
    active,
    payload,
    state,
    next,
    prev,
    go,
    isActive,
    isPassed,
  };
};

export default useSteps;
