import { FC, useState } from "react";
import { ProductVisualsProps, Visual } from "./interfaces";
import ProductVisualsNav from "atoms/ProductVisualsNav";
import ProductVisual from "./ProductVisual";
import VisualPreview from "./VisualPreview";
import ProductVisualsModal from "./ProductVisualsModal";

const ProductVisuals: FC<ProductVisualsProps> = ({
  productDemo,
  productImages = [],
}: ProductVisualsProps) => {
  const formattedImages: Visual[] = productImages.map((source) => ({
    source,
    type: "IMAGE",
  }));

  const productVisuals: Visual[] = formattedImages;

  if (productDemo) {
    productVisuals.unshift({
      source: productDemo,
      type: "VIDEO",
    });
  }

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isVisualsModalOpen, setIsVisualsModalOpen] = useState<boolean>(false);

  const handleMoveToNext = (): void => {
    if (currentIndex === productVisuals.length - 1) return;
    setCurrentIndex(currentIndex + 1);
  };

  const handleMoveToPrev = (): void => {
    if (currentIndex === 0) return;
    setCurrentIndex(currentIndex - 1);
  };

  const handleMoveToCusomPosition = (index: number) => {
    if (0 > index || index > productVisuals.length - 1) return;
    setCurrentIndex(index);
  };

  const handleOpenVisuals = () => setIsVisualsModalOpen(true);
  const handleCloseVisuals = () => setIsVisualsModalOpen(false);
  const currentVisual = productVisuals[currentIndex];
  const isMovePrveEnabled = currentIndex > 0;
  const isMoveNextEnabled = currentIndex < productVisuals.length - 1;

  return (
    <div className="flex flex-col flex-1 w-full lg:w-1/2 ">
      <div className="relative h-[300px]">
        {isMovePrveEnabled && (
          <ProductVisualsNav direction="PREV" onClick={handleMoveToPrev} />
        )}
        <ProductVisual
          type={currentVisual.type}
          source={currentVisual.source}
          onClick={handleOpenVisuals}
        />
        {isMoveNextEnabled && (
          <ProductVisualsNav direction="NEXT" onClick={handleMoveToNext} />
        )}
      </div>
      <div className="flex my-5 overflow-x-auto">
        <div className="flex flex-nowrap">
          {productVisuals.map(({ source, type }, index) => (
            <VisualPreview
              source={source}
              type={type}
              onClick={() => handleMoveToCusomPosition(index)}
              isCurrent={index === currentIndex}
            />
          ))}
        </div>
      </div>
      {isVisualsModalOpen && (
        <ProductVisualsModal
          defaultIndex={currentIndex}
          productImages={productImages}
          productDemo={productDemo}
          isVisualsModalOpen={isVisualsModalOpen}
          handleCloseVisuals={handleCloseVisuals}
        />
      )}
    </div>
  );
};

export default ProductVisuals;
