import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { pageAnimation } from "ui/animations";
import { Main, Page, PageHeader, Panel } from "ui/elements/PanelLayout.styles";
import { TableContainer } from "./AdminDiscover.style";
import { useMutation, useQuery } from "@apollo/client";

import {
  AdminDiscoverCompaniesResponse,
  AdminDiscoverCompany,
  CompanyType,
  DiscoverFeatureStatus,
} from "./interfaces";
import { useEffect, useRef, useState } from "react";
import type { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { ListboxWrapper } from "./Listbox";
import { useGuard } from "hooks/useGuard";
import { MustBeAdmin } from "core/routePolicies";
import { capitalize } from "lodash";
import {
  APPROVE_COMPANY,
  CANCEL_TRIAL,
  DECLINE_COMPANY,
  GET_DISCOVER_COMPANIES,
  SET_IS_GEM,
  START_TRIAL,
} from "queries/admin/adminDiscover";
import { convertTimeStampToDate } from "pages/startupsBoard/utils";
import { Subscription } from "gql/graphql";

const COMPANY_TYPE_FILTER_OPTIONS = [
  { name: CompanyType.All },
  { name: CompanyType.Investor },
  { name: CompanyType.Company },
];
const DISCOVER_STATUS_FILTER_OPTIONS = [
  { name: DiscoverFeatureStatus.All },
  { name: DiscoverFeatureStatus.Approved },
  { name: DiscoverFeatureStatus.NotRequested },
  { name: DiscoverFeatureStatus.Requested },
  { name: DiscoverFeatureStatus.Declined },
];
const PER_PAGE = 20;

export default function AdminDiscover() {
  useGuard([MustBeAdmin]);
  const page = useRef(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDiscoverStatus, setSelectedDiscoverStatus] = useState({
    name: DiscoverFeatureStatus.All,
  });
  const [selectedCompanyType, setSelectedCompanyType] = useState({
    name: CompanyType.All,
  });
  const [query, setQuery] = useState("");
  const [noMoreData, setNoMoreData] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    field: "createdAt",
    ascending: false,
  });

  const { data, fetchMore, loading, refetch } =
    useQuery<AdminDiscoverCompaniesResponse>(GET_DISCOVER_COMPANIES, {
      variables: {
        page: page.current,
        perPage: PER_PAGE,
        status:
          selectedDiscoverStatus.name === DiscoverFeatureStatus.All
            ? null
            : selectedDiscoverStatus.name,
        type:
          selectedCompanyType.name === CompanyType.All
            ? null
            : selectedCompanyType.name,
        query,
        sort: sortConfig,
      },
      onCompleted: () => {
        setNoMoreData(false);
      },
    });

  const companies: AdminDiscoverCompany[] =
    data?.adminDiscoverCompanies?.companies ?? [];

  const [approve] = useMutation(APPROVE_COMPANY, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Successfully approved",
          timer: 1000,
        })
      );
      refetch();
    },
  });

  const [decline] = useMutation(DECLINE_COMPANY, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Successfully declined",
          timer: 1000,
        })
      );
      refetch();
    },
  });

  const [setIsGem] = useMutation(SET_IS_GEM);

  const [startTrial] = useMutation(START_TRIAL, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Successfully Started the trial",
          timer: 1000,
        })
      );
      refetch();
    },
  });

  const [cancelTrial] = useMutation(CANCEL_TRIAL, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Successfully Cancelled the trial",
          timer: 1000,
        })
      );
      refetch();
    },
  });

  useEffect(() => {
    if (query) {
      refetch({ page: 1, query }).then((res) => {
        showLoadMoreButton(res.data?.adminDiscoverCompanies?.companies);
        page.current = 1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    refetch({ page: 1 }).then((res) => {
      showLoadMoreButton(res.data.adminDiscoverCompanies?.companies);
    });
    page.current = 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiscoverStatus, selectedCompanyType, sortConfig]);

  const showLoadMoreButton = (companies: AdminDiscoverCompany[]) => {
    if (companies.length < PER_PAGE) {
      setNoMoreData(true);
    }
  };

  const handleLoadMore = () => {
    page.current = page.current + 1;
    fetchMore({
      variables: {
        page: page.current,
      },
    }).then((res) => {
      showLoadMoreButton(res.data?.adminDiscoverCompanies?.companies);
    });
  };

  const handleDecline = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    account: AdminDiscoverCompany
  ) => {
    e.stopPropagation();
    decline({
      variables: {
        companyId: account.id,
      },
    });
  };

  const handleApprove = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    account: AdminDiscoverCompany
  ) => {
    e.stopPropagation();
    approve({
      variables: {
        companyId: account.id,
      },
    });
  };

  const handleSetIsGem = (companyId: string, value: boolean) => {
    setIsGem({
      variables: {
        value,
        companyId,
      },
      onCompleted: () => {
        dispatch(
          triggerSnack({
            type: "success",
            title: "Success",
            message: `Successfully marked as ${!value ? "NOT_" : ""}GEM`,
            timer: 1000,
          })
        );
        refetch();
      },
    });
  };

  const handleStartTrial = (companyId: string) => {
    startTrial({
      variables: {
        companyId,
      },
    });
  };

  const handleCancelTrial = (subscriptionId: string) => {
    cancelTrial({
      variables: {
        subscriptionId,
      },
    });
  };

  const onAppliedAtClicked = () => {
    setSortConfig((prev) => ({
      field: "discoverApplicationDate",
      ascending:
        prev.field === "discoverApplicationDate"
          ? !prev.ascending
          : prev.ascending,
    }));
  };

  const onRegistrationDateClicked = () => {
    setSortConfig((prev) => ({
      field: "createdAt",
      ascending: prev.field === "createdAt" ? !prev.ascending : prev.ascending,
    }));
  };

  const getTrialContent = (
    companyId: string,
    accountType: string,
    subscription: Subscription | undefined
  ) => {
    if (accountType === "company") return <p>N/A</p>;

    if (!subscription)
      return (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleStartTrial(companyId);
          }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-aqua-500 bg-aqua-100 hover:bg-gray-200 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-200 transition ease-in-out duration-150 mr-4"
        >
          Start trial
        </button>
      );

    if (subscription.status === "ACTIVE")
      return (
        <p className="inline-flex cursor-not-allowed items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-aqua-500 bg-aqua-100 hover:bg-gray-200 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-200 transition ease-in-out duration-150 mr-4">
          PRO
        </p>
      );

    if (subscription.status === "TRIALING")
      return (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleCancelTrial(subscription.id!);
          }}
          className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-amber-500 bg-amber-200 hover:bg-gray-200 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-200 transition ease-in-out duration-150 mr-4"
        >
          Cancel Trial
        </button>
      );
  };

  const onCopy = () => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Email copied to clipboard!",
      })
    );
  };

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
    >
      <div className="flex w-full px-6 mx-auto">
        <Main>
          <Panel>
            <PageHeader>
              <div className="mb-4">
                <h1 className="text-2xl text-black sm:text-4xl sm:truncate">
                  Companies for Discover
                </h1>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 mb-4 xl:flex-row">
                <input
                  placeholder="Search..."
                  value={query}
                  onChange={({ target }) => setQuery(target.value)}
                  className="w-full px-2 py-1 text-sm border border-gray-200 rounded-md shadow-sm md:w-52 focus:border-gray-400 md:mr-0 md:mb-0"
                />
                <label>
                  Startups: {data?.adminDiscoverCompanies?.startupsCount}
                </label>
                <label>
                  Investors: {data?.adminDiscoverCompanies?.investorsCount}
                </label>
                <div className="flex gap-4">
                  <ListboxWrapper
                    options={DISCOVER_STATUS_FILTER_OPTIONS}
                    onSelect={(value) => setSelectedDiscoverStatus(value)}
                    selected={selectedDiscoverStatus}
                  />
                  <ListboxWrapper
                    options={COMPANY_TYPE_FILTER_OPTIONS}
                    onSelect={(value) => setSelectedCompanyType(value)}
                    selected={selectedCompanyType}
                  />
                </div>
              </div>
            </PageHeader>

            <TableContainer>
              <div className="flex flex-col">
                <div className="inline-block w-full overflow-x-auto align-middle sm:rounded-bl-lg sm:rounded-br-lg">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Logo & Name
                        </th>

                        <th
                          className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer bg-gray-50 whitespace-nowrap"
                          onClick={onAppliedAtClicked}
                        >
                          Applied at
                          {sortConfig.field === "discoverApplicationDate" && (
                            <FontAwesomeIcon
                              icon={[
                                "fas",
                                sortConfig.ascending
                                  ? "caret-down"
                                  : "caret-up",
                              ]}
                              size="lg"
                              className="ml-1"
                            />
                          )}
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Type
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Website
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Trial
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Contact
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          GEM
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Status
                        </th>

                        <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 whitespace-nowrap">
                          Actions
                        </th>

                        <th
                          className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer bg-gray-50 whitespace-nowrap"
                          onClick={onRegistrationDateClicked}
                        >
                          Registration date
                          {sortConfig.field === "createdAt" && (
                            <FontAwesomeIcon
                              icon={[
                                "fas",
                                sortConfig.ascending
                                  ? "caret-down"
                                  : "caret-up",
                              ]}
                              size="lg"
                              className="ml-1"
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {companies.map((account) => {
                        const logo = account?.logo?.small?.location;
                        return (
                          <tr
                            onClick={(e) => {
                              navigate(
                                `/${account.type.toLowerCase()}/${account.slug}`
                              );
                            }}
                            key={account._id}
                          >
                            <td className="px-6 py-4 truncate border-b border-gray-200">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 w-10 h-10">
                                  {logo ? (
                                    <img
                                      className="object-cover w-10 h-10 rounded-full"
                                      src={logo}
                                      alt={`${account.displayName} logo`}
                                    />
                                  ) : (
                                    <span className="inline-flex items-center justify-center object-cover w-10 h-10 bg-gray-500 rounded-full">
                                      <span className="font-medium leading-none text-white">
                                        {account?.displayName
                                          ?.charAt(0)
                                          ?.toUpperCase()}
                                      </span>
                                    </span>
                                  )}
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium leading-5 text-gray-900">
                                    {account.displayName}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              {account.discoverApplicationDate
                                ? convertTimeStampToDate(
                                    account.discoverApplicationDate
                                  )
                                : "N/A"}
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              {account.type}
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              <a
                                href={account.website}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn(
                                  !account.website && "cursor-not-allowed"
                                )}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <span className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-gray-500 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-200 transition ease-in-out duration-150 mr-4">
                                  <FontAwesomeIcon
                                    icon={["fal", "external-link"]}
                                  />
                                </span>
                              </a>
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              {getTrialContent(
                                account.id,
                                account.type,
                                account.subscription
                              )}
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              <CopyToClipboard
                                onCopy={onCopy}
                                text={account.email}
                              >
                                <button
                                  type="button"
                                  onClick={(e) => e.stopPropagation()}
                                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-gray-500 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:border-gray-300 focus:shadow-outline-gray active:bg-gray-200 transition ease-in-out duration-150 mr-4"
                                >
                                  {account.email}
                                </button>
                              </CopyToClipboard>
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              <input
                                className="w-6 h-6"
                                type="checkbox"
                                checked={account.isGem}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleSetIsGem(account.id, !account.isGem);
                                }}
                              />
                            </td>

                            <td className="px-6 py-4 capitalize truncate border-b border-gray-200">
                              <span
                                className={cn(
                                  "p-1 rounded-lg text-white bg-black",
                                  account.discoverFeatureStatus ===
                                    DiscoverFeatureStatus.Approved &&
                                    "bg-aqua-600",
                                  account.discoverFeatureStatus ===
                                    DiscoverFeatureStatus.Declined &&
                                    "bg-red-600"
                                )}
                              >
                                {capitalize(account.discoverFeatureStatus)}
                              </span>
                            </td>

                            <td className="px-6 py-4 text-sm font-medium leading-5 text-left truncate border-b border-gray-200">
                              {account.discoverFeatureStatus !==
                                DiscoverFeatureStatus.Approved && (
                                <button
                                  type="button"
                                  className="inline-flex disabled:cursor-not-allowed items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-aqua-500 bg-aqua-100 hover:bg-aqua-50 focus:outline-none focus:border-aqua-300 focus:shadow-outline-aqua active:bg-aqua-200 transition ease-in-out duration-150"
                                  onClick={(e) => handleApprove(e, account)}
                                >
                                  Approve
                                </button>
                              )}
                              {account.discoverFeatureStatus !==
                                DiscoverFeatureStatus.Declined && (
                                <button
                                  type="button"
                                  className="inline-flex items-center disabled:cursor-not-allowed ml-4 px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-amber-500 bg-amber-100 hover:bg-amber-50 focus:outline-none focus:border-amber-300 focus:shadow-outline-aqua active:bg-amber-200 transition ease-in-out duration-150"
                                  onClick={(e) => handleDecline(e, account)}
                                >
                                  Decline
                                </button>
                              )}
                            </td>
                            <td className="px-6 py-4 text-left capitalize truncate border-b border-gray-200">
                              <span>
                                {account.createdAt
                                  ? convertTimeStampToDate(account.createdAt)
                                  : "N/A"}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {Boolean(data?.adminDiscoverCompanies?.companies?.length) &&
                !noMoreData && (
                  <div className="w-full mt-12 text-center">
                    <Button
                      size="large"
                      color="primary"
                      loading={loading}
                      disabled={loading}
                      onHover={handleLoadMore}
                    >
                      Load more
                    </Button>
                  </div>
                )}
            </TableContainer>
          </Panel>
        </Main>
      </div>
    </Page>
  );
}
