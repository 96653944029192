import { moduleTypes } from "core/modules";

const module = [
  {
    path: "/startup-dealflow",
    key: "startup_dealflow",
    componentId: "startup_dealflow",
    component: import(/* webpackChunkName: "startupDealflow" */ "./StartupDealflow"),
    routes: [],
    module: moduleTypes.INVESTOR_DEALFLOW,
    isEnabled: () => true,
    importReducer: () => [],
    importSagas: () => [],
    title: "Dealflow",
    exact: true,
    name: "Dealflow",
  },
];

export default module;
