export const MustBeCompany = (userData: any) => {
  if (!userData.isCompany) {
    return "/dealflow";
  }
};

export const MustBeInvestor = (userData: any) => {
  if (!userData.isInvestor) {
    return "/myspace";
  }
};

export const MustBeAccelerator = (userData: any) => {
  if (!userData.isAccelerator) {
    // Accelerators are still investors, DF is open for them
    return "/dealflow";
  }
};

export const MustBeAdmin = (userData: any) => {
  if (!userData.isAdmin) {
    return "/";
  }
};
