import { FC, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { googleClientID } from "core/config";
import { useLang } from "core/lang/LangaugeSetting";
import {
  triggerGoogleSignIn,
  triggerGoogleSignUp,
} from "models/auth/sagaActions";

import { getGoogleSignUpSuccess } from "models/auth/selectors";

interface GoogleSignInProps {
  type: "SIGN_IN";
  cohort?: string;
  onSignInSuccess?: () => void;
}

interface GoogleSignUpProps {
  type: "SIGN_UP";
  onSignUpSuccess?: () => void;
  cohort?: string;
  signUpAs?: "COMPANY" | "INVESTOR";
  userType: string;
  referralUsed?: string;
  validate?: () => void;
  policiesAndTermsAgreed?: boolean;
}

// Discriminating unions in typescript. This component can
// handle both signup and signin
type GoogleAuthProps = GoogleSignInProps | GoogleSignUpProps;

const GoogleAuth: FC<GoogleAuthProps> = (props) => {
  const { lang } = useLang();
  const googleBtnRef = useRef<HTMLDivElement>(null);
  const googleSignUpSuccess = useSelector(getGoogleSignUpSuccess);

  const dispatch = useDispatch();

  useEffect(() => {
    if (googleSignUpSuccess) {
      if (props.type === "SIGN_UP") {
        props.onSignUpSuccess?.();
      }
    }
  }, [googleSignUpSuccess]);

  const onGoogleAuthSuccess = async (idToken: string) => {
    if (props.type === "SIGN_IN") {
      dispatch(
        triggerGoogleSignIn({ lang, input: { idToken, cohort: props.cohort } })
      );
      return;
    }

    if (props.type === "SIGN_UP") {
      const {
        cohort,
        signUpAs,
        userType,
        referralUsed,
        policiesAndTermsAgreed,
      } = props;

      dispatch(
        triggerGoogleSignUp({
          lang,
          input: {
            idToken,
            cohort,
            signUpAs,
            referralUsed,
            policiesAndTermsAgreed,
          },
        })
      );

      window.dataLayer?.push({
        event: "Pageview",
        "Page Path": "/signup successful",
        "Data Layer": `from ${userType} portal`,
      });
    }
  };

  const getSignInButtonText = () => {
    if (props.type === "SIGN_UP") {
      return "signup_with";
    }
    return "signin_with";
  };

  useEffect(() => {
    if (
      typeof window === "undefined" ||
      !window.google ||
      !googleBtnRef.current ||
      !googleClientID
    ) {
      return;
    }

    try {
      window.google.accounts.id.initialize({
        client_id: googleClientID,
        ux_mode: "popup",

        callback: async (resp) => {
          if (resp.credential) {
            await onGoogleAuthSuccess(resp.credential);
          }
        },
      });

      if (props.type === "SIGN_UP") {
        //  blocking any clicks on gogle signin button until the form is valid.
        googleBtnRef.current.style.pointerEvents = "none";
        googleBtnRef.current.style.cursor = "not-allowed";

        if (props.policiesAndTermsAgreed) {
          googleBtnRef.current.style.pointerEvents = "auto";
          googleBtnRef.current.style.cursor = "auto";
        }
      }

      window.google.accounts.id.renderButton(googleBtnRef.current, {
        type: "standard",
        text: getSignInButtonText(),
      });
    } catch (error) {}
  }, [
    window.google,
    googleBtnRef.current,
    props.type,
    props.type === "SIGN_UP" && props.policiesAndTermsAgreed,
  ]);

  return (
    <>
      <div className="social-login-section">
        <div className="my-4 text-center">
          <span>{lang.or}</span>
        </div>
        <div
          className="flex justify-center gap-2 h-11"
          // one drawback with google client library's
          // sign in button is that we cannot do some validations
          // before signing in the users. so validation is added
          // to the parent div here
          onClick={() => {
            if (props.type === "SIGN_UP") {
              props.validate?.();
            }
          }}
        >
          <div ref={googleBtnRef} />
        </div>
      </div>
    </>
  );
};
export default GoogleAuth;
