// @ts-nocheck
import { getClient } from "core/utility";
import { API_STATUS } from "models/auth/constants";
import { toggleLoader } from "models/loaders/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { RESET_PASSWORD_BUTTON, RESET_PASSWORD } from "./constants";
import { IS_VALID_RESET_TOKEN, RESET_PASSWORD_QUERY } from "queries/auth/auth";

const getValidToken = async (token) => {
  try {
    let client = getClient();
    return await client.mutate({
      mutation: IS_VALID_RESET_TOKEN,
      fetchPolicy: "network-only",
      variables: { token },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

const doResetPassword = async ({ input, fetchPolicy = "network-only" }) => {
  try {
    let client = getClient();
    return await client.mutate({
      mutation: RESET_PASSWORD_QUERY,
      fetchPolicy,
      variables: { input },
    });
  } catch (error) {
    return { isError: true, error };
  }
};

export function* handleGetValidToken({ params, lang }) {
  const { data, error, isError } = yield getValidToken(params.token);
  if (isError) {
    let errorMessage = lang?.thereIsProblemInTheSystemContactToAdmin;
    if (error?.graphQLErrors[0]?.message) {
      errorMessage = error.graphQLErrors[0].message;
      yield put(slice.updateValidToken({ isValidToken: { hasError: true } }));
    }
    yield put(
      triggerSnack({
        type: "error",
        title: lang?.somethingWentWrong,
        message: errorMessage,
      })
    );
    return;
  }

  yield put(slice.updateValidToken({ isValidToken: data }));
  return {
    isError,
    error,
    data,
  };
}

function* initializeReset({ payload: { params, lang } }) {
  const { token } = params;

  yield put(toggleLoader({ component: RESET_PASSWORD, isLoading: true }));

  if (token) {
    yield handleGetValidToken({ params, lang });
  }

  yield put(toggleLoader({ component: RESET_PASSWORD, isLoading: false }));
}

function* handleResetPassword({ payload: { formValues, token } }) {
  let { newPassword, confirmPassword } = formValues;
  yield put(
    toggleLoader({ component: RESET_PASSWORD_BUTTON, isLoading: true })
  );
  const { data, isError } = yield doResetPassword({
    input: { token, newPassword, confirmPassword },
  });
  if (isError) {
    yield put(slice.updateResetPassword({ status: API_STATUS.FAILED }));
  }
  yield put(slice.updateResetPassword({ status: API_STATUS.SUCCESS }));
  yield put(
    toggleLoader({ component: RESET_PASSWORD_BUTTON, isLoading: false })
  );
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.initialize, initializeReset),
    yield takeEvery(sagaActions.resetPassword, handleResetPassword),
  ];
}
