import type { CommentsProps } from "./interfaces";
import type { GetRecordComments } from "./interfaces";
import { FC, Fragment, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "atoms/Loading/Loading";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import TimeLabel from "atoms/TimeLabel/TimeLabel";
import Button from "atoms/Button/Button";
import { Textarea } from "atoms/Textarea";
import {
  ADD_DEALFLOW_COMMENT,
  GET_RECORD_COMMENTS,
} from "queries/investor/investorDealflow";

const ONE_ROW = 1;
const COMMENTS_POLL_MS = 15000;

const Comments: FC<CommentsProps> = ({ id }) => {
  const dispatch = useDispatch();
  const {
    data,
    loading: isQueryLoading,
    refetch,
  } = useQuery<GetRecordComments>(GET_RECORD_COMMENTS, {
    variables: { id },
    pollInterval: COMMENTS_POLL_MS,
  });
  const [addComment, { loading: isMutationLoading }] =
    useMutation(ADD_DEALFLOW_COMMENT);
  const [value, setValue] = useState("");

  const raiseSnack = (type: string, title: string, message: string) =>
    dispatch(triggerSnack({ type, title, message }));

  const sendComment = async () => {
    if (isMutationLoading || !value) {
      return;
    }

    try {
      await addComment({
        variables: {
          record: id,
          text: value,
        },
      });
      setValue("");
      refetch();
    } catch (error: any) {
      raiseSnack("error", "Couldn't send a comment", "Please try again");
    }
  };

  const comments = data?.dealflowRecord.comments || [];
  const isLoading = isQueryLoading || isMutationLoading;

  return (
    <div className="pb-6 text-sm">
      <div className="flex items-center mb-2 text-gray-500">
        <FontAwesomeIcon icon={["fas", "comment"]} />
        <span className="mx-2">Comments ({comments.length})</span>
        {isLoading && <Loading size="small" />}
      </div>
      <div>
        <Textarea
          placeholder="Add new comment"
          className="w-full p-2 border border-gray-300 rounded-md resize-none max-h-[16rem]"
          autoResize
          value={value}
          rows={ONE_ROW}
          onChange={({ target }) => setValue(target.value)}
        />
        <div className="flex justify-end">
          <Button
            color="primary"
            size="small"
            data-key="add-comment-dealflow"
            onClick={sendComment}
          >
            Save
          </Button>
        </div>
      </div>
      <div>
        {comments.map((comment) => (
          <div
            key={comment.id}
            className="p-2 mt-4 bg-gray-100 shadow-md rounded-xl"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  alt={comment.author?.displayName || ""}
                  src={comment.author?.logo?.small?.location}
                  className="object-cover w-6 h-6 rounded-full"
                />
                <h5 className="ml-2">{comment.author?.displayName}</h5>
              </div>
              <span className="text-xs text-gray-600">
                <TimeLabel time={comment.createdAt} />
              </span>
            </div>
            <div className="mt-4 break-all">
              {comment.text?.split("\n").map((line, idx, array) => (
                <Fragment key={idx}>
                  <span>{line}</span>
                  {idx !== array.length - 1 && <br />}
                </Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Comments;
