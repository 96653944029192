import { useMutation } from "@apollo/client";
import { setGlobalState } from "models/globalState/sagaActions";
import { getGlobalState } from "models/globalState/selectors";
import { getCohort, getUser } from "models/user/selectors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CohortApplicationModalProps } from "../CohortApplicationModal/interfaces";
import { APPLY_TO_COHORT } from "../CohortApplicationModal/queries";
import { refetchUserData } from "models/user/sagaActions";
import { useLang } from "core/lang/LangaugeSetting";

const useCohortApplicationModal = ({
  handleClose,
}: CohortApplicationModalProps) => {
  const lang = useLang();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cohort = useSelector(getCohort);
  const user = useSelector(getUser);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const isShowModal = useSelector((state) =>
    getGlobalState(state, "isCohortModalOpen")
  );

  const [applyToCohort, { loading: isApplyLoading }] =
    useMutation(APPLY_TO_COHORT);

  const handleCloseModal = () => {
    dispatch(setGlobalState({ isCohortModalOpen: false }));
    handleClose();
  };
  const handleApply = async () => {
    try {
      await applyToCohort({
        variables: {
          slug: cohort?.slug,
        },
      });
    } catch (error: any) {
      if (!user?.completedMajorSteps.includes("APPLICATION_SENT")) {
        navigate(`/${user?.type}/settings`);
      } else return setAlreadyApplied(true);
    }
    if (!isApplyLoading) {
      dispatch(refetchUserData({ lang })); // to get the updated isApplicationSent flag
      navigate(`/${user?.type}/settings`);
    }
  };

  const header =
    (cohort.isOpen ? cohort.header : cohort.closedHeader) || cohort.header;
  const description =
    (cohort.isOpen ? cohort.description : cohort.closedDescription) ||
    cohort.description;
  const isButtonEnabled =
    !cohort?.isOpen || cohort?.registrationType !== user?.type;
  const registrationType =
    cohort.registrationType === "company" ? "startups" : "investors";
  const isType = cohort.registrationType !== user?.type;

  return {
    header,
    description,
    registrationType,
    cohort,
    isShowModal,
    alreadyApplied,
    isButtonEnabled,
    isType,
    isApplyLoading,
    handleCloseModal,
    handleApply,
  };
};

export default useCohortApplicationModal;
