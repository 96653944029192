import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrencyValues } from "pages/companyProfile/utils";
import { FC, ReactNode, useRef, useState } from "react";
import Tooltip from "atoms/Tooltip/Tooltip";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import EditButton from "./EditButton";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { generateInputObject } from "pages/editStartupProfile/helpers";
import SaveAndCancelButtons from "./SaveAndCancelButtons";

interface Props {
  title: string;
  value: string | number | null | undefined;
  currencyCode?: string;
  tooltipContent?: any;
  style?: "primary" | "secondary";
  showCurrency?: boolean;
  fieldName?: string;
  inputType?: "text" | "number";
  className?: string;
}

/**
 * In this version of the component rendering content of edit mode, handling
 * state and saving data are all responsibility of the component itself.
 * Used for cases that edit mode is a simple input.
 * For rendering custom content for edit mode EditablePlaceholder component should be used
 */

const EditablePlaceholderInput: FC<Props> = ({
  value,
  title,
  currencyCode,
  tooltipContent,
  fieldName,
  style = "primary",
  showCurrency,
  inputType = "text",
  className,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleSave = () => {
    const inputValue = inputRef.current?.value;

    const parsedInputValue =
      (inputValue || inputValue === "0") && inputType === "number"
        ? parseInt(inputValue)
        : inputValue;

    if (fieldName) {
      dispatch(
        updateStartupProfile(
          generateInputObject(
            parsedInputValue || parsedInputValue === 0
              ? parsedInputValue
              : null,
            fieldName
          )
        )
      );
    }

    setIsEditMode(false);
  };

  const getEditModeContent = () => {
    return (
      <div
        className={cn(
          "flex flex-col",
          style === "secondary" ? "border-gray-400" : "border-gray-300"
        )}
      >
        <input
          type={inputType}
          className="rounded-lg min-w-0 grow border bg-white mb-2 p-1"
          ref={inputRef}
          defaultValue={value !== null ? value : undefined} // null is not assignable to input
        />

        <SaveAndCancelButtons
          onSave={handleSave}
          onCancel={() => setIsEditMode(false)}
          className="self-end"
        />
      </div>
    );
  };

  const getIntValue = (value: string | number) => {
    if (typeof value === "number") return value;
    return parseInt(value);
  };

  const getContent = (value: string | number) => {
    return (
      <div>
        <span className={cn("text-base", style === "primary" && "font-bold")}>
          {showCurrency && currencyCode
            ? formatCurrencyValues(getIntValue(value), currencyCode)
            : value}
        </span>
      </div>
    );
  };

  const getNoContentplaceholder = () => (
    <div
      className="cursor-pointer flex items-center"
      onClick={() => setIsEditMode(true)}
    >
      <FontAwesomeIcon
        icon={["fal", "plus-circle"]}
        className="mr-2 text-aqua-400"
      />
      <span className="text-aqua-400 text-sm">Add</span>
    </div>
  );

  return (
    <div
      className={cn(
        "rounded-md p-2",
        style === "primary" && "bg-white",
        className
      )}
    >
      <div className="flex justify-between">
        <p
          className={cn(
            "inline-flex gap-x-1 items-center whitespace-nowrap text-sm mb-2",
            style === "secondary" && "font-bold"
          )}
        >
          <span>{title}</span>
          {tooltipContent && (
            <Tooltip content={tooltipContent}>
              <HelpIcon className="h-4" />
            </Tooltip>
          )}
        </p>
        {!isEditMode && (value || value === 0) && (
          <EditButton onClick={() => setIsEditMode(true)} size="small" />
        )}
      </div>
      {isEditMode ? (
        getEditModeContent()
      ) : (
        <>
          {!!value || value === 0
            ? getContent(value)
            : getNoContentplaceholder()}
        </>
      )}
    </div>
  );
};

export default EditablePlaceholderInput;
