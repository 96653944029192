import { createAction } from "@reduxjs/toolkit";

export const getBusinessTiming = createAction(
  "companySettings/business-timing/fetch"
);

export const saveBusinessTiming = createAction(
  "companySettings/business-timing/save",
  (payload) => ({
    payload,
  })
);
