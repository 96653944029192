import { useCallback, useEffect, useRef, useState } from "react";

const useSvg = () => {
  const ref = useRef<SVGSVGElement>();
  const [box, setBox] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const handleRef = useCallback((node: SVGSVGElement | null) => {
    if (node) {
      const { width, height } = node.getBoundingClientRect();

      setBox((state) => ({ ...state, width, height }));
      ref.current = node;
    }
  }, []);

  useEffect(() => {
    const listener = () => {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();

        setBox((state) => ({ ...state, width, height }));
      }
    };

    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const viewBox = `${box.x} ${box.y} ${box.width} ${box.height}`;

  return {
    handleRef,
    box,
    viewBox,
  };
};

export default useSvg;
