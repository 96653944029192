import { FC, useEffect, useMemo, useState } from "react";
import { useSvg } from "../../hooks";

const TICK_SIZE = 8;
const GROUP_LENGTH = 2;
const GROUPS_SIZE = 6;
const SMOOTH_FACTOR = 0.07;
const GROUP_SMOOTH_STEP = 0.1;

const drawTick = (x: number, y: number, s = TICK_SIZE) =>
  `M ${x - s} ${y - s} l ${s} ${s} l ${s} ${-s}`;

const split = <T extends any>(array: T[], length: number) => {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += length) {
    result.push(array.slice(i, i + length));
  }

  return result;
};

const TicksDecor: FC = () => {
  const { handleRef, viewBox, box } = useSvg();
  const [transforms, setTransforms] = useState<string[]>([]);

  const w = (p: number) => box.width * p;

  const h = (p: number) => box.height * p;

  const paths = useMemo(
    () => [
      [drawTick(w(0.2), h(0.28)), "rotate(68) scale(.6)"],
      [drawTick(w(0.85), h(0.66)), "rotate(12) scale(.6)"],

      [drawTick(w(0.7), h(0.38)), "rotate(148) scale(.7)"],
      [drawTick(w(0.16), h(0.56)), "rotate(128) scale(.7)"],

      [drawTick(w(0.22), h(0.66)), "rotate(91) scale(.8)"],
      [drawTick(w(0.74), h(0.56)), "rotate(91) scale(.8)"],

      [drawTick(w(0.12), h(0.74)), "rotate(148) scale(.85)"],
      [drawTick(w(0.1), h(0.38)), "rotate(148) scale(.85)"],

      [drawTick(w(0.8), h(0.48)), "rotate(64) scale(.9)"],
      [drawTick(w(0.25), h(0.5)), "rotate(218) scale(.9)"],

      [drawTick(w(0.32), h(0.6)), "rotate(148)"],
      [drawTick(w(0.69), h(0.68)), "rotate(122)"],
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [box.width, box.height]
  );

  const groups = split(paths, GROUP_LENGTH);

  useEffect(() => {
    const handler = ({ clientX, clientY }: MouseEvent) => {
      const cx = window.innerWidth / 2;
      const cy = window.innerHeight / 2;

      const tx = -(clientX - cx) * SMOOTH_FACTOR;
      const ty = -(clientY - cy) * SMOOTH_FACTOR;

      const getTransform = (index: number) =>
        `translate(${tx * (index + 1) * GROUP_SMOOTH_STEP} ${
          ty * (index + 1) * GROUP_SMOOTH_STEP
        })`;

      setTransforms(
        Array.from(
          {
            length: GROUPS_SIZE,
          },
          (_, index) => getTransform(index)
        )
      );
    };

    window.addEventListener("mousemove", handler);

    return () => {
      window.removeEventListener("mousemove", handler);
    };
  }, []);

  const renderTick = ([d, transform]: string[], index: number) => (
    <path
      key={index}
      d={d}
      style={{ transformBox: "fill-box" }}
      transform={transform}
      fill="none"
      stroke="#9ACECF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );

  return (
    <svg ref={handleRef} viewBox={viewBox} className="w-full h-full">
      {groups.map((paths, index) => (
        <g key={index} transform={transforms[index] ?? ""}>
          {paths.map(renderTick)}
        </g>
      ))}
    </svg>
  );
};

export default TicksDecor;
