import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { Price } from "gql/graphql";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  value: Array<Price | null> | null | undefined;
  note: string | null | undefined;
  currencyCode: string;
}

const ValueOfContractsInPipeline: FC<Props> = ({
  value,
  note,
  currencyCode,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const valueData = value?.[0];

  const renderContent = () => {
    return (
      <div>
        {valueData?.amount && valueData.currency ? (
          <div>
            {formatCurrencyValues(Number(valueData.amount), currencyCode)}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          annualValueContractsPipeline: {
            amount: inputValue,
            // this won't affect functionality but still good to update the DB
            currency: currencyCode,
          },
          annualValueContractsPipelineNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={valueData?.amount}
        noteDefaultValue={note}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="Annual value of contracts in the pipeline"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={valueData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default ValueOfContractsInPipeline;
