import { FC } from "react";
import { useDispatch } from "react-redux";
import Button from "atoms/Button/Button";
import { setGlobalState } from "models/globalState/sagaActions";

const PitchDeckError: FC = () => {
  const dispatch = useDispatch();
  const onHelpClicked = () => {
    dispatch(
      setGlobalState({
        showSupportPanel: true,
      })
    );
  };

  return (
    <>
      <span className="mt-4 text-center text-red-500">
        Sorry, I was unable to read relevant information in this document.
        Please try again. If you still get an error, contact our product team
        below and we will take care of it. Please attach the pitch deck in the
        form.
      </span>
      <Button
        size="small"
        color="secondary"
        onClick={onHelpClicked}
        className="my-2 font-bold p-2 rounded-lg"
      >
        Contact
      </Button>
    </>
  );
};

export default PitchDeckError;
