import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  tractionSummary: string | undefined | null;
}

const TractionSummary: FC<Props> = ({ tractionSummary }) => {
  return (
    <EditablePlaceholderText
      title="Traction Summary"
      value={tractionSummary}
      fieldName="traction.summary"
      addValueTitle="Summarise your traction"
      inputPlaceholder="Enter your traction summary"
    />
  );
};

export default TractionSummary;
