import type { ExpandPanelProps } from "./interfaces";
import type { FC } from "react";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

const ExpandPanel: FC<ExpandPanelProps> = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <Disclosure.Button className="flex items-center justify-between w-full px-4 py-1 mt-4 text-gray-600 bg-gray-100 rounded-lg">
          <span
            className={cn("transition duration 100", open && "text-aqua-500")}
          >
            {title}
          </span>
          <FontAwesomeIcon
            className={cn(
              "transition duration 100",
              open && "rotate-180 text-aqua-500"
            )}
            icon={["fas", "chevron-down"]}
          />
        </Disclosure.Button>
        <Disclosure.Panel className="mt-1 bg-gray-100 rounded-lg">
          {children}
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default ExpandPanel;
