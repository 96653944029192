import { FC, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { DowngradePlanProps } from "../interfaces";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Button from "atoms/Button/Button";
import { UPDATE_PLAN } from "queries/general/account/billing";
import { PlanPrice } from "pages/plans/interfaces";
import {
  mixpanelClickDowngradePlan,
  mixpanelDownGradePlan,
} from "core/mixpanel/Mixpanel";

const DowngradePlan: FC<DowngradePlanProps> = ({
  currentPrice,
  currentPlan,
  allPlans,
  refetchSubscription,
}) => {
  const dispatch = useDispatch();
  const [isModelOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    mixpanelClickDowngradePlan();
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  const [downgradePlan] = useMutation(UPDATE_PLAN, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Plan Successfully downgraded",
        })
      );
      refetchSubscription();
    },
    onError: () => {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Something went wrong",
          message: "We could not downgrade",
        })
      );
    },
  });

  const pickedPlan = allPlans?.find((plan) => plan.id === currentPlan.id);

  const isPromptToMonthly = currentPrice?.interval === "YEARLY";

  const getPlanToDowngrade = () => {
    let planToDowngrade: PlanPrice | undefined;

    if (isPromptToMonthly) {
      planToDowngrade = pickedPlan?.prices.find(
        (price) => price.interval === "MONTHLY"
      );
    }

    return planToDowngrade;
  };

  const handleDowngrade = () => {
    handleClose();

    const planToDowngrade = getPlanToDowngrade();

    if (!planToDowngrade) {
      return;
    }

    downgradePlan({
      variables: {
        newPriceId: planToDowngrade.id,
      },
    });
    mixpanelDownGradePlan();
  };

  const planToDowngrade = getPlanToDowngrade();

  if (!planToDowngrade) return null;

  return (
    <>
      <button onClick={handleOpen} className="text-[#0077D1] text-sm mt-3">
        Switch to monthly
      </button>
      <Transition appear show={isModelOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {isPromptToMonthly && (
                  <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <p className="pb-2 font-medium text-black">
                      Switch to monthly subscription?
                    </p>
                    <div className="mt-3 text-sm text-gray-500">
                      <p>
                        Upon the completion of your annual subscription, your
                        subscription will switch to the monthly plan. Please
                        note that the monthly plan does not include a two-month
                        free discount.
                      </p>
                      <p className="mt-2">
                        Are you sure you want to proceed with switching to the
                        monthly plan?
                      </p>
                    </div>
                    <div className="flex justify-end mt-8">
                      <Button
                        color="transparent"
                        size="small"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        className="ml-2"
                        onClick={handleDowngrade}
                      >
                        Switch to monthly
                      </Button>
                    </div>
                  </Dialog.Panel>
                )}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DowngradePlan;
