import { createAction } from "@reduxjs/toolkit";

export const initializeEditStartupProfilePage = createAction(
  "editProfile/initializeEditStartupProfilePage",
  (payload) => ({
    payload,
  })
);

export const updateStartupProfile = createAction(
  "editProfile/updateStartupProfile",
  (payload) => ({
    payload,
  })
);
