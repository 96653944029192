import type { Payload } from "./interfaces";
import { getAuthorId } from "./author";
import { TouchType } from "./type";
import { restApiBaseUrl } from "core/config";

const ANALYTICS_TOUCH_URL = `${restApiBaseUrl}/a/touch`;
const AUTHOR_ID_HEADER_NAME = "X-Actor-Id";
const TIMEZONE_HEADER_NAME = "X-TZ-Offset";
const authorId = getAuthorId();
const timezone = String(-new Date().getTimezoneOffset());

export const touch = async <T extends TouchType>(
  type: T,
  payload: Payload<T>
) => {
  try {
    await fetch(ANALYTICS_TOUCH_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        [AUTHOR_ID_HEADER_NAME]: authorId,
        [TIMEZONE_HEADER_NAME]: timezone,
      },
      body: JSON.stringify({ type, payload }),
    });
  } catch (error: any) {}
};
