import type { TeaserStepProps } from "../interfaces";
import { FC, useRef } from "react";
import BackButton from "../BackButton";
import TeamsForm from "pages/companySettings/steps/TeamsStep/TeamsForm";

const Team: FC<TeaserStepProps> = ({ onNext, onPrev }) => {
  const foundersRef = useRef<any[]>([]);

  const handleNext = () =>
    onNext({
      team: {
        founders: foundersRef.current,
      },
    });

  return (
    <div className="w-full h-full px-2 py-8 md:px-16">
      <BackButton onAction={onPrev} />
      <h2 className="text-xl text-center">Founding team</h2>
      <div className="my-8 font-bold text-center text-gray-500">
        We'd love to hear more about your founding team.
        <br /> This is a critical success factor that investors look closely at
      </div>
      <TeamsForm
        inline
        onWatch={(values) => (foundersRef.current = values.founders)}
      />
      <div className="flex justify-end mt-8 pb-4">
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Team;
