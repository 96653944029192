import { FC, useEffect, useRef, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import { BusinessCategory } from "gql/graphql";
import { BUSINESS_CATEGORY } from "core/consts";
import ButtonGroup from "molecules/Form/ButtonGroup";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";
import { useLang } from "core/lang/LangaugeSetting";

interface Props {
  categories: BusinessCategory[] | undefined | null;
  categoriesNote: string | undefined | null;
}

const Product: FC<Props> = ({ categories, categoriesNote }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [categoryNoteError, setCategoryNoteError] = useState(false);

  useEffect(() => {
    setSelected(categories || []);
  }, [categories]);

  const { lang } = useLang();
  const dispatch = useDispatch();
  const noteRef = useRef<HTMLTextAreaElement>(null);

  const onCategoryChange = (data: string[]) => {
    setSelected(data);
  };

  const onCancel = () => {
    setSelected(categories || []);
    setIsEditMode(false);
  };

  const onSave = () => {
    if (noteRef.current && !noteRef.current.value) {
      setCategoryNoteError(true);
      return;
    }

    dispatch(
      updateStartupProfile({
        business: {
          categories: selected,
          categoriesNote: noteRef.current?.value,
        },
      })
    );
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="mt-2">
        <ButtonGroup
          id="categories"
          options={Object.entries(BUSINESS_CATEGORY)}
          onChange={onCategoryChange}
          limit={3}
          type="company"
          className="capitalize"
          defaultValue={categories || undefined}
        />
        {selected?.includes("OTHER" as BusinessCategory) && (
          <>
            <textarea
              rows={3}
              id="businessCategoryNote"
              className={`form-textarea block mt-2 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                categoryNoteError
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : ""
              }`}
              placeholder={lang.addNoteForBusinessType}
              defaultValue={categoriesNote || ""}
              maxLength={200}
              ref={noteRef}
            />
            {categoryNoteError && (
              <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
            )}
          </>
        )}
        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right mt-2"
        />
      </div>
    );
  };

  const renderContent = () => (
    <div>
      <div>{categories?.map((i) => BUSINESS_CATEGORY[i]).join(", ")}</div>
      {categories?.includes("OTHER" as BusinessCategory) && (
        <p>{categoriesNote}</p>
      )}
    </div>
  );

  return (
    <EditablePlaceholder
      title="Product"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={
        categories && categories.length > 0 ? renderContent : undefined
      }
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default Product;
