// @ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SidebarContent } from "ui/elements/PanelLayout.styles";

import { animation } from "ui/animations";
import { useLang } from "core/lang/LangaugeSetting";
import { getSocialNetworks } from "./selectors";
import * as sagaActions from "./sagaActions";
import Loading from "atoms/Loading/Loading";
import SocialNetworksForm from "./SocialNetworksForm";

export default () => {
  const { lang } = useLang();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sagaActions.getSocialNetworks());
  }, []);

  const data = useSelector(getSocialNetworks);

  return (
    <SidebarContent
      variants={animation}
      animate="show"
      initial="hidden"
      exit="hidden"
    >
      {!data ? <Loading fullPage /> : <SocialNetworksForm />}
    </SidebarContent>
  );
};
