import { gql } from "@apollo/client";

export const SAVE_PROFILE_INFO = gql`
  mutation saveCompanyProfileNew($input: StartupProfileStepInputNew!) {
    saveCompanyProfileNew(input: $input) {
      id
      displayName
      phone
      orgNumber
    }
  }
`;
