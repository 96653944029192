import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  customState: {
    isDealFlowView: "dealroom",
    isTableView: true,
    start: 0,
    registeredListing: [],
    membersList: [],
    companies: [],
    loading: false,
    selectedCohorts: [],
  },
};

export const name = "dealflow";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateCustomState: (state, { payload }) => ({
      ...state,
      customState: {
        ...state.customState,
        ...payload,
      },
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const { updateCustomState } = slice.actions;
