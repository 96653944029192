import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { UserRole } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  user: any;
  userRole: UserRole;
  toggleSideNavMenu: () => void;
  logo?: string;
}

const SideNavLogo: FC<Props> = ({
  userRole,
  logo,
  user,
  toggleSideNavMenu,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between border-t border-gray-300 p-3 mt-2">
      <button
        onClick={() => {
          if (userRole === UserRole.STARTUP) {
            navigate("/profile");
          } else {
            toggleSideNavMenu();
          }
        }}
        className="flex items-center max-w-xs text-sm text-white rounded-full shrink-0 focus:outline-none focus:shadow-solid"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
      >
        {logo ? (
          <img
            className="object-cover w-10 h-10 rounded-full"
            src={logo}
            alt=""
          />
        ) : (
          <span className="inline-flex items-center justify-center object-cover w-10 h-10 bg-gray-500 rounded-full">
            <span className="font-medium leading-none text-white">
              {user?.displayName?.charAt(0)?.toUpperCase() || ""}
            </span>
          </span>
        )}
      </button>

      <div className="text-[#0B0D18] font-medium rounded-md hover:bg-gray-50/50 mx-2 max-w-[7rem] overflow-hidden text-ellipsis whitespace-nowrap">
        {user.displayName}
      </div>

      <button
        className="w-10 h-10 flex justify-center items-center rounded-full hover:bg-[#F5F5F5] cursor-pointer overflow-hidden"
        onClick={() => toggleSideNavMenu()}
      >
        <FontAwesomeIcon icon={["fas", "ellipsis-h"]} color="#0b0d189e" />
      </button>
    </div>
  );
};

export default SideNavLogo;
