import { useMutation } from "@apollo/client";
import { EMAIL_PATTERN, REMEMBER_ME_COOKIE_KEY } from "core/consts";
import { mixpanelSignIn, mixpanelSignUp } from "core/mixpanel/Mixpanel";
import { Cohort } from "gql/graphql";
import useCookie from "hooks/useCookie";
import { getAlreadyRegistered } from "models/auth/selectors";
import { refetchUserData } from "models/user/sagaActions";
import { getCohort } from "models/user/selectors";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { LOGIN_MUTATION, SIGN_UP_MUTATION } from "queries/auth/auth";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PASSWORD_RULES, getEmailFromRegister } from "../utils";

const useCohortForm = (onNext: (payload: any) => void) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { getCookie, setCookie } = useCookie();
  const [initialEmail] = useState(getEmailFromRegister);
  const { email, active } = getCookie(REMEMBER_ME_COOKIE_KEY);
  const cohort: Cohort = useSelector((state) => getCohort(state));
  const [isPassword, setIsPassword] = useState(true);
  const [emailValue, setEmailValue] = useState(email || initialEmail);
  const [userType, setUserType] = useState("");
  const [rememberMe, setRememberMe] = useState(active);
  const [password, setPassword] = useState("");
  const [isAgreed, setIsAgreed] = useState(false);
  const [hasExistingAccount, setHasExistingAccount] = useState(false);
  const alreadyRegistered = useSelector((state) => getAlreadyRegistered(state));
  const [signUp, { loading: isRegisterLoading }] =
    useMutation(SIGN_UP_MUTATION);
  const [signIn, { loading: isLoginLoading, error }] = useMutation(
    LOGIN_MUTATION,
    {
      onCompleted: (data) => {
        mixpanelSignIn(data?.signInV2);
      },
    }
  );

  const isLoading = isRegisterLoading || isLoginLoading;

  const triggerError = (message: string | undefined | null) =>
    dispatch(
      triggerSnack({
        type: "error",
        title: "Error occurred",
        message: message || "Something went wrong, please try again later",
      })
    );

  useEffect(() => {
    if (cohort?.registrationType) {
      setUserType(
        cohort?.registrationType === "company" ? "COMPANY" : "INVESTOR"
      );
    }
  }, [cohort?.registrationType]);

  const onSubmit = async () => {
    try {
      await signIn({
        variables: {
          email: emailValue,
          password: password,
          referrer: cohort?.slug,
        },
      });

      if (rememberMe) {
        setCookie(REMEMBER_ME_COOKIE_KEY, { email, active: true });
      } else {
        setCookie(REMEMBER_ME_COOKIE_KEY, {});
      }

      dispatch(refetchUserData({}));
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleRegister = async () => {
    if (isLoading) {
      return;
    }

    try {
      await signUp({
        variables: {
          input: {
            name: "",
            email: emailValue,
            password,
            signUpAs: userType,
            referrer: cohort?.slug,
            policiesAndTermsAgreed: isAgreed,
          },
        },
      });

      await signIn({
        variables: {
          email: emailValue,
          password: password,
        },
      });

      mixpanelSignUp(email, userType, cohort?.slug);
      onNext({ type: userType === "COMPANY" ? "startup" : "investor" });
    } catch (error: any) {
      triggerError("Email is taken. Try to login instead");
      // setIsApplyToCohort(false);
    }
  };

  const handleOnGoogleSignUpSuccess = () => {
    dispatch(refetchUserData({}));
  };

  const cohortErrorType = userType === "COMPANY" ? "startups" : "investors";

  const cohortDescription =
    (cohort?.isOpen ? cohort?.description : cohort?.closedDescription) ||
    cohort?.description;

  const isRulesSatisfied = PASSWORD_RULES.every(([regexp]) =>
    password.match(regexp)
  );

  const isEmailValid = Boolean(emailValue.match(EMAIL_PATTERN));

  const isRegisterDisabled =
    !isRulesSatisfied || !isEmailValid || (!hasExistingAccount && !isAgreed);

  return {
    emailValue,
    password,
    isPassword,
    isAgreed,
    userType,
    cohort,
    rememberMe,
    isLoading,
    cohortErrorType,
    params,
    error,
    alreadyRegistered,
    isEmailValid,
    isRegisterDisabled,
    cohortDescription,
    hasExistingAccount,
    setHasExistingAccount,
    setIsPassword,
    setEmailValue,
    setRememberMe,
    setPassword,
    setIsAgreed,
    onSubmit,
    handleRegister,
    handleOnGoogleSignUpSuccess,
  };
};

export default useCohortForm;
