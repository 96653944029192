import { ReactNode } from "react";

export enum SubscriptionUserType {
  Startup = "STARTUP",
  Angel = "ANGEL",
  VC = "VC",
  Accelerator = "ACCELERATOR",
}
export interface HeaderProps {
  title: string;
  description: string;
}
export interface PlanFeature {
  id: string;
  title: string;
  description: string;
  note: string;
}

export interface PlanContext {
  billingFrequencies: BillingFrequency[];
  selectedFrequency: BillingFrequency;
  toggleBillingFrequency: (frequency: BillingFrequency) => void;
}

export type BillingFrequency = "MONTHLY" | "YEARLY" | "THREE_MONTHLY" | "SIX_MONTHLY";

export interface PlanPrice {
  id: string;
  price: number;
  priceBeforeDiscount: number;
  discount: number; 
  currency: string;
  interval: BillingFrequency;
}

export interface Plan {
  id: string;
  key: string;
  title: string;
  description: string;
  userType: SubscriptionUserType;
  prices: PlanPrice[];
  currency: string;
  tagline: string;
  features: PlanFeature[];
  isMostPopular?: boolean;
}

export interface Subscription {
  id: string;
  plan: Plan;
  price: PlanPrice;
  status: string;
  nextBillingDate: Date;
  cancelAtPeriodEnd: boolean;
  customerId: string;
}

export interface PlanProps {
  plan: Plan;
}

export interface PlansProps {
  plans: Plan[];
}

export interface PlanToggleButtonProps {
  billingFrequency: BillingFrequency;
  toggle: (frequency: BillingFrequency) => void;
  isSelected?: boolean;
}

export interface PlanHeaderProps extends Omit<Plan, "features"> {}

export interface PlanFeaturesProps {
  features: PlanFeature[];
  renderFeature: (feature: PlanFeature) => ReactNode;
  className?: string;
}

export interface PlanFeatureProps {
  feature: PlanFeature;
}

export interface ChoosePlanButtonProps {
  checkout: () => void;
  isLoading: boolean;
  isMostPopular?: boolean;
}
