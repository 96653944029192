import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "atoms/Tooltip/Tooltip";
import cn from "classnames";
import { FC, useState } from "react";
import CompetitorsSkeleton from "../Skeleton";
import { ColumnRenders, DeepDiveTableProps, RowRenders } from "./interfaces";
import { COLUMNS_DEEP_DIVE, getDomain, getSourceDomains } from "./utils";
import HorizontalScroll from "./HorizontalScroll";
import Loading from "atoms/Loading/Loading";

const isMobile = window.innerWidth < 768;

const DeepDiveTable: FC<DeepDiveTableProps> = ({
  deepDivedCompetitors,
  isDeepDiveLoading,
}) => {
  const [isShowMore, setIsShowMore] = useState(false);
  if (
    !deepDivedCompetitors ||
    (deepDivedCompetitors.length === 0 && !isDeepDiveLoading)
  )
    return null;

  if (isDeepDiveLoading && deepDivedCompetitors.length === 0)
    return (
      <div className="my-2">
        <CompetitorsSkeleton />
      </div>
    );

  return (
    <div className="relative border-t border-l border-r border-gray-200 rounded-md mt-4 mb-6 max-w-4xl lg:max-w-full">
      <HorizontalScroll>
        <div
          className={cn(
            isShowMore || isMobile ? "max-h-full" : "max-h-[25rem]",
            "relative transition-all duration-200 ease-in-out overflow-y-hidden"
          )}
        >
          <table className="bg-aqua-50/50 rounded-lg no-hover w-full">
            <tbody className="deep-dive-table">
              <ColumnHeadersDeepDive
                competitors={deepDivedCompetitors}
                loading={isDeepDiveLoading}
              />
              {COLUMNS_DEEP_DIVE.map((column) => (
                <RowsDeepDive
                  key={column.rowName}
                  competitors={deepDivedCompetitors}
                  rowName={column.rowName}
                  getValue={column.getValue}
                  competitorsLength={deepDivedCompetitors.length}
                />
              ))}
            </tbody>
          </table>
        </div>
      </HorizontalScroll>
      <button
        className="text-aqua-500 w-full text-center border-b border-aqua-200 bg-gradient-to-b from-white to-aqua-50/50 py-1 rounded-md hidden md:block"
        onClick={() => setIsShowMore(!isShowMore)}
      >
        {isShowMore ? "Show less" : "Show more"}
      </button>
    </div>
  );
};

export default DeepDiveTable;

const ColumnHeadersDeepDive: FC<ColumnRenders> = ({ competitors, loading }) => {
  return (
    <tr className="bg-white border-b border-gray-300">
      <td className="text-left align-top py-3 font-normal px-6 text-sm">
        {loading && <Loading />}
      </td>
      {competitors?.map((comp) => (
        <td
          className="font-semibold py-3 px-6 text-left align-top text-sm min-w-[20rem]"
          key={comp.id}
        >
          <div className="flex">
            {comp?.logo?.large?.location && (
              <img
                src={comp.logo?.large?.location}
                className="h-14 w-14 rounded-md mr-2"
              />
            )}
            <div className="flex flex-col">
              <span className="text-lg">{comp.name}</span>
              <div className="items-center text-center flex flex-row gap-2 mt-1">
                {comp.linkedInCompany?.companyUrl && (
                  <Tooltip
                    content={
                      getSourceDomains(comp.linkedInCompany.companyUrl) || ""
                    }
                  >
                    <a
                      type="linkedin"
                      href={
                        comp.linkedInCompany?.companyUrl.includes("https")
                          ? comp.linkedInCompany?.companyUrl
                          : `https://${comp.linkedInCompany?.companyUrl}`
                      }
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        icon={["fab", "linkedin"]}
                        className="text-blue-400 mb-1"
                        size="lg"
                      />
                    </a>
                  </Tooltip>
                )}
                {comp.website && (
                  <Tooltip content={getDomain(comp.website)}>
                    <a type="website" href={comp.website} target="_blank">
                      <FontAwesomeIcon
                        icon={["fas", "globe"]}
                        className="text-aqua-400 hover:text-aqua-100 mb-1"
                        size="lg"
                      />
                    </a>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </td>
      ))}
    </tr>
  );
};

const RowsDeepDive: FC<RowRenders> = ({
  competitors,
  rowName,
  getValue,
  competitorsLength,
}) => {
  return (
    <tr className="border-b border-gray-200">
      <td
        className={cn(
          competitors.length <= 3 ? "" : "lg:whitespace-nowrap",
          competitors.length === 1 && "lg:whitespace-nowrap",
          "text-left align-top py-2 px-6 text-sm text-gray-500"
        )}
      >
        {rowName}
      </td>

      {competitors?.map((comp) => (
        <td
          className={cn(
            competitors.length <= 3 ? "" : "w-[20rem]",
            competitors.length === 1 && "w-full",
            "h-[2rem] overflow-hidden py-2 px-6 text-left align-top text-sm no-hover"
          )}
          key={`${comp.id} ${rowName}`}
        >
          {getValue(comp, competitorsLength)}
          {rowName === "Revenue (in thousands, USD)" && (
            <div className={cn(competitorsLength === 1 && "w-1/2")}>
              {comp?.finances?.pitchbookUrl &&
                comp.finances.revenue &&
                comp.finances.revenue?.length > 0 && (
                  <div className="flex flex-row justify-between text-sm mt-2">
                    <span>Sources: </span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={comp.finances.pitchbookUrl}
                      className="text-aqua-400 text-right"
                    >
                      PitchBook
                    </a>
                  </div>
                )}
            </div>
          )}
          {rowName === "Fundraising" && (
            <div className={cn(competitorsLength === 1 && "w-1/2")}>
              {(comp?.finances?.pitchbookUrl || comp.finances?.crunchbaseUrl) &&
                comp.finances?.fundingRounds &&
                comp.finances?.fundingRounds.length > 0 && (
                  <div className="flex flex-row justify-between text-sm mt-2">
                    <span>Sources: </span>
                    <div className="flex flex-col">
                      {comp.finances?.crunchbaseUrl && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={comp.finances.crunchbaseUrl}
                          className="text-aqua-400 text-right"
                        >
                          crunchbase
                        </a>
                      )}
                      {comp?.finances?.pitchbookUrl && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={comp.finances.pitchbookUrl}
                          className="text-aqua-400 text-right"
                        >
                          PitchBook
                        </a>
                      )}
                    </div>
                  </div>
                )}
            </div>
          )}
        </td>
      ))}
    </tr>
  );
};
