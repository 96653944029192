import React from "react";
import CollapsiblePage from "./CollapsiblePage";

const AffinityIntegrationDocs = () => {
  return (
    <CollapsiblePage title="Affinity Integration" className="my-2">
      <div>Coming soon...</div>
    </CollapsiblePage>
  );
};

export default AffinityIntegrationDocs;
