import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClickOutside from "atoms/ClickOutside";
import Loading from "atoms/Loading/Loading";
import Tooltip from "atoms/Tooltip/Tooltip";
import axios from "axios";
import { restApiBaseUrl } from "core/config";
import { useLang } from "core/lang/LangaugeSetting";
import useClickOutside from "hooks/useOnClickOutside";
import { withRouter } from "hooks/withRouter";
import { getUser } from "models/user/selectors";
import { triggerSnack } from "organisms/Snack/sagaActions";
import ViewSettings from "pages/companyProfile/components/shared/Navbar/ViewSettings";
import { useEffect, useMemo, useRef, useState, createRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { animation } from "ui/animations";
import { getFormattedDate, getTimeSince } from "utils/generalUtils";
import ExpireScreen from "../Components/ExpireScreen";
import GraphComponent from "../Components/GraphComponent";
import { PrivateLink, SectionAnimation, WhiteCard } from "../MySpace.style";
import cn from "classnames";
import { GET_IDENTIFIED_VIEWS } from "queries/startup/myspace";
import AnalyticsActivationWrapper from "organisms/Activation/Analytics/AnalyticsActivationWrapper";

const setOpen = () => {};

const LIMIT = 20;
const SORT_BY = "asc";
const isMobile = window.matchMedia("(max-width: 768px)").matches;

const ProfileAnalytics = (props) => {
  const { lang } = useLang();
  const [isViewSettingsModalOpen, setIsViewSettingsModalOpen] = useState(false);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [copy, setCopy] = useState();
  const [privateList, setPrivateList] = useState([]);
  const [createInput, setCreateInput] = useState({
    linkName: "",
    createLoading: false,
    isCreatePrivateLink: false,
  });
  const { data: identifiedViewsData, loading: isIdentifiedViewsLoading } =
    useQuery(GET_IDENTIFIED_VIEWS);

  const [visitorData, setVisitorData] = useState({ visitors: [] });
  const [privateListData, setPrivateListData] = useState({
    data: [],
    sortBy: SORT_BY,
    sortKey: "",
  });
  const [loading, setLoading] = useState(false);

  const useMemoRef = useRef(0);
  const incrementUseMemoRef = () => useMemoRef.current++;
  const memoizedValue = useMemo(() => incrementUseMemoRef(), [privateList]);

  const [filterList, setFilterList] = useState({ data: [] });

  const ref = createRef();
  useClickOutside(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    getPrivateLinksList(0, privateListData.sortBy, privateListData.sortKey);
    seachPrivateLink(0);
  }, []);

  const handleChecked = (selected, id, value) => {
    var data = filterList?.data;
    for (let i in data) {
      if (selected === "radio" && value !== "private_link") {
        data[i].selected = false;
      } else if (selected !== "radio" && id === parseInt(i)) {
        data[i].selected = selected;
      }
    }
    setFilterList({
      ...filterList,
      data,
    });
  };

  const seachPrivateLink = async (start) => {
    setLoading("searchLoading");
    axios
      .post(`${restApiBaseUrl}/share/UserVisitStats`, {
        userId: user?._id,
        isPrivate: true,
        start: start,
        limit: LIMIT,
      })
      .then((response) => {
        var selectedList = filterList?.data?.filter((link) => link.selected);
        var list = [];
        if (response?.data?.success) {
          response?.data?.data?.forEach((item) => {
            var selected = false;
            selectedList.forEach((link) => {
              if (link.value === item.link) {
                selected = true;
              }
            });
            list.push({ value: item.link, text: item.name, selected });
          });
        } else {
          response?.data?.data?.forEach((item) => {
            var selected = false;
            list.push({ value: item.link, text: item.name, selected });
          });
          list = [...filterList.data, ...list];
        }
        setFilterList({
          ...filterList,
          ...response?.data,
          data: list,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          triggerSnack({
            type: "error",
            title: lang.somethingWentWrong,
            message: lang.weCouldNotLoadData,
          })
        );
      });
  };

  const createPrivateLink = (e) => {
    e.preventDefault();
    setCreateInput({
      ...createInput,
      createLoading: true,
    });
    axios
      .post(`${restApiBaseUrl}/share/updateUserVisits`, {
        name: createInput.linkName,
        userId: user?._id,
        isPublic: false,
      })
      .then((response) => {
        if (response?.data?.success) {
          var data = privateListData?.data;
          var filterData = filterList?.data;
          data.unshift(response?.data?.data);
          filterData.unshift({
            value: response?.data?.data?.link,
            text: response?.data?.data?.name,
            selected: false,
          });
          document.getElementById("create-link").blur();
          setCreateInput({
            ...createInput,
            isCreatePrivateLink: false,
            linkName: "",
          });
          setPrivateListData({
            ...privateListData,
            totalCount: privateListData?.totalCount
              ? privateListData.totalCount + 1
              : 1,
            data,
          });

          setFilterList({
            ...filterList,
            data: filterData,
          });

          dispatch(
            triggerSnack({
              type: "success",
              title: lang.created,
              message: lang.linkCreatedSuccessfully,
            })
          );
        } else {
          setCreateInput({
            ...createInput,
            linkName: createInput.linkName,
          });
          dispatch(
            triggerSnack({
              type: "error",
              title: lang.somethingWentWrong,
              message: response?.data?.message,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          triggerSnack({
            type: "error",
            title: lang.somethingWentWrong,
            message: lang.weCouldNotLoadData,
          })
        );
      });
  };

  const getPrivateLinksList = async (start, sortBy, sortKey) => {
    var body = {
      userId: user?._id,
      isPrivate: true,
      start: start,
      limit: LIMIT,
    };
    if (sortKey) {
      body["sortKey"] = sortKey;
      body["sortBy"] = sortBy;
    }
    setLoading("privateListLoading");
    axios
      .post(`${restApiBaseUrl}/share/UserVisitStats`, body)
      .then((response) => {
        if (response?.data?.success) {
          var visits = [...response?.data?.data];
          if (start > 0) {
            visits = [...privateListData?.data, ...response?.data?.data];
          }
          setPrivateListData({
            ...privateListData,
            ...response?.data,
            sortBy,
            sortKey,
            data: visits,
          });
        } else {
          dispatch(
            triggerSnack({
              type: "error",
              title: lang.somethingWentWrong,
              message: lang.weCouldNotLoadData,
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          triggerSnack({
            type: "error",
            title: lang.somethingWentWrong,
            message: lang.weCouldNotLoadData,
          })
        );
      });
  };

  const handleSort = (sortKey) => {
    const sortBy = privateListData.sortBy === "asc" ? "desc" : "asc";
    getPrivateLinksList(0, sortBy, sortKey);
  };

  return (
    <div>
      {user?.isTrialExpiring === "expiring" && <ExpireScreen />}
      <div className="mt-1">
        <SectionAnimation
          variants={animation}
          animate="show"
          initial="hidden"
          className="w-full m-auto "
        >
          <GraphComponent
            handleChecked={handleChecked}
            privateList={filterList?.data}
            memoizedValue={memoizedValue}
            loading={loading === "searchLoading"}
            hasMore={filterList?.totalCount > filterList?.data?.length}
            fetchMore={() => seachPrivateLink(filterList?.data?.length)}
          />

          {visitorData?.publicUrl && (
            <WhiteCard id="profile-section">
              <div
                className={`flex items-center _public_link flex-col md:flex-row `}
              >
                <div className="w-full lg:w-1/6">
                  <h1 className="text-2xl">{lang.profileLink}</h1>
                </div>
                <div className="w-full lg:w-1/2">
                  <CopyToClipboard text={visitorData?.publicUrl}>
                    <button
                      className="copy-link"
                      data-key="copy-application-link"
                      onClick={() => setCopy(visitorData?.publicUrl)}
                    >
                      <div className="truncate" style={{ width: "auto" }}>
                        {visitorData?.publicUrl}
                      </div>
                      {copy === visitorData?.publicUrl ? (
                        <span className="copied">{lang.copied}</span>
                      ) : (
                        <span>{lang.copy}</span>
                      )}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </WhiteCard>
          )}

          <div className="p-5 mb-5">
            <div className="flex items-center">
              <h2 className="mr-2 text-2xl">My profile access</h2>
              <button
                type="button"
                onClick={() => setIsViewSettingsModalOpen(true)}
                className={cn(
                  isMobile
                    ? "border leading-6 mt-2 border-aqua-400 py-1 rounded-full align-bottom flex justify-center w-full md:w-auto md:mr-2 md:mt-0 navbar-btn"
                    : "",
                  "flex gap-4 items-center px-6 py-4 w-auto"
                )}
                id="analytics-page-settings-button"
              >
                {!isMobile && (
                  <FontAwesomeIcon
                    icon={["fas", "eye"]}
                    className="text-[#4F5656]"
                  />
                )}
                <span
                  className={cn(isMobile ? "text-aqua-400" : "", "truncate")}
                >
                  {isMobile ? "Settings" : "Page settings"}
                </span>
              </button>
            </div>
            {isIdentifiedViewsLoading && <Loading size="medium" />}
            <div className="flex flex-col items-start pb-4 mt-2 overflow-y-auto max-h-60">
              {identifiedViewsData?.me?.identifiedViews?.length === 0 && (
                <span className="text-gray-600">
                  No visitors have shared their emails yet
                </span>
              )}
              {identifiedViewsData?.me?.identifiedViews?.map((view) => (
                <div className="flex items-center px-4 py-2 mt-3 bg-gray-100 rounded-lg shadow-md">
                  <span className="mr-4 font-bold">{view.name}</span>
                  <a
                    href={`mailto:${view.email}`}
                    className="underline text-aqua-400"
                  >
                    {view.email}
                  </a>
                  <span
                    title={getFormattedDate(view.createdAt)}
                    className="ml-12 text-sm text-gray-600"
                  >
                    {getTimeSince(view.createdAt)}
                  </span>
                </div>
              ))}
            </div>
            {/* Rendering here closer to "page setting" button to minimize the risk of running activation before button is rendered */}
            <AnalyticsActivationWrapper />
          </div>

          <WhiteCard>
            <div
              className={`flex items-center justify-between flex-col md:flex-row`}
            >
              <div className="w-full md:w-2/6 md:mb-0 _public_link">
                <h1 className="text-2xl">
                  {`${lang.uniqueProfileLinks}(${
                    privateListData?.data?.length
                  }/${
                    privateListData?.totalCount
                      ? privateListData?.totalCount
                      : 0
                  })`}
                </h1>
                <p className="mt-1 mb-3 text-sm">{lang.trialScreenDesc}</p>
              </div>
              <div className="w-full text-right md:w-auto lg:w-4/6">
                <ClickOutside
                  onClick={() =>
                    setCreateInput({
                      ...createInput,
                      isCreatePrivateLink: false,
                      linkName: "",
                    })
                  }
                >
                  <PrivateLink
                    isCreatePrivateLink={createInput.isCreatePrivateLink}
                    id="create-link-button"
                  >
                    <form
                      className="m-0 flex justify-between relative top-0.5"
                      autoComplete="off"
                    >
                      <input
                        type="text"
                        id="create-link"
                        onChange={(e) =>
                          setCreateInput({
                            ...createInput,
                            linkName: e.target.value,
                          })
                        }
                        onFocus={() =>
                          setCreateInput({
                            ...createInput,
                            isCreatePrivateLink: true,
                          })
                        }
                        value={createInput.linkName}
                        className="text-white bg-transparent"
                        placeholder={
                          createInput.isCreatePrivateLink
                            ? `${lang.enterUniqueLinkName}...`
                            : `${lang.create} ${lang.uniqueLink}`
                        }
                      />
                      {createInput.isCreatePrivateLink && (
                        <button
                          disabled={
                            !createInput.linkName || createInput.createLoading
                          }
                          onClick={createPrivateLink}
                          data-key="create-unique-link"
                        >
                          {createInput.createLoading ? (
                            <FontAwesomeIcon
                              icon={["fal", "spinner-third"]}
                              spin
                            />
                          ) : (
                            lang.create
                          )}
                        </button>
                      )}
                    </form>
                  </PrivateLink>
                </ClickOutside>
              </div>
            </div>

            <div className="relative mt-5 overflow-auto">
              {loading === "privateListLoading" && (
                <Loading fullPage position="absolute" />
              )}

              <table className="w-full table-auto min-w-max">
                <thead>
                  <tr className="text-sm font-normal capitalize bg-gray-100">
                    <th className="px-2 py-3 font-medium text-left text-gray-700"></th>
                    <th className="px-2 py-3 font-medium text-center text-gray-400">
                      <span> {lang.link} </span>
                    </th>
                    <th
                      className="px-2 py-3 font-medium text-center text-gray-700"
                      onClick={() => handleSort("createdAt")}
                    >
                      <div className="text-gray-400 short-icon">
                        <span className="whitespace-normal">
                          {lang.createdOn}
                        </span>
                        <span>
                          <FontAwesomeIcon
                            icon={["fas", "sort-up"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "asc" &&
                              privateListData.sortKey === "createdAt" &&
                              "text-aqua-400"
                            }`}
                          />
                          <FontAwesomeIcon
                            icon={["fas", "sort-down"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "desc" &&
                              privateListData.sortKey === "createdAt" &&
                              "text-aqua-400"
                            }`}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      className="px-2 py-3 font-medium text-center text-gray-700"
                      onClick={() => handleSort("visits")}
                    >
                      <div className="text-gray-400 short-icon">
                        <span> {lang.visits} </span>
                        <span>
                          <FontAwesomeIcon
                            icon={["fas", "sort-up"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "asc" &&
                              privateListData.sortKey === "visits" &&
                              "text-aqua-400"
                            }`}
                          />
                          <FontAwesomeIcon
                            icon={["fas", "sort-down"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "desc" &&
                              privateListData.sortKey === "visits" &&
                              "text-aqua-400"
                            }`}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      className="px-2 py-3 font-medium text-center text-gray-700"
                      onClick={() => handleSort("uniqueVisits")}
                    >
                      <div className="items-baseline text-gray-400 short-icon w-16 md:w-auto">
                        <span>{lang.uniqueVisitors}</span>
                        <span>
                          <FontAwesomeIcon
                            icon={["fas", "sort-up"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "asc" &&
                              privateListData.sortKey === "uniqueVisits" &&
                              "text-aqua-400"
                            }`}
                          />
                          <FontAwesomeIcon
                            icon={["fas", "sort-down"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "desc" &&
                              privateListData.sortKey === "uniqueVisits" &&
                              "text-aqua-400"
                            }`}
                          />
                        </span>
                      </div>
                    </th>
                    <th
                      className="px-2 py-3 font-medium text-center text-gray-700"
                      onClick={() => handleSort("downloads")}
                    >
                      <div className="items-baseline text-gray-400 short-icon w-20 md:w-auto">
                        <span> {lang.pitchDownloads} </span>
                        <span>
                          <FontAwesomeIcon
                            icon={["fas", "sort-up"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "asc" &&
                              privateListData.sortKey === "downloads" &&
                              "text-aqua-400"
                            }`}
                          />
                          <FontAwesomeIcon
                            icon={["fas", "sort-down"]}
                            className={`mr-1 ${
                              privateListData.sortBy === "desc" &&
                              privateListData.sortKey === "downloads" &&
                              "text-aqua-400"
                            }`}
                          />
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm text-gray-600 bg-white rounded-md">
                  {privateListData?.data?.length > 0 ? (
                    privateListData?.data?.map((link, index) => (
                      <tr key={index} id={index}>
                        <td className="p-1 text-left border-b-0 cursor-auto one-line">
                          <div className="flex items-center w-full">
                            <div className="text-base">
                              <h5 className="font-medium text-black md:font-normal">
                                {link.name}
                              </h5>
                              <small className="text-gray-400 pt-1 items-center flex">
                                <Tooltip
                                  content={`${lang.lastVisited} ${getTimeSince(
                                    link?.lastVisit
                                  )}`}
                                >
                                  <FontAwesomeIcon
                                    icon={["fal", "clock"]}
                                    className="mr-1 text-aqua-400"
                                  />
                                </Tooltip>
                                {getTimeSince(link?.lastVisit)}
                              </small>
                            </div>
                          </div>
                        </td>
                        <td className="p-1">
                          <CopyToClipboard text={link?.link}>
                            <button
                              className="copy-link"
                              onClick={() => setCopy(link?.link)}
                            >
                              <div>{"..." + link?.link?.substr(-10)}</div>
                              <span
                                className={cn(
                                  "w-12",
                                  copy === link?.link && "copied"
                                )}
                              >
                                {copy === link?.link ? lang.copied : lang.copy}
                              </span>
                            </button>
                          </CopyToClipboard>
                        </td>
                        <td
                          className="p-1 text-center whitespace-no-wrap cursor-auto"
                          data-label={lang.createdOn}
                        >
                          {getFormattedDate(link?.createdAt)}
                        </td>
                        <td
                          className="p-1 text-center cursor-auto"
                          data-label={lang.visits}
                        >
                          {link?.visits}
                        </td>
                        <td
                          className="px-1 text-center cursor-auto"
                          data-label={lang.uniqueVisitors}
                        >
                          {link.uniqueVisits}
                        </td>
                        <td
                          className="px-1 text-center cursor-auto"
                          data-label={lang.pitchDownloads}
                        >
                          {link.downloads}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="px-3 py-3 text-center cursor-auto"
                        colSpan="7"
                      >
                        <span className="block w-full text-center">
                          {lang.noRecordFound}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </WhiteCard>
        </SectionAnimation>
      </div>
      <ViewSettings
        isOpen={isViewSettingsModalOpen}
        onClose={() => {
          setIsViewSettingsModalOpen(false);
        }}
      />
    </div>
  );
};
export default withRouter(ProfileAnalytics);
