import type { TeaserStepProps } from "../interfaces";
import { FC, useState } from "react";
import ButtonGroup from "molecules/Form/ButtonGroup";
import { FUNDRAISING_STAGES } from "core/consts";
import { useSelector } from "react-redux";
import { getCurrencyList } from "models/user/selectors";
import { SingleSelect } from "molecules/SingleSelect";
import BackButton from "../BackButton";
import { prettyNum } from "utils/number";
import DateSelect from "atoms/Form/DateSelect";

const STAGES_WITHOUT_OTHER = Object.entries(FUNDRAISING_STAGES).filter(
  ([key]) => key !== "OTHER"
);

const Investment: FC<TeaserStepProps> = ({ onNext, onPrev }) => {
  const [ticketSize, setTicketSize] = useState("");
  const [raisedTarget, setRaisedTarget] = useState("");
  const [fundraisingCloseOn, setFundraisingCloseOn] = useState<Date | null>();
  const list: any[] = useSelector(getCurrencyList);
  const currencies =
    list?.map((item) => ({
      id: item._id,
      title: item.code,
      symbol: item.symbol,
      isDefault: item.isDefault,
    })) || [];
  const [activeCurrency, setActiveCurrency] = useState(
    () => currencies.find((item) => item.isDefault) ?? currencies[0] ?? null
  );
  const [stages, setStages] = useState<string[]>([]);

  const handleNext = () =>
    onNext({
      investment: {
        ticketSize,
        raisedTarget,
        currency: activeCurrency,
        stages,
        fundraisingCloseOn,
      },
    });

  return (
    <div className="w-full h-full px-2 py-8 md:px-16">
      <BackButton onAction={onPrev} />
      <h2 className="text-xl text-center">Investment goals</h2>
      <div className="my-8 font-bold text-center text-gray-500">
        Your targets for this investment round
      </div>
      <div>
        <div className="w-full mt-6">
          <div className="mb-1 text-sm text-gray-400">Choose currency</div>
          <SingleSelect
            choices={currencies}
            value={activeCurrency}
            onChange={setActiveCurrency}
          />
        </div>
        <div className="w-full mt-6">
          <label className="flex flex-col w-full">
            <span className="ml-2 text-sm text-gray-400">
              Fundraising target
            </span>
            <div className="flex w-full px-2 py-1 border border-gray-200 rounded-xl">
              <span className="mr-1 text-gray-400 min-w-[16px]">
                {activeCurrency.symbol}
              </span>
              <input
                className="w-full border-none"
                value={raisedTarget}
                onChange={({ target }) =>
                  setRaisedTarget(prettyNum(target.value))
                }
              />
            </div>
          </label>
        </div>
        <div className="w-full mt-6">
          <label className="flex flex-col w-full">
            <span className="ml-2 text-sm text-gray-400">
              Minimum ticket size
            </span>
            <div className="flex w-full px-2 py-1 border border-gray-200 rounded-xl">
              <span className="mr-1 text-gray-400 min-w-[16px]">
                {activeCurrency.symbol}
              </span>
              <input
                className="w-full border-none"
                value={ticketSize}
                onChange={({ target }) =>
                  setTicketSize(prettyNum(target.value))
                }
              />
            </div>
          </label>
        </div>
        <div className="w-full mt-6">
          <label className="flex flex-col w-full">
            <span className="ml-2 text-sm text-gray-400">
              When do you expect to close your fundraising round?
            </span>
            <DateSelect
              //@ts-ignore
              defaultValue={fundraisingCloseOn as any}
              onChange={(val: Date) => setFundraisingCloseOn(val)}
              className="flex w-full px-2 py-1 border border-gray-200 cursor-pointer rounded-xl focus:ring-0 focus:border-gray-200"
            />
          </label>
        </div>
        <div className="flex flex-col items-start mt-6">
          <label className="mb-2" htmlFor="fundraising-stage">
            What fundraising stage are you in?
          </label>
          <ButtonGroup
            id="fundraising-stage"
            options={STAGES_WITHOUT_OTHER}
            onChange={setStages}
            className="!mb-2 !mr-2"
            limit={1}
          />
        </div>
      </div>
      <div className="flex justify-end mt-8 pb-4">
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Investment;
