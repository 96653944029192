import PureHtml from "atoms/PureHtml";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { MarketSegmentProps } from "./interfaces";

const MarketSegment: FC<MarketSegmentProps> = ({
  marketGrowthDrivers,
  isDeepDiving,
}) => {
  if (isDeepDiving && !marketGrowthDrivers)
    return (
      <div className="grid grid-cols-1 mb-4 gap-4">
        <div>
          <h3 className="mr-2 text-lg text-gray-700 my-2">
            Market growth drivers
          </h3>
          <Skeleton count={3.6} />
        </div>
      </div>
    );

  return (
    <div className="grid grid-cols-1 mb-4 gap-4">
      {marketGrowthDrivers && (
        <div className="flex flex-col">
          <h3 className="text-lg text-gray-700 my-2">Market growth drivers</h3>
          <div className="aiaProfile pl-4 text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
            <PureHtml content={marketGrowthDrivers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketSegment;
