import Button from "atoms/Button/Button";
import { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Checkbox from "atoms/Checkbox/Checkbox";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "atoms/Loading/Loading";
import { Link } from "react-router-dom";
import FixScrollProblem from "atoms/FixScrollProblem";
import {
  GET_VIEW_SETTINGS,
  SAVE_VIEW_SETTINGS,
} from "queries/startup/startupProfile";

const ViewSettings: FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const [error, setError] = useState("");
  const { data, refetch, loading: isQuerying } = useQuery(GET_VIEW_SETTINGS);
  const [saveSettings, { loading: isMutating }] =
    useMutation(SAVE_VIEW_SETTINGS);

  const isLoading = isQuerying || isMutating;

  const handleChange = async (checked: boolean) => {
    if (isLoading) {
      return;
    }

    try {
      await saveSettings({
        variables: {
          isIdentityRequired: checked,
        },
      });
      refetch();
    } catch (error: any) {
      setError("We couldn't update your profile now. Please try again later");
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <FixScrollProblem />
                  <p className="pb-2 text-gray-600 border-b border-gray-200">
                    You can configure how your page should be accessible for
                    other users.
                  </p>
                  <div className="flex justify-between mt-6 text-sm text-gray-600">
                    <Checkbox
                      label="Require name and email to view my page"
                      checked={Boolean(
                        data?.me?.viewSettings?.isIdentityRequired
                      )}
                      onChange={handleChange}
                    />
                    {isLoading && <Loading size="small" />}
                  </div>
                  <p className="mt-4 text-sm text-gray-500">
                    You will be able to review those emails in your{" "}
                    <Link
                      to="/myspace"
                      className="hover:underline text-aqua-500"
                    >
                      analytics
                    </Link>{" "}
                    page.
                  </p>
                  <div className="flex justify-end mt-4">
                    <Button color="transparent" size="small" onClick={onClose}>
                      Close
                    </Button>
                  </div>
                  {error && (
                    <p className="mt-10 text-sm text-red-400">{error}</p>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewSettings;
