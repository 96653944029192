import type { ViewIdentificationProps } from "./interfaces";
import { ChangeEvent, FC, Fragment, useState } from "react";
import { getUser } from "models/user/selectors";
import { useSelector } from "react-redux";
import FormInput from "atoms/Form/FormInput";
import Checkbox from "atoms/Checkbox/Checkbox";
import Button from "atoms/Button/Button";
import { useEffect } from "react";
import {
  getRememberIdentity,
  identifyFor,
  isIdentified,
  rememberIdentity,
} from "./storage";
import { touch, TouchType } from "analytics";
import { Dialog, Transition } from "@headlessui/react";
import { mixpanelViewRestricted } from "core/mixpanel/Mixpanel";

const ViewIdentification: FC<ViewIdentificationProps> = ({
  onFinish,
  boardSlug,
  startupSlug,
  children,
  buttonLabel = "View",
  isMeetingRequest,
}) => {
  const [email, setEmail] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const user = useSelector(getUser);
  const type = boardSlug ? "board" : "startup";
  const slug = boardSlug || startupSlug || "unknown";

  const sendCredentials = (name: string, email: string) => {
    touch(TouchType.IntroduceForView, {
      agreedWithPolicy: isAgreed,
      name,
      email,
      isMeetingRequest,
      [type]: slug,
    });
  };

  useEffect(() => {
    if (isIdentified(type, slug)) {
      onFinish(true);

      return;
    }

    if (user) {
      sendCredentials(`${user.firstName} ${user.lastName}`, user.email);
      onFinish(true);
    }
  }, [user]);

  useEffect(() => {
    if (isIdentified(type, slug)) {
      onFinish(true);

      return;
    }

    const rememberIdentity = getRememberIdentity();

    if (rememberIdentity) {
      sendCredentials(rememberIdentity.name, rememberIdentity.email);
      onFinish(true);
    }
  }, []);

  const handleSend = () => {
    if (isRemember) {
      rememberIdentity({
        name: visitorName,
        email,
      });
    }

    mixpanelViewRestricted(email, visitorName, type);

    sendCredentials(visitorName, email);
    identifyFor(type, slug);
    onFinish(true);
  };

  const handleDiscard = () => {
    touch(TouchType.Click, {
      id: "reject_identity",
      data: slug,
    });
    onFinish(false);
  };

  const isAbleToSend = isAgreed && Boolean(visitorName) && Boolean(email);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-lg p-6 mx-auto -mt-40 bg-white border border-gray-300 rounded-lg shadow-lg dark:border-black dark:text-white/80 dark:bg-[#202022]">
                <div className="flex flex-col items-center w-full mx-auto">
                  <div className="w-full">{children}</div>
                  <div className="w-full mt-4 mb-4">
                    <FormInput
                      label="Your name"
                      type="text"
                      value={visitorName}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setVisitorName(e.target.value)
                      }
                    />
                    <div className="mt-4"></div>
                    <FormInput
                      label="Your email"
                      type="text"
                      value={email}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                    />
                  </div>
                  <div className="w-full text-xs text-gray-600 md:text-sm dark:text-gray-400">
                    <Checkbox
                      label="Use these credentials for any other protected resource"
                      checked={isRemember}
                      onChange={setIsRemember}
                    />
                    <div className="mb-2"></div>
                    <Checkbox
                      label={
                        <>
                          By clicking this you agree to our{" "}
                          <a
                            className="cursor-pointer text-aqua-400 hover:text-aqua-600"
                            href="https://sircular.io/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy policy
                          </a>{" "}
                          and{" "}
                          <a
                            className="cursor-pointer text-aqua-400 hover:text-aqua-600"
                            href="https://sircular.io/terms-of-service"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and conditions
                          </a>
                        </>
                      }
                      checked={isAgreed}
                      onChange={setIsAgreed}
                    />
                  </div>
                  <div className="flex mt-4">
                    <Button
                      className="mr-4"
                      color="primary"
                      size="small"
                      onClick={handleSend}
                      disabled={!isAbleToSend}
                    >
                      {buttonLabel}
                    </Button>
                    <Button
                      color="transparent"
                      size="small"
                      onClick={handleDiscard}
                    >
                      Go back
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ViewIdentification;
