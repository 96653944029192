// @ts-nocheck
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";

const INITIAL_TIMER = 10000;
const STOP_TIMER = -1;
const SHORT_TIMER = 3000;

const Snack = ({
  id,
  type,
  title,
  message,
  timer: customTimer,
  handleRemoveSnack,
}) => {
  const [timer, setTimer] = useState(customTimer || INITIAL_TIMER);

  useEffect(() => {
    if (timer === STOP_TIMER) return;

    const timeoutId = setTimeout(() => handleRemoveSnack(id), timer);

    return () => clearTimeout(timeoutId);
  }, [timer, id]);

  return (
    <AnimatePresence>
      <motion.div
        key={id}
        onMouseEnter={() => setTimer(STOP_TIMER)}
        onMouseLeave={() => setTimer(SHORT_TIMER)}
        positiontransition="true"
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.15 } }}
        className={`max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mb-3 ${
          type === "error" && "text-fire-400"
        } ${type === "success" && "text-aqua-400"}`}
      >
        <div className="overflow-hidden rounded-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 mt-1 text-xl">
                {type === "error" && (
                  <FontAwesomeIcon icon={["fal", "times-circle"]} />
                )}
                {type === "success" && (
                  <FontAwesomeIcon icon={["fal", "check-circle"]} />
                )}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm text-black leading-5">
                  {title || "Snack title"}
                </p>
                <p className="mt-1 text-sm text-gray-500 leading-5">
                  {message || "Snack message here."}
                </p>
              </div>
              <div className="flex flex-shrink-0 ml-4">
                <button
                  className="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500 hover:text-gray-900"
                  onClick={() => handleRemoveSnack(id)}
                >
                  <svg
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Snack;
