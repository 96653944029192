import { FC, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { ChatBot } from "./ChatBot";
import {
  AiaCompetitor,
  AiaTaskPayload,
  AiaTaskProfileLoaded,
} from "gql/graphql";
import { Page } from "ui/Page";

/* component imports */
import InvestmentSummaryMobile from "./components/shared/InvestmentSummaryMobile";
import KeyFigures from "./components/shared/KeyFigures/KeyFigures";
import AppStore from "./components/shared/Appstore/AppStore";
import InvestmentAnalysis from "./components/shared/InvestmentAnalysis/InvestmentAnalysis";
import SDGs from "./components/shared/SDGs/SDGs";
import News from "./components/shared/News/News";
import FundingRounds from "./components/shared/FundingRounds/FundingRounds";
import AppScreenShots from "./components/shared/Appstore/AppScreenShots";
import Hero_Aia from "./components/aia/Hero_Aia";
import MarketOpportunity_Aia from "./components/aia/MarketOpportunity_Aia/MarketOpportunity_Aia";
import ExecutiveSummary_Aia from "./components/aia/ExecutiveSummary/Index";
import FundraisingOverview_Aia from "./components/aia/FundraisingOverView_Aia";
import Team_Aia from "./components/aia/Team/Team_Aia";
import SectionHeader from "./components/shared/SectionHeader/SectionHeader";
import CriteriaScoring from "./components/aia/CriteriaScoring_Aia";
import Legislation from "./components/aia/Legislation/Legislation";
import CapTable from "./components/shared/Investors/CapTable";
import Advisors from "./components/shared/Advisors/Advisors";
import Traction from "./components/aia/Traction";
import { UpgradePlanModalProvider } from "organisms/AccessControl/UpgradePlanModal";
import BusinessModelAndPricing from "./components/aia/BusinessModel/BusinessModelAndPricing";
import Product from "./components/aia/Product/Product";
import ProfileNavigation from "./components/aia/ProfileNavigation/ProfileNavigation";
import Competitors from "./components/aia/Competitors/Competitors";

type AnalysisLoadingStatus = Pick<
  AiaTaskPayload,
  | "teamAnalysisFinished"
  | "competitorsFinished"
  | "investmentFinished"
  | "marketOppFinished"
  | "executiveSummaryFinished"
  | "aiaCriteriaFinished"
  | "competitorValidation"
>;

interface Props {
  profileLoadingStatus?: AiaTaskProfileLoaded;
  analysisLoadingStatus?: AnalysisLoadingStatus;
  data: any;
  traction: any;
  analysis: any;
  competitors: {
    aiaCompetitors: AiaCompetitor[];
    marketSegmentAnalysis: {
      marketSegmentation: string;
      marketGrowthDrivers: string;
    };
  };
  currencyCode: any;
  isPublic?: boolean;
}

const CompanyProfile_Aia: FC<Props> = ({
  /* For public profiles we don't have loading statuses since everything is already generated, therefore defaults are all true */
  profileLoadingStatus = {
    appStore: true,
    basics: true,
    businessModel: true,
    businessRelation: true,
    crunchbase: true,
    elevatorPitch: true,
    industries: true,
    marketOpportunity: true,
    productCategory: true,
    productDescription: true,
    raisedTarget: true,
    raisedValue: true,
    sdg: true,
    socials: true,
    team: true,
    investors: true,
    timing: true,
    tractionSummary: true,
    tractionVerbose: true,
    valuation: true,
    webScrape: true,
    perplexityCompetitors: true,
    competitorsFromDeck: true,
  },
  analysisLoadingStatus = {
    competitorsFinished: true,
    executiveSummaryFinished: true,
    aiaCriteriaFinished: true,
    investmentFinished: true,
    marketOppFinished: true,
    teamAnalysisFinished: true,
    competitorValidation: true,
  },
  data,
  traction,
  analysis,
  competitors,
  currencyCode,
  isPublic,
}) => {
  // The state is set in this parent component to share the state with chatbot and with the hero component
  const [showAddContextModal, setShowAddContextModal] = useState(false);

  // The state is set in this parent component to share the state with chatbot and with the email component
  const [isChatHidden, setIsChatHidden] = useState(false);

  const renderTeam = () => {
    return (
      <Team_Aia
        companyId={data?.companyBySlug?.id}
        founders={data?.companyBySlug?.founders}
        members={data?.companyBySlug?.teamMembers}
        isTeamLoading={!profileLoadingStatus?.team}
        teamNote={data?.companyBySlug?.teamNote}
        amITeamMember={data?.companyBySlug?.amITeamMember}
        isPublic={isPublic}
        /* Sourcing data */
        linkedInUrl={data?.companyBySlug?.sourcing?.linkedInCompany?.companyUrl}
        openPositions={data?.companyBySlug?.sourcing?.openPositionsLinkedIn}
        /* Analysis data */
        coExperiences={analysis?.team?.coExperiences}
        previouslyStudiedTogether={analysis?.team?.previouslyStudiedTogether}
        sameIndustryExperience={analysis?.team?.sameIndustryExperience}
        criticalCompetences={analysis?.team?.criticalCompetences}
        isTeamAnalysisLoading={!analysisLoadingStatus?.teamAnalysisFinished}
        slug={data?.companyBySlug?.slug}
      />
    );
  };

  const marketTimingSection =
    data?.companyBySlug?.marketOpp ||
    data?.companyBySlug?.businessModelDescription ||
    data?.companyBySlug?.pricing ||
    data?.companyBySlug?.businessTiming ||
    data?.sustainableDevelopmentGoals?.items?.length > 0 ||
    analysis?.marketOpp.marketOpp;

  const calcHeight = () => {
    if (isPublic) {
      if (window.innerWidth < 990) return "calc(100% - 3.8rem)";
      else return "calc(100% - 5rem)";
    }
    return "100%";
  };

  return (
    <div
      className="flex"
      // To accomodate the height of public navbar
      style={{ height: calcHeight() }}
    >
      <div className="h-full w-48 bg-neutral-100 shrink-0 hidden md:block">
        <ProfileNavigation isPublic={isPublic} />
      </div>
      <div className="h-full flex-grow overflow-y-scroll">
        <Page>
          <UpgradePlanModalProvider>
            <Hero_Aia
              showAddContextModal={showAddContextModal}
              setShowAddContextModal={setShowAddContextModal}
              company={data?.companyBySlug}
              isLoading={
                !(
                  profileLoadingStatus?.basics &&
                  profileLoadingStatus?.industries &&
                  profileLoadingStatus?.socials
                )
              }
              currencyCode={currencyCode}
              setIsChatHidden={setIsChatHidden}
            />

            {!isPublic && data?.companyBySlug?.amITeamMember && (
              <CriteriaScoring
                company={data?.companyBySlug}
                criteriaAnalysis={analysis?.criteriaAnalysis}
              />
            )}

            {/* Mobile */}
            <div className="md:hidden">
              <FundraisingOverview_Aia
                finance={data?.companyBySlug?.finance}
                valuation={data?.companyBySlug?.valuation}
                raisedTarget={data?.companyBySlug?.finance?.raisedTarget}
                raisedValue={data?.companyBySlug?.finance?.raisedValue}
                minTicketSize={data?.companyBySlug?.finance?.minTicketSize}
                currencyCode={currencyCode}
                fit={data?.companyBySlug?.fit}
                fundraisingCloseOn={
                  data?.companyBySlug?.finance?.fundraisingCloseOn
                }
                committed={data?.companyBySlug?.finance?.committed}
                isProfileGeneratedByAIA={true}
              />
            </div>

            {/* Mobile */}
            {profileLoadingStatus?.businessModel &&
            profileLoadingStatus?.productCategory ? (
              <InvestmentSummaryMobile
                businessCategory={data?.companyBySlug?.businessCategory}
                businessTypeNote={data?.companyBySlug?.businessTypeNote}
                businessModel={data?.companyBySlug?.businessModel}
                businessCategoryNote={data?.companyBySlug?.businessCategoryNote}
                founded={data?.companyBySlug?.founded}
                companyStage={data?.companyBySlug?.companyStage}
                teamSize={data?.companyBySlug?.teamSize}
              />
            ) : (
              <div className="mx-auto my-4 md:hidden">
                <Skeleton count={2.5} />
              </div>
            )}

            <ExecutiveSummary_Aia
              isTractionSummaryLoading={!profileLoadingStatus?.tractionSummary}
              tractionSummary={data?.companyBySlug?.traction?.summary}
              elevatorPitch={data?.companyBySlug?.pitch}
              isExecutiveSummaryLoading={
                !analysisLoadingStatus?.executiveSummaryFinished
              }
              executiveSummary={analysis?.executiveSummary}
            />

            {analysisLoadingStatus?.investmentFinished ? (
              <InvestmentAnalysis investmentData={analysis?.investment} />
            ) : (
              <Skeleton count={3.6} />
            )}

            {profileLoadingStatus?.team ? (
              <div className="bg-white dark:bg-neutral-900">
                {(data?.companyBySlug?.founders !== null ||
                  data?.companyBySlug?.teamMembers !== null) &&
                  (data?.companyBySlug?.founders !== undefined ||
                    data?.companyBySlug?.teamMembers !== undefined) && (
                    <div className="container mx-auto my-6">{renderTeam()}</div>
                  )}
              </div>
            ) : (
              <div className="mx-auto my-4">
                <SectionHeader title="Meet the Team" />
                <Skeleton count={3} />
              </div>
            )}

            {data?.companyBySlug?.advisors &&
              data?.companyBySlug?.advisors?.length > 0 && (
                <Advisors
                  advisors={data?.companyBySlug?.advisors}
                  isLoading={!profileLoadingStatus?.team}
                />
              )}

            {data?.companyBySlug?.investors &&
              data?.companyBySlug?.investors?.length > 0 && (
                <CapTable
                  isLoading={!profileLoadingStatus?.investors}
                  investors={data?.companyBySlug?.investors}
                />
              )}

            {data?.companyBySlug?.sourcing?.investmentAnalysis
              ?.fundingRounds && (
              <FundingRounds
                crunchbaseUrl={
                  data?.companyBySlug?.sourcing?.investmentAnalysis
                    ?.crunchbaseUrl
                }
                pitchbookUrl={
                  data?.companyBySlug?.sourcing?.investmentAnalysis
                    ?.pitchbookUrl
                }
                fundingRounds={
                  data.companyBySlug.sourcing.investmentAnalysis.fundingRounds
                }
              />
            )}

            <div className="sm:bg-gradient-to-l from-white bg-gray-50 sm:bg-white sm:from-neutral-100 dark:sm:from-neutral-900 dark:sm:to-neutral-800">
              <KeyFigures
                traction={traction}
                companyStage={data?.companyBySlug?.companyStage}
                currencyCode={currencyCode}
              />
            </div>

            <Traction
              isLoading={!profileLoadingStatus?.tractionVerbose}
              traction={data?.companyBySlug?.traction?.verbose}
            />

            {profileLoadingStatus?.productDescription ? (
              <Product
                customers={data?.companyBySlug?.customers}
                valueProposition={data?.companyBySlug?.valueProposition}
                description={data?.companyBySlug?.description}
                productDemo={data?.companyBySlug?.productDemo}
                productImages={data?.companyBySlug?.productImages}
                isProfileGeneratedByAIA={true}
              />
            ) : (
              <div className="mx-auto my-4">
                <SectionHeader title="Product" />
                <Skeleton count={3} />
              </div>
            )}

            {profileLoadingStatus?.appStore ? (
              <>
                {data?.companyBySlug?.sourcing?.appstore?.appInfo
                  ?.screenShots &&
                  data?.companyBySlug?.sourcing?.appstore?.appInfo?.screenShots
                    .length > 0 && (
                    <AppScreenShots
                      screenshots={
                        data?.companyBySlug?.sourcing?.appstore?.appInfo
                          ?.screenShots
                      }
                    />
                  )}
                {data?.companyBySlug?.sourcing?.appstore?.appInfo && (
                  <AppStore
                    appstore={data?.companyBySlug?.sourcing?.appstore}
                  />
                )}
              </>
            ) : (
              <div className="mx-auto my-4">
                <SectionHeader title="Appstore" />
                <Skeleton count={3} />
              </div>
            )}

            <Competitors
              slug={data?.companyBySlug?.slug}
              companyId={data?.companyBySlug?.id}
              isCompetitorsFromDeckLoading={
                !profileLoadingStatus?.competitorsFromDeck
              }
              isValidatingCompetitors={
                !analysisLoadingStatus?.competitorValidation
              }
              competitorsFinished={!analysisLoadingStatus?.competitorsFinished}
              isPerplexityLoading={!profileLoadingStatus?.perplexityCompetitors}
              isPublic={isPublic}
              amITeamMember={data?.companyBySlug?.amITeamMember}
              competitors={competitors?.aiaCompetitors}
              marketGrowthDrivers={
                competitors?.marketSegmentAnalysis?.marketGrowthDrivers
              }
            />

            {marketTimingSection && (
              <div className="p-4 sm:bg-gradient-to-r from-white bg-gray-50 sm:bg-white sm:from-neutral-100 dark:bg-neutral-900 dark:sm:from-neutral-900 dark:sm:to-neutral-800">
                <BusinessModelAndPricing
                  businessModelDescription={
                    data?.companyBySlug?.businessModelDescription
                  }
                  pricing={data?.companyBySlug?.pricing}
                  isLoading={!profileLoadingStatus?.businessModel}
                />

                <MarketOpportunity_Aia
                  marketOpp={data?.companyBySlug?.marketOpp}
                  marketOppAnalysis={analysis?.marketOpp.marketOpp}
                  blueOcean={analysis?.marketOpp.blueOcean}
                  sources={analysis?.marketOpp.sources}
                  isProfileLoading={!profileLoadingStatus?.marketOpportunity}
                  isAnalysisLoading={!analysisLoadingStatus?.marketOppFinished}
                />

                <Legislation
                  todaysLegislation={analysis?.legislation?.todaysLegislation}
                  futureLegislation={analysis?.legislation?.futureLegislation}
                />

                <SDGs
                  sustainableDevelopmentGoals={
                    data?.companyBySlug?.sustainableDevelopmentGoals
                  }
                  isLoading={!profileLoadingStatus?.sdg}
                />
              </div>
            )}

            {profileLoadingStatus?.webScrape ? (
              <News
                news={data?.companyBySlug?.news}
                updates={
                  data?.companyBySlug?.sourcing?.linkedInCompany
                    ?.isCorrectCompany
                    ? data?.companyBySlug?.sourcing?.linkedInCompany
                        ?.companyUpdates
                    : []
                }
              />
            ) : (
              <div className="mx-auto my-4">
                <SectionHeader title="News" />
                <Skeleton count={3} />
              </div>
            )}

            <ChatBot
              companyId={data?.companyBySlug?.id}
              showAddedContext={showAddContextModal}
              isHidden={isChatHidden}
              setIsHidden={setIsChatHidden}
            />
          </UpgradePlanModalProvider>
        </Page>
      </div>
    </div>
  );
};

export default CompanyProfile_Aia;
