import { FC } from "react";
import { TeamMemberProps } from "./interfaces";
import DefaultImage from "images/dealroom-image.png";

const getSocialLink = (link: string) =>
  link.includes("https") ? link : `https://${link}`;

const TeamMember: FC<TeamMemberProps> = ({ member }) => (
  <div className="px-4 py-2 mb-4 ml-4 text-sm border border-gray-200 rounded-lg shadow-md last:mr-4">
    <div className="flex">
      <img
        className="w-10 h-10 mr-2 border border-gray-400 rounded-full object-fit min-w-[2.5rem]"
        alt={member.name}
        src={member.image?.large?.location || DefaultImage}
      />
      <div className="flex flex-col">
        <span className="text-gray-600 truncate max-w-[150px] hover:max-w-full">
          {member.name}
        </span>
        <span className="text-gray-400 truncate max-w-[150px] hover:max-w-full">
          {member.title}
        </span>
      </div>
    </div>
    <div className="flex mt-2">
      {member.email && (
        <a
          className="mr-2 text-aqua-400 hover:text-aqua-500 hover:underline"
          href={`mailto:${member.email}`}
        >
          {member.email}
        </a>
      )}
      {member.linkedin && (
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="mr-2 text-aqua-400 hover:text-aqua-500 hover:underline"
          href={getSocialLink(member.linkedin)}
        >
          LinkedIn
        </a>
      )}
    </div>
  </div>
);

export default TeamMember;
