import Cookies from "universal-cookie";

const cookies = new Cookies();

export default () => {
  const getCookie = (key) => cookies.get(key) || {};

  const setCookie = (key, value) => {
    cookies.set(key, value, { path: "/" });
  };

  return { getCookie, setCookie };
};
