import type { FC } from "react";
import type { BottomPanelProps } from "./interfaces";
import Pagination from "pages/dealflow/InvestorDealflow/components/BottomPanel/Pagination";

const BottomPanel: FC<BottomPanelProps> = ({ ...paginationProps }) => (
  <div className="flex justify-end py-2 bg-gray-100 h-[66px]">
    <Pagination {...paginationProps} />
  </div>
);

export default BottomPanel;
