import { gql } from "@apollo/client";

export const SAVE_TEAM_MEMBER = gql`
  mutation saveCompanyTeamMembersStepNew($input: StartupTeamMembersStep!) {
    saveCompanyTeamMembersStepNew(input: $input) {
      _id
      teamSize
      teamNote
      teamMembers {
        id
        name
        gender
        title
        linkedin
        previouslyFounded
        successfulExits
        image {
          large {
            location
          }
        }
        email
      }
    }
  }
`;

export const GET_TEAM_INFO = gql`
  query getStartupTeamInfo {
    me {
      teamSize
      teamNote
      teamMembers {
        id
        name
        gender
        title
        linkedin
        email
        image {
          large {
            location
          }
        }
      }
      founders {
        id
        name
        gender
        title
        email
        linkedin
        previouslyFounded
        successfulExits
        image {
          large {
            location
          }
        }
      }
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation removeTeamMember($id: ID!) {
    removeTeamMember(id: $id) {
      _id
      teamMembers {
        id
      }
    }
  }
`;

export const REMOVE_CO_FOUNDER = gql`
  mutation removeFounder($id: ID!) {
    removeFounder(id: $id) {
      _id
      founders {
        id
      }
    }
  }
`;
