import { createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "models/auth/constants";

export const initialState = {
  status: API_STATUS.PENDING,
  isLoading: true,
};

export const name = "forgotPassword";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForgotPassword: (state, { payload: { status } }) => ({
      ...state,
      status,
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const { updateForgotPassword } = slice.actions;
