// @ts-nocheck
import { useLang } from "core/lang/LangaugeSetting";
import Button from "atoms/Button/Button";
import { ReactComponent as UserIcon } from "images/user-icon.svg";
import { ReactComponent as BuildingIcon } from "images/building-icon.svg";

import FloatingLabelInput from "atoms/Form/FloatingLabelInput";

export type CustomInvestorType = {
  name: string;
  displayName: string;
};

type CustomInvestorProps = {
  onCustomInvestorAdded: () => void;
  onCustomInvestorCancel: () => void;
  register: any;
  errors: any;
};

export default ({
  onCustomInvestorAdded,
  onCustomInvestorCancel,
  register,
  errors,
}: CustomInvestorProps) => {
  const { lang } = useLang();
  return (
    <>
      <div className="grid items-baseline md:flex">
        <div className="w-full pr-0 md:pr-2">
          <FloatingLabelInput
            type="text"
            reference={register("customInvestor.name", {
              required: true,
              maxLength: 25,
            })}
            errors={
              errors.name?.type === "maxLength"
                ? lang.allowedOnly25Charcters
                : errors?.name && lang.mandatory
            }
            icon={<UserIcon />}
          >
            {lang.enterFullName}
          </FloatingLabelInput>
        </div>
        <div className="w-full pl-0 -mt-4 md:pl-2">
          <FloatingLabelInput
            type="text"
            reference={register("customInvestor.displayName", {
              required: true,
              maxLength: 25,
            })}
            errors={
              errors.displayName?.type === "maxLength"
                ? lang.allowedOnly25Charcters
                : errors.displayName && lang.mandatory
            }
            icon={<BuildingIcon />}
          >
            {lang.enterFirmName}
          </FloatingLabelInput>
        </div>
      </div>
      <div className="mt-2 text-right __button">
        <Button
          size="small"
          color="white"
          type="button"
          className="mr-2 border-none"
          onClick={() => onCustomInvestorCancel()}
        >
          <span>{lang.cancel}</span>
        </Button>
        <Button
          size="small"
          color="white"
          type="button"
          className="mr-0 preview-btn"
          onClick={() => onCustomInvestorAdded()}
        >
          <span>{lang.add}</span>
        </Button>
      </div>
    </>
  );
};
