import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import {
  GetFeedbackQuery,
  GetFeedbackQueryVariables,
  SendFeedbackMutation,
  SendFeedbackMutationVariables,
} from "gql/graphql";
import useOnClickOutside from "hooks/useOnClickOutside";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { GET_FEEDBACK, SEND_FEEDBACK } from "queries/aia/feedback";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import InfoButton from "./InfoButton";
import { AiaFeedbackProps } from "./interfaces";

const AiaFeedback: FC<AiaFeedbackProps> = ({
  companyId,
  target,
  noComment,
  likeInfo,
  dislikeInfo,
}) => {
  const dispatch = useDispatch();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [comment, setComment] = useState("");
  const { data, loading, refetch } = useQuery<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >(GET_FEEDBACK, {
    variables: {
      companyId,
      key: target,
    },
  });
  const [sendFeedback, { loading: isSending }] = useMutation<
    SendFeedbackMutation,
    SendFeedbackMutationVariables
  >(SEND_FEEDBACK);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(modalRef, () => setIsFeedbackOpen(false));

  const showButtons = !loading && !data?.aia?.feedback?.id;

  const handleSend = async (like?: boolean) => {
    if (isSending) {
      return;
    }

    const finalLike = like ?? isLike;

    try {
      await sendFeedback({
        variables: {
          companyId,
          key: target,
          isLike: finalLike,
          comment,
        },
      });

      dispatch(
        triggerSnack({
          type: "success",
          title: "Feedback sent",
          message: "Your feedback helps us improve",
        })
      );
      refetch();
      setIsFeedbackOpen(false);
    } catch (error) {
      console.log(error);
      dispatch(
        triggerSnack({
          type: "error",
          title: "Couldn't send your feedback",
          message: "Something went wrong",
        })
      );
    }
  };

  const handleAction = (isLike: boolean) => {
    setIsLike(isLike);

    if (noComment) {
      handleSend(isLike);
    } else {
      setIsFeedbackOpen(true);
    }
  };

  return (
    <div className="relative flex items-center">
      {showButtons && (
        <>
          <InfoButton info={likeInfo}>
            <button
              type="button"
              title={likeInfo ? undefined : "Like"}
              onClick={() => handleAction(true)}
              className="flex p-1 text-xs text-gray-500 rounded-full hover:text-aqua-500 hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={["fas", "thumbs-up"]} />
            </button>
          </InfoButton>
          <InfoButton info={dislikeInfo}>
            <button
              type="button"
              title={dislikeInfo ? undefined : "Dislike"}
              onClick={() => handleAction(false)}
              className="flex p-1 ml-1 text-xs text-gray-500 rounded-full hover:text-aqua-500 hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={["fas", "thumbs-down"]} />
            </button>
          </InfoButton>
        </>
      )}
      {isFeedbackOpen && (
        <div
          ref={modalRef}
          className="absolute top-full z-20 left-0 w-[300px] border border-gray-200 p-2 bg-white rounded-lg shadow-lg"
        >
          <h3 className="mb-2 text-xs text-gray-500">
            Want to add additional comment?
          </h3>
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSend();
              }
            }}
            className="w-full px-2 py-1 text-xs border border-gray-200 rounded focus:border-aqua-400"
            placeholder="Optional comment..."
          />
          <div className="flex items-center justify-between px-4 mt-4">
            <div className="text-sm text-gray-500">
              <FontAwesomeIcon
                className="mr-2"
                icon={["fas", isLike ? "thumbs-up" : "thumbs-down"]}
              />
              <span>({isLike ? "Like" : "Dislike"})</span>
            </div>
            <Button onClick={() => handleSend()} color="primary" size="small">
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AiaFeedback;
