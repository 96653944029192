import { createContext, useContext, useRef, useState } from "react";
import { SectionId } from "./constants";

interface ContextType {
  observer: IntersectionObserver;
  activeSectionId: SectionId | undefined;
  availableSections: Map<SectionId, HTMLDivElement>;
}

const options = {
  root: null,
  rootMargin: "-20% 0% -70% 0%",
  threshold: 0.01,
};

const ProfileNavigationContext = createContext<ContextType>({
  observer: new IntersectionObserver(() => {}, options),
  activeSectionId: undefined,
  availableSections: new Map<SectionId, HTMLDivElement>(),
});

ProfileNavigationContext.displayName = "ProfileNavigationContext";

export const ProfileNavigationProvider = (props: any) => {
  const [activeSectionId, setActiveSectionId] = useState<
    SectionId | undefined
  >();

  const callback = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSectionId(entry.target.id as SectionId);
      }
    });
  };

  const observer = useRef(new IntersectionObserver(callback, options));
  const availableSections = useRef(new Map<SectionId, HTMLDivElement>());

  return (
    <ProfileNavigationContext.Provider
      value={{
        observer: observer.current,
        activeSectionId: activeSectionId,
        availableSections: availableSections.current,
      }}
      {...props}
    />
  );
};

export const useProfileNavigationContext = () => {
  const context = useContext(ProfileNavigationContext);

  if (!context) {
    throw new Error(
      "ProfileNavigationContext must be used within a ProfileNavigationProvider"
    );
  }

  return context;
};
