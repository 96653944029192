//@ts-nocheck
import { useSelector } from "react-redux";
import { getActiveCohort } from "models/user/selectors";
import { withRouter } from "hooks/withRouter";
import {
  MarketplaceVisibility,
  LeftContent,
  RightContent,
} from "./MarketplaceVisibleModal.Style";
import Button from "atoms/Button/Button";
import { useLang } from "core/lang/LangaugeSetting";
import { animation } from "ui/animations";

import { ReactComponent as ClappingIcon } from "images/circle-clapping-icon.svg";
import GraphicBG from "images/sircular-graphic-2.png";
import MarketplaceCards from "images/marketplace-cards.svg";
import BackgroundImg from "images/bg-billing.png";

const MarketplaceVisibleModal = (props) => {
  const { lang } = useLang();
  const activeCohort = useSelector(getActiveCohort);

  var divHeight = "100%";
  if (document.getElementById("marketplace")) {
    divHeight = document.getElementById("marketplace").offsetHeight;
  }

  const renderPremiumScreen = () => (
      <div className="flex w-full">
        <LeftContent id="marketplace">
          <ClappingIcon className="w-16 h-16" />
          <h2 className="mt-5 text-2xl font-medium text-black leading-7">
            {`${lang.yourApplicationTo} ${props.acceleratorName} ${lang.hasBeenSubmitted}`}
          </h2>
          <p className="mt-0 text-sm">
            {lang.willGetBackToYouWithinComingDays}
          </p>
          <p className="pb-3 mt-5 mb-5 text-sm text-gray-900">
            {activeCohort?.slug === "invest-stockholm" ? (
              <>
                <span>
                  The information you have shared in your application has been
                  put together to form your startup profile.
                </span>
                <br />
                <span>
                  We will use this profile when communicating with investors and
                  stakeholders of the STHLM FINTECH WEEK.
                </span>
                <br />
                <span>
                  You can view your profile and update the information at any
                  time.
                </span>
              </>
            ) : (
              <>
                {lang.freemiumDesc3} <br />
                {lang.freemiumDesc4} <br />
                {lang.freemiumDesc5}
              </>
            )}
          </p>
          <div className="justify-center w-full">
            <div className="w-full mt-3 text-center">
              <Button
                color="primary"
                size="large"
                className="justify-center w-full"
                onClick={() => props.handleCloseModal()}
              >
                {lang.viewProfile}
              </Button>
            </div>
          </div>
        </LeftContent>
        <RightContent background={GraphicBG} height={divHeight}>
          <div>
            <img
              src={MarketplaceCards}
              alt="marketplace-cards"
              className="w-11/12 h-auto m-auto"
            />
          </div>
        </RightContent>
      </div>
    );

  return (
    <MarketplaceVisibility
      background={BackgroundImg}
      variants={animation}
      animate="show"
      initial="hidden"
      premiumScreen={true}
    >
      <div className="modal-container">
        <div className="modal-body">{renderPremiumScreen()}</div>
      </div>
    </MarketplaceVisibility>
  );
};
export default withRouter(MarketplaceVisibleModal);
