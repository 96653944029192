import Button from "atoms/Button/Button";
import TextArea from "atoms/Form/TextArea";
import { useLang } from "core/lang/LangaugeSetting";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, StepActionButton } from "ui/elements/PanelLayout.styles";
import { goToPreviousStep } from "../../reducer";
import * as sagaActions from "./sagaActions";
import { saveImpact } from "./sagaActions";
import SdgList from "./SdgList";
import { getImpact } from "./selectors";
import { convertStringToBool } from "core/utility";
import { ReactComponent as SDGIcon } from "images/sdg-icon.svg";
import StepActionButton_Stateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import cn from "classnames";

export default () => {
  const { lang } = useLang();
  const dispatch = useDispatch();

  const data = useSelector(getImpact);
  const loading = useSelector((state) => isLoadingEnabled(state, STEP.IMPACT));
  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const sdgItems = Object.values<number>(
    data?.sustainableDevelopmentGoals?.items || {}
  );
  const impactNote = data?.impact?.impactNote || "";
  const isImpact = data?.impact?.isImpact;

  const [sdgs, setSdgs] = useState<number[]>(sdgItems ?? []);
  const [impactNoteInput, setImpactNoteInput] = useState(impactNote ?? "");

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(
      saveImpact({
        lang,
        input: {
          isImpact: isImpact,
          impactNote: impactNoteInput,
          items: sdgs,
        },
      })
    );
  };

  const updateSdg = (sdg: number) => {
    if (sdgs.includes(sdg)) {
      const newSdgs = sdgs.filter((index) => index !== sdg);
      return setSdgs(newSdgs);
    }

    setSdgs([...sdgs, sdg]);
  };

  useEffect(() => {
    setSdgs(sdgItems);
  }, [sdgItems.length]);

  useEffect(() => {
    if (!isImpact) {
      setSdgs([]);
      setImpactNoteInput("");
    }
  }, [isImpact]);

  return (
    <form
      onSubmit={onSubmit}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <SDGIcon />
            <h1 className="ml-3 text-2xl font-normal text-black sm:truncate">
              Impact
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>
              An impact company is an organisation that has a positive impact on
              society, humans, ecosystems, and or the climate. The key
              characteristic of an impact company is that their operations,
              strategy and business models are built around the social impact
              they have set out to create.
            </p>
          </div>
        </div>
      </PageHeader>
      <label className="mb-2 text-sm font-medium text-opacity-100">
        Do you identify as an impact company?
      </label>
      <div className="flex flex-row mb-4">
        <div className="flex items-center mt-0 mr-4 cursor-pointer __custom_input checked:bg-fire-400 checked:border-fire-400">
          <input
            id="yes-impact"
            type="radio"
            name="isImpact"
            className="w-4 h-4 cursor-pointer transition duration-150 ease-in-out form-radio"
            value="true"
            checked={isImpact === true}
            onChange={(e) =>
              dispatch(
                sagaActions.updateIsImpact(convertStringToBool(e.target.value))
              )
            }
          />
          <label
            htmlFor="yes-impact"
            className="ml-2 cursor-pointer hover:bg-gray-200 "
          >
            <span className="block text-sm leading-5">Yes</span>
          </label>
        </div>
        <div className="flex items-center mt-0 mr-4 cursor-pointer __custom_input checked:bg-fire-400 checked:border-fire-400">
          <input
            id="no-impact"
            name="isImpact"
            type="radio"
            className="w-4 h-4 cursor-pointer transition duration-150 ease-in-out form-radio"
            value="false"
            checked={isImpact === false}
            onChange={(e) =>
              dispatch(
                sagaActions.updateIsImpact(convertStringToBool(e.target.value))
              )
            }
          />
          <label
            htmlFor="no-impact"
            className="ml-2 cursor-pointer hover:bg-gray-200"
          >
            <span className="block text-sm leading-5">No</span>
          </label>
        </div>
      </div>
      {isImpact && (
        <>
          <div className="transition duration-150 ease-in-out sm:grid sm:gap-8 sm:items-start">
            <div className="mt-1 grid grid-cols-6 gap-2 sm:mt-0 sm:col-span-2 sm:gap-6">
              <SdgList sdgs={sdgs} updateSdg={updateSdg} />
            </div>
          </div>
          <>
            <label className="mt-6 mb-2 text-sm font-medium text-opacity-100">
              In your own words, how would you describe your impact?
            </label>
            <TextArea
              rows="3"
              id="impactNote"
              className="block w-full duration-150 ease-in-out rounded-md shadow-sm tr-2ansition mt form-textarea sm:text-sm sm:leading-5"
              placeholder="Add note for impact"
              defaultValue={impactNoteInput}
              maxLength={200}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setImpactNoteInput(e.target.value)
              }
            ></TextArea>
          </>
        </>
      )}

      <StepActionButton_Stateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButton_Stateful>
    </form>
  );
};
