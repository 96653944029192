export const createRandom = (seed: number) => () => {
  const x = Math.sin(seed++) * 10000;

  return x - Math.floor(x);
};

// Fisher-Yates shuffle
//
// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
//
export const shuffle = <T>(array: T[], seed: number) => {
  const random = createRandom(seed);
  const clone = array.slice();
  let length = clone.length;

  while (length) {
    const index = Math.floor(random() * length--);
    const tmp = clone[length];

    clone[length] = clone[index];
    clone[index] = tmp;
  }

  return clone;
};
