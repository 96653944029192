// @ts-nocheck
import { Sdg } from "./Sdg.style";
import { ReactComponent as CheckIcon } from "images/circle-check-icon.svg";
import { SDG } from "core/consts";
import Tooltip from "atoms/Tooltip/Tooltip";
import IconSrc1 from "images/sdg/sdg-1.svg";
import IconSrc2 from "images/sdg/sdg-2.svg";
import IconSrc3 from "images/sdg/sdg-3.svg";
import IconSrc4 from "images/sdg/sdg-4.svg";
import IconSrc5 from "images/sdg/sdg-5.svg";
import IconSrc6 from "images/sdg/sdg-6.svg";
import IconSrc7 from "images/sdg/sdg-7.svg";
import IconSrc8 from "images/sdg/sdg-8.svg";
import IconSrc9 from "images/sdg/sdg-9.svg";
import IconSrc10 from "images/sdg/sdg-10.svg";
import IconSrc11 from "images/sdg/sdg-11.svg";
import IconSrc12 from "images/sdg/sdg-12.svg";
import IconSrc13 from "images/sdg/sdg-13.svg";
import IconSrc14 from "images/sdg/sdg-14.svg";
import IconSrc15 from "images/sdg/sdg-15.svg";
import IconSrc16 from "images/sdg/sdg-16.svg";
import IconSrc17 from "images/sdg/sdg-17.svg";

export const sdgIcons = [
  IconSrc1,
  IconSrc2,
  IconSrc3,
  IconSrc4,
  IconSrc5,
  IconSrc6,
  IconSrc7,
  IconSrc8,
  IconSrc9,
  IconSrc10,
  IconSrc11,
  IconSrc12,
  IconSrc13,
  IconSrc14,
  IconSrc15,
  IconSrc16,
  IconSrc17,
].map((src, index) => <img src={src} alt={SDG[index]} />);

// @TODO: use real typings instead of any
//
export default ({ active = false, number, onClick, tooltip = true }: any) => {
  const getContent = () => {
    const component = (
      <Sdg active={active} onClick={onClick}>
        {sdgIcons[number - 1]}
        {active && <CheckIcon className="check-icon" />}
      </Sdg>
    );

    if (tooltip) {
      return (
        <Tooltip content={`${number}. ${SDG[number - 1]}`}>{component}</Tooltip>
      );
    }

    return component;
  };

  return getContent();
};
