// @ts-nocheck
import { getClient } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { triggerSnack } from "organisms/Snack/sagaActions";
import {
  put,
  select,
  takeLatest,
  delay,
  race,
  call,
  take,
} from "redux-saga/effects";

import { getMessages } from "./selectors";
import {
  initializeMessagesPage,
  getContactMessages,
  startWatchWorker,
  stopPolling,
} from "./sagaActions";
import { MESSAGES } from "./constants";
import { updateContactMessages } from "./reducer";
import { GET_CONTACT_MESSAGES } from "queries/general/contact";

const client = getClient();

function* handleInitializeMessagesPage() {
  yield put(
    toggleLoader({
      component: MESSAGES,
      isLoading: true,
    })
  );

  yield call(handleGetContactMessages, {
    payload: { start: 0, limit: 10 },
  });

  yield put(
    toggleLoader({
      component: MESSAGES,
      isLoading: false,
    })
  );
}

const fetchContactMessages = async (start: number, limit: number) => {
  try {
    return await client.query({
      query: GET_CONTACT_MESSAGES,
      fetchPolicy: "no-cache",
      variables: { start, limit },
    });
  } catch (error) {
    console.log(error);
    return { isError: true, error };
  }
};

function* handleGetContactMessages({
  payload: { start, limit },
}: {
  payload: { start: number; limit: number };
}) {
  const { data, isError } = yield fetchContactMessages(start, limit);

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message: "We could not load the messages, please try again later",
      })
    );
    return;
  }

  const { messages, searchCount, totalCount } = data.getContactMessages;

  yield put(
    updateContactMessages({
      messages,
      searchCount,
      totalCount,
      searchStart: start,
    })
  );

  yield put(
    toggleLoader({
      component: MESSAGES,
      isLoading: false,
    })
  );
}

export function* watchWorker() {
  // Starting two concurrent effects. As soon as the take effect 'cancel' is triggered, the 'task' will be cancelled.
  yield race({
    // Start the polling worker
    task: call(pollingWorker),
    // Start a take effect waiting for the cancel action.
    cancel: take("messages/stopPolling"),
  });
}

export function* pollingWorker() {
  while (true) {
    try {
      yield delay(30000);
      const { totalCount, searchStart } = yield select(getMessages);

      if (searchStart > 0) continue;

      const { data, isError } = yield fetchContactMessages(0, 10);

      if (isError) {
        yield put(stopPolling());
      }

      const {
        messages,
        searchCount,
        totalCount: newTotalCount,
      } = data.getContactMessages;

      if (newTotalCount > totalCount) {
        yield put(
          updateContactMessages({
            messages,
            searchCount,
            totalCount: newTotalCount,
            searchStart,
          })
        );
      }
    } catch (error) {
      console.error(error);
      yield put(stopPolling());
    }
  }
}

export default function* rootSaga() {
  return [
    yield takeLatest(initializeMessagesPage, handleInitializeMessagesPage),
    yield takeLatest(getContactMessages, handleGetContactMessages),
    yield takeLatest(startWatchWorker, watchWorker),
  ];
}
