import { Switch, Transition } from "@headlessui/react";
import cn from "classnames";
import { ReactComponent as EmailIcon } from "images/email.svg";
import { ReactComponent as EyeIcon } from "images/eye.svg";
import { ReactComponent as KeyIcon } from "images/key.svg";
import { ReactComponent as Logo } from "images/logo-white.svg";
import GoogleAuth from "organisms/GoogleAuth/GoogleAuth";
import { BackgroundGradient, HeaderDecor } from "pages/onboard/desktop/decors";
import { FC } from "react";
import { Link } from "react-router-dom";
import useLoginForm from "../hooks/useLoginForm";
import CicleDecor from "./CircleDecor";
import Button from "atoms/Button/Button";

const LoginForm: FC = () => {
  const {
    emailValue,
    password,
    isPassword,
    rememberMe,
    error,
    isLoginLoading,
    setIsPassword,
    setEmailValue,
    setRememberMe,
    setPassword,
    onSubmit,
  } = useLoginForm();

  return (
    <div className="relative w-screen h-screen text-white md:overflow-hidden">
      <BackgroundGradient type={"login"} />
      <HeaderDecor spread={false} />
      <CicleDecor />
      <h2 className="relative z-10 w-full mx-auto leading-7 top-4">
        <div className="flex-col">
          <div className="flex flex-col items-center justify-center text-3xl">
            Login to <Logo />
          </div>
        </div>
      </h2>
      <Transition appear show={true}>
        <Transition.Child
          className="relative z-30 flex flex-col items-center w-screen min-h-screen mt-10 overflow-y-auto transition no-srollbar invisible-scroll"
          enter="easy-in-out duration-1000"
          enterFrom="opacity-0 scale-95 translate-y-24"
          enterTo="opacity-100 scale-100 translate-y-0"
          leave="duration-700"
          leaveTo="scale-95 opacity-0 translate-y-96"
        >
          <div className="bg-white flex flex-col items-center w-[400px] mt-10 px-12 pt-4 pb-12 text-gray-800 rounded-lg">
            <div className="bg-[#202337] w-[70px] h-[5px] rounded-md mb-4"></div>
            <div>
              <div className="w-full md:mt-4">
                <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                  <EmailIcon width="24" height="24" />
                  <input
                    type="text"
                    className="w-full ml-2 border-none"
                    placeholder="Email"
                    value={emailValue}
                    onChange={({ target }) => setEmailValue(target.value)}
                  />
                </label>
                <label className="flex items-center w-full p-2 mb-4 border border-gray-300 rounded-lg">
                  <KeyIcon width="24" height="24" />
                  <input
                    type={isPassword ? "password" : "text"}
                    className="w-full ml-2 border-none"
                    placeholder="Password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => setIsPassword((prev) => !prev)}
                    className="rounded-full cursor-pointer"
                  >
                    <EyeIcon width="24" height="24" />
                  </button>
                </label>
                {error && (
                  <p className="text-xs text-fire-400 mb-2 mx-auto text-center max-w-[14rem]">
                    {error?.message}
                  </p>
                )}
                <div className="flex flex-col w-full gap-2">
                  <div className="flex items-center">
                    <Switch
                      checked={rememberMe}
                      onChange={(e: any) => setRememberMe(e)}
                      className={cn(
                        rememberMe ? "bg-aqua-400" : "bg-aqua-200",
                        "relative inline-flex mb-0.5 h-[20px] w-[33px] shrink-0 cursor-pointer rounded-full border-1 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={cn(
                          rememberMe ? "translate-x-3" : "translate-x-0.5",
                          "pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg my-auto ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <label
                      className={cn(
                        "ml-2 text-sm",
                        rememberMe ? "text-gray-800" : "text-gray-500"
                      )}
                    >
                      Remember me
                    </label>
                  </div>
                  <Link
                    className="text-sm text-aqua-400 hover:text-aqua-300"
                    to="/forgot-password"
                  >
                    Forgot password?
                  </Link>
                </div>
                <Button
                  className="flex justify-center w-full py-2 mt-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                  type="submit"
                  onClick={onSubmit}
                  loading={isLoginLoading}
                >
                  Sign In
                </Button>
                <label className="flex items-center justify-center w-full mt-4 text-sm">
                  <span className="text-gray-400">Don't have an account?</span>
                </label>
                <Link
                  className="flex justify-center w-full py-2 mt-2 font-bold bg-white border rounded-lg text-aqua-400 hover:text-white border-aqua-400 hover:bg-aqua-300 disabled:bg-gray-500 disabled:hover:bg-gray-400"
                  to="/onboard"
                >
                  Create Account
                </Link>
                <GoogleAuth type="SIGN_IN" />
              </div>
            </div>
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
};

export default LoginForm;
