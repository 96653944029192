// @ts-nocheck

import { useState, useEffect } from "react";
import "./Form.css";

const FloatingLabelInput = ({
  className,
  type,
  name,
  children,
  icon,
  defaultValue,
  errors,
  reference,
  handleUpdateVal,
  unregister,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(defaultValue);
    return () => {
      unregister && unregister(name);
    };
  }, [defaultValue]);

  const handleChangeValue = (e) => {
    setValue(e.target.value);
    if (handleUpdateVal) {
      handleUpdateVal(e.target.value);
    }
  };

  return (
    <div>
      <div
        className={[
          "flex w-full items-center relative border rounded pt-1 pb-1",
          errors
            ? "border-red-300 text-red-900 border-opacity-1"
            : "text-black border-black border-opacity-25",
        ].join(" ")}
        style={{ marginTop: (value && children) ? 35 : 25 }}
      >
        {icon && <div className="px-2 svg-icon">{icon}</div>}
        <input
          className={`outline-none w-full rounded bg-transparent text-sm transition-all duration-200 ease-in-out p-2 pl-0 text-gray-700 z-50 ${
            !icon && "ml-2"
          }`}
          id={name}
          name={name}
          type={type}
          onKeyUp={handleChangeValue}
          defaultValue={value ? value : ""}
          {...reference}
          style={{ outline: 0, paddingLeft: 0, color: "#16192F" }}
        />
        {
          children && (

        <label
          className={[
            "absolute top-0 flex items-center p-2 transition-all duration-200 ease-in-out text-opacity-50 h-full __editor",
            errors ? "text-sm text-opacity-1 placeholder-red-300" : "text-sm",
            value
              ? "text-opacity-100 font-medium top-[-38px] left-[-9px] text-[#333]"
              : " text-[#6B7280]",
            className ? className : "",
            icon ? "left-7" : "left-0",
          ].join(" ")}
          htmlFor={name}
        >
          {children}
        </label>
          )
        }
      </div>
      {errors && <p className="mt-2 mb-3 text-sm text-fire-400">{errors}</p>}
    </div>
  );
};
export default FloatingLabelInput;
