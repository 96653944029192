import { FC } from "react";

interface Props {
  percentage: number;
}

const ProgressBar: FC<Props> = ({ percentage }) => {
  // Ensure the percentage is within the range of 0 to 100
  const normalizedPercentage = Math.min(Math.max(percentage, 0), 100);

  const barWidth = `${normalizedPercentage}%`;

  return (
    <div className="w-full bg-gray-200 sticky top-0 z-10">
      <div
        className="h-1 bg-aqua-300 transition-all duration-1000"
        style={{ width: barWidth }}
      />
      <div
        className="absolute top-0 text-aqua-400 font-bold text-xl transition-all duration-1000"
        style={{
          left:
            normalizedPercentage <= 10 ? `${normalizedPercentage}%` : undefined,
          right:
            normalizedPercentage > 10
              ? `${100 - normalizedPercentage}%`
              : undefined,
        }}
      >
        {normalizedPercentage.toFixed() + "%"}
      </div>
    </div>
  );
};

export default ProgressBar;
