import { FC } from "react";
import { AiaActivationConfig } from "./consts";
import PureHtml from "atoms/PureHtml";
import cn from "classnames";
interface Props {
  config: typeof AiaActivationConfig.GenerateEmail;
  setSeenActivation: () => void;
  bottomOffset?: number;
}

const POINTER_HEIGHT = 20;

const AiaActivationBubble: FC<Props> = ({
  config,
  setSeenActivation,
  bottomOffset,
}) => {
  const getBottomClass = () => {
    if (bottomOffset) return "top-" + bottomOffset;
    return "top-15";
  };

  return (
    <div
      className={cn(
        "absolute z-50 w-max max-w-sm rounded-xl bg-[#0F3231] left-0",
        config.placement === "bottom" ? getBottomClass() : "bottom-13"
      )}
    >
      {config.placement === "bottom" && (
        <div
          className="w-8 h-6 absolute bg-inherit"
          style={{
            left: "calc(20% - 1rem)",
            clipPath: "polygon(0 0, 0 100%, 100% 100%)",
            top: -POINTER_HEIGHT,
          }}
        />
      )}
      <div className="p-6 flex flex-col">
        <PureHtml content={config.content} className="mb-2 text-white" />
        <button
          className="px-2 py-1 rounded bg-white text-[#0F3231] self-end"
          onClick={() => setSeenActivation()}
        >
          Close
        </button>
      </div>
      {config.placement === "top" && (
        <div
          className="w-8 h-6 absolute bg-inherit"
          style={{
            left: "calc(20% - 0.5rem)",
            clipPath: "polygon(0 0, 100% 0, 0% 100%)",
            bottom: -POINTER_HEIGHT,
          }}
        />
      )}
    </div>
  );
};

export default AiaActivationBubble;
