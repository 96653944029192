import { FC } from "react";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

interface Props {
  description: string | undefined | null;
}

const ProductDescription: FC<Props> = ({ description }) => {
  return (
    <EditablePlaceholderText
      fieldName="product.description"
      title="Product Description"
      addValueTitle="Add product description"
      value={description}
    />
  );
};

export default ProductDescription;
