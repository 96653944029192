import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabContainer from "../TabContainer";
import {
  CREATE_REFERRAL,
  GET_REFERRALS,
} from "queries/general/account/referral";
import { GetReferralsQuery } from "gql/graphql";
import { format } from "date-fns";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useDispatch } from "react-redux";
import { ReactComponent as Mailbox } from "images/mailbox.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const ReferralTab = () => {
  const dispatch = useDispatch();

  const { data, loading } = useQuery<GetReferralsQuery>(GET_REFERRALS);

  const [createReferral] = useMutation(CREATE_REFERRAL, {
    refetchQueries: [{ query: GET_REFERRALS }],
  });

  const handleGenerateLink = async () => {
    try {
      await createReferral();
    } catch (err: any) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Something went wrong",
          message: err.message,
        })
      );
    }
  };

  const onCopy = (e: any) => {
    dispatch(
      triggerSnack({
        type: "success",
        title: " ",
        message: "Link copied to clipboard!",
      })
    );
  };

  const generateLinkFromSlug = (slug: string) =>
    `${window.location.origin}/onboard?referral=${slug}`;

  useEffect(() => {
    if (!data?.me?.referral && !loading) {
      handleGenerateLink();
    }
  }, [data?.me?.referral]);

  return (
    <TabContainer>
      <div className="flex flex-col mb-6 lg:flex-row font-lato">
        <div className="w-full px-6 mt-4 sm:p-0 lg:w-1/2">
          <h1 className="text-xl">Invite your network to Sircular</h1>
          <p className="pr-2 mt-3 mb-4 text-sm text-gray-500 sm:w-4/5">
            <span className="inline-block mb-2">
              Increase your collaboration with your network by inviting them to
              Sircular.
            </span>
            <span className="inline-block mb-2">
              Simply copy the link below and share it in your preferred channels
            </span>
          </p>

          {data?.me?.referral?.slug && (
            <>
              <div className="mt-4 text-sm font-medium">Your referral link</div>
              <CopyToClipboard
                onCopy={onCopy}
                text={generateLinkFromSlug(data?.me?.referral.slug)}
              >
                <div className="flex px-4 py-3 mt-1 bg-gray-100 rounded-full max-w-fit placeholder:text-sm placeholder:italic hover:cursor-pointer">
                  <FontAwesomeIcon icon={["fal", "link"]} className="!w-3" />
                  <span className="ml-2 text-xs truncate">
                    {generateLinkFromSlug(data?.me?.referral.slug)}
                  </span>
                </div>
              </CopyToClipboard>
            </>
          )}
        </div>
        <div className="w-full m-4 lg:w-1/2">
          <div className="w-full p-4 bg-gray-100 xl:w-2/3 rounded-xl">
            <h2 className="text-xl">
              {`You have invited ${
                data?.me?.referral?.referees?.length || "0"
              } friends`}
            </h2>
            {data?.me?.referral?.referees?.length ? (
              <table className="w-full mt-4">
                <thead>
                  <tr>
                    <th className="text-sm text-left text-gray-400">Name</th>
                    <th className="text-sm text-left text-gray-400">
                      Onboarded
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.me?.referral?.referees?.map((referee) => (
                    <tr key={referee?.slug}>
                      <td className="flex items-center ">
                        {/* logo only shown for joined status, a plaseholder for others */}
                        <img
                          src={
                            referee?.logo?.small?.location ||
                            "/assets/images/investor-placeholder-img.png"
                          }
                          alt="logo"
                          className="w-8 h-8 mt-1 mb-1 mr-2 rounded-full"
                        />
                        <div className="flex flex-col">
                          {/* <span className="text-sm">{referee?.}</span> */}
                          <Link
                            to={
                              referee?.type === "company"
                                ? `/public/${referee?.slug}`
                                : `/investor/public/${referee?.slug}`
                            }
                            className="text-sm text-aqua-400"
                          >
                            {referee?.name || "View profile"}
                          </Link>
                        </div>
                      </td>
                      <td className="mt-4 text-sm">
                        {format(new Date(referee?.createdAt), "d MMM yyyy")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <Mailbox className="w-full mt-10 max-h-28" />
                <p className="mt-6 text-center text-black sm:mt-10">
                  You haven't invited anyone yet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </TabContainer>
  );
};

export default ReferralTab;
