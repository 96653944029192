import type { FC } from "react";
import type { TractionItemProps } from "./interfaces";

const TractionItem: FC<TractionItemProps> = ({
  label,
  figure,
  suffix,
  middle,
}) => (
  <div className="flex items-center justify-between w-full px-3 py-1 mt-2 hover:bg-white">
    <div className="text-sm">{label}</div>
    <div>
      <div>
        <span className="font-bold text-aqua-500">{figure}</span>
        {suffix && <span className="ml-1 text-sm text-gray-400">{suffix}</span>}
      </div>
      {middle && <div className="text-sm">{middle}</div>}
    </div>
  </div>
);

export default TractionItem;
