import type { ModalContentProps } from "./interfaces";
import { FC, useState } from "react";
import FileSelect from "atoms/Form/FileSelect";
import Button from "atoms/Button/Button";
import { CSVToJSON } from "./utils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { ReactComponent as GridIcon } from "images/drag-n-drop.svg";
import { useMutation } from "@apollo/client";
import Loading from "atoms/Loading/Loading";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useDispatch } from "react-redux";
import OUR_HEADERS from "./headers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BULK_INSERT_STARTUPS } from "queries/investor/investorDealflow";

const AddBulkUpload: FC<ModalContentProps> = ({
  onChangeMode,
  refetch,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState<"upload" | "match" | "process">("upload");
  const [ourHeaders, setOurHeaders] = useState(OUR_HEADERS);
  const [theirHeaders, setTheirHeaders] = useState<string[]>([]);
  const [theirDefaultHeaders, setTheirDefaultHeaders] = useState<string[]>([]);
  const [error, setError] = useState("");
  const [records, setRecords] = useState<any[]>([]);
  const [bulkInsert] = useMutation(BULK_INSERT_STARTUPS);

  const handleUpload = async (file: File) => {
    const text = await file.text();
    const { headers, records } = CSVToJSON(text);

    setTheirHeaders(headers);
    setTheirDefaultHeaders(headers);
    setRecords(records);
    setStage("match");
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newHeaders = [...theirHeaders];

    newHeaders.splice(result.source.index, 1);
    newHeaders.splice(result.destination.index, 0, result.draggableId);

    setTheirHeaders(newHeaders);
  };

  const handleReset = () => {
    setTheirHeaders(theirDefaultHeaders);
    setOurHeaders(OUR_HEADERS);
  };

  const handleSend = async () => {
    if (theirHeaders.length !== ourHeaders.length) {
      setError(
        "Please match all headers, they must be equal in number. You can remove some headers if you don't need them"
      );
      return;
    }

    setStage("process");

    const items = records.map((record) =>
      ourHeaders.reduce((result, header, index) => {
        const value = record[theirHeaders[index]];

        result[header.key] = header.process(value);

        return result;
      }, {} as Record<string, any>)
    );

    try {
      await bulkInsert({ variables: { items } });

      dispatch(
        triggerSnack({
          title: "Done",
          message: "Startups have been successfully added",
          type: "success",
        })
      );

      refetch();
      onClose();
    } catch {
      dispatch(
        triggerSnack({
          title: "Error occurred",
          message: "Something went wrong. Please try again later",
          type: "error",
        })
      );
      setStage("match");
    }
  };

  return (
    <div className="pt-6">
      {stage === "upload" && (
        <>
          <div className="mb-4 text-sm text-gray-500">
            1. Upload CSV file with startups you want to add
          </div>
          <FileSelect
            type="csv"
            onChange={handleUpload}
            disabled={false}
            loading={false}
            imageDesc="Drop your files here or "
          />
        </>
      )}
      {stage === "match" && (
        <>
          <div className="mb-4 text-sm text-gray-500">
            2. Match headers from CSV to headers we need
          </div>
          {error && <div className="mb-4 text-sm text-red-500">{error}</div>}
          <div className="my-2">
            <Button onClick={handleReset} size="small" color="primary">
              Reset
            </Button>
          </div>
          <div className="flex grid grid-cols-2 stroke-gray-400 gap-x-2">
            <div className="text-gray-500">
              <div className="mb-2 text-sm text-gray-500">Required headers</div>
              {ourHeaders.map((header) => (
                <div
                  className="flex items-center justify-between px-2 py-1 mb-2 bg-gray-100 border border-gray-200 rounded"
                  key={header.key}
                >
                  <span>{header.label}</span>
                  <button
                    type="button"
                    className="flex items-center justify-center w-5 h-5 text-gray-400 rounded-full hover:text-red-400 focus:text-red-400"
                    onClick={() =>
                      setOurHeaders(
                        ourHeaders.filter((h) => h.key !== header.key)
                      )
                    }
                  >
                    <FontAwesomeIcon
                      className="text-xs"
                      icon={["fas", "trash"]}
                    />
                  </button>
                </div>
              ))}
            </div>
            <div>
              <div className="mb-2 text-sm text-gray-500">Your headers</div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="csv-headers">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {theirHeaders.map((header, idx) => (
                        <Draggable
                          key={header}
                          draggableId={header}
                          index={idx}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="pb-2"
                            >
                              <div className="flex items-center justify-between h-full px-2 py-1 bg-white border border-gray-200 rounded">
                                <div className="flex items-center">
                                  <GridIcon width="16px" height="16px" />
                                  <span className="ml-1">{header}</span>
                                </div>
                                <button
                                  type="button"
                                  className="flex items-center justify-center w-5 h-5 text-gray-400 rounded-full hover:text-red-400 focus:text-red-400"
                                  onClick={() =>
                                    setTheirHeaders(
                                      theirHeaders.filter((h) => h !== header)
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    className="text-xs"
                                    icon={["fas", "trash"]}
                                  />
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </>
      )}
      {stage === "process" && (
        <>
          <div className="mb-4 text-sm text-gray-500">
            3. Adding startups to your dealflow
          </div>
          <div className="flex justify-center py-8">
            <Loading size="small" />
          </div>
        </>
      )}
      <div className="flex justify-between mt-8">
        <Button
          onClick={() => onChangeMode("search")}
          size="small"
          color="primary"
        >
          Back
        </Button>
        {stage === "match" && (
          <Button onClick={handleSend} size="small" color="primary">
            Upload {records.length} startups
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddBulkUpload;
