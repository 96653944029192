import { gql } from "@apollo/client";

export const GET_INVESTOR_BY_SLUG = gql`
  query investorBySlug($slug: String!) {
    investorBySlug(slug: $slug) {
      _id
      id
      email
      type
      currency
      country
      investmentCapital
      investmentRounds
      investmentRanges {
        min
        max
      }
      opportunitiesInterestedIn
      opportunitiesNotInterestedIn
      supportAsAdvisor
      fullAddress
      socials {
        linkedin
        instagram
        twitter
      }
      meetingUrl
      investorType
      description
      businessModels
      investmentStage
      investmentStageNote
      industries
      sustainableDevelopmentGoals {
        items
        comment
      }
      priorInvestments {
        id
        slug
        name
        country
        industries
        investmentDate
        investmentRound
      }
      competences
      countriesInvestIn {
        countries
        regions
      }
    }
  }
`;
