import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const DealFlowContainer = styled.div(({ backgroundImg }) => [
  backgroundImg &&
    css`
      .react-trello-board {
        background: url(${backgroundImg});
        background-repeat: no-repeat;
        background-size: cover;
        height: calc(100vh - 165px);
      }
    `,
  css`
    .smooth-dnd-draggable-wrapper.animated {
      transition-duration: 50ms !important;
    }
    .react-trello-board {
      .react-trello-lane > div {
        width: 100%;
        max-height: calc(100vh - 245px);
      }
      .react-trello-lane {
        background: transparent;
        position: relative;
        height: calc(100vh - 168px);
        padding: 10px 20px;
        padding-bottom: 0;
        border-radius: 5px;
        ${media.lessThan("768px")`
                    padding: 8px;           
                `}
        ${media.lessThan("480px")`
            width: 350px !important;
            
        `}
                &:before {
          content: "";
          height: 100vh;
          width: 10px;
          background: #ffffff69;
          position: absolute;
          top: -10px;
          right: -10px;
        }
        &:last-child:before {
          content: none;
        }
        header {
          margin-bottom: 10px;
          justify-content: center;
          align-items: center;
          span {
            width: auto;
            margin-right: 10px;
            font-weight: 500;
            color: #16192f;
            font-size: 16px;
            line-height: 20px;
            &:last-child {
              background: #16192f;
              color: #fff;
              font-weight: 400;
              padding: 0px 5px;
              border-radius: 20px;
              margin-right: 0px;
              min-width: 27px;
              text-align: center;
              span {
                margin: 0;
                font-size: 10px;
                line-height: 18px;
              }
            }
          }
        }
        .smooth-dnd-container {
          height: 100%;
          overflow: auto;
          .no-record-found {
            cursor: auto;
            background: transparent;
            border: none;
            box-shadow: none;
            margin: 0 auto;
            header {
              display: none;
            }
            p {
              margin-top: 15px;
              color: #333333;
              font-size: 14px;
            }
          }
          .react-trello-card {
            max-width: 100%;
            padding-bottom: 0;
            margin-right: 8px;
            border-radius: 5px;
            cursor: move;
            &:hover {
              background-color: #fff;
              header {
                div {
                  button {
                    display: none;
                  }
                }
              }
            }
            header {
              justify-content: space-between;
              border: none;
              padding: 0;
              span {
                display: flex;
                margin: 0;
                padding: 0;
                font-weight: 500;
                .flex {
                  .icon {
                    cursor: pointer;
                    border: 1px solid #6b72803b;
                    border-radius: 5px;
                    margin-right: 5px;
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    svg {
                      position: relative;
                      right: -1px;
                    }
                    &:last-child {
                      margin: 0;
                    }
                  }
                }
                &:first-child {
                  width: 70%;
                  font-size: 18px;
                  cursor: move;
                }
              }
            }
          }
        }
      }
    }
  `,
]);

export const StickyNotes = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 91px;
  height: 100vh;
  background: #fff;
  z-index: 20;
  animation-name: reducetime;
  animation-duration: 400ms;
  max-width: 400px;
  #before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  .title_wrap {
    .__custom {
      right: 0px;
      ul {
        li {
          padding-right: 0;

          span {
          }
          span + span {
            padding-right: 0;
          }
        }
      }
    }
    button {
      width: 180px;
      border-radius: 30px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .close {
    position: absolute;
    right: 25px;
    top: 10px;
    text-align: center;
    line-height: 43px;
    color: #fff;
    font-size: 20px;
    &:hover {
      font-size: 22px;
      transition: 0.04s ease-in;
    }
  }

  .header_wrap {
    position: relative;
    background: #252f3c;
    height: 80px;

    .profile_pic {
      display: flex;
      align-content: center;
      justify-content: space-between;
    }
    .wrapper {
      bottom: -25px;
      z-index: 1;
      .profile_img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        left: 15px;
        position: relative;
        border: 4px solid #fff;
      }
      .bookmark {
        right: 20px;
        position: relative;
        text-align: center;
        line-height: 35px;
        color: #333;
        cursor: pointer;
      }
    }
  }
  @keyframes reducetime {
    0% {
      width: 0;
    }
  }
  .form-textarea.bg-gray-100::placeholder,
  .form-textarea.bg-gray-100::-webkit-input-placeholder,
  .form-textarea.bg-gray-100::-webkit-input-placeholder {
    font-style: italic;
  }
`;
export const ButonGroupListing = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 15px;
  }
  svg {
    cursor: pointer;
  }
`;

export const DocumentListItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  border: 1px solid #979797;
  margin-bottom: 0.5rem;
  border-radius: 8px;
`;

export const InfoItem = styled.div`
  display: flex;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoKey = styled.div`
  margin-right: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  width: calc(40% - 15px);

  ${({ color }) =>
    color &&
    css`
color: $ {
  color
};
`}
`;

export const InfoText = styled.div`
  color: #333333;
  font-size: 14px;
  width: 60%;
`;

export const InfoLink = styled.a`
  color: #18beb3;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: initial;
  justify-content: initial;
  &.text-black {
    color: #000;
    &:hover {
      color: #18beb3 !important;
      text-decoration: underline;
    }
  }
`;

export const SelectOption = styled.div(({ icon }) => [
  tw`text-black`,
  css`
    select {
      padding-right: 30px;
      appearance: none;
      background: url(${icon});
      background-repeat: no-repeat;
      background-position-x: calc(100% - 15px);
      background-position-y: center;
      option {
        &:hover {
          background: red;
        }
        &.active {
          color: #18beb3;
        }
      }
    }
  `,
]);

export const DealroomHeader = styled.div(() => [
  css`
    transition: all 0.4s ease-in;
    background: #16192f;
    width: 100%;
    padding: 0 0 0px 15px;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    ${media.lessThan("768px")`
            width: 100%;
            height: auto;
            display: block;
            padding: 0;
            p{
                padding: 8px 15px 8px 27px;
            }
        `}
  `,
]);

export const DealroomSidebar = styled.div(({ isToggleMenu, position }) => [
  css`
    top: 0;
    height: 100vh;
    width: 200px;
    float: left;
    font-size: 14px;
    background: #fff;
    transition: all 0.3s ease;
    position: ${position};
    ${position === "relative" &&
    `
            height: calc(100vh - 81px);
            transition: all 0.3s ease;
        `}
    ${media.lessThan("768px")`
            // position: absolute;
            // left: -200px;
            // z-index: 2;
            // transition: all 0.3s ease;
            // height: calc(100vh - 55px);
            // ${
              isToggleMenu &&
              `
            //     left: 0;
            //     transition: all 0.3s ease;
            // `
            }

            z-index: 2;
            box-shadow: 0px 1px 10px 0px #bbb;
            position: fixed;
            bottom: 0;
            height: 50px;
            top: auto;
            width: 100%;
            min-height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
  `,
]);

export const Step = styled.div(({ active }) => [
  css`
    padding: 15px 10px;
    display: flex;
    align-items: center;
    color: #00000094;
    cursor: pointer;
    font-size: 14px;
    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
    span {
      height: 20px;
    }
    ${media.lessThan("768px")`
            display: block;
            padding: 11px 10px;
            svg{
                display: block;
                margin: 0 auto 2px auto;
                width: 14px;
                height: 14px;
            }
            span{
                font-size: 13px;
                width: 100%;
                display: block;
                height:12px
            }
        `}
  `,
  active &&
    css`
      color: #000;
      background: #f4f5f7;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 65%;
        width: 5px;
        border-radius: 10px;
        background: #18beb3;
        left: 0;
      }
      ${media.lessThan("768px")`
            background: #fff;
            &:after{
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 3px;
                width: 20px;
            }
        `}
    `,
]);

export const CreateCompany = styled(motion.div)(({ background }) => [
  css`
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: 15px;
    background-color: #e7f8f7;
    background-image: url(${background});
    z-index: 99;
    background-size: cover;
    .modal-container {
      width: 475px;
      .modal-body {
        background: #fff;
        padding: 25px;
        border-radius: 7px;
      }
    }
  `,
]);

export const RightSideContent = styled.div(() => [
  tw`flex`,
  css`
    ${media.lessThan("768px")`
            justify-content: space-between;
            border-top: 1px solid #F4F5F76E;
            padding-left: 15px;
        `}
    button.gray {
      border: none;
      background: #f6f7f7;
      padding: 5px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      svg {
        margin: 0;
      }
    }
    .industires-selection {
      width: 250px;
      margin-left: 10px;
      .space-y-1 > :not(template) ~ :not(template) {
        margin: 0;
      }
      .clear {
        top: -18px;
        right: 6px;
      }
      button {
        border-radius: 50px;
        height: 40px;
        padding: 0 15px;
      }
    }
  `,
]);

export const HeaderSearchBar = styled.div(() => [
  tw`flex w-full py-2 text-gray-700 transition duration-150 ease-in-out bg-transparent border-gray-900 outline-none form-input sm:text-sm`,
  css`
    width: 250px;
    border-radius: 50px;
    background: transparent;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
    &.status-search {
      width: 90%;
      box-shadow: none;
    }
    ${media.lessThan("768px")`
            width: 100%;
            padding: 0;
        `}
  `,
]);

export const DealFlowCard = styled(motion.div)(() => [
  tw`block bg-white`,
  css`
    // box-shadow: 0px 2px 10px #00000029;
    padding-bottom: 20px;
    .card-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      cursor: grab;
      font-size: 18px;
      font-weight: 600;
    }
    .icon {
      cursor: pointer;
      border: 1px solid #6b72803b;
      border-radius: 5px;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        position: relative;
        right: -1px;
      }
      &:last-child {
        margin: 0;
      }
    }
  `,
]);

export const DropdownSection = styled(motion.div)(() => [
  tw`absolute right-0 p-2 bg-white shadow-lg cursor-auto top-8`,
  css`
    h3 {
      cursor: pointer !important;
      font-size: 14px;
      font-weight: 500;
      padding: 5px;
    }
  `,
]);

export const MobileStatusSection = styled(motion.div)(() => [
  tw`fixed w-full`,
  css`
    position: fixed;
    width: 100%;
    background: #00000091;
    z-index: 11;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    .status-div {
      position: fixed;
      width: 100%;
      padding: 0.75rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      --bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--bg-opacity));
      bottom: 0;
      left: 0;
      border-radius: 0.375rem;
    }
  `,
]);

export const CardMemberHeader = styled.div(() => [
  tw`absolute left-0 flex justify-between w-full pt-2 pb-2 pl-5 pr-5 bottom-4`,
  css`
    background: #f3fcfb;
    span {
      color: #18beb3;
      cursor: pointer;
    }
  `,
]);

export const WhiteCard = styled.div(() => [
  tw`w-full p-2 pt-3 mb-3 bg-white rounded`,
  css`
    .dot {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: rgb(173 173 175);
      margin: 0 7px;
    }
    .activity-list {
      height: 300px;
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
        overflow-x: auto;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #000;
        overflow-x: auto;
      }
    }
    .activity-list-notes-section {
      // height: 290px;
      &._max-height {
        max-height: 55vh;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
        overflow-x: auto;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #000;
        overflow-x: auto;
      }
    }
  `,
]);

export const StickyWhiteCard = styled.div(() => [
  tw`w-full p-2 pb-0 bg-white rounded`,
  css`
    .dot {
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background: rgb(173 173 175);
      margin: 0 7px;
    }
    .activity-list {
      height: 300px;
      // &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   border-radius: 10px;
      //   background-color: #f5f5f5;
      //   overflow-x: auto;
      // }
      // &::-webkit-scrollbar {
      //   width: 6px;
      //   height: 6px;
      //   background-color: #000;
      //   overflow-x: auto;
      // }
    }
    .activity-list-notes-section {
      // height: 290px;
      &._max-height {
        //max-height:53vh; @TODO: remove?
      }
      // &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      //   border-radius: 10px;
      //   background-color: #f5f5f5;
      //   overflow-x: auto;
      // }
      // &::-webkit-scrollbar {
      //   width: 6px;
      //   height: 6px;
      //   background-color: #000;
      //   overflow-x: auto;
      // }
    }
  `,
]);

export const ChatListItem = styled.div(() => [
  tw`w-full h-auto mb-4 overflow-auto`,
  css`
    overflow-y: hidden;
    h5 {
      font-weight: 500;
      display: flex;
      font-size: 14px;
      align-items: center;
      img {
        margin-right: 8px;
        height: 25px;
        width: 25px;
      }
      span {
        color: #949495;
        font-weight: normal;
        font-size: 10px;
        &.bg-gray-400 {
          background: #6b7280;
          color: #fff;
          span {
            color: #fff;
          }
        }
      }
    }
  `,
]);

export const ChatMessage = styled.div(() => [
  css`
    width: calc(100% - 30px);
    float: right;
    padding: 2px;
    p {
      padding: 8px;
      background: #fff;
      border-radius: 0.25rem;
      margin: 10px 0;
      font-size: 14px;
      .text-gray-700 {
        display: none;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      span {
        cursor: pointer;
        font-size: 14px;
      }
    }
  `,
]);

export const DocumentListItem = styled.div(() => [
  tw`relative flex justify-between w-full p-2 pr-0 mt-3 mb-3 overflow-hidden bg-gray-100 rounded`,
  css`
    h5 {
      width: calc(100% - 55px);
      .relative {
        width: calc(100% - 45px);
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
        }
      }
    }
  `,
]);

export const ButonGroup = styled.div(() => [
  tw`absolute top-0 right-0 flex items-center h-full text-gray-500 bg-gray-200`,
  css`
    margin: 0 1px;
    svg {
      cursor: pointer;
      margin: 0 7px;
      &:hover {
        opacity: 0.7;
      }
    }
  `,
]);

export const SectionAnimation = styled(motion.div)(() => [
  css`
    ._middle_sec {
      h2 {
        font-size: 24px;
      }
      .__control {
        .__deivde {
          width: 50%;
          padding-right: 0px;
          span {
            font-size: 18px;
          }
        }
        .__last {
          width: 100%;
          margin-top: 30px;
        }
      }
      .investment_scetion {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        span {
          font-size: 12px;
        }
      }
      #action {
        bottom: 90px;
      }
      .toggle-btn {
        bottom: 50px;
      }
    }
    ${media.lessThan("768px")`
    ._mobile_card_wrap {
        width: 200%;
        overflow-x: scroll;

        ._middle_sec {
            width: 90%;
        }
        ._right_sec {
            width: 110%;
        }
        ._right_sec {
            width: 110%;
            position: sticky;
            top: 0px;
        }
    }
    `}
  `,
]);

export const ToggleButton = styled.div(() => [
  tw`absolute text-white cursor-pointer`,
  css`
    display: none;
    ${media.lessThan("768px")`
            display: block;
            background: #FF8060;
            right: -23px;
            padding: 7px;
            border-radius: 15px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            top: 6px;
            z-index: 1;
            `}
  `,
]);

export const UploadDocumentButton = styled.div(() => [
  tw`flex items-center justify-center w-full text-sm border rounded-md border-aqua-400 text-aqua-400`,
  css`
    label {
      text-align: center;
      width: 100%;
      height: 32px;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .fa-spin {
        height: 20px;
        width: 20px;
      }
    }
  `,
]);

export const UploadDocumentButtonSticky = styled.div(() => [
  tw`flex items-center justify-center w-full text-sm rounded-md`,

  css`
    height: 130px;
    border: 2px dashed #a9a9a9;
    label {
      text-align: center;
      width: 100%;
      height: 32px;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .fa-spin {
        height: 20px;
        width: 20px;
      }
    }
  `,
]);

export const DealFlowHeader = styled.div(() => [
  tw`flex items-center justify-between px-3 pt-3 pb-3 bg-white rounded-tl-md rounded-tr-md`,
]);

export const TabLinks = styled.div(({ active }) => [
  tw`flex items-center w-full px-3 py-2`,
  css`
    button {
      margin-right: 10px;
      padding: 3px 8px;
      border-radius: 5px;
      &.active {
        background: #e1efef;
        font-weight: 500;
      }
    }
  `,
]);
