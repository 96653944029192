// @TODO: move common types to general place
import { ColumnDescription } from "pages/dealflow/InvestorDealflow/interfaces";

export const COLUMNS_LIST: ColumnDescription[] = [
  {
    key: "displayName",
    title: "Name",
    isSortable: true,
    isPreview: true,
    isLeftSticky: true,
  },
  {
    key: "createdAt",
    title: "Application",
    isSortable: true,
  },
  {
    key: "type",
    title: "Type",
    isSortable: true,
  },
  {
    key: "country",
    title: "Location",
    isSortable: true,
  },
  {
    key: "ticketSize",
    title: "Ticket Size",
  },
  {
    key: "stage",
    title: "Stage",
  },
  {
    key: "industries",
    title: "Industry",
  },
  {
    key: "relation",
    title: "Business relation",
  },
  {
    key: "isAdvisor",
    title: "Advisor",
  },
  {
    key: "status",
    title: "Status",
    isPreview: true,
  },
  {
    key: "expand",
    title: "",
  },
];
