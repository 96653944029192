import { useLang } from "core/lang/LangaugeSetting";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUser } from "models/user/selectors";
import { Link } from "react-router-dom";
import UserProfileIcon from "molecules/NavigationLayout/UserProfileIcon";
import useBoardLogo from "./useBoardLogo";
import { mixpanelClickSignUpTopNav } from "core/mixpanel/Mixpanel";
import NavbarLogo from "./NavbarLogo";

const TopNavBar = ({
  className,
  toggleMenuDrawer,
}: {
  className?: string;
  toggleMenuDrawer?: () => void;
}) => {
  const { lang } = useLang();
  const user = useSelector(getUser);
  const boardLogo = useBoardLogo();

  return (
    <div className={`sticky top-0 z-50 sm:relative border-b ${className}`}>
      <nav className="relative z-10 navbar bg-neutral-100 dark:bg-neutral-800 w-full">
        {/*if no user we do not render side nav. these classes are to align top nav accoordingly */}
        <div
          className={`${boardLogo ? "max-w-full w-full md:w-auto" : ""} ${
            user || boardLogo ? "sm:mx-16 mx-4" : "sm:mx-auto px-6 w-full"
          }`}
        >
          <div className="flex items-center justify-between h-20">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <NavbarLogo />
              </div>
            </div>

            <div>
              <div className="flex items-center md:hidden lg:mr-0">
                {user && <UserProfileIcon />}
                {user && (
                  <button
                    onClick={toggleMenuDrawer}
                    className="flex items-center justify-center w-12 h-12 text-sm text-black rounded-full focus:outline-none focus:shadow-solid hover:bg-gray-100"
                    id="side-drawer"
                    aria-label="Side Drawer"
                    aria-haspopup="true"
                  >
                    <FontAwesomeIcon
                      icon={["fal", "bars"]}
                      style={{ margin: 0, fontSize: 24 }}
                    />
                  </button>
                )}
              </div>
              {!user && (
                <Link
                  to="/onboard"
                  className="px-4 py-2 mr-2 border rounded-md border-[#18BEB3] text-[#18BEB3]"
                  onClick={mixpanelClickSignUpTopNav}
                >
                  {lang.signUp}
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default TopNavBar;
