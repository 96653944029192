// @ts-nocheck
import { initialState, name } from "./reducer";

const offerModalReducer = (state) => state[name] || initialState;

export const isOfferModalEnabled = (state) => offerModalReducer(state)["isEnabled"];

export const getOfferModalId = (state) => offerModalReducer(state)["data"]?.id;

export const getOfferById = (state) => offerModalReducer(state)["offer"];

export const showOfferRequestVerificationIsEnabled = (state) =>
  offerModalReducer(state)["showOfferRequestVerification"];
