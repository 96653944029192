import type { FC } from "react";
import type { FloatImageDecorProps } from "./interfaces";
import DialogueImage from "images/dialogue.svg";
import HelloImage from "images/investor-info-icon.svg";
import MoneyImage from "images/money-up.svg";
import LookForImage from "images/review.png";
import CelebrateImage from "images/illustration-celebrate.png";
import ReadyImage from "images/illustration-ready.png";
import ImageTransition from "./ImageTransition";

const FloatImageDecor: FC<FloatImageDecorProps> = ({ active }) => (
  <div className="fixed inset-0 z-20 object-cover max-h-screen overflow-hidden">
    <ImageTransition show={active === "basics"}>
      <img
        src={HelloImage}
        alt=""
        width="360px"
        className="-translate-y-24 opacity-80"
      />
    </ImageTransition>
    <ImageTransition show={active === "basics"} right>
      <img
        src={DialogueImage}
        alt=""
        className="translate-y-24 max-w-[400px] max-h-[400px] opacity-80"
      />
    </ImageTransition>
    <ImageTransition show={active === "investment"}>
      <img src={LookForImage} alt="" className="h-[360px] w-auto opacity-80" />
    </ImageTransition>
    <ImageTransition show={active === "investment"} right>
      <img
        src={MoneyImage}
        alt=""
        width="400px"
        className="translate-y-24 opacity-80"
      />
    </ImageTransition>
    <ImageTransition
      show={active === "elevator_pitch" || active === "interests"}
    >
      <img
        src={ReadyImage}
        alt=""
        width="400px"
        className="-translate-y-24 opacity-80"
      />
    </ImageTransition>
    <ImageTransition
      show={active === "elevator_pitch" || active === "interests"}
      right
    >
      <img src={CelebrateImage} alt="" width="400px" className="opacity-80" />
    </ImageTransition>
  </div>
);

export default FloatImageDecor;
