// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isEnabled: false,
  data: null,
  offer: null,
  showOfferRequestVerification: false,
};

export const name = "organisms/reducer/offerModal";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateOfferModalData(state, { payload: { isEnabled, data } }) {
      return {
        ...state,
        isEnabled,
        data,
      };
    },
    getOfferData(state, { payload: { data } }) {
      return {
        ...state,
        offer: data,
      };
    },
    triggerShowOfferRequestVerification(state, { payload: { isEnabled } }) {
      return {
        ...state,
        showOfferRequestVerification: isEnabled,
      };
    },
  },
});

export default slice;

export const {
  updateOfferModalData,
  getOfferData,
  triggerShowOfferRequestVerification,
} = slice.actions;
