// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState: {
  isLoading: boolean;
  isApplicationClosed: boolean;
  registerType: string;
} = {
  isLoading: true,
  isApplicationClosed: false,
  registerType: "",
};

const slice = createSlice({
  name: "register",
  initialState,
  reducers: {
    updateApplicationStatus: (state, { payload: { isClosed } }) => ({
      ...state,
      isApplicationClosed: isClosed,
    }),
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const { updateApplicationStatus } = slice.actions;
