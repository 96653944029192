import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { PlanFeature, PlanProps } from "../../interfaces";
import PlanHeader from "../PlanHeader";
import { ReactComponent as CheckIcon } from "images/circle-check-icon-2.svg";
import ChoosePlanButton from "./ChoosePlanButton";
import PlanFeatures from "../PlanFeatures";
import usePlan from "pages/plans/hooks/usePlan";
import { usePlanContext } from "pages/plans/context/planContext";
import { useLazyQuery } from "@apollo/client";
import { GET_PAYMENT_SESSION_URL } from "queries/general/account/plan";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useLang } from "core/lang/LangaugeSetting";
import { mixpanelClickCheckout } from "core/mixpanel/Mixpanel";

const Plan: FC<PlanProps> = ({
  plan: { features: enabledFeatures, prices, isMostPopular, ...rest },
}: PlanProps) => {
  const { allFeatures, activeSubscription } = usePlan();
  const { selectedFrequency } = usePlanContext();
  const dispatch = useDispatch();
  const { lang } = useLang();

  const isEnabled = (featureId: string) => {
    const index = enabledFeatures.findIndex((f: any) => f.id === featureId);
    return index > -1;
  };

  const isFreePlan = prices.findIndex((price) => price.price === 0) > -1;

  const priceData = prices.find(
    (price) => price.interval === selectedFrequency
  );

  const [getPaymentUrl, { loading, data, error }] = useLazyQuery(
    GET_PAYMENT_SESSION_URL,
    {
      variables: {
        priceId: priceData?.id,
      },
    }
  );

  const handleCheckout = () => {
    mixpanelClickCheckout(priceData?.price, selectedFrequency);
    getPaymentUrl();
  };

  useEffect(() => {
    const paymentUrl = data?.paymentPageUrl;

    if (paymentUrl) {
      window.location.replace(paymentUrl);
    }
  }, [data]);

  if (error) {
    dispatch(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: "Unable to checkout",
      })
    );
  }

  const getFeatureNote = (feature: PlanFeature) => (
    <p
      className={cn(
        isMostPopular ? "text-white font-medium" : "text-[#535161]"
      )}
    >
      {enabledFeatures.find((f) => f.id === feature.id)?.note}
    </p>
  );

  return (
    <div className="relative flex flex-col flex-1 p-12 sm:px-0 lg:p-0 min-w-[250px] lg:last:mr-0">
      <div className="flex flex-col">
        <PlanHeader {...rest} prices={prices} isMostPopular={isMostPopular} />
        <PlanFeatures
          className="hidden sm:block"
          features={allFeatures}
          renderFeature={(feature) => (
            <div
              key={feature.id}
              className={cn(
                "z-20 flex items-center justify-center mb-2 sm:h-20 lg:h-28 xl:h-20",
                isMostPopular && "border-b"
              )}
            >
              {isEnabled(feature.id) &&
                ((feature.note && getFeatureNote(feature)) || <CheckIcon />)}
            </div>
          )}
        />

        <PlanFeatures
          className="block sm:hidden"
          features={enabledFeatures}
          renderFeature={(feature) => (
            <div
              key={feature.id}
              className="z-20 flex items-start mt-5 mb-2 sm:hidden"
            >
              <div className="flex-shrink-0 ">
                <CheckIcon className="w-6 h-6" />
              </div>
              <div className="mb-2 ml-2">
                <p
                  className={cn(
                    isMostPopular
                      ? "text-[#B6B4C5] font-medium"
                      : "text-[#535161]"
                  )}
                >
                  {feature.title}
                </p>
                {feature.note && getFeatureNote(feature)}
                <p
                  className={cn(
                    "text-sm",
                    isMostPopular
                      ? "text-[#B6B4C5] font-medium"
                      : "text-[#A4A2B3]"
                  )}
                >
                  {feature.description}
                </p>
              </div>
            </div>
          )}
        />
        {!isFreePlan && !activeSubscription && (
          <ChoosePlanButton
            isMostPopular={isMostPopular}
            checkout={handleCheckout}
            isLoading={loading}
          />
        )}
      </div>
      {isMostPopular && (
        <div className="bg-[#231D4F] shadow-[0_42px_34px_0px_rgba(82,67,194,0.295755)] absolute top-0 left-0 bottom-0 lg:-top-12 lg:-bottom-6 rounded-2xl w-full" />
      )}
    </div>
  );
};

export default Plan;
