import { useMutation, useQuery } from "@apollo/client";
import Button from "atoms/Button/Button";
import Loading from "atoms/Loading/Loading";
import cn from "classnames";
import {
  mixpanelAiaDeepDive,
  mixpanelAiaFetchMoreCompetitors,
} from "core/mixpanel/Mixpanel";
import {
  AiaCompetitor,
  AiaTaskStatus,
  CompetitorDeepDiveMutation,
  FetchMoreCompetitorsMutation,
  GetAiaTaskQuery,
} from "gql/graphql";
import { getUser } from "models/user/selectors";
import { AiaActivationConfig } from "organisms/AiaActivation/consts";
import AiaFeedback from "organisms/AiaFeedback/AiaFeedback";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { refetchCompetitorsData } from "pages/companyProfile/sagaActions";
import { GET_AIA_TASK } from "queries/aia/task";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPETITOR_DEEP_DIVE,
  FETCH_MORE_COMPETITORS,
} from "../../../../../queries/aia/competitors";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import Competitor from "./Competitor";
import DeepDiveTable from "./DeepDive/DeepDiveTable";
import MarketSegment from "./DeepDive/MarketSegment";
import { Expandable } from "./Expandable";
import ManuallyAddCompetitors from "./ManuallyAddCompetitors";
import CompetitorsSkeleton from "./Skeleton";
import { CompetitorSource, CompetitorsProps } from "./interfaces";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
import WithActivation from "organisms/AiaActivation/WithActivation";
import { useAiaRateLimits } from "organisms/AccessControl/aiaRates";
import AiaRatesModal from "organisms/AccessControl/AiaRateToolTip";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";

const AIA_FEATURE_LIMITS = ["DEEP_DIVE", "FETCH_MORE_COMPETITORS"];

const Competitors: FC<CompetitorsProps> = ({
  competitors,
  marketGrowthDrivers,
  isCompetitorsFromDeckLoading,
  isPerplexityLoading,
  competitorsFinished,
  isValidatingCompetitors,
  companyId,
  isPublic,
  amITeamMember,
  slug,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [isAddManuallyOpen, setIsAddManuallyOpen] = useState(false);
  const [isFetchMore, setIsFetchMore] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [idsDeepDive, setIdsDeepDive] = useState<string[]>([]);
  const [isDeepDive, setIsDeepDive] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [showRateToolTip, setShowRateToolTip] = useState(false);
  const { openModal } = useUpgradePlanModal();
  //Mutations
  const { profileSectionRef } = useProfileNavigation();

  const [analyseCompetitors, { loading: isRequesting }] =
    useMutation<FetchMoreCompetitorsMutation>(FETCH_MORE_COMPETITORS);
  const [competitorDeepDive, { loading: isDeepDiving, data: deepDiveData }] =
    useMutation<CompetitorDeepDiveMutation>(COMPETITOR_DEEP_DIVE);

  //Queries
  const {
    data: taskData,
    loading: isTaskLoading,
    stopPolling,
    startPolling,
  } = useQuery<GetAiaTaskQuery>(GET_AIA_TASK, {
    skip: !taskId,
    variables: {
      id: taskId,
    },
    fetchPolicy: "network-only",
  });

  const { formattedAiaRates, refetch, isRatesLoading } = useAiaRateLimits(
    user?.id,
    AIA_FEATURE_LIMITS
  );

  const deepDiveRates = formattedAiaRates && formattedAiaRates["DEEP_DIVE"];
  const fetchMoreCompetitorsRates =
    formattedAiaRates && formattedAiaRates["FETCH_MORE_COMPETITORS"];

  const isGrantedFetchMore = Boolean(fetchMoreCompetitorsRates?.remaining);
  const isGrantedDeepDive = Boolean(deepDiveRates?.remaining);

  //Handlers
  const handleDeepDive = async () => {
    setIsDeepDive(true);
    await competitorDeepDive({
      variables: { companyId, competitorIds: idsDeepDive },
    });
  };

  const handleAddToDeepDive = (id: string) => {
    if (idsDeepDive.includes(id)) {
      setIdsDeepDive((prev) => prev.filter((item) => item !== id));
    } else {
      setIdsDeepDive((prev) => [...prev, id]);
    }
  };

  const handleRegenerate = async () => {
    const result = await analyseCompetitors({
      variables: {
        companyId,
      },
    });

    const id = result?.data?.aiaCompetitor?.fetchMoreCompetitors?.id;

    if (id) {
      setTaskId(id);
      startPolling(8000);
      setIsFetchMore(true);
    }
  };

  const isRegenerating =
    isRequesting ||
    isTaskLoading ||
    taskData?.aia?.task?.status === AiaTaskStatus.InProgress ||
    isRatesLoading;

  const showButton =
    !isRegenerating && !isPerplexityLoading && !isPublic && amITeamMember;

  const hasCompetitors =
    !isPerplexityLoading && competitors && competitors?.length !== 0;

  useEffect(() => {
    const intervalFn = () => {
      dispatch(refetchCompetitorsData({ id: companyId }));
    };

    if (isFetchMore || isDeepDive) {
      const intervalId = setInterval(intervalFn, 8000);
      return () => clearInterval(intervalId);
    }
  }, [isFetchMore, isDeepDive, deepDiveData]);

  useEffect(() => {
    if (deepDiveData?.aiaCompetitor?.competitorDeepDive?.done) {
      dispatch(refetchCompetitorsData({ id: companyId }));
      refetch({ companyId: user?.id, features: AIA_FEATURE_LIMITS });
      setIsDeepDive(false);
      setIdsDeepDive([]);
      mixpanelAiaDeepDive(
        user,
        deepDivedCompetitors?.map((comp) => comp.name)
      );
    }
  }, [deepDiveData]);

  useEffect(() => {
    const task = taskData?.aia?.task;

    if (task?.status === AiaTaskStatus.Completed) {
      setTaskId("");
      mixpanelAiaFetchMoreCompetitors(user);
      stopPolling();
      setIsFetchMore(false);
    }

    if (task?.status === AiaTaskStatus.Failed) {
      setTaskId("");
      setIsFetchMore(false);
      dispatch(
        triggerSnack({
          type: "error",
          title: "Analysis failed",
          message: "Please try again later",
        })
      );
    }
  }, [companyId, taskData?.aia?.task]);

  const renderEmptyCompetitorsMessage = () => {
    return (
      <p>
        We were unable to find any competitors. Click on Fetch more competitors
        to trigger the competitor search again.
      </p>
    );
  };

  const getCompetitorHeader = (source: CompetitorSource) => {
    switch (source) {
      case CompetitorSource.PROFILE:
        return "Competitors found in the deck are shown below";
      case CompetitorSource.MANUALLY_ADDED:
        return "Manually added competitors are shown below";
      default:
        return "Competitors found on the web are shown below";
    }
  };

  const deepDivedCompetitors = competitors?.filter((comp) => comp.isDeepDive);

  const webCompetitors = competitors?.filter(
    (comp) =>
      (comp?.source === CompetitorSource.WEB ||
        comp?.source === CompetitorSource.PERPLEXITY) &&
      !comp.isDeepDive
  );

  const deckCompetitors = competitors?.filter(
    (comp) => comp?.source === CompetitorSource.PROFILE && !comp.isDeepDive
  );

  const manuallyAddedCompetitors = competitors?.filter(
    (comp) =>
      comp?.source === CompetitorSource.MANUALLY_ADDED && !comp.isDeepDive
  );

  const renderCompetitors = (
    source: CompetitorSource,
    competitors?: AiaCompetitor[]
  ) => {
    const isCompetitorsFromPerplexityLoading =
      isPerplexityLoading && source === CompetitorSource.PERPLEXITY;
    const isCompetitorsFromWebLoading =
      isCompetitorsFromDeckLoading && source === CompetitorSource.PROFILE;

    if (isCompetitorsFromPerplexityLoading || isCompetitorsFromWebLoading) {
      return <CompetitorsSkeleton />;
    }

    return (
      <div className="hidden md:block">
        {competitors && competitors?.length !== 0 ? (
          <div>
            <p className="mb-2 italic mt-3 ml-2">
              {source !== "web" && getCompetitorHeader(source)}
            </p>

            {source !== "web" && (
              <div className="hidden border border-gray-200 rounded md:block">
                <div className="flex px-3 py-3 border-b border-b-gray-200">
                  <div className="p-1 text-sm text-gray-400 basis-1/3 ml-8">
                    Competitor
                  </div>
                  <div className="p-1 text-sm text-gray-400 basis-1/3">
                    Similarity
                  </div>
                  <div className="p-1 text-sm text-gray-400 basis-1/3">
                    Difference
                  </div>
                </div>
              </div>
            )}

            {competitors?.map((item) => {
              return (
                <Competitor
                  key={item.id}
                  item={item}
                  isPublic={isPublic}
                  amITeamMember={amITeamMember}
                  companyId={companyId}
                  idsDeepDive={idsDeepDive}
                  handleAddToDeepDive={handleAddToDeepDive}
                  noOfDeepDives={deepDivedCompetitors?.length}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const disabled = idsDeepDive.length === 0 || isRegenerating;

  const deepDivesLeft =
    deepDiveRates?.limit > 0
      ? `${deepDiveRates?.remaining}/${deepDiveRates?.limit} deep analysis left this week.`
      : false;

  return (
    <div
      id={PROFILE_SECTION_IDS.Competitors}
      ref={profileSectionRef}
      className="mx-auto my-4"
    >
      <div className="mx-auto">
        <div className="flex items-center">
          <SectionHeader title="Competitors" mr={4} mb={0} />

          {hasCompetitors && !isPublic && amITeamMember && (
            <AiaFeedback companyId={companyId} target="competitors_list" />
          )}
          {deepDivesLeft && (
            <p className="text-sm text-gray-500 ml-2">{deepDivesLeft}</p>
          )}
        </div>
        <MarketSegment
          marketGrowthDrivers={marketGrowthDrivers}
          isDeepDiving={isDeepDiving}
        />
        <DeepDiveTable
          deepDivedCompetitors={deepDivedCompetitors}
          isDeepDiveLoading={isDeepDive}
        />
        <div className="flex gap-2 my-4 flex-wrap">
          {isRegenerating && (
            <div className="flex items-center">
              <span className="mr-2 text-sm text-gray-500">
                Loading new competitors
              </span>
              <Loading size="small" />
            </div>
          )}

          {showButton && (
            <div className="relative">
              {showRateToolTip && (
                <AiaRatesModal
                  feature="DEEP_DIVE"
                  aiaRates={deepDiveRates}
                  onClose={() => setShowRateToolTip(false)}
                  translateX={2}
                  translateY={110}
                />
              )}
              <WithActivation
                config={AiaActivationConfig.CompetitorsDeepDive}
                bottomOffset={13}
              >
                <Button
                  color="primary"
                  size="small"
                  className="md:text-sm !text-xs whitespace-nowrap"
                  onClick={() => {
                    if (!isGrantedDeepDive) {
                      setShowRateToolTip(true);
                      return;
                    }

                    if (isGrantedDeepDive) {
                      handleDeepDive();
                    }
                  }}
                  onHover={() => {
                    if (!isGrantedDeepDive) {
                      setShowRateToolTip(true);
                    }
                  }}
                  loading={isDeepDive}
                  disabled={disabled && isGrantedDeepDive}
                >
                  Deep analysis
                </Button>
              </WithActivation>
            </div>
          )}
          {showButton && (
            <WithActivation config={AiaActivationConfig.FetchMoreCompetitors}>
              <Button
                onClick={
                  // checks the tier of the user and shows the upgrade modal if the user is on the lower tier
                  isGrantedFetchMore
                    ? () => handleRegenerate()
                    : () => openModal()
                }
                color="primary"
                size="small"
                className="md:text-sm !text-xs whitespace-nowrap"
                disabled={isRegenerating}
              >
                Fetch more competitors
              </Button>
            </WithActivation>
          )}

          {showButton && (
            <Button
              onClick={() => setIsAddManuallyOpen(true)}
              color="primary"
              size="small"
              className="md:text-sm !text-xs whitespace-nowrap"
              disabled={isRegenerating}
            >
              Add manually
            </Button>
          )}
        </div>
        {competitors &&
          competitors?.length !== 0 &&
          !competitorsFinished &&
          !isValidatingCompetitors &&
          !isPublic &&
          amITeamMember && (
            <p className="mb-2 ml-2 text-sm italic text-gray-500">
              You can fetch more competitors by clicking on Fetch more
              competitors above. Clicking on thumbs down or thumbs up below will
              tailor the search to your perspective.
            </p>
          )}

        {competitors &&
          competitors?.length === 0 &&
          !isCompetitorsFromDeckLoading &&
          !isPerplexityLoading &&
          renderEmptyCompetitorsMessage()}
        {isCompetitorsFromDeckLoading && isPerplexityLoading ? (
          <CompetitorsSkeleton />
        ) : (
          <div>
            <div
              className={cn(
                isShowMore || (competitors && competitors?.length <= 4)
                  ? "max-h-full"
                  : "max-h-[40rem] after:content-[' '] after:left-0 after:h-[10rem] after:absolute after:w-full after:bg-gradient-to-t from-white to-transparent after:bottom-0 after:rounded-t-lg after:z-10 after:transition-all after:duration-200 after:ease-in-out",
                "relative transition-all duration-500 ease-in-out md:w-full overflow-hidden no-scrollbar"
              )}
            >
              {renderCompetitors(
                CompetitorSource.MANUALLY_ADDED,
                manuallyAddedCompetitors as AiaCompetitor[]
              )}
              {renderCompetitors(
                CompetitorSource.PROFILE,
                deckCompetitors as AiaCompetitor[]
              )}
              {renderCompetitors(
                CompetitorSource.PERPLEXITY,
                webCompetitors as AiaCompetitor[]
              )}
            </div>
            {competitors && competitors?.length > 4 && (
              <button
                className="text-gray-500 w-full text-center border-b border-gray-200 bg-gradient-to-b from-white to-gray-50/50 py-1 rounded-md hidden md:block"
                onClick={() => setIsShowMore(!isShowMore)}
              >
                {isShowMore ? "Show less" : "Show more"}
              </button>
            )}
          </div>
        )}
      </div>

      {/* Mobile */}
      <div className="md:hidden">
        {competitors?.map((item, index) => (
          <Expandable competitor={item} key={index} />
        ))}
      </div>
      <ManuallyAddCompetitors
        companyId={companyId}
        isOpen={isAddManuallyOpen}
        slug={slug ?? ""}
        onClose={() => setIsAddManuallyOpen((prev) => !prev)}
      />
    </div>
  );
};

export default Competitors;
