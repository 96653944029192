import { useState } from "react";
import AuthImage from "atoms/AuthImage/AuthImage";
import LanguageSelection from "atoms/Lang/LanguageSelection";
import LanguageModal from "molecules/Lang/LanguageModal";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { useParams } from "react-router-dom";

const ForgotPassword = () => {
  const [openModal, setModal] = useState(true);

  return (
    <div className="flex min-h-screen bg-white">
      <div className="relative flex flex-col flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <ForgotPasswordForm />
      </div>

      <AuthImage />
    </div>
  );
};

export default ForgotPassword;
