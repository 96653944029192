import { SubscriptionUserType } from "../interfaces";

export const mapUserType = (userType: SubscriptionUserType) => {
  switch (userType) {
    case SubscriptionUserType.Startup:
      return "Startups";
    case SubscriptionUserType.Angel:
      return "Angel investor";
    case SubscriptionUserType.VC:
      return "VCs and Family Offices";
    case SubscriptionUserType.Accelerator:
        return "Accelerators";
    default:
      break;
  }
};
