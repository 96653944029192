// @ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthImage from "atoms/AuthImage/AuthImage";
import Loading from "atoms/Loading/Loading";
import { ReactComponent as Logo } from "images/logo-black.svg";
import { useLang } from "core/lang/LangaugeSetting";
import { pageAnimation } from "ui/animations";
import { Page } from "ui/elements/PanelLayout.styles";
import Confirmation from "./components/Confirmation";
import { initialize } from "./sagaActions";
import { CONFIRM_REGISTRATION_BUTTON } from "./constants";

export default (props) => {
  const { token } = props?.params;
  const { lang } = useLang();
  const dispatch = useDispatch();

  //Selectors
  const isLoading = useSelector((state) =>
    isLoadingPage(state, CONFIRM_REGISTRATION_BUTTON)
  );

  useEffect(() => {
    dispatch(initialize({ token, lang }));
  }, [token]);

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
    >
      <div className="flex min-h-screen bg-white">
        <div className="relative flex flex-col justify-center flex-1 w-1/2 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="w-full max-w-sm mx-auto">
            <div className="flex justify-center">
              <Logo className="w-auto h-12" />
            </div>
            {!isLoading && <Confirmation />}
            {isLoading && (
              <p className="flex justify-center p-6">
                {lang.verifyingYourAccount}
              </p>
            )}
            {isLoading && <Loading />}
          </div>
        </div>
        <AuthImage />
      </div>
    </Page>
  );
};
