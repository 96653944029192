import { OpenPositionLinkedIn } from "gql/graphql";
import { FC } from "react";

interface OpenPosProps {
  openPositions: OpenPositionLinkedIn[];
}

const OpenPositions: FC<OpenPosProps> = ({ openPositions }) => {
  return (
    <div className="flex flex-col">
      <div className="grid md:grid-cols-2">
        {openPositions?.map((job) => (
          <div key={job.id} className="my-2">
            <div>
              <a
                href={job.jobUrl || ""}
                target="_blank"
                rel="noreferrer"
                className="text-base font-bold"
              >
                {job.jobTitle}
              </a>
            </div>
            <p className="text-base">Location: {job.location}</p>
            <p className="text-base">Posted: {job.date}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpenPositions;
