// @ts-nocheck
import React from "react";
import ReactPaginate from "react-paginate";
import { Pagination } from "./Pagination.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ entries, ...props }) => (
    props.pageCount > 1 && (
      <Pagination>
        <div className="flex flex-col items-center justify-between sm:flex-row">
          {entries}
          <div className="flex items-center justify-center">
            <ReactPaginate
              {...props}
              breakLabel="..."
              pageRangeDisplayed={3}
              activeClassName="active"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              nextLabel={<FontAwesomeIcon icon={["fal", "angle-right"]} />}
              previousLabel={<FontAwesomeIcon icon={["fal", "angle-left"]} />}
            />
          </div>
        </div>
      </Pagination>
    )
  );
