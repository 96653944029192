import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { Cac as CacType } from "gql/graphql";
import { useDispatch } from "react-redux";
import { GROWTHPERRATEPERIOD, TIME } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";

interface Props {
  cacPayback: Array<CacType | null> | null | undefined;
  note: string | null | undefined;
}

const CacPayback: FC<Props> = ({ cacPayback, note }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const cacPaybackData = cacPayback?.[0];

  const renderContent = () => {
    return (
      <div>
        {cacPaybackData?.amount && cacPaybackData?.frequency ? (
          <div>
            {cacPaybackData.amount} {TIME[cacPaybackData.frequency]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({ inputValue, dropdownValue, noteValue }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          cacPayback: {
            amount: inputValue,
            frequency: dropdownValue,
          },
          cacPaybackNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={cacPaybackData?.amount}
        dropdownDefaultValue={cacPaybackData?.frequency}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(TIME).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="CAC Payback"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={cacPaybackData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default CacPayback;
