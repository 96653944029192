import { useLazyQuery } from "@apollo/client";
import { getGlobalState } from "models/globalState/selectors";
import { getActiveCohort, getUser } from "models/user/selectors";
import { GET_COMPANY_BY_SLUG } from "queries/startup/startupProfile";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMixpanelInvestorProps, setMixpanelStartupProps } from "./Mixpanel";
import { setGlobalState } from "models/globalState/sagaActions";
import { GET_INVESTOR_BY_SLUG } from "queries/investor/investorProfile";
import { Mixpanel } from "./MixpanelWrapper";

const useSetMixpanelUserProps = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUser);
  const isTracked = useSelector((state) => getGlobalState(state, "isTracked"));
  const activeCohort = useSelector(getActiveCohort);

  const [getCompanyBySlug, { data: startupDetails }] = useLazyQuery(
    GET_COMPANY_BY_SLUG,
    { variables: { slug: userData?.slug } }
  );

  const [getInvestorBySlug, { data: investorDetails }] = useLazyQuery(
    GET_INVESTOR_BY_SLUG,
    { variables: { slug: userData?.slug } }
  );

  useEffect(() => {
    if (userData?.isCompany && !isTracked) {
      getCompanyBySlug();
    } else if (userData && !isTracked) {
      getInvestorBySlug();
    }
  }, [userData]);

  useEffect(() => {
    if (startupDetails && !isTracked) {
      Mixpanel.identify(startupDetails?.companyBySlug?.email);
      setMixpanelStartupProps(
        startupDetails,
        activeCohort?.name,
        userData?.referrer,
        userData?.firstName + " " + userData?.lastName,
        userData?.subscription?.status,
        userData?.tier
      );
      dispatch(setGlobalState({ isTracked: true }));
    }
    if (investorDetails && !isTracked) {
      Mixpanel.identify(investorDetails?.investorBySlug?.email);
      setMixpanelInvestorProps(
        investorDetails,
        userData?.referrer,
        userData?.firstName + " " + userData?.lastName,
        userData?.subscription?.status,
        userData?.tier
      );
      dispatch(setGlobalState({ isTracked: true }));
    }
  }, [startupDetails, investorDetails]);
};

export default useSetMixpanelUserProps;
