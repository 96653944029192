export const formatCurrencyValues = (
  value: number,
  currency: string,
  showSymbol?: boolean
) => {
  const formatter = new Intl.NumberFormat("en", {
    style: "currency",
    currency,
    currencyDisplay: showSymbol ? "symbol" : "code",
    notation: "compact",
  });

  return formatter.format(value);
};
