// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const initialize = createAction("reset/init", (payload) => ({
  payload,
}));

export const forgotPassword = createAction("forgot-password", (payload) => ({
  payload,
}));
