import { FC, useEffect } from "react";
import SingleSelect from "./SingleSelect";

interface Props {
  isLabelHide?: any;
  type?: any;
  handleUpdateVal?: any;
  icon?: any;
  onSelectVal?: any;
  inputVal?: any;
  selectName?: any;
  label?: any;
  inputPlaceholder?: any;
  selectOptions?: any;
  selectValue?: any;
  inputName?: any;
  reference?: any;
  errors?: any;
  direction?: any;
}

const SelectionDropdownInput: FC<Props> = ({
  isLabelHide,
  type,
  handleUpdateVal,
  icon,
  onSelectVal,
  inputVal,
  selectName,
  label,
  inputPlaceholder,
  selectOptions,
  selectValue,
  inputName,
  reference,
  errors,
  direction,
}) => {
  useEffect(() => {
    const el = document.getElementById(inputName) as HTMLInputElement;
    if (el) el.value = inputVal;
  }, [inputVal]);

  return (
    <div>
      {!isLabelHide && (
        <label className="block mb-1 text-base font-medium text-opacity-100">
          {label}
        </label>
      )}
      <div
        className={[
          "flex items-center relative border rounded pb-1 pt-1",
          errors
            ? "border-red-300 text-red-900 border-opacity-1"
            : "text-black border-black border-opacity-25",
        ].join(" ")}
      >
        {direction === "right" && icon ? (
          <div className="px-2 svg-icon">{icon}</div>
        ) : (
          ""
        )}
        <div
          style={{
            order: direction === "right" ? 2 : 0,
            marginRight: direction === "right" ? 10 : undefined,
            marginLeft: direction === "left" ? 6 : undefined,
          }}
        >
          <SingleSelect
            label={""}
            id={selectName}
            name={selectName}
            options={selectOptions}
            defaultValue={selectValue}
            direction={direction}
            selectDropdownInput={true}
            onChange={onSelectVal}
          />
        </div>
        <input
          className="w-full p-2 pl-0 text-sm text-gray-700 placeholder-gray-800 transition-all duration-200 ease-in-out bg-transparent rounded outline-none"
          id={inputName}
          name={inputName}
          type={type ? type : "text"}
          defaultValue={inputVal ? inputVal : ""}
          onBlur={(e) =>
            handleUpdateVal ? handleUpdateVal(e.target.value) : ""
          }
          onChange={(e) =>
            handleUpdateVal ? handleUpdateVal(e.target.value) : ""
          }
          {...(reference && reference)}
          placeholder={inputPlaceholder}
          style={{
            marginLeft:
              direction === "right" ? (isLabelHide ? (icon ? 7 : 12) : 7) : 10,
            outline: "none",
          }}
        />
      </div>
      {errors && <p className="mt-2 mb-3 text-sm text-fire-400">{errors}</p>}
    </div>
  );
};
export default SelectionDropdownInput;
