import { Section } from "atoms/Section.style";
import { FC } from "react";
import Investments from "../Investments/Investments";
import {
  DescriptionContainer,
  DescriptionGrid,
  VisionMission,
} from "./About.style";

interface FormerInvestorsProps {
  priorInvestments: string;
  investorNote: string;
}

const FormerInvestors: FC<FormerInvestorsProps> = ({
  priorInvestments,
  investorNote,
}) => {
  if (priorInvestments && priorInvestments.length > 0)
    return (
      <Section className="dark:bg-neutral-900">
        <DescriptionGrid>
          <DescriptionContainer>
            <Investments
              priorInvestments={priorInvestments}
              investorNote={investorNote}
            />
          </DescriptionContainer>
        </DescriptionGrid>
      </Section>
    );

  return null;
};

export default FormerInvestors;
