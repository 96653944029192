import type { FC } from "react";
import type { PaginationProps } from "./interfaces";
import Loading from "atoms/Loading/Loading";
import cn from "classnames";

const PAGE_OFFSET = 2;
const FIRST_PAGE = 1;

const Pagination: FC<PaginationProps> = ({
  page,
  onPageChange,
  total,
  totalPages,
  showingFrom,
  showingTo,
  isLoading,
}) => {
  const leftPoint = Math.max(
    FIRST_PAGE,
    page - PAGE_OFFSET - Math.max(0, PAGE_OFFSET - (totalPages - page))
  );
  const rightPoint = Math.min(
    totalPages,
    page + PAGE_OFFSET + Math.max(0, PAGE_OFFSET - page + 1)
  );
  const hasLeftGap = leftPoint > FIRST_PAGE;
  const hasRightGap = rightPoint < totalPages;
  const length = rightPoint - leftPoint + 1;
  const points = Array.from({ length }, (_, i) => leftPoint + i);

  const renderPoint = (value: number) => (
    <button
      key={value}
      onClick={() => onPageChange(value)}
      className={cn(
        "inline-block w-8 py-1 text-center border border-gray-300 text-gray-400 hover:text-gray-600 hover:bg-gray-100",
        page === value &&
          "text-aqua-400 bg-aqua-50 hover:text-aqua-400 hover:bg-aqua-50 cursor-default"
      )}
    >
      {value}
    </button>
  );

  return (
    <div className="flex flex-wrap items-center justify-center w-full mt-2 ml-4 md:justify-between md:justify-start md:w-auto md:flex-nowrap">
      <div className="flex items-center mr-2 text-sm text-gray-600">
        {total > 0 ? (
          <>
            <span className="hidden md:inline">
              From {showingFrom} to {showingTo} of {total} in the table
            </span>
            <span className="inline-block px-2 py-1 bg-gray-100 border border-gray-300 rounded-lg md:hidden">
              {showingFrom} - {showingTo} ({total} total)
            </span>
          </>
        ) : (
          <span>No data</span>
        )}
        {isLoading && (
          <span className="ml-2 md:hidden">
            <Loading size="small" />
          </span>
        )}
      </div>
      <div className="flex p-1 mr-4">
        {hasLeftGap && (
          <>
            {renderPoint(FIRST_PAGE)}
            <span className="inline-block mx-1">.</span>
          </>
        )}
        {points.map(renderPoint)}
        {hasRightGap && (
          <>
            <span className="inline-block mx-1">.</span>
            {renderPoint(totalPages)}
          </>
        )}
      </div>
    </div>
  );
};

export default Pagination;
