import { FC, useState } from "react";
import Review from "./Review";
import Rating from "./Rating";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Reviews, SimilarApps } from "gql/graphql";
import Tooltip from "atoms/Tooltip/Tooltip";
import Button from "atoms/Button/Button";

const SHOWN_SIMILAR_APPS = 2;

interface Props {
  similarApps: SimilarApps;
}

const SimilarAppsAppStore: FC<Props> = ({ similarApps }) => {
  const [showAll, setShowAll] = useState(false);

  const similarAppsLength = similarApps?.info?.length || 0;

  const toggleShowMore = () => {
    setShowAll((prev) => !prev);
  };

  const sortedSimilarApps = similarApps?.info?.sort((a, b) => {
    const aScore = a?.currentVersionScore || 0;
    const bScore = b?.currentVersionScore || 0;

    return bScore - aScore;
  });

  return (
    <div className="mt-4">
      <div className="mx-auto">
        {sortedSimilarApps && sortedSimilarApps?.length > 0 && (
          <div>
            <div className="hidden lg:block">
              <div className="border-b border-b-gray-200 px-3 py-3 grid grid-cols-4">
                <div className="text-base text-gray-700 mb-4 col-span-1">
                  Similar Apps
                </div>
                <div className="text-gray-400 text-sm col-span-1 p-1">
                  Rating
                </div>
                <div className="text-gray-400 text-sm col-span-1 p-1">
                  Reviews
                </div>
                <div className="text-gray-400 text-sm col-span-1 p-1">
                  Description
                </div>
              </div>
              {sortedSimilarApps?.map((similarApp, index) => (
                <div key={index}>
                  {showAll || index < SHOWN_SIMILAR_APPS ? (
                    <div
                      className="border-b border-b-gray-200 px-3 py-3 grid grid-cols-4"
                      key={index}
                    >
                      <div className="col-span-1">
                        <div className="text-gray-600 text-sm font-semibold">
                          <p className="whitespace-pre-wrap mb-4">
                            {similarApp?.title}
                          </p>
                          <Tooltip content={similarApp?.appStoreUrl || ""}>
                            <a
                              href={
                                similarApp?.appStoreUrl
                                  ? similarApp?.appStoreUrl
                                  : "#"
                              }
                              target="_blank"
                              rel="noreferrer"
                              className={cn(
                                similarApp?.appStoreUrl
                                  ? ""
                                  : "pointer-events-none",
                                "flex items-center w-16 h-16"
                              )}
                            >
                              <img
                                src={similarApp?.icon || ""}
                                className="w-16 h-16 rounded-md mb-4"
                              />
                            </a>
                          </Tooltip>
                          {similarApp?.developerWebsite && (
                            <a
                              href={similarApp?.developerWebsite}
                              target="_blank"
                              rel="noreferrer"
                              className="text-gray-400 text-sm"
                            >
                              <div className="text-gray-400 text-sm">
                                {similarApp?.developerWebsite || ""}
                              </div>
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="text-gray-600 text-sm font-semibold !items-left col-span-1">
                        <Rating
                          rating={(
                            similarApp?.currentVersionScore || 0
                          ).toFixed(2)}
                          noOfReviews={similarApp?.numberOfReviews || 0}
                          ratingStarsize="lg"
                          ratingTextSize="text-lg"
                        />
                      </div>
                      <div className="text-gray-600 text-sm flex flex-col items-left col-span-1">
                        {similarApp?.reviews
                          ?.slice(0, 2)
                          .map((review, reviewIndex) => (
                            <div key={reviewIndex} className="mr-4 mb-2">
                              <Review
                                index={reviewIndex}
                                rating={review?.score || 0}
                                title={review?.title || ""}
                                userName={review?.userName || ""}
                              />
                            </div>
                          ))}
                      </div>
                      <div className="text-gray-600 text-sm flex flex-col items-left col-span-1">
                        <p className="line-clamp-4 hover:line-clamp-none">
                          {similarApp?.description}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
              <div className="flex justify-center mt-4">
                <Button onClick={toggleShowMore} color="primary" size="small">
                  {!showAll && similarAppsLength > SHOWN_SIMILAR_APPS
                    ? "More apps"
                    : "Less apps"}
                </Button>
              </div>
            </div>
            <div className="lg:hidden px-6">
              <div>Similar apps</div>
              {sortedSimilarApps?.map((similarApp, index) => (
                <Expandable
                  key={index}
                  similarApp={similarApp || ([] as any)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Expandable: FC<{
  similarApp: {
    appStoreUrl: string;
    title: string;
    icon: string;
    developerWebsite: string;
    currentVersionScore: number;
    numberOfReviews: number;
    reviews: Reviews[];
    description: string;
  };
}> = ({ similarApp }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div>
      <div
        className={cn(
          "flex py-3 cursor-pointer transition-all duration-500 px-2",
          isExpanded && "flex-col bg-gray-50"
        )}
        onClick={toggleExpanded}
      >
        <div className="flex flex-row justify-between w-full">
          <a
            href={similarApp.appStoreUrl ? similarApp?.appStoreUrl : "#"}
            target="_blank"
            rel="noreferrer"
            className={cn(
              similarApp?.appStoreUrl ? "" : "pointer-events-none",
              "flex items-center"
            )}
          >
            <p className="whitespace-pre-wrap items-center">
              {similarApp?.title}
            </p>
          </a>
          <FontAwesomeIcon
            icon={["fal", isExpanded ? "chevron-up" : "chevron-down"]}
            color="#4fc2ba"
            size="lg"
          />
        </div>

        {isExpanded && (
          <>
            <div className="flex justify-between items-center my-4">
              <div className="text-sm flex flex-row justify-center w-full">
                <div>
                  <img
                    src={similarApp?.icon || ""}
                    className="w-16 h-16 rounded-md mb-2"
                  />
                  <Rating
                    rating={(similarApp?.currentVersionScore || 0).toFixed(2)}
                    noOfReviews={similarApp?.numberOfReviews || 0}
                    ratingStarsize="2x"
                    ratingTextSize="text-lg"
                  />
                </div>

                <div className="flex flex-col items-left">
                  {similarApp?.reviews
                    ?.slice(0, 2)
                    .map((review: Reviews, index: number) => (
                      <div key={index} className="mr-4 mt-1">
                        <Review
                          index={index}
                          rating={review?.score || 0}
                          title={review?.title || ""}
                          userName={review?.userName || ""}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="px-6">
              {similarApp?.description && (
                <p className="">{similarApp?.description}</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SimilarAppsAppStore;
