import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import Button from "atoms/Button/Button";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { ReactComponent as TopNavGraphic } from "images/aia-top-nav.svg";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_PAYMENT_SESSION_URL,
  GET_PLANS_BY_USER,
} from "queries/general/account/plan";
import { useLang } from "core/lang/LangaugeSetting";
import { BillingFrequency, Plan } from "pages/plans/interfaces";
import { PlanPrice, Tier } from "gql/graphql";
import Loading from "atoms/Loading/Loading";
import { getUser } from "models/user/selectors";
import { UPDATE_PLAN } from "queries/general/account/billing";

const ENTERPRISE_PLAN_LINK = "https://cal.com/alexisbeaussant/30min";

interface InvestorPlansProps {
  // whether to show the upgrade modal in profile or not
  isUpgradeModal?: boolean;
  closeModal?: () => void;
}

const billingFrequencies: BillingFrequency[] = [
  "MONTHLY",
  "THREE_MONTHLY",
  "YEARLY",
];

const mapBillingFrequencyHeader = (fr: BillingFrequency) => {
  switch (fr) {
    case "MONTHLY":
      return "MONTHLY";
    case "YEARLY":
      return "YEARLY";
    case "THREE_MONTHLY":
      return "QUARTERLY";
    default:
      break;
  }
};

const InvestorPlanLayout = ({ children }: any) => {
  return (
    <div className="flex flex-col items-center min-h-full bg-[#0B0D18]">
      <TopNavGraphic className="invisible w-full sm:visible" />
      {children}
    </div>
  );
};

const InvestorPlans: FC<InvestorPlansProps> = ({
  isUpgradeModal = false,
  closeModal,
}) => {
  const { data, loading: plansLoading } = useQuery<{ plansByUserType: Plan[] }>(
    GET_PLANS_BY_USER,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getPaymentUrl, { loading }] = useLazyQuery(GET_PAYMENT_SESSION_URL);

  const [upgradePlan] = useMutation(UPDATE_PLAN, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Plan successfull upgraded",
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 300);
    },
    onError: () => {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Something went wrong",
          message: "We could not upgrade",
        })
      );
    },
  });

  const [selectedFrequency, setselectedFrequency] = useState<
    BillingFrequency | undefined
  >(billingFrequencies[1]);

  const user = useSelector(getUser);
  const currentPlan = user?.subscription?.plan.title as Tier;

  const dispatch = useDispatch();
  const { lang } = useLang();

  const plans = data?.plansByUserType;

  const toggleBillingFrequency = (frequency: BillingFrequency) => {
    setselectedFrequency(frequency);
  };

  const handleCheckout = async (price: PlanPrice) => {
    const priceId = price.id;

    if (!priceId) {
      return dispatch(
        triggerSnack({
          type: "error",
          title: lang.somethingWentWrong,
          message: "Unable to checkout",
        })
      );
    }
    await getPaymentUrl({
      variables: {
        priceId,
      },
      onCompleted: (data) => {
        window.location.replace(data?.paymentPageUrl);
      },
      onError: () => {
        dispatch(
          triggerSnack({
            type: "error",
            title: lang.somethingWentWrong,
            message: "Unable to checkout",
          })
        );
      },
    });
  };

  const getNextPlans = (currentPlan: Tier) => {
    switch (currentPlan) {
      case Tier.Pending:
      case Tier.Trial:
        return [Tier.Solo, Tier.Team, Tier.Business];
      case Tier.Solo:
        return [Tier.Team, Tier.Business];
      case Tier.Team:
        return [Tier.Business];
      default:
        return [];
    }
  };

  const getCheckoutText = (plan: Plan) => {
    const selectedPrice = getPrice(plan);

    if (selectedPrice) {
      if (currentPlan === plan.title) {
        return "Current plan";
      }
      if (
        isUpgradeModal &&
        getNextPlans(currentPlan).includes(plan.title as Tier)
      ) {
        return "Upgrade";
      } else {
        return "Select";
      }
    }

    return "Book a call";
  };

  const handleUpgrade = async (price: PlanPrice) => {
    const newPriceId = price.id;
    if (!newPriceId) return;
    // They dont have a existing subscription
    if (currentPlan === Tier.Pending || currentPlan === Tier.Trial) {
      return handleCheckout(price);
    }

    await upgradePlan({
      variables: {
        newPriceId,
      },
    });
  };

  const getPrice = (plan: Plan) => {
    if (!plan) return;
    return plan.prices.find((price) => price.interval === selectedFrequency);
  };

  const renderPrice = (plan: Plan) => {
    if (!plan.prices.length) {
      return (
        <p className="mt-2 text-sm text-center text-white">
          CONTACT SALES FOR PRICING
        </p>
      );
    }

    const { discount, priceBeforeDiscount, price } = getPrice(plan)!;

    return (
      <div className="flex flex-col items-center">
        <div className="flex items-end  space-x-1">
          {discount ? (
            <s className="text=[#FFFFFF]">€{priceBeforeDiscount}</s>
          ) : null}

          <p className={cn("mt-2 mr-2 text-3xl", "text-white")}>€{price}</p>
          <p className="mt-2 mr-1 text-inherit text-md">/ mo</p>
        </div>
        {discount ? <p className="text-[#18BEB3]">{discount}%</p> : null}
      </div>
    );
  };

  const getPlanFeatuersIntro = (plan: Plan) => {
    let text = " ";

    if (plan.title === "BUSINESS") text = "Team plan and ...";
    if (plan.title === "ENTERPRISE") text = "Business plan and ...";

    return (
      <p className="text-sm font-thin text-slate-100 italic h-6">{text}</p>
    );
  };

  return (
    <div className="flex relative font-lato bg-gradient-to-b space-y-6 from-[#0B0D18] via-[#082321] to-[#0C312E] max-w-4xl p-10 mt-0 flex-col items-center mx-auto w-full shadow-xl rounded-xl">
      {plansLoading || !plans ? (
        <Loading />
      ) : (
        <>
          {isUpgradeModal && (
            <button className="absolute top-4 right-4" onClick={closeModal}>
              <FontAwesomeIcon
                className="text-xl text-white"
                icon={["fal", "times"]}
              />
            </button>
          )}

          <p className="text-2xl font-semibold text-white sm:text-3xl">
            Upgrade your account
          </p>

          <div className="flex flex-col items-center justify-center text-white sm:space-x-3 sm:flex-row">
            <p className="text-white">Gain speed </p>
            <span className="hidden text-lg text-white sm:block ">&bull;</span>
            <p className="text-white">Access more data</p>
            <span className="hidden text-lg text-white sm:block">&bull;</span>
            <p className="text-white"> Deepen your analysis</p>
          </div>

          <div className="flex h-9 bg-[#FFFFFF80] rounded-full !mb-2">
            {billingFrequencies?.map((bf) => (
              <button
                key={bf}
                onClick={() => toggleBillingFrequency(bf)}
                className={cn(
                  "flex justify-center items-center h-full px-4  cursor-pointer",
                  selectedFrequency === bf && "bg-[#18BEB3] rounded-full"
                )}
              >
                <span
                  className={cn(
                    "text-sm font-bold",
                    selectedFrequency === bf ? "text-white" : "text-[#0B0D18]"
                  )}
                >
                  {mapBillingFrequencyHeader(bf)}
                </span>
              </button>
            ))}
          </div>

          <div
            className={cn("grid w-full grid-cols-1 gap-4 sm:grid-cols-2", {
              "lg:grid-cols-3": plans?.length === 3,
              "lg:grid-cols-4": plans?.length === 4,
            })}
          >
            {plans.map((plan) => {
              return (
                <div
                  key={plan.id}
                  className={cn(
                    "flex flex-col justify-between p-6 md:p-4 border rounded-2xl",
                    plan.isMostPopular
                      ? "border-[#18BEB3] border-2"
                      : "border-[#FFFFFF]"
                  )}
                >
                  <div className="flex flex-col space-y-2">
                    <p className="md:text-lg text-2xl font-bold text-aqua-300 capitalize">
                      {plan.title.toLowerCase()}
                    </p>

                    <div className="flex items-end my-2 justify-center md:justify-start">
                      <div
                        className={cn(
                          "text-sm flex h-20 items-start",
                          "text-white"
                        )}
                      >
                        {renderPrice(plan)}
                      </div>
                    </div>

                    <div className="space-y-1">
                      {getPlanFeatuersIntro(plan)}
                      {plan.features.map((feature) => (
                        <div
                          key={feature.id}
                          className="flex gap-x-1 items-center"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "check"]}
                            size="xs"
                            color="#53cfc6"
                          />
                          <p className="text-sm font-thin text-slate-100">
                            {feature}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col items-center h-20 mt-4 space-y-2">
                    <Button
                      onClick={async () => {
                        if (isUpgradeModal) {
                          await handleUpgrade(getPrice(plan)!);
                        } else {
                          const planPrice = getPrice(plan);
                          if (!planPrice) {
                            window.open(ENTERPRISE_PLAN_LINK);
                            return;
                          }

                          handleCheckout(planPrice);
                        }
                        closeModal?.();
                      }}
                      disabled={currentPlan === plan.title || loading}
                      color={plan.isMostPopular ? "primary" : "transparent"}
                      className="flex self-center justify-center w-full p-2 mt-4 text-white hover:opacity-90"
                    >
                      <span className="font-bold">{getCheckoutText(plan)}</span>
                    </Button>
                    {getPrice(plan) ? (
                      <p className="italic text-center text-white">
                        Billed{" "}
                        {mapBillingFrequencyHeader(
                          selectedFrequency!
                        )?.toLowerCase()}
                      </p>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export { InvestorPlanLayout, InvestorPlans };
