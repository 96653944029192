import { gql } from "@apollo/client";

// @ts-nocheck
export const GET_OFFER = gql`
  query getOfferById($id: ID!) {
    getOfferById(id: $id) {
      _id
      title
      companyName
      published
      description
      status
      features
      link
      email
      banner {
        large {
          location
        }
      }
      logo {
        large {
          location
        }
      }
    }
  }
`;

export const SEND_OFFER_REQUEST = gql`
  mutation sendOfferRequest($id: ID!) {
    sendOfferRequest(id: $id) {
      _id
      requests {
        totalCount
        nodes {
          company {
            _id
          }
        }
      }
    }
  }
`;
