import { FC } from "react";
import { AiaAnalysisInvestment } from "gql/graphql";
import PotentialOpportunities from "./components/PotentialOpportunities";
import PotentialLossCases from "./components/PotentialLossCases";
import FiveQuestions from "./components/FiveQuestions";

interface Props {
  investmentData: AiaAnalysisInvestment;
}

const InvestmentAnalysis: FC<Props> = ({ investmentData }) => {
  return (
    <>
      {investmentData?.opportunityAnalysis && investmentData?.riskAnalysis && (
        <div className="grid grid-cols-1 sm:grid-cols-2 mt-4 mb-4 gap-4">
          <PotentialOpportunities
            content={investmentData.opportunityAnalysis}
          />
          <PotentialLossCases content={investmentData.riskAnalysis} />
        </div>
      )}
      {investmentData?.questionsFromFounders && (
        <FiveQuestions content={investmentData.questionsFromFounders} />
      )}
    </>
  );
};

export default InvestmentAnalysis;
