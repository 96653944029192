// @ts-nocheck
import { gql } from "@apollo/client";

export const SAVE_FUNDRAISING_INFO = gql`
  mutation saveCompanyFundraisingStep($input: StartupFundraisingInput!) {
    saveCompanyFundraisingStep(input: $input) {
      _id
      currency
      finance {
        raisedFrom
        raisedValue
        raisedTarget
        fundraisingCloseOn
        committed
        note
      }
      investorNote
      investors {
        name
        logo
        displayName
      }
      valuation
    }
  }
`;

export const GET_FUNDRAISING_INFO = gql`
  query getFundraisingInfo {
    me {
      currency
      finance {
        raisedFrom
        raisedValue
        raisedTarget
        fundraisingCloseOn
        committed
        minTicketSize
        investmentStage
        investmentStageNote
        note
      }
      investorNote
      fit
      isActivelyLookingForCapital
      investors {
        name
        logo
        displayName
      }
      valuation
    }
  }
`;

export const SEARCH_INVESTOR = gql`
  query searchInvestor($searchQuery: String) {
    searchInvestors(searchQuery: $searchQuery) {
      nodes {
        displayName
        name
        logo {
          large {
            location
          }
        }
      }
    }
  }
`;
