// @ts-nocheck
import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const MenuDrawer = styled(motion.div)(() => [
  css`
    position: fixed;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    ${media.greaterThan("medium")`

    `}
  `,
]);

export const Menu = styled(motion.div)(() => [
  css`
    background: #fff;
    z-index: 1;
    position: absolute;
    right: 0px;
    height: 100vh;
    overflow: auto;
    width: 350px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${media.lessThan("small")`
    width:100%;
   `}

    &.top {
      justify-content: start;
    }
    &.bottom {
      justify-content: end;
    }

    .menu-wrap {
      & > div:first-child {
        margin-top: 0px;
      }
    }

    .close-menu {
      position: absolute;
      font-size: 30px;
      right: 20px;
      top: 24px;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  `,
]);

export const MenuBackground = styled(motion.div)(() => [
  css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 70%);
    cursor: pointer;
  `,
]);

export const MenuTitle = styled(motion.div)(() => [
  css`
    font-size: 26px;
    margin: 30px 0px 10px;
    font-weight: 600;
  `,
]);

export const MenuGroup = styled(motion.div)(() => [
  css`
    margin: 0px -10px;

    & > a,
    & > div {
      display: flex;
      font-size: 16px;
      margin-bottom: 5px;
      padding: 10px;
      border-radius: 0.375rem;
      cursor: pointer;

      &:hover {
        background: #f9fafb;
      }

      &.active {
        background: #f4f5f7 !important;
      }

      svg {
        margin-right: 12px;
        font-size: 20px;
        width: 25px !important;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  `,
]);
