import Slider from "rc-slider";
import { InvestmentRangeStyle } from "./ComponentStyle";
import { CURRENCY_FORMAT } from "core/consts";
import "rc-slider/assets/index.css";
import { FC } from "react";

const InvestmentRange: FC<any> = ({
  currencyName,
  minRange,
  maxRange,
  changeInvestmentRange,
}) => {
  const log = Math.log;
  const exp = Math.exp;

  return (
    <InvestmentRangeStyle>
      <Slider
        range
        marks={{
          [log(1000)]: `${currencyName} 1k`,
          [log(10000)]: `${currencyName} 10k`,
          [log(100000)]: `${currencyName} 100k`,
          [log(1000000)]: `${currencyName} 1M`,
          [log(5010000)]: `${currencyName} 5M+`,
        }}
        min={log(1000)}
        max={log(5010000)}
        className="custom-range-slider"
        defaultValue={[log(minRange), log(maxRange)]}
        step={(log(5010000) - log(1000)) / 100}
        onChange={(data: any) =>
          changeInvestmentRange(data.map((num: any) => Math.ceil(exp(num))))
        }
        // @ts-ignore
        tipFormatter={(value: any) => CURRENCY_FORMAT(exp(value))}
      />
    </InvestmentRangeStyle>
  );
};

export default InvestmentRange;
