import { FC, useState } from "react";
import { useLang } from "core/lang/LangaugeSetting";
import { Section } from "../../../../../atoms/Section.style";
import { Header, Team } from "./Team.style";
import Skeleton from "react-loading-skeleton";
import { MemberType, OpenPositionLinkedIn } from "gql/graphql";
import AiaFeedback from "organisms/AiaFeedback/AiaFeedback";
import PureHtml from "atoms/PureHtml";
import OpenPositions from "./OpenPositions";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import TeamMemberCard from "./TeamMemberCard";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";
import Button from "atoms/Button/Button";
import ManuallyAddLinkedInMember from "./ManuallyAddLinkedInMember";

// @TODO sort the CEO in BE when saving the data.
interface Props {
  founders?: any[];
  members?: any[];
  isTeamLoading?: boolean;
  teamNote?: string;
  coExperiences?: any;
  previouslyStudiedTogether?: any;
  founderAnalysis?: any;
  sameIndustryExperience: any;
  criticalCompetences: any;
  isTeamAnalysisLoading?: boolean;
  openPositions?: OpenPositionLinkedIn[];
  companyId: string;
  linkedInUrl?: string;
  isPublic?: boolean;
  amITeamMember?: boolean;
  slug?: string;
}

const TeamComponent: FC<Props> = ({
  founders,
  members,
  isTeamLoading,
  teamNote,
  coExperiences,
  previouslyStudiedTogether,
  sameIndustryExperience,
  criticalCompetences,
  isTeamAnalysisLoading,
  openPositions,
  companyId,
  linkedInUrl,
  isPublic,
  amITeamMember,
  slug,
}) => {
  const { lang } = useLang();
  const { profileSectionRef } = useProfileNavigation();
  const [isAddManuallyOpen, setIsAddManuallyOpen] = useState(false);

  const renderFounders = () => {
    if (isTeamLoading)
      return (
        <div className="grid grid-cols-2 mt-4 gap-x-6">
          <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
          <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
          <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
          <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
        </div>
      );

    if (!founders || founders.length === 0) return null;

    const sortedFounders = founders.sort((a, b) => {
      const titleA = a?.title.toLowerCase();
      const titleB = b?.title.toLowerCase();

      if (titleA.includes("ceo")) return -1;
      if (titleB.includes("ceo")) return 1;
      if (!titleA.includes("ceo") && !titleB.includes("ceo"))
        return titleA.localeCompare(titleB);
      return 0;
    });

    return (
      <div className="mt-4">
        <h5>Founders</h5>
        <div className="grid grid-cols-1 gap-2 my-4 md:grid-cols-2">
          {sortedFounders.map((founder: any, index: number) => (
            <TeamMemberCard
              companyId={companyId}
              member={founder}
              key={founder.id}
              index={index}
              type={MemberType.Founder}
              isPublic={isPublic}
              amITeamMember={amITeamMember}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderMembers = () => {
    if (isTeamLoading)
      return (
        <div className="grid grid-cols-2 mt-4 gap-x-6">
          <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
          <Skeleton count={0.3} className="mb-2 !w-16 !h-16" circle />
          <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
          <Skeleton count={2.6} className="my-2" borderRadius="1rem" />
        </div>
      );

    if (!members || members.length === 0) return null;

    const sortedMembers = members.sort((a, b) => {
      const titleA = a?.title.toLowerCase();
      const titleB = b?.title.toLowerCase();

      if (titleA.includes("ceo")) return -1;
      if (titleB.includes("ceo")) return 1;
      if (!titleA.includes("ceo") && !titleB.includes("ceo"))
        return titleA.localeCompare(titleB);
      return 0;
    });

    const cLevelMembers = sortedMembers.filter(
      (m) => /^c\s|^c[a-z]/i.test(m?.title)
    );

    return (
      <div className="mt-4">
        <h5>Members</h5>

        {cLevelMembers && cLevelMembers.length > 0 && (
          <div className="grid grid-cols-1 gap-2 my-4 md:grid-cols-2">
            {cLevelMembers.map((member: any, index: number) => (
              <TeamMemberCard
                companyId={companyId}
                member={member}
                key={member.id}
                index={index}
                type={MemberType.Member}
                isPublic={isPublic}
                amITeamMember={amITeamMember}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderLinkedInUrl = () => {
    if (linkedInUrl)
      return (
        <p className="my-2">
          AIA cannot guarantee the accuracy of the data. Please check the source
          before drawing any conclusions:
          <a
            className="text-blue-400 ml-2"
            title="LinkedIn"
            target="_blank"
            rel="noreferrer"
            href={linkedInUrl}
          >
            LinkedIn Company Profile
          </a>
        </p>
      );
  };

  const renderCompanyOpenPositions = () => {
    // hotfix for loading
    if (isTeamAnalysisLoading && !openPositions?.length)
      return (
        <div className="my-6">
          <h5 className="mt-4 mr-2 text-xl font-medium">Open positions</h5>
          <div className="grid grid-cols-2 gap-x-6">
            <div>
              <Skeleton count={0.5} borderRadius="1rem" />
              <Skeleton count={2} borderRadius="1rem" />
            </div>
            <div>
              <Skeleton count={0.5} borderRadius="1rem" />
              <Skeleton count={2} borderRadius="1rem" />
            </div>
          </div>
        </div>
      );

    if (openPositions && openPositions.length > 0)
      return (
        <div>
          <h5 className="mt-4 mr-2 text-xl font-medium">Open positions</h5>
          <OpenPositions openPositions={openPositions} />
        </div>
      );
    else
      return (
        <div className="mb-4">
          <h5 className="mt-4 mr-2 text-xl font-medium">Open positions</h5>
          <p>The company does not seem to be actively recruiting.</p>
        </div>
      );
  };

  const renderTeamInsights = () => {
    // hotfix for loading

    if (
      isTeamAnalysisLoading &&
      !coExperiences &&
      !previouslyStudiedTogether &&
      !sameIndustryExperience &&
      !criticalCompetences
    )
      return (
        <div className="grid grid-cols-2 mt-4 gap-x-6">
          <Skeleton
            count={0.3}
            className="mb-4"
            height="1.5rem"
            borderRadius="1rem"
            baseColor="#9debe5"
            highlightColor="#66e3da"
          />
          <Skeleton
            count={0.3}
            height="1.5rem"
            className="mb-4"
            borderRadius="1rem"
            baseColor="#9debe5"
            highlightColor="#66e3da"
          />
          <Skeleton
            count={2.6}
            height="1.5rem"
            className="my-2"
            borderRadius="1rem"
            baseColor="#9debe5"
            highlightColor="#66e3da"
          />
          <Skeleton
            count={2.6}
            height="1.5rem"
            className="my-2"
            borderRadius="1rem"
            baseColor="#9debe5"
            highlightColor="#66e3da"
          />
        </div>
      );

    return (
      <div className="mt-4 text-sm p-4 bg-gray-100 rounded">
        <div className="flex items-center mt-2 mb-4">
          <h3 className="mr-2 text-lg text-gray-700">Team Insights</h3>
          {!isPublic && (
            <AiaFeedback companyId={companyId} target="team_analysis" />
          )}
        </div>

        <div className="grid grid-cols-2 gap-4 aiaProfile">
          {coExperiences && (
            <div className="col-span-2 sm:col-span-1">
              <h4 className="mb-2 font-bold text-gray-700">
                Shared work experience
              </h4>
              <p className="mt-2 dark:text-white">{coExperiences}</p>
            </div>
          )}

          {sameIndustryExperience && (
            <div className="col-span-2">
              <h4 className="mb-2 font-bold text-gray-700">
                Industry experience
              </h4>
              <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-sm">
                <PureHtml content={sameIndustryExperience} />
              </div>
            </div>
          )}

          {criticalCompetences && (
            <div className="col-span-2">
              <h4 className="mb-2 font-bold text-gray-700">
                Team assessment on key competences
              </h4>
              <div className="whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper text-sm">
                <PureHtml content={criticalCompetences} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Section
      id={PROFILE_SECTION_IDS.Team}
      ref={profileSectionRef}
      className="overflow-hidden padding-none"
      padding="no-top"
    >
      <div className="mx-auto">
        <Team>
          <Header>
            <div className="w-full dark:text-white/80">
              <SectionHeader title="Meet the Team" />{" "}
              
              <Button
                onClick={() => setIsAddManuallyOpen(true)}
                color="primary"
                size="small"
                className="md:text-sm !text-xs whitespace-nowrap mb-2"
              >
                Add team members manually
              </Button>
              <div className="w-full md:w-3/4 dark:text-gray-400 ">
                <PureHtml
                  content={teamNote ? teamNote : lang.teamDescription}
                />
              </div>
            </div>
          </Header>

          {renderFounders()}

          {renderMembers()}

          {renderLinkedInUrl()}

          {renderCompanyOpenPositions()}

          {renderTeamInsights()}
        </Team>
      </div>
      <ManuallyAddLinkedInMember
        companyId={companyId}
        isOpen={isAddManuallyOpen}
        slug={slug ?? ""}
        onClose={() => setIsAddManuallyOpen((prev) => !prev)}
      />
    </Section>
  );
};

export default TeamComponent;
