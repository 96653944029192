// @ts-nocheck
import { InvestorCard } from "../../CompanySetting.style";
import { ReactComponent as CloseIcon } from "images/gray-close-icon.svg";
import { ReactComponent as UserCircleIcon } from "images/user-circle-icon.svg";

export type InvestorType = {
  name: string;
  displayName: string;
  logo?: string;
};
type InvestorsProp = {
  investors: InvestorType[];
  onRemoveInvestor: (index: number) => void;
};

export default ({ investors, onRemoveInvestor }: InvestorsProp) => (
    <>
      {investors.map(({ name, logo, displayName }, index) => (
          <InvestorCard key={index}>
            {logo ? (
              <img className="w-8 h-8 mr-2 rounded-full" src={logo} />
            ) : (
              <UserCircleIcon />
            )}
            <div>
              <div>{name}</div>
              <p>{displayName}</p>
            </div>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => onRemoveInvestor(index)}
            />
          </InvestorCard>
        ))}
    </>
  );
