// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  GET_TEAM_INFO,
  REMOVE_CO_FOUNDER,
  REMOVE_TEAM_MEMBER,
  SAVE_TEAM_MEMBER,
} from "queries/startup/startupSettings/teamStep";
import { updateTeam, goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

function* getTeam() {
  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_TEAM_INFO,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  yield put(
    updateTeam({
      team: data?.me,
    })
  );

  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: false }));
}

function* saveTeam({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: true }));

  const { isError, error } = yield client
    .mutate({
      mutation: SAVE_TEAM_MEMBER,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.TEAMS, isLoading: false }));
    return;
  }

  yield getTeam();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

function* removeTeamMember({ payload: { lang, ...variables } }) {
  const { isError, error } = yield client
    .mutate({
      mutation: REMOVE_TEAM_MEMBER,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    return;
  }
}

function* removeCoFounder({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: true }));

  yield client
    .query({
      query: REMOVE_CO_FOUNDER,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));

  yield getTeam();

  yield put(toggleLoader({ component: STEP.TEAMS, isLoading: false }));
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getTeam, getTeam),
    yield takeEvery(sagaActions.saveTeam, saveTeam),
    yield takeEvery(sagaActions.removeTeamMember, removeTeamMember),
    yield takeEvery(sagaActions.removeFounder, removeCoFounder),
  ];
}
