import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { FC, Fragment } from "react";
import { useDispatch } from "react-redux";
import type { InviteModalProps } from "./interfaces";
import { mixpanelInviteStartupToDealflow } from "core/mixpanel/Mixpanel";

const InviteModal: FC<InviteModalProps> = ({
  show,
  onClose,
  invitationLink,
}) => {
  const dispatch = useDispatch();

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        triggerSnack({
          type: "success",
          title: "Copied",
          message: "Copied to clipboard.",
        })
      );
      onClose();
      mixpanelInviteStartupToDealflow(text);
    } catch (error: any) {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Copy Failed",
          message: "Couldn't copy, please copy manually.",
        })
      );
    }
  };

  return (
    <Transition show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 bg-white shadow-xl rounded-2xl">
                <div className="flex justify-between mb-4">
                  <h2>Streamline your sources</h2>
                  <button
                    type="button"
                    aria-label="Close"
                    onClick={onClose}
                    className="text-gray-500 rounded-full hover:text-red-500 focus:text-red-500"
                  >
                    <svg
                      className="w-5 h-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {invitationLink ? (
                  <div>
                    <div className="mb-4 text-sm text-gray-500">
                      <p className="mt-2">
                        You can streamline your different dealflow sources into
                        your Sircular account. By sharing the link below with
                        startups, we will structure and score your inbound
                        reach-outs allowing you to focus on the most probable
                        hits. <br />
                      </p>
                      <div className="mt-2">
                        All you have left to do is Follow the ones you like, and
                        pass on the ones you don't.
                      </div>
                      <p className="mt-2 font-bold">
                        Tip: Many investors add the link to their LinkedIn
                        description.
                      </p>
                    </div>
                    <div>
                      <label className="flex flex-col">
                        <span className="text-gray-500">Share link</span>
                        <button
                          type="button"
                          className="flex items-center px-4 py-2 bg-gray-100 rounded-full"
                          onClick={() => copyToClipboard(invitationLink)}
                        >
                          <FontAwesomeIcon
                            className="text-gray-400"
                            icon={["fas", "link"]}
                          />
                          <span className="ml-4 text-sm text-gray-500">
                            {invitationLink}
                          </span>
                        </button>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-500">
                    Sorry, this feature is currently not available for you.
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InviteModal;
