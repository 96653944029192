import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "atoms/Button/Button";
import { DismissActivationModalProps } from "../../types";

const DismissActivationModal: React.FC<DismissActivationModalProps> = ({
  isOpen,
  setIsOpen,
  dismissActivationHandler,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleDismiss = () => {
    dismissActivationHandler();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="mb-4 text-lg font-medium leading-6 text-gray-900"
                >
                  Dismiss this guide?
                </Dialog.Title>

                <Dialog.Description as="p">
                  If you dismiss it, you won’t see
                  <span className="mx-1 font-semibold">
                    Get started with Sircular
                  </span>
                  again.
                </Dialog.Description>

                <div className="flex justify-end mt-6">
                  <Button
                    color="transparent"
                    onClick={handleCancel}
                    className="px-6"
                    size="small"
                  >
                    <span>Cancel</span>
                  </Button>

                  <Button
                    color="primary"
                    onClick={handleDismiss}
                    className="px-6 ml-2"
                    size="small"
                  >
                    <span>Dismiss</span>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DismissActivationModal;
