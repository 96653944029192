import { gql } from "@apollo/client";

export const GET_COMPANY_MEMBERS = gql`
  query getCompanyMembers {
    getCompanyMembers {
      id
      status
      permissions
      user {
        _id
        email
        updatedAt
      }
      inviter {
        id
      }
      lastActive
    }
  }
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember($input: MemberInvitationInput!) {
    inviteMember(input: $input) {
      id
      slug
    }
  }
`;

export const UPDATE_COMPANY_MEMBER_ROLE = gql`
  mutation updateCompanyMemberRole(
    $permissions: [Permission]!
    $companyMemberId: String!
  ) {
    updateCompanyMemberRole(
      permissions: $permissions
      companyMemberId: $companyMemberId
    )
  }
`;

export const UPDATE_COMPANY_MEMBER_STATUS = gql`
  mutation updateCompanyMemberStatus(
    $status: MemberStatus!
    $companyMemberId: String!
  ) {
    updateCompanyMemberStatus(
      status: $status
      companyMemberId: $companyMemberId
    )
  }
`;

export const GET_INVESTOR_TYPE = gql`
  query getInvestorType {
    me {
      _id
      investorType
    }
  }
`;
