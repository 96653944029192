// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};
export const name = "models/loaders";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    toggleLoaderState: (state, { payload: { component, isLoading } }) => ({
        ...state,
        [component]: { isLoading },
      }),
  },
});

export default slice;

export const { toggleLoaderState } = slice.actions;
