import { useState, useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/client";
import { gql } from "@apollo/client";
import { useLang } from "core/lang/LangaugeSetting";
import FormInput from "atoms/Form/FormInput";
import CompanyRow from "./CompanyRow";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";

const SEARCH_COMPANIES = gql`
  query searchCompaniesForBoard($searchQuery: String) {
    searchCompaniesForBoard(searchQuery: $searchQuery) {
      nodes {
        _id
        displayName
        fullAddress
        industries
        logo {
          small {
            location
          }
        }
      }
    }
  }
`;

const AddStartupsToBoard = ({
  addCompanyToSelectedCompanies,
  selectedCompanies,
}) => {
  const selectedCompanyRef = useRef();

  const dispatch = useDispatch();
  const setSnack = (snackParams) => {
    dispatch(triggerSnack(snackParams));
  };

  const client = useApolloClient();
  const { lang } = useLang();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [navigationState, setNavigationState] = useState({
    selectedIndex: -1,
    maxIndex: 9999,
  });

  const onSearchInputChange = (searchQuery) => {
    getStartups(searchQuery);
  };

  const updateSearchResults = (companies) => {
    setNavigationState({
      selectedIndex: -1,
      maxIndex: companies.length - 1,
    });
    setSearchResults(companies);
  };

  const getStartups = async (searchQuery) => {
    setSearchQuery(searchQuery);

    if (!searchQuery) {
      updateSearchResults([]);
      setIsLoading(false);
      return null;
    }

    try {
      setIsLoading(true);

      const { data: res } = await client.query({
        query: SEARCH_COMPANIES,
        fetchPolicy: "network-only",
        variables: {
          searchQuery: searchQuery,
        },
      });

      if (res) {
        const { nodes: companies } = res?.searchCompaniesForBoard;
        updateSearchResults(companies);
      }
    } catch (error) {
      setSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.couldNotLoadCompaniesTryAgainLater,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearchResultClicked = (company) => {
    addCompanyToSelectedCompanies(company);
    setSearchQuery("");
    updateSearchResults([]);
  };

  const isNoResultsFound =
    searchQuery && !isLoading && searchResults.length === 0;
  const isSearching = Boolean(searchQuery);
  const isLoadingAndEmpty = isLoading && searchResults.length === 0;
  const isNoSelectedCompanies = !searchQuery && selectedCompanies.length === 0;

  // @TODO: use classnames library and make an agreement
  // which styling approach we're gonna use in future
  //
  const resultsClassName = [
    "bg-white rounded w-full z-10",
    "h-auto max-h-72 overflow-auto",
    "absolute top-20",
    isSearching && "border border-black border-opacity-25",
    isSearching && "shadow-lg",
    (isNoResultsFound || isLoadingAndEmpty) &&
      "flex align-center justify-center",
  ]
    .filter((className) => className)
    .join(" ");

  const keyDownPressed = () => {
    setNavigationState((prevState) => {
      const { maxIndex, selectedIndex } = prevState;
      return selectedIndex < maxIndex
        ? {
            selectedIndex: selectedIndex + 1,
            maxIndex,
          }
        : prevState;
    });
    if (selectedCompanyRef.current) {
      selectedCompanyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  const keyUpPressed = () => {
    setNavigationState((prevState) => {
      const { maxIndex, selectedIndex } = prevState;
      return selectedIndex > 0
        ? {
            selectedIndex: selectedIndex - 1,
            maxIndex,
          }
        : prevState;
    });
    if (selectedCompanyRef.current) {
      selectedCompanyRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  };

  const enterKeyPressed = () => {
    if (selectedCompanyRef.current) {
      selectedCompanyRef.current.click();
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.keyCode == 38) {
        keyUpPressed();
        return false;
      } else if (e.keyCode == 40) {
        keyDownPressed();
        return false;
      } else if (e.keyCode == 13) {
        enterKeyPressed();
        return false;
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div className="relative mt-5">
      <div className="w-full">
        <FormInput
          name="companies"
          id="companies"
          autoComplete="off"
          label="Add startups to your board"
          placeholder={lang.typeToSearchStartups}
          value={searchQuery}
          onChange={(e) => onSearchInputChange(e.target.value)}
        />
      </div>

      {isNoSelectedCompanies && (
        <div className="absolute flex justify-center w-full pt-8 text-sm text-gray-500">
          Add startups to your board
        </div>
      )}

      <div className={resultsClassName}>
        {isNoResultsFound && (
          <div className="py-4 text-sm text-gray-500">
            {lang.NoResultFoundFourYourSearch}
          </div>
        )}

        {isLoadingAndEmpty && (
          <div className="py-4 text-sm text-gray-500">{lang.loading}...</div>
        )}

        {searchResults
          .filter(
            (startup) => !selectedCompanies.find((sc) => sc._id === startup._id)
          )
          .map((startup, index) => (
              <div
                key={startup._id}
                onClick={() => onSearchResultClicked(startup)}
                className={`px-4 hover:bg-green-100 cursor-pointer ${
                  index === navigationState.selectedIndex ? "bg-green-100" : ""
                }`}
                tabIndex={0}
                ref={
                  index === navigationState.selectedIndex
                    ? selectedCompanyRef
                    : null
                }
              >
                <CompanyRow company={startup} isSearchResult />
              </div>
            ))}
      </div>
    </div>
  );
};

export default AddStartupsToBoard;
