import { gql } from "@apollo/client";

export const LIST_MY_COHORTS_SHORT = gql`
  query listMyCohortsShort {
    listMyCohorts {
      id
      name
    }
  }
`;

export const IS_COHORT_SLUG_AVAILABLE = gql`
  query isCohortSlugAvailable($slug: String!) {
    isCohortSlugAvailable(slug: $slug)
  }
`;

export const CREATE_COHORT = gql`
  mutation createCohort($input: CohortInput!) {
    createCohort(input: $input) {
      id
    }
  }
`;

export const UPDATE_COHORT = gql`
  mutation updateCohort($input: UpdateCohortInput!) {
    updateCohort(input: $input) {
      id
    }
  }
`;

export const ACTIVATE_COHORT = gql`
  mutation activateCohort($id: ID!) {
    activateCohort(id: $id) {
      id
    }
  }
`;

export const DEACTIVATE_COHORT = gql`
  mutation deactivateCohort($id: ID!) {
    deactivateCohort(id: $id) {
      id
    }
  }
`;

export const LIST_MY_COHORTS = gql`
  query listMyCohorts {
    listMyCohorts {
      id
      name
      slug
      createdAt
      isOpen
      header
      description
      closedHeader
      closedDescription
      logo {
        medium {
          location
        }
        small {
          location
        }
      }
      poster {
        medium {
          location
        }
      }
      stats {
        total
        pending
      }
    }
  }
`;
