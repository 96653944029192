import { Fragment, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

type ButtonType = "button" | "submit" | "reset" | undefined;

type ButtonProps = {
  id: string;
  color: string;
  size: string;
  loading: boolean;
  onClick: (e: React.MouseEvent) => void;
  onHover?: (e: React.MouseEvent) => void;
  disabled: boolean;
  children: ReactNode;
  className: string;
  type: ButtonType;
  rectangular: boolean;
};

const Button = ({
  id,
  loading,
  onClick,
  onHover,
  className,
  disabled,
  type,
  children,
  color,
  size,
  rectangular,
}: Partial<ButtonProps>) => (
  <button
    id={id}
    className={cn(
      className,
      "inline-flex items-center px-4 py-2 text-base font-normal leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-full shadow-sm focus:outline-none h-10",
      color === "primary" && "text-white bg-aqua-400 hover:bg-aqua-500",
      color === "secondary" && "text-white bg-fire-400 hover:bg-fire-500",
      color === "warning" && "bg-yellow-400 hover:bg-yellow-500",
      color === "dark" && "bg-midnight",
      color === "gray" && "bg-gray-300",
      color === "white" &&
        "text-aqua-400 bg-white border border-gray-200 border-solid hover:bg-gray-50",
      color === "transparent" &&
        "bg-transparent shadow-none hover:bg-aqua-400 hover:!text-white !border-aqua-400 !text-aqua-400",
      size === "small" && "px-4 py-2 !text-sm h-8",
      rectangular && "!rounded-md",
      disabled && "opacity-50 cursor-not-allowed"
    )}
    type={type}
    onClick={disabled ? undefined : onClick}
    onMouseOver={disabled ? undefined : onHover}
    disabled={disabled}
    onMouseEnter={onHover}
    onMouseLeave={onHover}
  >
    {loading ? (
      <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
    ) : (
      <Fragment>{children}</Fragment>
    )}
  </button>
);

export default Button;
