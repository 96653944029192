/**
 * In this version of the component rendering content of edit mode, handling state
 * and saving data are all responsibility of the parent component.
 * Used for cases that edit mode is NOT a simple input.
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode } from "react";
import Tooltip from "atoms/Tooltip/Tooltip";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import EditButton from "./EditButton";
import cn from "classnames";

interface Props {
  title: string;
  value: string | number | null | undefined;
  tooltipContent?: any;
  style?: "primary" | "secondary";
  isEditMode: boolean;
  setIsEditMode: (v: boolean) => void;
  renderContent?: (data?: any) => ReactNode;
  renderEditModeContent: (data?: any) => ReactNode;
}

/**
 * In this version of the component rendering content of edit mode, handling state
 * and saving data are all responsibility of the parent component.
 * Used for cases that edit mode is NOT a simple input.
 * Note that if renderContent is provided it takes precedence over value.
 */
const EditablePlaceholder: FC<Props> = ({
  value,
  title,
  tooltipContent,
  style = "primary",
  isEditMode,
  setIsEditMode,
  renderContent,
  renderEditModeContent,
}) => {
  const hasContent = Boolean(renderContent) || Boolean(value);

  // If renderContent is not provided value is rendered as a simple string or number
  const getContent = () => {
    if (renderContent) return renderContent();
    return (
      <div className={cn("text-base", style === "primary" && "font-bold")}>
        {value}
      </div>
    );
  };

  const getNoContentplaceholder = () => (
    <div
      className="cursor-pointer flex items-center"
      onClick={() => setIsEditMode(true)}
    >
      <FontAwesomeIcon
        icon={["fal", "plus-circle"]}
        className="mr-2 text-aqua-400"
      />
      <span className="text-aqua-400 text-sm">Add</span>
    </div>
  );

  return (
    <div className={cn("rounded-md p-2", style === "primary" && "bg-white")}>
      <div className="flex justify-between">
        <div
          className={cn(
            "inline-flex gap-x-1 items-center whitespace-nowrap text-sm mb-2 text-gray-600",
            style === "secondary" && "font-bold"
          )}
        >
          <span>{title}</span>
          {tooltipContent && (
            <Tooltip content={tooltipContent}>
              <HelpIcon className="h-4" />
            </Tooltip>
          )}
        </div>
        {!isEditMode && hasContent && (
          <EditButton onClick={() => setIsEditMode(true)} size="small" />
        )}
      </div>
      {isEditMode ? (
        renderEditModeContent()
      ) : (
        <>{hasContent ? getContent() : getNoContentplaceholder()}</>
      )}
    </div>
  );
};

export default EditablePlaceholder;
