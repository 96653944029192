// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const getBusinessModel = createAction(
  "companySettings/business-model/fetch"
);

export const saveBusinessModel = createAction(
  "companySettings/business-model/save",
  (payload) => ({
    payload,
  })
);
