import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { restApiBaseUrl } from "core/config";
import { PROFILE_ANALYTICS_DAYS, PROFILE_ANALYTICS_YEAR } from "core/consts";
import { useLang } from "core/lang/LangaugeSetting";
import { getUser } from "models/user/selectors";
import SingleSelect from "molecules/Form/SingleSelect";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsButton, WhiteCard } from "../MySpace.style";
import AppextChart from "./AppexChart";

const year = Object.entries(PROFILE_ANALYTICS_YEAR)[1][1];
const days = Object.entries(PROFILE_ANALYTICS_DAYS)[0][1];

const GraphComponent = () => {
  const { lang } = useLang();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [timestamp, updateTimeStamp] = useState(Date.now());
  const [state, setState] = useState({
    chartData: [],
    year: year,
    filterType: "visits",
    days: days,
    period: "week",
  });

  const setSnack = (snack) => {
    dispatch(triggerSnack(snack));
  };

  const getStats = async () => {
    // @TODO: refactor
    //

    let years;

    Object.entries(PROFILE_ANALYTICS_YEAR).forEach((year) => {
      if (year[1] === state.year) {
        years = year[0];
        if (years) {
          setState({ ...state, days: lang.monthly });
        }
      }
    });

    const periodMap = {
      7: "week",
      30: "month",
      90: "quarter",
    };

    const fieldMap = {
      visits: "visits",
      uniqueVisits: "visitors",
      downloads: "downloads",
    };

    try {
      const {
        data: {
          data: { chartData, visits, uniqueVisitors, downloads },
        },
      } = await axios.post(`${restApiBaseUrl}/share/UserProfileStats`, {
        period: periodMap[years],
        user: user?._id,
        field: fieldMap[state.filterType],
      });

      const finalChartData = chartData.map(({ date, value }) => ({
        x: date,
        y: String(value),
      }));

      setState({
        ...state,
        chartData: finalChartData,
        statsLoading: false,
        visits: visits,
        downloads: downloads,
        uniqueVisits: uniqueVisitors,
      });

      updateTimeStamp(Date.now());
    } catch (error) {
      setSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotLoadData,
      });
    }
  };

  useEffect(() => {
    getStats();
  }, [state.year, state.filterType]);

  return (
    <WhiteCard>
      <div className="flex flex-col items-center lg:flex-row _search_wrap">
        <div className="w-full lg:w-2/6 __title">
          <h1 className="text-2xl">{lang.profileAnalytics}</h1>
        </div>
        <div className="w-full lg:w-4/6 _item_wrap">
          <div
            className={`flex flex-col md:flex-row md:justify-end justify-start`}
          >
            <div className="flex">
              <div className="mr-3 analytics-dropdown">
                <SingleSelect
                  label={lang.selectOne}
                  name="year"
                  options={Object.values(PROFILE_ANALYTICS_YEAR)}
                  onChange={(year) => setState({ ...state, year })}
                  defaultValue={state.year}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative graph2">
        {state.chartData?.length > 0 ? (
          <>
            <AppextChart
              key={timestamp}
              chartData={state.chartData}
              title={state.title}
              filterType={state.filterType}
              year={state.year}
            />
          </>
        ) : (
          <div className="flex items-center justify-center w-full text-2xl text-aqua-400 h-[350px]">
            {lang.noRecordFound}
          </div>
        )}
      </div>

      <div className="flex max-w-lg mx-auto mt-4">
        <AnalyticsButton
          active={state.filterType === "visits"}
          loading={state.statsLoading}
          onClick={() =>
            setState({
              ...state,
              filterType: "visits",
              statsLoading: "visitsLoading",
            })
          }
        >
          <button>
            {state.statsLoading === "visitsLoading" ? (
              <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
            ) : (
              <div>
                {`${lang.totalVisits}: `}
                <b>{state?.visits ? state.visits : 0}</b>
              </div>
            )}
          </button>
        </AnalyticsButton>
        <AnalyticsButton
          active={state.filterType === "uniqueVisits"}
          loading={state.statsLoading}
          onClick={() =>
            setState({
              ...state,
              filterType: "uniqueVisits",
              statsLoading: "uniqueVisitsLoading",
            })
          }
        >
          <button>
            {state.statsLoading === "uniqueVisitsLoading" ? (
              <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
            ) : (
              <div>
                {`${lang.uniqueVisitors}: `}
                <b>{state?.uniqueVisits ? state.uniqueVisits : 0}</b>
              </div>
            )}
          </button>
        </AnalyticsButton>
        <AnalyticsButton
          active={state.filterType === "downloads"}
          loading={state.statsLoading}
          onClick={() =>
            setState({
              ...state,
              filterType: "downloads",
              statsLoading: "downloadsLoading",
            })
          }
        >
          <button>
            {state.statsLoading === "downloadsLoading" ? (
              <FontAwesomeIcon icon={["fal", "spinner-third"]} spin />
            ) : (
              <div>
                {`${lang.pitchDownloads}: `}
                <b>{state?.downloads ? state.downloads : 0}</b>
              </div>
            )}
          </button>
        </AnalyticsButton>
      </div>
    </WhiteCard>
  );
};
export default GraphComponent;
