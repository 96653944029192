import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { restApiBaseUrl } from "core/config";
import { mixpanelAiaDownloadProfile } from "core/mixpanel/Mixpanel";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { Permission } from "gql/graphql";

interface Props {
  companyId: string;
  companyName: string;
  user: any;
  isDownLoadProfileGranted: boolean;
}

const DownloadProfile: FC<Props> = ({
  companyId,
  companyName,
  user,
  isDownLoadProfileGranted,
}) => {
  const { openModal } = useUpgradePlanModal();

  async function handleDownloadProfilePdf() {
    // Make a request to the server to generate the PDF
    const response = await fetch(`${restApiBaseUrl}/generate-profile-pdf`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ companyId }),
    });

    if (response.ok) {
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = response.headers.get("File-Name") || "profile.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      mixpanelAiaDownloadProfile(user, companyName);
    } else {
      console.error(
        "Failed to generate PDF:",
        response.status,
        response.statusText
      );
    }
  }

  return (
    <button
      className="mt-2 text-aqua-400 underline"
      onClick={isDownLoadProfileGranted ? handleDownloadProfilePdf : openModal}
    >
      <FontAwesomeIcon icon={["fas", "download"]} className="mr-2" />
      <span>Download profile</span>
    </button>
  );
};

export default DownloadProfile;
