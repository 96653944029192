// @ts-nocheck
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Button from "atoms/Button/Button";
//@ts-ignore
import { ReactComponent as BuildingIcon } from "images/building-icon.svg";
import * as sagaActions from "./sagaActions";
import { getProfileInfo } from "./selectors";
import { STEP } from "../../constants";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import { useLang } from "core/lang/LangaugeSetting";
import { isLoadingEnabled } from "models/loaders/selectors";
import { getGlobalState } from "models/globalState/selectors";
//@ts-ignore
import { ReactComponent as ProfileIcon } from "images/profile-icon.svg";
import "react-phone-number-input/style.css";
import StepActionButtonStateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getUser } from "models/user/selectors";
import { uploadLogo } from "models/user/sagaActions";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";

type FormValues = {
  displayName: string;
  orgNumber: string;
  phone: string;
};

type BasicData = FormValues;

const ProfileStepForm = () => {
  const data: BasicData = useSelector(getProfileInfo);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const { lang } = useLang();

  const [phoneError, setPhoneError] = useState();

  const loading = useSelector((state) => isLoadingEnabled(state, STEP.PROFILE));
  const countryCode = useSelector((state) =>
    getGlobalState(state, "countryCode")
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: data });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(
      sagaActions.saveProfileInfo({
        input: {
          displayName: data.displayName,
          phone: data.phone,
          orgNumber: data.orgNumber,
        },
      })
    );
  };

  const onChange = async (file) => {
    dispatch(uploadLogo({ lang, file }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader>
        <div className="flex-1 min-w-0 mb-2">
          <div className="flex items-center">
            <ProfileIcon />
            <h1 className="ml-3 text-2xl font-bold text-black sm:truncate">
              {lang.profile}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>{lang.companyProfileDesc}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <div className="mt-3">
          <label
            className="text-sm font-medium text-opacity-100"
            htmlFor="displayName"
          >
            {lang.startupName}
          </label>
          <div
            className={[
              "flex w-full items-center relative border rounded pt-1 pb-1",
              errors.displayName
                ? "border-red-300 text-red-900 border-opacity-1"
                : "text-black border-black border-opacity-25",
            ].join(" ")}
          >
            <div className="px-2 svg-icon">
              <BuildingIcon />
            </div>
            <input
              className="z-50 w-full p-2 pl-0 text-sm text-gray-700 bg-transparent rounded outline-none"
              id="displayName"
              name="displayName"
              type="text"
              defaultValue={data.displayName}
              {...register("displayName", {
                required: true,
              })}
              placeholder="Enter startup name"
            />
          </div>
          {errors.displayName && (
            <p className="mt-2 mb-3 text-sm text-fire-400">
              {lang.startupNameIsMandatory}
            </p>
          )}
        </div>

        <div className="mt-3">
          <label
            className="text-sm font-medium text-opacity-100"
            htmlFor="orgNumber"
          >
            Org number / VAT number (this will not be shared with other users
            and is only used for account verification)
          </label>
          <div className="relative flex items-center w-full pt-1 pb-1 border border-black border-opacity-25 rounded">
            <div className="px-2 svg-icon">
              <BuildingIcon />
            </div>
            <input
              className="z-50 w-full p-2 pl-0 text-sm text-gray-700 bg-transparent rounded outline-none"
              id="orgNumber"
              name="orgNumber"
              type="text"
              defaultValue={data.orgNumber}
              {...register("orgNumber")}
              placeholder="Enter organisation number"
            />
          </div>
        </div>

        <div className="mt-3">
          <label
            className="block mb-1 text-sm font-medium text-black text-opacity-100"
            style={{ color: "rgb(51, 51, 51)" }}
          >
            {lang.contactNumber}
          </label>
          <div
            style={{ paddingLeft: 10 }}
            className={`py-3 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border ${
              phoneError
                ? "border-red-300 text-red-900 border-opacity-1"
                : "border-black border-opacity-25"
            }`}
          >
            <Controller
              control={control}
              name="phone"
              render={({ field: { value, onChange } }) => (
                <PhoneInput
                  defaultCountry={countryCode || "SV"}
                  placeholder={lang.enterContactNumber}
                  value={value}
                  onChange={(newPhone) => {
                    var phoneErr = newPhone
                      ? isValidPhoneNumber(newPhone)
                        ? undefined
                        : lang.invalidPhoneNumber
                      : false; //lang.mandatory
                    if (phoneErr) {
                      setPhoneError(phoneErr);
                    } else {
                      setPhoneError(undefined);
                    }
                    onChange(newPhone);
                  }}
                  error={phoneError}
                />
              )}
            />
          </div>
          {phoneError && (
            <p className="mt-2 mb-2 text-sm text-fire-400">{phoneError}</p>
          )}
        </div>
        <div className="mt-2">
          <ProfilePictureSelect
            label="Upload logo"
            onComplete={(image) => onChange(image)}
            preview={user?.logo?.large?.location}
          />
        </div>
      </div>

      <StepActionButtonStateful>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButtonStateful>
    </form>
  );
};

export default ProfileStepForm;
