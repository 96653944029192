// @ts-nocheck
import tw, { styled } from "twin.macro";

export const ToolTip = styled.div`
  .custom-tooltip {
    border: 1px solid #18beb3;
    box-shadow: 0px 0px 40px #18be8747;
    max-width: 400px;
  }
`;
