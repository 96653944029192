// @ts-nocheck
import { moduleTypes } from "core/modules";
import { isLoggedinUser } from "models/user/selectors";

const module = [
  {
    path: "/account",
    key: "companyAccount",
    componentId: "companyAccount",
    component: import(/* webpackChunkName: "account" */ "./AccountSettings"),
    routes: [],
    importReducer: () => [],
    module: moduleTypes.ACCOUNT,
    importSagas: () => [],
    isEnabled: (state) => isLoggedinUser(state),
    title: "Account Settings",
    exact: true,
    name: "Account Settings",
  },
  {
    path: "/account",
    key: "investorAccount",
    componentId: "investorAccount",
    component: import(/* webpackChunkName: "account" */ "./AccountSettings"),
    routes: [],
    importReducer: () => [],
    module: moduleTypes.ACCOUNT,
    importSagas: () => [],
    isEnabled: (state) => isLoggedinUser(state),
    title: "Account Settings",
    exact: true,
    name: "Account Settings",
  },
  {
    path: "/account",
    key: "adminAccount",
    componentId: "adminAccount",
    component: import(/* webpackChunkName: "account" */ "./AccountSettings"),
    routes: [],
    importReducer: () => [],
    module: moduleTypes.ACCOUNT,
    importSagas: () => [],
    isEnabled: (state) => isLoggedinUser(state),
    title: "Account Settings",
    exact: true,
    name: "Account Settings",
  },
];

export default module;
