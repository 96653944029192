// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  GET_DOCUMENTS,
  SAVE_DOCUMENTS,
  REMOVE_DOCUMENT,
} from "queries/startup/startupSettings/pitchDeckStep";
import { goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { updateDocuments } from "../../reducer";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

function* getDocuments() {
  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_DOCUMENTS,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  yield put(
    updateDocuments({
      documents: data?.me?.documents || [],
    })
  );

  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: false }));
}

function* saveDocument({ payload: { lang, file } }) {
  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: true }));
  const { isError, error } = yield client
    .mutate({
      mutation: SAVE_DOCUMENTS,
      variables: {
        file,
      },
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: false }));
    return;
  }

  yield getDocuments();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );
}

function* removeDocument({ payload: { lang, id } }) {
  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: REMOVE_DOCUMENT,
      fetchPolicy: "network-only",
      variables: {
        id,
      },
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: false }));
    return;
  }

  yield getDocuments();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.PITCH_DECK, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getDocuments, getDocuments),
    yield takeEvery(sagaActions.saveDocument, saveDocument),
    yield takeEvery(sagaActions.removeDocument, removeDocument),
  ];
}
