import { FC } from "react";
import { PlanFeatureProps } from "../interfaces";

const PlanFeature: FC<PlanFeatureProps> = ({
  feature: { title, description },
}: PlanFeatureProps) => (
  <div className="flex items-center mb-2 sm:pr-5 lg:max-w-[300px] md:h-20 lg:h-28 xl:h-20">
    <div className="mb-2">
      <p className="text-[#535161]">{title}</p>
      <p className="text-[#B6B4C5] text-sm">{description}</p>
    </div>
  </div>
);

export default PlanFeature;
