import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { StarRatingProps } from "./interfaces";

const STARS = [0, 1, 2, 3, 4];

const StarRating: FC<StarRatingProps> = ({ rating }) => {
  const filledStars = Math.floor(rating);

  return (
    <div>
      {STARS.map((i) => (
        <span key={i}>
          <FontAwesomeIcon
            icon={["fas", "star"]}
            className={i < filledStars ? "text-yellow-400" : "text-gray-300"}
          />
        </span>
      ))}
    </div>
  );
};

export default StarRating;
