// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  userData: null,
  isLinkRemoved: false,
  isLoggedInUser: null,
  location: null,
  currencyList: null,
  mysSpaceTutotrialType: 1,
  cohort: null,
  activeCohort: null,
  subscription: null,
};

export const name = "models/user";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateUserData(state, { payload: { userData, isLoggedInUser, hidden } }) {
      return {
        ...state,
        userData,
        isLoggedInUser,
        hidden,
      };
    },
    updateCohort: (state, { payload }) => {
      state.cohort = payload;
    },
    updateLinkStatus: (state, { payload: { isLinkRemoved } }) => ({
      ...state,
      isLinkRemoved,
    }),
    updateCurrencyList: (state, { payload: { currencyList } }) => ({
      ...state,
      currencyList,
    }),
    updateUserHidden: (state, { payload: { hidden } }) => ({
      ...state,
      hidden,
    }),
    updateUserSubscription: (state, { payload }) => {
      state.subscription = payload;
    },
    updateActiveCohort: (state, { payload }) => {
      state.activeCohort = payload;
    },
    updateWorkspases: (state, { payload }) => {
      state.userData.workspaces = payload;
    },
  },
  // currency list is initialized in App component and should not reset after logout
  extraReducers(builder) {
    builder.addCase("resetState", (state) => ({
      ...initialState,
      currencyList: state.currencyList,
      isLoggedInUser: false,
    }));
  },
});

export default slice;

export const {
  updateUserData,
  updateLinkStatus,
  updateCurrencyList,
  updateUserHidden,
  updateCohort,
  updateActiveCohort,
  updateWorkspases,
  updateUserSubscription,
} = slice.actions;
