import { useLang } from "core/lang/LangaugeSetting";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrencyList, fetchUserData } from "models/user/sagaActions";
import { isLoadingEnabled } from "models/loaders/selectors";
import { LOADER_CURRENCY_LIST, USER_DATA } from "models/user/constants";
import Loading from "atoms/Loading/Loading";
import { isLoggedinUser } from "models/user/selectors";
import { setCountryCode } from "models/globalState/sagaActions";
import { LOGOUT_COMPONENT } from "models/auth/constants";
import useAnalyticsAnyVisit from "hooks/useAnalyticsAnyVisit";
import RootRoutes from "RootRoutes";
import useMixpanel from "hooks/useMixpanel";
import { initHotjar } from "core/hotjar";
import ActivationMainPanel from "organisms/Activation/MainPanel/MainPanelWrapper";
import { ActivationProvider } from "organisms/Activation/context";

const App = () => {
  const dispatch = useDispatch();
  const { lang } = useLang();
  const isLoading = useSelector((state) => isLoadingEnabled(state, USER_DATA));
  const isUserLoggingOut = useSelector((state) =>
    isLoadingEnabled(state, LOGOUT_COMPONENT)
  );
  const isLoadingCurrency = useSelector((state) =>
    isLoadingEnabled(state, LOADER_CURRENCY_LIST)
  );
  const isLoggedIn = useSelector(isLoggedinUser);

  useEffect(() => {
    dispatch(setCountryCode({}));
    dispatch(fetchUserData({ lang }));
    dispatch(fetchCurrencyList({}));
    initHotjar();
  }, []);

  useMixpanel();
  useAnalyticsAnyVisit();

  if (
    isLoading ||
    isLoadingCurrency ||
    isUserLoggingOut ||
    isLoggedIn === null
  ) {
    return <Loading fullPage />;
  }

  return (
    <ActivationProvider>
      <RootRoutes />
      <ActivationMainPanel />
      {
        // Hiding trial modals. we might bring this up in the future
      }
      {/* {userSubscription?.subscriptionData?.status === "PAUSED" &&
        isLoggedIn && <GoProModal userType={isInvestor} />} */}
    </ActivationProvider>
  );
};

export default App;
