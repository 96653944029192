import type { FC } from "react";
import type { PreviewSidebarProps } from "./interfaces";
import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "atoms/Loading/Loading";
import DefaultImage from "images/dealroom-image.png";
import { getTimeSince } from "utils/generalUtils";
import { profileUrl } from "pages/dealflow/InvestorDealflow/utils";
import ShareButton from "molecules/ShareButton/ShareButton";
import { useSelector } from "react-redux";
import { isPublicShareModalOpen } from "organisms/ShareProfileModal/selectors";
import PublicShare from "organisms/ShareProfileModal/PublicShare";
import { getUser } from "models/user/selectors";
import { GET_COMPANY_BY_SLUG } from "queries/startup/startupProfile";
import { StartupCard } from "pages/dealflow/InvestorDealflow/components/StartupCard";

const PreviewSidebar: FC<PreviewSidebarProps> = ({ record, onClose }) => {
  const { data, loading } = useQuery(GET_COMPANY_BY_SLUG, {
    variables: {
      slug: record?.company.slug,
    },
    skip: !record,
  });

  const isShareModal = useSelector(isPublicShareModalOpen);
  const user = useSelector(getUser);

  if (!record) {
    return null;
  }

  const onLinkClicked = (e: any) => {
    e.stopPropagation();
    profileUrl(
      record?.company?.slug,
      record?.company?.id,
      record?.company?.isAiaGenerated
    );
  };

  return (
    <aside className="self-end min-w-[24rem] max-w-[24rem] max-h-[calc(100vh-204px)] bg-white border border-gray-300 rounded-md ml-4 overflow-y-auto hidden lg:block invisible-scroll no-scrollbar">
      <article className="relative min-h-[calc(100vh-204px)]">
        <div className="sticky top-0 z-10 flex justify-between p-4 pb-6 border-b border-gray-300 rounded-t-md bg-gradient-to-r from-aqua-400 to-aqua-600">
          <div className="flex items-start text-white">
            <span
              onClick={onLinkClicked}
              className="inline-block border-4 border-white rounded-full w-14 h-14"
            >
              <img
                className="rounded-full object-fit"
                src={record.company?.logo?.small?.location || DefaultImage}
                alt=""
              />
            </span>
            <div className="ml-2">
              <span
                onClick={onLinkClicked}
                className="inline-block text-white truncate max-w-[18rem]"
              >
                {record.company.displayName}
              </span>
              <span className="block text-sm">
                Joined {getTimeSince(record.company.createdAt)}
              </span>
            </div>
          </div>
          <span className="text-xl text-white cursor-pointer" onClick={onClose}>
            <FontAwesomeIcon icon={["fal", "times"]} />
          </span>
        </div>
        <div>
          {loading && <Loading />}
          {data && (
            <div className="px-4 mb-2">
              <ShareButton small isMe={false} />
            </div>
          )}
          {data && (
            <StartupCard
              startup={data.companyBySlug}
              currency={record.company?.currencyData}
            />
          )}
        </div>
      </article>
      {isShareModal && data && (
        <PublicShare
          publicUrl={data.companyBySlug.publicUrl}
          title={data.companyBySlug.displayName}
          us="df_share"
          uc={user?.slug}
        />
      )}
    </aside>
  );
};

export default PreviewSidebar;
