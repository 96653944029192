// @ts-nocheck
import { useState } from "react";
import Button from "atoms/Button/Button";
import ButtonGroup from "molecules/Form/ButtonGroup";
import SelectionDropdownInput from "molecules/Form/SelectionDropdownInput";
import {
  BUSINESS_CATEGORY,
  BUSINESS_MODEL,
  CURRENT_COMPANY_STAGE,
  INDUSTRY_CATEGORIES_SWEDISH,
  WEBSITE_OPTIONS,
  YEAR_OPTIONS,
} from "core/consts";
import { PageHeader } from "ui/elements/PanelLayout.styles";
import { ReactComponent as LocationIcon } from "images/location-icon.svg";
import { ReactComponent as BasicIcon } from "images/basic-icon.svg";
import { useLang } from "core/lang/LangaugeSetting";
import GoogleInput from "molecules/Form/GoogleInput";
import SingleSelect from "molecules/Form/SingleSelect";
import TextArea from "atoms/Form/TextArea";
import { SubmitHandler, useForm, useWatch, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isLoadingEnabled } from "models/loaders/selectors";
import { STEP } from "pages/companySettings/constants";

import { getBasicInfo } from "./selectors";
import * as sagaActions from "./sagaActions";
import { goToPreviousStep } from "../../reducer";
import StepActionButtonStateful from "pages/companySettings/components/StepActionButton_Stateful";
import { getGlobalState } from "models/globalState/selectors";
import cn from "classnames";

type FormValues = {
  founded: number;
  businessModel: string[];
  businessCategory: string[];
  businessCategoryNote: string;
  businessTypeNote: string;
  expectationsNote: string;
  industries: string[];
  companyStage: string;
  website: {
    website: string;
    websiteOption: string;
  };
  headquarters: {
    country: string;
    state: string;
    city: string;
    fullAddress: string;
    lat: string;
    long: string;
  };
};

type BasicData = {
  founded: string;
  businessModel: string[];
  businessCategory: string[];
  businessCategoryNote: string;
  businessTypeNote: string;
  expectationsNote: string;
  industries: string[];
  companyStage: string;
  website: string;
  websiteOption: string;
  country: string;
  state: string;
  city: string;
  fullAddress: string;
  lat: string;
  long: string;
};

const BasicStepForm = () => {
  const dispatch = useDispatch();
  const basicInfo: BasicData = useSelector(getBasicInfo);
  const loading = useSelector((state) => isLoadingEnabled(state, STEP.BASIC));
  const isNavigationEnabled = useSelector((state) =>
    getGlobalState(state, "isNavigationEnabled")
  );

  const getDefaultValues = (): FormValues => {
    const {
      website,
      websiteOption,
      founded,
      country,
      state,
      city,
      fullAddress,
      lat,
      long,
      ...rest
    } = basicInfo;
    // Formatting data so that it fits correctly to the inputs
    return {
      ...rest,
      website: {
        website,
        websiteOption,
      },
      founded: parseInt(founded) || YEAR_OPTIONS[0],
      headquarters: {
        country,
        state,
        city,
        fullAddress,
        lat,
        long,
      },
    };
  };

  const { lang } = useLang();
  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: getDefaultValues() });

  const businessModel = useWatch({ control, name: "businessModel" });
  const businessCategory = useWatch({ control, name: "businessCategory" });

  const [websiteError, setWebsiteError] = useState<string | null>(null);
  const [locationSearchQuery, setLocationSearchQuery] = useState<string>(
    basicInfo.fullAddress || ""
  );

  const onSubmit: SubmitHandler<FormValues> = () => {
    const {
      founded,
      website: { website, websiteOption },
      headquarters: { lat, long, fullAddress, ...hqData },
      ...rest
    } = getValues();

    dispatch(
      sagaActions.saveBasicInfo({
        lang,
        input: {
          founded: founded && founded.toString(),
          website: website ? websiteOption + website : "",
          lat: lat ?? parseFloat(lat),
          long: long ?? parseFloat(long),
          fullAddress,
          ...hqData,
          ...rest,
        },
      })
    );
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    if (e.target.value === "Enter" && !e.shiftKey) {
      document.getElementById("headquartedAt").blur();
    } else {
      setLocationSearchQuery(e.target.value);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn("md:mb-0", isNavigationEnabled ? "mb-24" : "mb-12")}
    >
      <PageHeader>
        <div className="flex-1 min-w-0 mb-6">
          <div className="flex items-center">
            <BasicIcon />
            <h1 className="ml-3 text-2xl font-medium text-black sm:truncate">
              {lang.basics}
            </h1>
          </div>
          <div className="flex flex-col mt-2 text-sm sm:mt-0 sm:flex-row sm:flex-wrap">
            <p>{lang.companyBasicDesc}</p>
          </div>
        </div>
      </PageHeader>
      <div>
        <Controller
          control={control}
          name="website"
          render={({ field: { onChange, value } }) => (
            // @ts-ignore
            <SelectionDropdownInput
              selectName="weboption"
              label={lang.website}
              selectOptions={WEBSITE_OPTIONS}
              direction="left"
              selectValue={value.websiteOption}
              inputName="website"
              inputVal={value.website}
              inputPlaceholder={lang.companyWebsite}
              onSelectVal={(websiteOption: string) => {
                onChange({ ...value, websiteOption });
              }}
              handleUpdateVal={(website: string) => {
                setWebsiteError(null);
                if (website.includes("http")) {
                  setWebsiteError(lang.dontAddHttpInInput);
                  return;
                }
                onChange({ ...value, website });
              }}
              errors={websiteError}
            />
          )}
        />

        <div className="md:flex">
          <div className="w-full pr-0 mt-4 md:pr-2">
            <Controller
              control={control}
              name="headquarters"
              render={({ field: { onChange } }) => (
                <GoogleInput
                  getPlace={({
                    autoCompleteVal,
                    ...rest
                  }: {
                    city: string;
                    country: string;
                    fullAddress: string;
                    lat: number;
                    long: number;
                    state: string;
                    autoCompleteVal: string;
                  }) => {
                    onChange(rest);
                    setLocationSearchQuery(autoCompleteVal);
                  }}
                  placeholder={lang.city}
                  label={lang.headquarteredAt}
                  variant="rect"
                  id="city"
                  defaultValue={watch("headquarters.fullAddress")}
                  name="city"
                  icon={<LocationIcon />}
                  onKeyPress={(e) => e.key === "Enter" && handleKeyPress(e)}
                  onChange={(e) => setLocationSearchQuery(e.target.value)}
                />
              )}
            />
          </div>
          <div className="w-full pr-0 mt-4 md:pr-2">
            <label
              htmlFor="founded"
              className="block mb-2 text-sm font-medium text-black text-opacity-100"
            >
              {lang.founded}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 company-dropdown">
              <Controller
                control={control}
                name="founded"
                render={({ field: { value, onChange } }) => (
                  <SingleSelect
                    options={YEAR_OPTIONS}
                    defaultValue={value}
                    onChange={(selected: number) => onChange(selected)}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <label
            htmlFor="industries"
            className="text-sm font-medium text-black text-opacity-100"
          >
            {lang.whatBestDescibesYourBusinessRelations}
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="businessModel"
              render={({ field: { onChange } }) => (
                <ButtonGroup
                  id="businessModel"
                  options={Object.entries(BUSINESS_MODEL)}
                  onChange={onChange}
                  defaultValue={basicInfo.businessModel}
                  type="company"
                />
              )}
            />
            {errors.businessModel &&
              errors.businessModel.type === "validate" && (
                <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
              )}
          </div>
          {businessModel?.includes("OTHERS") && (
            <>
              <TextArea
                rows="3"
                id="businessCategoryNote"
                className={`form-textarea block mt-4 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                  errors.businessCategoryNote
                    ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : ""
                }`}
                placeholder={lang.addNoteForBusinessCategory}
                defaultValue={basicInfo?.businessCategoryNote}
                {...register("businessCategoryNote", { required: true })}
                maxLength={200}
              ></TextArea>
              {errors.businessCategoryNote && (
                <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
              )}
            </>
          )}
        </div>
        <div className="mt-4">
          <label
            htmlFor="industries"
            className="text-sm font-medium text-black text-opacity-100"
          >
            {lang.whatIndustryDoesYourBusinessOperateIn} <br />
            {`(${lang.youCanChooseUpTo3Categories})`}
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="industries"
              render={({ field: { onChange } }) => (
                <ButtonGroup
                  id="industries"
                  options={Object.entries(INDUSTRY_CATEGORIES_SWEDISH)}
                  onChange={onChange}
                  limit={3}
                  type="company"
                  className="capitalize"
                  defaultValue={basicInfo?.industries}
                />
              )}
            />
          </div>
        </div>

        <div className="mt-2">
          <label
            htmlFor="companyStage"
            className="text-sm font-medium text-black text-opacity-100"
          >
            {lang.whatIsCurrentStageOfStartup}
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="companyStage"
              render={({ field: { onChange } }) => (
                <ButtonGroup
                  id="companyStage"
                  name="companyStage"
                  options={Object.entries(CURRENT_COMPANY_STAGE)}
                  limit={1}
                  onChange={onChange}
                  type="company"
                  defaultValue={basicInfo?.companyStage}
                />
              )}
            />
          </div>
        </div>
        <div className="mt-2">
          <label
            htmlFor="businessCategory"
            className="text-sm font-medium text-black text-opacity-100"
          >
            What is your product category?
          </label>
          <div className="mt-2">
            <Controller
              control={control}
              name="businessCategory"
              render={({ field: { onChange } }) => (
                <ButtonGroup
                  id="businessCategory"
                  name="businessCategory"
                  options={Object.entries(BUSINESS_CATEGORY)}
                  onChange={onChange}
                  type="company"
                  defaultValue={basicInfo?.businessCategory}
                />
              )}
            />
          </div>
          {businessCategory?.includes("OTHER") && (
            <>
              <TextArea
                rows="3"
                id="businessTypeNote"
                className={`form-textarea block mt-4 w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm ${
                  errors.businessTypeNote
                    ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : ""
                }`}
                placeholder={lang.addNoteForBusinessType}
                //defaultValue={formData?.businessTypeNote}
                {...register("businessTypeNote", { required: true })}
                maxLength={200}
              ></TextArea>
              {errors.businessTypeNote && (
                <p className="mb-2 text-sm text-fire-400">{lang.mandatory}</p>
              )}
            </>
          )}
        </div>
      </div>
      <StepActionButtonStateful>
        <Button
          size="small"
          color="white"
          className="preview-btn"
          onClick={() => dispatch(goToPreviousStep())}
        >
          <span>{lang.back}</span>
        </Button>
        <Button size="small" color="primary" type="submit" loading={loading}>
          <span>{lang.saveAndContinue}</span>
        </Button>
      </StepActionButtonStateful>
    </form>
  );
};

export default BasicStepForm;
