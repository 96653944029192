import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "atoms/Button/Button";
import { analyticsLink } from "utils/links";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RocketIcon } from "images/rocket-small.svg";

const isTouchScreen = window.matchMedia("(pointer: coarse)").matches;

const AnalyticsButton = ({ analyticsLink }: { analyticsLink: string }) => {
  const [clickCounter, setClickCounter] = useState(0);

  useEffect(() => {
    if (clickCounter === 0) return;
    if (isTouchScreen && clickCounter <= 1) return;
    window.open(analyticsLink, "_blank", "noreferrer");
  }, [clickCounter]);

  return (
    <Button
      color="primary"
      size="small"
      onClick={() => setClickCounter((prev) => prev + 1)}
      className="analytics-button"
    >
      <FontAwesomeIcon icon={["fal", "analytics"]} className="mr-2" />
      <span>Analytics</span>
    </Button>
  );
};

const UpgradeToProButton = () => {
  const navigate = useNavigate();
  return (
    <Button
      className="bg-black"
      size="small"
      onClick={() => navigate("/plans")}
    >
      <RocketIcon className="mr-2" />
      <span>Upgrade to Pro</span>
    </Button>
  );
};

interface Props {
  companyId?: string;
  boardSlug?: string;
  isOnProPlan: boolean;
}

const InteractiveAnalyticsButton: FC<Props> = ({
  companyId,
  boardSlug,
  isOnProPlan,
}) => {
  const [AnalyticsBtn, setAnalyticsBtn] = useState(
    <AnalyticsButton
      analyticsLink={analyticsLink({ company: companyId, board: boardSlug })}
    />
  );

  const onEnter = () => {
    setAnalyticsBtn(<UpgradeToProButton />);
  };

  const onLeave = () => {
    setAnalyticsBtn(
      <AnalyticsButton
        analyticsLink={analyticsLink({ company: companyId, board: boardSlug })}
      />
    );
  };

  const clickHandler = () => {
    if (isTouchScreen && !isOnProPlan) {
      setAnalyticsBtn(<UpgradeToProButton />);
    }
  };

  return (
    <div
      onMouseEnter={isOnProPlan ? undefined : onEnter}
      onMouseLeave={isOnProPlan ? undefined : onLeave}
      onClick={clickHandler}
    >
      {AnalyticsBtn}
    </div>
  );
};

export default InteractiveAnalyticsButton;
