import tw, { styled, css } from "twin.macro";
import media from "styled-media-query";
import { motion } from "framer-motion";

export const InfoItem = styled.div`
  display: flex;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InfoKey = styled.div`
  margin-right: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  width: calc(40% - 15px);

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const InfoText = styled.div`
  color: #333333;
  font-size: 14px;
  width: 60%;
`;

export const InfoLink = styled.a`
  color: #18beb3;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: initial;
  justify-content: initial;
`;

export const PlaceHeader = styled.div(() => [
  css`
    transition: all 0.4s ease-in;
    background: #16192f;
    width: 100%;
    padding: 0 0 0px 15px;
    color: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    ${media.lessThan("768px")`
            width: 100%;
            height: auto;
            display: block;
            padding: 0;
            p{
                padding: 8px 15px 8px 27px;
            }
        `}
  `,
]);

export const PlaceSidebar = styled.div(({ isToggleMenu, position }) => [
  css`
    top: 0;
    height: 100vh;
    width: 200px;
    float: left;
    font-size: 14px;
    background: #fff;
    transition: all 0.3s ease;
    position: ${position};
    ${position === "relative" &&
    `
            height: calc(100vh - 81px);
            transition: all 0.3s ease;
        `}
    ${media.lessThan("1024px")`
            z-index: 2;
            position: fixed;
            bottom: 0;
            height: 50px;
            top: auto;
            width: 100%;
            min-height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
  `,
]);

export const Step = styled.div(({ active }) => [
  css`
    padding: 15px 10px;
    display: flex;
    align-items: center;
    color: #00000094;
    cursor: pointer;
    font-size: 14px;

    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      fill: #18beb3;
    }
    span {
      height: 20px;
      color: #18beb3;
    }
    ${media.lessThan("1024px")`
            display: block;
            padding: 11px 10px;
            svg{
                display: block;
                margin: 0 auto 2px auto;
                width: 14px;
                height: 14px;
            }
            span{
                font-size: 13px;
                width: 100%;
                display: block;
                height:12px
            }
        `}
  `,
  active &&
    css`
      color: #000;
      background: #f4f5f7;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 65%;
        width: 5px;
        border-radius: 10px;
        background: #18beb3;
        left: 0;
      }
      ${media.lessThan("1024px")`
            background: #fff;
            &:after{
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 3px;
                width: 20px;
            }
        `}
    `,
]);

export const RightSideContent = styled.div(() => [
  tw`flex`,
  css`
    ${media.lessThan("1024px")`
            justify-content: space-between;
            border-top: 1px solid #F4F5F76E;
            padding-left: 15px;
        `}
    button {
      height: 50px;
      border-radius: 0;
      &.filter-btn {
        background: #16192f;
        padding: 10px;
        border-left: 1px solid #f4f5f76e;
        svg {
          height: 17px;
          width: 17px;
        }
      }
    }
  `,
]);

export const WhiteCard = styled.div(() => [
  tw`block bg-white p-5 rounded mb-5 relative`,
  css`
    .max-h-40 {
      max-height: 150px;
    }
    .apexcharts-tooltip-title {
      background: #202436 !important;
      border-bottom: 0;
      margin-bottom: 0;
    }
    .visit-tooltip {
      padding: 8px 15px;
      display: block;
      background: #202436;
      border: none;
      width: 125px;
      border-radius: 0px;
      overflow: hidden !important;
      box-shadow: none;
      p {
        color: #fff;
      }
    }
    ${media.lessThan("medium")`
        display:flex;
        flex-direction:column;  
        .graph1 {
            order: 2;
        }
        .graph2 {
            order: 3;
        }
        ._counter_button {
            order: 1;
        }
        ._public_link{
            h1{
             margin-bottom: 0.75rem; 
            }
        }
       `}
    ${media.lessThan("768px")`
       ._public_link{
        h1{
         margin-bottom: 0.75rem; 
        }
    }
       `}
       .apexcharts-tooltip {
      background: #202436;
      color: #fff;
      border-radius: 4px;
      overflow: hidden;
      border: none;
      text-align: center;
      .apexcharts-tooltip-title,
      .apexcharts-tooltip-series-group {
        background: #202436;
        border-bottom: 0;
        margin-bottom: 0;
      }
      .apexcharts-tooltip-marker {
        margin-right: 5px;
      }
    }
    .apexcharts-xaxis-title-text {
      font-weight: normal;
    }
    .apexcharts-xaxistooltip-bottom {
      display: none;
    }
    .enable-toggle-btn {
      ${media.lessThan("768px")`
                float: right;
            `}
      input:checked ~ .dot {
        transform: translateX(80%);
        background-color: #18beb3;
      }
      .shadow-inner {
        background: #bad9d8;
        width: 34px;
      }
      .dot {
        top: -2px;
        width: 20px;
        height: 20px;
      }
    }
    .analytics-dropdown {
      .__custom {
        min-width: 140px;
        ul {
          background: #202436;
          li {
            span {
              text-transform: capitalize;
              color: #fffc;
              transition: 0.6s ease-in;
              svg {
                color: #18beb3;
              }
            }
            &:hover {
              background: #202436;
              span {
                color: #18beb3;
                transition: 0.4s ease-in;
              }
            }
          }
        }
      }
      button {
        border-radius: 30px;
        min-width: 140px;
        background: #f5f5f5;
        border-color: #f5f5f5;
        &:focus {
          background: #202436;
          color: #fff;
          border: 0;
        }
        span {
          text-transform: capitalize;
        }
      }
      ${media.lessThan("768px")`
                width: 100%;
           `}
    }
    table tr th {
      .short-icon {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        span + span {
          position: relative;
        }
        svg {
          margin: 0;
          position: absolute;
          top: -6px;
          right: -10px;
        }
      }
    }
  `,
]);

export const AnalyticsButton = styled.div(() => [
  tw`rounded-md cursor-pointer text-center relative`,
  css`
    width: calc(35% - 15px);
    margin-right: 15px;
    background: #e8f9f8;
    font-weight: 500;
    font-size: 14px;
    b {
      font-weight: 500;
      ${media.lessThan("1024px")`
            display:block;
            
           `}
    }
    &:last-child {
      margin: 0;
    }
    button {
      padding: 15px;
      width: 100%;
      ${media.lessThan("medium")`
            padding: 15px 5px;
            font-size:11px;
            
           `}
      &:focus {
        outline: none;
      }
    }
    ${({ loading }) =>
      loading &&
      `
            pointer-events: none;
        `}
    ${({ active }) =>
      active &&
      `
            background: #18beb3;
            pointer-events: none;
            color: #fff;
            &:after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: -12px;
                width: 0px;
                height: 0px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-bottom: 12px solid #18beb3;
                margin: 0 auto;       
                @media screen and (max-width:768px){ 
                bottom: -10px !important;
                border-top: 12px solid #18beb3 !important; 
                border-bottom: 0px;   
                top: auto; 
                z-index: 1; 
                   
               
               }
             
            }
            
            }
        //     ${media.lessThan("medium")`
        //     ${({ active }) =>
          active &&
          `
        //     &:after{
        //         bottom: -15px !important;
        //         border-top: 12px solid #18beb3 !important; 
        //         border-bottom: 0px;  
              
        //         top: auto;       
             
        //        `}
        //        `}
        // `}
        ${({ active }) =>
      !active &&
      `
            color: #17293d;
            b{
                color: #18beb3;
                ${media.lessThan("medium")`
                display:block;
                
               `}
            }
        `}
  `,
]);

export const PrivateLink = styled.div(({ isCreatePrivateLink }) => [
  tw`bg-aqua-400 text-white text-right px-4 font-normal py-2 float-right overflow-hidden	`,
  css`
    width: 175px;
    border-radius: 30px;
    height: 42px;
    ${media.lessThan("medium")`
            width: 100%;
            float:none;
       `}
    input {
      width: 100%;
      cursor: pointer;
      &::placeholder {
        opacity: 1;
        color: #fff !important;
        ${media.lessThan("medium")`
                    text-align: center;
                `}
      }
    }
    button {
      margin-left: 15px;
      &:disabled {
        opacity: 0.7;
      }
    }
    ${isCreatePrivateLink &&
    `
            width: auto;
            input{
                cursor: auto;
                &::placeholder{
                    opacity: 0.7;
                    @media screen and (max-width: 767px){
                        text-align: left !important;
                    }
                }
            }
        `}
  `,
]);

export const AdvancedFilter = styled.div(() => [
  tw`relative`,
  css`
    width: 300px;
    background: #f5f5f5;
    border-radius: 50px;
    border: 1px solid #18beb3;
    background: #fff;
    ${media.lessThan("767px")`
            width: 100%;
        `}
    span {
      width: 38px;
      height: 38px;
      background: whitesmoke;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      color: #18beb3;
      cursor: pointer;
      top: 0px;
      svg {
        left: 15px;
        top: 12px;
        position: absolute;
      }
    }
    ${media.lessThan("768px")`
           // width: 100%;
        `}
    input {
      border: 1px solid #ccc;
      padding: 6px 30px;
      border-radius: 30px;
      &:focus,
      &:focus-visible {
        border: 1px solid #18beb3 !important;
      }
    }
    .fa-search {
      position: absolute;
      left: 10px;
      top: 11px;
      width: 14px;
    }
    .w-full {
      padding: 7px 15px;
    }
    ${({ isAdvancedFilter }) =>
      isAdvancedFilter &&
      `
            color: #fff; 
            background: rgb(32, 36, 54);
        `}
  `,
]);

export const AdvancedFilterDropdown = styled.div(() => [
  tw`absolute w-full mt-3 rounded-md z-10 text-white left-0`,
  css`
    background: rgb(32, 36, 54);
    .scrollbar-container {
      max-height: 250px;
      margin: 10px 0;
      padding: 0 13px;
    }
    button {
      margin: 0 13px 15px;
      width: calc(100% - 26px);
    }
    .checkbox {
      display: flex;
      align-items: center;
      margin: 7px 0;
      position: relative;
      input[type="radio"] {
        appearance: none;
        padding: 0;
        border: none;
        width: 0;
        height: 0;
        &:focus {
          outline: none;
          border: none !important;
        }
        &:checked {
          &:before {
            position: absolute;
            right: 20px;
            top: 12px;
            height: 7px;
            width: 2px;
            background-color: #18beb3;
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;
          }
          &:after {
            position: absolute;
            right: 5px;
            bottom: 8px;
            height: 2px;
            width: 17px;
            background-color: #18beb3;
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;
          }
        }
        ~ label {
          left: 0;
        }
      }
      input[type="checkbox"] {
        order: 1;
        position: relative;
        width: 17px;
        height: 17px;
        padding: 0;
        border: 1px solid #18beb3;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        &::before {
          position: absolute;
          content: "";
          display: block;
          top: 2px;
          left: 6px;
          width: 4px;
          height: 9px;
          border-style: solid;
          border-color: #18beb3;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
        }
        &:checked {
          color: #18beb3;
          border-color: #18beb3;
          &::before {
            opacity: 1;
          }
          ~ label::before {
            color: #18beb3;
          }
        }
      }
      label {
        position: relative;
        cursor: pointer;
        width: 196px;
        display: inline;
        text-transform: capitalize;
      }
    }
  `,
]);

export const SectionAnimation = styled(motion.div)(() => [
  css`
    ${media.lessThan("768px")`
    ._search_wrap{
        flex-direction: column;
        order:0;
             
        .__title{
            width:100%;
            display: block;
            margin-bottom: 8px;
        }
        ._item_wrap{
            width: 100%;            
            margin-bottom: 20px;
            margin-top:5px;
            
        }
            
            div:first-child {
                // width: 100%;
                // flex-direction: column;
            }
            ._item{
                display:flex;
                flex-direction: row !important;
                margin-bottom: 6px;
            }
    }

    }
    `}
    ${media.lessThan("767px")`
    ._clear{
        margin-top:10px;
        button{
            color: #fff;
            background: #18beb3;
            width: 100%;
            height: 39px;
            border-radius: 50px;
        }
    }
`}
  `,
]);

export const ExpireContent = styled.div(() => [
  tw`rounded p-3 flex justify-between items-center mb-5`,
  css`
    background: #eb826833;
    color: #333;
    ${media.lessThan("medium")`
       flex-direction:column;
       h3{
        margin-bottom: 10px;
       }
      `}
  `,
]);
