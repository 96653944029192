import { gql } from "@apollo/client";

export const GET_IDENTIFIED_VIEWS = gql`
  query getIdentifiedViews {
    me {
      identifiedViews {
        name
        email
        createdAt
      }
    }
  }
`;
