// @ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import { toggleLoader } from "models/loaders/sagaActions";
import { getClient } from "core/utility";

import { STEP } from "../../constants";
import {
  GET_BUSINESS_MODEL,
  SAVE_BUSINESS_MODEL,
} from "queries/startup/startupSettings/businessModelStep";
import { goToNextStep } from "../../reducer";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { updateBusinessModel } from "../../reducer";
import { initializeCompanySettings } from "pages/companySettings/sagaActions";

const client = getClient();

function* getBusinessModel() {
  yield put(toggleLoader({ component: STEP.BUSINESS_MODEL, isLoading: true }));
  const { data, isError, error } = yield client
    .query({
      query: GET_BUSINESS_MODEL,
      fetchPolicy: "network-only",
    })
    .catch((error) => ({ isError: true, error }));

  yield put(
    updateBusinessModel({
      businessModel: data?.me,
    })
  );

  yield put(toggleLoader({ component: STEP.BUSINESS_MODEL, isLoading: false }));
}

function* saveBusinessModel({ payload: { lang, ...variables } }) {
  yield put(toggleLoader({ component: STEP.BUSINESS_MODEL, isLoading: true }));
  const { isError } = yield client
    .mutate({
      mutation: SAVE_BUSINESS_MODEL,
      fetchPolicy: "network-only",
      variables,
    })
    .catch((error) => ({ isError: true, error }));
  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: lang.somethingWentWrong,
        message: lang.weCouldNotSave,
      })
    );
    yield put(
      toggleLoader({ component: STEP.BUSINESS_MODEL, isLoading: false })
    );
    return;
  }

  yield getBusinessModel();

  // triggering this action to get back the completedSteps
  yield put(initializeCompanySettings({ lang }));

  yield put(toggleLoader({ component: STEP.BUSINESS_MODEL, isLoading: false }));

  yield put(
    triggerSnack({
      type: "success",
      title: lang.settingSaved,
      message: lang.yourSettingSaved,
    })
  );

  yield put(goToNextStep());
}

export default function* rootSaga() {
  return [
    yield takeEvery(sagaActions.getBusinessModel, getBusinessModel),
    yield takeEvery(sagaActions.saveBusinessModel, saveBusinessModel),
  ];
}
