import { gql } from "@apollo/client";

export const SAVE_PRODUCT_DETAILS = gql`
  mutation saveProductDetails($input: ProductDetailsInput) {
    saveCompanyProductDetails(input: $input) {
      description
      productImages {
        large {
          location
        }
      }
      productDemo
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails {
    me {
      description
      productImages {
        large {
          location
          filename
        }
      }
      productDemo
    }
  }
`;

export const ADD_PRODUCT_IMAGE = gql`
  mutation addProductImage($file: Upload!) {
    addProductImage(file: $file) {
      large {
        location
        filename
      }
    }
  }
`;
