// @ts-nocheck
import { History } from "core/utility";
import { toggleLoader } from "models/loaders/sagaActions";
import { put, takeEvery } from "redux-saga/effects";
import * as sagaActions from "./sagaActions";
import * as slice from "models/user/reducer";
import { LOGIN_PAGE } from "models/auth/constants";
import { getCohortBySlug } from "models/user/sagas";
import { triggerSnack } from "organisms/Snack/sagaActions";

function* initializeLogin({ payload: { params, lang } }) {
  yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: true }));

  if (!params?.id) {
    yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: false }));

    return;
  }

  const { data: cohortData, isError } = yield getCohortBySlug(params.id);

  if (isError) {
    yield put(
      triggerSnack({
        type: "error",
        title: "Something went wrong",
        message:
          "We could not fetch the invitation data, please try again later",
      })
    );
  } else {
    if (cohortData) {
      yield put(slice.updateCohort(cohortData.getCohortBySlug));
      document.title = cohortData.getCohortBySlug.header;
    }
  }
  yield put(toggleLoader({ component: LOGIN_PAGE, isLoading: false }));
  // @TODO: handle favicon href for cohorts
}

export default function* rootSaga() {
  return [yield takeEvery(sagaActions.initialize, initializeLogin)];
}
