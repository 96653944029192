import { useState } from "react";
import { Section } from "atoms/Section.style";
import Tooltip from "atoms/Tooltip/Tooltip";
import {
  DEMANDFREQUNITS,
  PERIODICITY,
  PERRATEPERIOD,
  SUPPLYFREQUNITS,
  TIME,
  USERS,
} from "core/consts";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as HelpIcon } from "images/help-icon.svg";
import NumberFormat from "react-number-format";

import {
  Description,
  Figure,
  FiguresContainer,
  FiguresSmallRow,
  Header,
  HeaderAction,
  KeyFigures,
} from "./KeyFigures.style";
import PureHtml from "atoms/PureHtml";
import SectionHeader from "../SectionHeader/SectionHeader";

export default ({ traction, currencyCode, companyStage }) => {
  const { lang } = useLang();
  const [existTraction, setExistTraction] = useState();

  const _renderActiveUsers = () => {
    if (traction?.activeUsers) {
      return traction?.activeUsers?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  // thousandSeparator={','}
                  // prefix={`${currencyCode} `}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  PERIODICITY[user.frequency]
                }`}</small>
              </div>
              <div className="label">
                {lang.activeUsers}
                {traction.activeUsersNote && (
                  <Tooltip content={traction.activeUsersNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderTotalUsers = () => {
    if (traction?.totalUsers) {
      return traction?.totalUsers?.map((user) => {
        if (user.count) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.count}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix="%"
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  USERS[user.type]
                }`}</small>
              </div>
              <div className="label">
                {lang.totalUsers}
                {traction.totalUsersNote && (
                  <Tooltip content={traction.totalUsersNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderUserGrowthRate = () => {
    if (traction?.growthRate) {
      return traction?.growthRate?.map((user) => {
        if (user.rate || user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              {user.rate && (
                <div>
                  <NumberFormat
                    value={user.rate}
                    displayType="text"
                    thousandSeparator={","}
                    suffix="%"
                  />
                  <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                    PERRATEPERIOD[user.period]
                  }`}</small>
                </div>
              )}
              {user.amount && (
                <div>
                  <NumberFormat
                    value={user.amount}
                    displayType="text"
                    // thousandSeparator={','}
                    // suffix="%"
                  />
                  {` ${TIME[user.time]}`}
                </div>
              )}
              <div className="label">
                {lang.userGrowthRate}
                {traction.growthRateNote && (
                  <Tooltip content={traction.growthRateNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderRetentionRate = () => {
    if (traction?.retentionRate) {
      return traction?.retentionRate?.map((user) => {
        if (user.rate || user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              {user.rate && (
                <div>
                  <NumberFormat
                    value={user.rate}
                    displayType="text"
                    thousandSeparator={","}
                    suffix="%"
                  />
                  <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                    PERIODICITY[user.period]
                  }`}</small>
                </div>
              )}
              {user.amount && (
                <div>
                  <NumberFormat
                    value={user.amount}
                    displayType="text"
                    // thousandSeparator={','}
                    // suffix="%"
                  />
                  {` ${TIME[user.time]}`}
                </div>
              )}
              <div className="label">
                {lang.retentionRate}
                {traction.retentionRateNote && (
                  <Tooltip content={traction.retentionRateNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderChurnRate = () => {
    if (traction?.churnRate) {
      return traction?.churnRate?.map((user) => {
        if (user.percentage) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.percentage}
                  displayType="text"
                  // thousandSeparator={','}
                  suffix="%"
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  PERRATEPERIOD[user.period]
                }`}</small>
              </div>
              <div className="label">
                {lang.churnRate}
                {traction.churnRateNote && (
                  <Tooltip content={traction.churnRateNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
                <div className="hover-desc">{traction.churnRateNote}</div>
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderNetRevenue = () => {
    if (traction?.netRevenue) {
      return traction?.netRevenue?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  PERRATEPERIOD[user.time]
                }`}</small>
              </div>
              <div className="label">
                {lang.netRevenue}
                {traction.netRevenueNote && (
                  <Tooltip content={traction.netRevenueNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderRecurringRevenue = () => {
    if (traction?.recurringRevenue) {
      return traction?.recurringRevenue?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  PERIODICITY[user.time]
                }`}</small>
              </div>
              <div className="label">
                {lang.recurringRevenue}
                {traction.recurringRevenueNote && (
                  <Tooltip content={traction.recurringRevenueNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderRevenueGrowthRate = () => {
    if (traction?.revenueRate) {
      return traction?.revenueRate?.map((user) => {
        if (user.rate || user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              {user.rate && (
                <div>
                  <NumberFormat
                    value={user.rate}
                    displayType="text"
                    thousandSeparator={","}
                    suffix="%"
                  />
                  <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                    PERRATEPERIOD[user.period]
                  }`}</small>
                </div>
              )}
              {user.amount && (
                <div>
                  <NumberFormat
                    value={user.amount}
                    displayType="text"
                    // thousandSeparator={','}
                    // suffix="%"
                  />
                  {` ${TIME[user.time]}`}
                </div>
              )}
              <div className="label">
                {lang.revenueGrowthRate}
                {traction.revenueRateNote && (
                  <Tooltip content={traction.revenueRateNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderSMV_GSV = () => {
    if (traction?.smv_gsv) {
      return traction?.smv_gsv?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  PERIODICITY[user.time]
                }`}</small>
              </div>
              <div className="label">
                {lang.SMVperGSV}
                {traction.smv_gsvNote && (
                  <Tooltip content={traction.smv_gsvNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const __renderAverageSalesPrice = () => {
    if (traction?.salePriceAvg) {
      return traction?.salePriceAvg?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
              </div>
              <div className="label">
                {lang.averageSalesPrice}
                {traction.salePriceAvgNote && (
                  <Tooltip content={traction.salePriceAvgNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderRatePercentage = () => {
    if (traction?.ratePercentage) {
      return traction?.ratePercentage?.map((user) => {
        if (user) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={`%`}
                />
              </div>
              <div className="label">
                {lang.rakePercentage}
                {traction.ratePercentageNote && (
                  <Tooltip content={traction.ratePercentageNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderCacPayback = () => {
    if (traction?.cacPayback) {
      return traction?.cacPayback?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix={`%`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  TIME[user.frequency]
                }`}</small>
              </div>
              <div className="label">
                {lang.CacPayback}
                {traction.cacPaybackNote && (
                  <Tooltip content={traction.cacPaybackNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderLTCCacRatio = () => {
    if (traction?.ltcCacRatio) {
      return traction?.ltcCacRatio?.map((user) => {
        if (user) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix={`%`}
                />
              </div>
              <div className="label">
                {lang.ltvCacRatio}
                {traction.ltcCacRatioNote && (
                  <Tooltip content={traction.ltcCacRatioNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderDemandFrequency = () => {
    if (traction?.demandFreq) {
      return traction?.demandFreq?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix={`%`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  DEMANDFREQUNITS[user.unit]
                }${PERRATEPERIOD[user.frequency]}`}</small>
              </div>
              <div className="label">
                {lang.demandFrequency}
                {traction.demandFreqNote && (
                  <Tooltip content={traction.demandFreqNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderSupplyFrequency = () => {
    if (traction?.supplyFreq) {
      return traction?.supplyFreq?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix={`%`}
                />
                <small className="ml-2 text-sm text-gray-600 dark:text-white/80">{`${
                  SUPPLYFREQUNITS[user.unit]
                }${PERRATEPERIOD[user.frequency]}`}</small>
              </div>
              <div className="label">
                {lang.supplyFrequency}
                {traction.supplyFreqNote && (
                  <Tooltip content={traction.supplyFreqNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderSignedContracts = () => {
    if (traction?.signedContracts) {
      return traction?.signedContracts?.map((user) => {
        if (user) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user}
                  displayType="text"
                  thousandSeparator={","}
                  // suffix={`%`}
                />
              </div>
              <div className="label">
                {lang.signedContracts}
                {traction.signedContractsNote && (
                  <Tooltip content={traction.signedContractsNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderAnnualValueContracts = () => {
    if (traction?.annualValueContracts) {
      return traction?.annualValueContracts?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
              </div>
              <div className="label">
                {lang.annualValueOfSignedInContract}
                {traction.annualValueContractsNote && (
                  <Tooltip content={traction.annualValueContractsNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderPipelineContracts = () => {
    if (traction?.pipelineContracts) {
      return traction?.pipelineContracts?.map((user) => {
        if (user) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user}
                  displayType="text"
                  thousandSeparator={","}
                />
              </div>
              <div className="label">
                {lang.hashOfContractsInPipeline}
                {traction.pipelineContractsNote && (
                  <Tooltip content={traction.pipelineContractsNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderAnnualContractsPipeline = () => {
    if (traction?.annualValueContractsPipeline) {
      return traction?.annualValueContractsPipeline?.map((user) => {
        if (user.amount) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user.amount}
                  displayType="text"
                  thousandSeparator={","}
                  suffix={` ${currencyCode}`}
                />
              </div>
              <div className="label">
                {lang.annualValueOfSignedInPipeline}
                {traction.annualValueContractsPipelineNote && (
                  <Tooltip content={traction.annualValueContractsPipelineNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  const _renderLetterOfIntent = () => {
    if (traction?.lettersOfIntent) {
      return traction?.lettersOfIntent?.map((user) => {
        if (user) {
          if (!existTraction) {
            setExistTraction(true);
          }
          return (
            <Figure>
              <div>
                <NumberFormat
                  value={user}
                  displayType="text"
                  thousandSeparator={","}
                />
              </div>
              <div className="label">
                {lang.letterOfIntentSigned}
                {traction.lettersOfIntentNote && (
                  <Tooltip content={traction.lettersOfIntentNote}>
                    <HelpIcon className="h-auto ml-2" />
                  </Tooltip>
                )}
              </div>
            </Figure>
          );
        } else return null;
      });
    }
  };

  return (
    <>
      {(traction?.activeUsers ||
        traction?.totalUsers ||
        traction?.growthRate ||
        traction?.retentionRate ||
        traction?.churnRate ||
        traction?.netRevenue ||
        traction?.recurringRevenue ||
        traction?.revenueRate ||
        traction?.smv_gsv ||
        traction?.salePriceAvg ||
        traction?.ratePercentage ||
        traction?.cacPayback ||
        traction?.ltcCacRatio ||
        traction?.demandFreq ||
        traction?.supplyFreq ||
        traction?.signedContracts ||
        traction?.annualValueContracts ||
        traction?.pipelineContracts ||
        traction?.annualValueContractsPipeline ||
        traction?.lettersOfIntent) && (
        <Section
          className={`relative dark:sm:from-neutral-900 dark:sm:to-neutral-800 !py-2 dark:text-white/80 ${
            existTraction ? "block" : "hidden"
          }`}
        >
          <div className="mx-auto">
            <KeyFigures>
              {existTraction && (
                <>
                  <SectionHeader title={lang.traction} />
                  <Header>
                    <Description>
                      <p className="w-3/4 md-full dark:text-gray-400">
                        {lang.keyfigureDescForComapny}
                      </p>
                    </Description>
                    <HeaderAction></HeaderAction>
                  </Header>
                </>
              )}
              <FiguresContainer>
                <FiguresSmallRow>
                  {/* start traction showing details */}
                  {traction?.activeUsers && _renderActiveUsers()}
                  {traction?.totalUsers && _renderTotalUsers()}
                  {traction?.growthRate && _renderUserGrowthRate()}
                  {traction?.retentionRate && _renderRetentionRate()}
                  {companyStage?.length > 0 && companyStage[0] && (
                    <>
                      {companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.churnRate &&
                        _renderChurnRate()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.netRevenue &&
                        _renderNetRevenue()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.recurringRevenue &&
                        _renderRecurringRevenue()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.revenueRate &&
                        _renderRevenueGrowthRate()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.smv_gsv &&
                        _renderSMV_GSV()}
                      {traction?.salePriceAvg && __renderAverageSalesPrice()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.ratePercentage &&
                        _renderRatePercentage()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.cacPayback &&
                        _renderCacPayback()}
                      {companyStage[0] !== "PRE_REVENUE" &&
                        companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.ltcCacRatio &&
                        _renderLTCCacRatio()}
                      {companyStage[0] !== "PRE_PRODUCT" &&
                        traction?.demandFreq &&
                        _renderDemandFrequency()}
                    </>
                  )}
                  {traction?.supplyFreq && _renderSupplyFrequency()}
                  {traction?.signedContracts && _renderSignedContracts()}
                  {traction?.annualValueContracts &&
                    _renderAnnualValueContracts()}
                  {traction?.pipelineContracts && _renderPipelineContracts()}
                  {traction?.annualValueContractsPipeline &&
                    _renderAnnualContractsPipeline()}
                  {traction?.lettersOfIntent && _renderLetterOfIntent()}
                </FiguresSmallRow>
                {traction?.additionalTraction && (
                  <div className="mt-8">
                    <h3>{lang.additionalTraction}</h3>
                    <p className="mt-2 dark:text-gray-400">
                      <PureHtml content={traction?.additionalTraction} />
                    </p>
                  </div>
                )}
              </FiguresContainer>
            </KeyFigures>
          </div>
        </Section>
      )}
    </>
  );
};
