import { FC } from "react";
import { SDGItem, SDGItems } from "../About/About.style";
import SDG from "molecules/Sdg/Sdg";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../SectionHeader/SectionHeader";

interface Props {
  sustainableDevelopmentGoals: {
    items: any[];
  };
  isLoading?: boolean;
}

const SDGs: FC<Props> = ({ sustainableDevelopmentGoals, isLoading }) => {
  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="SDG Goals" />
        <Skeleton count={3.6} />
      </div>
    );

  return (
    <div className="mb-2">
      <SectionHeader title="SDG Goals" />

      {sustainableDevelopmentGoals?.items &&
      sustainableDevelopmentGoals.items.length > 0 ? (
        <SDGItems>
          {[...sustainableDevelopmentGoals?.items]
            ?.sort((a, b) => a - b)
            .map((key) => (
              <SDGItem key={key}>
                <SDG number={key} />
              </SDGItem>
            ))}
        </SDGItems>
      ) : (
        <p>
          The company does not seem to contribute to solving any Sustainable
          Development Goals.
        </p>
      )}
    </div>
  );
};

export default SDGs;
