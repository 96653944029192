import { FC, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { UpgradePlanProps } from "../interfaces";
import { triggerSnack } from "organisms/Snack/sagaActions";
import Button from "atoms/Button/Button";
import { UPDATE_PLAN } from "queries/general/account/billing";
import { PlanPrice } from "pages/plans/interfaces";
import {
  mixpanelClickUpgradePlan,
  mixpanelUpgradePlan,
} from "core/mixpanel/Mixpanel";

const UpgradePlan: FC<UpgradePlanProps> = ({
  currentPrice,
  currentPlan,
  allPlans,
  refetchSubscription,
}) => {
  const dispatch = useDispatch();
  const [isModelOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    mixpanelClickUpgradePlan();
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  const [upgradePlan] = useMutation(UPDATE_PLAN, {
    onCompleted: () => {
      dispatch(
        triggerSnack({
          type: "success",
          title: "Success",
          message: "Plan successfull upgraded",
        })
      );
      refetchSubscription();
    },
    onError: () => {
      dispatch(
        triggerSnack({
          type: "error",
          title: "Something went wrong",
          message: "We could not upgrade",
        })
      );
    },
  });

  const pickedPlan = allPlans?.find((plan) => plan.id === currentPlan.id);

  const isPromptToAnnual = currentPrice?.interval === "MONTHLY";

  const getPlanToUpgrade = () => {
    let planToUpgrade: PlanPrice | undefined;

    // Handle various upgrade cases here. For now we only have 2 plans so only upgrade is
    // Monthly => Annual

    if (isPromptToAnnual) {
      planToUpgrade = pickedPlan?.prices.find(
        (price) => price.interval === "YEARLY"
      );
    }

    return planToUpgrade;
  };

  const handleUpgrade = () => {
    handleClose();

    const planToUpgrade = getPlanToUpgrade();

    if (!planToUpgrade) {
      return;
    }

    upgradePlan({
      variables: {
        newPriceId: planToUpgrade.id,
      },
    });
    mixpanelUpgradePlan();
  };

  const planToUpgrade = getPlanToUpgrade();

  if (!planToUpgrade) return null;

  const originalPrice = isPromptToAnnual && currentPrice.price * 12;
  const discountedPrice = planToUpgrade.price;

  return (
    <>
      <button onClick={handleOpen} className="text-[#0077D1] text-sm mt-3">
        Upgrade to annual
      </button>
      <Transition appear show={isModelOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* Handle various upgrade cases here. For now we only have 2 plans so only upgrade is
                  Monthly => Annual */}
                {isPromptToAnnual && (
                  <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <p className="pb-2 font-medium text-black">
                      Upgrade to annual subscription and get 2 months of
                      Sircular professional free
                    </p>
                    <div className="flex mt-3 ">
                      <p className="mr-2 line-through text-[#18BEB3]">
                        {originalPrice} EUR/annually
                      </p>
                      <p className="text-lg text-[#18BEB3]">
                        {discountedPrice} EUR/annually
                      </p>
                    </div>
                    <p className="mt-3 text-sm text-gray-500">
                      The invoice for the upgraded plan is reduced by the amount
                      for the unused time.
                    </p>
                    <div className="flex justify-end mt-8">
                      <Button
                        color="transparent"
                        size="small"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        className="ml-2"
                        onClick={handleUpgrade}
                      >
                        Upgrade
                      </Button>
                    </div>
                  </Dialog.Panel>
                )}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpgradePlan;
