import { gql } from "@apollo/client";

export const GENERATE_EMAIL = gql`
  mutation generateEmail(
    $companyId: String!
    $emailType: EmailType!
    $language: Language!
    $additionalThoughts: String
  ) {
    aia {
      generateEmailDraft(
        companyId: $companyId
        emailType: $emailType
        language: $language
        additionalThoughts: $additionalThoughts
      ) {
        text
      }
    }
  }
`;

export const GET_EMAIL_SETTINGS = gql`
  query getEmailDraftSettings {
    getEmailDraftSettings {
      language
    }
  }
`;

export const SET_EMAIL_SETTINGS = gql`
  mutation setEmailDraftSettings($selectedLanguage: Language!) {
    setEmailDraftSettings(selectedLanguage: $selectedLanguage)
  }
`;
