import { createAction } from "@reduxjs/toolkit";

export const toggleLoader = createAction(
  "model/loaders/toggle",
  ({ component, isLoading }) => ({
    payload: {
      component,
      isLoading,
    },
  })
);
