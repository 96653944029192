// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isEnabled: false,
};

export const name = "organisms/shareProfileModal";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateShareProfileModalData(state, { payload: { isEnabled } }) {
      return { isEnabled };
    },
  },
});

export default slice;

export const { updateShareProfileModalData } = slice.actions;
