import { FC } from "react";
import { Tab } from "@headlessui/react";
import cn from "classnames";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import MarketOpp_PitckDeck from "./MarketOpp_PitckDeck";
import MarketSize from "./MarketSize";
import BlueOcean from "./BlueOcean";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";
interface Props {
  marketOpp?: string;
  sources?: Sources[];
  marketOppAnalysis?: string;
  blueOcean?: string;
  isProfileLoading?: boolean;
  isAnalysisLoading?: boolean;
}

interface Sources {
  title: string;
  url: string;
}

const MarketOpportunity: FC<Props> = ({
  marketOpp,
  sources,
  marketOppAnalysis,
  blueOcean,
  isProfileLoading,
  isAnalysisLoading,
}) => {
  const { profileSectionRef } = useProfileNavigation();

  return (
    <div
      id={PROFILE_SECTION_IDS.Market}
      ref={profileSectionRef}
      className="mb-4"
    >
      <SectionHeader title="Market Opportunity" />
      <div className="w-full">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-gray-200 p-1 w-52">
            <Tab
              className={({ selected }) =>
                cn(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-aqua-400",
                  "ring-white focus:outline-none focus:ring-0",
                  selected
                    ? "bg-white shadow"
                    : "text-gray-600 hover:text-aqua-400"
                )
              }
            >
              Pitch Deck
            </Tab>
            <Tab
              className={({ selected }) =>
                cn(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-aqua-400",
                  "ring-white focus:outline-none focus:ring-0",
                  selected
                    ? "bg-white shadow"
                    : "text-gray-600 hover:text-aqua-400"
                )
              }
            >
              AIA estimate
            </Tab>
          </Tab.List>

          <Tab.Panels className="mt-2">
            <Tab.Panel className="rounded-xl py-2 ring-white ring-opacity-0 focus:outline-none focus:ring-0">
              {isProfileLoading ? (
                <Skeleton count={3.6} />
              ) : (
                <MarketOpp_PitckDeck
                  content={marketOpp ? marketOpp : "No data provided"}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="rounded-xl py-2 ring-white ring-opacity-0 focus:outline-none focus:ring-0">
              {isAnalysisLoading || !marketOppAnalysis ? (
                <Skeleton count={3.6} />
              ) : (
                <div className="aiaProfile text-base whitespace-pre-wrap dark:text-gray-400 rich-text-content-wrapper">
                  <MarketSize content={marketOppAnalysis || ""} />
                  {blueOcean && <BlueOcean content={blueOcean} />}
                  {sources && sources?.length > 0 && (
                    <div className="mt-4">
                      <p>Sources: </p>
                      <ul>
                        {sources.map((source: Sources, index) => (
                          <li key={index}>
                            <a
                              className="text-aqua-400"
                              href={source.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {source?.title ? source.title : source.url}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default MarketOpportunity;
