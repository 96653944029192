import type { FC } from "react";
import Modal from "atoms/Modal/Modal";
import Button from "atoms/Button/Button";
import { CohortApplicationModalProps } from "./interfaces";
import useCohortApplicationModal from "../hooks/useCohortApplicationModal";
import StandardLogo from "images/company-placeholder-img.png";
import PureHtml from "atoms/PureHtml";

const CohortApplicationModal: FC<CohortApplicationModalProps> = (props) => {
  const {
    cohort,
    header,
    description,
    alreadyApplied,
    isButtonEnabled,
    registrationType,
    isType,
    isApplyLoading,
    handleCloseModal,
    handleApply,
  } = useCohortApplicationModal(props);

  return (
    <Modal width="44rem" onClose={handleCloseModal} hideCloseButton>
      <div className="sm:overflow-hidden content-container h-max">
        <div className="flex justify-center mt-2 mb-2 text-xl font-bold sm:text-3xl sm:mb-2 sm:mt-4">
          Welcome Back!
        </div>
        <div className="flex justify-center mt-2 mb-4 text-sm font-medium sm:text-l sm:mb-5 sm:mt-2">
          You already have an account with Sircular, so this application will be
          done in no time.
        </div>
        <div className="bg-white rounded-md sm:bg-sky-50 flex:col sm:flex min-h-[200px]">
          <div className="flex items-center justify-center w-full mr-4 overflow-hidden sm:mr-0 sm:w-2/5 sm:rounded-l-md">
            <img
              className="object-cover sm:rounded-l-md"
              src={cohort?.poster?.original?.location ?? StandardLogo}
            />
          </div>
          {isType ? (
            <div className="w-full py-20 mx-4 text-sm font-medium text-gray-600 sm:w-1/2">
              The application link to {header} is only for {registrationType}.
              If you want to apply, please change to another account or logout
              and create a new account.
            </div>
          ) : (
            <div className="justify-center w-full sm:mx-4 sm:w-1/2">
              <div className="mt-4 font-bold opacity-75">Apply to {header}</div>
              {alreadyApplied ? (
                <div className="mt-3 text-sm font-medium text-gray-400">
                  You have already applied to {header}!
                </div>
              ) : (
                <div className="relative block mt-3 mb-4 mr-4 sm:mr-0 h-max">
                  <PureHtml content={description} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-center pt-4">
          <Button
            color="transparent"
            className="mr-4"
            size="large"
            type="button"
            onClick={handleCloseModal}
          >
            <div className="mx-4 text-xs sm:text-sm">Cancel</div>
          </Button>
          <Button
            color="primary"
            size="large"
            loading={isApplyLoading}
            disabled={isButtonEnabled}
            onClick={handleApply}
          >
            <div className="mx-4 text-xs sm:text-sm">Apply</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CohortApplicationModal;
