import Loading from "atoms/Loading/Loading";
import UnavailableLink from "atoms/ServerError/UnavailableLink";
import { useLang } from "core/lang/LangaugeSetting";
import { isLoadingEnabled } from "models/loaders/selectors";
import { isUserLinkRemoved } from "models/user/selectors";
import ServerError from "molecules/ServerError/ServerError";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LOADER_COMPANY_BY_SLUG } from "./constants";
import { initializeCompanyProfile } from "./sagaActions";
import {
  getAnalysisDetails,
  getCompanyDetails,
  getCompetitorsDetails,
  getCurrencyDetails,
  getTractionDetails,
} from "./selectors";
import CompanyProfile_Aia from "./CompanyProfile_Aia";
import CompanyProfile_Regular from "./CompanyProfile_Regular";
import TopNavBar from "atoms/TopNavBar/TopNavBar";
import { ProfileNavigationProvider } from "./components/aia/ProfileNavigation/context";

const PublicCompanyProfile: React.FC<any> = ({ params }) => {
  /* Other hooks */
  const { lang } = useLang();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  /* Selectors */
  const loading = useSelector((state) =>
    isLoadingEnabled(state, LOADER_COMPANY_BY_SLUG)
  );
  const data = useSelector(getCompanyDetails);
  const traction = useSelector(getTractionDetails);
  const analysis = useSelector(getAnalysisDetails);
  const competitors = useSelector(getCompetitorsDetails);
  const isLinkRemoved = useSelector((state) => isUserLinkRemoved(state));
  const { currencyCode } = useSelector((state) => getCurrencyDetails(state));

  /* Effects */
  useEffect(() => {
    const isDark = Boolean(searchParams.get("dark"));
    if (!isDark) return;

    const element = document.documentElement;
    element.classList.add("dark");

    return () => {
      element.classList.remove("dark");
    };
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      initializeCompanyProfile({
        lang,
        params,
        isPublicCompanyProfile: true,
      })
    );
  }, []);

  if (data?.companyBySlug?.businessModel?.includes("OTHERS")) {
    for (let i in data?.companyBySlug?.businessModel) {
      if (data?.companyBySlug?.businessModel[i] === "OTHERS") {
        data?.companyBySlug?.businessModel.splice(i, 1);
        data?.companyBySlug?.businessModel.push("OTHERS");
      }
    }
  }

  if (loading) {
    return <Loading fullPage />;
  }

  if (!data?.companyBySlug) {
    return <ServerError />;
  }

  if (isLinkRemoved) {
    return <UnavailableLink />;
  }

  if (data?.companyBySlug?.isAiaGenerated) {
    return (
      <>
        <TopNavBar />
        <ProfileNavigationProvider>
          <CompanyProfile_Aia
            data={data}
            traction={traction}
            analysis={analysis}
            competitors={competitors}
            currencyCode={currencyCode}
            isPublic
          />
        </ProfileNavigationProvider>
      </>
    );
  } else
    return (
      <>
        <TopNavBar />
        <CompanyProfile_Regular
          data={data}
          traction={traction}
          analysis={analysis}
          currencyCode={currencyCode}
          isPublic
        />
      </>
    );
};

export default PublicCompanyProfile;
