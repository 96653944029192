import { FC, useState } from "react";
import cn from "classnames";
import { FundingRound } from "gql/graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHeader from "../SectionHeader/SectionHeader";

interface Props {
  fundingRounds: FundingRound[];
  pitchbookUrl?: string;
  crunchbaseUrl?: string;
}

const Expandable: FC<{ fundingRound: FundingRound }> = ({ fundingRound }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClick = () => setIsExpanded((prev) => !prev);

  return (
    <div
      className={cn(
        "hover:bg-gray-100 py-3 px-2 cursor-pointer transition-all duration-500",
        isExpanded && "bg-gray-50"
      )}
      onClick={onClick}
    >
      <div className="flex justify-between">
        <span>{fundingRound.name}</span>
        <FontAwesomeIcon
          icon={["fal", isExpanded ? "chevron-up" : "chevron-down"]}
          color="#4fc2ba"
          size="lg"
        />
      </div>
      {isExpanded && (
        <div className="text-sm">
          <h6 className="mt-2 font-medium text-gray-500">Announced On</h6>
          <p className="mb-2">{fundingRound.announcedDate}</p>
          {fundingRound.moneyRaised && (
            <>
              <h6 className="font-medium text-gray-500 ">Money Raised</h6>
              <p className="mb-2">{fundingRound.moneyRaised}</p>
            </>
          )}
          {fundingRound.leadInvestors && (
            <>
              <h6 className="mt-2 font-medium text-gray-500">Lead Investors</h6>
              <p className="mb-2">{fundingRound.leadInvestors}</p>
            </>
          )}
          {fundingRound.investors && fundingRound.investors.length > 0 && (
            <>
              <h6 className="mt-2 font-medium text-gray-500">Investors</h6>
              <div className="flex flex-col items-start justify-start text-sm text-gray-600 basis-1/3">
                {fundingRound.investors.map((item) => (
                  <p>{item ? item.name : "-"}</p>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const FundingRounds: FC<Props> = ({
  fundingRounds,
  pitchbookUrl,
  crunchbaseUrl,
}) => {
  return (
    <div className="container px-6 mx-auto">
      <SectionHeader title="Recent funding rounds" />
      {fundingRounds && fundingRounds?.length !== 0 ? (
        <>
          {/* Desktop */}
          <div className="hidden w-full border border-gray-200 rounded md:block ">
            <div className="flex px-3 py-3 border-b border-b-gray-200">
              <div className="p-1 text-sm text-gray-400 basis-1/3">
                Announced On
              </div>
              <div className="p-1 text-sm text-gray-400 basis-1/3">Round</div>
              <div className="p-1 text-sm text-gray-400 basis-1/3">
                Money Raised
              </div>
              <div className="p-1 text-sm text-gray-400 basis-1/3">
                Investors
              </div>
              <div className="p-1 text-sm text-gray-400 basis-1/3">
                Lead Investors
              </div>
            </div>

            {fundingRounds.map((item, index: number) => {
              if (item === null) return null;
              return (
                <div
                  className="flex px-3 py-3 border-b border-b-gray-200"
                  key={index}
                >
                  <div className="p-1 basis-1/3">
                    <div className="items-start my-2 text-sm font-semibold text-gray-600">
                      {item?.announcedDate}
                    </div>
                  </div>
                  <div className="flex items-start p-1 text-sm text-gray-600 basis-1/3">
                    {item?.name}
                  </div>
                  <div className="flex items-start p-1 text-sm text-gray-600 basis-1/3">
                    {item?.moneyRaised || "-"}
                  </div>
                  <div className="flex flex-col items-start justify-start p-1 text-sm text-gray-600 basis-1/3">
                    {item.investors && item.investors.length > 0
                      ? item.investors.map((investor) => (
                          <p>{investor ? investor.name : "-"}</p>
                        ))
                      : "-"}
                  </div>
                  <div className="flex items-start p-1 text-sm text-gray-600 basis-1/3">
                    {item?.leadInvestors || "-"}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Mobile */}
          <div className="md:hidden">
            {fundingRounds.map((round) => (
              <Expandable key={round.name} fundingRound={round} />
            ))}
          </div>
          <p className="mt-2">
            AIA cannot guarantee the accuracy of the data. Please check the
            source before drawing any conclusions:
            {crunchbaseUrl && (
              <a
                className="text-blue-400 ml-2"
                title="Crunchbase"
                rel="noreferrer"
                target="_blank"
                href={crunchbaseUrl}
              >
                Crunchbase
              </a>
            )}
            {pitchbookUrl && (
              <a
                className="text-blue-400 ml-2"
                title="Pitchbook"
                rel="noreferrer"
                target="_blank"
                href={pitchbookUrl}
              >
                Pitchbook
              </a>
            )}
          </p>
        </>
      ) : (
        <p>We were unable to find information about recent funding rounds.</p>
      )}
    </div>
  );
};

export default FundingRounds;
