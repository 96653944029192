import type { ColumnDescription } from "./interfaces";

export const COLUMNS_LIST: ColumnDescription[] = [
  {
    key: "displayName",
    title: "Name",
    isSortable: true,
    isPreview: true,
    isLeftSticky: true,
  },
  {
    key: "createdAt",
    title: "Added",
    isSortable: true,
  },
  {
    key: "elevator",
    title: "Pitch",
  },
  {
    key: "industries",
    title: "Industry",
  },
  {
    key: "finance.raisedValue",
    title: "Committed",
    isSortable: true,
  },
  {
    key: "finance.raisedTarget",
    title: "Target",
    isSortable: true,
  },
  {
    key: "valuation",
    title: "Valuation",
    isSortable: true,
  },
  {
    key: "teamMembers",
    title: "Team",
  },
  {
    key: "country",
    title: "Location",
    isSortable: true,
  },
  {
    key: "investors",
    title: "Former Investors",
  },
  {
    key: "documents",
    title: "Pitch Deck",
  },
  {
    key: "sustainableDevelopmentGoals",
    title: "SDG",
  },
  {
    key: "cohort",
    title: "Cohort",
  },
  {
    key: "status",
    title: "Status",
    isPreview: true,
  },
  {
    key: "expand",
    title: "",
  },
];
