import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import ActivationMainImage from "images/activation-main.png";
import ActivationDealflowImage from "images/activation-dealflow.png";
import ActivationBoardsImage from "images/activation-boards.png";
import { ReactComponent as Sircular } from "images/sircular.svg";
import DismissActivationModal from "./components/DismissActivationModal";
import DealflowSection from "./components/DealflowSection";
import BoardsSection from "./components/BoardsSection";
import { useActivationContext } from "../context";
import { setGlobalState } from "models/globalState/sagaActions";
import { useDispatch } from "react-redux";
import { UserType } from "../types";

const MainPanel: FC<{ userType: UserType }> = ({ userType }) => {
  const [isDismissActivationModalOpen, setIsDismissActivationModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setGlobalState({ isActivationMainPanelVisible: true }));

    return () => {
      dispatch(setGlobalState({ isActivationMainPanelVisible: false }));
    };
  }, []);

  const {
    isMinimized,
    setIsMinimized,
    openSection,
    setOpenSection,
    activationStatus,
    setActivationStatus,
  } = useActivationContext();

  const handleClose = () => {
    setIsDismissActivationModalOpen(true);
  };

  const minimizePanel = () => {
    setIsMinimized(true);
  };

  const maximizePanel = () => {
    setIsMinimized(false);
  };

  const dismissActivation = () => {
    setActivationStatus({
      Main: true,
      Dealflow: true,
      BoardsOrAnalytics: true,
    });
    setIsDismissActivationModalOpen(false);
  };

  const onDealflowActiovationClick = () =>
    setOpenSection((prev) => (prev === "dealflow" ? "none" : "dealflow"));

  const onBoardsActiovationClick = () =>
    setOpenSection((prev) =>
      prev === "boardsOrAnalytics" ? "none" : "boardsOrAnalytics"
    );

  const onDealflowActiovationButtonClick = () => {
    minimizePanel();
    const url = userType === "investor" ? "/dealflow" : "/startup-dealflow";
    navigate(url);
  };

  const onBoardsActiovationButtonClick = () => {
    minimizePanel();
    const url = userType === "investor" ? "/boards" : "/myspace";
    navigate(url);
  };

  const getImageSrc = () => {
    switch (openSection) {
      case "dealflow":
        return ActivationDealflowImage;
      case "boardsOrAnalytics":
        return ActivationBoardsImage;
      default:
        return ActivationMainImage;
    }
  };

  const numberOfUnseenActivations = activationStatus
    ? Object.entries(activationStatus).reduce((acc, cur) => {
        if (cur[0] === "Main") return acc;
        if (cur[1]) return acc;
        return acc + 1;
      }, 0)
    : 3;

  return (
    <>
      <div
        className={cn(
          "fixed bottom-0 right-0 z-50 sm:m-6 bg-white w-full sm:w-auto",
          isMinimized && "rounded-full !w-auto m-2"
        )}
      >
        <div
          className={cn(
            "relative border border-gray-300 rounded-t-lg",
            isMinimized && "hidden"
          )}
        >
          <img className="h-48 p-6 m-auto" src={getImageSrc()} />
          <div className="absolute right-3 top-3">
            <FontAwesomeIcon
              icon={["fal", "times"]}
              className="mr-3 cursor-pointer"
              size="lg"
              color="gray"
              onClick={handleClose}
            />
            <FontAwesomeIcon
              icon={["fal", "chevron-down"]}
              className="cursor-pointer"
              size="lg"
              color="gray"
              onClick={minimizePanel}
            />
          </div>
        </div>

        <div
          className={cn(
            "text-white bg-gradient-to-r from-[#18BEB3] to-[#05999F] flex p-3 border-b border-b-aqua-300 w-full sm:w-96 relative",
            isMinimized && "cursor-pointer !w-auto rounded-full px-6"
          )}
          onClick={isMinimized ? maximizePanel : undefined}
        >
          <span className="text-lg font-medium">Get started with</span>
          <Sircular className="ml-1" />
          {isMinimized && (
            <div className="absolute inline-flex items-center justify-center w-5 h-5 mr-2 text-white rounded-full -right-2 bg-aqua-400 -top-2">
              {numberOfUnseenActivations}
            </div>
          )}
        </div>

        <div
          className={cn(
            "text-white bg-gradient-to-r from-[#18BEB3] to-[#05999F] rounded-b-lg border-b border-b-aqua-400",
            isMinimized && "hidden"
          )}
        >
          <DealflowSection
            isOpen={openSection === "dealflow"}
            isDone={!!activationStatus?.Dealflow}
            onClick={onDealflowActiovationClick}
            onButtonClick={onDealflowActiovationButtonClick}
            userType={userType}
          />
          {/* For startups this becomes Analytics section */}
          <BoardsSection
            isOpen={openSection === "boardsOrAnalytics"}
            isDone={!!activationStatus?.BoardsOrAnalytics}
            onClick={onBoardsActiovationClick}
            onButtonClick={onBoardsActiovationButtonClick}
            userType={userType}
          />
        </div>
      </div>

      <DismissActivationModal
        isOpen={isDismissActivationModalOpen}
        setIsOpen={setIsDismissActivationModalOpen}
        dismissActivationHandler={dismissActivation}
      />
    </>
  );
};

export default MainPanel;
