import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { RatingProps } from "./interfaces";
import cn from "classnames";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export type RatingStarSize = "sm" | "lg" | "2x" | "3x" | "4x" | "5x";
export type RatingTextSize =
  | "text-sm"
  | "text-md"
  | "text-lg"
  | "text-4xl"
  | "text-5xl";

const Rating: FC<RatingProps> = ({
  rating,
  noOfReviews,
  ratingStarsize,
  ratingTextSize,
}) => {
  return (
    <div className="flex flex-col mr-8">
      <div className="flex flex-row mb-1">
        <h1 className={cn(ratingTextSize, "italic")}>{rating}</h1>
        <FontAwesomeIcon
          icon={["fas", "star"]}
          size={`${ratingStarsize}` as SizeProp}
          className="mb-2 ml-3 text-yellow-400"
        />
      </div>
      <p className="text-sm text-gray-500">{noOfReviews} total</p>
    </div>
  );
};

export default Rating;
