// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
import { NetworkStatus } from "./constants";

export const initialState = {
  isTracked: false,
  profileDropdownIsOpen: false,
  notificationDropdownIsOpen: false,
  chatDropdownIsOpen: false,
  contactRequestModalIsOpen: false,
  cancelSubscriptionModalIsOpen: false,
  downloadExcelModalIsOpen: false,
  podcastPlayerIsOpen: false,
  podcastPlayerSource: undefined,
  navigationHeight: 110,
  isNavigationEnabled: false,
  showSupportPanel: false,
  menuDrawerIsOpen: false,
  isCohortModalOpen: true,
  companyHasNews: false,
  countryCode: undefined,
  network: { status: NetworkStatus.Online, showStausBar: false },
  isActivationMainPanelVisible: false,
};

export const name = "models/globalState";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateGlobalState: (state, { payload: { stateArgs } }) => ({
      ...state,
      ...stateArgs,
    }),
    updateCountryCode: (state, { payload: { countryCode } }) => ({
      ...state,
      countryCode,
    }),
  },
  // contry code is initialized in App component and should not reset after logout
  extraReducers(builder) {
    builder.addCase("resetState", (state) => ({
      ...initialState,
      countryCode: state.countryCode,
    }));
  },
});

export default slice;

export const { updateGlobalState, updateCountryCode } = slice.actions;
