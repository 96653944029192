import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";
import CurrentRegulations from "./CurrentRegulations";
import FutureRegulations from "./FutureRegulations";
import useProfileNavigation from "../ProfileNavigation/useProfileNavigation";
import { PROFILE_SECTION_IDS } from "../ProfileNavigation/constants";

interface Props {
  todaysLegislation?: string;
  futureLegislation?: string;
  isLoading?: boolean;
}

const Legislation: FC<Props> = ({
  todaysLegislation,
  futureLegislation,
  isLoading,
}) => {
  const { profileSectionRef } = useProfileNavigation();

  if (isLoading)
    return (
      <div className="my-4">
        <SectionHeader title="Legislation" />
        <Skeleton count={3.6} />
      </div>
    );

  if (!todaysLegislation && !futureLegislation) return null;

  const getGridColumns = () => {
    if (todaysLegislation && futureLegislation) return "grid-cols-2";
    else if (todaysLegislation || futureLegislation) return "grid-cols-1";
  };

  return (
    <div id={PROFILE_SECTION_IDS.Regulations} ref={profileSectionRef}>
      <SectionHeader title="Regulations" />
      <div className={"mb-4 grid gap-4 grid-cols-1 md:" + getGridColumns()}>
        {todaysLegislation && (
          <CurrentRegulations content={todaysLegislation} />
        )}
        {futureLegislation && <FutureRegulations content={futureLegislation} />}
      </div>
    </div>
  );
};

export default Legislation;
