// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export type Login = {
  hasError: boolean;
  loginError: String;
};

const login: Login = {
  hasError: false,
  loginError: "",
};

export const initialState = {
  login: login,
  isUserConfirmed: true,
  alreadyRegistered: false,
  googleLoading: false,
  formElementVal: null,
  googleSignUpSuccess: false
};

export const name = "models/auth";
const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateUserConfirmStatus: (state, { payload: { isUserConfirmed } }) => ({
      ...state,
      isUserConfirmed,
    }),
    updateLoginData: (state, { payload: { login } }) => ({
      ...state,
      login,
    }),
    logout: (state, { payload: { login } }) => ({
      ...state,
      login,
    }),
    updateAlreadyRegistered(state, { payload: { alreadyRegistered } }) {
      return {
        ...state,
        alreadyRegistered,
      };
    },
    socialLoginReducer(state, { payload: { googleLoading } }) {
      return {
        ...state,
        googleLoading,
      };
    },
    setFormElementValue(state, { payload: { input } }) {
      return {
        ...state,
        formElementVal: input,
      };
    },
    setGoogleSignUpSuccess() {
      return {
        googleSignUpSuccess: true
      } 
    }
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const {
  updateUserConfirmStatus,
  updateLoginData,
  logout,
  updateAlreadyRegistered,
  setFormElementValue,
  socialLoginReducer,
  setGoogleSignUpSuccess
} = slice.actions;
