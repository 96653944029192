const STORAGE_KEY = "sort_seed";

export const getSortSeed = () => {
  const seed = localStorage.getItem(STORAGE_KEY);
  const seedNumber = seed ? parseFloat(seed) : -1;

  if (!seed || seedNumber <= 0 || seedNumber > 1) {
    const newSeed = Math.random() + 0.001;

    localStorage.setItem(STORAGE_KEY, String(newSeed));

    return newSeed;
  }

  return seedNumber;
};
