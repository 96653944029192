// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  snacksList: [],
};

const slice = createSlice({
  name: "organisms/snackPortal",
  initialState,
  reducers: {
    handleUpdateSnacks: (
      state,
      { payload: { type, title, message, timer, key } }
    ) => {
      if (state.snacksList.find((s) => s.key === key)) {
        return;
      }

      return {
        ...state,
        snacksList: state.snacksList.concat({
          type,
          title,
          message,
          timer,
          key,
        }),
      };
    },
    removeSnack: (state, { payload: { id } }) => ({
      ...state,
      snacksList: state.snacksList.filter(({ key }) => key !== id),
    }),
  },
});

export default slice;

export const { handleUpdateSnacks, removeSnack } = slice.actions;
