import type { Cohort } from "gql/graphql";
import { FC } from "react";
import { Transition } from "@headlessui/react";
import { ReactComponent as Logo } from "images/logo-black.svg";
import { ReactComponent as HandshakeImage } from "images/success-handshake.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "atoms/Button/Button";
import { refetchUserData } from "models/user/sagaActions";
import { setGlobalState } from "models/globalState/sagaActions";

interface SuccessScreenProps {
  cohort: Cohort;
  show: boolean;
}

const SuccessScreen: FC<SuccessScreenProps> = ({ show, cohort }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(refetchUserData({}));
    dispatch(setGlobalState({ isCohortModalOpen: false }));
    navigate("/company-profile");
  };

  return (
    <Transition
      show={show}
      className="relative z-30 flex flex-col items-center justify-center w-screen h-screen transition delay-500"
      enter="easy-in-out duration-500"
      enterFrom="scale-95 opacity-0"
      enterTo="scale-100 opacity-100"
    >
      <div className="w-[776px] max-w-full bg-white rounded-lg px-4 py-8">
        <Logo className="block h-6 mb-16" />
        <div className="flex">
          <div className="ml-10">
            <div className="flex items-center">
              <img
                className="object-cover w-12 h-12 border-4 border-gray-300 border-opacity-50 rounded-full"
                src={cohort.logo?.large?.location ?? ""}
                alt=""
              />
              <span className="ml-2 text-lg font-bold text-black">
                {cohort.owner?.displayName}
              </span>
            </div>
            <h3 className="my-2 text-xl font-bold text-black">
              Has received your application
            </h3>
          </div>
          <HandshakeImage className="max-w-[290px] ml-6" />
        </div>

        <Button
          color="primary"
          onClick={handleClick}
          className="w-36 m-auto !block mt-8"
        >
          To my profile
        </Button>
      </div>
    </Transition>
  );
};

export default SuccessScreen;
