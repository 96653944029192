import { FC } from "react";
import { Person, PersonInput, Team } from "gql/graphql";
import MemberCard from "./MemberCard";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import EditablePlaceholderText from "../reusables/EditablePlaceholderText";

export type Role = "FOUNDER" | "MEMBER";

interface Props {
  team: Team | undefined | null;
  founders: Person[] | undefined | null;
}

const MeetTheTeam: FC<Props> = ({ team, founders }) => {
  const dispatch = useDispatch();

  const handleSave = (person: PersonInput, role: Role) => {
    // Based on person type (founder/member) different lists should get updated
    // Updated person is to be found in one of these lists. Both lists must be filtered in case role has been changed
    // Image type has to be removed from Person, since PersonInput has Upload type instead

    const filteredMembers =
      team?.members
        ?.filter((m) => m.id !== person.id)
        .map((i) => {
          const { image, ...newPerson } = i;
          (newPerson as PersonInput).image = undefined;
          return newPerson;
        }) || [];

    const filteredFounders =
      founders
        ?.filter((f) => f.id !== person.id)
        .map((i) => {
          const { image, ...newPerson } = i;
          (newPerson as PersonInput).image = undefined;
          return newPerson;
        }) || [];

    if (role === "MEMBER") {
      dispatch(
        updateStartupProfile({
          founders: filteredFounders,
          team: {
            members: [...filteredMembers, person],
          },
        })
      );
    }

    if (role === "FOUNDER") {
      dispatch(
        updateStartupProfile({
          founders: [...filteredFounders, person],
          team: {
            members: filteredMembers,
          },
        })
      );
    }
  };

  const handleRemove = (id: string) => {
    const filteredMembers =
      team?.members
        ?.filter((m) => m.id !== id)
        .map((i) => {
          const { image, ...newPerson } = i;
          (newPerson as PersonInput).image = undefined;
          return newPerson;
        }) || [];

    const filteredFounders =
      founders
        ?.filter((f) => f.id !== id)
        .map((i) => {
          const { image, ...newPerson } = i;
          (newPerson as PersonInput).image = undefined;
          return newPerson;
        }) || [];

    dispatch(
      updateStartupProfile({
        founders: filteredFounders,
        team: {
          members: filteredMembers,
        },
      })
    );
  };

  return (
    <div className="mt-6 w-full">
      <h1 className="text-xl mb-2">Meet the Team</h1>
      <EditablePlaceholderText
        title="Team Description"
        value={team?.note}
        fieldName="team.note"
        addValueTitle="Describe your team"
        placeholderDescription="Investors love to know about your founding team members to figure out if your company is in good hands."
      />

      {founders && founders.length > 0 && (
        <>
          <div className="text-lg mt-4 -mb-2 lg:mb-0">Founders</div>
          <div className="lg:grid grid-cols-2 gap-x-4 gap-y-3">
            {founders.map((founder) => (
              <MemberCard
                member={founder}
                role="FOUNDER"
                key={founder.id}
                handleSave={handleSave}
                handleRemove={handleRemove}
              />
            ))}
          </div>
        </>
      )}

      {team?.members && team?.members?.length > 0 && (
        <>
          <div className="text-lg mt-4 -mb-2 lg:mb-0">Members</div>
          <div className="lg:grid grid-cols-2 gap-x-4 gap-y-3">
            {team.members.map((member) => (
              <MemberCard
                member={member}
                role="MEMBER"
                key={member.id}
                handleSave={handleSave}
                handleRemove={handleRemove}
              />
            ))}
          </div>
        </>
      )}

      <div className="mt-4 lg:mt-6">
        <MemberCard
          member={null}
          handleSave={handleSave}
          handleRemove={() => undefined}
        />
      </div>
    </div>
  );
};

export default MeetTheTeam;
