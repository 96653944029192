import MultiSelect from "atoms/Form/MultiSelect";
import { FC } from "react";
import { ReactComponent as CloseIcon } from "images/gray-close-icon.svg";
import { IndustryKey } from "./InvestorDealflow/utils";

type SelectedIndustries = {
  key: string;
  value: any;
}[];

interface IndustryProps {
  options: SelectedIndustries;
  selectedIndustries: SelectedIndustries;
  onIndustriesChange: (value: IndustryKey[]) => void;
  removeIndustry: (key: string) => void;
}

const SelectIndustries: FC<IndustryProps> = ({
  options,
  selectedIndustries,
  onIndustriesChange,
  removeIndustry,
}) => (
  <div className="z-40 w-full">
    <MultiSelect
      placeholder="All Industries"
      label="Industries"
      className="!outline-none !border-gray-200 !py-1.5 shadow-sm !rounded-xl !leading-5"
      showLabel
      checkMark={false}
      showChecked={false}
      clear={false}
      options={options}
      onChange={(e: any) => onIndustriesChange(e)}
    />
    {selectedIndustries && selectedIndustries.length > 0 && (
      <div className="flex flex-wrap gap-2 mt-2">
        {Object.values(selectedIndustries).map((industry, index) => (
          <div
            key={index}
            className="flex items-center capitalize justify-between px-3 py-2 text-xs font-medium text-aqua-400 bg-[#E4F5F4] rounded-md"
          >
            {industry.value}
            <button
              type="button"
              className="flex-shrink-0 ml-1.5 -mr-1.5 text-[#699DC6] hover:text-gray-500"
              onClick={() => removeIndustry(industry.key)}
            >
              <CloseIcon className="h-2" />
            </button>
          </div>
        ))}
      </div>
    )}
  </div>
);
export default SelectIndustries;
