// @ts-nocheck
import _ from "lodash";
import { all } from "redux-saga/effects";
import { modelSagas } from "../../models";
import { organismsSagas } from "../../organisms";
import { Pages } from "../../pages";

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  return yield all(
    _.map(
      _.uniq([...modelSagas, ...organismsSagas, ...getPageSagas()]),
      (callback) => callback()
    )
  );
}

export const getPageSagas = () => {
  /* Each imported page can have several config objects, hence flattened */
  const flatPages = _.flatten(Pages);

  const pageSagas = flatPages.map((i) => i.importSagas());

  /* Each config object can have several sagas, hence flattened */
  const flatSagas = _.flatten(pageSagas);

  return flatSagas;
};
