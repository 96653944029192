// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  company: {
    isEnabled: false,
    data: null,
  },
  investor: {
    isEnabled: false,
    data: null,
  },
};

export const name = "organisms/contactRequestModal";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    triggerCompanyContactRequestModalData(
      state,
      { payload: { isEnabled, data } }
    ) {
      return {
        ...state,
        company: {
          isEnabled,
          data,
        },
      };
    },
    triggerInvestorContactRequestModalData(
      state,
      { payload: { isEnabled, data } }
    ) {
      return {
        ...state,
        investor: {
          isEnabled,
          data,
        },
      };
    },
  },
  extraReducers(builder) {
    builder.addCase("resetState", () => initialState);
  },
});

export default slice;

export const {
  triggerCompanyContactRequestModalData,
  triggerInvestorContactRequestModalData,
} = slice.actions;
