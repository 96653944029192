import { gql } from "@apollo/client";

export const SAVE_BUSINESS_MODEL = gql`
  mutation saveCompanyBusinessModel($businessModelDescription: String) {
    saveCompanyBusinessModel(
      businessModelDescription: $businessModelDescription
    ) {
      _id
      businessModelDescription
    }
  }
`;

export const GET_BUSINESS_MODEL = gql`
  query getBusinessModel {
    me {
      _id
      completedPercentage
      completedSteps
      completedMajorSteps
      businessModelDescription
    }
  }
`;
