import { gql } from "@apollo/client";

export const MANUALLY_ADD_COMPETITORS = gql`
  mutation manuallyAddCompetitors(
    $companyId: String!
    $competitorUrls: [String!]
  ) {
    aiaCompetitor {
      manuallyAddCompetitors(
        companyId: $companyId
        competitorUrls: $competitorUrls
      ) {
        done
      }
    }
  }
`;

export const GET_COMPETITORS = gql`
  query getAiaCompetitors($companyId: String!) {
    aiaCompetitor {
      competitors(companyId: $companyId) {
        marketSegmentAnalysis {
          marketSegmentation
          marketGrowthDrivers
        }
        aiaCompetitors {
          id
          name
          hq {
            iso2
            name
            emoji
          }
          presence {
            name
            iso2
            emoji
          }
          logo {
            large {
              location
            }
          }
          founders {
            name
            title
          }
          productOverview {
            customerSegment
            businessModel
            productDescription
            keyFeatures
            valueProposition
            pricing
          }
          linkedInCompany {
            companyUrl
            followerCount
          }
          pitch
          source
          comparison {
            similarities
            differences
          }
          appstore {
            appInfo {
              currentVersionScore
              numberOfRatings
              appStoreUrl
            }
          }
          competitiveLandscapeAnalysis {
            marketPositionAnalysis
          }
          finances {
            revenue {
              year
              revenue
            }
            crunchbaseUrl
            pitchbookUrl
            fundingRounds {
              name
              moneyRaised
              announcedDate
              leadInvestors
              numberOfInvestors
              investors {
                name
                partners
              }
            }
          }
          website
          isValidated
          isDeepDive
        }
      }
    }
  }
`;

export const FETCH_MORE_COMPETITORS = gql`
  mutation fetchMoreCompetitors($companyId: String!) {
    aiaCompetitor {
      fetchMoreCompetitors(companyId: $companyId) {
        id
      }
    }
  }
`;

export const COMPETITOR_DEEP_DIVE = gql`
  mutation competitorDeepDive($companyId: ID!, $competitorIds: [ID!]) {
    aiaCompetitor {
      competitorDeepDive(companyId: $companyId, competitorIds: $competitorIds) {
        done
      }
    }
  }
`;
