import { gql } from "@apollo/client";

export const GET_INVITATION_BY_SLUG = gql`
  query getInvitationBySlug($slug: String!) {
    getInvitationBySlug(slug: $slug) {
      id
      slug
      status
      email
      creator {
        id
        inviter {
          name
          logo {
            small {
              location
            }
          }
        }
      }
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation acceptInvitation($input: AcceptInvitationInput!) {
    acceptInvitation(input: $input)
  }
`;

// Not in use atm
export const REJECT_INVITATION = gql`
  mutation rejectInvitation($id: String!) {
    rejectInvitation(id: $id)
  }
`;
