import {
  ColumnDescription,
  DealflowColumn,
  DealflowRecord,
  GetDealflow,
  GetDealflowRecords,
  ListMyCohortsShort,
} from "../interfaces";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { COLUMNS_LIST } from "../table";
import { useSelector } from "react-redux";
import { getCurrencyList, getUser } from "models/user/selectors";
import { useGuard } from "hooks/useGuard";
import { MustBeInvestor } from "core/routePolicies";
import {
  GET_DEALFLOW,
  GET_DEALFLOW_RECORDS,
  UPDATE_DEALFLOW_COLUMNS,
} from "queries/investor/investorDealflow";
import { LIST_MY_COHORTS_SHORT } from "queries/investor/cohort";
import { cohortLink } from "utils/links";

const FIRST_PAGE = 1;

const getNormalPage = (fromParams: string | null) =>
  Math.max(FIRST_PAGE, parseInt(fromParams || "0") || FIRST_PAGE);

const populateColumn = (column: DealflowColumn): ColumnDescription =>
  COLUMNS_LIST.find((item) => item.key === column.key) || column;

const populateColumns = (columns: DealflowColumn[]) =>
  columns
    .map((column) => populateColumn(column))
    .filter((c) => c.key !== "investors");

const useDealflow = () => {
  const navigate = useNavigate();
  const { createdAt } = useSelector(getUser);
  const [searchParams] = useSearchParams();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [page, setPage] = useState(getNormalPage(searchParams.get("page")));
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [cohorts, setCohorts] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);
  const [filterSections, setFilterSections] = useState<string[]>([]);
  const [sort, setSort] = useState({ key: "createdAt", order: -1 });
  const [selectedRecord, setSelectedRecord] = useState<DealflowRecord>();
  const [previewRecord, setPreviewRecord] = useState<DealflowRecord>();
  const [editRecord, setEditRecord] = useState<DealflowRecord>();
  const {
    data: dealflowData,
    loading: isDealflowLoading,
    refetch: refetchDealflow,
  } = useQuery<GetDealflow>(GET_DEALFLOW);
  const currencies = useSelector(getCurrencyList);
  const {
    data: companiesData,
    previousData: companiesPreviousData,
    loading: isCompaniesLoading,
    refetch,
  } = useQuery<GetDealflowRecords>(GET_DEALFLOW_RECORDS, {
    variables: {
      page,
      query,
      cohorts,
      sections: filterSections,
      industries,
      sort,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: myCohortsData, loading: isMyCohortsLoading } =
    useQuery<ListMyCohortsShort>(LIST_MY_COHORTS_SHORT);
  const [updateColumns] = useMutation(UPDATE_DEALFLOW_COLUMNS);

  useEffect(() => {
    if (!companiesData?.dealflow.records || !selectedRecord) {
      return;
    }

    const newValue = companiesData.dealflow.records.nodes.find(
      (record) => record.id === selectedRecord.id
    );

    if (newValue) {
      setSelectedRecord(newValue);
    }
  }, [companiesData?.dealflow.records, selectedRecord]);

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
    setPage(FIRST_PAGE);
  };

  const handlePageChange = setPage;

  const handleCohortsChange = (newCohorts: typeof cohorts) => {
    setCohorts(newCohorts);
    setPage(FIRST_PAGE);
  };

  const handleSectionsChange = (newSections: typeof filterSections) => {
    setFilterSections(newSections);
    setPage(FIRST_PAGE);
  };

  const handleIndustriesChange = (newIndustries: typeof industries) => {
    console.log(newIndustries);
    setIndustries(newIndustries);
    setPage(FIRST_PAGE);
  };

  const handleSortChange = setSort;

  const handleColumnsChange = async (newColumns: DealflowColumn[]) => {
    const columns = newColumns.map(({ title, key }) => ({ title, key }));

    try {
      await updateColumns({ variables: { columns } });
      refetchDealflow();
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleSelect = (record: DealflowRecord) => {
    setSelectedRecord(record);
    setPreviewRecord(record);
  };

  const handleCloseSidebar = () => setSelectedRecord(undefined);

  const handleClosePreview = () => setPreviewRecord(undefined);

  const handleGotoCohorts = () => navigate("/applications");

  const handleOpenModal = () => setIsShowModal(true);

  const handleCloseModal = (needToRefetch?: boolean) => {
    setIsShowModal(false);

    if (needToRefetch) {
      refetchDealflow();
    }
  };

  const handleCloseInviteModal = () => setIsInviteModal(false);

  const handleOpenAddModal = () => setIsAddModal(true);

  const handleEdit = (record: DealflowRecord) => {
    setIsAddModal(true);
    setEditRecord(record);
  };

  const handleCloseAddModal = () => {
    setIsAddModal(false);
    setEditRecord(undefined);
  };

  const populateRecords = (records: DealflowRecord[]) =>
    records.map((record) => ({
      ...record,
      company: {
        ...record.company,
        currencyData: currencies.find(
          (item: any) => item._id === record.company.currency
        ),
      },
    }));

  useGuard([MustBeInvestor]);

  const finalCompaniesData = isCompaniesLoading
    ? companiesPreviousData
    : companiesData;
  const myCohorts = myCohortsData?.listMyCohorts || [];
  const columns = populateColumns(dealflowData?.dealflow.columns || []);
  const sections = dealflowData?.dealflow.sections || [];
  const records = populateRecords(
    finalCompaniesData?.dealflow.records.nodes || []
  );
  const total = finalCompaniesData?.dealflow.records.total || 0;
  const perPage = finalCompaniesData?.dealflow.records.perPage || 0;
  const totalPages = Math.ceil(total / perPage);
  const isLoading =
    isDealflowLoading || isCompaniesLoading || isMyCohortsLoading;
  const isFiltering =
    Boolean(query) || Boolean(cohorts.length) || Boolean(filterSections.length);
  const isSearchEmpty = !isLoading && records.length === 0 && isFiltering;
  const isNoResults = !isLoading && records.length === 0 && !isFiltering;
  const showingFrom = 1 + (page - 1) * perPage;
  const showingTo = showingFrom + records.length - 1;
  const invitationLink = dealflowData?.dealflow.invitationCohortSlug
    ? cohortLink(dealflowData.dealflow.invitationCohortSlug)
    : null;

  const userCreatedAt = createdAt;

  return {
    isLoading,
    isSearchEmpty,
    isNoResults,
    isShowModal,
    isAddModal,
    isInviteModal,
    myCohorts,
    columns,
    sections,
    industries,
    records,
    invitationLink,
    total,
    totalPages,
    page,
    query,
    cohorts,
    sort,
    showingFrom,
    showingTo,
    filterSections,
    selectedRecord,
    previewRecord,
    editRecord,
    userCreatedAt,
    refetch,
    handleColumnsChange,
    handleQueryChange,
    handlePageChange,
    handleCohortsChange,
    handleSectionsChange,
    handleIndustriesChange,
    handleGotoCohorts,
    handleSortChange,
    handleOpenModal,
    handleCloseModal,
    handleSelect,
    handleCloseSidebar,
    handleClosePreview,
    handleOpenAddModal,
    handleCloseAddModal,
    handleCloseInviteModal,
    handleEdit,
  };
};

export default useDealflow;
