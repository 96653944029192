import type { SwitchProps } from "./interfaces";
import type { FC } from "react";

const Switch: FC<SwitchProps> = ({
  texts: [closeText, openText] = ["Closed", "Open"],
  checked,
  onChange,
  size = "medium",
}) => {
  const className = [
    "relative rounded-full text-white flex justify-center items-center cursor-pointer",
    checked ? "bg-aqua-400" : "bg-gray-500",
    size === "medium" ? "w-24 h-9 text-sm" : "",
    size === "small" ? "w-20 h-7 text-xs" : "",
  ].join(" ");

  const openTextClassName = [
    "absolute left-2 transition duration-150",
    checked ? "opacity-100" : "opacity-0",
  ].join(" ");

  const closeTextClassName = [
    "absolute right-2 transition duration-150",
    checked ? "opacity-0" : "opacity-100",
  ].join(" ");

  const circleClassName = [
    "absolute bg-white rounded-full left-0 transition duration-150",
    checked && size === "medium" ? "translate-x-16" : "",
    checked && size === "small" ? "translate-x-14" : "",
    !checked ? "translate-x-1" : "",
    size === "medium" ? "w-7 h-7" : "",
    size === "small" ? "w-5 h-5" : "",
  ].join(" ");

  return (
    <div className={className} onClick={() => onChange(!checked)}>
      <span className={closeTextClassName}>{closeText}</span>
      <span className={openTextClassName}>{openText}</span>
      <span className={circleClassName}></span>
    </div>
  );
};

export default Switch;
