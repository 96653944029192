import { FC, useEffect, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch, useSelector } from "react-redux";
import { SingleSelect, SingleSelectChoice } from "molecules/SingleSelect";
import { getCurrencyList } from "models/user/selectors";
import { Currency } from "pages/dealflow/InvestorDealflow/interfaces";
import { formatCurrencyValues } from "pages/companyProfile/utils";

interface Props {
  target: number | undefined | null;
  currencyCode?: string;
}

const TargetAndCurrency: FC<Props> = ({ target, currencyCode }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedCurrency, setSelectedCurrency] =
    useState<SingleSelectChoice | null>(null);

  const currencyData: Currency[] = useSelector((state) =>
    getCurrencyList(state)
  );

  useEffect(() => {
    const cur = currencyData?.find((c) => c.code === currencyCode);
    if (cur) {
      setSelectedCurrency({ id: cur?._id, title: cur?.code });
    }
  }, [currencyCode, currencyData]);

  useEffect(() => {
    setInputValue(String(target));
  }, [target]);

  const dispatch = useDispatch();

  const onCancel = () => {
    setIsEditMode(false);
  };

  const onSave = () => {
    dispatch(
      updateStartupProfile({
        fundraising: {
          target: Number(inputValue),
          currency: selectedCurrency?.id,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="flex flex-col">
        <div className="flex border p-1 rounded mb-2">
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="grow pl-2 text-gray-500 min-w-0"
          />

          <SingleSelect
            choices={currencyData.map((c) => ({
              id: c._id,
              title: c.code,
            }))}
            value={selectedCurrency}
            onChange={(e: SingleSelectChoice) => setSelectedCurrency(e)}
          />
        </div>
        <SaveAndCancelButtons
          onSave={onSave}
          onCancel={onCancel}
          className="self-end"
        />
      </div>
    );
  };

  const renderContent = () => (
    <div>
      <span className="text-base font-bold">
        {target &&
          selectedCurrency &&
          formatCurrencyValues(target, selectedCurrency.title as string)}
      </span>
    </div>
  );

  return (
    // <div className="grow">
    <EditablePlaceholder
      title="Target"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={target ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
    // </div>
  );
};

export default TargetAndCurrency;
