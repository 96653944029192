import tw, { styled, css } from "twin.macro";

export const ModalContent = styled.div(() => [
  tw`p-6 text-center relative`,
  css`
    padding: 0px;
    h3 {
      font-size: 14px;
      margin-bottom: 0px;
      font-weight: 500;
    }
  `,
]);

export const ModalHeader = styled.div(() => [
  css`
    margin-bottom: 30px;
  `,
]);

export const InvestmentRangeStyle = styled.div(() => [
  css`
    @keyframes rcSliderTooltipZoomDownIn {
      0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
      }
      100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
      }
    }
    @keyframes rcSliderTooltipZoomDownOut {
      0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
      }
      100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
      }
    }
    .rc-slider {
      position: relative;
      height: 14px;
      padding: 5px 0;
      width: 97%;
      border-radius: 6px;
      touch-action: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
    .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: #e9e9e9;
      height: 4px;
      border-radius: 6px;
    }
    .rc-slider-track {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: 6px;
      background-color: #18beb3;
    }
    .rc-slider-handle {
      position: absolute;
      width: 20px;
      height: 20px;
      cursor: pointer;
      cursor: -webkit-grab;
      margin-top: -8px;
      cursor: grab;
      border-radius: 50%;
      background-color: #18beb3;
      touch-action: pan-x;
      border: 2px solid #fff;
      &:focus {
        outline: none;
      }
      &:hover {
        border-color: #57c5f7;
      }
      &:active {
        border-color: #57c5f7;
        box-shadow: 0 0 5px #57c5f7;
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
      &:before {
        content: "=";
        color: #fff;
        position: absolute;
        top: -5px;
        left: 3px;
      }
    }
    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: #57c5f7;
    }
    .rc-slider-handle-click-focused {
      &:focus {
        border-color: #96dbfa;
        box-shadow: unset;
      }
    }
    .rc-slider-mark {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }
    .rc-slider-mark-text {
      // width: 50px;
      // position: absolute;
      // display: inline-block;
      // vertical-align: middle;
      // right: 0;
      cursor: pointer;
      color: #999;
      transform: none !important;
    }
    .rc-slider-mark-text-active {
      color: #666;
    }
    .rc-slider-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
    }
    .rc-slider-dot {
      position: absolute;
      bottom: -2px;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border: 2px solid #e9e9e9;
      background-color: #fff;
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
    }
    .rc-slider-dot-active {
      border-color: #96dbfa;
    }
    .rc-slider-dot-reverse {
      margin-right: -4px;
    }
    .rc-slider-disabled {
      background-color: #e9e9e9;
      .rc-slider-track {
        background-color: #ccc;
      }
      .rc-slider-handle {
        border-color: #ccc;
        box-shadow: none;
        background-color: #fff;
        cursor: not-allowed;
      }
      .rc-slider-dot {
        border-color: #ccc;
        box-shadow: none;
        background-color: #fff;
        cursor: not-allowed;
        cursor: not-allowed !important;
      }
      .rc-slider-mark-text {
        cursor: not-allowed !important;
      }
    }
    .rc-slider-vertical {
      width: 14px;
      height: 100%;
      padding: 0 5px;
      .rc-slider-rail {
        height: 100%;
        width: 4px;
      }
      .rc-slider-track {
        left: 5px;
        bottom: 0;
        width: 4px;
      }
      .rc-slider-handle {
        margin-left: -5px;
        touch-action: pan-y;
      }
      .rc-slider-mark {
        top: 0;
        left: 18px;
        height: 100%;
      }
      .rc-slider-step {
        height: 100%;
        width: 4px;
      }
      .rc-slider-dot {
        left: 2px;
        margin-bottom: -4px;
        &:first-child {
          margin-bottom: -4px;
        }
        &:last-child {
          margin-bottom: -4px;
        }
      }
    }
    .rc-slider-tooltip-zoom-down-enter {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      display: block !important;
      animation-play-state: paused;
      transform: scale(0, 0);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-appear {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      display: block !important;
      animation-play-state: paused;
      transform: scale(0, 0);
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .rc-slider-tooltip-zoom-down-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      display: block !important;
      animation-play-state: paused;
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active {
      animation-name: rcSliderTooltipZoomDownIn;
      animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
      animation-name: rcSliderTooltipZoomDownIn;
      animation-play-state: running;
    }
    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
      animation-name: rcSliderTooltipZoomDownOut;
      animation-play-state: running;
    }
    .rc-slider-tooltip {
      position: absolute;
      left: -9999px;
      top: -9999px;
      visibility: visible;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
    .rc-slider-tooltip-hidden {
      display: none;
    }
    .rc-slider-tooltip-placement-top {
      display: none;
    }
    .rc-slider-tooltip-inner {
      display: none;
    }
  `,
]);
