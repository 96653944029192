import { AiaTaskPayload } from "gql/graphql";
import { FC, useEffect, useState } from "react";

interface AddedContextLoaderProps {
  contextTask?: AiaTaskPayload | null;
}

const AddedContextLoader: FC<AddedContextLoaderProps> = ({ contextTask }) => {
  const [isInvestmentAnalysis, setIsInvestmentAnalysis] = useState(false);
  const [isExecSummary, setIsExecSummary] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isFinance, setIsFinance] = useState(false);
  const [isMarketOpp, setIsMarketOpp] = useState(false);
  const [isPricing, setIsPricing] = useState(false);
  const [isBusinessModel, setIsBusinessModel] = useState(false);
  const [isBasics, setIsBasics] = useState(false);
  const [isTractionSummary, setIsTractionSummary] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isCompetitors, setIsCompetitors] = useState(false);

  useEffect(() => {
    const addedContextLoaded = contextTask?.addedContextLoaded;
    if (addedContextLoaded?.investementAnalysis) {
      setIsInvestmentAnalysis(true);
    }
    if (addedContextLoaded?.execSummary) {
      setIsExecSummary(true);
    }
    if (
      addedContextLoaded?.customers ||
      addedContextLoaded?.productDescription ||
      addedContextLoaded?.valueProposition
    ) {
      setIsProduct(true);
    }
    if (addedContextLoaded?.finance || addedContextLoaded?.valuation) {
      setIsFinance(true);
    }
    if (
      addedContextLoaded?.marketOpp ||
      addedContextLoaded?.marketOppAnalysis
    ) {
      setIsMarketOpp(true);
    }
    if (addedContextLoaded?.pricing) {
      setIsPricing(true);
    }
    if (addedContextLoaded?.businessModelDescription) {
      setIsBusinessModel(true);
    }
    if (
      addedContextLoaded?.businessCategory ||
      addedContextLoaded?.industry ||
      addedContextLoaded?.companyStage
    ) {
      setIsBasics(true);
    }
    if (addedContextLoaded?.elevatorPitch) {
      setIsExecSummary(true);
    }
    if (addedContextLoaded?.tractionSummary) {
      setIsTractionSummary(true);
    }
    if (addedContextLoaded?.teamAnalysis || addedContextLoaded?.teamMembers) {
      setIsTeam(true);
    }
    if (addedContextLoaded?.competitors) {
      setIsCompetitors(true);
    }
  }, [contextTask?.addedContextLoaded]);

  const noUpdates =
    !isInvestmentAnalysis &&
    !isExecSummary &&
    !isProduct &&
    !isFinance &&
    !isMarketOpp &&
    !isPricing &&
    !isBusinessModel &&
    !isBasics &&
    !isTractionSummary &&
    !isTeam;

  return (
    <div className="flex flex-col text-left px-4 rich-text-content-wrapper">
      <h1 className="text-white mb-2">Updated sections</h1>
      <ul>
        {isBasics && <li className="text-white">Basic Information</li>}
        {isBusinessModel && <li className="text-white">Business Model</li>}
        {isFinance && <li className="text-white">Finance</li>}
        {isInvestmentAnalysis && (
          <li className="text-white">Investment Analysis</li>
        )}
        {isMarketOpp && <li className="text-white">Market Opportunity</li>}
        {isPricing && <li className="text-white">Pricing</li>}
        {isProduct && <li className="text-white">Product</li>}
        {isTeam && <li className="text-white">Team</li>}
        {isTractionSummary && <li className="text-white">Traction</li>}
        {isExecSummary && <li className="text-white">Executive Summary</li>}
        {isCompetitors && <li className="text-white">Competitors</li>}
        {noUpdates && (
          <li className="text-white">
            No updates where found in the added context
          </li>
        )}
      </ul>
    </div>
  );
};

export default AddedContextLoader;
