import { FC } from "react";
import { SocialLink } from "../aia/Team/Team.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, formatDuration } from "date-fns";
import PureHtml from "atoms/PureHtml";
import SectionHeader from "../shared/SectionHeader/SectionHeader";

interface Props {
  founders?: any[];
  members?: any[];
  teamNote?: string;
}

const Team_Regular: FC<Props> = ({ founders, members, teamNote }) => {
  return (
    <div className="my-2">
      <SectionHeader title="Meet the Team" />

      {teamNote && <PureHtml content={teamNote} tag="p" className="mb-4" />}

      {founders && founders.length > 0 && (
        <div className="mb-2">
          <h4 className="text-lg">Founders</h4>
          <div className="flex flex-wrap gap-2 mt-1">
            {founders?.map((founder: any, index: number) => (
              <TeamMember key={index} member={founder} index={index} />
            ))}
          </div>
        </div>
      )}

      {members && members.length > 0 && (
        <div>
          <h4 className="text-lg">Members</h4>
          <div className="flex flex-wrap gap-2 mt-1">
            {members?.map((member: any, index: number) => (
              <TeamMember key={index} member={member} index={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Team_Regular;

interface TeamMemberProps {
  member: any;
  index: number;
}

const TeamMember: FC<TeamMemberProps> = ({ member, index }) => {
  return (
    <div className="flex items-center border border-aqua-200 rounded-lg p-2 gap-3">
      <img
        className="shadow-[0_0_40px_#e2e2e2] dark:shadow-none rounded-full w-16 h-16"
        src={
          member?.image?.large?.location
            ? member?.image?.large?.location
            : `https://sircular-io.s3.eu-north-1.amazonaws.com/static/jake-${
                ((index + 1) % 6) + 1
              }.png`
        }
        alt={`member-${index}`}
      />
      <div className="flex flex-col gap-1">
        <h5 className="font-medium dark:text-white/80">{member.name}</h5>

        <h6 className="text-sm text-gray-700 dark:text-white">
          {member?.title}
        </h6>

        <div className="flex">
          {member?.email && (
            <SocialLink
              type="email"
              href={`mailto:${member.email}`}
              target="_blank"
            >
              <FontAwesomeIcon icon={["fas", "envelope"]} />
            </SocialLink>
          )}

          {member?.twitter && (
            <SocialLink
              type="twiiter"
              href={
                member?.twitter.includes("https")
                  ? member?.twitter
                  : `https://${member?.twitter}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "twitter"]} />
            </SocialLink>
          )}

          {member?.linkedin && (
            <SocialLink
              type="linkedin"
              href={
                member?.linkedin.includes("https")
                  ? member?.linkedin
                  : `https://${member?.linkedin}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} />
            </SocialLink>
          )}

          {member?.instagram && (
            <SocialLink
              type="instagram"
              href={
                member?.instagram.includes("https")
                  ? member?.instagram
                  : `https://${member?.instagram}`
              }
              target="_blank"
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </SocialLink>
          )}
        </div>
      </div>
    </div>
  );
};
