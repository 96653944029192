import React from "react";
import { ReactComponent as XlIcon } from "images/xl-icon.svg";
import { ReactComponent as PdfIcon } from "images/pdf-icon.svg";
import { ReactComponent as Download } from "images/download-pitchdeck.svg";
import Tooltip from "atoms/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { touch, TouchType } from "analytics";
import { getCompanyDetails } from "pages/companyProfile/selectors";
import { useSearchParams } from "react-router-dom";
import { downloadAndRenameFile } from "utils/downloadAndRename";

const isTouchScreen = window.matchMedia("(pointer: coarse)").matches;

interface Props {
  documents: any[];
  hidePreview?: boolean;
  hidePdfList?: boolean;
  containerClassName?: string;
}

const PitchDeck: React.FC<Props> = ({
  documents,
  hidePreview = false,
  hidePdfList = false,
  containerClassName = "",
}) => {
  const companyData = useSelector(getCompanyDetails);
  const [searchParams] = useSearchParams();

  const getDocumentUrl = (doc: any) => {
    const res = doc?.file?.location?.split("/");
    if (res) {
      const id = res[res.length - 1];
      return `https://sircular-io.s3.eu-north-1.amazonaws.com/documents/${id}`;
    } else if (doc?.location?.split("/")) {
      return doc?.location;
    }
    return "";
  };

  const handleMarkDownload = (documentId: string) => {
    touch(TouchType.PitchDownload, {
      company: companyData?.companyBySlug?.id,
      id: documentId,
      us: searchParams.get("us") ?? undefined,
      uc: searchParams.get("uc") ?? undefined,
    });
  };

  const handleDownloadButtonClicled = (document: any) => {
    handleMarkDownload(document.id);
    const url = getDocumentUrl(document);
    downloadAndRenameFile(url, document.title || "pitchdeck");
  };

  if (documents?.length <= 0) {
    return null;
  }

  return (
    /* Container class needs to change based on different scenarios */
    <div className={containerClassName}>
      <div className="flex flex-col mx-auto md:px-0 h-full">
        {!isTouchScreen &&
          !hidePreview &&
          documents &&
          documents.length > 0 && (
            <object
              data={getDocumentUrl(documents[0])}
              type="application/pdf"
              className="grow border-none hidden md:flex my-4 lg:my-0 min-h-[24rem]"
            >
              <span>Your browser does not support PDFs.</span>
              <a href={getDocumentUrl(documents[0])}>Download the PDF</a>
              instead.
            </object>
          )}

        {!hidePdfList && (
          <div className="flex flex-wrap mt-4">
            {documents?.map((document: any, index: number) => (
              <Tooltip content={document?.title} key={index}>
                <button
                  className="flex items-center justify-between h-8 px-3 py-4 mb-2 mr-1 bg-white border rounded-full dark:bg-black md:mb-1"
                  onClick={() => handleDownloadButtonClicled(document)}
                >
                  <div className="flex items-center">
                    {document?.mimetype === "application/pdf" ? (
                      <PdfIcon className="inline-block h-5 -ml-2" />
                    ) : (
                      <XlIcon className="inline-block h-8 md:h-6" />
                    )}
                    <span className="max-w-xs ml-2 overflow-hidden text-sm text-ellipsis whitespace-nowrap w-[8rem]">
                      {document?.title}
                    </span>
                  </div>
                  <Download className="ml-2" />
                </button>
              </Tooltip>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PitchDeck;
