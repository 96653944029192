export const PROFILE_SECTION_IDS = {
  ["Executive Summary"]: "executive-summary-section",
  Team: "team-section",
  ["Cap Table"]: "cap-table-section",
  Traction: "traction-section",
  Product: "product-section",
  Competitors: "competitors-section",
  ["Business Model & Pricing"]: "business-model-section",
  Market: "market-section",
  Regulations: "regulation-section",
  ESG: "esg-section",
  News: "news-section",
} as const;

type Keys = keyof typeof PROFILE_SECTION_IDS;
export type SectionId = (typeof PROFILE_SECTION_IDS)[Keys];
