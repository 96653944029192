import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImagePlaceholder from "images/investor-placeholder-img.png";
import { Person } from "gql/graphql";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";

interface Props {
  investor: Person | null;
  handleRemoveInvestor: (id: string) => void;
}

const InvestorCard: FC<Props> = ({ investor, handleRemoveInvestor }) => {
  return (
    <div className="w-full lg:w-auto z-10">
      <div className="w-full p-4 mt-2 border border-dashed rounded-lg border-aqua-400 bg-white flex items-center relative">
        <img
          src={investor?.logo ? investor.logo : ImagePlaceholder}
          className="object-cover w-20 h-20 rounded-full mr-4"
        />

        <div className="grow">
          <div>{investor?.name}</div>
          <div>{investor?.title}</div>
          {investor?.linkedin && (
            <a
              target="_blank"
              href={investor?.linkedin}
              rel="noreferrer"
              className=""
            >
              <LinkedinIcon />
            </a>
          )}
        </div>

        <FontAwesomeIcon
          icon={["fal", "times"]}
          className="mr-3 cursor-pointer absolute -top-2 -right-4 text-aqua-400 border border-aqua-400 p-px !w-6 !h-6 rounded-full bg-aqua-100"
          onClick={() => {
            if (investor?.id) handleRemoveInvestor(investor?.id);
          }}
        />
      </div>
    </div>
  );
};
export default InvestorCard;
