// @ts-nocheck
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormUnregister,
  Controller,
} from "react-hook-form";
import { EMAIL_PATTERN, LINKEDIN_PATTERN } from "core/consts";
import { useLang } from "core/lang/LangaugeSetting";
import { ReactComponent as EmailIcon } from "images/email-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/likedin-Icon.svg";
import { ReactComponent as UserIcon } from "images/user-icon.svg";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { SimpleInput } from "atoms/Form/SimpleInput";
import NameGenderField from "./NameGenderField";
import SingleSelect from "molecules/Form/SingleSelect";
import { ProfilePictureSelect } from "organisms/ProfilePictureUpload";

const SELECT_OPTIONS = [0, 1, 2, 3, 4, 5, 6];

export type TeamMember = {
  id: string;
  name: string;
  gender: string;
  title: string;
  image: Blob;
  email: string;
  linkedin: string;
  previouslyFounded: number;
  successfulExits: number;
};

type TeamMemberInputProps<T> = {
  id: string;
  control: Control<T>;
  register: UseFormRegister<T>;
  unregister: UseFormUnregister<T>;
  index: number;
  errors?: FieldErrors<T>;
  removeItem: (id: string) => void;
  fieldName: string;
};

const TeamMemberInput = <T extends FieldValues>({
  id,
  control,
  register,
  unregister,
  index,
  errors,
  removeItem,
  fieldName,
  label,
}: TeamMemberInputProps<T>) => {
  const { lang } = useLang();

  const isFounder = fieldName === "founders";

  const className = [index > 0 ? "mt-4" : "", `${fieldName}-item`].join(" ");

  return (
    <div key={index} className={className}>
      <div className="grid sm:grid-cols-2 md:flex sm:gap-4">
        <div className="w-full sm:col-span-2 icon-set">
          <NameGenderField
            label={label}
            control={control}
            fieldName={fieldName}
            icon={<UserIcon />}
            index={index}
          />
        </div>
        <div className="w-full -mt-5 sm:col-span-2">
          <div className="w-full sm:col-span-2">
            <div
              className={`py-3 pl-2 mt-11 outline-none form-input flex w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded text-gray-700 shadow-sm border${
                errors?.founders?.[index]?.title
                  ? " border-red-300 text-red-900 placeholder-red-300"
                  : " text-gray-700 border-black border-opacity-25"
              }`}
              // style={{ paddingLeft: 7, marginTop: 45 }}
            >
              <div className="text-lg text-gray-500">
                <StarIcon />
              </div>
              <Controller
                control={control}
                name={`${fieldName}.${index}.title`}
                render={({ field: { value, onChange } }) => (
                  <input
                    placeholder={lang.titleRole}
                    value={value}
                    onChange={({ target }) => onChange(target.value)}
                    className="w-full pl-3"
                  />
                )}
              />
            </div>
            {errors?.[fieldName]?.[index]?.title && (
              <p className="mt-2 mb-2 text-sm text-fire-400">
                {lang.mandatory}
              </p>
            )}
          </div>
        </div>
      </div>
      {isFounder && (
        <>
          <div className="mt-4 grid sm:mt-4 sm:grid-cols-2 md:flex sm:gap-4">
            <div className="flex flex-col justify-end w-full">
              <label className="text-sm font-medium text-black text-opacity-100">
                No. of previously founded companies
              </label>
              <div className="mt-2">
                <Controller
                  control={control}
                  name={`founders.${index}.previouslyFounded`}
                  render={({ field: { value, onChange } }) => (
                    <SingleSelect
                      label={SELECT_OPTIONS[0]}
                      defaultValue={value}
                      options={SELECT_OPTIONS}
                      onChange={(selected: number) => onChange(selected)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col justify-end w-full">
              <label className="text-sm font-medium text-black text-opacity-100">
                No. of successful exits
              </label>
              <div className="mt-2">
                <Controller
                  control={control}
                  name={`founders.${index}.successfulExits`}
                  render={({ field: { value, onChange } }) => (
                    <SingleSelect
                      label={SELECT_OPTIONS[0]}
                      defaultValue={value}
                      options={SELECT_OPTIONS}
                      onChange={(selected: number) => onChange(selected)}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <Controller
        control={control}
        name={`${fieldName}.${index}.email`}
        rules={{
          pattern: EMAIL_PATTERN,
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <SimpleInput
            icon={<EmailIcon />}
            placeholder={lang.enterEmailAddress}
            value={value}
            onChange={onChange}
            error={error && lang.thisIsNotValidEmail}
          />
        )}
      />

      <Controller
        control={control}
        name={`${fieldName}.${index}.linkedin`}
        rules={{
          pattern: LINKEDIN_PATTERN,
        }}
        render={({
          field: { value, onChange, name },
          fieldState: { error },
        }) => (
          <SimpleInput
            placeholder={lang.enterLinkedinLink}
            icon={<LinkedinIcon />}
            value={value}
            onChange={onChange}
            error={error && lang.urlMustBeLInkedinUrl}
          />
        )}
      />

      <div className="mt-5 mb-2">
        <Controller
          control={control}
          name={`${fieldName}.${index}.image`}
          render={({ field: { value, onChange } }) => (
            <ProfilePictureSelect
              label="Upload profile picture"
              onComplete={(image) => onChange(image)}
              preview={value?.large?.location}
            />
          )}
        />
      </div>

      <div
        className="block -mt-2 text-sm text-right cursor-pointer align-right"
        style={{ color: "#FF8060" }}
        onClick={() => removeItem(id)}
      >
        {lang.remove}
      </div>
    </div>
  );
};

export default TeamMemberInput;
