import { FC, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import type { SupplyFrequencyTraction } from "gql/graphql";
import { PERRATEPERIOD, SUPPLYFREQUNITS } from "core/consts";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import TractionInputDropdown, { OnSaveFunc } from "./TractionInputDropdown";
import { useDispatch } from "react-redux";

interface Props {
  frequency: Array<SupplyFrequencyTraction | null> | null | undefined;
  note: string | null | undefined;
}

const SupplyFrequency: FC<Props> = ({ frequency, note }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();

  const frequencyData = frequency?.[0];

  const renderContent = () => {
    return (
      <div>
        {frequencyData?.amount &&
        frequencyData?.frequency &&
        frequencyData.unit ? (
          <div>
            {frequencyData.amount} {SUPPLYFREQUNITS[frequencyData.unit]}
            {PERRATEPERIOD[frequencyData.frequency]}
          </div>
        ) : (
          "---"
        )}
        {note && <p>{note}</p>}
      </div>
    );
  };

  const onSave: OnSaveFunc = ({
    inputValue,
    dropdownValue,
    secondDropdownValue,
    noteValue,
  }) => {
    dispatch(
      updateStartupProfile({
        traction: {
          supplyFreq: {
            amount: inputValue,
            unit: dropdownValue,
            frequency: secondDropdownValue,
          },
          supplyFreqNote: noteValue,
        },
      })
    );

    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <TractionInputDropdown
        inputDefaultValue={frequencyData?.amount}
        dropdownDefaultValue={frequencyData?.unit}
        secondDropdownDefaultValue={frequencyData?.frequency}
        noteDefaultValue={note}
        dropdownChoices={Object.entries(SUPPLYFREQUNITS).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        secondDropdownChoices={Object.entries(PERRATEPERIOD).map((i) => ({
          id: i[0],
          title: i[1],
        }))}
        setIsEditMode={setIsEditMode}
        onSave={onSave}
      />
    );
  };

  return (
    <EditablePlaceholder
      title="Supply Frequency"
      style="secondary"
      value={undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderContent={frequencyData?.amount ? renderContent : undefined}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default SupplyFrequency;
