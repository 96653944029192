import { FC, useState } from "react";
import GoogleInput from "molecules/Form/GoogleInput";
import { ReactComponent as LocationIcon } from "images/location-icon.svg";
import { Location } from "gql/graphql";
import { useDispatch } from "react-redux";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
interface Props {
  location: Location | undefined | null;
}

const HeadOffice: FC<Props> = ({ location }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<object>();
  const dispatch = useDispatch();

  const handleGetPlace = ({
    autoCompleteVal,
    ...rest
  }: {
    city: string;
    country: string;
    fullAddress: string;
    lat: number;
    long: number;
    state: string;
    autoCompleteVal: string;
  }) => {
    setSelectedLocation(rest);
  };

  const onSave = () => {
    dispatch(updateStartupProfile({ location: selectedLocation }));
    setIsEditMode(false);
  };

  const onCancel = () => {
    setSelectedLocation(undefined);
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div>
        <GoogleInput
          getPlace={handleGetPlace}
          placeholder={"City"}
          variant="rect"
          id="city"
          defaultValue={undefined}
          name="city"
          icon={<LocationIcon />}
        />
        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right mt-2"
        />
      </div>
    );
  };

  return (
    <EditablePlaceholder
      title="Head Office"
      style="secondary"
      value={location?.fullAddress}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default HeadOffice;
