import { FC } from "react";
import { SectionId } from "./constants";
import cn from "classnames";

interface Props {
  title: string;
  sectionId: SectionId;
  activeSectionId: SectionId | undefined;
  sectionRef: HTMLDivElement | undefined;
}

const ProfileNavigationItem: FC<Props> = ({
  title,
  sectionId,
  activeSectionId,
  sectionRef,
}) => {
  const handleClick = () => {
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={cn(
        "my-2 ml-3 pl-3 border-l-4 cursor-pointer text-gray-500",
        sectionId === activeSectionId
          ? "border-l-aqua-400"
          : "border-l-neutral-100"
      )}
      onClick={handleClick}
    >
      {title}
    </div>
  );
};

export default ProfileNavigationItem;
