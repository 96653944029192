import { gql } from "@apollo/client";

export const AIA_UPLOAD = gql`
  mutation AIAUpload($pdf: Upload!) {
    aia {
      upload(pdf: $pdf) {
        slug
        id
      }
    }
  }
`;

export const INITIALISE_AIA_STARTUP_LIST = gql`
  mutation initialiseStartupList($file: Upload!) {
    aia {
      initialiseStartupList(file: $file) {
        taskId
        numOfRecords
        estimatedCost
        websiteColumn
      }
    }
  }
`;

export const ANALYSE_AIA_STARTUP_LIST = gql`
  mutation analyseStartupList($taskId: String!) {
    aia {
      analyseStartupList(taskId: $taskId)
    }
  }
`;

export const ANALYZE_PITCHDECK_URL = gql`
  mutation analyzePitchdeckUrl($url: String!, $type: PitchDeckUrlType!) {
    aia {
      analyzePitchUrl(url: $url, type: $type) {
        slug
        id
      }
    }
  }
`;

export const AIA_WEBSITE_UPLOAD = gql`
  mutation AIAWebsiteUpload($url: String!) {
    aia {
      uploadWebsite(url: $url) {
        slug
        id
      }
    }
  }
`;

export const VISION_AI = gql`
  mutation visionAI($images: [Upload!]) {
    aia {
      visionAI(images: $images) {
        text
      }
    }
  }
`;

export const AIA_TRIAL_STATUS = gql`
  query getAiaTrialStatus {
    me {
      company {
        aiaTrial {
          isOver
          totalUploads
          trialDaysLeft
        }
      }
    }
  }
`;

/* Test query for quickly testing an individual analysis */

export const AIA_TEST = gql`
  mutation test($companyId: String!) {
    aia {
      test(companyId: $companyId) {
        result
      }
    }
  }
`;

export const ADD_CONTEXT = gql`
  mutation addContext($companyId: String!, $contents: String) {
    aia {
      addContextAndReload(companyId: $companyId, contents: $contents) {
        id
      }
    }
  }
`;

export const ADD_INVESTOR_CRITERIA = gql`
  mutation addInvestorCriteria($input: [InvestorCriteriaInput!]) {
    aia {
      addInvestorCriteria(input: $input)
    }
  }
`;

export const GET_INVESTOR_CRITERIA = gql`
  query getInvestorCriteria {
    aia {
      investorCriteria {
        category
        description
        importance
      }
    }
  }
`;
