import Button from "atoms/Button/Button";
import { useUpgradePlanModal } from "organisms/AccessControl/UpgradePlanModal";
import { FC } from "react";

interface AiaHeaderProps {
  tier: string;
  showInvestmentCriteria: boolean;
  isGrantedInvestmentCriteria: boolean;
  handleOpenInvestmentCriteria: () => void;
  handleCloseInvestmentCriteria: () => void;
}

const AiaHeader: FC<AiaHeaderProps> = ({
  tier,
  showInvestmentCriteria,
  handleOpenInvestmentCriteria,
  isGrantedInvestmentCriteria,
}) => {


  return (
    <div className="flex flex-col font-sans items-center mt-20 w-full">
      <div className="relative flex flex-col items-center mb-3 md:mb-8">
        <p className="ml-12 md:ml-0 space-x-1 text-3xl font-bold sm:text-[84px] mb-8 text-black">
          Welcome to Sircular
        </p>
      <p className="text-black text-xl font-sans sm:text-4xl">Upload pitch decks. Get reports in minutes.</p>
      </div>
    </div>
  );
};

export default AiaHeader;
