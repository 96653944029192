import type { TeaserStepProps } from "../interfaces";
import FileSelect from "atoms/Form/FileSelect";
import { FC, useState } from "react";
import BackButton from "../BackButton";
import { useDispatch } from "react-redux";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ALLOWED_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/pdf",
];

const MAX_FILE_SIZE = 20000000;

const PitchDeck: FC<TeaserStepProps> = ({ onNext, onPrev }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<File[]>([]);

  const triggerError = (title: string, message: string) =>
    dispatch(
      triggerSnack({
        type: "error",
        title,
        message,
      })
    );

  const handleUpload = (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      triggerError(
        "Wrong file type",
        "Please upload only .xlsx, .xls, .pdf format"
      );

      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      triggerError(
        "Too large file",
        "File size is too large, it must be less than 20 MB"
      );
      return;
    }

    setFiles(files.concat(file));
  };

  const getHandleRemove = (index: number) => () =>
    setFiles(files.filter((_, idx) => idx !== index));

  const handleNext = () =>
    onNext({
      pitchDeck: {
        files,
      },
    });

  return (
    <div className="relative w-full h-full px-2 py-8 md:px-16">
      <BackButton onAction={onPrev} />
      <h2 className="ml-2 text-xl text-center">Pitch deck</h2>
      <div className="my-8 font-bold text-center text-gray-500">
        Upload your pitch deck and any other documents that you'd like to
        present to the investors
      </div>
      <FileSelect
        multiple
        type="document"
        onChange={handleUpload}
        disabled={false}
        loading={false}
        imageDesc="Drop your files here or "
      />
      <div className="mt-4">
        {files.map((file, index) => (
          <div
            key={index}
            className="flex items-center justify-between p-2 mb-1 border border-gray-200 rounded"
          >
            <span className="text-gray-500">{file.name}</span>
            <button
              title="Remove this file"
              type="button"
              className="flex p-1 text-sm text-red-400 rounded hover:text-red-600 focus:text-red-600"
              onClick={getHandleRemove(index)}
            >
              <FontAwesomeIcon icon={["fas", "trash"]} />
            </button>
          </div>
        ))}
      </div>
      <div className="flex justify-end mt-8">
        <button
          className="px-4 py-2 font-bold text-white rounded-lg bg-aqua-400 hover:bg-aqua-300"
          onClick={handleNext}
        >
          To my profile
        </button>
      </div>
    </div>
  );
};

export default PitchDeck;
