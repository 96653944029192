import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

/* MySpace Module Imports */
import MySpace from "pages/mySpace/MySpace";

/* Auth Module Imports */
import ForgotPassword from "pages/auth/ForgotPassword/ForgotPassword";
import ConfirmRegistrationPage from "pages/auth/ConfirmRegistration/ConfirmRegistrationPage";
import ResetPassword from "pages/auth/ResetPassword/ResetPassword";
import NewLoginPage from "pages/auth/LoginPage/NewLoginPage";

/* Dealflow Moldule Imports */
import { InvestorDealflow, StartupDealflow } from "pages/dealflow";

/* Boards Moldule Imports */
import { PrivateStartupsBoards, PublicStartupBoard } from "pages/startupsBoard";

/* Applications Moldule Imports */
import Applications from "pages/applications/Applications";
import PendingApplications from "pages/pendingApplications/PendingApplications";

/* CompanyProfile Module Imports */
import CompanyProfile from "pages/companyProfile/CompanyProfile";
import EditStartupProfile from "pages/editStartupProfile/EditStartupProfile";
import RedirectCompanyProfile from "pages/companyProfile/RedirectCompanyProfile";

/* Admin Module Imports */
import AdminDiscover from "pages/admin/AdminDiscover/AdminDiscover";

/* CompanySettings Module Imports */
import CompanySettings from "pages/companySettings/CompanySettings";

import Messages from "pages/messages/Messages";

/* Plans Module Imports */
import PlansContainer from "pages/plans/PlansContainer";
import { CheckoutSuccess } from "pages/plans/components/Checkout";

/* Onboarding */
import Onboard from "pages/onboard/Onboard";

/* Accounts Module Imports */
import AccountSettings from "pages/account/AccountSettings";

/* Invitaion Module Imports */
import InvitationLoader from "pages/invitation/InvitationLoader";
import InvitationPage from "pages/invitation/InvitationPage";

/* AIA Module Imports */
import Aia from "pages/aia";
import AiaProfileLoader from "pages/aia/AiaProfileLoader";

/* Explore Module Imports */
import Explore from "pages/explore/Explore";

/* Core Navigation Components */
import AppRoute from "core/AppRoute";
import AppRoutePublic from "core/AppRoutePublic";
import AppRouteProtected from "core/AppRouteProtected";
import { getRedirectNavRoute } from "core/redirectNavRoute";

/* Selectors */
import { getUserType } from "models/user/selectors";
import ApiSandBox from "pages/aiaSandbox/ApiSandBox";

const RootRoutes = () => {
  const navRoute = getRedirectNavRoute();

  return (
    // TODO: In the next step, we only want to have page level routes here. All subroutes will be handled in the page.
    <Routes>
      <Route path="/onboard" element={<Onboard />} />

      {/* ------------------------- Auth Module ------------------------ */}
      <Route path="/login" element={<AppRoute Component={NewLoginPage} />} />

      <Route
        path="/login/:id"
        element={<AppRoute Component={NewLoginPage} />}
      />

      <Route
        path="/register"
        element={<AppRoutePublic Component={Onboard} />}
      />

      <Route
        path="/register/:id"
        element={<AppRoutePublic Component={NewLoginPage} />}
      />

      <Route
        path="/forgot-password"
        element={<AppRoute Component={ForgotPassword} />}
      />

      <Route
        path="/confirm/:token"
        element={<AppRoute Component={ConfirmRegistrationPage} />}
      />

      <Route
        path="/reset/:token"
        element={<AppRoute Component={ResetPassword} />}
      />

      {/* --------------------------- MySpace Module ---------------------- */}
      <Route
        path="/myspace"
        element={<AppRouteProtected Component={MySpace} />}
      />

      {/* Needs guard */}
      <Route
        path="/myspace/*"
        element={<AppRouteProtected Component={MySpace} />}
      />

      {/* ------------------------- Dealflow Module ------------------------- */}
      {/* Needs guard */}
      <Route
        path="/dealflow"
        element={<AppRouteProtected Component={InvestorDealflow} />}
      />

      {/* Needs guard */}
      <Route
        path="/startup-dealflow"
        element={<AppRouteProtected Component={StartupDealflow} />}
      />

      {/* ------------------------- Applications Module ------------------------- */}

      {/* Needs guard */}
      <Route
        path="/applications"
        element={<AppRouteProtected Component={Applications} />}
      />

      {/* Needs guard */}
      <Route
        path="/pending-applications"
        element={<AppRouteProtected Component={PendingApplications} />}
      />

      {/* ------------------------- Boards Module ------------------------- */}
      {/* Needs guard */}
      <Route
        path="/boards"
        element={<AppRouteProtected Component={PrivateStartupsBoards} />}
      />
      <Route
        path="/board/public/:slug"
        element={<AppRoutePublic Component={PublicStartupBoard} />}
      />

      {/* ------------------------- ProfileSelect Module ------------------------- */}
      <Route
        path="/profile"
        element={<AppRouteProtected Component={ProfileRedirect} />}
      />

      {/* ------------------------- CompanyProfile Module ------------------------- */}
      {/* Needs guard */}
      <Route
        path="/company-profile"
        element={<AppRouteProtected Component={CompanyProfile} />}
      />

      <Route
        path="/edit-startup-profile"
        element={<AppRouteProtected Component={EditStartupProfile} />}
      />

      {/* Needs guard */}
      <Route
        path="/company/:slug"
        element={<AppRouteProtected Component={CompanyProfile} />}
      />

      <Route
        path="/public/:slug"
        element={<AppRoutePublic Component={RedirectCompanyProfile} />}
      />

      <Route
        path="/private/:slug"
        element={<AppRoutePublic Component={RedirectCompanyProfile} />}
      />

      <Route
        path="/aia-profile/:slug/:id"
        element={<AppRouteProtected Component={AiaProfileLoader} />}
      />

      {/* ------------------------- Admin Module ------------------------- */}
      <Route
        path="/admin/discover-companies"
        element={<AppRouteProtected Component={AdminDiscover} />}
      />

      {/* ------------------------- CompanySettings Module ------------------------- */}
      {/* Needs guard */}
      <Route
        path="/company/settings"
        element={<AppRouteProtected Component={CompanySettings} />}
      />

      {/* ------------------------- Plans Module ------------------------- */}
      <Route
        path="/plans"
        element={<AppRouteProtected Component={PlansContainer} />}
      />

      <Route
        path="/plans/checkout-success"
        element={<AppRouteProtected Component={CheckoutSuccess} />}
      />

      {/* ------------------------- Account Module ------------------------- */}
      <Route
        path="/account/*"
        element={<AppRouteProtected Component={AccountSettings} />}
      />

      <Route
        path="/messages"
        element={<AppRouteProtected Component={Messages} />}
      />

      {/* ------------------------- Invitation Module ------------------------- */}
      <Route
        path="/invitation/:invitationslug"
        element={<AppRoutePublic Component={InvitationLoader} />}
      />

      <Route
        path="/login/invitation/:invitationslug"
        element={<AppRoute Component={InvitationPage} />}
      />

      {/* ------------------------- AIA Module ------------------------- */}
      <Route path="/aia" element={<AppRouteProtected Component={Aia} />} />

      {/* ------------------------- Api SandBox ------------------------- */}
      <Route
        path="/api/docs"
        element={<AppRouteProtected Component={ApiSandBox} />}
      />

      {/* ------------------------- Explore Module ------------------------- */}
      {/* {process.env.NODE_ENV !== "production" && (
        <Route
          path="/explore"
          element={<AppRouteProtected Component={Explore} />}
        />
      )} */}

      <Route
        path="/not-found"
        element={
          <div className="flex flex-col justify-center items-center h-screen">
            <span className="text-9xl">404</span>
            <span className="text-3xl">Page Not Found</span>
          </div>
        }
      />

      <Route path="*" element={<Navigate to={navRoute} replace />} />
    </Routes>
  );
};

export default RootRoutes;

// TODO: move to its own module. Discuss where should it go with the team. core? pages?
const ProfileRedirect = () => {
  const userType = useSelector(getUserType);

  return <Navigate to={userType === "company" ? "/company-profile" : "/"} />;
};
