import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { FC, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const WYSIWYGEditor: FC<{
  onChange: any;
  value: any;
  placeholder?: string;
}> = ({ onChange, value, placeholder }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (editorState: any) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <React.Fragment>
      <div className="mb-4 editor">
        <Editor
          editorStyle={{ maxHeight: "150px" }}
          placeholder={placeholder}
          wrapperClassName="rounded p-1 border border-black border-opacity-25 w-full border border-black border-opacity-25 rounded-md shadow-sm form-textarea sm:text-sm sm:leading-5"
          toolbarClassName="border-none"
          editorClassName="text-sm text-black px-2"
          stripPastedStyles={true}
          spellCheck
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ["inline", "link", "list", "emoji"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            link: {
              popupClassName: "text-editor-link-popup",
              showOpenOptionOnHover: true,
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
