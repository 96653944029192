import type { FC } from "react";
import type { SectionProps } from "./interfaces";
import TeamMember from "./TeamMember";

const Founders: FC<SectionProps> = ({ startup }) => (
  <div className="mt-6">
    {startup.founders?.length > 0 && (
      <div>
        <div className="ml-4 text-sm text-gray-400">Founders</div>
        <ul className="flex items-start overflow-x-auto">
          {startup.founders.map((founder, idx) => (
            <li key={idx}>
              <TeamMember member={founder} />
            </li>
          ))}
        </ul>
      </div>
    )}
  </div>
);

export default Founders;
