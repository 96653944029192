import { FieldName } from "gql/graphql";

export const shouldRefetchCompanyData = (fieldName: FieldName) => {
  if (
    fieldName === FieldName.MarketOppPitchDeck ||
    fieldName === FieldName.BusinessModel ||
    fieldName === FieldName.Pricing ||
    fieldName === FieldName.ProductDescription ||
    fieldName === FieldName.ProductValueProposition ||
    fieldName === FieldName.ProductCustomers ||
    fieldName === FieldName.ExecuteveSummaryElevatorPitch ||
    fieldName === FieldName.ExecuteveSummaryTraction ||
    fieldName === FieldName.Traction
  ) {
    return true;
  }

  return false;
};

export const shouldRefetchAnalysisData = (fieldName: FieldName) => {
  if (
    fieldName === FieldName.OpportunityAnalysis ||
    fieldName === FieldName.RiskAnalysis ||
    fieldName === FieldName.FiveQuestions ||
    fieldName === FieldName.CurrentRegulations ||
    fieldName === FieldName.FutureRegulations ||
    fieldName === FieldName.MarketOppMarketSize ||
    fieldName === FieldName.MarketOppBlueOcean ||
    fieldName === FieldName.ExecuteveSummaryProblem ||
    fieldName === FieldName.ExecuteveSummaryMarketOpportunity ||
    fieldName === FieldName.ExecuteveSummaryProduct ||
    fieldName === FieldName.ExecuteveSummaryBusinessModel ||
    fieldName === FieldName.ExecuteveSummaryTeam ||
    fieldName === FieldName.ExecuteveSummaryGoToMarketStrategy
  ) {
    return true;
  }

  return false;
};

export const shouldRefetchCompetitorsData = (fieldName: FieldName) => {
  if (
    fieldName === FieldName.CompetitorDifference ||
    fieldName === FieldName.CompetitorSimilarity
  ) {
    return true;
  }

  return false;
};
