import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  refetchCompanyData,
  refetchUiRepresentation,
} from "pages/companyProfile/sagaActions";
import Loading from "atoms/Loading/Loading";
import { FieldName } from "gql/graphql";
import { UPDATE_AIA_PROFILE_GENERAL_INFO } from "queries/editAiaProfile/editAiaProfile";
import { mixpanelEditAiaProfile } from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import {
  getCompanyDetails,
  getIsPublicProfile,
} from "pages/companyProfile/selectors";
import SaveAndCancelIcons from "./SaveAndCancelIcons";

// HOC injected props
export interface HOCInjectedProps {}

// Necessary props, all components must send down
interface BaseProps {
  content: string;
}

// Arbitrary props, some components need to send extra props
interface Props extends BaseProps {
  competitorId?: string;
}

/**
 * This component is very similar to ContentEditable with following differences:
 * - It renders an input text instead of a rich text editor
 * - It renders check and cross icons instead of save and cancel buttons
 * _ It calls another mutation for simplicity
 */
export default function withValueEditable<T extends Props>(
  WrappedComponent: React.ComponentType<T & HOCInjectedProps>,
  fieldName: FieldName
) {
  const WithValueEditable = (props: T) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [updatedValue, setUpdatedValue] = useState("");

    const user = useSelector(getUser);
    const companyData = useSelector(getCompanyDetails);
    const isPublicProfile = useSelector(getIsPublicProfile);

    const companyId = companyData?.companyBySlug?.id;

    const dispatch = useDispatch();

    useEffect(() => {
      setUpdatedValue(props.content);
    }, [props.content]);

    const [updateAiaProfile, { loading }] = useMutation(
      UPDATE_AIA_PROFILE_GENERAL_INFO,
      {
        onCompleted() {
          if (fieldName === FieldName.GeneralInfoWebsite) {
            dispatch(refetchCompanyData({ realId: companyId, slug: "" }));
          } else {
            dispatch(refetchUiRepresentation({ id: companyId }));
          }
        },
      }
    );

    const handleSave = () => {
      setIsEditMode(false);
      updateAiaProfile({
        variables: {
          companyId,
          fieldName,
          updatedValue,
        },
      });
      mixpanelEditAiaProfile(user, companyId, fieldName);
    };

    const handleCancel = () => {
      setIsEditMode(false);
      setUpdatedValue(props.content);
    };

    const handleInputChange = (e: any) => {
      const str = e.target.value;
      setUpdatedValue(str);
    };

    const isTainted = props.content !== updatedValue;

    if (loading) return <Loading size="small" />;

    if (isPublicProfile || !companyData?.companyBySlug?.amITeamMember)
      return <WrappedComponent {...props} />;

    return (
      <div className="relative group">
        {isEditMode ? (
          <div className="flex justify-between items-center gap-x-2">
            <input
              type="text"
              className="bg-gray-100 px-1 grow"
              value={updatedValue}
              onChange={handleInputChange}
            />
            <SaveAndCancelIcons
              onSave={handleSave}
              onCancel={handleCancel}
              saveButtonDisabled={!isTainted}
            />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={["fal", "edit"]}
              className="absolute hidden group-hover:block cursor-pointer right-0 top-0"
              onClick={() => setIsEditMode(true)}
            />
            <WrappedComponent {...props} />
          </>
        )}
      </div>
    );
  };

  return WithValueEditable;
}
