// @ts-nocheck

import { put, select, takeEvery } from "redux-saga/effects";
import { triggerLoadMore, triggerUpdateLazyParams } from "./sagaActions";
import { getComponentLazyParams } from "./selectors";
import { LOAD_MORE } from "./constants";
import { updateParams } from "./reducer";

export default function* rootSaga() {
  function* handleUpdateLazyParams({ payload }) {
    const currentParams = yield select((state) =>
      getComponentLazyParams(state, payload?.componentId)
    );
    if (!payload.componentId) {
      return;
    }
    const filters = payload.filters ?? currentParams?.filters;
    const pagination = payload.pagination ?? currentParams?.pagination;
    const sorter = payload.sorter ?? currentParams?.sorter;

    yield put(
      updateParams({
        componentId: payload.componentId,
        filters,
        pagination,
        sorter,
      })
    );
  }

  function* handleLoadMore({ payload }) {
    const currentParams = yield select((state) =>
      getComponentLazyParams(state, payload.componentId)
    );
    if (!payload.componentId) {
      return;
    }
    let pagination = payload.pagination ??
      currentParams?.pagination ?? { skip: 0 };
    pagination = {
      ...pagination,
      skip: pagination.skip + LOAD_MORE,
    };

    yield put(
      updateParams({
        ...currentParams,
        componentId: payload.componentId,
        pagination,
      })
    );
  }

  return [
    yield takeEvery(triggerUpdateLazyParams, handleUpdateLazyParams),
    yield takeEvery(triggerLoadMore, handleLoadMore),
  ];
}
