export interface AdminDiscoverCompany {
  _id: string;
  id: string;
  displayName: string;
  discoverFeatureStatus: DiscoverFeatureStatus;
  discoverApplicationDate: string;
  createdAt: string;
  email: string;
  website: string;
  referrer: string;
  isGem: boolean;
  logo: {
    small: {
      location: string;
    };
  };
  slug: string;
  type: string;
  subscription?: {
    id: string
    status: string
  }
}

export interface AdminDiscoverCompaniesResponse {
  adminDiscoverCompanies: {
    companies: AdminDiscoverCompany[];
    investorsCount: number;
    startupsCount: number;
  };
}

export interface ListBoxProps<T> {
  options: { name: T }[];
  onSelect: (value: { name: T }) => void;
  selected: { name: T };
}

export enum DiscoverFeatureStatus {
  All = "ALL",
  Approved = "APPROVED",
  Declined = "DECLINED",
  NotRequested = "NOT_REQUESTED",
  Requested = "REQUESTED",
}

export enum CompanyType {
  All = "all",
  Investor = "investor",
  Company = "company",
}
