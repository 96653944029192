import { FC } from "react";
import FullscreenLayout from "atoms/FullscreenLayout/FullscreenLayout";

type AppRoutePublicProps = {
  Component: FC;
};

const AppRoutePublic: FC<AppRoutePublicProps> = ({ Component }) => (
  <FullscreenLayout component={Component} />
);

export default AppRoutePublic;
