import { useMutation } from "@apollo/client";
import { triggerSnack } from "organisms/Snack/sagaActions";
import { UPDATE_COMPANY_MEMBER_ROLE } from "queries/general/account/teamMembers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { permissionsToRole } from "./helpers";
import RoleSelector from "./RoleSelector";
import { Permission, Role, Roles } from "./types";

interface Props {
  permissions?: Permission[];
  companyMemberId: string;
  isUserCompanyAdmin: boolean;
}

const TeamPermission: React.FC<Props> = ({
  permissions,
  companyMemberId,
  isUserCompanyAdmin,
}) => {
  const [selectedRole, setSelectedRole] = useState<Role>(Roles.Collaborator);

  const [updateCompanyMemberRole, { loading }] = useMutation(
    UPDATE_COMPANY_MEMBER_ROLE
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const memberRole = permissions && permissionsToRole(permissions);
    if (memberRole) setSelectedRole(memberRole);
  }, [permissions]);

  function onRoleChange(role: Role) {
    const roleBeforeUpdate = { ...selectedRole };
    setSelectedRole(role);
    updateCompanyMemberRole({
      variables: {
        // Hotfix: The MULTIPLAYER permission should be added from BE side later to the workspaces that are on pro plan
        permissions: [...role.permissions, "MULTIPLAYER"],
        companyMemberId,
      },
    })
      .then((data) => {
        dispatch(
          triggerSnack({
            type: "success",
            title: "Member role updated successfully",
            message: "Member role updated successfully",
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          triggerSnack({
            type: "error",
            title: "Something went wrong",
            message: "We could not update members role",
          })
        );
        setSelectedRole(roleBeforeUpdate);
      });
  }

  if (!isUserCompanyAdmin) {
    return <span>{selectedRole.name}</span>;
  }

  return <RoleSelector selectedRole={selectedRole} onChange={onRoleChange} />;
};

export default TeamPermission;
