// @ts-nocheck
import { moduleTypes } from "core/modules";
import { isLoggedinUser } from "models/user/selectors";
import ConfirRegisterationSagas from "./ConfirmRegistration/sagas";
import ForgotPasswordSlice from "./ForgotPassword/reducer";
import ForgotPasswordSaga from "./ForgotPassword/sagas";
import LoginSaga from "./LoginPage/sagas";
import ResetPasswordSlice from "./ResetPassword/reducer";
import ResetPasswordSaga from "./ResetPassword/sagas";

const module = [
  {
    module: moduleTypes.AUTH,
    path: "/forgot-password",
    componentId: "forgotPassword",
    component: import(
      /* webpackChunkName: "auth" */ "./ForgotPassword/ForgotPassword"
    ),
    routes: [],
    importReducer: () => [ForgotPasswordSlice],
    importSagas: () => [ForgotPasswordSaga],
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Forgot Password Page",
    exact: true,
    showHeader: false,
    name: "Forgot Password",
  },
  {
    module: moduleTypes.AUTH,
    path: "/forgot-password/:id",
    componentId: "forgotPasswordSlug",
    component: import(
      /* webpackChunkName: "auth" */ "./ForgotPassword/ForgotPassword"
    ),
    routes: [],
    importReducer: () => [], // ForgotPasswordSlice is already injected
    importSagas: () => [], // ForgotPasswordSaga is already injected
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Forgot Password Page",
    exact: true,
    showHeader: false,
    name: "Forgot Password",
  },
  {
    module: moduleTypes.AUTH,
    path: "/confirm/:token",
    componentId: "confirmRegister",
    component: import(
      /* webpackChunkName: "auth" */ "./ConfirmRegistration/ConfirmRegistrationPage"
    ),
    routes: [],
    importReducer: () => [],
    importSagas: () => [ConfirRegisterationSagas],
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Confirm Registeration Page",
    exact: true,
    showHeader: false,
    name: "Login",
  },
  {
    module: moduleTypes.AUTH,
    path: "/",
    componentId: "login",
    component: import(
      /* webpackChunkName: "auth" */ "pages/auth/LoginPage/NewLoginPage"
    ),
    routes: [],
    importReducer: () => [],
    importSagas: () => [LoginSaga],
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Login Page",
    exact: true,
    showHeader: false,
    name: "Login",
  },
  {
    module: moduleTypes.AUTH,
    path: "/confirm/:token",
    componentId: "confirmRegister",
    component: import(
      /* webpackChunkName: "auth" */ "./ConfirmRegistration/ConfirmRegistrationPage"
    ),
    routes: [],
    importReducer: () => [],
    importSagas: () => [], // ConfirRegisterationSagas already injected
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Confirm Registeration Page",
    exact: true,
    showHeader: false,
    name: "Confirm Registeration",
  },
  {
    module: moduleTypes.AUTH,
    path: "/reset/:token",
    componentId: "resetPassword",
    component: import(
      /* webpackChunkName: "auth" */ "./ResetPassword/ResetPassword"
    ),
    routes: [],
    importReducer: () => [ResetPasswordSlice],
    importSagas: () => [ResetPasswordSaga],
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Reset Password Page",
    exact: true,
    showHeader: false,
    name: "Reset Password",
  },
  {
    module: moduleTypes.AUTH,
    path: "/reset/:id/:token",
    componentId: "resetPasswordSlug",
    component: import(
      /* webpackChunkName: "auth" */ "./ResetPassword/ResetPassword"
    ),
    routes: [],
    importReducer: () => [], // ResetPasswordSlice is already injected
    importSagas: () => [], // ResetPasswordSaga already injected
    isEnabled: (state) => !isLoggedinUser(state),
    title: "Reset Password Page",
    exact: true,
    showHeader: false,
    name: "Reset Password",
  },
  {
    module: moduleTypes.AUTH,
    path: "/:id",
    key: "key",
    componentId: "login",
    component: import(
      /* webpackChunkName: "auth" */ "pages/auth/LoginPage/NewLoginPage"
    ),
    routes: [],
    importReducer: () => [],
    importSagas: () => [], // LoginSaga already injected
    isEnabled: (state) => !isLoggedinUser(state),
    showHeader: false,
    title: "Login Page",
    exact: true,
    name: "Login",
  },
];

export default module;
