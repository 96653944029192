import type { FC } from "react";
import type { StartupCardProps } from "./interfaces";
import Founders from "./Founders";
import Header from "./Header";
import Finance from "./Finance";
import ExpandPanel from "./ExpandPanel";
import TeamMember from "./TeamMember";
import SDG from "molecules/Sdg/Sdg";
import { Startup } from "pages/dealflow/InvestorDealflow/interfaces";
import Traction from "./Traction";

const isStartupEmpty = (startup: Startup) =>
  !(
    startup.website ||
    startup.fullAddress ||
    startup.industries?.length ||
    startup.description ||
    startup.founders?.length ||
    startup.finance?.raisedTarget ||
    startup.finance?.minTicketSize ||
    startup.founded ||
    startup.valuation ||
    startup.investors?.length ||
    startup.companyStage?.length ||
    startup.teamSize ||
    startup.teamMembers?.length ||
    startup.businessModelDescription ||
    startup.businessTiming ||
    startup.marketOpp ||
    startup.sustainableDevelopmentGoals?.items.length ||
    startup.traction
  );

// This is the easiest way to check if startup has tractions
// We need to review structure of traction fields in order to make dealing with them easier
//
const isTractionPresent = (startup: Startup) =>
  startup.traction &&
  (
    [
      "activeUsers",
      "totalUsers",
      "growthRate",
      "retentionRate",
      "churnRate",
      "netRevenue",
      "recurringRevenue",
      "revenueRate",
      "smv_gsv",
      "salePriceAvg",
      "ratePercentage",
      "cacPayback",
      "ltcCacRatio",
      "demandFreq",
      "supplyFreq",
      "signedContracts",
      "annualValueContracts",
      "pipelineContracts",
      "annualValueContractsPipeline",
      "lettersOfIntent",
    ] as const
  ).some((key) =>
    (startup.traction?.[key] as any[])?.filter(
      (item) =>
        item.amount ||
        item.rate ||
        item.count ||
        item.percentage ||
        (typeof item === "string" && item)
    )
  );

const StartupCard: FC<StartupCardProps> = ({ startup, currency }) => (
  <div className="pb-4">
    <Header startup={startup} currency={currency} />
    <Founders startup={startup} currency={currency} />
    <Finance startup={startup} currency={currency} />
    <div className="px-4">
      {startup.teamMembers?.length > 0 && (
        <ExpandPanel title="Team">
          <div className="flex items-start pt-3 overflow-x-auto">
            {startup.teamMembers.map((member, index) => (
              <TeamMember key={index} member={member} />
            ))}
          </div>
        </ExpandPanel>
      )}
      {startup.businessModelDescription && (
        <ExpandPanel title="Business model">
          <div className="p-3 text-sm">{startup.businessModelDescription}</div>
        </ExpandPanel>
      )}
      {startup.businessTiming && (
        <ExpandPanel title="Timing">
          <div className="p-3 text-sm">{startup.businessTiming}</div>
        </ExpandPanel>
      )}
      {startup.marketOpp && (
        <ExpandPanel title="Market opportunity">
          <div className="p-3 text-sm">{startup.marketOpp}</div>
        </ExpandPanel>
      )}
      {startup.sustainableDevelopmentGoals?.items.length > 0 && (
        <ExpandPanel title="SDG">
          <div className="flex items-start py-3 pl-1 overflow-x-auto">
            {startup.sustainableDevelopmentGoals.items.map((item) => (
              <div className="w-10 h-10 ml-2" key={item}>
                <SDG number={item}></SDG>
              </div>
            ))}
          </div>
        </ExpandPanel>
      )}
      {isTractionPresent(startup) && (
        <ExpandPanel title="Traction">
          <Traction startup={startup} currency={currency} />
        </ExpandPanel>
      )}
      {isStartupEmpty(startup) && !isTractionPresent(startup) && (
        <div className="-mt-4 text-lg text-gray-400">Profile is empty</div>
      )}
    </div>
  </div>
);

export default StartupCard;
