// @ts-nocheck
import { isLoadingEnabled } from "models/loaders/selectors";
import { initialState } from "./reducer";

const resetPassword = (state) => state.resetPassword || initialState;

export const isLoadingPage = (state) =>
  isLoadingEnabled(state, "resetPassword");

export const isValidToken = (state) => resetPassword(state)["isValidToken"];

export const getResetStatus = (state) => resetPassword(state)["status"];
