import "./index.css";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";
import TagManager from "react-gtm-module";
import { graphqlApiEndpoint } from "core/config";
import { LanguageProvider } from "core/lang/LangaugeSetting";
import { NavigateSetter } from "organisms/Navigation";
import SnackPortal from "organisms/Snack/SnackPortal";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { GlobalStyle } from "ui/global";
import ConnectionStatus from "atoms/ConnectionStatus/ConnectionStatus";
import App from "App";
import store from "./core/store";

async function initApp() {
  let { library } = await import(
    /* webpackChunkName: "fortawesome-core" */ "@fortawesome/fontawesome-svg-core"
  );
  let { fab } = await import(
    /* webpackChunkName: "fortawesome-svg" */ "@fortawesome/free-brands-svg-icons"
  );
  let { fas } = await import(
    /* webpackChunkName: "fortawesome-solid-svg" */ "@fortawesome/free-solid-svg-icons"
  );
  let { fal } = await import(
    /* webpackChunkName: "fortawesome-light-svg" */ "@fortawesome/pro-light-svg-icons"
  );
  const { BrowserRouter } = await import(
    /* webpackChunkName: "react-core" */ "react-router-dom"
  );
  const { render } = await import(
    /* webpackChunkName: "react-core" */ "react-dom"
  );
  const { Provider: ReduxProvider } = await import(
    /* webpackChunkName: "react-core" */ "react-redux"
  );

  library.add(fab, fal, fas);

  const client = new ApolloClient({
    credentials: "include",
    link: createUploadLink({
      uri: graphqlApiEndpoint,
      credentials: "include",
      headers: {
        "x-localization": localStorage.getItem("language"),
      },
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            adminDiscoverCompanies: {
              keyArgs: false,
              merge(
                existing = {
                  companies: [],
                  investorsCount: 0,
                  startupsCount: 0,
                },
                incoming
              ) {
                return {
                  companies: [...existing.companies, ...incoming.companies],
                  investorsCount:
                    existing.investorsCount + incoming.investorsCount,
                  startupsCount:
                    existing.startupsCount + incoming.startupsCount,
                };
              },
            },
          },
        },
        Feature: {
          keyFields: ["note", "id"],
        },
      },
    }),
  });

  const root = document.getElementById("root") as HTMLElement;

  if (process.env && process.env.NODE_ENV === "production") {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER!,
    };

    TagManager.initialize(tagManagerArgs);
  }

  render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <LanguageProvider>
          {/* https://stackoverflow.com/questions/68399876/how-to-navigate-outside-of-react-component-using-react-router-6/70002872#70002872 */}
          <NavigateSetter />
          <ReduxProvider store={store}>
            <SnackPortal />
            <GlobalStyle />
            <ConnectionStatus />
            <App />
          </ReduxProvider>
        </LanguageProvider>
      </ApolloProvider>
    </BrowserRouter>,
    root
  );
}

initApp();
