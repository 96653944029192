import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Unicorn } from "images/unicorn.svg";
import Button from "atoms/Button/Button";
import { Page } from "ui/elements/PanelLayout.styles";
import { pageAnimation } from "ui/animations";
import Loading from "atoms/Loading/Loading";
import ApplicationLinkModal from "./ApplicationLinkModal";
import ApplicationsList from "./ApplicationsList";
import { useApplications } from "./hooks";
import { useGuard } from "hooks/useGuard";
import { MustBeAccelerator } from "core/routePolicies";

const Applications = () => {
  const {
    activeCohort,
    cohorts,
    loading,
    isModalOpen,
    isIntroMessage,
    showModal,
    hideModal,
    handleEdit,
    refetch,
  } = useApplications();

  useGuard([MustBeAccelerator]);

  return (
    <Page
      variants={pageAnimation}
      exit="hidden"
      initial="hidden"
      animate="show"
      className="w-full bg-white"
    >
      {isModalOpen && (
        <ApplicationLinkModal
          onAdd={refetch}
          onClose={hideModal}
          cohort={activeCohort}
        />
      )}
      {loading && <Loading />}
      {cohorts.length > 0 && (
        <>
          <div className="flex justify-end mt-2">
            <div className="py-2">
              <Button
                className="block mr-6"
                data-key="create-application-button"
                color="primary"
                size="large"
                onClick={showModal}
              >
                <div className="w-6 mr-2 bg-white rounded-full">
                  <FontAwesomeIcon
                    className="mb-0.5 text-aqua-400 h-3"
                    icon={["fas", "plus"]}
                  />
                </div>
                Create application
              </Button>
            </div>
          </div>
          <div className="px-8">
            <h2 className="text-xl bold">Your application links</h2>
            <p>
              Manage all your current application links here. The startups can
              apply to your program when the link is set as "open"
            </p>
          </div>
        </>
      )}
      <div className="px-8 mt-4">
        <ApplicationsList
          items={cohorts}
          onEdit={handleEdit}
          onClose={(cohort) => handleEdit(cohort)}
          refetch={refetch}
        />
      </div>
      {isIntroMessage && (
        <>
          <Unicorn className="w-4/5 h-40 m-auto sm:mt-10 sm:h-60 sm:w-full" />
          <div className="px-8">
            <h2 className="mt-4 text-xl text-center bold">
              Create your first application link!
            </h2>
            <div className="max-w-[40rem] m-auto mt-2 text-center">
              <p className="mb-2">
                Allow startups from your external network to get in touch with
                you in a structured way.
              </p>
              <p className="mb-2">
                Once you have created your first application link, you can have
                it in your LinkedIn bio, on your website or wherever startups
                try to reach out.
              </p>
              <p className="mb-2">
                As soon as they've applied, they will land in your{" "}
                <span className="text-aqua-600">Dealflow</span> where you'll be
                able to review them, share them with friends, and add them to
                your startup boards.
              </p>
            </div>
            <div className="flex justify-center mt-2">
              <div className="py-2">
                <Button
                  className="block mr-6"
                  color="primary"
                  size="large"
                  onClick={showModal}
                >
                  <div className="w-6 mr-2 bg-white rounded-full">
                    <FontAwesomeIcon
                      className="mb-0.5 text-aqua-400 h-3"
                      icon={["fas", "plus"]}
                    />
                  </div>
                  Create application
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default Applications;
