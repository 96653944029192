import { ReactComponent as CheckIcon } from "images/circle-check-icon.svg";
import Button from "atoms/Button/Button";
import { NavLink } from "react-router-dom";

const reasonBoxClassName =
  "bg-aqua-50 text-sm flex flex-col items-center justify-center relative text-center w-64 sm:w-44 py-6 px-4 m-2 sm:m-1";

const checkBoxClassName = "absolute -top-2";

const DealFlowEmptyState = () => (
  <div className="flex flex-col items-center mt-8">
    <img
      className="mb-2 sm:h-48"
      src="/assets/images/dealflow-empty-state.png"
      alt=""
    />
    <h1 className="mb-2 text-xl font-bold text-black">
      Welcome to your Dealflow!
    </h1>
    <p className="max-w-2xl mx-6 text-sm font-thin text-center text-black md:w-3/5">
      This space is where you can view and manage companies that have actively
      reached out to you or that you have added here.
    </p>
    <h2 className="mt-4 mb-2 text-sm font-normal text-gray-400">
      How companies get here
    </h2>
    <div className="flex flex-col sm:flex-row">
      <div className={reasonBoxClassName}>
        <CheckIcon className={checkBoxClassName} />
        <div>
          When you <span className="text-aqua-500">match</span> with a startup
        </div>
      </div>

      <div className={reasonBoxClassName}>
        <CheckIcon className={checkBoxClassName} />
        <div>
          When a startup reaches out to you via your
          <span className="text-aqua-500"> application link</span>
        </div>
      </div>

      <div className={reasonBoxClassName}>
        <CheckIcon className={checkBoxClassName} />
        <div>
          When you manually
          <span className="text-aqua-500"> add a startup</span>
        </div>
      </div>
    </div>
    <p className="mx-8 my-4 text-sm text-center text-black">
      Click the button to view and personalise your application link:
    </p>
  </div>
);

export default DealFlowEmptyState;
