import { FC, useEffect, useState } from "react";
import EditablePlaceholder from "../reusables/EditablePlaceholder";
import SaveAndCancelButtons from "../reusables/SaveAndCancelButtons";
import { updateStartupProfile } from "pages/editStartupProfile/sagaActions";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

interface Props {
  closingDate: string | undefined | null;
}

const InvestmentClosingDate: FC<Props> = ({ closingDate }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [selected, setSelected] = useState<string | null>();

  useEffect(() => {
    setSelected(closingDate);
  }, [closingDate]);

  const dispatch = useDispatch();

  const onCancel = () => {
    setSelected(closingDate);
    setIsEditMode(false);
  };

  const onSave = () => {
    if (selected)
      dispatch(
        updateStartupProfile({
          fundraising: { closeOn: selected },
        })
      );
    setIsEditMode(false);
  };

  const renderEditModeContent = () => {
    return (
      <div className="mt-2">
        <DayPicker
          mode="single"
          selected={selected ? new Date(selected) : new Date()}
          modifiersStyles={{
            selected: {
              backgroundColor: "#18BEB3",
            },
          }}
          onSelect={(date) => {
            setSelected(date?.toISOString());
          }}
          style={{ margin: "auto", width: "18rem" }}
        />
        <SaveAndCancelButtons
          onCancel={onCancel}
          onSave={onSave}
          className="float-right mt-2"
        />
      </div>
    );
  };

  return (
    <EditablePlaceholder
      title="Investment Closing Date"
      style="secondary"
      value={selected ? format(new Date(selected), "dd MMM yyyy") : undefined}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      renderEditModeContent={renderEditModeContent}
    />
  );
};

export default InvestmentClosingDate;
