// @ts-nocheck
import { createAction } from "@reduxjs/toolkit";

export const initialize = createAction("reset/init", (payload) => ({
  payload,
}));

export const resetPassword = createAction("reset-password", (payload) => ({
  payload,
}));
